/* tslint:disable */
/* eslint-disable */
export interface EquipmentAccess {
  _bt_employee_value?: null | string;
  _bt_grouplocation_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_location_value?: null | string;
  _bt_locationlegalentity_value?: null | string;
  bt_equipmentaccess?: null | string;
  bt_equipmentaccessid?: null | string;
  odataEmployee?: null | string;
  odataGroupLocation?: null | string;
  odataLegalEntity?: null | string;
  odataLocation?: null | string;
  odataLocationLegalEntity?: null | string;
}
