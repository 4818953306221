import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BookingsActions, BookingsActionTypes } from '../actions/bookings.actions';
import * as fromDashboardRelatedReducer from './dashboard-related.reducer';

// Models
import { BookingsBookingsFilteredDto, BookingsFiltered } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type BookingsState = EntityState<BookingsBookingsFilteredDto>;

export interface BookingsRelatedState {
  bookingsState: BookingsState;
  loading: boolean;
  error: any;
}

export const bookingsKeys = ['bt_verdebookingsid'];
export const bookingsStateAdapter: EntityAdapter<BookingsBookingsFilteredDto> = createEntityAdapter<BookingsBookingsFilteredDto>({
  selectId: (data) => keyHandler(data, bookingsKeys),
});

export const initialBookingsState: BookingsState = bookingsStateAdapter.getInitialState({});

export const initialBookingsRelatedState = {
  bookingsState: initialBookingsState,
  loading: false,
  error: {},
};

export function bookingsStateRelatedReducer(state = initialBookingsRelatedState, action: BookingsActions): BookingsRelatedState {
  switch (action.type) {
    // Get All Bookings
    case BookingsActionTypes.BOOKINGS_GET_ALL:
      return { ...state, loading: true };
    case BookingsActionTypes.BOOKINGS_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        bookingsState: bookingsStateAdapter.setAll(action.payload, state.bookingsState),
      };
    }
    case BookingsActionTypes.BOOKINGS_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Clear
    case BookingsActionTypes.BOOKINGS_CLEAR:
      return { ...initialBookingsRelatedState };
    default:
      return state;
  }
}

export const getHomeRelatedState = createFeatureSelector<fromDashboardRelatedReducer.DashboardRelatedState>('dashboardRelatedReducer');

export const getBookingsRelatedState = createSelector(getHomeRelatedState, (state: fromDashboardRelatedReducer.DashboardRelatedState) => state.bookingsState);

// Current Bookings
export const getBookingsState = createSelector(getBookingsRelatedState, (state) => state.bookingsState);
export const { selectAll: getBookings } = bookingsStateAdapter.getSelectors(getBookingsState);

// Loading
export const isLoading = createSelector(getBookingsRelatedState, (state) => state.loading);

// Error
export const getError = createSelector(getBookingsRelatedState, (state) => state.error);
