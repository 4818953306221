/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { TemplateBaseService } from '../template-base-service';
import { TemplateApiConfiguration } from '../template-api-configuration';
import { TemplateStrictHttpResponse } from '../template-strict-http-response';
import { TemplateRequestBuilder } from '../template-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TemplateBenchmark } from '../models/template-benchmark';
import { TemplateGetBenchmarksByGroupQuery } from '../models/template-get-benchmarks-by-group-query';
import { TemplateGetBenchmarksQuery } from '../models/template-get-benchmarks-query';
import { TemplateGetGroupsQuery } from '../models/template-get-groups-query';
import { TemplateGetMeasuresQuery } from '../models/template-get-measures-query';
import { TemplateGetSubgroupsQuery } from '../models/template-get-subgroups-query';
import { TemplateGetTemplateLinksQuery } from '../models/template-get-template-links-query';
import { TemplateGetTemplatesQuery } from '../models/template-get-templates-query';
import { TemplatePatchTemplateGroupCommand } from '../models/template-patch-template-group-command';
import { TemplatePatchTemplateMeasureCommand } from '../models/template-patch-template-measure-command';
import { TemplatePatchTemplateSubgroupCommand } from '../models/template-patch-template-subgroup-command';
import { TemplateTemplateData } from '../models/template-template-data';
import { TemplateTemplateGroup } from '../models/template-template-group';
import { TemplateTemplateLink } from '../models/template-template-link';
import { TemplateTemplateMeasure } from '../models/template-template-measure';
import { TemplateTemplateSubgroup } from '../models/template-template-subgroup';
import { TemplateUploadGroupCommand } from '../models/template-upload-group-command';
import { TemplateUploadMeasureCommand } from '../models/template-upload-measure-command';
import { TemplateUploadSubgroupCommand } from '../models/template-upload-subgroup-command';

@Injectable({
  providedIn: 'root',
})
export class TemplateApiTemplatesService extends TemplateBaseService {
  constructor(config: TemplateApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getTemplates
   */
  static readonly GetTemplatesPath = '/api/Templates/templates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplates()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTemplates$Response(params?: { tenantId?: string; body?: TemplateGetTemplatesQuery }): Observable<TemplateStrictHttpResponse<Array<TemplateTemplateData>>> {
    const rb = new TemplateRequestBuilder(this.rootUrl, TemplateApiTemplatesService.GetTemplatesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as TemplateStrictHttpResponse<Array<TemplateTemplateData>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTemplates$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTemplates(params?: { tenantId?: string; body?: TemplateGetTemplatesQuery }): Observable<Array<TemplateTemplateData>> {
    return this.getTemplates$Response(params).pipe(map((r: TemplateStrictHttpResponse<Array<TemplateTemplateData>>) => r.body as Array<TemplateTemplateData>));
  }

  /**
   * Path part for operation getMeasures
   */
  static readonly GetMeasuresPath = '/api/Templates/measures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMeasures()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMeasures$Response(params?: {
    tenantId?: string;
    body?: TemplateGetMeasuresQuery;
  }): Observable<TemplateStrictHttpResponse<Array<TemplateTemplateMeasure>>> {
    const rb = new TemplateRequestBuilder(this.rootUrl, TemplateApiTemplatesService.GetMeasuresPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as TemplateStrictHttpResponse<Array<TemplateTemplateMeasure>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMeasures$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getMeasures(params?: { tenantId?: string; body?: TemplateGetMeasuresQuery }): Observable<Array<TemplateTemplateMeasure>> {
    return this.getMeasures$Response(params).pipe(
      map((r: TemplateStrictHttpResponse<Array<TemplateTemplateMeasure>>) => r.body as Array<TemplateTemplateMeasure>),
    );
  }

  /**
   * Path part for operation getGroups
   */
  static readonly GetGroupsPath = '/api/Templates/groups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGroups()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getGroups$Response(params?: { tenantId?: string; body?: TemplateGetGroupsQuery }): Observable<TemplateStrictHttpResponse<Array<TemplateTemplateGroup>>> {
    const rb = new TemplateRequestBuilder(this.rootUrl, TemplateApiTemplatesService.GetGroupsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as TemplateStrictHttpResponse<Array<TemplateTemplateGroup>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGroups$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getGroups(params?: { tenantId?: string; body?: TemplateGetGroupsQuery }): Observable<Array<TemplateTemplateGroup>> {
    return this.getGroups$Response(params).pipe(map((r: TemplateStrictHttpResponse<Array<TemplateTemplateGroup>>) => r.body as Array<TemplateTemplateGroup>));
  }

  /**
   * Path part for operation getSubgroups
   */
  static readonly GetSubgroupsPath = '/api/Templates/subgroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubgroups()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSubgroups$Response(params?: {
    tenantId?: string;
    body?: TemplateGetSubgroupsQuery;
  }): Observable<TemplateStrictHttpResponse<Array<TemplateTemplateSubgroup>>> {
    const rb = new TemplateRequestBuilder(this.rootUrl, TemplateApiTemplatesService.GetSubgroupsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as TemplateStrictHttpResponse<Array<TemplateTemplateSubgroup>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSubgroups$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getSubgroups(params?: { tenantId?: string; body?: TemplateGetSubgroupsQuery }): Observable<Array<TemplateTemplateSubgroup>> {
    return this.getSubgroups$Response(params).pipe(
      map((r: TemplateStrictHttpResponse<Array<TemplateTemplateSubgroup>>) => r.body as Array<TemplateTemplateSubgroup>),
    );
  }

  /**
   * Path part for operation getTemplateLinks
   */
  static readonly GetTemplateLinksPath = '/api/Templates/templatelinks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplateLinks()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTemplateLinks$Response(params?: {
    tenantId?: string;
    body?: TemplateGetTemplateLinksQuery;
  }): Observable<TemplateStrictHttpResponse<Array<TemplateTemplateLink>>> {
    const rb = new TemplateRequestBuilder(this.rootUrl, TemplateApiTemplatesService.GetTemplateLinksPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as TemplateStrictHttpResponse<Array<TemplateTemplateLink>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTemplateLinks$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getTemplateLinks(params?: { tenantId?: string; body?: TemplateGetTemplateLinksQuery }): Observable<Array<TemplateTemplateLink>> {
    return this.getTemplateLinks$Response(params).pipe(
      map((r: TemplateStrictHttpResponse<Array<TemplateTemplateLink>>) => r.body as Array<TemplateTemplateLink>),
    );
  }

  /**
   * Path part for operation getBenchmarks
   */
  static readonly GetBenchmarksPath = '/api/Templates/Benchmarks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBenchmarks()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBenchmarks$Response(params?: { tenantId?: string; body?: TemplateGetBenchmarksQuery }): Observable<TemplateStrictHttpResponse<Array<TemplateBenchmark>>> {
    const rb = new TemplateRequestBuilder(this.rootUrl, TemplateApiTemplatesService.GetBenchmarksPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as TemplateStrictHttpResponse<Array<TemplateBenchmark>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBenchmarks$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBenchmarks(params?: { tenantId?: string; body?: TemplateGetBenchmarksQuery }): Observable<Array<TemplateBenchmark>> {
    return this.getBenchmarks$Response(params).pipe(map((r: TemplateStrictHttpResponse<Array<TemplateBenchmark>>) => r.body as Array<TemplateBenchmark>));
  }

  /**
   * Path part for operation getBenchmarksByGroup
   */
  static readonly GetBenchmarksByGroupPath = '/api/Templates/BenchmarksByGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBenchmarksByGroup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBenchmarksByGroup$Response(params?: {
    tenantId?: string;
    body?: TemplateGetBenchmarksByGroupQuery;
  }): Observable<TemplateStrictHttpResponse<Array<TemplateBenchmark>>> {
    const rb = new TemplateRequestBuilder(this.rootUrl, TemplateApiTemplatesService.GetBenchmarksByGroupPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as TemplateStrictHttpResponse<Array<TemplateBenchmark>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBenchmarksByGroup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBenchmarksByGroup(params?: { tenantId?: string; body?: TemplateGetBenchmarksByGroupQuery }): Observable<Array<TemplateBenchmark>> {
    return this.getBenchmarksByGroup$Response(params).pipe(
      map((r: TemplateStrictHttpResponse<Array<TemplateBenchmark>>) => r.body as Array<TemplateBenchmark>),
    );
  }

  /**
   * Path part for operation createTemplateMeasure
   */
  static readonly CreateTemplateMeasurePath = '/api/Templates/create_templateMeasure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTemplateMeasure()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTemplateMeasure$Response(params?: { tenantId?: string; body?: TemplateUploadMeasureCommand }): Observable<TemplateStrictHttpResponse<boolean>> {
    const rb = new TemplateRequestBuilder(this.rootUrl, TemplateApiTemplatesService.CreateTemplateMeasurePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as TemplateStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTemplateMeasure$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTemplateMeasure(params?: { tenantId?: string; body?: TemplateUploadMeasureCommand }): Observable<boolean> {
    return this.createTemplateMeasure$Response(params).pipe(map((r: TemplateStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation createTemplateGroup
   */
  static readonly CreateTemplateGroupPath = '/api/Templates/create_templateGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTemplateGroup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTemplateGroup$Response(params?: { tenantId?: string; body?: TemplateUploadGroupCommand }): Observable<TemplateStrictHttpResponse<boolean>> {
    const rb = new TemplateRequestBuilder(this.rootUrl, TemplateApiTemplatesService.CreateTemplateGroupPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as TemplateStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTemplateGroup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTemplateGroup(params?: { tenantId?: string; body?: TemplateUploadGroupCommand }): Observable<boolean> {
    return this.createTemplateGroup$Response(params).pipe(map((r: TemplateStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation createTemplateSubgroup
   */
  static readonly CreateTemplateSubgroupPath = '/api/Templates/create_templateSubgroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTemplateSubgroup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTemplateSubgroup$Response(params?: { tenantId?: string; body?: TemplateUploadSubgroupCommand }): Observable<TemplateStrictHttpResponse<boolean>> {
    const rb = new TemplateRequestBuilder(this.rootUrl, TemplateApiTemplatesService.CreateTemplateSubgroupPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as TemplateStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTemplateSubgroup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTemplateSubgroup(params?: { tenantId?: string; body?: TemplateUploadSubgroupCommand }): Observable<boolean> {
    return this.createTemplateSubgroup$Response(params).pipe(map((r: TemplateStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchTemplateMeasure
   */
  static readonly PatchTemplateMeasurePath = '/api/Templates/patch_templateMeasure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTemplateMeasure()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchTemplateMeasure$Response(params?: { tenantId?: string; body?: TemplatePatchTemplateMeasureCommand }): Observable<TemplateStrictHttpResponse<boolean>> {
    const rb = new TemplateRequestBuilder(this.rootUrl, TemplateApiTemplatesService.PatchTemplateMeasurePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as TemplateStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchTemplateMeasure$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchTemplateMeasure(params?: { tenantId?: string; body?: TemplatePatchTemplateMeasureCommand }): Observable<boolean> {
    return this.patchTemplateMeasure$Response(params).pipe(map((r: TemplateStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchTemplateGroup
   */
  static readonly PatchTemplateGroupPath = '/api/Templates/patch_templateGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTemplateGroup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchTemplateGroup$Response(params?: { tenantId?: string; body?: TemplatePatchTemplateGroupCommand }): Observable<TemplateStrictHttpResponse<boolean>> {
    const rb = new TemplateRequestBuilder(this.rootUrl, TemplateApiTemplatesService.PatchTemplateGroupPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as TemplateStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchTemplateGroup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchTemplateGroup(params?: { tenantId?: string; body?: TemplatePatchTemplateGroupCommand }): Observable<boolean> {
    return this.patchTemplateGroup$Response(params).pipe(map((r: TemplateStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchTemplateSubgroup
   */
  static readonly PatchTemplateSubgroupPath = '/api/Templates/patch_templateSubgroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTemplateSubgroup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchTemplateSubgroup$Response(params?: { tenantId?: string; body?: TemplatePatchTemplateSubgroupCommand }): Observable<TemplateStrictHttpResponse<boolean>> {
    const rb = new TemplateRequestBuilder(this.rootUrl, TemplateApiTemplatesService.PatchTemplateSubgroupPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as TemplateStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchTemplateSubgroup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchTemplateSubgroup(params?: { tenantId?: string; body?: TemplatePatchTemplateSubgroupCommand }): Observable<boolean> {
    return this.patchTemplateSubgroup$Response(params).pipe(map((r: TemplateStrictHttpResponse<boolean>) => r.body as boolean));
  }
}
