/* tslint:disable */
/* eslint-disable */
export interface DvBusinessCycles {
  _bt_period_value?: null | string;
  _bthr_businesscycletype_value?: null | string;
  bt_businesscycleunique?: null | string;
  bt_maxvalue?: null | number;
  bt_minvalue?: null | number;
  bthr_businesscycleid?: null | string;
  bthr_cycleenddate?: null | string;
  bthr_cyclename?: null | string;
  bthr_cyclestartdate?: null | string;
  odataCycleType?: null | string;
  odataLegalEntity?: null | string;
  odataPeriod?: null | string;
  statecode?: null | number;
}
