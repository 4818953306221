/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TasksApiConfiguration, TasksApiConfigurationParams } from './tasks-api-configuration';

import { TasksApiTasksService } from './services/tasks-api-tasks.service';
import { TasksApiVersionService } from './services/tasks-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [TasksApiTasksService, TasksApiVersionService, TasksApiConfiguration],
})
export class TasksApiModule {
  static forRoot(params: TasksApiConfigurationParams): ModuleWithProviders<TasksApiModule> {
    return {
      ngModule: TasksApiModule,
      providers: [
        {
          provide: TasksApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: TasksApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('TasksApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
