/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PolicyBaseService } from '../policy-base-service';
import { PolicyApiConfiguration } from '../policy-api-configuration';
import { PolicyStrictHttpResponse } from '../policy-strict-http-response';
import { PolicyRequestBuilder } from '../policy-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PolicyGetPolicyQuery } from '../models/policy-get-policy-query';
import { PolicyPolicyExample } from '../models/policy-policy-example';
import { PolicyPostPolicyCommand } from '../models/policy-post-policy-command';

@Injectable({
  providedIn: 'root',
})
export class PolicyApiPolicyService extends PolicyBaseService {
  constructor(config: PolicyApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getPolicy
   */
  static readonly GetPolicyPath = '/api/Policy/get/policy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPolicy()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPolicy$Response(params?: { tenantId?: string; body?: PolicyGetPolicyQuery }): Observable<PolicyStrictHttpResponse<Array<PolicyPolicyExample>>> {
    const rb = new PolicyRequestBuilder(this.rootUrl, PolicyApiPolicyService.GetPolicyPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as PolicyStrictHttpResponse<Array<PolicyPolicyExample>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPolicy$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPolicy(params?: { tenantId?: string; body?: PolicyGetPolicyQuery }): Observable<Array<PolicyPolicyExample>> {
    return this.getPolicy$Response(params).pipe(map((r: PolicyStrictHttpResponse<Array<PolicyPolicyExample>>) => r.body as Array<PolicyPolicyExample>));
  }

  /**
   * Path part for operation postPolicy
   */
  static readonly PostPolicyPath = '/api/Policy/post/policy';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPolicy()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postPolicy$Response(params?: { tenantId?: string; body?: PolicyPostPolicyCommand }): Observable<PolicyStrictHttpResponse<boolean>> {
    const rb = new PolicyRequestBuilder(this.rootUrl, PolicyApiPolicyService.PostPolicyPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PolicyStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPolicy$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postPolicy(params?: { tenantId?: string; body?: PolicyPostPolicyCommand }): Observable<boolean> {
    return this.postPolicy$Response(params).pipe(map((r: PolicyStrictHttpResponse<boolean>) => r.body as boolean));
  }
}
