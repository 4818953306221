/* tslint:disable */
/* eslint-disable */
import { BthrFund } from './bthr-fund';
export interface EmployeeFunds {
  _bt_fundoption_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bthr_employee_value?: null | string;
  _bthr_fund_value?: null | string;
  bt_approvalcomment?: null | string;
  bt_approvalid?: null | string;
  bt_approvername?: null | string;
  bt_contribution?: number;
  bthr_Fund?: BthrFund;
  bthr_applicationformcontract?: null | string;
  bthr_applicationformcontract_name?: null | string;
  bthr_employeefundid?: null | string;
  bthr_enddate?: null | string;
  bthr_entrydate?: null | string;
  bthr_fundnumber?: null | string;
  bthr_fundoverview?: null | string;
  bthr_fundoverview_name?: null | string;
  bthr_latestbalance?: null | string;
  bthr_latestbalance_base?: null | string;
  bthr_latestbalancedate?: null | string;
  bthr_name?: null | string;
  odataEmployee?: null | string;
  odataFundOption?: null | string;
  odataLegalEntity?: null | string;
  odataPostFundOption?: null | string;
  odataRecordStatus?: null | string;
  odataaddressidtype?: null | string;
  odatafund?: null | string;
}
