/* tslint:disable */
/* eslint-disable */
export enum UserBthrPartnershipStatus {
  Single = 'Single',
  Married = 'Married',
  Divorced = 'Divorced',
  Widowed = 'Widowed',
  LifePartner = 'LifePartner',
  Separated = 'Separated',
}
