/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TimeTrackingApiConfiguration, TimeTrackingApiConfigurationParams } from './time-tracking-api-configuration';

import { TimeTrackingApiTimeTrackingService } from './services/time-tracking-api-time-tracking.service';
import { TimeTrackingApiVersionService } from './services/time-tracking-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [TimeTrackingApiTimeTrackingService, TimeTrackingApiVersionService, TimeTrackingApiConfiguration],
})
export class TimeTrackingApiModule {
  static forRoot(params: TimeTrackingApiConfigurationParams): ModuleWithProviders<TimeTrackingApiModule> {
    return {
      ngModule: TimeTrackingApiModule,
      providers: [
        {
          provide: TimeTrackingApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: TimeTrackingApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('TimeTrackingApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
