/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EngageBaseService } from '../engage-base-service';
import { EngageApiConfiguration } from '../engage-api-configuration';
import { EngageStrictHttpResponse } from '../engage-strict-http-response';
import { EngageRequestBuilder } from '../engage-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EngageEngageExample } from '../models/engage-engage-example';
import { EngageGetEngageQuery } from '../models/engage-get-engage-query';
import { EngagePostEngageCommand } from '../models/engage-post-engage-command';

@Injectable({
  providedIn: 'root',
})
export class EngageApiEngageService extends EngageBaseService {
  constructor(config: EngageApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getEngage
   */
  static readonly GetEngagePath = '/api/Engage/get/engage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEngage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEngage$Response(params?: { tenantId?: string; body?: EngageGetEngageQuery }): Observable<EngageStrictHttpResponse<Array<EngageEngageExample>>> {
    const rb = new EngageRequestBuilder(this.rootUrl, EngageApiEngageService.GetEngagePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EngageStrictHttpResponse<Array<EngageEngageExample>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEngage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEngage(params?: { tenantId?: string; body?: EngageGetEngageQuery }): Observable<Array<EngageEngageExample>> {
    return this.getEngage$Response(params).pipe(map((r: EngageStrictHttpResponse<Array<EngageEngageExample>>) => r.body as Array<EngageEngageExample>));
  }

  /**
   * Path part for operation postEngage
   */
  static readonly PostEngagePath = '/api/Engage/post/engage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postEngage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEngage$Response(params?: { tenantId?: string; body?: EngagePostEngageCommand }): Observable<EngageStrictHttpResponse<boolean>> {
    const rb = new EngageRequestBuilder(this.rootUrl, EngageApiEngageService.PostEngagePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as EngageStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postEngage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEngage(params?: { tenantId?: string; body?: EngagePostEngageCommand }): Observable<boolean> {
    return this.postEngage$Response(params).pipe(map((r: EngageStrictHttpResponse<boolean>) => r.body as boolean));
  }
}
