/* tslint:disable */
/* eslint-disable */
export interface BtApprovalDefinition {
  _bt_approvaldefinitionheader_value?: null | string;
  _bt_assignedemployee_value?: null | string;
  _bt_assignlegalentity_value?: null | string;
  _bt_businessunit_value?: null | string;
  _bt_function_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_parentdefinition_value?: null | string;
  _bt_team_value?: null | string;
  _bt_verderole_value?: null | string;
  bt_approvalamount?: null | string;
  bt_approvalamount_base?: null | string;
  bt_approvaldefinitionid?: null | string;
  bt_approvaltype?: number;
  bt_assignmenttype?: number;
  bt_definitionname?: null | string;
  bt_description?: null | string;
  bt_leadtimehours?: number;
  bt_module?: number;
  bt_name?: null | string;
  bt_recordtechnicalcolumn?: null | string;
  bt_recordtechnicaltable?: null | string;
  bt_seq?: number;
  bt_taskpriority?: number;
  bt_technicalname?: null | string;
  bt_value?: null | string;
  odataApprovalDefinitionHeader?: null | string;
  odataAssignLegalEntity?: null | string;
  odataAssignedEmployee?: null | string;
  odataBusinessUnit?: null | string;
  odataFunction?: null | string;
  odataLegalEntity?: null | string;
  odataParentDefinition?: null | string;
  odataTeam?: null | string;
  odataVerdeRole?: null | string;
}
