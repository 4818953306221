/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvAccreditationAuthorities } from '../models/dv-accreditation-authorities';
import { DvAccreditationAuthoritiesPost } from '../models/dv-accreditation-authorities-post';

@Injectable({
  providedIn: 'root',
})
export class SharedApiAccreditationAuthoritiesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllAccreditationAuthorities
   */
  static readonly GetAllAccreditationAuthoritiesPath = '/api/AccreditationAuthorities/get/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAccreditationAuthorities()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAccreditationAuthorities$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<DvAccreditationAuthorities>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiAccreditationAuthoritiesService.GetAllAccreditationAuthoritiesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvAccreditationAuthorities>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllAccreditationAuthorities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAccreditationAuthorities(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<Array<DvAccreditationAuthorities>> {
    return this.getAllAccreditationAuthorities$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvAccreditationAuthorities>>) => r.body as Array<DvAccreditationAuthorities>),
    );
  }

  /**
   * Path part for operation postAccreditationAuthorities
   */
  static readonly PostAccreditationAuthoritiesPath = '/api/AccreditationAuthorities/post';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postAccreditationAuthorities()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postAccreditationAuthorities$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: DvAccreditationAuthoritiesPost;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiAccreditationAuthoritiesService.PostAccreditationAuthoritiesPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postAccreditationAuthorities$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postAccreditationAuthorities(params?: { subscriptionKey?: string; APIURL?: string; body?: DvAccreditationAuthoritiesPost }): Observable<boolean> {
    return this.postAccreditationAuthorities$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
