/* tslint:disable */
/* eslint-disable */
import { UserSecurity } from './user-security';
export interface RoleDefinition {
  _bt_employee_value?: null | string;
  _bt_employeelegalentity_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_rolelookup_value?: null | string;
  allUserSecurity?: null | Array<UserSecurity>;
  bt_roledefinitionid?: null | string;
  bt_rolename?: null | string;
  odataLegalEntity?: null | string;
  odataRole?: null | string;
}
