/* eslint-disable @typescript-eslint/no-empty-interface */
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EnhancedReviewDetailActions, EnhancedReviewDetailActionTypes } from '../actions/enhanced-review-detail.actions';
import * as fromEnhancedReviewDetailRelatedReducer from './enhanced-review-detail.reducer';

// Models
import { PerformanceReviewPatchEnhancedReviewDetailCommand } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export interface EnhancedReviewDetailState extends EntityState<PerformanceReviewPatchEnhancedReviewDetailCommand> {}

export interface EnhancedReviewDetailRelatedState {
  enhancedReviewDetailState: EnhancedReviewDetailState;
  loading: boolean;
  error: any;
}

export const enhancedReviewDetailKeys = ['bt_anceReviewid'];
export const enhancedReviewDetailStateAdapter: EntityAdapter<PerformanceReviewPatchEnhancedReviewDetailCommand> =
  createEntityAdapter<PerformanceReviewPatchEnhancedReviewDetailCommand>({
    selectId: (data) => keyHandler(data, enhancedReviewDetailKeys),
  });

export const initialEnhancedReviewDetailState: EnhancedReviewDetailState = enhancedReviewDetailStateAdapter.getInitialState({});

export const initialEnhancedReviewDetailRelatedState = {
  enhancedReviewDetailState: initialEnhancedReviewDetailState,
  enhancedReviewDetailSavedState: initialEnhancedReviewDetailState,
  loading: false,
  error: {},
};

export function enhancedReviewDetailRelatedReducer(
  state = initialEnhancedReviewDetailRelatedState,
  action: EnhancedReviewDetailActions,
): EnhancedReviewDetailRelatedState {
  switch (action.type) {
    // Get EnhancedReviewDetail
    //case EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_GET_ALL:
    //  return { ...state, loading: true };
    //case EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_GET_ALL_SUCCESS:
    //  return {
    //    ...state,
    //    loading: false,
    //    enhancedReviewDetailState: enhancedReviewDetailStateAdapter.upsertMany(action.payload, state.enhancedReviewDetailState),
    //  };
    //case EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_GET_ALL_ERROR:
    //  return { ...state, loading: false, error: action.payload };

    //// EnhancedReviewDetail Upload
    //case EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_UPLOAD_SUCCESS: {
    //  return {
    //    ...state,
    //  };
    //}

    // EnhancedReviewDetail Update
    case EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_UPDATE_SUCCESS: {
      return {
        ...state,
      };
    }

    // Clear
    case EnhancedReviewDetailActionTypes.ENHANCEDREVIEWDETAIL_ITEM_CLEAR:
      return { ...initialEnhancedReviewDetailRelatedState };

    default:
      return state;
  }
}

export const getEnhancedReviewDetailsRelatedState =
  createFeatureSelector<fromEnhancedReviewDetailRelatedReducer.EnhancedReviewDetailRelatedState>('enhancedReviewDetailRelatedReducer');

export const getEnhancedReviewDetailRelatedState = createSelector(
  getEnhancedReviewDetailsRelatedState,
  (state: fromEnhancedReviewDetailRelatedReducer.EnhancedReviewDetailRelatedState) => state.enhancedReviewDetailState,
);

// Current EnhancedReviewDetail

export const getEnhancedReviewDetailState = createSelector(getEnhancedReviewDetailsRelatedState, (state) => state.enhancedReviewDetailState);
export const { selectAll: getAllEnhancedReviewDetail } = enhancedReviewDetailStateAdapter.getSelectors(getEnhancedReviewDetailState);

// Loading

export const isEnhancedReviewDetailLoading = createSelector(getEnhancedReviewDetailsRelatedState, (state) => state.loading);

// Error

export const getEnhancedReviewDetailError = createSelector(getEnhancedReviewDetailsRelatedState, (state) => state.error);
