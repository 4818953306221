/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvTaxRecords } from '../models/dv-tax-records';
import { DvTaxRecordsPost } from '../models/dv-tax-records-post';

@Injectable({
  providedIn: 'root',
})
export class SharedApiTaxRecordsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllTaxRecordsRecords
   */
  static readonly GetAllTaxRecordsRecordsPath = '/api/TaxRecords/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTaxRecordsRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTaxRecordsRecords$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvTaxRecords>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTaxRecordsService.GetAllTaxRecordsRecordsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvTaxRecords>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllTaxRecordsRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTaxRecordsRecords(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<DvTaxRecords>> {
    return this.getAllTaxRecordsRecords$Response(params).pipe(map((r: StrictHttpResponse<Array<DvTaxRecords>>) => r.body as Array<DvTaxRecords>));
  }

  /**
   * Path part for operation patchTaxRecords
   */
  static readonly PatchTaxRecordsPath = '/api/TaxRecords/patch/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTaxRecords()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchTaxRecords$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvTaxRecordsPost;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTaxRecordsService.PatchTaxRecordsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchTaxRecords$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchTaxRecords(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: DvTaxRecordsPost }): Observable<boolean> {
    return this.patchTaxRecords$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postTaxRecords
   */
  static readonly PostTaxRecordsPath = '/api/TaxRecords/post/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTaxRecords()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTaxRecords$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvTaxRecordsPost;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTaxRecordsService.PostTaxRecordsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postTaxRecords$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTaxRecords(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: DvTaxRecordsPost }): Observable<boolean> {
    return this.postTaxRecords$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
