/* tslint:disable */
/* eslint-disable */
export interface TakeOnHrFocusImport {
  _bt_timeofftype_value?: null | string;
  bt_balance?: null | number;
  bt_cycleenddate?: null | string;
  bt_takeonhrfocusimportid?: null | string;
  bt_totaltaken?: null | number;
  odataTimeOffType?: null | string;
}
