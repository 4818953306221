import { TitleCasePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

// Reports
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';

// Spinner
import { NgxSpinnerModule } from 'ngx-spinner';

// Material
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

// Shared Components
import { IdValidationComponent } from './components/id-validation/id-validation.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { ImageUploadDirective } from './components/image-upload/image-upload.directive';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { SuccessionsTabComponent } from './components/successions-tab/successions-tab.component';
import { VaccinationTabComponent } from './components/vaccination-tab/vaccination-tab.component';
import { EngageTabComponent } from './components/engage-tab/engage-tab.component';
import { NominationApprovalTabComponent } from './components/nomination-approval-tab/nomination-approval-tab.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { ResponsiveDialogDirective } from './directives/responsive-dialog.directive';
import { ProvidentFundComponent } from './services/manual-documents/components/provident-fund/provident-fund.component';
import { LoaderComponent } from './components/loader/loader.component';

// Shared Modals
import { FleetApprovalModalComponent } from './modals/fleet/fleet-approval-modal/fleet-approval-modal.component';
import { FleetAssignModalComponent } from './modals/fleet/fleet-assign-modal/fleet-assign-modal.component';
import { FleetClassificationModalComponent } from './modals/fleet/fleet-classification-modal/fleet-classification-modal.component';
import { FleetConfirmationModalComponent } from './modals/fleet/fleet-confirmation-modal/fleet-confirmation-modal.component';
import { FleetLicenseModalComponent } from './modals/fleet/fleet-license-modal/fleet-license-modal.component';
import { FleetModelsModalComponent } from './modals/fleet/fleet-models-modal/fleet-models-modal.component';
import { IssueModalComponent } from './modals/issue-modal/issue-modal.component';
import { ModalComponent } from './modals/modal/modal.component';
import { NaMobileModalComponent } from './modals/na-mobile-modal/na-mobile-modal.component';
import { OnlineTrainingModalComponent } from './modals/online-training-modal/online-training-modal.component';
import { PayslipDialogComponent } from './modals/payslip-dialog/payslip-dialog.component';
import { NewRequestModalComponent } from './modals/requests/new-request-modal/new-request-modal.component';
import { TimeoffApprovalModalComponent } from './modals/timeoff-approval-modal/timeoff-approval-modal.component';
import { VerdeApprovalModalComponent } from './modals/verde-approval-modal/verde-approval-modal.component';

// Pipes
import { DatePipe } from '@angular/common';
import { SafePipe } from './pipes/safe.pipe';
import { YesNoPipe } from './pipes/yesno.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';

// QR Codes
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QRCodeModule } from 'angularx-qrcode';

// Web-Cam
import { WebcamModule } from 'ngx-webcam';

// Permissions
import { NgxPermissionsModule } from 'ngx-permissions';

// Lottie
import { LottieModule } from 'ngx-lottie';

export function playerFactory(): any {
  return import('lottie-web');
}

// Formly
import { KendoFormlyModule } from './kendo-formly/kendo-formly.module';

export const SHARED_COMPONENT_EXPORT = [
  ImageUploadComponent,
  ImageUploadDirective,
  PageContainerComponent,
  UserCardComponent,
  IdValidationComponent,
  SuccessionsTabComponent,
  VaccinationTabComponent,
  EngageTabComponent,
  NominationApprovalTabComponent,
  PayslipDialogComponent,
  IssueModalComponent,
  ModalComponent,
  TimeoffApprovalModalComponent,
  FleetAssignModalComponent,
  FleetConfirmationModalComponent,
  FleetApprovalModalComponent,
  FleetLicenseModalComponent,
  FleetModelsModalComponent,
  FleetClassificationModalComponent,
  NewRequestModalComponent,
  NaMobileModalComponent,
  VerdeApprovalModalComponent,
  OnlineTrainingModalComponent,
  ProvidentFundComponent,
  LoaderComponent,
];

export const SHARED_PIPES = [SafePipe, YesNoPipe, FormatDatePipe];

export const SHARED_MODULE_EXPORT = [
  // Spinner
  NgxSpinnerModule,
  // Material
  MatIconModule,
  MatDividerModule,
  // QR Codes
  QRCodeModule,
  ZXingScannerModule,
  // Web-Cam
  WebcamModule,
  // Permissions
  NgxPermissionsModule,
  HttpClientModule,
  MatMenuModule,
  //Formly
  KendoFormlyModule,
  // Lottie
  LottieModule.forRoot({ player: playerFactory }) as any,
  // Reports
  TelerikReportingModule,
];

@NgModule({
  declarations: [...SHARED_COMPONENT_EXPORT, ...SHARED_PIPES, ResponsiveDialogDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [...SHARED_MODULE_EXPORT],
  exports: [...SHARED_MODULE_EXPORT, ...SHARED_COMPONENT_EXPORT, ...SHARED_PIPES],
  providers: [DatePipe, TitleCasePipe],
})
export class VerdeSharedModule {}
