import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Annotation, SharedApiAnnotationService, SharedApiFleetMovementService, SharedApiTaskService, VerdeTask } from '@verde/api';
import { ImageCompressionService } from '@verde/core';
import { ModalService, VerdeApprovalService, VerdeTaskCustom } from '@verde/shared';
import { BackUrl } from 'apps/verde/src/app/features/fleet/models/back-url-enum';
import { ChecklistFormService } from 'apps/verde/src/app/features/fleet/services/checklist-form/checklist-form.service';
import { NoteType } from 'apps/verde/src/app/features/tasks/enums/note-type.enum';
import { TaskFleetItemTemplates } from 'apps/verde/src/app/features/tasks/enums/task-fleet-item-templates.enum';
import { TaskFollowUpStatus } from 'apps/verde/src/app/features/tasks/enums/task-follow-up-status.enum';
import { TaskPriority } from 'apps/verde/src/app/features/tasks/enums/task-priority.enum';
import { TaskSource } from 'apps/verde/src/app/features/tasks/enums/task-source.enum';
import { TaskStatus } from 'apps/verde/src/app/features/tasks/enums/task-status.enum';
import { TaskService } from 'apps/verde/src/app/features/tasks/services/task.service';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, mergeMap, of, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'view-task',
  templateUrl: './view-task.component.html',
  styleUrls: ['./view-task.component.scss'],
})
export class ViewTaskComponent {
  private readonly onDestroy$: Subject<boolean> = new Subject<boolean>();

  TaskStatus = TaskStatus;
  TaskSource = TaskSource;

  currentTask: VerdeTaskCustom;
  currentNotes: Array<Annotation>;

  selectedTaskListId = '';
  noteTitle = '';
  noteText = '';
  followUpComment = '';
  savingNote: boolean;
  noteType: NoteType = NoteType.Text;
  noteFile = '';
  noteFileName = '';
  noteMimeType = '';
  showTimeOffApprovalModal = false;
  showVerdeApprovalModal = false;
  submitDisabled = false;
  loading = false;

  showFleetApprovalModal: boolean;
  showAssignModal: boolean;
  showConfirmationModal: boolean;
  showLicenseModal: boolean;
  confirmationType: string;
  referenceGuid: string;
  currentStep = 1;
  get taskPriority(): string {
    if (this.currentTask?.bt_priority === TaskPriority.Low) {
      return 'Low';
    } else if (this.currentTask?.bt_priority === TaskPriority.Medium) {
      return 'Medium';
    } else if (this.currentTask?.bt_priority === TaskPriority.Important) {
      return 'Important';
    } else if (this.currentTask?.bt_priority === TaskPriority.Urgent) {
      return 'Urgent';
    }

    return 'Low';
  }

  get spinnerId(): string {
    return `adding-new-note-${this.currentTask.bt_verdetasklistid}`;
  }

  get assignedTo(): string {
    return this.currentTask.bt_AssignedTo?.bt_fullname ?? 'Not assigned';
  }

  get closedTasks(): boolean {
    return this.currentTask.bt_taskstatus === TaskStatus.Closed;
  }

  get followUpTasks(): boolean {
    return this.currentTask.bt_FollowedUpby !== null;
  }

  get completeButton(): string {
    if (this.currentTask.bt_tasksource === TaskSource.FleetItem) {
      switch (this.currentTask.odataTaskTemplate) {
        case TaskFleetItemTemplates.FleetManagerApprove:
          return 'Approve';
        case TaskFleetItemTemplates.FleetManagerCompleteChecklist:
          return 'Complete checklist';
        case TaskFleetItemTemplates.FleetManagerMustAssign:
          return 'Assign';
        case TaskFleetItemTemplates.FromFleetManagerCompleteConfirmation:
          return 'Confirm';
        case TaskFleetItemTemplates.FromResponderCompleteChecklist:
          return 'Complete checklist';
        case TaskFleetItemTemplates.FromResponderCompleteConfirmation:
          return 'Confirm';
        case TaskFleetItemTemplates.OutstandingFleetItemLicense:
          return 'Submit License';
        case TaskFleetItemTemplates.ToFleetManagerCompleteConfirmation:
          return 'Confirm';
        case TaskFleetItemTemplates.ToResponderCompleteChecklist:
          return 'Complete checklist';
        case TaskFleetItemTemplates.ToResponderCompleteConfirmation:
          return 'Confirm';
        case TaskFleetItemTemplates.FromFleetManagerApprove:
          return 'Approve';
        case TaskFleetItemTemplates.FromFleetManagerCompleteChecklist:
          return 'Complete checklist';
        case TaskFleetItemTemplates.ToFleetManagerCompleteChecklist:
          return 'Complete checklist';
        default:
          return 'Complete task';
      }
    } else if (this.currentTask.bt_tasksource === TaskSource.Equipment) {
      return 'Complete task';
    }
    return 'Complete task';
  }

  constructor(
    public taskService: TaskService,
    private router: Router,
    private modalService: ModalService,
    private ngxSpinner: NgxSpinnerService,
    private sharedApiAnnotationService: SharedApiAnnotationService,
    private sharedApiTaskService: SharedApiTaskService,
    private compressService: ImageCompressionService,
    private sharedApiFleetMovementService: SharedApiFleetMovementService,
    private fleetNavService: ChecklistFormService,
    private sidebarService: VerdeApprovalService,
  ) {}

  ngOnInit(): void {
    this.initCurrentTask();
    this.initNotes();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  navigateBack(): void {
    this.router.navigateByUrl('/tasks');
  }

  completeTaskClicked(): void {
    this.currentStep++;
    if (this.currentTask.bt_tasksource === TaskSource.FleetItem) {
      this.handleFleetItemTask();
    } else if (this.currentTask.bt_tasksource === TaskSource.Equipment) {
      this.router.navigate(['/equipment', 'take-on']);
    } else {
      this.completeTaskModal();
    }
  }

  approveTaskClicked(technicalName: string): void {
    this.currentStep++;
    if (technicalName === 'TIME-TRACKING-ALLOW-TIME-TRACKING-APPROVAL') {
      this.showVerdeApprovalModal = true;
    } else {
      this.showTimeOffApprovalModal = true;
    }
  }

  hideApprovalModal(): void {
    this.showTimeOffApprovalModal = false;
  }

  hideVerdeApprovalModal(): void {
    this.showVerdeApprovalModal = false;
  }

  addNote(): void {
    this.modalService.open('add-task-note-' + this.currentTask?.bt_verdetasklistid);
  }

  completeTaskModal(): void {
    this.modalService.open('confirm-complete-task-' + this.currentTask?.bt_verdetasklistid);
  }

  acceptTaskModal(): void {
    this.modalService.open('accept-task-' + this.currentTask?.bt_verdetasklistid);
  }

  rejectTaskModal(): void {
    this.modalService.open('reject-task-' + this.currentTask?.bt_verdetasklistid);
  }

  completeTask(taskStatus: TaskStatus = TaskStatus.Completed, approved = false): void {
    const tempTask: VerdeTaskCustom = this.mapPatchTask(this.currentTask);
    tempTask.bt_taskstatus = taskStatus;
    if (this.taskService.isProxyUser) {
      tempTask.bt_proxy = true;
      tempTask.odatPostProxyEmployee = `/bthr_employees(${this.taskService.mainUser.employeeId})`;
      tempTask.odatPostProxyLegalEntity = `/bthr_legalentitieses(${this.taskService.mainUser.legalEntityId})`;
    }
    this.sharedApiTaskService
      .patchVerdeTaskList({ body: tempTask })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.taskService.getAllFollowUpTasks();
          this.taskService.getAllPersonalTasks();
          if (approved) {
            this.navigateBack();
          }
          //this.taskCompleted.emit();
        },
      });
    this.currentTask.bt_taskstatus = TaskStatus.Completed;
  }

  acceptTask(): void {
    const tempTask = this.mapPatchTask(this.currentTask);
    tempTask.bt_followupstatus = TaskFollowUpStatus.Acceptable;
    tempTask.bt_followedup = true;
    tempTask.bt_followupcomments = this.followUpComment;
    this.sharedApiTaskService
      .patchVerdeTaskList({ body: tempTask })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.taskService.getAllFollowUpTasks();
          this.taskService.getAllPersonalTasks();
        },
      });
  }

  rejectTask(): void {
    const tempTask = this.mapPatchTask(this.currentTask);
    tempTask.bt_followupstatus = TaskFollowUpStatus.Unacceptable;
    tempTask.bt_followedup = true;
    tempTask.bt_followupcomments = this.followUpComment;
    this.sharedApiTaskService
      .patchVerdeTaskList({ body: tempTask })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.taskService.getAllFollowUpTasks();
          this.taskService.getAllPersonalTasks();
        },
      });
  }

  onFileChanged(event: any): void {
    this.submitDisabled = true;
    const uploadedFile: File = event.target.files[0];
    this.noteFileName = uploadedFile.name;
    this.noteMimeType = uploadedFile.type;
    this.compressService.compressBase64Image(uploadedFile, 50, 50).then((image) => {
      this.noteFile = image;
      this.submitDisabled = false;
    });
  }

  startTask(): void {
    if (this.currentTask.bt_taskstatus < TaskStatus.InProgress && !this.followUpTasks) {
      const tempTask = this.mapPatchTask(this.currentTask);
      tempTask.bt_taskstatus = TaskStatus.InProgress;
      tempTask.bt_startdate = moment().format('YYYY-MM-DD');
      tempTask.odatPostAssignedTo = `/bthr_employees(${this.taskService.proxyUser.employeeId})`;
      tempTask.odatPostAssignedToLegalEntity = `/bthr_legalentitieses(${this.taskService.proxyUser.legalEntityId})`;
      if (this.taskService.isProxyUser) {
        tempTask.bt_proxy = true;
        tempTask.odatPostProxyEmployee = `/bthr_employees(${this.taskService.mainUser.employeeId})`;
        tempTask.odatPostProxyLegalEntity = `/bthr_legalentitieses(${this.taskService.mainUser.legalEntityId})`;
      }
      this.sharedApiTaskService
        .getVerdeTasksListStatusById({ taskID: tempTask.bt_verdetasklistid })
        .pipe(
          mergeMap((task) => (task.bt_taskstatus < TaskStatus.InProgress ? this.sharedApiTaskService.patchVerdeTaskList({ body: tempTask }) : of(null))),
          tap((val) => {
            if (!val) {
              alert('Task already in progress');
              this.taskService.getAllPersonalTasks();
            }
          }),
          take(1),
        )
        .subscribe({
          next: () => {
            this.taskService.getAllFollowUpTasks();
            this.taskService.getAllPersonalTasks();
            this.navigateBack();
            //this.taskStarted.emit();
          },
          complete: () => {
            this.taskService.getAllFollowUpTasks();
            this.taskService.getAllPersonalTasks();
          },
        });
    }
  }

  saveNote(): void {
    this.savingNote = true;
    this.ngxSpinner.show(this.spinnerId);
    const annotation: Annotation = {
      isdocument: this.noteType === NoteType.File,
      subject: this.noteTitle || '',
      notetext: this.noteText || '',
      documentbody: this.noteFile || '',
      filename: this.noteFileName || '',
      mimetype: this.noteMimeType || '',
      objecttypecode: 'bt_verdetasklist',
      odatPostObjectID: `/bt_verdetasklists(${this.currentTask.bt_verdetasklistid})`,
    };

    this.sharedApiAnnotationService
      .postAnnotation({ body: annotation })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.resetForm(true);
          this.ngxSpinner.hide(this.spinnerId);
          this.savingNote = false;
          this.currentNotes.push({
            ...annotation,
            isdocument: annotation.documentbody !== '',
            createdon: moment(Date.now()).format(),
          });
          this.taskService.getAllPersonalTasks();
        },
        complete: () => this.ngxSpinner.hide(this.spinnerId),
      });
  }

  handleState(close: boolean): void {
    if (close) {
      this.followUpComment = '';
    }
  }

  resetForm(close: boolean): void {
    if (close) {
      this.noteFileName = '';
      this.noteFile = '';
      this.noteText = '';
      this.noteTitle = '';
      this.noteMimeType = '';
    }
  }

  setTaskToComplete(approved: boolean): void {
    if (approved) {
      this.completeTask(TaskStatus.Closed, true);
    }
  }

  approvalModalClosed(success: boolean): void {
    this.showFleetApprovalModal = false;
    if (success) {
      this.completeTask(TaskStatus.Closed, true);
    }
  }

  assignModalClosed(success: boolean): void {
    this.showAssignModal = false;
    if (success) {
      this.completeTask();
    }
  }

  confirmationModalClosed(success: boolean): void {
    this.showConfirmationModal = false;
    if (success) {
      this.completeTask();
    }
  }

  licenseModalClosed(success: boolean): void {
    this.showLicenseModal = false;
    if (success) {
      this.completeTask();
    }
  }

  private handleFleetItemTask(): void {
    this.referenceGuid = this.currentTask.bt_referenceguid;
    if (this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.OutstandingFleetItemLicense) {
      this.showLicenseModal = true;
    } else if (
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.FleetManagerApprove ||
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.FromFleetManagerApprove
    ) {
      this.showFleetApprovalModal = true;
    } else if (
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.FleetManagerCompleteChecklist ||
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.FromFleetManagerCompleteChecklist ||
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.ToFleetManagerCompleteChecklist ||
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.FromResponderCompleteChecklist ||
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.ToResponderCompleteChecklist ||
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.ResponderCompleteMonthlyChecklist ||
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.FleetManagerCompleteMonthlyChecklist ||
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.MonthlyChecklist ||
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.MonthlyVehicleCheckList
    ) {
      this.sharedApiFleetMovementService
        .getFleetChecklistById({ checklistID: this.currentTask.bt_referenceguid })
        .pipe(take(1))
        .subscribe({
          next: (checklists) => {
            console.error(checklists);
            this.fleetNavService.setAllChecklistsAndNav(checklists, false, false, true, true, true, BackUrl.Tasks);
          },
        });
    } else if (
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.FromFleetManagerCompleteConfirmation ||
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.FromResponderCompleteConfirmation
    ) {
      this.confirmationType = 'From';
      this.showConfirmationModal = true;
    } else if (
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.ToFleetManagerCompleteConfirmation ||
      this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.ToResponderCompleteConfirmation
    ) {
      this.confirmationType = 'To';
      this.showConfirmationModal = true;
    } else if (this.currentTask.odataTaskTemplate === TaskFleetItemTemplates.FleetManagerMustAssign) {
      this.showAssignModal = true;
    }
  }

  private mapPatchTask(task: VerdeTaskCustom): VerdeTask {
    return {
      bt_approval: task.bt_approval,
      bt_completeddate: task.bt_completeddate,
      bt_priority: task.bt_priority,
      bt_proxy: task.bt_proxy,
      bt_referencecolumn: task.bt_referencecolumn,
      bt_referenceguid: task.bt_referenceguid,
      bt_referencetable: task.bt_referencetable,
      bt_startdate: task.bt_startdate,
      bt_taskdescription: task.bt_taskdescription,
      bt_taskheader: task.bt_taskheader,
      bt_tasksource: task.bt_tasksource,
      bt_taskstatus: task.bt_taskstatus,
      bt_verdetasklistid: task.bt_verdetasklistid,
    };
  }

  private initNotes(): void {
    this.taskService.activeAnnotations.pipe(takeUntil(this.onDestroy$)).subscribe((notes) => (this.currentNotes = notes));
  }

  private initCurrentTask(): void {
    this.sidebarService
      .getSelectedTask()
      .pipe(
        tap((params) => (this.selectedTaskListId = params)),
        takeUntil(this.onDestroy$),
      )
      .subscribe({
        next: () => {
          this.loadCurrentTask(this.selectedTaskListId);
        },
      });
  }

  private loadCurrentTask(taskId: string): void {
    this.sharedApiTaskService
      .getVerdeTasksListStatusById({ taskID: taskId })
      .pipe(take(1))
      .subscribe({
        next: (task) => {
          this.currentTask = task;
        },
      });
  }
}
