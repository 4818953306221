/* tslint:disable */
/* eslint-disable */
export interface DriversLicenseCode {
  _bt_code_value?: null | string;
  _bt_country_value?: null | string;
  _bt_driverslicense_value?: null | string;
  _bt_restriction_value?: null | string;
  bt_driverslicensecodeid?: null | string;
  bt_driverslicensecodes?: null | string;
  bt_firstissuedate?: null | string;
  bt_idnumber?: null | string;
  bt_personalrestrictions?: null | string;
  odataCode?: null | string;
  odataCountry?: null | string;
  odataDriversLicense?: null | string;
  odataFirstIssue?: null | string;
  odataPersonalRestrictions?: null | string;
  odataPostCode?: null | string;
  odataPostCountry?: null | string;
  odataPostDriversLicense?: null | string;
  odataPostRestriction?: null | string;
  odataRestriction?: null | string;
}
