import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import {
  DelegationsApiDelegationsService,
  ProcessesApiProcessesService,
  ProcessesCitizenshipStatus,
  ProcessesGetLocalResidencyStatusQuery,
  ProcessesLocalResidencyStatus,
  ProcessesPositionSeats,
  ProcessesProcessCountry,
  ProcessesProcessesEmployee,
  ProcessesProcessNationality,
  ProcessesValidateIdNumberQuery,
} from '@verde/api';
import { RefreshService } from '@verde/shared';
import { saveAs } from 'file-saver';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'verde-onboarding-nationality-edit',
  templateUrl: './onboarding-nationality-edit.component.html',
  styleUrls: ['./onboarding-nationality-edit.component.scss'],
})
export class OnboardingNationalityEditComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  @Input() public personalDetails: FormGroup | null = null;
  @Input() nationalities: ProcessesProcessNationality[] | null = null;
  @Input() countries: ProcessesProcessCountry[] | null = null;
  @Input() citizenStatus: ProcessesCitizenshipStatus[] | null = null;
  @Input() localResidencyStatus: ProcessesLocalResidencyStatus[] | null = null;
  @Input() testCountry: any[] | null = null;
  @Input() positionSeat: ProcessesPositionSeats | null = null;
  @Input() validateYesNo: boolean | null = null;
  @Input() validationMessage: string | null = null;
  @Output() selectedCountryEvent = new EventEmitter<string>();
  @Input() onboardingEmployee: ProcessesProcessesEmployee;
  @Output() customEvent = new EventEmitter<boolean>();

  public isReadOnly: boolean = true;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['jpg', 'jpeg', 'png', 'pdf'],
  };

  defaultCountryID: string | null = null;
  CountryObj: ProcessesProcessCountry | null = null;
  localResidencyStatusObj: ProcessesLocalResidencyStatus | null = null;
  nationalitiesObj: ProcessesProcessNationality | null = null;
  citizenStatusObj: ProcessesCitizenshipStatus | null = null;
  loadingLocalResidencyStatus: boolean = false;
  loadingCitizenshipStatus: boolean = false;
  validateEnabled: boolean = true;
  selectedCountry: ProcessesProcessCountry;
  selectedNationality: ProcessesProcessNationality;
  countriesData: ProcessesProcessCountry[] | null = null;

  docBase64: any;

  constructor(
    private processesApiProcessesService: ProcessesApiProcessesService,
    private refreshService: RefreshService,
    private delegationApi: DelegationsApiDelegationsService,
  ) {}

  ngOnInit(): void {
    this.countriesData = this.countries.slice();
    if (!this.personalDetails) {
      return;
    }

    this.personalDetails
      .get('primaryNationality')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.updateForeignNationalRadio();
      });

    this.personalDetails
      .get('citizenshipStatus')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.updateForeignNationalRadio();
      });

    this.personalDetails
      .get('countryResidence')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.updateForeignNationalRadio();
      });

    this.updateForeignNationalRadio();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  public onIDChange(value: string): void {
    if (value != this.positionSeat.bthr_Employee.bthr_nationalidnumber) {
      this.validateEnabled = false;
    } else this.validateEnabled = true;
  }

  updateForeignNationalRadio() {
    const nationalityControl = this.personalDetails?.get('primaryNationality');
    const foreignNationalControl = this.personalDetails?.get('foreignNational');

    if (foreignNationalControl && nationalityControl) {
      const nationality = this.nationalities.find((nationality) => nationality.bthr_nationalityid === nationalityControl.value?.bthr_nationalityid);
      const foreignNationalValue = this.calculateForeignNationalValue(nationality);

      foreignNationalControl?.setValue(foreignNationalValue);
    }
  }

  calculateForeignNationalValue(nationality) {
    if (nationality.bt_country.primaryName === this.positionSeat.bt_legalentityCountryText) {
      this.customEvent.emit(false);
      return 'no';
    } else {
      this.customEvent.emit(true);
      return 'yes';
    }
  }

  residencyChange(residencyChange) {
    if (residencyChange) {
      this.validateYesNo = residencyChange.bt_validateid;
    }
  }

  validateIdNumber() {
    if (!this.personalDetails) {
      return;
    }

    const countryName = this.personalDetails.value.countryResidence?.bthr_country;
    const idNumber = this.personalDetails.value.idNumber;

    if (countryName) {
      const validation: ProcessesValidateIdNumberQuery = {
        countryName: countryName,
        idNumber: idNumber,
        validateId: this.validateYesNo || false,
      };

      this.processesApiProcessesService
        .validateIdNumber({ body: validation })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (val) => {
            this.validationMessage = val;
          },
          () => {},
        );
    }
  }

  nationalityChange(nationality) {
    if (nationality) {
      this.selectedNationality = nationality;
      this.refreshService.updateSharedValue(nationality.bthr_nationality);
      this.personalDetails?.get('citizenshipStatus')?.setValue(null);
      this.loadingCitizenshipStatus = true;
      this.getCitizenShipStatusUpdate();
    }
  }

  countryChange(country) {
    this.selectedCountry = country;
    if (!this.personalDetails) {
      return;
    }
    this.loadingLocalResidencyStatus = true;
    const body: ProcessesGetLocalResidencyStatusQuery = {
      countryID: country?.bthr_countryid,
    };

    this.personalDetails.get('localResidency').setValue(null);

    this.processesApiProcessesService
      .getLocalResidencyStatus({ body: body })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.localResidencyStatus = val;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.localResidencyStatusObj = this.localResidencyStatus?.find(
            (c) => c.bt_localresidencystatus === String(this.positionSeat?.bthr_Employee?.bt_localresidencystatus?.primaryName),
          );
          if (this.localResidencyStatusObj) {
            this.personalDetails.get('localResidency').setValue(this.localResidencyStatusObj);
            this.validateYesNo = this.localResidencyStatusObj.bt_validateid;
          }
          this.loadingLocalResidencyStatus = false;
        },
      );
  }

  onFileSelect(event: any): void {
    const file = event.files[0];
    if (file && this.personalDetails) {
      this.personalDetails.get('personalDetails.idUpload').setValue(file);
    }
  }

  getCitizenShipStatusUpdate() {
    this.processesApiProcessesService
      .getCitizenshipStatus({ body: { nationalityID: this.selectedNationality.bthr_nationalityid } })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.citizenStatus = val;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.loadingCitizenshipStatus = false;
        },
      );
  }

  handleCountryFilter(value) {
    this.countriesData = this.countries.filter((s) => s.bthr_country.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  donwloadDoc() {
    if (this.onboardingEmployee.employeeID) {
      this.delegationApi
        .downloadFile({
          body: { columnName: 'bthr_nationalidpassport', entityName: 'bthr_employee', recordID: this.onboardingEmployee.employeeID },
        })
        .pipe(take(1))
        .subscribe(
          (val) => {
            this.docBase64 = val;
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.downloadFile(this.docBase64);
          },
        );
    }
  }

  //convert to blob
  downloadFile(base64String: string) {
    const mimeType = this.getMimeType(this.onboardingEmployee.bthr_nationalidpassport_Name);
    const binaryData = atob(base64String);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: mimeType });
    saveAs(blob, this.onboardingEmployee.bthr_nationalidpassport_Name);
  }

  getMimeType(fileName: string): string {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'application/pdf';
      case 'jpeg':
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      default:
        throw new Error('Unsupported file type');
    }
  }
}
