/* tslint:disable */
/* eslint-disable */
export interface BtTaxPeriod {
  _bt_taxyear_value?: null | string;
  bt_periodend?: null | string;
  bt_periodno?: number;
  bt_periodstart?: null | string;
  bt_taxyearperiod?: null | string;
  bt_taxyearperiodid?: null | string;
}
