/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface AllTimeTrackingAcessEntries {
  _bt_activityentity_value?: null | string;
  _bt_employee_value?: null | string;
  _bt_employeelegalentity_value?: null | string;
  _bt_group_value?: null | string;
  _bt_timeentryactivity_value?: null | string;
  bt_Employee?: User;
  bt_timeactivityemployeeno?: null | string;
  bt_verdetimeactivityemployeeid?: null | string;
  odataEmployeeLegalEntity?: null | string;
  odataPostActivityEntity?: null | string;
  odataPostbt_Employee?: null | string;
  odataTimeEntryActivity?: null | string;
  odataTimeEntryGroup?: null | string;
  odata_bt_activityentity_value?: null | string;
  odata_bt_employee_value?: null | string;
  odata_bt_employeelegalentity_value?: null | string;
  odata_bt_group_value?: null | string;
  odata_bt_timeentryactivity_value?: null | string;
  statecode?: null | number;
  statuscode?: null | number;
}
