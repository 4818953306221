/* tslint:disable */
/* eslint-disable */
import { EquipmentTakeOnControl } from './equipment-take-on-control';
import { User } from './user';
export interface EquipmentTakeOn {
  _bt_employee_value?: null | string;
  _bt_equipmenttype_value?: null | string;
  _bt_group_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_make_value?: null | string;
  _bt_takeoncontrol_value?: null | string;
  bt_Employee?: User;
  bt_TakeOnControl?: EquipmentTakeOnControl;
  bt_assetno?: null | string;
  bt_classification?: null | number;
  bt_condition?: null | number;
  bt_conditioncomment?: null | string;
  bt_deletereason?: null | string;
  bt_devicename?: null | string;
  bt_equipmentdescription?: null | string;
  bt_equipmentphoto?: null | string;
  bt_equipmentphotoname?: null | string;
  bt_equipmentphotosize?: null | number;
  bt_equipmenttakeonid?: null | string;
  bt_equipmenttakeonno?: null | string;
  bt_equipmenttypeother?: null | string;
  bt_makeother?: null | string;
  bt_model?: null | string;
  bt_quantity?: null | number;
  bt_receivedas?: null | boolean;
  bt_receiveddate?: null | string;
  bt_serialnumber?: null | string;
  bt_serialnumberphoto?: null | string;
  bt_serialnumberphotoname?: null | string;
  bt_serialnumberphotosize?: null | number;
  odataClassification?: null | string;
  odataCondition?: null | string;
  odataEmployee?: null | string;
  odataEquipmentType?: null | string;
  odataGroup?: null | string;
  odataLegalEntity?: null | string;
  odataMake?: null | string;
  odataPostEmployee?: null | string;
  odataPostEquipmentType?: null | string;
  odataPostGroup?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostMake?: null | string;
  odataPostTakeOnControl?: null | string;
  odataReceivedAs?: null | string;
  odataTakeOnControl?: null | string;
  statecode?: null | number;
}
