import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HealthRiskActions, HealthRiskActionTypes } from '../actions/healthrisk.actions';
import * as fromDashboardRelatedReducer from './dashboard-related.reducer';

// Models
import { DvRiskManagement } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type HealthRiskState = EntityState<DvRiskManagement>;

export interface HealthRiskRelatedState {
  healthRiskState: HealthRiskState;
  loading: boolean;
  error: any;
}

export const healthRiskKeys = ['bt_employeevaccinationsummaryid'];
export const healthRiskStateAdapter: EntityAdapter<DvRiskManagement> = createEntityAdapter<DvRiskManagement>({
  selectId: (data) => keyHandler(data, healthRiskKeys),
});

export const initialHealthRiskState: HealthRiskState = healthRiskStateAdapter.getInitialState({});

export const initialHealthRiskRelatedState = {
  healthRiskState: initialHealthRiskState,
  loading: false,
  error: {},
};

export function healthRiskStateRelatedReducer(state = initialHealthRiskRelatedState, action: HealthRiskActions): HealthRiskRelatedState {
  switch (action.type) {
    // Get All HealthRisk
    case HealthRiskActionTypes.HEALTHRISK_GET_ALL:
      return { ...state, loading: true };
    case HealthRiskActionTypes.HEALTHRISK_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        healthRiskState: healthRiskStateAdapter.setAll(action.payload, state.healthRiskState),
      };
    }
    case HealthRiskActionTypes.HEALTHRISK_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Clear
    case HealthRiskActionTypes.HEALTHRISK_CLEAR:
      return { ...initialHealthRiskRelatedState };
    default:
      return state;
  }
}

export const getHomeRelatedState = createFeatureSelector<fromDashboardRelatedReducer.DashboardRelatedState>('dashboardRelatedReducer');

export const getHealthRiskRelatedState = createSelector(
  getHomeRelatedState,
  (state: fromDashboardRelatedReducer.DashboardRelatedState) => state.healthRiskState,
);

// Current HealthRisk
export const getHealthRiskState = createSelector(getHealthRiskRelatedState, (state) => state.healthRiskState);
export const { selectAll: getHealthRisk } = healthRiskStateAdapter.getSelectors(getHealthRiskState);

// Loading
export const isLoading = createSelector(getHealthRiskRelatedState, (state) => state.loading);

// Error
export const getError = createSelector(getHealthRiskRelatedState, (state) => state.error);
