/* tslint:disable */
/* eslint-disable */
export enum ProfileBtDynamicRule {
  None = 'None',
  Email = 'email',
  PhoneNumber = 'PhoneNumber',
  CustomPatern = 'CustomPatern',
  MinandMax = 'MinandMax',
  Min = 'Min',
  Max = 'Max',
}
