/* tslint:disable */
/* eslint-disable */
export enum TasksBtTaskSource {
  ReviewGroup = 'ReviewGroup',
  FleetItem = 'FleetItem',
  Request = 'Request',
  TimeoffApproval = 'TimeoffApproval',
  HrTimeoffApproval = 'HRTimeoffApproval',
  Role = 'Role',
  IssueReported = 'IssueReported',
  Policy692360007 = 'Policy_692360007',
  Booking = 'Booking',
  Equipment = 'Equipment',
  TemplatesFleet = 'TemplatesFleet',
  Policy692360011 = 'Policy_692360011',
  Manual = 'Manual',
  Approval = 'Approval',
  TimeTracking = 'TimeTracking',
  VerdeChecklist = 'VerdeChecklist',
}
