/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvIdentityDocuments } from '../models/dv-identity-documents';
import { DvIdentityDocumentsPost } from '../models/dv-identity-documents-post';

@Injectable({
  providedIn: 'root',
})
export class SharedApiIdentityDocumentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllIdentityDocuments
   */
  static readonly GetAllIdentityDocumentsPath = '/api/IdentityDocuments/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllIdentityDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllIdentityDocuments$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvIdentityDocuments>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiIdentityDocumentsService.GetAllIdentityDocumentsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvIdentityDocuments>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllIdentityDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllIdentityDocuments(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<DvIdentityDocuments>> {
    return this.getAllIdentityDocuments$Response(params).pipe(map((r: StrictHttpResponse<Array<DvIdentityDocuments>>) => r.body as Array<DvIdentityDocuments>));
  }

  /**
   * Path part for operation patchIdentityDocuments
   */
  static readonly PatchIdentityDocumentsPath = '/api/IdentityDocuments/patch/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchIdentityDocuments()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchIdentityDocuments$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvIdentityDocumentsPost;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiIdentityDocumentsService.PatchIdentityDocumentsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchIdentityDocuments$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchIdentityDocuments(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: DvIdentityDocumentsPost }): Observable<boolean> {
    return this.patchIdentityDocuments$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postIdentityDocuments
   */
  static readonly PostIdentityDocumentsPath = '/api/IdentityDocuments/post/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postIdentityDocuments()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postIdentityDocuments$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvIdentityDocumentsPost;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiIdentityDocumentsService.PostIdentityDocumentsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postIdentityDocuments$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postIdentityDocuments(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: DvIdentityDocumentsPost }): Observable<boolean> {
    return this.postIdentityDocuments$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
