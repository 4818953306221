import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TemplateBtResponseType, TemplateBtTemplateType, TemplateTemplateMeasure } from '@verde/api';
import { ModalService } from '@verde/shared';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { RefreshServiceService } from '../../../../../../../../apps/verde/src/app/features/profile2/components/templates/services/refresh-service.service';
import { MeasureService } from '../../../../../../../../apps/verde/src/app/features/profile2/services/measure.service';
import { VerdeApprovalService } from '../../services/verde-approval.service';

@Component({
  selector: 'verde-template-measure-add',
  templateUrl: './template-measure-add.component.html',
  styleUrls: ['./template-measure-add.component.scss'],
})
export class TemplateMeasureAddComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  imageRequired = [
    {
      text: 'Yes',
      value: 'Yes',
    },
    {
      text: 'No',
      value: 'No',
    },
  ];

  allowImage = [
    {
      text: 'Yes',
      value: 'Yes',
    },
    {
      text: 'No',
      value: 'No',
    },
  ];

  responseType: { text: string; value: string }[] = [];
  selectedResponseType: string;
  legalEntityID: string;
  legalEntityName: string;
  tempType: string;
  dropdownOptions: string[] = [];
  newOption: string;
  measureForm: FormGroup;
  formValues: any;
  postDropDownOption: any;
  visited: boolean;
  private methodCallSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private measureService: MeasureService,
    private sidebarService: VerdeApprovalService,
    private modalService: ModalService,
    private refreshServiceService: RefreshServiceService,
  ) {
    this.methodCallSubscription = this.refreshServiceService.methodCall$.subscribe(() => {
      if (this.visited === true) {
        this.loadPage();
      }
    });
  }

  ngOnInit(): void {
    this.visited = true;
    this.loadPage;
  }

  loadPage() {
    this.sidebarService
      .getSelectedLegalEntityID()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((id: any) => {
        this.legalEntityID = id;
      });
    this.sidebarService
      .getSelectedLegalEntityName()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((name: any) => {
        this.legalEntityName = name;
      });
    this.sidebarService
      .getSelectedTemplateType()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((type: any) => {
        this.tempType = type;
      });

    if (this.responseType.length < 1) {
      for (const key in TemplateBtResponseType) {
        if (TemplateBtResponseType.hasOwnProperty(key)) {
          const text = key;
          const value = TemplateBtResponseType[key];
          this.responseType.push({ text, value });
        }
      }
    }

    this.measureForm = this.formBuilder.group({
      legalEntity: [this.legalEntityName],
      templateType: [this.tempType],
      measure: ['', Validators.required],
      sorting: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      imageRequired: [, Validators.required],
      allowImage: [, Validators.required],
      responseType: [, Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  imageRequiredDropdown(e: any) {}

  allowImageDropdown(e: any) {}

  responseTypeDropdown(e: any) {}

  measureGroupDropdown(e: any) {}

  measureSubGroupDropdown(e: any) {}

  onResponseTypeChange(responseType: any): void {
    this.selectedResponseType = responseType.text;
    // Clear the options when the dropdown value changes
    this.dropdownOptions = [];
  }

  addOption(): void {
    if (this.newOption) {
      this.dropdownOptions.push(this.newOption);
      this.newOption = ''; // Clear the textbox
    }
  }

  clearOptions(): void {
    this.dropdownOptions = [];
  }

  groupsDropdown(e: any) {}

  subgroupsDropdown(e: any) {}

  addMeasure() {
    const formValues = this.measureForm.value;
    if (this.measureForm.valid) {
      this.formValues = formValues;
      this.modalService.open('confirmMeasure');
    } else {
    }

    console.log(this.dropdownOptions);
  }

  confirm() {
    if (this.dropdownOptions != null) {
      this.postDropDownOption = this.dropdownOptions.join(', ');
    }

    var numberValue = Number(this.formValues.sorting);
    let imgReq = true;
    if (this.formValues.imageRequired.value === 'Yes') {
      imgReq = true;
    } else if (this.formValues.imageRequired.value === 'No') {
      imgReq = false;
    }
    let allowImg = true;
    if (this.formValues.allowImage.value === 'Yes') {
      allowImg = true;
    } else if (this.formValues.allowImage.value === 'No') {
      allowImg = false;
    }

    let postTempType: TemplateBtTemplateType | undefined;
    if (this.tempType === TemplateBtTemplateType.PeerReview) {
      postTempType = TemplateBtTemplateType.PeerReview;
    } else if (this.tempType === TemplateBtTemplateType.PerformanceReview) {
      postTempType = TemplateBtTemplateType.PerformanceReview;
    } else if (this.tempType === TemplateBtTemplateType.Fleet) {
      postTempType = TemplateBtTemplateType.Fleet;
    } else if (this.tempType === TemplateBtTemplateType.Equipment) {
      postTempType = TemplateBtTemplateType.Equipment;
    }

    if (this.postDropDownOption === null) {
      let body: TemplateTemplateMeasure = {
        bt_name: this.formValues.measure,
        bt_measure: this.formValues.measure,
        bt_imagerequired: imgReq,
        bt_allowimage: allowImg,
        bt_sorting: numberValue,
        bt_templatetype: postTempType,
        bt_responsetype: this.formValues.responseType.value,
        bt_dropdownresponselist: this.postDropDownOption,
      };
      this.measureService.uploadMeasures({ templateMeasureBody: body });
    } else {
      let body: TemplateTemplateMeasure = {
        bt_name: this.formValues.measure,
        bt_measure: this.formValues.measure,
        bt_imagerequired: imgReq,
        bt_allowimage: allowImg,
        bt_sorting: numberValue,
        bt_templatetype: postTempType,
        bt_responsetype: this.formValues.responseType.value,
      };
      this.measureService.uploadMeasures({ templateMeasureBody: body });
    }

    this.sidebarService.setShowSidebar(false);

    this.resetForm();
  }

  resetForm(): void {
    const legalEntityValue = this.measureForm.get('legalEntity')?.value;
    const templateTypeValue = this.measureForm.get('templateType')?.value;
    this.measureForm.reset();
    this.measureForm.patchValue({
      legalEntity: legalEntityValue,
      templateType: templateTypeValue,
    });
    this.refresh();
  }

  refresh() {
    this.refreshServiceService.refreshView();
  }
}
