import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Annotation, SharedApiAnnotationService, UserUserDto } from '@verde/api';
import { UserService } from '@verde/core';
import { VerdeTaskCustom } from '@verde/shared';
import { Observable, ReplaySubject, Subject, take } from 'rxjs';
import { TaskTabs } from '../enums/task-tabs-enum';
import * as TaskClosedActions from '../store/actions/task-closed.actions';
import * as TaskFollowUpActions from '../store/actions/task-follow-up.actions';
import * as TaskPersonalActions from '../store/actions/task-personal.actions';
import * as fromTaskClosedReducer from '../store/reducers/task-closed.reducer';
import * as fromTaskFollowUpReducer from '../store/reducers/task-follow-up.reducer';
import * as fromTaskPersonalReducer from '../store/reducers/task-personal.reducer';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  onDestroy$ = new Subject<boolean>();
  closedTasks$: Observable<VerdeTaskCustom[]>;
  closedTasksLoading$: Observable<boolean>;
  followUpTasks$: Observable<VerdeTaskCustom[]>;
  followUpTasksLoading$: Observable<boolean>;
  personalTasks$: Observable<VerdeTaskCustom[]>;
  personalTasksLoading$: Observable<boolean>;
  activeAnnotations: ReplaySubject<Array<Annotation>> = new ReplaySubject<Array<Annotation>>(1);
  annotationLoading: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  currentSelectedTask: VerdeTaskCustom;

  activeTab: TaskTabs = TaskTabs.PersonalTasks;
  isProxyUser: boolean;
  mainUser: UserUserDto;
  proxyUser: UserUserDto;
  isTaskPersonalPollingActive = false;
  personalTaskPollingUser: UserUserDto;
  isTaskClosedPollingActive = false;
  closedTaskPollingUser: UserUserDto;
  isTaskFollowUpPollingActive = false;
  followUpPollingUser: UserUserDto;
  pollingTimer = 20000;

  constructor(
    private store: Store<fromTaskPersonalReducer.TaskPersonalState>,
    private userService: UserService,
    private sharedApiAnnotationService: SharedApiAnnotationService,
  ) {
    this.closedTasksLoading$ = this.store.pipe(select(fromTaskClosedReducer.isTaskClosedLoading));
    this.closedTasks$ = this.store.pipe(select(fromTaskClosedReducer.getAllClosedTasks));
    this.followUpTasksLoading$ = this.store.pipe(select(fromTaskFollowUpReducer.isTaskFollowUpLoading));
    this.followUpTasks$ = this.store.pipe(select(fromTaskFollowUpReducer.getAllFollowUpTasks));
    this.personalTasksLoading$ = this.store.pipe(select(fromTaskPersonalReducer.isTaskPersonalLoading));
    this.personalTasks$ = this.store.pipe(select(fromTaskPersonalReducer.getAllPersonalTasks));
    this.mainUser = this.userService.user;
    this.proxyUser = this.userService.user;
  }

  ngOnDestroy(): void {
    this.stopAllClosedTaskPolling();
    this.stopAllFollowUpTaskPolling();
    this.stopAllPersonalTaskPolling();
  }

  // Get

  getAllPersonalTasks() {
    this.store.dispatch(new TaskPersonalActions.TaskPersonalGetAll(this.proxyUser));
  }

  stopAllPersonalTaskPolling() {
    this.store.dispatch(new TaskPersonalActions.TaskPersonalGetAllStop(this.proxyUser));
  }

  getAllFollowUpTasks() {
    this.store.dispatch(new TaskFollowUpActions.TaskFollowUpGetAll(this.proxyUser));
  }

  stopAllFollowUpTaskPolling() {
    this.store.dispatch(new TaskFollowUpActions.TaskFollowUpGetAllStop(this.proxyUser));
  }

  getAllClosedTasks() {
    this.store.dispatch(new TaskClosedActions.TaskClosedGetAll(this.proxyUser));
  }

  stopAllClosedTaskPolling() {
    this.store.dispatch(new TaskClosedActions.TaskClosedGetAllStop(this.proxyUser));
  }

  getAllTasks() {
    if (this.activeTab === TaskTabs.PersonalTasks) {
      this.getAllPersonalTasks();
    } else if (this.activeTab === TaskTabs.FollowUp) {
      this.getAllFollowUpTasks();
    } else if (this.activeTab === TaskTabs.Closed) {
      this.getAllClosedTasks();
    }
  }

  loadTaskNotes(task: VerdeTaskCustom): void {
    this.annotationLoading.next(true);
    this.sharedApiAnnotationService
      .getAnnotationsByObjectId({ objectID: task.bt_verdetasklistid })
      .pipe(take(1))
      .subscribe({
        next: (notes) => this.activeAnnotations.next(notes),
        error: () => this.annotationLoading.next(false),
        complete: () => this.annotationLoading.next(false),
      });
  }

  // Clear

  clearAllPersonalTasks() {
    this.store.dispatch(new TaskPersonalActions.TaskPersonalClear(null));
  }

  clearAllFollowUpTasks() {
    this.store.dispatch(new TaskPersonalActions.TaskPersonalClear(null));
  }

  clearAllTasks() {
    this.clearAllFollowUpTasks();
    this.clearAllPersonalTasks();
  }

  // User

  setProxyUser(user: UserUserDto) {
    this.proxyUser = user;
    if (this.proxyUser.employeeId === this.mainUser.employeeId) {
      this.isProxyUser = false;
    } else {
      this.isProxyUser = true;
    }
    this.getAllTasks();
  }
}
