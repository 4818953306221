/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface VehiclesFiltered {
  _bt_checkliststype_value?: null | string;
  _bt_createdby_value?: null | string;
  _bt_employees_value?: null | string;
  _bt_fleetrental_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_location_value?: null | string;
  _bt_newemployee_value?: null | string;
  _bt_newlocation_value?: null | string;
  _bt_nextactionlegalentity_value?: null | string;
  _bt_nextstepemployee_value?: null | string;
  _bt_pooldepartment_value?: null | string;
  _bt_poollocation_value?: null | string;
  _bt_rentalsupplier_value?: null | string;
  _bt_supplier_value?: null | string;
  _bt_trackersupplier_value?: null | string;
  _bthr_asset_value?: null | string;
  _bthr_assetclass_value?: null | string;
  _bthr_department_value?: null | string;
  _bthr_division_value?: null | string;
  _bthr_employee_value?: null | string;
  _bthr_inspectionperformedby_value?: null | string;
  _bthr_make_value?: null | string;
  _bthr_model_value?: null | string;
  bt_NextStepEmployee?: User;
  bt_acccessoryvalue?: number;
  bt_acccessoryvalue_state?: null | number;
  bt_alternateserviceunit?: null | string;
  bt_alternatserviceinterval?: null | string;
  bt_assetno?: null | string;
  bt_calculateddaystillnextservice?: null | number;
  bt_commissionstatus?: null | number;
  bt_countofmovementscompleted?: null | number;
  bt_countofmovementsnotcompleted?: null | number;
  bt_departmental?: null | boolean;
  bt_driverlicensecode?: null | string;
  bt_enginenumber?: null | string;
  bt_fleetcardrequired?: null | boolean;
  bt_fleetmovementstatus?: null | number;
  bt_fleetno?: null | string;
  bt_invoicenumber?: null | string;
  bt_itemtype?: null | number;
  bt_lastcommissiondate?: null | string;
  bt_lastmonthlychecklist?: null | string;
  bt_lastserviceodometerreading?: null | string;
  bt_licenseexpirystatus?: null | string;
  bt_maintenanceplan?: null | boolean;
  bt_maintenanceplandocname?: null | string;
  bt_maintenanceplandocument?: null | string;
  bt_modelyear?: null | string;
  bt_monthlyprocessstatus?: null | number;
  bt_nextserviceodo?: null | number;
  bt_nextstep?: null | string;
  bt_odorequired?: null | boolean;
  bt_podate?: null | string;
  bt_ponumber?: null | string;
  bt_povalue?: null | string;
  bt_purchaseprice?: null | number;
  bt_refno?: null | string;
  bt_registrationdocument?: null | string;
  bt_registrationdocumentname?: null | string;
  bt_rentalcost?: null | string;
  bt_rentalend?: null | string;
  bt_rentalinvoice?: null | string;
  bt_rentalquote?: null | string;
  bt_rentalquoteamount?: null | string;
  bt_rentalquotename?: null | string;
  bt_rentalstart?: null | string;
  bt_scanlicense?: null | boolean;
  bt_servicestatus?: null | number;
  bt_serviceunit?: null | string;
  bt_statuscomment?: null | string;
  bt_statusdate?: null | string;
  bt_suppliername?: null | string;
  bt_tare?: null | string;
  bt_todaydate?: null | string;
  bt_totalvalue?: null | number;
  bt_trackingdevicerequired?: null | boolean;
  bt_trackingid?: null | string;
  bt_trackingprovider?: null | string;
  bt_vehiclestatus?: null | number;
  bt_vehicleunique?: null | string;
  bthr_Employee?: User;
  bthr_assetloaded?: null | boolean;
  bthr_assetnumber?: null | string;
  bthr_basiccolour?: null | string;
  bthr_chassisnumbervin?: null | string;
  bthr_classification?: null | number;
  bthr_companyasset?: null | boolean;
  bthr_depreciatedamount?: null | number;
  bthr_depreciateddate?: null | string;
  bthr_depreciationstartdate?: null | string;
  bthr_disposaldate?: null | string;
  bthr_disposaltype?: null | number;
  bthr_disposed?: null | boolean;
  bthr_etagnumber?: null | string;
  bthr_etagrequired?: null | boolean;
  bthr_expirydate?: null | string;
  bthr_fueltype?: null | number;
  bthr_inspectionstatus?: null | number;
  bthr_lastinspectiondate?: null | string;
  bthr_lastservicedate?: null | string;
  bthr_licensenumber?: null | string;
  bthr_maintenanceplan?: null | boolean;
  bthr_odometerreading?: null | number;
  bthr_purchasedate?: null | string;
  bthr_recordstatus?: null | number;
  bthr_regno?: null | string;
  bthr_serviceinterval?: null | string;
  bthr_serviceplan?: null | boolean;
  bthr_tankcapacityl?: null | number;
  bthr_transmissiontype?: null | number;
  bthr_vehiclephoto?: null | string;
  bthr_vehiclephotoid?: null | string;
  bthr_vehiclesid?: null | string;
  bthr_warrantyenddate?: null | string;
  bthr_warrantyperiodmonths?: null | string;
  bthr_warrantystartdate?: null | string;
  legalEntityID?: null | string;
  makeID?: null | string;
  modelID?: null | string;
  odataAsset?: null | string;
  odataAssetClass?: null | string;
  odataChecklistsType?: null | string;
  odataClassification?: null | string;
  odataCommissionStatus?: null | string;
  odataCompanyAsset?: null | string;
  odataCreatedBy?: null | string;
  odataDepartment?: null | string;
  odataDisposed?: null | string;
  odataDivision?: null | string;
  odataEmployee?: null | string;
  odataEmployees?: null | string;
  odataExpiryDate?: null | string;
  odataFleetMovementStatus?: null | string;
  odataFleetRental?: null | string;
  odataFuelType?: null | string;
  odataInspectionPerformedBy?: null | string;
  odataInspectionStatus?: null | string;
  odataLegalEntity?: null | string;
  odataLocation?: null | string;
  odataMake?: null | string;
  odataModel?: null | string;
  odataMonthlyProcessstatus?: null | string;
  odataNewEmployee?: null | string;
  odataNewLocation?: null | string;
  odataNextActionLegalEntity?: null | string;
  odataNextStepEmployee?: null | string;
  odataPoolDepartment?: null | string;
  odataPoolLocation?: null | string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostMake?: null | string;
  odataPostModel?: null | string;
  odataPurchaseDate?: null | string;
  odataPurchasePrice?: null | string;
  odataRecordStatus?: null | string;
  odataRentalSupplier?: null | string;
  odataServiceStatus?: null | string;
  odataSupplier?: null | string;
  odataTrackerSupplier?: null | string;
  odataTransmissionType?: null | string;
  odataVehicleStatus?: null | string;
}
