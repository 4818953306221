/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ColumnLabels } from '../models/column-labels';
import { CovidTest } from '../models/covid-test';
import { DailyAccess } from '../models/daily-access';
import { EmployeePresence } from '../models/employee-presence';
import { EmployeeStatus } from '../models/employee-status';
import { EmployeeStatusEngage } from '../models/employee-status-engage';
import { EmployeeStatusFiltered } from '../models/employee-status-filtered';
import { EmployeeSymptom } from '../models/employee-symptom';
import { EmployeeTracking } from '../models/employee-tracking';
import { Isolation } from '../models/isolation';
import { Locations } from '../models/locations';
import { NotificationTemplates } from '../models/notification-templates';
import { Offices } from '../models/offices';
import { QrCodeString } from '../models/qr-code-string';
import { Sites } from '../models/sites';
import { TrackAndTrace } from '../models/track-and-trace';
import { TriggerLog } from '../models/trigger-log';
import { WorkLocation } from '../models/work-location';

@Injectable({
  providedIn: 'root',
})
export class SharedApiEngageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getQrCode
   */
  static readonly GetQrCodePath = '/api/Engage/qrcode/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQrCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQrCode$Response(params: { subscriptionKey?: string; APIURL?: string; name: string }): Observable<StrictHttpResponse<QrCodeString>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetQrCodePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('name', params.name, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<QrCodeString>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQrCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQrCode(params: { subscriptionKey?: string; APIURL?: string; name: string }): Observable<QrCodeString> {
    return this.getQrCode$Response(params).pipe(map((r: StrictHttpResponse<QrCodeString>) => r.body as QrCodeString));
  }

  /**
   * Path part for operation getColumnLables
   */
  static readonly GetColumnLablesPath = '/api/Engage/columnlabels/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getColumnLables()` instead.
   *
   * This method doesn't expect any request body.
   */
  getColumnLables$Response(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<StrictHttpResponse<Array<ColumnLabels>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetColumnLablesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ColumnLabels>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getColumnLables$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getColumnLables(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<ColumnLabels>> {
    return this.getColumnLables$Response(params).pipe(map((r: StrictHttpResponse<Array<ColumnLabels>>) => r.body as Array<ColumnLabels>));
  }

  /**
   * Path part for operation getWorkLocation
   */
  static readonly GetWorkLocationPath = '/api/Engage/workoptions/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkLocation$Response(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<StrictHttpResponse<Array<WorkLocation>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetWorkLocationPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<WorkLocation>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWorkLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkLocation(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<WorkLocation>> {
    return this.getWorkLocation$Response(params).pipe(map((r: StrictHttpResponse<Array<WorkLocation>>) => r.body as Array<WorkLocation>));
  }

  /**
   * Path part for operation getUserStatus
   */
  static readonly GetUserStatusPath = '/api/Engage/user/status/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserStatus$Response(params: { subscriptionKey?: string; APIURL?: string; email: string }): Observable<StrictHttpResponse<Array<EmployeeStatus>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetUserStatusPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('email', params.email, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EmployeeStatus>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserStatus(params: { subscriptionKey?: string; APIURL?: string; email: string }): Observable<Array<EmployeeStatus>> {
    return this.getUserStatus$Response(params).pipe(map((r: StrictHttpResponse<Array<EmployeeStatus>>) => r.body as Array<EmployeeStatus>));
  }

  /**
   * Path part for operation getColumnLabelsExtended
   */
  static readonly GetColumnLabelsExtendedPath = '/api/Engage/columnlabels/extended/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getColumnLabelsExtended()` instead.
   *
   * This method doesn't expect any request body.
   */
  getColumnLabelsExtended$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<ColumnLabels>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetColumnLabelsExtendedPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ColumnLabels>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getColumnLabelsExtended$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getColumnLabelsExtended(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<ColumnLabels>> {
    return this.getColumnLabelsExtended$Response(params).pipe(map((r: StrictHttpResponse<Array<ColumnLabels>>) => r.body as Array<ColumnLabels>));
  }

  /**
   * Path part for operation getColumnLabelsExtendedCovid
   */
  static readonly GetColumnLabelsExtendedCovidPath = '/api/Engage/columnlabels/extended/covid/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getColumnLabelsExtendedCovid()` instead.
   *
   * This method doesn't expect any request body.
   */
  getColumnLabelsExtendedCovid$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<ColumnLabels>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetColumnLabelsExtendedCovidPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ColumnLabels>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getColumnLabelsExtendedCovid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getColumnLabelsExtendedCovid(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<ColumnLabels>> {
    return this.getColumnLabelsExtendedCovid$Response(params).pipe(map((r: StrictHttpResponse<Array<ColumnLabels>>) => r.body as Array<ColumnLabels>));
  }

  /**
   * Path part for operation getAllColumnLabels
   */
  static readonly GetAllColumnLabelsPath = '/api/Engage/columnlabels/all/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllColumnLabels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllColumnLabels$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<ColumnLabels>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetAllColumnLabelsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ColumnLabels>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllColumnLabels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllColumnLabels(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<ColumnLabels>> {
    return this.getAllColumnLabels$Response(params).pipe(map((r: StrictHttpResponse<Array<ColumnLabels>>) => r.body as Array<ColumnLabels>));
  }

  /**
   * Path part for operation postSymptoms
   */
  static readonly PostSymptomsPath = '/api/Engage/employeesymptoms/{employeeguid}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSymptoms()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postSymptoms$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeguid: string;
    legalEntityID: string;
    body?: EmployeeSymptom;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.PostSymptomsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeguid', params.employeeguid, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSymptoms$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postSymptoms(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeguid: string;
    legalEntityID: string;
    body?: EmployeeSymptom;
  }): Observable<boolean> {
    return this.postSymptoms$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postStatus
   */
  static readonly PostStatusPath = '/api/Engage/employeestatus/{employeeguid}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postStatus()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postStatus$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeguid: string;
    legalEntityID: string;
    body?: EmployeeStatus;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.PostStatusPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeguid', params.employeeguid, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postStatus$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postStatus(params: { subscriptionKey?: string; APIURL?: string; employeeguid: string; legalEntityID: string; body?: EmployeeStatus }): Observable<boolean> {
    return this.postStatus$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchEmployeeSymptoms
   */
  static readonly PatchEmployeeSymptomsPath = '/api/Engage/employeesymptoms/patch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEmployeeSymptoms()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeSymptoms$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: EmployeeSymptom }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.PatchEmployeeSymptomsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEmployeeSymptoms$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeSymptoms(params?: { subscriptionKey?: string; APIURL?: string; body?: EmployeeSymptom }): Observable<boolean> {
    return this.patchEmployeeSymptoms$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postPresence
   */
  static readonly PostPresencePath = '/api/Engage/employeepresence/{employeeguid}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPresence()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postPresence$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeguid: string;
    legalEntityID: string;
    body?: EmployeePresence;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.PostPresencePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeguid', params.employeeguid, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPresence$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postPresence(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeguid: string;
    legalEntityID: string;
    body?: EmployeePresence;
  }): Observable<boolean> {
    return this.postPresence$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postDailyAccess
   */
  static readonly PostDailyAccessPath = '/api/Engage/dailyaccess';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDailyAccess()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDailyAccess$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: DailyAccess }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.PostDailyAccessPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postDailyAccess$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDailyAccess(params?: { subscriptionKey?: string; APIURL?: string; body?: DailyAccess }): Observable<boolean> {
    return this.postDailyAccess$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getNotificationTemplates
   */
  static readonly GetNotificationTemplatesPath = '/api/Engage/notificationtemplates/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotificationTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationTemplates$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<NotificationTemplates>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetNotificationTemplatesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<NotificationTemplates>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getNotificationTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotificationTemplates(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<NotificationTemplates>> {
    return this.getNotificationTemplates$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NotificationTemplates>>) => r.body as Array<NotificationTemplates>),
    );
  }

  /**
   * Path part for operation getUserSymptoms
   */
  static readonly GetUserSymptomsPath = '/api/Engage/user/symptoms/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserSymptoms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserSymptoms$Response(params: { subscriptionKey?: string; APIURL?: string; email: string }): Observable<StrictHttpResponse<EmployeeSymptom>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetUserSymptomsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('email', params.email, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<EmployeeSymptom>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserSymptoms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserSymptoms(params: { subscriptionKey?: string; APIURL?: string; email: string }): Observable<EmployeeSymptom> {
    return this.getUserSymptoms$Response(params).pipe(map((r: StrictHttpResponse<EmployeeSymptom>) => r.body as EmployeeSymptom));
  }

  /**
   * Path part for operation getEmployeePresences
   */
  static readonly GetEmployeePresencesPath = '/api/Engage/get/employeepresence/{userID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeePresences()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeePresences$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
  }): Observable<StrictHttpResponse<Array<EmployeePresence>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetEmployeePresencesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EmployeePresence>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeePresences$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeePresences(params: { subscriptionKey?: string; APIURL?: string; userID: string }): Observable<Array<EmployeePresence>> {
    return this.getEmployeePresences$Response(params).pipe(map((r: StrictHttpResponse<Array<EmployeePresence>>) => r.body as Array<EmployeePresence>));
  }

  /**
   * Path part for operation getOffices
   */
  static readonly GetOfficesPath = '/api/Engage/get/offices/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOffices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffices$Response(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<StrictHttpResponse<Array<Offices>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetOfficesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Offices>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOffices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffices(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<Offices>> {
    return this.getOffices$Response(params).pipe(map((r: StrictHttpResponse<Array<Offices>>) => r.body as Array<Offices>));
  }

  /**
   * Path part for operation getLocations
   */
  static readonly GetLocationsPath = '/api/Engage/get/locations/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocations$Response(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<StrictHttpResponse<Array<Locations>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetLocationsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Locations>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLocations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocations(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<Locations>> {
    return this.getLocations$Response(params).pipe(map((r: StrictHttpResponse<Array<Locations>>) => r.body as Array<Locations>));
  }

  /**
   * Path part for operation getEmployeeTracking
   */
  static readonly GetEmployeeTrackingPath = '/api/Engage/get/employeetracking/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeTracking()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeTracking$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<EmployeeTracking>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetEmployeeTrackingPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EmployeeTracking>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeTracking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeTracking(params: { subscriptionKey?: string; APIURL?: string; userID: string; legalEntityID: string }): Observable<Array<EmployeeTracking>> {
    return this.getEmployeeTracking$Response(params).pipe(map((r: StrictHttpResponse<Array<EmployeeTracking>>) => r.body as Array<EmployeeTracking>));
  }

  /**
   * Path part for operation getAllCovidTests
   */
  static readonly GetAllCovidTestsPath = '/api/Engage/get/covidtests/{employeeGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCovidTests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCovidTests$Response(params: { subscriptionKey?: string; APIURL?: string; employeeGuid: string }): Observable<StrictHttpResponse<Array<CovidTest>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetAllCovidTestsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGuid', params.employeeGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CovidTest>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllCovidTests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCovidTests(params: { subscriptionKey?: string; APIURL?: string; employeeGuid: string }): Observable<Array<CovidTest>> {
    return this.getAllCovidTests$Response(params).pipe(map((r: StrictHttpResponse<Array<CovidTest>>) => r.body as Array<CovidTest>));
  }

  /**
   * Path part for operation postCovidTest
   */
  static readonly PostCovidTestPath = '/api/Engage/post/covidtest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCovidTest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postCovidTest$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: CovidTest }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.PostCovidTestPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCovidTest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postCovidTest(params?: { subscriptionKey?: string; APIURL?: string; body?: CovidTest }): Observable<boolean> {
    return this.postCovidTest$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getAllSites
   */
  static readonly GetAllSitesPath = '/api/Engage/get/sites/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSites()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSites$Response(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<StrictHttpResponse<Array<Sites>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetAllSitesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Sites>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllSites$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSites(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<Sites>> {
    return this.getAllSites$Response(params).pipe(map((r: StrictHttpResponse<Array<Sites>>) => r.body as Array<Sites>));
  }

  /**
   * Path part for operation postTrakAndTrace
   */
  static readonly PostTrakAndTracePath = '/api/Engage/post/trackandtrace';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTrakAndTrace()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTrakAndTrace$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: TrackAndTrace }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.PostTrakAndTracePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postTrakAndTrace$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTrakAndTrace(params?: { subscriptionKey?: string; APIURL?: string; body?: TrackAndTrace }): Observable<boolean> {
    return this.postTrakAndTrace$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getAllIsolation
   */
  static readonly GetAllIsolationPath = '/api/Engage/get/isolation/{employeeGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllIsolation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllIsolation$Response(params: { subscriptionKey?: string; APIURL?: string; employeeGuid: string }): Observable<StrictHttpResponse<Array<Isolation>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetAllIsolationPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGuid', params.employeeGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Isolation>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllIsolation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllIsolation(params: { subscriptionKey?: string; APIURL?: string; employeeGuid: string }): Observable<Array<Isolation>> {
    return this.getAllIsolation$Response(params).pipe(map((r: StrictHttpResponse<Array<Isolation>>) => r.body as Array<Isolation>));
  }

  /**
   * Path part for operation postIsolation
   */
  static readonly PostIsolationPath = '/api/Engage/post/isolation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postIsolation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postIsolation$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: Isolation }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.PostIsolationPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postIsolation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postIsolation(params?: { subscriptionKey?: string; APIURL?: string; body?: Isolation }): Observable<boolean> {
    return this.postIsolation$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postTriggerLog
   */
  static readonly PostTriggerLogPath = '/api/Engage/post/triggerlog/{employeeGUID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTriggerLog()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTriggerLog$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
    legalEntityID: string;
    body?: TriggerLog;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.PostTriggerLogPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postTriggerLog$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTriggerLog(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string; legalEntityID: string; body?: TriggerLog }): Observable<boolean> {
    return this.postTriggerLog$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchIsolation
   */
  static readonly PatchIsolationPath = '/api/Engage/patch/isolation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchIsolation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchIsolation$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: Isolation }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.PatchIsolationPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchIsolation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchIsolation(params?: { subscriptionKey?: string; APIURL?: string; body?: Isolation }): Observable<boolean> {
    return this.patchIsolation$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getEngageTeam
   */
  static readonly GetEngageTeamPath = '/api/Engage/engageteam/{email}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEngageTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEngageTeam$Response(params: { subscriptionKey?: string; APIURL?: string; email: string }): Observable<StrictHttpResponse<Array<EmployeeStatusFiltered>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetEngageTeamPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('email', params.email, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EmployeeStatusFiltered>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEngageTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEngageTeam(params: { subscriptionKey?: string; APIURL?: string; email: string }): Observable<Array<EmployeeStatusFiltered>> {
    return this.getEngageTeam$Response(params).pipe(map((r: StrictHttpResponse<Array<EmployeeStatusFiltered>>) => r.body as Array<EmployeeStatusFiltered>));
  }

  /**
   * Path part for operation getEmployeeSymptomEngage
   */
  static readonly GetEmployeeSymptomEngagePath = '/api/Engage/getEmployeeSymptomEngage/{EmpID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeSymptomEngage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeSymptomEngage$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    EmpID: string;
  }): Observable<StrictHttpResponse<Array<EmployeeSymptom>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetEmployeeSymptomEngagePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('EmpID', params.EmpID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EmployeeSymptom>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeSymptomEngage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeSymptomEngage(params: { subscriptionKey?: string; APIURL?: string; EmpID: string }): Observable<Array<EmployeeSymptom>> {
    return this.getEmployeeSymptomEngage$Response(params).pipe(map((r: StrictHttpResponse<Array<EmployeeSymptom>>) => r.body as Array<EmployeeSymptom>));
  }

  /**
   * Path part for operation getEmployeeStatusEngage
   */
  static readonly GetEmployeeStatusEngagePath = '/api/Engage/getEmployeeStatusEngage/{EmpID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeStatusEngage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeStatusEngage$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    EmpID: string;
  }): Observable<StrictHttpResponse<Array<EmployeePresence>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetEmployeeStatusEngagePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('EmpID', params.EmpID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EmployeePresence>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeStatusEngage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeStatusEngage(params: { subscriptionKey?: string; APIURL?: string; EmpID: string }): Observable<Array<EmployeePresence>> {
    return this.getEmployeeStatusEngage$Response(params).pipe(map((r: StrictHttpResponse<Array<EmployeePresence>>) => r.body as Array<EmployeePresence>));
  }

  /**
   * Path part for operation getEmployeeStatusFilteredEngage
   */
  static readonly GetEmployeeStatusFilteredEngagePath = '/api/Engage/getEmployeeStatusFilteredEngage/{EmpID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeStatusFilteredEngage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeStatusFilteredEngage$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    EmpID: string;
  }): Observable<StrictHttpResponse<Array<EmployeeStatusEngage>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEngageService.GetEmployeeStatusFilteredEngagePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('EmpID', params.EmpID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EmployeeStatusEngage>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeStatusFilteredEngage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeStatusFilteredEngage(params: { subscriptionKey?: string; APIURL?: string; EmpID: string }): Observable<Array<EmployeeStatusEngage>> {
    return this.getEmployeeStatusFilteredEngage$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeStatusEngage>>) => r.body as Array<EmployeeStatusEngage>),
    );
  }
}
