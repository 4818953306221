import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TasksActions, TasksActionTypes } from '../actions/tasks.actions';
import * as fromDashboardRelatedReducer from './dashboard-related.reducer';

// Models
import { VerdeTask } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type TasksState = EntityState<VerdeTask>;

export interface TasksRelatedState {
  tasksState: TasksState;
  loading: boolean;
  error: any;
}

export const tasksKeys = ['bt_verdetasklistid'];
export const tasksStateAdapter: EntityAdapter<VerdeTask> = createEntityAdapter<VerdeTask>({
  selectId: (data) => keyHandler(data, tasksKeys),
});

export const initialTasksState: TasksState = tasksStateAdapter.getInitialState({});

export const initialTasksRelatedState = {
  tasksState: initialTasksState,
  loading: false,
  error: {},
};

export function tasksStateRelatedReducer(state = initialTasksRelatedState, action: TasksActions): TasksRelatedState {
  switch (action.type) {
    // Get All Tasks
    case TasksActionTypes.TASKS_GET_ALL:
      return { ...state, loading: true };
    case TasksActionTypes.TASKS_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        tasksState: tasksStateAdapter.setAll(action.payload, state.tasksState),
      };
    }
    case TasksActionTypes.TASKS_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Clear
    case TasksActionTypes.TASKS_CLEAR:
      return { ...initialTasksRelatedState };
    default:
      return state;
  }
}

export const getHomeRelatedState = createFeatureSelector<fromDashboardRelatedReducer.DashboardRelatedState>('dashboardRelatedReducer');

export const getTasksRelatedState = createSelector(getHomeRelatedState, (state: fromDashboardRelatedReducer.DashboardRelatedState) => state.tasksState);

// Current Tasks
export const getTasksState = createSelector(getTasksRelatedState, (state) => state.tasksState);
export const { selectAll: getTasks } = tasksStateAdapter.getSelectors(getTasksState);

// Loading
export const isLoading = createSelector(getTasksRelatedState, (state) => state.loading);

// Error
export const getError = createSelector(getTasksRelatedState, (state) => state.error);
