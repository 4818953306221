/* eslint-disable no-console */
import { Injectable, OnDestroy } from '@angular/core';
import { UserService } from '@verde/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DocumentsRoleService implements OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  userHasPermission$ = new BehaviorSubject<boolean>(false);

  constructor(private userService: UserService) {
    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => {
      if (config?.legalEntityConfig?.documentManagement && this.userService.user) {
        this.checkUserRoles();
      }
    });
  }

  get userHasPermission(): boolean {
    return this.userHasPermission$.getValue();
  }

  checkUserRoles() {
    this.userHasPermission$.next(false);

    if (this.userService.isPermissionsLoaded) {
      const userHasPermission = this.userService.userHasPermission('PEOPLE-PEOPLE-MANAGEMENT-DOCUMENTS');
      this.userHasPermission$.next(userHasPermission);
    } else {
      console.log('Err: User not loaded');
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  // New HR Documents people management functionality
  private _hrDocumentsUserObj$ = new BehaviorSubject<any>({ isHrUser: false, legalEntityName: '', legalEntityId: '' });
  currentHrDocumentsUserObj = this._hrDocumentsUserObj$.asObservable();

  changeHrDocumentsUserObj(obj: any) {
    this._hrDocumentsUserObj$.next(obj);
  }
}
