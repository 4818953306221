/* tslint:disable */
/* eslint-disable */
export interface BtPayrollImportDetail {
  _bt_importbyentity_value?: null | string;
  _bt_importedby_value?: null | string;
  _bt_payrollimportheaderno_value?: null | string;
  bt_comment?: null | string;
  bt_importstatus?: number;
  bt_overwrite?: boolean;
  bt_payrollimportdetailid?: null | string;
  bt_payrollimportdetailno?: null | string;
  odatabt_ImportbyEntity?: null | string;
  odatabt_Importedby?: null | string;
  odatabt_PayrollImportHeaderNo?: null | string;
}
