import { Component, Input } from '@angular/core';

interface Client {
  id: string;
  name: string;
}

@Component({
  selector: 'verde-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent {
  @Input() clientId: string;
  @Input() officeId: string;

  clients: { [key: number]: { [key: string]: Client } } = {};

  constructor() {
    this.clients = {
      1: {
        prod: { id: '680d8ac6-53f0-eb11-bacb-000d3aaf8540', name: 'Braintree' },
        uat: { id: '680d8ac6-53f0-eb11-bacb-000d3aaf8540', name: 'Braintree' },
        dev: { id: '680d8ac6-53f0-eb11-bacb-000d3aaf8540', name: 'Braintree' },
      },
    };
  }

  selectedClient(clients: Client[]): boolean {
    return clients?.some((client) => client?.id === this.clientId);
  }
}
