import { Action } from '@ngrx/store';
import { Counter } from '@verde/api';

export enum CountersActionTypes {
  // Get Voting Counter
  VOTING_COUNTER_GET = '[VOTING COUNTER] Get ',
  VOTING_COUNTER_GET_SUCCESS = '[VOTING COUNTER] Get  Success',
  VOTING_COUNTER_GET_ERROR = '[VOTING COUNTER] Get  Error',

  // Clear Counters
  COUNTERS_CLEAR = '[VOTING_COUNTER] Clear',
}

// Get Voting Counter
export class VotingCounterGet implements Action {
  readonly type = CountersActionTypes.VOTING_COUNTER_GET;
  constructor(public employeeID: string, public legalEntityID: string) {}
}

export class VotingCounterGetSuccess implements Action {
  readonly type = CountersActionTypes.VOTING_COUNTER_GET_SUCCESS;
  constructor(public payload: Counter[]) {}
}

export class VotingCounterGetError implements Action {
  readonly type = CountersActionTypes.VOTING_COUNTER_GET_ERROR;
  constructor(public payload: any) {}
}

// Clear Counters
export class CountersClear implements Action {
  readonly type = CountersActionTypes.COUNTERS_CLEAR;
  constructor(public payload: any) {}
}

export type CountersActions =
  // Get Voting Counter
  | VotingCounterGet
  | VotingCounterGetSuccess
  | VotingCounterGetError
  // Clear Counters
  | CountersClear;
