/* tslint:disable */
/* eslint-disable */
import { BtVerdeManualDocumentsControl } from './bt-verde-manual-documents-control';
import { User } from './user';
export interface VerdeManualDocuments {
  _bt_employee_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_verdemanualdocumentscontrol_value?: null | string;
  bt_Employee?: User;
  bt_VerdeManualDocumentsControl?: BtVerdeManualDocumentsControl;
  bt_acknowledgement?: null | boolean;
  bt_allowobjection?: null | boolean;
  bt_attached?: null | boolean;
  bt_documentname?: null | string;
  bt_duedate?: null | string;
  bt_message?: null | string;
  bt_referenceno?: null | string;
  bt_required?: null | boolean;
  bt_verdemanualdocuments?: null | string;
  bt_verdemanualdocumentsid?: null | string;
  odataDocumentControl?: null | string;
  odataDueDate?: null | string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
}
