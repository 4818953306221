import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QualificationActions, QualificationActionTypes } from '../actions/qualification.actions';
import * as fromProfileRelatedReducer from './profile-related.reducer';

// Models
import { QualificationsBthrQualification } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type QualificationState = EntityState<QualificationsBthrQualification>;

export interface QualificationRelatedState {
  qualificationState: QualificationState;
  selectedIds: string[];
  loading: boolean;
  error: any;
}

export const qualificationKeys = ['bthr_qualificationid'];
export const qualificationStateAdapter: EntityAdapter<QualificationsBthrQualification> = createEntityAdapter<QualificationsBthrQualification>({
  selectId: (data) => keyHandler(data, qualificationKeys),
});

export const initialQualificationState: QualificationState = qualificationStateAdapter.getInitialState({});

export const initialQualificationRelatedState = {
  qualificationState: initialQualificationState,
  selectedIds: [],
  loading: false,
  error: {},
};

export function QualificationRelatedReducer(state = initialQualificationRelatedState, action: QualificationActions): QualificationRelatedState {
  switch (action.type) {
    // Get Qualification
    case QualificationActionTypes.QUALIFICATION_GET_ALL:
      return { ...state, loading: true };
    case QualificationActionTypes.QUALIFICATION_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        qualificationState: qualificationStateAdapter.setAll(action.payload, state.qualificationState),
      };
    }
    case QualificationActionTypes.QUALIFICATION_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Get Reduced Qualification
    case QualificationActionTypes.QUALIFICATION_GET_REDUCED:
      return { ...state, loading: true };
    case QualificationActionTypes.QUALIFICATION_GET_REDUCED_SUCCESS: {
      return {
        ...state,
        loading: false,
        qualificationState: qualificationStateAdapter.setAll(action.payload, state.qualificationState),
      };
    }

    // Upload
    case QualificationActionTypes.QUALIFICATION_UPLOAD_SUCCESS: {
      return {
        ...state,
      };
    }
    case QualificationActionTypes.QUALIFICATION_UPLOAD_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Clear
    case QualificationActionTypes.QUALIFICATION_CLEAR:
      return { ...initialQualificationRelatedState };
    default:
      return state;
  }
}

export const getProfileRelatedState = createFeatureSelector<fromProfileRelatedReducer.ProfileRelatedState>('profileRelatedReducer');

export const getQualificationRelatedState = createSelector(
  getProfileRelatedState,
  (state: fromProfileRelatedReducer.ProfileRelatedState) => state.qualificationState,
);

// Current Qualification

export const getQualificationState = createSelector(getQualificationRelatedState, (state) => state.qualificationState);
export const { selectAll: getQualification } = qualificationStateAdapter.getSelectors(getQualificationState);

// Selected

export const getSelectedQualificationIds = createSelector(getQualificationRelatedState, (state) => state.selectedIds);

export const getSelectedProfiles = createSelector(getQualification, getSelectedQualificationIds, (Qualification, selectedIds: string[]) =>
  Qualification.filter((m) => selectedIds.indexOf(keyHandler(m, qualificationKeys)) !== -1),
);

// Loading

export const isQualificationLoading = createSelector(getQualificationRelatedState, (state) => state.loading);

// Error

export const getProfileError = createSelector(getQualificationRelatedState, (state) => state.error);
