import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DelegationsRequestBuilder } from '@verde/api';
import { IEmbedTokenRequest, IEmbedTokenResponse } from '@verde/shared';
import { Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { PowerBiTokenService } from './power-bi-token.service';

@Injectable({
  providedIn: 'root',
})
export class VerdePowerBiService {
  static readonly embedTokenAuthenticatePath = '/api/GetBIEmbedToken';
  reports: { [key: string]: IEmbedTokenResponse } = {};
  private rootUrl: string = this.environment.powerBiUrl;

  constructor(private readonly http: HttpClient, private readonly tokenService: PowerBiTokenService, @Inject('environment') private environment: any) {
    this.rootUrl = this.environment.powerBiUrl;
  }

  authenticate(request: IEmbedTokenRequest): Observable<IEmbedTokenResponse> {
    const cacheKey = request.reportId;
    const isExpiredOrAboutToExpire = this.tokenService.isExpired || this.tokenService.isAboutToExpire;

    if (this.reports[cacheKey] && !isExpiredOrAboutToExpire) {
      return of(this.reports[cacheKey]);
    }

    const builder = new DelegationsRequestBuilder(this.rootUrl, VerdePowerBiService.embedTokenAuthenticatePath, 'post');
    if (request) {
      builder.query('code', this.environment.azureFunctionCode);
      // builder.rootUrl = 'http://localhost:7007';
      builder.body(request, 'application/json');
    }

    return this.build<IEmbedTokenResponse>(builder).pipe(
      filter(this.handleUnsuccessfulResponses),
      tap((response) => {
        // Cache the response.
        this.reports[cacheKey] = response;
        this.tokenService.setToken(response.embedToken.token);
      }),
    );
  }

  build<T>(builder: DelegationsRequestBuilder): Observable<T> {
    return this.http
      .request(
        builder.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => r.body as T),
      );
  }

  private handleUnsuccessfulResponses(response: any): any {
    const containsStatusCode: boolean = 'status' in response || 'statusCode' in response;
    const statusCode = containsStatusCode ? response.status || response.statusCode : undefined;

    if (containsStatusCode && statusCode !== 200) {
      throw new Error(response.message);
    }

    return response;
  }
}
