import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TemplateGetBenchmarksByGroupQuery, TemplateGetBenchmarksQuery } from '@verde/api';
import { Observable } from 'rxjs';
import * as BenchmarkActions from '../store/actions/benchmark.actions';
import * as fromBenchmarkReducer from '../store/reducers/benchmark.reducer';
import * as fromProfileRelatedReducer from '../store/reducers/profile-related.reducer';

@Injectable({
  providedIn: 'root',
})
export class BenchmarkService {
  // Benchmark
  benchmarkLoading$: Observable<boolean>;
  benchmarkObjects$: Observable<any[]>;

  constructor(private store: Store<fromProfileRelatedReducer.ProfileRelatedState>) {
    // Benchmark
    this.benchmarkLoading$ = this.store.pipe(select(fromBenchmarkReducer.isBenchmarkLoading));
    this.benchmarkObjects$ = this.store.pipe(select(fromBenchmarkReducer.getAllBenchmark));
  }

  // Get benchmark
  getBenchmark(body: TemplateGetBenchmarksQuery) {
    this.store.dispatch(new BenchmarkActions.BenchmarksGet(body));
  }

  // Get benchmark by group
  getBenchmarkByGroup(body: TemplateGetBenchmarksByGroupQuery) {
    this.store.dispatch(new BenchmarkActions.BenchmarksByGroupGet(body));
  }

  // Clear
  benchmarkClear() {
    this.store.dispatch(new BenchmarkActions.BenchmarksClear(null));
  }
}
