/* tslint:disable */
/* eslint-disable */
export enum EquipmentBtEquipmentMovementStatus {
  OnHand = 'OnHand',
  Allocated = 'Allocated',
  Assigned = 'Assigned',
  OnOrderAllocated = 'OnOrder_Allocated',
  OnOrder = 'OnOrder',
  Returned = 'Returned',
  AtSupplier = 'AtSupplier',
  Disposed = 'Disposed',
  Requested = 'Requested',
  RequestedAllocated = 'Requested_Allocated',
}
