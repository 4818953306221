/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StructureApiConfiguration, StructureApiConfigurationParams } from './structure-api-configuration';

import { StructureApiStructureService } from './services/structure-api-structure.service';
import { StructureApiVersionService } from './services/structure-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [StructureApiStructureService, StructureApiVersionService, StructureApiConfiguration],
})
export class StructureApiModule {
  static forRoot(params: StructureApiConfigurationParams): ModuleWithProviders<StructureApiModule> {
    return {
      ngModule: StructureApiModule,
      providers: [
        {
          provide: StructureApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: StructureApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('StructureApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
