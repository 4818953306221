/* tslint:disable */
/* eslint-disable */
import { BtStructureMdSeat } from './bt-structure-md-seat';
import { BtStructureManagingDirector } from './bt-structure-managing-director';
import { BtPositionseat } from './bt-positionseat';
export interface StructureLegalEntities {
  _bthr_parentlegalentity_value?: null | string;
  btHaschildrenODataCommunityDisplayV1FormattedValue?: null | string;
  bt_MDSeat?: BtStructureMdSeat;
  bt_ManagingDirector?: BtStructureManagingDirector;
  bt_employeesemployed?: null | number;
  bt_employeesoffboarding?: null | number;
  bt_employeesonboarding?: null | number;
  bt_haschildren?: boolean;
  bt_mdseatstatus?: BtPositionseat;
  bt_registeredname?: null | string;
  bt_seatonboarding?: null | number;
  bt_seatonboardingassigned?: null | number;
  bt_website?: null | string;
  bthrParentlegalentityValueODataCommunityDisplayV1FormattedValue?: null | string;
  bthr_legalentitiesid?: null | string;
  bthr_legalentityname?: null | string;
  odataMDSeatStatus?: null | string;
}
