/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvBankBranches } from '../models/dv-bank-branches';
import { DvBanks } from '../models/dv-banks';

@Injectable({
  providedIn: 'root',
})
export class SharedApiBanksAndBranchesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllBanks
   */
  static readonly GetAllBanksPath = '/api/BanksAndBranches/banks/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBanks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBanks$Response(params: { subscriptionKey?: string; APIURL?: string; legalEntityGuid: string }): Observable<StrictHttpResponse<Array<DvBanks>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiBanksAndBranchesService.GetAllBanksPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvBanks>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllBanks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBanks(params: { subscriptionKey?: string; APIURL?: string; legalEntityGuid: string }): Observable<Array<DvBanks>> {
    return this.getAllBanks$Response(params).pipe(map((r: StrictHttpResponse<Array<DvBanks>>) => r.body as Array<DvBanks>));
  }

  /**
   * Path part for operation getAllBankBranches
   */
  static readonly GetAllBankBranchesPath = '/api/BanksAndBranches/bankbranches/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBankBranches()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBankBranches$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<DvBankBranches>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiBanksAndBranchesService.GetAllBankBranchesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvBankBranches>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllBankBranches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBankBranches(params: { subscriptionKey?: string; APIURL?: string; legalEntityGuid: string }): Observable<Array<DvBankBranches>> {
    return this.getAllBankBranches$Response(params).pipe(map((r: StrictHttpResponse<Array<DvBankBranches>>) => r.body as Array<DvBankBranches>));
  }
}
