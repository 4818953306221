/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { GiftsBaseService } from '../gifts-base-service';
import { GiftsApiConfiguration } from '../gifts-api-configuration';
import { GiftsStrictHttpResponse } from '../gifts-strict-http-response';
import { GiftsRequestBuilder } from '../gifts-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GiftsGetGiftsQuery } from '../models/gifts-get-gifts-query';
import { GiftsGiftsExample } from '../models/gifts-gifts-example';
import { GiftsPostGiftsCommand } from '../models/gifts-post-gifts-command';

@Injectable({
  providedIn: 'root',
})
export class GiftsApiGiftsService extends GiftsBaseService {
  constructor(config: GiftsApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getGifts
   */
  static readonly GetGiftsPath = '/api/Gifts/get/gifts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGifts()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getGifts$Response(params?: { tenantId?: string; body?: GiftsGetGiftsQuery }): Observable<GiftsStrictHttpResponse<Array<GiftsGiftsExample>>> {
    const rb = new GiftsRequestBuilder(this.rootUrl, GiftsApiGiftsService.GetGiftsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as GiftsStrictHttpResponse<Array<GiftsGiftsExample>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGifts$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getGifts(params?: { tenantId?: string; body?: GiftsGetGiftsQuery }): Observable<Array<GiftsGiftsExample>> {
    return this.getGifts$Response(params).pipe(map((r: GiftsStrictHttpResponse<Array<GiftsGiftsExample>>) => r.body as Array<GiftsGiftsExample>));
  }

  /**
   * Path part for operation postGifts
   */
  static readonly PostGiftsPath = '/api/Gifts/post/gifts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postGifts()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postGifts$Response(params?: { tenantId?: string; body?: GiftsPostGiftsCommand }): Observable<GiftsStrictHttpResponse<boolean>> {
    const rb = new GiftsRequestBuilder(this.rootUrl, GiftsApiGiftsService.PostGiftsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as GiftsStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postGifts$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postGifts(params?: { tenantId?: string; body?: GiftsPostGiftsCommand }): Observable<boolean> {
    return this.postGifts$Response(params).pipe(map((r: GiftsStrictHttpResponse<boolean>) => r.body as boolean));
  }
}
