/* tslint:disable */
/* eslint-disable */
export interface EmployeeObjections {
  _bt_riskmanagement_value?: null | string;
  bt_covid19vaccineprocess?: null | string;
  bt_covid19vaccineprocessid?: null | string;
  bt_employeeemail?: null | string;
  bt_outcome?: null | number;
  bt_workflowstatus?: null | number;
}
