import { Component, Input } from '@angular/core';
import { DeviceTypeService } from '@verde/core';
import { BookingsDataService } from '../../../../../../shared/services/booking/bookings-data.service';

interface Office {
  id: string;
  name: string;
  isSubOffice: boolean;
}

@Component({
  selector: 'verde-braintree',
  templateUrl: './braintree.component.html',
  styleUrls: ['./braintree.component.scss'],
})
export class BraintreeComponent {
  @Input() officeId: string;

  offices: { [key: number]: { [key: string]: Office } } = {};
  constructor(public bookingsDataService: BookingsDataService, public deviceTypeService: DeviceTypeService) {
    this.offices = {
      1: {
        prod: { id: '5d594e51-c57c-ef11-ac20-6045bd96625f', name: '29 Scott Street', isSubOffice: true },
        uat: { id: '048224a8-3ffd-ec11-82e5-000d3a2cdffc', name: 'Waverley - Block A - Braintree', isSubOffice: true },
        dev: { id: '5d594e51-c57c-ef11-ac20-6045bd96625f', name: '29 Scott Street', isSubOffice: true },
      },
    };
  }

  selectedOffice(offices: Office[]): boolean {
    return offices?.some((office) => office?.id === this.officeId);
  }
}
