/* tslint:disable */
/* eslint-disable */
export enum ProfileBthrCode {
  A1Motorbike125Cc = 'A1Motorbike125cc',
  BCarTrailerSmall = 'BCarTrailerSmall',
  C1SmallTruckorBus = 'C1SmallTruckorBus',
  EbArticulatedvehicleswithgrosscombinationmassGcMof3500Kg = 'EBArticulatedvehicleswithgrosscombinationmass_GCMof3500kg',
  Ec1SmallTruckandTrailer = 'EC1SmallTruckandTrailer',
  EcTruckorBussandTrailer = 'ECTruckorBussandTrailer',
  AMotorcycleswithanenginecapacity125Cc = 'AMotorcycleswithanenginecapacity125cc',
  CBusesandgoodsvehicleswithGvMgreaterthan16000Kg = 'CBusesandgoodsvehicleswithGVMgreaterthan16000kg',
}
