import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import * as microsoftTeams from '@microsoft/teams-js';
import { BehaviorSubject, filter } from 'rxjs';
import { AuthState } from '../models/auth-state';
import { createClaimsTable } from '../utils/claim-utils';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authState$: BehaviorSubject<AuthState | undefined> = new BehaviorSubject<AuthState | undefined>(undefined);
  loaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  claims: any = [];

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfig: MsalInterceptorConfiguration,
  ) {
    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED))
      .subscribe(() => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        }
      });

    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)).subscribe(async () => {
      await this.checkAndSetActiveAccount();
      this.getClaims(this.msalService.instance.getActiveAccount()?.idTokenClaims);
    });

    this.msalService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (!this.msalService.instance.getActiveAccount() && this.msalService.instance.getAllAccounts().length > 0) {
          this.checkAndSetActiveAccount();
        }
      },
      error: (error) => console.log(error),
    });
  }

  protectedResourcesLoaded() {
    return this.msalInterceptorConfig && this.msalInterceptorConfig.protectedResourceMap;
  }

  async checkAndSetActiveAccount(isTeamsAuthed = false) {
    return new Promise<boolean>(async (resolve) => {
      if (!this.protectedResourcesLoaded()) {
        console.warn('Protected resources are not loaded.');
        return false;
      }

      await this.msalService.instance.initialize();

      const accounts = this.msalService.instance.getAllAccounts();

      if (accounts.length === 0) {
        await this.signIn();
        return false;
      }

      let activeAccount = this.msalService.instance.getActiveAccount();
      if (!activeAccount) {
        activeAccount = accounts[0];
      }

      if (activeAccount) {
        this.msalService.instance.setActiveAccount(activeAccount);

        this.authState$.next({
          ...this.authState$.getValue(),
          isMicrosoftAuthed: true,
          isTeamsAuthed,
          tenantId: activeAccount.tenantId,
          userEmail: activeAccount.username,
          azureObjectId: activeAccount.localAccountId,
        });

        this.loaded$.next(true);
      }

      resolve(true);
    });
  }

  getClaims(claims: any) {
    if (claims) {
      const claimsTable = createClaimsTable(claims);
      this.claims = [...claimsTable];
    }
  }

  private checkInTeams() {
    const microsoftTeamsLib = microsoftTeams || window['microsoftTeams'];

    // Check if the Microsoft Teams library is loaded
    if (!microsoftTeamsLib) {
      return false;
    }

    if (
      (window.parent === window.self && (window as any).nativeInterface) ||
      window.navigator.userAgent.includes('Teams/') ||
      window.name === 'embedded-page-container' ||
      window.name === 'extension-tab-frame'
    ) {
      return true;
    }
    return false;
  }

  async inTeams() {
    const self = this;
    return new Promise<boolean>((resolve) => {
      if (self.checkInTeams()) {
        microsoftTeams.initialize(() => {
          microsoftTeams.getContext((context) => {
            self.authState$.next({
              ...self.authState$.getValue(),
              isMicrosoftAuthed: true,
              isTeamsAuthed: true,
              tenantId: context.tid ?? '',
              userEmail: context.userPrincipalName ?? '',
              azureObjectId: context.userObjectId ?? '',
            });
            resolve(true);
          });
        });
      } else {
        resolve(false);
      }
    });
  }

  // Getters

  get teamsAuthenticated() {
    return this.authState$.getValue()?.isTeamsAuthed;
  }

  // Prompt the user to sign in and
  // grant consent to the requested permission scopes
  async signIn() {
    this.loaded$.next(false);
    await this.msalService.instance.initialize();
    // This ensures that MSAL processes the redirect result (if any).
    const redirectResult = await this.msalService.instance.handleRedirectPromise();
    console.log('Redirect result processed:', redirectResult);

    if (this.msalService.instance.getAllAccounts().length === 0) {
      if (this.checkInTeams()) {
        const popupRequest: PopupRequest = this.msalGuardConfig.authRequest as PopupRequest;
        await this.msalService.loginPopup(popupRequest);
      } else {
        const redirectRequest: RedirectRequest = this.msalGuardConfig.authRequest as RedirectRequest;
        this.msalService.loginRedirect(redirectRequest);
      }
    } else {
      await this.checkAndSetActiveAccount();
    }
  }

  // Sign out
  async signOut(): Promise<void> {
    await this.msalService.instance.logout(); // Clear stale session
    await this.msalService.logoutRedirect();

    this.authState$.next({
      ...this.authState$.getValue(),
      isMicrosoftAuthed: false,
      isTeamsAuthed: false,
    });

    this.loaded$.next(false);
  }
}
