/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BFFApiConfiguration, BFFApiConfigurationParams } from './bff-api-configuration';

import { BFFApiDocumentQueueService } from './services/bff-api-document-queue.service';
import { BFFApiVersionService } from './services/bff-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [BFFApiDocumentQueueService, BFFApiVersionService, BFFApiConfiguration],
})
export class BFFApiModule {
  static forRoot(params: BFFApiConfigurationParams): ModuleWithProviders<BFFApiModule> {
    return {
      ngModule: BFFApiModule,
      providers: [
        {
          provide: BFFApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: BFFApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('BFFApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
