/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Calendar } from '../models/calendar';

@Injectable({
  providedIn: 'root',
})
export class SharedApiCalendarService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getCalendarData
   */
  static readonly GetCalendarDataPath = '/api/Calendar/CalendarAPI/{year}/{legalEntityAPI}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalendarData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendarData$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    year: string;
    legalEntityAPI: string;
  }): Observable<StrictHttpResponse<Array<Calendar>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCalendarService.GetCalendarDataPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('year', params.year, {});
      rb.path('legalEntityAPI', params.legalEntityAPI, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Calendar>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCalendarData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendarData(params: { subscriptionKey?: string; APIURL?: string; year: string; legalEntityAPI: string }): Observable<Array<Calendar>> {
    return this.getCalendarData$Response(params).pipe(map((r: StrictHttpResponse<Array<Calendar>>) => r.body as Array<Calendar>));
  }

  /**
   * Path part for operation getCalendarOverviewYear
   */
  static readonly GetCalendarOverviewYearPath = '/api/Calendar/getCalendarOverviewYear/{legalEntityAPI}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalendarOverviewYear()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendarOverviewYear$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityAPI: string;
  }): Observable<StrictHttpResponse<Array<Calendar>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCalendarService.GetCalendarOverviewYearPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityAPI', params.legalEntityAPI, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Calendar>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCalendarOverviewYear$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendarOverviewYear(params: { subscriptionKey?: string; APIURL?: string; legalEntityAPI: string }): Observable<Array<Calendar>> {
    return this.getCalendarOverviewYear$Response(params).pipe(map((r: StrictHttpResponse<Array<Calendar>>) => r.body as Array<Calendar>));
  }
}
