/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DriversLicense } from '../models/drivers-license';
import { DriversLicenseCode } from '../models/drivers-license-code';
import { DriversLicenseCountryCodes } from '../models/drivers-license-country-codes';
import { DriversLicenseType } from '../models/drivers-license-type';
import { DriversLicenseVehicleRestrictions } from '../models/drivers-license-vehicle-restrictions';
import { DvCountries } from '../models/dv-countries';

@Injectable({
  providedIn: 'root',
})
export class SharedApiDriversLicensesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllDriversLicenses
   */
  static readonly GetAllDriversLicensesPath = '/api/DriversLicenses/get/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDriversLicenses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDriversLicenses$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DriversLicense>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiDriversLicensesService.GetAllDriversLicensesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DriversLicense>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllDriversLicenses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDriversLicenses(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<DriversLicense>> {
    return this.getAllDriversLicenses$Response(params).pipe(map((r: StrictHttpResponse<Array<DriversLicense>>) => r.body as Array<DriversLicense>));
  }

  /**
   * Path part for operation getDriversLicenseCodesByLicense
   */
  static readonly GetDriversLicenseCodesByLicensePath = '/api/DriversLicenses/get/codes/{licenseID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDriversLicenseCodesByLicense()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDriversLicenseCodesByLicense$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    licenseID: string;
  }): Observable<StrictHttpResponse<Array<DriversLicenseCode>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiDriversLicensesService.GetDriversLicenseCodesByLicensePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('licenseID', params.licenseID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DriversLicenseCode>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDriversLicenseCodesByLicense$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDriversLicenseCodesByLicense(params: { subscriptionKey?: string; APIURL?: string; licenseID: string }): Observable<Array<DriversLicenseCode>> {
    return this.getDriversLicenseCodesByLicense$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DriversLicenseCode>>) => r.body as Array<DriversLicenseCode>),
    );
  }

  /**
   * Path part for operation getAllDriversLicenseTypes
   */
  static readonly GetAllDriversLicenseTypesPath = '/api/DriversLicenses/get/types/{countryID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDriversLicenseTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDriversLicenseTypes$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    countryID: string;
  }): Observable<StrictHttpResponse<Array<DriversLicenseType>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiDriversLicensesService.GetAllDriversLicenseTypesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('countryID', params.countryID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DriversLicenseType>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllDriversLicenseTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDriversLicenseTypes(params: { subscriptionKey?: string; APIURL?: string; countryID: string }): Observable<Array<DriversLicenseType>> {
    return this.getAllDriversLicenseTypes$Response(params).pipe(map((r: StrictHttpResponse<Array<DriversLicenseType>>) => r.body as Array<DriversLicenseType>));
  }

  /**
   * Path part for operation getDriversLicenseCountryCodes
   */
  static readonly GetDriversLicenseCountryCodesPath = '/api/DriversLicenses/get/countrycodes/{countryID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDriversLicenseCountryCodes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDriversLicenseCountryCodes$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    countryID: string;
  }): Observable<StrictHttpResponse<Array<DriversLicenseCountryCodes>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiDriversLicensesService.GetDriversLicenseCountryCodesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('countryID', params.countryID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DriversLicenseCountryCodes>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDriversLicenseCountryCodes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDriversLicenseCountryCodes(params: { subscriptionKey?: string; APIURL?: string; countryID: string }): Observable<Array<DriversLicenseCountryCodes>> {
    return this.getDriversLicenseCountryCodes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DriversLicenseCountryCodes>>) => r.body as Array<DriversLicenseCountryCodes>),
    );
  }

  /**
   * Path part for operation getDriversLicenseCountryVehicleRestrictions
   */
  static readonly GetDriversLicenseCountryVehicleRestrictionsPath = '/api/DriversLicenses/get/vehiclerestrictions/{countryID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDriversLicenseCountryVehicleRestrictions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDriversLicenseCountryVehicleRestrictions$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    countryID: string;
  }): Observable<StrictHttpResponse<Array<DriversLicenseVehicleRestrictions>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiDriversLicensesService.GetDriversLicenseCountryVehicleRestrictionsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('countryID', params.countryID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DriversLicenseVehicleRestrictions>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDriversLicenseCountryVehicleRestrictions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDriversLicenseCountryVehicleRestrictions(params: {
    subscriptionKey?: string;
    APIURL?: string;
    countryID: string;
  }): Observable<Array<DriversLicenseVehicleRestrictions>> {
    return this.getDriversLicenseCountryVehicleRestrictions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DriversLicenseVehicleRestrictions>>) => r.body as Array<DriversLicenseVehicleRestrictions>),
    );
  }

  /**
   * Path part for operation getDriversLicenseCountries
   */
  static readonly GetDriversLicenseCountriesPath = '/api/DriversLicenses/get/driverslicensecountries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDriversLicenseCountries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDriversLicenseCountries$Response(params?: { subscriptionKey?: string; APIURL?: string }): Observable<StrictHttpResponse<Array<DvCountries>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiDriversLicensesService.GetDriversLicenseCountriesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvCountries>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDriversLicenseCountries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDriversLicenseCountries(params?: { subscriptionKey?: string; APIURL?: string }): Observable<Array<DvCountries>> {
    return this.getDriversLicenseCountries$Response(params).pipe(map((r: StrictHttpResponse<Array<DvCountries>>) => r.body as Array<DvCountries>));
  }

  /**
   * Path part for operation patchDriversLicenses
   */
  static readonly PatchDriversLicensesPath = '/api/DriversLicenses/patch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchDriversLicenses()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchDriversLicenses$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: DriversLicense }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiDriversLicensesService.PatchDriversLicensesPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchDriversLicenses$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchDriversLicenses(params?: { subscriptionKey?: string; APIURL?: string; body?: DriversLicense }): Observable<boolean> {
    return this.patchDriversLicenses$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postDriversLicenses
   */
  static readonly PostDriversLicensesPath = '/api/DriversLicenses/post';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDriversLicenses()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDriversLicenses$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: DriversLicense }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiDriversLicensesService.PostDriversLicensesPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postDriversLicenses$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDriversLicenses(params?: { subscriptionKey?: string; APIURL?: string; body?: DriversLicense }): Observable<boolean> {
    return this.postDriversLicenses$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postDriversLicenseCodes
   */
  static readonly PostDriversLicenseCodesPath = '/api/DriversLicenses/post/code';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDriversLicenseCodes()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDriversLicenseCodes$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: DriversLicenseCode }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiDriversLicensesService.PostDriversLicenseCodesPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postDriversLicenseCodes$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postDriversLicenseCodes(params?: { subscriptionKey?: string; APIURL?: string; body?: DriversLicenseCode }): Observable<boolean> {
    return this.postDriversLicenseCodes$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
