/* tslint:disable */
/* eslint-disable */
export interface PeopleManagement {
  employedCount?: null | number;
  legalEntityID?: null | string;
  legalEntityName?: null | string;
  offBoardinfCount?: null | number;
  onboardingCount?: null | number;
  seatOnboarding?: null | number;
  seatOnboardingAssigned?: null | number;
}
