/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Counter } from '../models/counter';
import { StringArrayParamaters } from '../models/string-array-paramaters';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class SharedApiCountersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getFleetManagerApprovals
   */
  static readonly GetFleetManagerApprovalsPath = '/api/Counters/fleetmanagerapprovals/{legalEntityID}/{locationGroupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetManagerApprovals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetManagerApprovals$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    locationGroupID: string;
  }): Observable<StrictHttpResponse<Counter>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCountersService.GetFleetManagerApprovalsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('locationGroupID', params.locationGroupID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Counter>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetManagerApprovals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetManagerApprovals(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string; locationGroupID: string }): Observable<Counter> {
    return this.getFleetManagerApprovals$Response(params).pipe(map((r: StrictHttpResponse<Counter>) => r.body as Counter));
  }

  /**
   * Path part for operation getAssignedTasksOverdue
   */
  static readonly GetAssignedTasksOverduePath = '/api/Counters/assignedtasksoverdue/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAssignedTasksOverdue()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssignedTasksOverdue$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Counter>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCountersService.GetAssignedTasksOverduePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Counter>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAssignedTasksOverdue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssignedTasksOverdue(params: { subscriptionKey?: string; APIURL?: string; userID: string; legalEntityID: string }): Observable<Counter> {
    return this.getAssignedTasksOverdue$Response(params).pipe(map((r: StrictHttpResponse<Counter>) => r.body as Counter));
  }

  /**
   * Path part for operation getAssignedTasksDueToday
   */
  static readonly GetAssignedTasksDueTodayPath = '/api/Counters/assignedtasksduetoday/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAssignedTasksDueToday()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssignedTasksDueToday$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Counter>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCountersService.GetAssignedTasksDueTodayPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Counter>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAssignedTasksDueToday$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssignedTasksDueToday(params: { subscriptionKey?: string; APIURL?: string; userID: string; legalEntityID: string }): Observable<Counter> {
    return this.getAssignedTasksDueToday$Response(params).pipe(map((r: StrictHttpResponse<Counter>) => r.body as Counter));
  }

  /**
   * Path part for operation getAssignedApprovalTasks
   */
  static readonly GetAssignedApprovalTasksPath = '/api/Counters/assignedapprovaltasks/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAssignedApprovalTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssignedApprovalTasks$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Counter>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCountersService.GetAssignedApprovalTasksPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Counter>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAssignedApprovalTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssignedApprovalTasks(params: { subscriptionKey?: string; APIURL?: string; userID: string; legalEntityID: string }): Observable<Counter> {
    return this.getAssignedApprovalTasks$Response(params).pipe(map((r: StrictHttpResponse<Counter>) => r.body as Counter));
  }

  /**
   * Path part for operation getAssignedOutstandingTasks
   */
  static readonly GetAssignedOutstandingTasksPath = '/api/Counters/assignedoutstandingtasks/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAssignedOutstandingTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssignedOutstandingTasks$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Counter>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCountersService.GetAssignedOutstandingTasksPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Counter>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAssignedOutstandingTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssignedOutstandingTasks(params: { subscriptionKey?: string; APIURL?: string; userID: string; legalEntityID: string }): Observable<Counter> {
    return this.getAssignedOutstandingTasks$Response(params).pipe(map((r: StrictHttpResponse<Counter>) => r.body as Counter));
  }

  /**
   * Path part for operation getVotingOpenCount
   */
  static readonly GetVotingOpenCountPath = '/api/Counters/countvotingopen/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVotingOpenCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVotingOpenCount$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<Counter>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCountersService.GetVotingOpenCountPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<Counter>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVotingOpenCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVotingOpenCount(params: { subscriptionKey?: string; APIURL?: string; userID: string; legalEntityID: string }): Observable<Array<Counter>> {
    return this.getVotingOpenCount$Response(params).pipe(map((r: StrictHttpResponse<Array<Counter>>) => r.body as Array<Counter>));
  }

  /**
   * Path part for operation getYourFleetItemsEmployee
   */
  static readonly GetYourFleetItemsEmployeePath = '/api/Counters/yourfleetitemsemployee/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getYourFleetItemsEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getYourFleetItemsEmployee$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Counter>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCountersService.GetYourFleetItemsEmployeePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Counter>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getYourFleetItemsEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getYourFleetItemsEmployee(params: { subscriptionKey?: string; APIURL?: string; userID: string; legalEntityID: string }): Observable<Counter> {
    return this.getYourFleetItemsEmployee$Response(params).pipe(map((r: StrictHttpResponse<Counter>) => r.body as Counter));
  }

  /**
   * Path part for operation getYourFleetItemsManager
   */
  static readonly GetYourFleetItemsManagerPath = '/api/Counters/yourfleetitemsmanager';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getYourFleetItemsManager()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getYourFleetItemsManager$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: StringArrayParamaters;
  }): Observable<StrictHttpResponse<Counter>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCountersService.GetYourFleetItemsManagerPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Counter>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getYourFleetItemsManager$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getYourFleetItemsManager(params?: { subscriptionKey?: string; APIURL?: string; body?: StringArrayParamaters }): Observable<Counter> {
    return this.getYourFleetItemsManager$Response(params).pipe(map((r: StrictHttpResponse<Counter>) => r.body as Counter));
  }

  /**
   * Path part for operation getFleetItemsMovementStatusCount
   */
  static readonly GetFleetItemsMovementStatusCountPath = '/api/Counters/fleetitemsmovementstatuscount/{locationID}/{legalEntityID}/{movementStatus}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetItemsMovementStatusCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemsMovementStatusCount$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    locationID: string;
    legalEntityID: string;
    movementStatus: string;
  }): Observable<StrictHttpResponse<Counter>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCountersService.GetFleetItemsMovementStatusCountPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('locationID', params.locationID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('movementStatus', params.movementStatus, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Counter>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetItemsMovementStatusCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemsMovementStatusCount(params: {
    subscriptionKey?: string;
    APIURL?: string;
    locationID: string;
    legalEntityID: string;
    movementStatus: string;
  }): Observable<Counter> {
    return this.getFleetItemsMovementStatusCount$Response(params).pipe(map((r: StrictHttpResponse<Counter>) => r.body as Counter));
  }

  /**
   * Path part for operation getEmployeeBirthdaysComingUp
   */
  static readonly GetEmployeeBirthdaysComingUpPath = '/api/Counters/employeebirthdayscomingup/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeBirthdaysComingUp()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeBirthdaysComingUp$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<User>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCountersService.GetEmployeeBirthdaysComingUpPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<User>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeBirthdaysComingUp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeBirthdaysComingUp(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<User>> {
    return this.getEmployeeBirthdaysComingUp$Response(params).pipe(map((r: StrictHttpResponse<Array<User>>) => r.body as Array<User>));
  }
}
