/* eslint-disable no-console */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { GroupDescriptor, orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { EmployeeStatusFiltered, SharedApiEngageService, UserUserDto } from '@verde/api';
import { DeviceTypeService, UserService } from '@verde/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'verde-engage-tab',
  templateUrl: './engage-tab.component.html',
  styleUrls: ['./engage-tab.component.scss'],
})
export class EngageTabComponent implements OnInit, OnDestroy {
  //#region Variable Declarations
  private onDestroy$ = new Subject<boolean>();

  // Users profile
  user: UserUserDto;

  disableAnimation: boolean;

  // API Array
  allEngageTeam: Array<EmployeeStatusFiltered> = new Array<EmployeeStatusFiltered>();

  //#region Kendo variables
  // Engage Team
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  engageTeamGridData: GridDataResult[] | EmployeeStatusFiltered[];

  engageTeamGridView: GridDataResult;

  engageTeamState: State = {
    skip: 0,
    take: 5,
  };

  engageTeamSort: SortDescriptor[] = [
    {
      field: 'bt_fullnamewithempid',
      dir: 'asc',
    },
  ];

  engageTeamGroups: GroupDescriptor[];

  engageTeamSelection: any[] = [];

  engageTeamSelectedCallback = (args) => args.dataItem;

  //#endregion Kendo variables

  //#endregion Variable Declarations

  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private sharedApiEngageService: SharedApiEngageService,
    public deviceTypeService: DeviceTypeService,
  ) {}

  ngOnInit() {
    this.spinner.show('engagetab');

    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => {
      this.user = config.user;
      this.user = config.user;
    });

    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe((data) => {
      this.disableAnimation = data;
    });

    this.getEngageTeam();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  refresh() {
    this.spinner.show('engagetab');
    this.getEngageTeam();
  }

  getEngageTeam() {
    this.sharedApiEngageService
      .getEngageTeam({ email: this.user?.employeeId })
      .pipe(takeUntil(this.onDestroy$), distinctUntilChanged())
      .subscribe(
        (ret) => {
          this.allEngageTeam = ret;
          this.engageTeamGridData = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.loadEngageTeam();
          this.spinner.hide('engagetab');
        },
      );
  }

  //#region Kendo Methods
  // Row style callback
  rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  // Engage Team
  loadEngageTeam(): void {
    this.engageTeamGridView = {
      data: orderBy(this.allEngageTeam, this.engageTeamSort),
      total: this.allEngageTeam.length,
    };
  }

  engageTeamGroupChange(groups: GroupDescriptor[]): void {
    this.engageTeamGroups = groups;
    this.engageTeamGridView = process(this.allEngageTeam, { group: this.engageTeamGroups });
  }

  engageTeamDataStateChange(state: DataStateChangeEvent): void {
    this.engageTeamState = state;
    this.engageTeamGridView = process(this.allEngageTeam, this.engageTeamState);
  }

  engageTeamSortChange(sort: SortDescriptor[]): void {
    this.engageTeamSort = sort;
    this.loadEngageTeam();
  }

  onEngageTeamFilter(inputValue: string): void {
    this.engageTeamGridData = process(this.allEngageTeam, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'bthr_EmployeeID.bt_fullnamewithempid',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'bt_status',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'bt_presencestatussource',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'odataHealthStatus',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'odataLocationUpdated',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  //#endregion Kendo Methods
}
