/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BtVehicleInspectionChecklistDetailVehic } from '../models/bt-vehicle-inspection-checklist-detail-vehic';
import { FleetAccess } from '../models/fleet-access';
import { FleetChecklist } from '../models/fleet-checklist';
import { FleetItemMovementHistory } from '../models/fleet-item-movement-history';
import { FleetMovement } from '../models/fleet-movement';
import { FleetRental } from '../models/fleet-rental';
import { RentalReason } from '../models/rental-reason';
import { VehicleAccessoryLink } from '../models/vehicle-accessory-link';
import { VehiclesFiltered } from '../models/vehicles-filtered';
import { VerdeSupplier } from '../models/verde-supplier';
import { VerdeSupplierReason } from '../models/verde-supplier-reason';

@Injectable({
  providedIn: 'root',
})
export class SharedApiFleetMovementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation postFleetMovement
   */
  static readonly PostFleetMovementPath = '/api/FleetMovement/post/fleetmovement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFleetMovement()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetMovement$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetMovement }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.PostFleetMovementPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postFleetMovement$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetMovement(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetMovement }): Observable<boolean> {
    return this.postFleetMovement$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postFleetRental
   */
  static readonly PostFleetRentalPath = '/api/FleetMovement/post/fleetrental';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFleetRental()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetRental$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetRental }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.PostFleetRentalPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postFleetRental$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetRental(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetRental }): Observable<boolean> {
    return this.postFleetRental$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getRentalReasons
   */
  static readonly GetRentalReasonsPath = '/api/FleetMovement/rentalreasons/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRentalReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalReasons$Response(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<StrictHttpResponse<Array<RentalReason>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetRentalReasonsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<RentalReason>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRentalReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalReasons(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<RentalReason>> {
    return this.getRentalReasons$Response(params).pipe(map((r: StrictHttpResponse<Array<RentalReason>>) => r.body as Array<RentalReason>));
  }

  /**
   * Path part for operation getFleetMovementById
   */
  static readonly GetFleetMovementByIdPath = '/api/FleetMovement/fleetmovementbyid/{movementID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetMovementById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetMovementById$Response(params: { subscriptionKey?: string; APIURL?: string; movementID: string }): Observable<StrictHttpResponse<FleetMovement>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetFleetMovementByIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('movementID', params.movementID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FleetMovement>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetMovementById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetMovementById(params: { subscriptionKey?: string; APIURL?: string; movementID: string }): Observable<FleetMovement> {
    return this.getFleetMovementById$Response(params).pipe(map((r: StrictHttpResponse<FleetMovement>) => r.body as FleetMovement));
  }

  /**
   * Path part for operation getFleetMovements
   */
  static readonly GetFleetMovementsPath = '/api/FleetMovement/fleetmovements/{userID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetMovements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetMovements$Response(params: { subscriptionKey?: string; APIURL?: string; userID: string }): Observable<StrictHttpResponse<Array<FleetMovement>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetFleetMovementsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetMovement>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetMovements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetMovements(params: { subscriptionKey?: string; APIURL?: string; userID: string }): Observable<Array<FleetMovement>> {
    return this.getFleetMovements$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetMovement>>) => r.body as Array<FleetMovement>));
  }

  /**
   * Path part for operation getFleetMovementsByLocation
   */
  static readonly GetFleetMovementsByLocationPath = '/api/FleetMovement/fleetmovementsbylocation/{locationID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetMovementsByLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetMovementsByLocation$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    locationID: string;
  }): Observable<StrictHttpResponse<Array<FleetMovement>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetFleetMovementsByLocationPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('locationID', params.locationID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetMovement>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetMovementsByLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetMovementsByLocation(params: { subscriptionKey?: string; APIURL?: string; locationID: string }): Observable<Array<FleetMovement>> {
    return this.getFleetMovementsByLocation$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetMovement>>) => r.body as Array<FleetMovement>));
  }

  /**
   * Path part for operation patchFleetMovement
   */
  static readonly PatchFleetMovementPath = '/api/FleetMovement/patch/fleetmovement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFleetMovement()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchFleetMovement$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetMovement }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.PatchFleetMovementPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchFleetMovement$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchFleetMovement(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetMovement }): Observable<boolean> {
    return this.patchFleetMovement$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchFleetAccessoryLink
   */
  static readonly PatchFleetAccessoryLinkPath = '/api/FleetMovement/patch/fleetaccessorylink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFleetAccessoryLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchFleetAccessoryLink$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VehicleAccessoryLink;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.PatchFleetAccessoryLinkPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchFleetAccessoryLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchFleetAccessoryLink(params?: { subscriptionKey?: string; APIURL?: string; body?: VehicleAccessoryLink }): Observable<boolean> {
    return this.patchFleetAccessoryLink$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getFleetAccess
   */
  static readonly GetFleetAccessPath = '/api/FleetMovement/fleetaccess/{userID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetAccess$Response(params: { subscriptionKey?: string; APIURL?: string; userID: string }): Observable<StrictHttpResponse<Array<FleetAccess>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetFleetAccessPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetAccess>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetAccess(params: { subscriptionKey?: string; APIURL?: string; userID: string }): Observable<Array<FleetAccess>> {
    return this.getFleetAccess$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetAccess>>) => r.body as Array<FleetAccess>));
  }

  /**
   * Path part for operation getRequestEmployeeFleetMovements
   */
  static readonly GetRequestEmployeeFleetMovementsPath = '/api/FleetMovement/requestemployeefleetmovements/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestEmployeeFleetMovements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestEmployeeFleetMovements$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<FleetMovement>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetRequestEmployeeFleetMovementsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetMovement>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequestEmployeeFleetMovements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestEmployeeFleetMovements(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<Array<FleetMovement>> {
    return this.getRequestEmployeeFleetMovements$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetMovement>>) => r.body as Array<FleetMovement>));
  }

  /**
   * Path part for operation getRequestNewEmployeeFleetMovements
   */
  static readonly GetRequestNewEmployeeFleetMovementsPath = '/api/FleetMovement/requestnewemployeefleetmovements/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestNewEmployeeFleetMovements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestNewEmployeeFleetMovements$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<FleetMovement>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetRequestNewEmployeeFleetMovementsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetMovement>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequestNewEmployeeFleetMovements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestNewEmployeeFleetMovements(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<FleetMovement>> {
    return this.getRequestNewEmployeeFleetMovements$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetMovement>>) => r.body as Array<FleetMovement>));
  }

  /**
   * Path part for operation getFleetMovementsByFleetItemId
   */
  static readonly GetFleetMovementsByFleetItemIdPath = '/api/FleetMovement/fleetmovementsbyfleetitemid/{fleetItemID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetMovementsByFleetItemId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetMovementsByFleetItemId$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    fleetItemID: string;
  }): Observable<StrictHttpResponse<Array<FleetMovement>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetFleetMovementsByFleetItemIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('fleetItemID', params.fleetItemID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetMovement>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetMovementsByFleetItemId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetMovementsByFleetItemId(params: { subscriptionKey?: string; APIURL?: string; fleetItemID: string }): Observable<Array<FleetMovement>> {
    return this.getFleetMovementsByFleetItemId$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetMovement>>) => r.body as Array<FleetMovement>));
  }

  /**
   * Path part for operation getRequestEmployeeItems
   */
  static readonly GetRequestEmployeeItemsPath = '/api/FleetMovement/requestemployeefleetitems/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequestEmployeeItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestEmployeeItems$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VehiclesFiltered>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetRequestEmployeeItemsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VehiclesFiltered>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRequestEmployeeItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequestEmployeeItems(params: { subscriptionKey?: string; APIURL?: string; userID: string; legalEntityID: string }): Observable<Array<VehiclesFiltered>> {
    return this.getRequestEmployeeItems$Response(params).pipe(map((r: StrictHttpResponse<Array<VehiclesFiltered>>) => r.body as Array<VehiclesFiltered>));
  }

  /**
   * Path part for operation getFleetChecklistById
   */
  static readonly GetFleetChecklistByIdPath = '/api/FleetMovement/fleetchecklistsummarybyid/{checklistID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetChecklistById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetChecklistById$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    checklistID: string;
  }): Observable<StrictHttpResponse<Array<FleetChecklist>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetFleetChecklistByIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('checklistID', params.checklistID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetChecklist>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetChecklistById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetChecklistById(params: { subscriptionKey?: string; APIURL?: string; checklistID: string }): Observable<Array<FleetChecklist>> {
    return this.getFleetChecklistById$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetChecklist>>) => r.body as Array<FleetChecklist>));
  }

  /**
   * Path part for operation getFleetChecklists
   */
  static readonly GetFleetChecklistsPath = '/api/FleetMovement/fleetmovementchecklist/{movementID}/{responderID}/{legalEntityID}/{transferdirection}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetChecklists()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetChecklists$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementID: string;
    responderID: string;
    legalEntityID: string;
    transferdirection: string;
  }): Observable<StrictHttpResponse<Array<FleetChecklist>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetFleetChecklistsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('movementID', params.movementID, {});
      rb.path('responderID', params.responderID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('transferdirection', params.transferdirection, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetChecklist>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetChecklists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetChecklists(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementID: string;
    responderID: string;
    legalEntityID: string;
    transferdirection: string;
  }): Observable<Array<FleetChecklist>> {
    return this.getFleetChecklists$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetChecklist>>) => r.body as Array<FleetChecklist>));
  }

  /**
   * Path part for operation getFleetManagerChecklists
   */
  static readonly GetFleetManagerChecklistsPath = '/api/FleetMovement/fleetmanagerchecklists/{movementID}/{legalEntityID}/{locationID}/{transferdirection}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetManagerChecklists()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetManagerChecklists$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementID: string;
    legalEntityID: string;
    locationID: string;
    transferdirection: string;
  }): Observable<StrictHttpResponse<Array<FleetChecklist>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetFleetManagerChecklistsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('movementID', params.movementID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('locationID', params.locationID, {});
      rb.path('transferdirection', params.transferdirection, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetChecklist>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetManagerChecklists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetManagerChecklists(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementID: string;
    legalEntityID: string;
    locationID: string;
    transferdirection: string;
  }): Observable<Array<FleetChecklist>> {
    return this.getFleetManagerChecklists$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetChecklist>>) => r.body as Array<FleetChecklist>));
  }

  /**
   * Path part for operation getFleetItemChecklists
   */
  static readonly GetFleetItemChecklistsPath = '/api/FleetMovement/fleetitemchecklists/{fleetItemID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetItemChecklists()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemChecklists$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    fleetItemID: string;
  }): Observable<StrictHttpResponse<Array<FleetChecklist>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetFleetItemChecklistsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('fleetItemID', params.fleetItemID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetChecklist>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetItemChecklists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemChecklists(params: { subscriptionKey?: string; APIURL?: string; fleetItemID: string }): Observable<Array<FleetChecklist>> {
    return this.getFleetItemChecklists$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetChecklist>>) => r.body as Array<FleetChecklist>));
  }

  /**
   * Path part for operation getFleetChecklistsByMovementId
   */
  static readonly GetFleetChecklistsByMovementIdPath = '/api/FleetMovement/fleetchecklistsbymovementid/{movementID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetChecklistsByMovementId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetChecklistsByMovementId$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    movementID: string;
  }): Observable<StrictHttpResponse<Array<FleetChecklist>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetFleetChecklistsByMovementIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('movementID', params.movementID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetChecklist>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetChecklistsByMovementId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetChecklistsByMovementId(params: { subscriptionKey?: string; APIURL?: string; movementID: string }): Observable<Array<FleetChecklist>> {
    return this.getFleetChecklistsByMovementId$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetChecklist>>) => r.body as Array<FleetChecklist>));
  }

  /**
   * Path part for operation patchFleetChecklistSummary
   */
  static readonly PatchFleetChecklistSummaryPath = '/api/FleetMovement/patch/fleetchecklistsummary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFleetChecklistSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchFleetChecklistSummary$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetChecklist }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.PatchFleetChecklistSummaryPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchFleetChecklistSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchFleetChecklistSummary(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetChecklist }): Observable<boolean> {
    return this.patchFleetChecklistSummary$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchFleetChecklistDetail
   */
  static readonly PatchFleetChecklistDetailPath = '/api/FleetMovement/patch/fleetchecklistdetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFleetChecklistDetail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchFleetChecklistDetail$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: BtVehicleInspectionChecklistDetailVehic;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.PatchFleetChecklistDetailPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchFleetChecklistDetail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchFleetChecklistDetail(params?: { subscriptionKey?: string; APIURL?: string; body?: BtVehicleInspectionChecklistDetailVehic }): Observable<boolean> {
    return this.patchFleetChecklistDetail$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getMonthlyChecklistsFleetManager
   */
  static readonly GetMonthlyChecklistsFleetManagerPath = '/api/FleetMovement/monthlychecklistsfleetmanager/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonthlyChecklistsFleetManager()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonthlyChecklistsFleetManager$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<FleetChecklist>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetMonthlyChecklistsFleetManagerPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetChecklist>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMonthlyChecklistsFleetManager$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonthlyChecklistsFleetManager(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<FleetChecklist>> {
    return this.getMonthlyChecklistsFleetManager$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetChecklist>>) => r.body as Array<FleetChecklist>));
  }

  /**
   * Path part for operation getMonthlyChecklistsTeamManager
   */
  static readonly GetMonthlyChecklistsTeamManagerPath = '/api/FleetMovement/monthlycheckliststeammanager/{legalEntityID}/{managerID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonthlyChecklistsTeamManager()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonthlyChecklistsTeamManager$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    managerID: string;
  }): Observable<StrictHttpResponse<Array<FleetChecklist>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetMonthlyChecklistsTeamManagerPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('managerID', params.managerID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetChecklist>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMonthlyChecklistsTeamManager$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonthlyChecklistsTeamManager(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    managerID: string;
  }): Observable<Array<FleetChecklist>> {
    return this.getMonthlyChecklistsTeamManager$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetChecklist>>) => r.body as Array<FleetChecklist>));
  }

  /**
   * Path part for operation getMonthlyChecklistsResponderId
   */
  static readonly GetMonthlyChecklistsResponderIdPath = '/api/FleetMovement/monthlychecklistsresponderid/{legalEntityID}/{responderID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMonthlyChecklistsResponderId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonthlyChecklistsResponderId$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    responderID: string;
  }): Observable<StrictHttpResponse<Array<FleetChecklist>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetMonthlyChecklistsResponderIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('responderID', params.responderID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetChecklist>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMonthlyChecklistsResponderId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMonthlyChecklistsResponderId(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    responderID: string;
  }): Observable<Array<FleetChecklist>> {
    return this.getMonthlyChecklistsResponderId$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetChecklist>>) => r.body as Array<FleetChecklist>));
  }

  /**
   * Path part for operation getFleetItemMovementHistory
   */
  static readonly GetFleetItemMovementHistoryPath = '/api/FleetMovement/fleetitemmovementhistory/{vehicleID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetItemMovementHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemMovementHistory$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    vehicleID: string;
  }): Observable<StrictHttpResponse<Array<FleetItemMovementHistory>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetFleetItemMovementHistoryPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('vehicleID', params.vehicleID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetItemMovementHistory>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetItemMovementHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemMovementHistory(params: { subscriptionKey?: string; APIURL?: string; vehicleID: string }): Observable<Array<FleetItemMovementHistory>> {
    return this.getFleetItemMovementHistory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FleetItemMovementHistory>>) => r.body as Array<FleetItemMovementHistory>),
    );
  }

  /**
   * Path part for operation getAllVerdeSuppliers
   */
  static readonly GetAllVerdeSuppliersPath = '/api/FleetMovement/verdesuppliers/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllVerdeSuppliers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVerdeSuppliers$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VerdeSupplier>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetAllVerdeSuppliersPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdeSupplier>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllVerdeSuppliers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVerdeSuppliers(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<VerdeSupplier>> {
    return this.getAllVerdeSuppliers$Response(params).pipe(map((r: StrictHttpResponse<Array<VerdeSupplier>>) => r.body as Array<VerdeSupplier>));
  }

  /**
   * Path part for operation getVerdeSupplierReasons
   */
  static readonly GetVerdeSupplierReasonsPath = '/api/FleetMovement/fleetsupplierreasons/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeSupplierReasons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeSupplierReasons$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VerdeSupplierReason>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.GetVerdeSupplierReasonsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdeSupplierReason>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeSupplierReasons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeSupplierReasons(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<VerdeSupplierReason>> {
    return this.getVerdeSupplierReasons$Response(params).pipe(map((r: StrictHttpResponse<Array<VerdeSupplierReason>>) => r.body as Array<VerdeSupplierReason>));
  }

  /**
   * Path part for operation patchVerdeSupplier
   */
  static readonly PatchVerdeSupplierPath = '/api/FleetMovement/patch/verdesupplier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVerdeSupplier()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeSupplier$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeSupplier }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.PatchVerdeSupplierPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVerdeSupplier$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeSupplier(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeSupplier }): Observable<boolean> {
    return this.patchVerdeSupplier$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postVerdeSupplier
   */
  static readonly PostVerdeSupplierPath = '/api/FleetMovement/post/verdesupplier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVerdeSupplier()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeSupplier$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeSupplier }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFleetMovementService.PostVerdeSupplierPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVerdeSupplier$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeSupplier(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeSupplier }): Observable<boolean> {
    return this.postVerdeSupplier$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
