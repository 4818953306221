import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { DataStateChangeEvent, FilterableSettings, GridDataResult, RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { AggregateDescriptor, CompositeFilterDescriptor, GroupDescriptor, SortDescriptor, State, orderBy, process } from '@progress/kendo-data-query';
import {
  BtVerdeManualDocumentsControl,
  SharedApiFileService,
  SharedApiVerdeDocumentsService,
  UserAppConfigDto,
  UserUserDto,
  VerdeManualDocuments,
} from '@verde/api';
import { UserService } from '@verde/core';
import { DotsMenuItem, ManualDocumentsDataService, ModalService } from '@verde/shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'verde-other-documents',
  templateUrl: './other-documents.component.html',
  styleUrls: ['./other-documents.component.scss'],
})
export class OtherDocumentsComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();
  @ViewChild('GroupMyGrid') private grid: any;
  selectedItem: VerdeManualDocuments | undefined;
  groupDotMenuItems: DotsMenuItem[] = [];
  GroupGridData: VerdeManualDocuments[] = [];
  GroupGridDataSource: VerdeManualDocuments[] = [];
  public distinctCategories: BtVerdeManualDocumentsControl[] = [];
  GroupAggregates: AggregateDescriptor[] = [{ field: 'bt_VerdeManualDocumentsControl.btGroupValueODataCommunityDisplayV1FormattedValue', aggregate: 'count' }];

  GroupGroups: GroupDescriptor[] = [
    { field: 'bt_VerdeManualDocumentsControl.btGroupValueODataCommunityDisplayV1FormattedValue', aggregates: this.GroupAggregates },
  ];

  GroupState: State = {
    skip: 0,
    take: 5,
  };
  GroupGridView: GridDataResult | undefined;

  allGroupData: Array<any> = new Array<any>();

  GroupSort: SortDescriptor[] = [
    {
      field: 'bt_VerdeManualDocumentsControl.btGroupValueODataCommunityDisplayV1FormattedValue',
      dir: 'asc',
    },
  ];

  public selectableSettings: SelectableSettings = {
    enabled: true,
    checkboxOnly: true,
  };

  GroupSelection: any[] = [];
  GroupSelectedCallback = (args: { dataItem: any }) => args.dataItem;

  public GroupFilterMode: FilterableSettings = 'row';
  public checked = false;
  public filter: CompositeFilterDescriptor | undefined;

  public onModeChange(mode: FilterableSettings): void {
    this.GroupFilterMode = mode;
  }

  config: UserAppConfigDto = {};
  disableAnimation: boolean = false;
  otherDocsLoading: boolean = true;

  //OpenDoc
  maxFileSize: number = 5242880;
  manualDocument: VerdeManualDocuments = {};
  manualDocumentFile: string = '';
  manualDocumentFileUserUpload: string = '';
  manualDocumentsSubmit: string = 'Continue';
  uploadDocument: File | undefined;
  updateManualDocumentBody: VerdeManualDocuments = {};
  confirmationMessage: string = '';
  acknowledgeYes: boolean = false;
  acknowledgeNo: boolean = true;
  objectYes: boolean = false;
  objectNo: boolean = true;

  constructor(
    private userService: UserService,
    private modalService: ModalService,

    private spinner: NgxSpinnerService,
    private manualDocumentsDataService: ManualDocumentsDataService,
    private sharedApiFileService: SharedApiFileService,
    private sharedapiverdedocumentsservice: SharedApiVerdeDocumentsService,
  ) {
    this.manualDocumentsDataService.getUserUpdates().subscribe((user: UserUserDto) => {
      this.GetGroupHRData(user);
    });
  }

  ngOnInit(): void {
    this.GetGroupData();
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });

    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => {
      this.config = config;
    });

    this.manualDocumentsDataService.manualDocumentFile.pipe(takeUntil(this.onDestroy$)).subscribe((doc) => {
      this.manualDocumentFile = doc;
    });

    this.manualDocumentsDataService.manualDocumentFileUserUpload.pipe(takeUntil(this.onDestroy$)).subscribe((doc) => {
      this.manualDocumentFileUserUpload = doc;
    });
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  acknowledgeYesChange(e: any, element: FormControlDirective) {
    this.acknowledgeYes = e;
    this.acknowledgeNo = !e;
    element.reset();

    if (this.acknowledgeYes) {
      this.manualDocumentsSubmit = 'Submit';
    }
  }

  acknowledgeNoChange(e: any, element: FormControlDirective) {
    this.acknowledgeNo = e;
    this.acknowledgeYes = !e;
    element.reset();

    if (this.acknowledgeNo) {
      this.manualDocumentsSubmit = 'Continue';
    }
  }

  objectYesChange(e: any, element: FormControlDirective) {
    this.objectYes = e;
    this.objectNo = !e;
    element.reset();

    if (this.acknowledgeYes) {
      this.manualDocumentsSubmit = 'Submit';
    }
  }

  objectNoChange(e: any, element: FormControlDirective) {
    this.objectNo = e;
    this.objectYes = !e;
    element.reset();

    if (this.acknowledgeYes) {
      this.manualDocumentsSubmit = 'Submit';
    }
  }

  patchManualDocument() {
    if (this.manualDocumentsSubmit === 'Submit') {
      this.spinner.show('confirmmanualdocumentupload');
      this.modalService.open('confirmDocumentManualModal');

      if (this.manualDocument?.bt_VerdeManualDocumentsControl?.bt_acknowledge) {
        this.updateManualDocumentBody.bt_acknowledgement = this.acknowledgeYes;
      }

      if (this.manualDocument?.bt_VerdeManualDocumentsControl?.bt_objection) {
        this.updateManualDocumentBody.bt_allowobjection = this.objectYes;
      }

      if (this.objectNo && !this.objectYes) {
        this.updateManualDocumentBody.bt_documentname = '';
      }

      this.updateManualDocumentBody.bt_attached = true;
      this.updateManualDocumentBody.bt_verdemanualdocumentsid = this.manualDocument.bt_verdemanualdocumentsid;

      this.sharedapiverdedocumentsservice
        .patchVerdeManualDocuments({ body: this.updateManualDocumentBody })
        .pipe(take(1))
        .subscribe(
          () => {
            //
          },
          (error) => {
            console.error(error);
          },
          () => {
            if (this.manualDocument?.bt_VerdeManualDocumentsControl?.bt_acknowledge) {
              this.confirmationMessage = 'You have successfully submitted your ' + this.manualDocument.bt_VerdeManualDocumentsControl.bt_description + '.';
              this.GetGroupData();
              this.spinner.hide('confirmmanualdocumentupload');
            }
          },
        );

      if (!this.manualDocument?.bt_VerdeManualDocumentsControl?.bt_acknowledge) {
        this.sharedApiFileService
          .patchVerdeManualDocument({ recordID: this.manualDocument.bt_verdemanualdocumentsid!, body: { file: this.uploadDocument } })
          .pipe(take(1))
          .subscribe(
            () => {
              //
            },
            (error) => {
              console.error(error);
            },
            () => {
              this.confirmationMessage = 'You have successfully submitted your ' + this.manualDocument.bt_VerdeManualDocumentsControl!.bt_description + '.';
              this.spinner.hide('confirmmanualdocumentupload');
              this.GetGroupData();
            },
          );
      } else if (
        this.manualDocument?.bt_VerdeManualDocumentsControl?.bt_acknowledge &&
        this.manualDocument?.bt_VerdeManualDocumentsControl?.bt_objection &&
        this.objectYes
      ) {
        this.sharedApiFileService
          .patchVerdeManualDocument({ recordID: this.manualDocument.bt_verdemanualdocumentsid!, body: { file: this.uploadDocument } })
          .pipe(take(1))
          .subscribe(
            () => {
              //
            },
            (error) => {
              console.error(error);
            },
            () => {
              this.GetGroupData();
            },
          );
      }
    }
  }

  // File
  manualDocumentSelected(event: any, element: FormControlDirective) {
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files[0].size < this.maxFileSize) {
        const splitName = event.target.files[0].name.split('.');
        const extension = splitName[splitName.length - 1];
        this.uploadDocument = new File([event.target.files[0]], this.manualDocument.bt_referenceno + '.' + extension);

        if (!this.manualDocument?.bt_VerdeManualDocumentsControl?.bt_acknowledge) {
          this.manualDocumentsSubmit = 'Submit';
        }

        this.updateManualDocumentBody.bt_documentname = this.uploadDocument.name;
      } else {
        alert('File: ' + event.target.files[0].name + 'is too large to upload.');
        element.reset();
      }
    }
  }

  downloadFile(base64: string, fileName: string) {
    const link = document.createElement('a');
    link.href = 'data:application/pdf;base64,' + base64;
    link.download = fileName;
    link.click();
  }

  //#region Group Grid Stuff

  GetGroupData() {
    this.spinner.show('GroupGridSpinner');
    this.sharedapiverdedocumentsservice
      .getVerdeAllDocuments({
        userID: this.userService.proxyUser.employeeId,
        legalEntityID: this.userService.proxyUser.legalEntityId,
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          ret?.forEach((t) => {
            if (t.bt_VerdeManualDocumentsControl?.bt_completed == false) {
              t.bt_VerdeManualDocumentsControl!.btCompletedODataCommunityDisplayV1FormattedValue = 'No';
            } else {
              t.bt_VerdeManualDocumentsControl!.btCompletedODataCommunityDisplayV1FormattedValue = 'Yes';
            }
          });
          this.GroupGridDataSource = ret;
          this.GroupGridData = ret;
          this.otherDocsLoading = false;
        },
        (error) => {
          console.error(error);
          this.otherDocsLoading = false;
        },
        () => {
          this.otherDocsLoading = false;
          this.spinner.hide('GroupGridSpinner');
        },
      );
  }

  GetGroupHRData(user: UserUserDto) {
    this.spinner.show('GroupGridSpinner');
    this.sharedapiverdedocumentsservice
      .getVerdeAllDocuments({
        userID: user.employeeId,
        legalEntityID: user.legalEntityId,
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          ret?.forEach((t) => {
            if (t.bt_VerdeManualDocumentsControl?.bt_completed == false) {
              t.bt_VerdeManualDocumentsControl!.btCompletedODataCommunityDisplayV1FormattedValue = 'No';
            } else {
              t.bt_VerdeManualDocumentsControl!.btCompletedODataCommunityDisplayV1FormattedValue = 'Yes';
            }
          });
          this.GroupGridDataSource = ret;
          this.GroupGridData = ret;
          this.otherDocsLoading = false;
        },
        (error) => {
          console.error(error);
          this.otherDocsLoading = false;
        },
        () => {
          this.otherDocsLoading = false;
          this.spinner.hide('GroupGridSpinner');
        },
      );
  }

  groupRowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  groupMenuClicked(parentItem: any) {
    this.selectedItem = parentItem;
    this.manualDocument = parentItem;

    this.groupDotMenuItems = [];
    let tempItems: DotsMenuItem[] = [];

    tempItems.push({ id: 'open', text: 'Open' });

    this.groupDotMenuItems = [...tempItems];
  }

  GroupMenuItemClicked(menuItem: DotsMenuItem) {
    if (menuItem.id === 'open') {
      this.acknowledgeYes = this.selectedItem?.bt_acknowledgement!;

      this.acknowledgeNo = !this.acknowledgeYes;

      if (this.selectedItem?.bt_allowobjection && this.selectedItem.bt_attached) {
        this.objectYes = true;

        this.objectNo = !this.objectYes;
      }

      this.manualDocumentsDataService.getVerdeManualDocumentFile(this.selectedItem!.bt_VerdeManualDocumentsControl!.bt_verdemanualdocumentcontrolid!);

      if (this.manualDocument.bt_attached && this.manualDocument.bt_documentname != null) {
        this.manualDocumentsDataService.getVerdeManualDocumentFileUserUpload(this.selectedItem!.bt_verdemanualdocumentsid!);
      }

      //this.manualDocument = this.selectedItem!;
      this.modalService.open('manualDocumentsModalGroup');
    }
  }
  GroupGroupChange(group: GroupDescriptor[]): void {
    group.map((group) => (group.aggregates = this.GroupAggregates));

    this.GroupGroups = group;
  }
  GroupDataStateChange(state: DataStateChangeEvent): void {
    this.GroupState = state;
    this.GroupGridView = process(this.allGroupData, this.GroupState);
  }

  GroupSortChange(sort: SortDescriptor[]): void {
    this.GroupSort = sort;
    this.loadGroupData();
  }

  private loadGroupData(): void {
    this.GroupGridView = {
      data: orderBy(this.allGroupData, this.GroupSort),
      total: this.allGroupData.length,
    };

    this.GroupGridView.data?.forEach((_, index) => {
      this.grid?.collapseGroup(String(index));
    });
  }

  refresh() {
    this.GetGroupData();
  }

  //#endregion
}
