import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { ProcessesApiProcessesService, ProcessesBthrTerminationreason, UserAppConfigDto, UserBtResignationNotice, UserUserDto } from '@verde/api';
import { UserService } from '@verde/core';
import { ModalService, VerdeApprovalService } from '@verde/shared';
import { TerminationService } from 'apps/verde/src/app/features/profile2/services/termination.service';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'verde-employee-resignation',
  templateUrl: './employee-resignation.component.html',
  styleUrls: ['./employee-resignation.component.scss'],
})
export class EmployeeResignationComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  // Config
  config: UserAppConfigDto;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['pdf'],
  };

  public registerForm: FormGroup = new FormGroup({
    legalEntity: new FormControl(),
    employee: new FormControl(),
    department: new FormControl(),
    joinDate: new FormControl(),
    noticeDate: new FormControl(),
    exitReason: new FormControl(),
    exitDate: new FormControl(),
    comments: new FormControl(),
    fileUpload: new FormControl(),
  });

  editorId: string = 'commentEditor';
  terminationReasons = ProcessesBthrTerminationreason;
  public resignationTypes: any[] = [];
  uploadedFiles: File[] = [];
  user: UserUserDto;
  joinDate: Date;
  gridColWidth = '1fr';
  editorValue: string;
  todayDate: Date = new Date();
  noticePeriod: any;
  exitDate: Date = new Date();
  minExitDate: Date = new Date();
  resignationNotice: string;
  terminationOptions: string[];
  confirmMessage: string;
  public terminationOptionsList: Array<{ value: string; text: number }>;

  constructor(
    private userService: UserService,
    private processesApiProcessesService: ProcessesApiProcessesService,
    private sidebarService: VerdeApprovalService,
    private modalService: ModalService,
    private terminationService: TerminationService,
  ) {}

  ngOnInit(): void {
    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.config = data;
      if (this.config?.legalEntityConfig?.resignationNotice === UserBtResignationNotice.NoticeonScreen) {
        this.resignationNotice = 'NoticeOnScreen';
      } else if (this.config?.legalEntityConfig?.resignationNotice === UserBtResignationNotice.AttachNotice) {
        this.resignationNotice = 'AttachNotice';
      } else if (this.config?.legalEntityConfig?.resignationNotice === UserBtResignationNotice.Both) {
        this.resignationNotice = 'Both';
      }
    });

    this.terminationOptions = this.userService.user.terminationOptions; //.split(/;\s*|\s*,\s*/);
    this.terminationOptionsList = this.terminationOptions.map((word, index) => ({ value: word.trim(), text: index + 1 }));

    this.user = this.userService.user;
    this.joinDate = new Date(this.user.newOrganizationJoinDate);
    this.noticePeriod = this.userService.user.noticeDays;
    this.exitDate.setDate(this.todayDate.getDate() + this.noticePeriod);
    this.resignationTypes = Object.keys(this.terminationReasons).map((key) => ({
      text: this.terminationReasons[key],
      value: key,
    }));
    const currentDate = new Date();
    this.minExitDate.setDate(currentDate.getDate() + 30);
    this.registerForm = new FormGroup({
      legalEntity: new FormControl(this.user.legalEntityName),
      employee: new FormControl(this.user),
      department: new FormControl(this.user),
      joinDate: new FormControl(this.joinDate),
      exitDate: new FormControl(this.exitDate),
      noticeDate: new FormControl(this.todayDate),
      noticePeriod: new FormControl(this.userService.user.noticeDays),
      resignationType: new FormControl(),
      editor: new FormControl(),
      fileUpload: new FormControl(Validators.required),
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  calculateNoticePeriod(date) {
    let currentDate = new Date();
    let days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
    let noticePeriod: number;
    if (days >= 365) {
      noticePeriod = 30;
    } else if (days < 365 && days >= 180) {
      noticePeriod = 14;
    } else {
      noticePeriod = 7;
    }
    this.noticePeriod = noticePeriod;
    return noticePeriod;
  }

  onNoticeDateChange(event: any) {
    if (this.noticePeriod) {
      event.setDate(event.getDate() + this.noticePeriod);
    }
    this.registerForm.get('exitDate').setValue(event);
    this.minExitDate = event;
  }

  process() {
    this.registerForm.markAllAsTouched();
    if (this.resignationNotice === 'AttachNotice' || this.resignationNotice === 'Both') {
      if (this.uploadedFiles.length === 0) {
        this.modalService.open('requiredFieldsModal');
      } else {
        if (this.registerForm.valid) {
          this.confirmMessage = 'Please confirm that you would like to submit your termination notice';
          this.modalService.open('confirmationModal');
        }
      }
    }
  }

  closeModal() {
    this.modalService.close('requiredFieldsModal');
  }

  confirmResignation() {
    this.postTermination();
  }

  postTermination() {
    this.processesApiProcessesService
      .postEmployeeResignation({
        body: {
          EmployeeID: this.userService.user.employeeId,
          LegalEntityID: this.userService.user.legalEntityId,
          bthr_terminationcomment: this.registerForm.controls.editor.value,
          filesToUpload: this.uploadedFiles,
          bthr_resignationdate: this.registerForm.value.exitDate,
          bthr_terminationnoticedate: this.registerForm.value.noticeDate,
          bthr_terminationreason: this.registerForm.value.resignationType.value,
        },
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.terminationService.notifySidePanelClosed();
          this.sidebarService.setShowSidebar(false);
        },
      );
  }

  editorValueChange(event: any) {
    this.editorValue = event;
  }

  onFileSelect(event: any) {
    const selectedFiles: FileList = event;
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      if (!this.uploadedFiles.includes(file)) {
        this.uploadedFiles.push(file);
      }
    }
  }

  removeFile(event: any) {
    const index = this.uploadedFiles.findIndex((item) => item.size === event.files[0].size);
    if (index >= 0) {
      this.uploadedFiles.splice(index, 1);
    }
  }
}
