/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvVerdeErrorLog } from '../models/dv-verde-error-log';

@Injectable({
  providedIn: 'root',
})
export class SharedApiVerdeErrorLogService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getErrorLog
   */
  static readonly GetErrorLogPath = '/api/VerdeErrorLog/get/{userID}/{legalEntityID}/{issueStatus}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErrorLog()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorLog$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
    issueStatus: string;
  }): Observable<StrictHttpResponse<Array<DvVerdeErrorLog>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeErrorLogService.GetErrorLogPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('issueStatus', params.issueStatus, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvVerdeErrorLog>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErrorLog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorLog(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
    issueStatus: string;
  }): Observable<Array<DvVerdeErrorLog>> {
    return this.getErrorLog$Response(params).pipe(map((r: StrictHttpResponse<Array<DvVerdeErrorLog>>) => r.body as Array<DvVerdeErrorLog>));
  }

  /**
   * Path part for operation patchErrorLog
   */
  static readonly PatchErrorLogPath = '/api/VerdeErrorLog/patch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchErrorLog()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchErrorLog$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: DvVerdeErrorLog }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeErrorLogService.PatchErrorLogPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchErrorLog$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchErrorLog(params?: { subscriptionKey?: string; APIURL?: string; body?: DvVerdeErrorLog }): Observable<boolean> {
    return this.patchErrorLog$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postErrorLog
   */
  static readonly PostErrorLogPath = '/api/VerdeErrorLog/post';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postErrorLog()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postErrorLog$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: DvVerdeErrorLog }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeErrorLogService.PostErrorLogPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postErrorLog$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postErrorLog(params?: { subscriptionKey?: string; APIURL?: string; body?: DvVerdeErrorLog }): Observable<string> {
    return this.postErrorLog$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation issuesNoteFunction
   */
  static readonly IssuesNoteFunctionPath = '/api/VerdeErrorLog/function/{environmentURL}/{slot}/{issueID}/{hasAttachment}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issuesNoteFunction()` instead.
   *
   * This method doesn't expect any request body.
   */
  issuesNoteFunction$Response(params: { environmentURL: string; slot: string; issueID: string; hasAttachment: string }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeErrorLogService.IssuesNoteFunctionPath, 'get');
    if (params) {
      rb.path('environmentURL', params.environmentURL, {});
      rb.path('slot', params.slot, {});
      rb.path('issueID', params.issueID, {});
      rb.path('hasAttachment', params.hasAttachment, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `issuesNoteFunction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issuesNoteFunction(params: { environmentURL: string; slot: string; issueID: string; hasAttachment: string }): Observable<void> {
    return this.issuesNoteFunction$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }
}
