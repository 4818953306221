import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { VerdeApprovalService } from '@verde/shared';
import { catchError, of, Subject, take, takeUntil } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

// Steps for creation:
// Copy and paste existing component as a base to work off of
// Change file names accordingly
// In .ts file, change selector, templateUrl, styleUrls and the component name on the export line
// go to the shared.module.ts and add reference in for your component
// in verde-approval-panel.component.html, add you reference to your new component dependent on the sidebar type
// in verde-approval-service, make sure you add in relevant variables to pass data through to your new component
// on the component that you are initiating navigation from, inject the verde-approval-service and set the relevant fields to open the sidebar, including populating the type to the relevant value
// sidebar should open to your new component

@Component({
  selector: 'verde-graph-test',
  templateUrl: './graph-test.component.html',
  styleUrls: ['./graph-test.component.scss'],
})
export class GraphTestComponent implements OnInit, AfterViewInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject();
  fb: FormBuilder;
  //fg: FormGroup;
  public fg: FormGroup = new FormGroup({
    exampleGraphApi1: new FormControl(),
    graphUrl: new FormControl(),
    exampleGraphApi3: new FormControl(),
    exampleGraphApi4: new FormControl(),
    exampleGraphApi5: new FormControl(),
    exampleGraphApi6: new FormControl(),
    exampleGraphApi7: new FormControl(),
    exampleGraphApi1a: new FormControl(),
    exampleGraphApi1b: new FormControl(),
    graphApiOutput: new FormControl(),
    graphApiDropdown1: new FormControl(),
  });

  exampleGraphApi1: string;
  graphUrl: string;
  exampleGraphApi3: string;
  exampleGraphApi4: string;
  exampleGraphApi5: string;
  exampleGraphApi6: string;
  exampleGraphApi7: string;
  exampleGraphApi1a: string;
  exampleGraphApi1b: string;
  graphApiOutput: string;
  graphApiDropdown1: GraphApiDropdownMethod;
  graphApiDropdown1data: GraphApiDropdownMethod[];

  currentStep: number = 1;
  //  Set total steps needed for your form
  totalSteps: number = 1;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['jpg', 'jpeg', 'png', 'pdf'],
  };

  constructor(private sidebarService: VerdeApprovalService, private http: HttpClient, private msalService: MsalService) {}

  ngOnInit(): void {
    this.fb = new FormBuilder();
    this.resetForm();

    // this.graphApiDropdown1data.(new (graphApiDropdown1Value: "Get", graphApiDropdown1ID: "get"))

    var holdTemp: GraphApiDropdownMethod[] = [];
    holdTemp.push({ graphApiDropdown1Value: 'Get', graphApiDropdown1ID: 'get' });
    holdTemp.push({ graphApiDropdown1Value: 'Post', graphApiDropdown1ID: 'post' });

    this.graphApiDropdown1data = holdTemp;
    //   this.graphApiDropdown1data.push({graphApiDropdown1Value: "Post", graphApiDropdown1ID: "post"})
    this.fg?.get('graphApiDropdown1')?.setValue({ graphApiDropdown1Value: 'Get', graphApiDropdown1ID: 'get' });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  resetForm() {
    this.fg = this.fb.group({
      exampleTextBox: [],
      exampleTextBox2: [],
      exampleTextBox3: [],
      exampleTextBox4: [],
      exampleTextBox5: [],
      exampleDateInput: [new Date()],
      exampleDropdown: [],
      exampleDropdown2: [],
      exampleDropdown3: [],
      exampleTextArea: [],
      fileUpload: [],
      radioButton: [],
      radioButton2: [],
      exampleGraphApi1: [],
      graphUrl: [],
      exampleGraphApi3: [],
      exampleGraphApi4: [],
      exampleGraphApi5: [],
      exampleGraphApi6: [],
      exampleGraphApi7: [],
      exampleGraphApi1a: [],
      exampleGraphApi1b: [],
      graphApiOutput: [],
      graphApiDropdown1: [],
      selectParams: [],
      filerParams: [],
    });
  }
  // = { graphApiDropdown1Value: "Get", graphApiDropdown1ID: "get" }
  public next(): void {
    this.currentStep += 1;
  }

  public prev(): void {
    this.currentStep -= 1;
  }

  handleGraphApiDropdown1Filter() {}

  submit() {
    console.log(this.fg?.value?.exampleGraphApi5);
    console.log(this.fg?.value?.graphUrl);

    this.fg?.get('graphApiOutput')?.setValue(null);

    const account = this.msalService.instance.getActiveAccount();

    if ((this.fg?.value?.graphUrl ?? '') != '' && this.fg?.value?.graphApiDropdown1.graphApiDropdown1ID == 'get') {
      if (account) {
        const url = this.buildUrlQuery();

        this.msalService
          .acquireTokenSilent({
            scopes: [this.fg?.value?.exampleGraphApi1],
            account: account,
          })
          .pipe(
            catchError((error) => {
              console.log('Caught observable error:', error);
              this.fg?.get('graphApiOutput')?.setValue(JSON.stringify(error).toString() ?? null);
              return of(null); // Ensure an observable is returned
            }),
          )
          .subscribe((tokenResponse: AuthenticationResult) => {
            const headers = new HttpHeaders({
              Authorization: `Bearer ${tokenResponse}`,
              'Content-Type': 'application/json',
            });

            if (!this.fg?.get('graphApiOutput')?.value) {
              this.http
                .get(url, { headers })
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(
                  (data: any) => {
                    console.log('GraphGetAPIData', JSON.stringify(data.value));
                    this.fg?.get('graphApiOutput')?.setValue(JSON.stringify(data.value) ?? null);
                    //   this.spinner.hide('my-calendar');
                  },
                  (error?: any) => {
                    console.log('GraphGetAPIError', error.error);
                    this.fg?.get('graphApiOutput')?.setValue(JSON.stringify(error).toString() ?? null);
                  },
                );
            }
          });
      }
    }

    if ((this.fg?.value?.graphUrl ?? '') != '' && this.fg?.value?.graphApiDropdown1.graphApiDropdown1ID == 'post') {
      if (account) {
        const url = this.buildUrlQuery();

        this.msalService
          .acquireTokenSilent({
            scopes: [this.fg?.value?.exampleGraphApi1],
            account: account,
          })
          .pipe(
            catchError((error) => {
              debugger;
              console.log('Caught observable error:', error);
              this.fg?.get('graphApiOutput')?.setValue(JSON.stringify(error).toString() ?? null);
              return of(null); // Ensure an observable is returned
            }),
          )
          .subscribe((tokenResponse: AuthenticationResult) => {
            const headers = new HttpHeaders({
              Authorization: `Bearer ${tokenResponse}`,
              'Content-Type': 'application/json',
            });

            this.http
              .post(url, this.fg?.value?.exampleGraphApi6, { headers })
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (data?: any) => {
                  console.log('GraphGetAPIData', data.value);
                  this.fg?.get('graphApiOutput')?.setValue(JSON.stringify(data.value) ?? null);
                  //       this.spinner.hide('my-calendar');
                },
                (error?: any) => {
                  console.log('GraphGetAPIError', error.error);
                  this.fg?.get('graphApiOutput')?.setValue(JSON.stringify(error).toString() ?? null);
                },
              );
          });
      }
    }
  }

  buildUrlQuery(): string {
    const selectParams = this.fg?.value?.selectParams ? `$select=${this.fg?.value?.selectParams}` : '';
    const filterParams = this.fg?.value?.filerParams ? `$filter=${this.fg?.value?.filerParams}` : '';

    // Build the base URL
    const baseUrl = this.fg?.value?.graphUrl;

    // Construct the query string
    let queryString = '';
    if (selectParams || filterParams) {
      queryString += selectParams;
      if (selectParams && filterParams) {
        queryString += '&';
      }
      queryString += filterParams;
    }

    // Construct the final URL
    const url = queryString ? `${baseUrl}?${queryString}` : baseUrl;
    return url;
  }
}

export interface GraphApiDropdownMethod {
  graphApiDropdown1Value: string;
  graphApiDropdown1ID: string;
}
