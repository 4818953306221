/* tslint:disable */
/* eslint-disable */
import { BtMeasure } from './bt-measure';
export interface BtVehicleInspectionChecklistDetailVehic {
  _bt_checklisttemplate_value?: null | string;
  _bt_fleetmovement_value?: null | string;
  _bt_fromemployee_value?: null | string;
  _bt_fromlocation_value?: null | string;
  _bt_location_value?: null | string;
  _bt_measure_value?: null | string;
  _bt_regno_value?: null | string;
  _bt_responder_value?: null | string;
  _bt_subgroup_value?: null | string;
  _bt_toemployee_value?: null | string;
  _bt_tolocation_value?: null | string;
  _bt_vehicleinspectionchecklistsummary_value?: null | string;
  bt_Measure?: BtMeasure;
  bt_allowimage?: null | boolean;
  bt_allowimages?: null | boolean;
  bt_completed?: null | boolean;
  bt_date?: null | string;
  bt_dayofmonth?: null | number;
  bt_detailtype?: null | number;
  bt_displaysubgroup?: null | boolean;
  bt_duehours?: null | number;
  bt_frequency?: null | number;
  bt_fromdate?: null | string;
  bt_fromodo?: null | number;
  bt_groupsorting?: null | number;
  bt_image?: null | string;
  bt_image2?: null | string;
  bt_image3?: null | string;
  bt_image4?: null | string;
  bt_image5?: null | string;
  bt_image6?: null | string;
  bt_imagerequired?: null | boolean;
  bt_measuresorting?: null | number;
  bt_movementtype?: null | number;
  bt_odo?: null | number;
  bt_responsecomments?: null | string;
  bt_responsedate?: null | string;
  bt_responsetype?: null | number;
  bt_responsevalue?: null | number;
  bt_responseyesno?: null | boolean;
  bt_subgroupsorting?: null | number;
  bt_todate?: null | string;
  bt_toodo?: null | number;
  bt_transferdirection?: null | number;
  bt_transfertype?: null | number;
  bt_vehicleinspectionchecklistdetail?: null | string;
  bt_vehicleinspectionchecklistdetailid?: null | string;
  odataDetailType?: null | string;
  odataFrequency?: null | string;
  odataGroup?: null | string;
  odataMeasure?: null | string;
  odataMovementType?: null | string;
  odataResponseType?: null | string;
  odataSubGroup?: null | string;
  odataTemplate?: null | string;
  odataTransferDirection?: null | string;
  odataTransferType?: null | string;
}
