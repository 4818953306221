/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FleetClassificationUpdate } from '../models/fleet-classification-update';
import { FleetConfirmationParameters } from '../models/fleet-confirmation-parameters';
import { FleetLicenseRenewal } from '../models/fleet-license-renewal';
import { FleetMake } from '../models/fleet-make';
import { FleetModel } from '../models/fleet-model';
import { FleetOdoUpdates } from '../models/fleet-odo-updates';
import { FleetTrackingOdo } from '../models/fleet-tracking-odo';
import { FleetType } from '../models/fleet-type';
import { VehicleAccessory } from '../models/vehicle-accessory';
import { VehicleAccessoryLink } from '../models/vehicle-accessory-link';
import { VehiclesFiltered } from '../models/vehicles-filtered';
import { DvAllVehicleMakes } from '../models/dv-all-vehicle-makes';
import { DvAllVehicleModels } from '../models/dv-all-vehicle-models';

@Injectable({
  providedIn: 'root',
})
export class SharedApiVehiclesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllVehicles
   */
  static readonly GetAllVehiclesPath = '/api/Vehicles/employee/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllVehicles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVehicles$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<VehiclesFiltered>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetAllVehiclesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VehiclesFiltered>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllVehicles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVehicles(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<VehiclesFiltered>> {
    return this.getAllVehicles$Response(params).pipe(map((r: StrictHttpResponse<Array<VehiclesFiltered>>) => r.body as Array<VehiclesFiltered>));
  }

  /**
   * Path part for operation getAllMakes
   */
  static readonly GetAllMakesPath = '/api/Vehicles/makes/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMakes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMakes$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<DvAllVehicleMakes>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetAllMakesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvAllVehicleMakes>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllMakes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMakes(params: { subscriptionKey?: string; APIURL?: string; legalEntityGuid: string }): Observable<Array<DvAllVehicleMakes>> {
    return this.getAllMakes$Response(params).pipe(map((r: StrictHttpResponse<Array<DvAllVehicleMakes>>) => r.body as Array<DvAllVehicleMakes>));
  }

  /**
   * Path part for operation getAllModels
   */
  static readonly GetAllModelsPath = '/api/Vehicles/models/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllModels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllModels$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<DvAllVehicleModels>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetAllModelsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvAllVehicleModels>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllModels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllModels(params: { subscriptionKey?: string; APIURL?: string; legalEntityGuid: string }): Observable<Array<DvAllVehicleModels>> {
    return this.getAllModels$Response(params).pipe(map((r: StrictHttpResponse<Array<DvAllVehicleModels>>) => r.body as Array<DvAllVehicleModels>));
  }

  /**
   * Path part for operation patchVehicles
   */
  static readonly PatchVehiclesPath = '/api/Vehicles/patch/fleetitem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVehicles()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVehicles$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: VehiclesFiltered }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.PatchVehiclesPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVehicles$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVehicles(params?: { subscriptionKey?: string; APIURL?: string; body?: VehiclesFiltered }): Observable<boolean> {
    return this.patchVehicles$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postVehicles
   */
  static readonly PostVehiclesPath = '/api/Vehicles/post/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVehicles()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVehicles$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: VehiclesFiltered;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.PostVehiclesPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVehicles$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVehicles(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: VehiclesFiltered }): Observable<boolean> {
    return this.postVehicles$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getManagerTeamVehicles
   */
  static readonly GetManagerTeamVehiclesPath = '/api/Vehicles/managerteam/{managerID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManagerTeamVehicles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManagerTeamVehicles$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
  }): Observable<StrictHttpResponse<Array<VehiclesFiltered>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetManagerTeamVehiclesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerID', params.managerID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VehiclesFiltered>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManagerTeamVehicles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManagerTeamVehicles(params: { subscriptionKey?: string; APIURL?: string; managerID: string }): Observable<Array<VehiclesFiltered>> {
    return this.getManagerTeamVehicles$Response(params).pipe(map((r: StrictHttpResponse<Array<VehiclesFiltered>>) => r.body as Array<VehiclesFiltered>));
  }

  /**
   * Path part for operation getVehicleAccessoryLinks
   */
  static readonly GetVehicleAccessoryLinksPath = '/api/Vehicles/accessorylinks/{vehicleID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleAccessoryLinks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleAccessoryLinks$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    vehicleID: string;
  }): Observable<StrictHttpResponse<Array<VehicleAccessoryLink>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetVehicleAccessoryLinksPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('vehicleID', params.vehicleID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VehicleAccessoryLink>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVehicleAccessoryLinks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleAccessoryLinks(params: { subscriptionKey?: string; APIURL?: string; vehicleID: string }): Observable<Array<VehicleAccessoryLink>> {
    return this.getVehicleAccessoryLinks$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VehicleAccessoryLink>>) => r.body as Array<VehicleAccessoryLink>),
    );
  }

  /**
   * Path part for operation getVehicleAccessories
   */
  static readonly GetVehicleAccessoriesPath = '/api/Vehicles/accessories/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVehicleAccessories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleAccessories$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VehicleAccessory>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetVehicleAccessoriesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VehicleAccessory>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVehicleAccessories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVehicleAccessories(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<VehicleAccessory>> {
    return this.getVehicleAccessories$Response(params).pipe(map((r: StrictHttpResponse<Array<VehicleAccessory>>) => r.body as Array<VehicleAccessory>));
  }

  /**
   * Path part for operation postFleetAccessoryLink
   */
  static readonly PostFleetAccessoryLinkPath = '/api/Vehicles/post/accessorylink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFleetAccessoryLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetAccessoryLink$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VehicleAccessoryLink;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.PostFleetAccessoryLinkPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postFleetAccessoryLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetAccessoryLink(params?: { subscriptionKey?: string; APIURL?: string; body?: VehicleAccessoryLink }): Observable<boolean> {
    return this.postFleetAccessoryLink$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getAllFleetItemsByLocation
   */
  static readonly GetAllFleetItemsByLocationPath = '/api/Vehicles/allfleetitems/{locationID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllFleetItemsByLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFleetItemsByLocation$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    locationID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VehiclesFiltered>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetAllFleetItemsByLocationPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('locationID', params.locationID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VehiclesFiltered>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllFleetItemsByLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFleetItemsByLocation(params: {
    subscriptionKey?: string;
    APIURL?: string;
    locationID: string;
    legalEntityID: string;
  }): Observable<Array<VehiclesFiltered>> {
    return this.getAllFleetItemsByLocation$Response(params).pipe(map((r: StrictHttpResponse<Array<VehiclesFiltered>>) => r.body as Array<VehiclesFiltered>));
  }

  /**
   * Path part for operation getFleetItemsAtLocation
   */
  static readonly GetFleetItemsAtLocationPath = '/api/Vehicles/fleetitemsatlocation/{locationID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetItemsAtLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemsAtLocation$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    locationID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VehiclesFiltered>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetItemsAtLocationPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('locationID', params.locationID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VehiclesFiltered>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetItemsAtLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemsAtLocation(params: {
    subscriptionKey?: string;
    APIURL?: string;
    locationID: string;
    legalEntityID: string;
  }): Observable<Array<VehiclesFiltered>> {
    return this.getFleetItemsAtLocation$Response(params).pipe(map((r: StrictHttpResponse<Array<VehiclesFiltered>>) => r.body as Array<VehiclesFiltered>));
  }

  /**
   * Path part for operation getFleetItemsByEntity
   */
  static readonly GetFleetItemsByEntityPath = '/api/Vehicles/fleetitemsbyentity/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetItemsByEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemsByEntity$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VehiclesFiltered>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetItemsByEntityPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VehiclesFiltered>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetItemsByEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemsByEntity(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<VehiclesFiltered>> {
    return this.getFleetItemsByEntity$Response(params).pipe(map((r: StrictHttpResponse<Array<VehiclesFiltered>>) => r.body as Array<VehiclesFiltered>));
  }

  /**
   * Path part for operation getFleetItemById
   */
  static readonly GetFleetItemByIdPath = '/api/Vehicles/fleetitembyid/{itemID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetItemById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemById$Response(params: { subscriptionKey?: string; APIURL?: string; itemID: string }): Observable<StrictHttpResponse<VehiclesFiltered>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetItemByIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('itemID', params.itemID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VehiclesFiltered>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetItemById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemById(params: { subscriptionKey?: string; APIURL?: string; itemID: string }): Observable<VehiclesFiltered> {
    return this.getFleetItemById$Response(params).pipe(map((r: StrictHttpResponse<VehiclesFiltered>) => r.body as VehiclesFiltered));
  }

  /**
   * Path part for operation getFleetItemMaintenance
   */
  static readonly GetFleetItemMaintenancePath = '/api/Vehicles/fleetitemmaintenance/{itemID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetItemMaintenance()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemMaintenance$Response(params: { subscriptionKey?: string; APIURL?: string; itemID: string }): Observable<StrictHttpResponse<VehiclesFiltered>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetItemMaintenancePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('itemID', params.itemID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VehiclesFiltered>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetItemMaintenance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetItemMaintenance(params: { subscriptionKey?: string; APIURL?: string; itemID: string }): Observable<VehiclesFiltered> {
    return this.getFleetItemMaintenance$Response(params).pipe(map((r: StrictHttpResponse<VehiclesFiltered>) => r.body as VehiclesFiltered));
  }

  /**
   * Path part for operation getFleetTrackingOdo
   */
  static readonly GetFleetTrackingOdoPath = '/api/Vehicles/fleettrackingodo/{itemID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetTrackingOdo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetTrackingOdo$Response(params: { subscriptionKey?: string; APIURL?: string; itemID: string }): Observable<StrictHttpResponse<Array<FleetTrackingOdo>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetTrackingOdoPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('itemID', params.itemID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetTrackingOdo>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetTrackingOdo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetTrackingOdo(params: { subscriptionKey?: string; APIURL?: string; itemID: string }): Observable<Array<FleetTrackingOdo>> {
    return this.getFleetTrackingOdo$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetTrackingOdo>>) => r.body as Array<FleetTrackingOdo>));
  }

  /**
   * Path part for operation getFleetOdoUpdates
   */
  static readonly GetFleetOdoUpdatesPath = '/api/Vehicles/fleetodoupdates/{itemID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetOdoUpdates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetOdoUpdates$Response(params: { subscriptionKey?: string; APIURL?: string; itemID: string }): Observable<StrictHttpResponse<Array<FleetOdoUpdates>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetOdoUpdatesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('itemID', params.itemID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetOdoUpdates>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetOdoUpdates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetOdoUpdates(params: { subscriptionKey?: string; APIURL?: string; itemID: string }): Observable<Array<FleetOdoUpdates>> {
    return this.getFleetOdoUpdates$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetOdoUpdates>>) => r.body as Array<FleetOdoUpdates>));
  }

  /**
   * Path part for operation getFleetAssignmentByRegNo
   */
  static readonly GetFleetAssignmentByRegNoPath = '/api/Vehicles/fleetassignmentbyregno/{regNo}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetAssignmentByRegNo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetAssignmentByRegNo$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    regNo: string;
  }): Observable<StrictHttpResponse<Array<VehiclesFiltered>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetAssignmentByRegNoPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('regNo', params.regNo, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VehiclesFiltered>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetAssignmentByRegNo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetAssignmentByRegNo(params: { subscriptionKey?: string; APIURL?: string; regNo: string }): Observable<Array<VehiclesFiltered>> {
    return this.getFleetAssignmentByRegNo$Response(params).pipe(map((r: StrictHttpResponse<Array<VehiclesFiltered>>) => r.body as Array<VehiclesFiltered>));
  }

  /**
   * Path part for operation getFleetLicensesByEntity
   */
  static readonly GetFleetLicensesByEntityPath = '/api/Vehicles/fleetlicenses/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetLicensesByEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetLicensesByEntity$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<FleetLicenseRenewal>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetLicensesByEntityPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetLicenseRenewal>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetLicensesByEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetLicensesByEntity(params: { subscriptionKey?: string; APIURL?: string; legalEntityGuid: string }): Observable<Array<FleetLicenseRenewal>> {
    return this.getFleetLicensesByEntity$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FleetLicenseRenewal>>) => r.body as Array<FleetLicenseRenewal>),
    );
  }

  /**
   * Path part for operation getFleetLicensesByVehicle
   */
  static readonly GetFleetLicensesByVehiclePath = '/api/Vehicles/fleetlicensesbyvehicle/{vehicleID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetLicensesByVehicle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetLicensesByVehicle$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    vehicleID: string;
  }): Observable<StrictHttpResponse<Array<FleetLicenseRenewal>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetLicensesByVehiclePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('vehicleID', params.vehicleID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetLicenseRenewal>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetLicensesByVehicle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetLicensesByVehicle(params: { subscriptionKey?: string; APIURL?: string; vehicleID: string }): Observable<Array<FleetLicenseRenewal>> {
    return this.getFleetLicensesByVehicle$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FleetLicenseRenewal>>) => r.body as Array<FleetLicenseRenewal>),
    );
  }

  /**
   * Path part for operation patchFleetLicenseRenewal
   */
  static readonly PatchFleetLicenseRenewalPath = '/api/Vehicles/patch/fleetlicenserenewal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFleetLicenseRenewal()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchFleetLicenseRenewal$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: FleetLicenseRenewal;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.PatchFleetLicenseRenewalPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchFleetLicenseRenewal$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchFleetLicenseRenewal(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetLicenseRenewal }): Observable<boolean> {
    return this.patchFleetLicenseRenewal$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postFleetLicenseRenewal
   */
  static readonly PostFleetLicenseRenewalPath = '/api/Vehicles/post/fleetlicenserenewal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFleetLicenseRenewal()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetLicenseRenewal$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: FleetLicenseRenewal;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.PostFleetLicenseRenewalPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postFleetLicenseRenewal$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetLicenseRenewal(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetLicenseRenewal }): Observable<boolean> {
    return this.postFleetLicenseRenewal$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getFleetModelById
   */
  static readonly GetFleetModelByIdPath = '/api/Vehicles/fleetmodelbyid/{modelID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetModelById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetModelById$Response(params: { subscriptionKey?: string; APIURL?: string; modelID: string }): Observable<StrictHttpResponse<FleetModel>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetModelByIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('modelID', params.modelID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<FleetModel>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetModelById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetModelById(params: { subscriptionKey?: string; APIURL?: string; modelID: string }): Observable<FleetModel> {
    return this.getFleetModelById$Response(params).pipe(map((r: StrictHttpResponse<FleetModel>) => r.body as FleetModel));
  }

  /**
   * Path part for operation getFleetModels
   */
  static readonly GetFleetModelsPath = '/api/Vehicles/fleetmodels/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetModels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetModels$Response(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<StrictHttpResponse<Array<FleetModel>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetModelsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetModel>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetModels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetModels(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<FleetModel>> {
    return this.getFleetModels$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetModel>>) => r.body as Array<FleetModel>));
  }

  /**
   * Path part for operation patchFleetModel
   */
  static readonly PatchFleetModelPath = '/api/Vehicles/patch/fleetmodels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFleetModel()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchFleetModel$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetModel }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.PatchFleetModelPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchFleetModel$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchFleetModel(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetModel }): Observable<boolean> {
    return this.patchFleetModel$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postFleetModel
   */
  static readonly PostFleetModelPath = '/api/Vehicles/post/fleetmodels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFleetModel()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetModel$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetModel }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.PostFleetModelPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postFleetModel$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetModel(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetModel }): Observable<boolean> {
    return this.postFleetModel$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getFleetMakes
   */
  static readonly GetFleetMakesPath = '/api/Vehicles/fleetmakes/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetMakes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetMakes$Response(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<StrictHttpResponse<Array<FleetMake>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetMakesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetMake>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetMakes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetMakes(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<FleetMake>> {
    return this.getFleetMakes$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetMake>>) => r.body as Array<FleetMake>));
  }

  /**
   * Path part for operation getFleetTypes
   */
  static readonly GetFleetTypesPath = '/api/Vehicles/fleettypes/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetTypes$Response(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<StrictHttpResponse<Array<FleetType>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetTypesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetType>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetTypes(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<FleetType>> {
    return this.getFleetTypes$Response(params).pipe(map((r: StrictHttpResponse<Array<FleetType>>) => r.body as Array<FleetType>));
  }

  /**
   * Path part for operation getFleetClassificationUpdates
   */
  static readonly GetFleetClassificationUpdatesPath = '/api/Vehicles/fleetclassificationupdates/{locationID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetClassificationUpdates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetClassificationUpdates$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    locationID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<FleetClassificationUpdate>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetClassificationUpdatesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('locationID', params.locationID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetClassificationUpdate>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetClassificationUpdates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetClassificationUpdates(params: {
    subscriptionKey?: string;
    APIURL?: string;
    locationID: string;
    legalEntityID: string;
  }): Observable<Array<FleetClassificationUpdate>> {
    return this.getFleetClassificationUpdates$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FleetClassificationUpdate>>) => r.body as Array<FleetClassificationUpdate>),
    );
  }

  /**
   * Path part for operation getFleetClassificationUpdatesByVehicle
   */
  static readonly GetFleetClassificationUpdatesByVehiclePath = '/api/Vehicles/fleetclassificationupdatesbyvehicle/{legalEntityID}/{fleetItemID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetClassificationUpdatesByVehicle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetClassificationUpdatesByVehicle$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    fleetItemID: string;
  }): Observable<StrictHttpResponse<Array<FleetClassificationUpdate>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.GetFleetClassificationUpdatesByVehiclePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('fleetItemID', params.fleetItemID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FleetClassificationUpdate>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetClassificationUpdatesByVehicle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFleetClassificationUpdatesByVehicle(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    fleetItemID: string;
  }): Observable<Array<FleetClassificationUpdate>> {
    return this.getFleetClassificationUpdatesByVehicle$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FleetClassificationUpdate>>) => r.body as Array<FleetClassificationUpdate>),
    );
  }

  /**
   * Path part for operation postFleetClassificationUpdate
   */
  static readonly PostFleetClassificationUpdatePath = '/api/Vehicles/post/fleetclassificationupdates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFleetClassificationUpdate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetClassificationUpdate$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: FleetClassificationUpdate;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.PostFleetClassificationUpdatePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postFleetClassificationUpdate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetClassificationUpdate(params?: { subscriptionKey?: string; APIURL?: string; body?: FleetClassificationUpdate }): Observable<boolean> {
    return this.postFleetClassificationUpdate$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postFleetMovementConfirmation
   */
  static readonly PostFleetMovementConfirmationPath = '/api/Vehicles/post/fleetmovementconfirmation/{environmentURL}/{slot}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFleetMovementConfirmation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetMovementConfirmation$Response(params: {
    environmentURL: string;
    slot: string;
    body?: FleetConfirmationParameters;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVehiclesService.PostFleetMovementConfirmationPath, 'post');
    if (params) {
      rb.path('environmentURL', params.environmentURL, {});
      rb.path('slot', params.slot, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postFleetMovementConfirmation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetMovementConfirmation(params: { environmentURL: string; slot: string; body?: FleetConfirmationParameters }): Observable<boolean> {
    return this.postFleetMovementConfirmation$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
