/* tslint:disable */
/* eslint-disable */
import { BtPolicyAcceptance } from './bt-policy-acceptance';
export interface PolicyListAcceptance {
  _bt_employee_value?: null | string;
  bt_Policy?: BtPolicyAcceptance;
  bt_accepted?: boolean;
  bt_accepteddate?: null | any;
  bt_verdepolicyacceptanceid?: null | string;
}
