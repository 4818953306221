import { Component, EventEmitter, Input, Output } from '@angular/core';
import { zoomInOnEnterAnimation, zoomOutOnLeaveAnimation } from 'angular-animations';

interface SelectOption {
  value: number;
  text: string;
  selected: boolean;
}

@Component({
  selector: 'verde-enhanced-rating-option',
  templateUrl: './enhanced-rating-option.component.html',
  styleUrls: ['./enhanced-rating-option.component.scss'],
  animations: [zoomInOnEnterAnimation({ anchor: 'selected', duration: 300, delay: 100 }), zoomOutOnLeaveAnimation({ anchor: 'deselected', duration: 300 })],
})
export class EnhancedRatingOptionComponent {
  @Input() set setReviewOption(val: any) {
    this.reviewOption = val;
    this.setOptions();
  }

  @Output() answerSelected: EventEmitter<any> = new EventEmitter();

  reviewOption: any = {};
  allOptions: SelectOption[] = [];
  selectedValue: number;

  setOptions(): void {
    console.log('REVIEWOPTION', this.reviewOption);
    this.selectedValue = this.reviewOption.bt_ratingfloat || this.reviewOption.bt_ratingfloat === 0 ? this.reviewOption.bt_ratingfloat : null;
    /*this.reviewOption.bt_minvalue = 1;*/

    for (let i = this.reviewOption.bt_minvalue; i <= this.reviewOption.bt_maxvalue; i++) {
      this.allOptions.push({
        value: i,
        selected: this.selectedValue === i,
        text: `${i}`,
      });
    }
  }

  setSelected(passOption: SelectOption): void {
    for (const option of this.allOptions) {
      option.selected = false;
    }

    passOption.selected = true;
    this.selectedValue = passOption.value;
    this.answerSelected.emit({ ...this.reviewOption, bt_ratingfloat: this.selectedValue });
  }
}
