/* tslint:disable */
/* eslint-disable */
export interface BookingsFiltered {
  _bt_bookingitem_value?: null | string;
  _bt_bookingtype_value?: null | string;
  _bt_duration_value?: null | string;
  _bt_employee_value?: null | string;
  _bt_office_value?: null | string;
  _bt_primarylocation_value?: null | string;
  _bt_suboffice_value?: null | string;
  bt_bookingno?: null | string;
  bt_bookingreason?: null | string;
  bt_departmental?: boolean;
  bt_fromdate?: null | string;
  bt_todate?: null | string;
  bt_verdebookingsid?: null | string;
  odataBookingItem?: null | string;
  odataBookingType?: null | string;
  odataDuration?: null | string;
  odataEmployee?: null | string;
  odataOffice?: null | string;
  odataPostBookingItem?: null | string;
  odataPostBookingType?: null | string;
  odataPostDepartment?: null | string;
  odataPostDuration?: null | string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostOffice?: null | string;
  odataPostPrimaryLocation?: null | string;
  odataPostSubOffice?: null | string;
  odataPrimaryLocation?: null | string;
  odataSubOffice?: null | string;
  statecode?: null | string;
}
