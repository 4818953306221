/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { UserBaseService } from '../user-base-service';
import { UserApiConfiguration } from '../user-api-configuration';
import { UserStrictHttpResponse } from '../user-strict-http-response';
import { UserRequestBuilder } from '../user-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserVerdeVersion } from '../models/user-verde-version';

@Injectable({
  providedIn: 'root',
})
export class UserApiVersionService extends UserBaseService {
  constructor(config: UserApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getVersionNumber
   */
  static readonly GetVersionNumberPath = '/api/Version/GetVersionNumber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVersionNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersionNumber$Response(params?: {}): Observable<UserStrictHttpResponse<UserVerdeVersion>> {
    const rb = new UserRequestBuilder(this.rootUrl, UserApiVersionService.GetVersionNumberPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as UserStrictHttpResponse<UserVerdeVersion>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVersionNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersionNumber(params?: {}): Observable<UserVerdeVersion> {
    return this.getVersionNumber$Response(params).pipe(map((r: UserStrictHttpResponse<UserVerdeVersion>) => r.body as UserVerdeVersion));
  }
}
