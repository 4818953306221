<!--<ngx-spinner [disableAnimation]="disableAnimation"
             name="engagetab"
             bdColor="rgb(237, 237, 237)"
             size="default"
             color="#6264a7"
             type="ball-clip-rotate"
             [fullScreen]="false">

</ngx-spinner>-->

<!-- #region Body -->
<kendo-grid
  [rowClass]="rowCallback"
  [kendoGridBinding]="engageTeamGridData"
  [resizable]="true"
  [scrollable]="true"
  [groupable]="true"
  [group]="engageTeamGroups"
  (groupChange)="engageTeamGroupChange($event)"
  (dataStateChange)="engageTeamDataStateChange($event)"
  [selectable]="true"
  [kendoGridSelectBy]="engageTeamSelectedCallback"
  [selectedKeys]="engageTeamSelection"
  [sortable]="true"
  [sort]="engageTeamSort"
  (sortChange)="engageTeamSortChange($event)"
>
  <!-- columns for larger devices -->
  <ng-template kendoGridToolbarTemplate>
    <input placeholder="Search all columns" kendoTextBox style="text-transform: none !important" (input)="onEngageTeamFilter($event.target.value)" />
    <kendo-grid-spacer></kendo-grid-spacer>
    <button *ngIf="!(deviceTypeService.isMobile$ | async)" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
    <img title="Refresh" class="refresh_icon" src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg" alt="" (click)="refresh()" />
  </ng-template>
  <kendo-grid-column field="bthr_EmployeeID.bt_fullnamewithempid" title="Full Name"> </kendo-grid-column>
  <kendo-grid-column field="bt_status" title="Status"> </kendo-grid-column>
  <kendo-grid-column field="bt_presencestatussource" title="Presence Status"> </kendo-grid-column>
  <kendo-grid-column field="odataHealthStatus" title="Health Completed"> </kendo-grid-column>
  <kendo-grid-column field="odataLocationUpdated" title="Location Updated"> </kendo-grid-column>
  <kendo-grid-column field="odataRisk" title="Risk"> </kendo-grid-column>

  <kendo-grid-excel fileName="EngageTeam.xlsx"></kendo-grid-excel>
</kendo-grid>
<!-- #endregion Body -->
