import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserUserDto, VerdeRequestType } from '@verde/api';
import * as moment from 'moment';
import { RequistionStatus } from '../models/requests.interface';

@Injectable({
  providedIn: 'root',
})
export class RequestModalService {
  private newRequestForm: FormGroup;
  private newRequestTypeForm: FormGroup;

  initialiseRequestTypeForm(proxyUser: UserUserDto): void {
    const { employeeId, legalEntityId, managerEmployeeId, managerLegalEntityId } = proxyUser;

    this.newRequestTypeForm = new FormGroup({
      odataPostRequestType: new FormControl(null, [Validators.required]),
      odataPostRequestedByLegalEntity: new FormControl(`/bthr_legalentitieses(${legalEntityId})`, [Validators.required]),
      odataPostRequestedBy: new FormControl(`/bthr_employees(${employeeId})`, [Validators.required]),
      odataPostManagerEntity: new FormControl(`/bthr_legalentitieses(${managerLegalEntityId})`, [Validators.required]),
      odataPostManager: new FormControl(`/bthr_employees(${managerEmployeeId})`, [Validators.required]),
      bt_description: new FormControl(null),
      odataProcessLegalEntity: new FormControl(null, [Validators.required]),
      bt_fullname: new FormControl(null, [Validators.required]),
    });
  }

  initialiseRequestForm(): void {
    const today = moment().format('YYYY-MM-DD');

    this.newRequestForm = new FormGroup({
      bt_requisitionstatus: new FormControl(RequistionStatus.STATUS_NEW),
      bt_requestdate: new FormControl(today, [Validators.required]),
      bt_description: new FormControl(null, [Validators.required]),
      bt_motivation: new FormControl(null, [Validators.required]),
      bt_uniquename: new FormControl(null),
      bt_quote1value: new FormControl(null),
      bt_quotevalue2: new FormControl(null),
      bt_quotevalue3: new FormControl(null),
      bt_quote1name: new FormControl(null),
      bt_quote2name: new FormControl(null),
      bt_quote3name: new FormControl(null),
      bt_specificationdocumentname: new FormControl(null),
    });
  }

  setRequestTypeForm(currentRequestType: VerdeRequestType, proxyUser: UserUserDto): void {
    const { employeeId, legalEntityId, managerEmployeeId, managerLegalEntityId } = proxyUser;

    this.newRequestTypeForm.controls.bt_description.setValue(currentRequestType?.bt_description);
    this.newRequestTypeForm.controls.odataProcessLegalEntity.setValue(currentRequestType?.odataProcessLegalEntity);
    this.newRequestTypeForm.controls.bt_fullname.setValue(currentRequestType?.bt_ProcessOwner?.bt_fullname);
    this.newRequestTypeForm.controls.odataPostRequestedByLegalEntity.setValue(`/bthr_legalentitieses(${legalEntityId})`);
    this.newRequestTypeForm.controls.odataPostRequestedBy.setValue(`/bthr_employees(${employeeId})`);
    this.newRequestTypeForm.controls.odataPostManagerEntity.setValue(`/bthr_legalentitieses(${managerLegalEntityId})`);
    this.newRequestTypeForm.controls.odataPostManager.setValue(`/bthr_employees(${managerEmployeeId})`);

    if (currentRequestType.bt_specificationrequired) {
      this.newRequestForm.controls.bt_specificationdocumentname.setValidators([Validators.required]);
      this.newRequestForm.controls.bt_specificationdocumentname.updateValueAndValidity();
    }

    if (currentRequestType.bt_quiterequired) {
      if (currentRequestType.bt_numberofquotesrequired === 1) {
        this.newRequestForm.controls.bt_quote1value.setValidators([Validators.required]);
        this.newRequestForm.controls.bt_quote1value.updateValueAndValidity();
      } else if (currentRequestType.bt_numberofquotesrequired === 2) {
        this.newRequestForm.controls.bt_quote1value.setValidators([Validators.required]);
        this.newRequestForm.controls.bt_quotevalue2.setValidators([Validators.required]);

        this.newRequestForm.controls.bt_quote1value.updateValueAndValidity();
        this.newRequestForm.controls.bt_quotevalue2.updateValueAndValidity();
      } else if (currentRequestType.bt_numberofquotesrequired === 3) {
        this.newRequestForm.controls.bt_quote1value.setValidators([Validators.required]);
        this.newRequestForm.controls.bt_quotevalue2.setValidators([Validators.required]);
        this.newRequestForm.controls.bt_quotevalue3.setValidators([Validators.required]);

        this.newRequestForm.controls.bt_quote1value.updateValueAndValidity();
        this.newRequestForm.controls.bt_quotevalue2.updateValueAndValidity();
        this.newRequestForm.controls.bt_quotevalue3.updateValueAndValidity();
      }
    }
  }

  getRequestTypeform(): FormGroup {
    return this.newRequestTypeForm;
  }

  getRequestForm(): FormGroup {
    return this.newRequestForm;
  }

  resetRequestForm(): void {
    this.newRequestForm.reset();
  }

  resetRequestTypeForm(): void {
    this.newRequestTypeForm.reset();
  }
}
