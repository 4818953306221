/* tslint:disable */
/* eslint-disable */
export interface FleetLicenseRenewal {
  _bt_legalentity_value?: null | string;
  _bt_uploademployee_value?: null | string;
  _bt_uploadlegalentity_value?: null | string;
  _bt_vehicle_value?: null | string;
  bt_fleetlicenserenewalid?: null | string;
  bt_name?: null | string;
  bt_nextexpirydate?: null | string;
  bt_processdocument?: null | boolean;
  bt_processstatus?: null | string;
  bt_proofofrenewal?: null | string;
  bt_proofofrenewalname?: null | string;
  bt_successful?: null | boolean;
  createdon?: null | string;
  modifiedon?: null | string;
  odataFleetItem?: null | string;
  odataFleetItemLegalEntity?: null | string;
  odataSuccessful?: null | string;
  odataUploadEmployee?: null | string;
  odataUploadLegalEntity?: null | string;
}
