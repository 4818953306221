import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// Queries
import { PerformanceReviewPatchEnhancedReviewDetailCommand } from '@verde/api';

// Store
//Related
import * as fromEnhancedReviewDetailRelatedReducer from '../store/reducers/performance-review-related.reducer';
// EnhancedReviewDetail
import * as EnhancedReviewDetailActions from '../store/actions/enhanced-review-detail.actions';
import * as fromEnhancedReviewDetailReducer from '../store/reducers/enhanced-review-detail.reducer';

@Injectable({
  providedIn: 'root',
})
export class StoreEnhancedReviewDetailService {
  // EnhancedReviewDetail
  enhancedReviewDetailLoading$: Observable<boolean>;
  enhancedReviewDetailObjects$: Observable<any[]>;

  constructor(private store: Store<fromEnhancedReviewDetailRelatedReducer.PerformanceReviewRelatedState>) {
    // EnhancedReviewDetail
    this.enhancedReviewDetailLoading$ = this.store.pipe(select(fromEnhancedReviewDetailReducer.isEnhancedReviewDetailLoading));
    this.enhancedReviewDetailObjects$ = this.store.pipe(select(fromEnhancedReviewDetailReducer.getAllEnhancedReviewDetail));
  }

  //public getEnhancedReviewDetail(body: TemplateGetEnhancedReviewDetailsQuery) {
  //  this.store.dispatch(new EnhancedReviewDetailActions.EnhancedReviewDetailsGet(body));
  //}

  // Clear
  enhancedReviewDetailClear() {
    this.store.dispatch(new EnhancedReviewDetailActions.EnhancedReviewDetailClear(null));
  }

  //// Upload
  //uploadEnhancedReviewDetails(payload: TemplateUploadEnhancedReviewDetailCommand) {
  //  this.store.dispatch(new EnhancedReviewDetailActions.EnhancedReviewDetailsUpload(payload));
  //}

  // EnhancedReviewDetail update
  updateEnhancedReviewDetail(payload: PerformanceReviewPatchEnhancedReviewDetailCommand) {
    this.store.dispatch(new EnhancedReviewDetailActions.EnhancedReviewDetailUpdate(payload));
  }
}
