/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BookingsFiltered } from '../models/bookings-filtered';

@Injectable({
  providedIn: 'root',
})
export class SharedApiBookingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getBookingsByEmployee
   */
  static readonly GetBookingsByEmployeePath = '/api/Bookings/bookingsbyemployee/{legalEntity}/{employeeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingsByEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingsByEmployee$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntity: string;
    employeeId: string;
  }): Observable<StrictHttpResponse<Array<BookingsFiltered>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiBookingsService.GetBookingsByEmployeePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntity', params.legalEntity, {});
      rb.path('employeeId', params.employeeId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<BookingsFiltered>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingsByEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingsByEmployee(params: { subscriptionKey?: string; APIURL?: string; legalEntity: string; employeeId: string }): Observable<Array<BookingsFiltered>> {
    return this.getBookingsByEmployee$Response(params).pipe(map((r: StrictHttpResponse<Array<BookingsFiltered>>) => r.body as Array<BookingsFiltered>));
  }

  /**
   * Path part for operation postBooking
   */
  static readonly PostBookingPath = '/api/Bookings/post/booking';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postBooking()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postBooking$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: BookingsFiltered }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiBookingsService.PostBookingPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postBooking$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postBooking(params?: { subscriptionKey?: string; APIURL?: string; body?: BookingsFiltered }): Observable<boolean> {
    return this.postBooking$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getBookingsSummary
   */
  static readonly GetBookingsSummaryPath = '/api/Bookings/bookingsdashboardsummary/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingsSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingsSummary$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<BookingsFiltered>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiBookingsService.GetBookingsSummaryPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<BookingsFiltered>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingsSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingsSummary(params: { subscriptionKey?: string; APIURL?: string; userID: string; legalEntityID: string }): Observable<Array<BookingsFiltered>> {
    return this.getBookingsSummary$Response(params).pipe(map((r: StrictHttpResponse<Array<BookingsFiltered>>) => r.body as Array<BookingsFiltered>));
  }
}
