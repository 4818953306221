/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvPermitRequests } from '../models/dv-permit-requests';
import { DvPermitRequestsPost } from '../models/dv-permit-requests-post';
import { DvPermitTypes } from '../models/dv-permit-types';

@Injectable({
  providedIn: 'root',
})
export class SharedApiPermitsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getPermitRequests
   */
  static readonly GetPermitRequestsPath = '/api/Permits/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPermitRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermitRequests$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvPermitRequests>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiPermitsService.GetPermitRequestsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvPermitRequests>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPermitRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPermitRequests(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<DvPermitRequests>> {
    return this.getPermitRequests$Response(params).pipe(map((r: StrictHttpResponse<Array<DvPermitRequests>>) => r.body as Array<DvPermitRequests>));
  }

  /**
   * Path part for operation getAllPermitTypes
   */
  static readonly GetAllPermitTypesPath = '/api/Permits/types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPermitTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPermitTypes$Response(params?: { subscriptionKey?: string; APIURL?: string }): Observable<StrictHttpResponse<Array<DvPermitTypes>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiPermitsService.GetAllPermitTypesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvPermitTypes>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPermitTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPermitTypes(params?: { subscriptionKey?: string; APIURL?: string }): Observable<Array<DvPermitTypes>> {
    return this.getAllPermitTypes$Response(params).pipe(map((r: StrictHttpResponse<Array<DvPermitTypes>>) => r.body as Array<DvPermitTypes>));
  }

  /**
   * Path part for operation postPermitRequest
   */
  static readonly PostPermitRequestPath = '/api/Permits/post/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postPermitRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postPermitRequest$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvPermitRequestsPost;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiPermitsService.PostPermitRequestPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postPermitRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postPermitRequest(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: DvPermitRequestsPost }): Observable<boolean> {
    return this.postPermitRequest$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
