/* eslint-disable no-console */
import { Inject, Injectable } from '@angular/core';
import { Subject, take } from 'rxjs';
import { AuthenticationService, UserService } from '@verde/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class MfAuthService {
  userData: any;
  _signInSuccess: Subject<{ success: boolean; data: string }> = new Subject<{ success: boolean; data: string }>();

  getSignInSuccess() {
    return this._signInSuccess.asObservable();
  }

  setSignInSuccess(item: { success: boolean; data: string }) {
    this._signInSuccess.next(item);
  }

  _signInError: Subject<{ fail: boolean; data: string }> = new Subject<{ fail: boolean; data: string }>();

  getSignInError() {
    return this._signInError.asObservable();
  }

  setSignInError(item: { fail: boolean; data: string }) {
    this._signInError.next(item);
  }

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private msalService: MsalService,
    @Inject('environment') private environment: any,
  ) {}

  async microsoftSignIn() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    that.authenticationService.authState$.pipe(take(1)).subscribe((authState) => {
      if (authState && authState.isMicrosoftAuthed && that.authenticationService.protectedResourcesLoaded()) {
        const account = that.msalService.instance.getActiveAccount();
        if (account) {
          that.msalService
            .acquireTokenSilent({
              scopes: that.environment.graphConfig.scopes,
              account: account,
            })
            .subscribe((tokenResponse: AuthenticationResult) => {
              const headers = new Headers();
              const bearer = 'Bearer ' + tokenResponse.accessToken;
              headers.append('Authorization', bearer);

              const options = {
                method: 'GET',
                headers: headers,
              };
              const graphEndpoint = 'https://graph.microsoft.com/v1.0/me';

              fetch(graphEndpoint, options).then(function (response) {
                response.json().then(function (data) {
                  that.userData = data;
                  if (
                    that.userData.mail.toLowerCase() === that.userService.user.email.toLowerCase() ||
                    that.userData.userPrincipalName.toLowerCase() === that.userService.user.email.toLowerCase()
                  ) {
                    that.setSignInSuccess({ success: true, data: data });
                  } else {
                    that.setSignInError({ fail: true, data: data });
                  }
                });
              });
            });
        }
      }
    });
  }
}
