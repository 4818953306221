import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { process } from '@progress/kendo-data-query';
import { FleetMovement, SharedApiFleetMovementService, VehiclesFiltered } from '@verde/api';
import { UserService } from '@verde/core';
import { FleetAccessService } from 'apps/verde/src/app/features/fleet/services/fleet-access/fleet-access.service';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'verde-fleet-assign-modal',
  templateUrl: './fleet-assign-modal.component.html',
  styleUrls: ['./fleet-assign-modal.component.scss'],
})
export class FleetAssignModalComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  @Input() requestID: string = '';

  @Output() hideModalEvent = new EventEmitter<boolean>();
  @Output() transactionSuccessful = new EventEmitter<boolean>();

  //selected
  selectedTransfer: FleetMovement = {};
  selectedTransferItem: VehiclesFiltered = {};

  allFleetMovementRequests: Array<FleetMovement> = new Array<FleetMovement>();

  patchTransferBody: FleetMovement = {};

  allFleetItemsData: Array<VehiclesFiltered> = new Array<VehiclesFiltered>();
  allFleetItemsSource: Array<VehiclesFiltered> = new Array<VehiclesFiltered>();

  assignFleetItemDropdownValue: string | null | undefined = 'default';

  confirmationMessage: string = '';
  disableAnimation: boolean = false;
  actionAlreadyCompletedWarning: boolean = false;
  disableRejectionButton: boolean = false;

  assignFormDefaultState = {};

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    public userService: UserService,
    public fleetAccess: FleetAccessService,
    private sharedApiFleetMovementService: SharedApiFleetMovementService,
  ) {}

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });

    this.fleetAccess.currentFleetItemsAtLocation.pipe(takeUntil(this.onDestroy$)).subscribe((fleet) => {
      if (fleet.length > 0) {
        this.allFleetItemsSource = fleet.filter((t) => {
          if (t.bt_fleetmovementstatus !== 692360001 && t.bt_countofmovementsnotcompleted === 0) {
            return t;
          }
        });

        this.allFleetItemsData = this.allFleetItemsSource;
      }
    });

    setTimeout(() => {
      document.getElementById('showAssignModal')?.click();
    }, 5);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  hideModal() {
    this.hideModalEvent.emit(false);
  }

  onConfirmClick(): void {
    this.transactionSuccessful.emit(true);
    this.hideModalEvent.emit(false);
  }

  getFleetMovement(myForm: FormGroup) {
    this.spinner.show('fleettransferassign');
    this.modalService.open('newFleetTransferAssignModal');

    this.sharedApiFleetMovementService
      .getFleetMovementById({ movementID: this.requestID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.selectedTransfer = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.selectedTransfer._bt_regno_value === null) {
            this.assignFormDefaultState = {
              assignToEmployee: this.selectedTransfer?.bt_ToEmployee?.bt_fullname,
              assignRequestReason: this.selectedTransfer?.bt_comment,
              assignFleetItem: null,
            };

            myForm.reset(this.assignFormDefaultState);

            this.spinner.hide('fleettransferassign');

            this.fleetAccess.getFleetItemsAtLocation(
              this.selectedTransfer.bt_ToEmployee?._bt_defaultfleetlocation_value!,
              this.selectedTransfer._bt_legalentity_value!,
            );
          } else {
            this.actionAlreadyCompletedWarning = true;
            this.spinner.hide('fleettransferassign');
          }
        },
      );
  }

  patchFleetAssignment() {
    this.spinner.show('confirmfleettransferassign');
    this.modalService.close('newFleetTransferAssignModal');
    this.modalService.open('confirmFleetTransferAssignModal');

    this.patchTransferBody.bt_movementstatus = 692360001;

    this.patchTransferBody.odataPostFromLocation = '/bthr_locations(' + this.selectedTransferItem._bt_location_value + ')';
    this.patchTransferBody.odataPostRegNo = '/bthr_vehicleses(' + this.selectedTransferItem.bthr_vehiclesid + ')';
    this.patchTransferBody.bt_classification = this.selectedTransferItem.bthr_classification;

    this.patchTransferBody.odataPostAssignedEmployee = '/bthr_employees(' + this.userService.user?.employeeId + ')';
    this.patchTransferBody.odataPostAssignedLegalEntity = '/bthr_legalentitieses(' + this.userService.user?.legalEntityId + ')';
    this.patchTransferBody.bt_assigneddate = moment().format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss') + 'Z';

    this.patchTransferBody.bt_nextmovementstep = '';

    this.patchTransferBody.bt_fleetmovementid = this.selectedTransfer?.bt_fleetmovementid;

    this.sharedApiFleetMovementService
      .patchFleetMovement({ body: this.patchTransferBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
          this.transactionSuccessful.emit(false);
        },
        () => {
          this.sharedApiFleetMovementService
            .getFleetMovementsByLocation({ locationID: this.fleetAccess.selectedFleetAccess._bt_grouplocation_value! })
            .pipe(take(1))
            .subscribe(
              (ret) => {
                this.allFleetMovementRequests = ret.filter((t) => {
                  if (t.bt_movementtype === 692360001) {
                    return t;
                  } else {
                    return {};
                  }
                });
              },
              (error) => {
                console.error(error);
              },
              () => {
                this.patchTransferBody = {};
                this.confirmationMessage = 'You have successfully assigned this fleet item!';

                this.spinner.hide('confirmfleettransferassign');
              },
            );
        },
      );

    //updating fleet item movement status
    this.fleetAccess.patchFleetItemMovementStatusAllocated(this.selectedTransferItem?.bthr_vehiclesid!, this.selectedTransfer?.bt_ToEmployee?.bt_fullname!);
  }

  confirmRejection() {
    this.modalService.close('newFleetTransferAssignModal');
    this.modalService.open('confirmFleetTransferAssignRejectionModal');
  }

  patchRejectection(myForm: FormGroup) {
    this.spinner.show('confirmfleettransferassign');
    this.modalService.close('confirmFleetTransferAssignRejectionModal');
    this.modalService.open('confirmFleetTransferAssignModal');

    this.patchTransferBody.bt_movementstatus = 692360002;
    this.patchTransferBody.bt_comment =
      this.selectedTransfer.bt_comment +
      ' - Transfer Cancelled by ' +
      this.userService?.user?.fullNameWithEmpId +
      ' - Reason: ' +
      myForm.value.confirmRejectionComment;

    this.patchTransferBody.bt_nextmovementstep = '';

    this.patchTransferBody.bt_fleetmovementid = this.selectedTransfer?.bt_fleetmovementid;

    this.sharedApiFleetMovementService
      .patchFleetMovement({ body: this.patchTransferBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
          this.transactionSuccessful.emit(false);
        },
        () => {
          this.sharedApiFleetMovementService
            .getFleetMovementsByLocation({ locationID: this.fleetAccess.selectedFleetAccess._bt_grouplocation_value! })
            .pipe(take(1))
            .subscribe(
              (ret) => {
                this.allFleetMovementRequests = ret.filter((t) => {
                  if (t.bt_movementtype === 692360001) {
                    return t;
                  } else {
                    return {};
                  }
                });
              },
              (error) => {
                console.error(error);
              },
              () => {
                this.patchTransferBody = {};
                this.confirmationMessage = 'This fleet transfer has been rejected.';

                this.spinner.hide('confirmfleettransferassign');
              },
            );
        },
      );
  }

  setAssignFleetItemDropdownValue(e: VehiclesFiltered) {
    this.assignFleetItemDropdownValue = e.bthr_vehiclesid;

    this.selectedTransferItem = e;

    this.disableRejectionButton = true;
  }

  handleFilter(value: string) {
    if (value != '') {
      this.allFleetItemsData = process(this.allFleetItemsSource, {
        filter: {
          logic: 'or',
          filters: [
            {
              field: 'odataLocation',
              operator: 'contains',
              value: value,
            },
            {
              field: 'bt_fleetno',
              operator: 'contains',
              value: value,
            },
            {
              field: 'odataModel',
              operator: 'contains',
              value: value,
            },
            {
              field: 'bthr_licensenumber',
              operator: 'contains',
              value: value,
            },
            {
              field: 'odataClassification',
              operator: 'contains',
              value: value,
            },
          ],
        },
      }).data;
    } else {
      this.allFleetItemsData = this.allFleetItemsSource;
    }
  }
}
