/* tslint:disable */
/* eslint-disable */
export interface TriggerLog {
  _bt_triggertemplate_value?: null | string;
  bt_messagetosend?: null | string;
  bt_notificationtriggerlogid?: null | string;
  bt_parameters?: null | string;
  bt_triggerrequired?: null | boolean;
  odataLegalEntity?: null | string;
  odataNotificationRelatedTo?: null | string;
  odataNotificationSendTo?: null | string;
  odataTriggerTemplate?: null | string;
}
