import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControlDirective, FormGroup } from '@angular/forms';
import { FleetConfirmationParameters, FleetMovement, SharedApiFleetMovementService, SharedApiVehiclesService, UserUserDto, VehiclesFiltered } from '@verde/api';
import { UserService } from '@verde/core';
import { ModalService, VerdeApprovalService } from '@verde/shared';
import { FleetAccessService } from 'apps/verde/src/app/features/fleet/services/fleet-access/fleet-access.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'verde-fleet-confirmation-modal',
  templateUrl: './fleet-confirmation-modal.component.html',
  styleUrls: ['./fleet-confirmation-modal.component.scss'],
})
export class FleetConfirmationModalComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  @Input() requestID: string = '';
  @Input() confirmationType: string = '';

  @Output() hideModalEvent = new EventEmitter<boolean>();
  @Output() transactionSuccessful = new EventEmitter<boolean>();

  confirmationMessage: string = '';
  disableAnimation: boolean = false;

  userHasPermission: boolean;

  proxyUser: UserUserDto;

  selectedTransfer: FleetMovement = {};
  selectedTransferItem: VehiclesFiltered = {};

  allFleetMovementRequests: Array<FleetMovement> = new Array<FleetMovement>();

  patchTransferBody: FleetMovement = {};

  agreedYes: boolean = false;
  agreedNo: boolean = true;
  transferDirection: string = '';
  confirmationText: string = '';
  actionAlreadyCompletedWarning: boolean = false;

  confirmFormDefaultState = {};

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    public userService: UserService,
    public fleetAccess: FleetAccessService,
    private sharedApiVehiclesService: SharedApiVehiclesService,
    private sharedApiFleetMovementService: SharedApiFleetMovementService,
    private verdeApprovalService: VerdeApprovalService,
    @Inject('environment') private environment: any,
  ) {}

  ngOnInit(): void {
    this.fleetAccess.userHasPermission$.pipe(takeUntil(this.onDestroy$)).subscribe((permission) => {
      this.userHasPermission = permission;
    });

    this.userService.proxyUser$.pipe(takeUntil(this.onDestroy$)).subscribe((proxyUser) => {
      if (proxyUser == null) {
        this.proxyUser = this.userService.user;
      } else {
        this.proxyUser = proxyUser;
      }

      if (Object.keys(this.proxyUser).length > 0) {
        setTimeout(() => {
          document.getElementById('showConfirmationModal')?.click();
        }, 5);
      }
    });

    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });

    if (this.confirmationType.toLowerCase().includes('from')) {
      this.transferDirection = 'Release';
      this.confirmationText = 'Please confirm that you would like to release this fleet item';
    } else {
      this.transferDirection = 'Acceptance';
      this.confirmationText = 'Please confirm that you would like to accept this fleet item';
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  hideModal() {
    this.hideModalEvent.emit(false);
  }

  onConfirmClick(): void {
    this.transactionSuccessful.emit(true);
    this.hideModalEvent.emit(false);
  }

  getFleetMovement(myForm: FormGroup) {
    this.spinner.show('fleettransferconfirmation');
    this.modalService.open('newFleetTransferConfirmationModal');
    this.sharedApiFleetMovementService
      .getFleetMovementById({ movementID: this.requestID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.selectedTransfer = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (
            (this.confirmationType.toLowerCase() === 'from' && this.selectedTransfer.bt_acceptfrom) ||
            (this.confirmationType.toLowerCase() === 'to' && this.selectedTransfer.bt_acceptto)
          ) {
            this.actionAlreadyCompletedWarning = true;
            this.spinner.hide('fleettransferconfirmation');
          } else {
            this.sharedApiVehiclesService
              .getFleetItemById({ itemID: this.selectedTransfer?._bt_regno_value! })
              .pipe(take(1))
              .subscribe(
                (ret) => {
                  this.selectedTransferItem = ret;
                },
                (error) => {
                  console.error(error);
                },
                () => {
                  this.agreedYes = false;
                  this.agreedNo = true;

                  this.confirmFormDefaultState = {
                    agreedYes: this.agreedYes,
                    agreedNo: this.agreedNo,
                    confirmFromEmployee: this.selectedTransfer?.bt_FromEmployee?.bt_fullname,
                    confirmFromLocation: this.selectedTransfer?.odataFromLocation,
                    confirmToEmployee: this.selectedTransfer?.bt_ToEmployee?.bt_fullname,
                    confirmToLocation: this.selectedTransfer?.odataToLocation,
                    confirmModel: this.selectedTransferItem.odataMake + ' - ' + this.selectedTransferItem.odataModel,
                    confirmLicenseNo: this.selectedTransferItem.bthr_licensenumber,
                  };

                  myForm.reset(this.confirmFormDefaultState);

                  this.modalService.open('newFleetTransferConfirmationModal');
                  this.spinner.hide('fleettransferconfirmation');
                },
              );
          }
        },
      );
  }

  patchFleetTransferConfirmation(myForm: FormGroup) {
    this.spinner.show('confirmfleettransferconfirmation');
    this.modalService.close('newFleetTransferConfirmationModal');
    this.modalService.open('confirmFleetTransferConfirmationModal');

    //if (this.confirmationType === 'From') {
    //  if (this.userHasPermission) {
    //    this.patchTransferBody.odataPostFromConfirmationEmployee = '/bthr_employees(' + this.userService.user?.employeeId + ')';
    //    this.patchTransferBody.odataPostFromConfirmationLegalEntity = '/bthr_legalentitieses(' + this.userService.user?.legalEntityId + ')';
    //    this.patchTransferBody.bt_fromconfirmationdate = moment().format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss') + 'Z';
    //  } else {
    //    this.patchTransferBody.odataPostFromConfirmationEmployee = '/bthr_employees(' + this.proxyUser.employeeId + ')';
    //    this.patchTransferBody.odataPostFromConfirmationLegalEntity = '/bthr_legalentitieses(' + this.proxyUser.legalEntityId + ')';
    //    this.patchTransferBody.bt_fromconfirmationdate = moment().format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss') + 'Z';
    //  }
    //} else {
    //  if (this.userHasPermission) {
    //    this.patchTransferBody.odataPostToConfirmationEmployee = '/bthr_employees(' + this.proxyUser.employeeId + ')';
    //    this.patchTransferBody.odataPostToConfirmationLegalEntity = '/bthr_legalentitieses(' + this.proxyUser.legalEntityId + ')';
    //    this.patchTransferBody.bt_toconfirmationdate = moment().format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss') + 'Z';
    //  } else {
    //    this.patchTransferBody.odataPostToConfirmationEmployee = '/bthr_employees(' + this.proxyUser.employeeId + ')';
    //    this.patchTransferBody.odataPostToConfirmationLegalEntity = '/bthr_legalentitieses(' + this.proxyUser.legalEntityId + ')';
    //    this.patchTransferBody.bt_toconfirmationdate = moment().format('YYYY-MM-DD') + 'T' + moment().format('HH:mm:ss') + 'Z';
    //  }
    //}

    //this.patchTransferBody.bt_fleetmovementid = this.selectedTransfer?.bt_fleetmovementid;

    //this.sharedApiFleetMovementService
    //  .patchFleetMovement({ body: this.patchTransferBody })
    //  .pipe(take(1))
    //  .subscribe(
    //    (ret) => {
    //      console.log(ret);
    //    },
    //    (error) => {
    //      console.error(error);
    //    },
    //    () => {
    //      this.patchTransferBody = {};

    //      //Second update
    //      let secondUpdateBody: FleetMovement = {};

    //      if (this.confirmationType === 'From') {
    //        secondUpdateBody.bt_acceptfrom = true;
    //        secondUpdateBody.bt_acceptfromdate = moment().format('YYYY-MM-DD') + 'T' + moment().utc().format('HH:mm');

    //        if (this.selectedTransfer?.bt_supplier) {
    //          secondUpdateBody.bt_movementstatus = 692360002;
    //        }
    //      } else {
    //        secondUpdateBody.bt_acceptto = true;
    //        secondUpdateBody.bt_accepttodate = moment().format('YYYY-MM-DD') + 'T' + moment().utc().format('HH:mm');
    //        secondUpdateBody.bt_movementstatus = 692360002;
    //      }

    //      secondUpdateBody.bt_nextmovementstep = '';

    //      secondUpdateBody.bt_fleetmovementid = this.selectedTransfer?.bt_fleetmovementid;

    //      this.sharedApiFleetMovementService
    //        .patchFleetMovement({ body: secondUpdateBody })
    //        .pipe(take(1))
    //        .subscribe(
    //          (ret) => {
    //            console.log(ret);
    //          },
    //          (error) => {
    //            console.error(error);
    //          },
    //          () => {
    //            secondUpdateBody = {};

    //            this.confirmationMessage = 'You have successfully confirmed this transfer!';

    //            this.spinner.hide('confirmfleettransferconfirmation');
    //          },
    //        );
    //    },
    //  );

    ////updating fleet item movement status
    //if (this.confirmationType === 'To') {
    //  if (
    //    this.selectedTransfer?.bt_transfertype === 692360000 ||
    //    this.selectedTransfer?.bt_transfertype === 692360002 ||
    //    this.selectedTransfer?.bt_transfertype === 692360007
    //  ) {
    //    this.fleetAccess.patchFleetItemMovementStatusAtLocation(this.selectedTransferItem?.bthr_vehiclesid!, this.selectedTransfer?.odataToLocation!);
    //  } else if (
    //    this.selectedTransfer?.bt_transfertype === 692360001 ||
    //    this.selectedTransfer?.bt_transfertype === 692360003 ||
    //    this.selectedTransfer?.bt_transfertype === 692360006
    //  ) {
    //    this.fleetAccess.patchFleetItemMovementStatusAssigned(this.selectedTransferItem?.bthr_vehiclesid!, this.selectedTransfer?.bt_ToEmployee?.bt_fullname!);
    //  }
    //} else {
    //  if (this.selectedTransfer?.bt_transfertype === 692360004 || this.selectedTransfer?.bt_transfertype === 692360005) {
    //    this.fleetAccess.patchFleetItemMovementStatusAtSupplier(this.selectedTransferItem?.bthr_vehiclesid!, this.selectedTransfer?.odataToSupplier!);
    //  }
    //}

    // new logic
    let movementStatus: number = 0;
    let statusComment: string = '';

    if (this.confirmationType.toLowerCase() === 'to') {
      if (
        // To Location
        this.selectedTransfer?.bt_transfertype === 692360000 ||
        this.selectedTransfer?.bt_transfertype === 692360002 ||
        this.selectedTransfer?.bt_transfertype === 692360007
      ) {
        movementStatus = 692360000;
        statusComment = this.selectedTransfer?.odataToLocation!;
      } else if (
        // To Employee
        this.selectedTransfer?.bt_transfertype === 692360001 ||
        this.selectedTransfer?.bt_transfertype === 692360003 ||
        this.selectedTransfer?.bt_transfertype === 692360006
      ) {
        movementStatus = 692360002;
        statusComment = this.selectedTransfer?.bt_ToEmployee?.bt_fullname!;
      }
    } else {
      if (this.selectedTransfer?.bt_transfertype === 692360004 || this.selectedTransfer?.bt_transfertype === 692360005) {
        movementStatus = 692360003;
        statusComment = this.selectedTransfer?.odataToSupplier!;
      }
    }

    let fleetConfirmationBody: FleetConfirmationParameters = {
      comment: statusComment,
      confirmationEmployee: this.proxyUser?.employeeId,
      employeeLegalEntity: this.proxyUser?.legalEntityId,
      isSupplier: this.selectedTransfer?.bt_supplier,
      movementID: this.selectedTransfer?.bt_fleetmovementid,
      movementStatus: movementStatus,
      rejectionReason: myForm.value.confirmRequestComment,
      type: this.confirmationType,
      vehicleID: this.selectedTransferItem?.bthr_vehiclesid!,
    };

    this.sharedApiVehiclesService
      .postFleetMovementConfirmation({
        body: fleetConfirmationBody,
        environmentURL: this.userService.verdeEnvironmentConfig?.dataverseUrl,
        slot: this.environment.slot,
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.confirmationMessage = 'You have successfully confirmed this transfer!';
          this.spinner.hide('confirmfleettransferconfirmation');
          this.verdeApprovalService.setShowSidebar(false);
        },
      );
  }

  // agreed
  agreedYesChange(e: boolean, element: FormControlDirective) {
    this.agreedYes = e;
    this.agreedNo = !e;
    element.reset();
  }

  agreedNoChange(e: boolean, element: FormControlDirective) {
    this.agreedNo = e;
    this.agreedYes = !e;
    element.reset();
  }
}
