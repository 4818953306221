/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvMedicalRecords } from '../models/dv-medical-records';

@Injectable({
  providedIn: 'root',
})
export class SharedApiMedicalRecordsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllMedicalRecords
   */
  static readonly GetAllMedicalRecordsPath = '/api/MedicalRecords/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMedicalRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMedicalRecords$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvMedicalRecords>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiMedicalRecordsService.GetAllMedicalRecordsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvMedicalRecords>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllMedicalRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMedicalRecords(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<DvMedicalRecords>> {
    return this.getAllMedicalRecords$Response(params).pipe(map((r: StrictHttpResponse<Array<DvMedicalRecords>>) => r.body as Array<DvMedicalRecords>));
  }

  /**
   * Path part for operation patchMedicalRecords
   */
  static readonly PatchMedicalRecordsPath = '/api/MedicalRecords/patch/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchMedicalRecords()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchMedicalRecords$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvMedicalRecords;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiMedicalRecordsService.PatchMedicalRecordsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchMedicalRecords$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchMedicalRecords(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: DvMedicalRecords }): Observable<boolean> {
    return this.patchMedicalRecords$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postMedicalRecords
   */
  static readonly PostMedicalRecordsPath = '/api/MedicalRecords/post/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMedicalRecords()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postMedicalRecords$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvMedicalRecords;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiMedicalRecordsService.PostMedicalRecordsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMedicalRecords$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postMedicalRecords(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: DvMedicalRecords }): Observable<boolean> {
    return this.postMedicalRecords$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
