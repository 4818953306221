/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LearnApiConfiguration, LearnApiConfigurationParams } from './learn-api-configuration';

import { LearnApiLearnService } from './services/learn-api-learn.service';
import { LearnApiVersionService } from './services/learn-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [LearnApiLearnService, LearnApiVersionService, LearnApiConfiguration],
})
export class LearnApiModule {
  static forRoot(params: LearnApiConfigurationParams): ModuleWithProviders<LearnApiModule> {
    return {
      ngModule: LearnApiModule,
      providers: [
        {
          provide: LearnApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: LearnApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('LearnApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
