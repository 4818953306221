import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationService } from '@verde/core';

@Component({
  selector: 'verde-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(private msalService: MsalService, private authenticationService: AuthenticationService, private router: Router) {}

  ngOnInit(): void {
    this.logout();
  }

  // Perform the logout operation
  logout(): void {
    // Clear the session in MSAL
    this.msalService.logoutRedirect().subscribe({
      next: () => {
        // Once logged out, clear any local state related to authentication
        this.authenticationService.authState$.next(undefined);
        // Redirect to the login page
        this.router.navigate(['/login']);
      },
      error: (error) => {
        console.error('Logout failed', error);
        // Optionally, you could navigate the user back to a fallback page or show a message
        this.router.navigate(['/']);
      },
    });
  }
}
