import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ShortcutsActions, ShortcutsActionTypes } from '../actions/shortcuts.actions';
import * as fromDashboardRelatedReducer from './dashboard-related.reducer';

// Models
import { WorkspaceShortcut } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type ShortcutsState = EntityState<WorkspaceShortcut>;

export interface ShortcutsRelatedState {
  shortcutsState: ShortcutsState;
  loading: boolean;
  error: any;
}

export const shortcutsKeys = ['bt_verdemyworkspaceshortcutid'];
export const shortcutsStateAdapter: EntityAdapter<WorkspaceShortcut> = createEntityAdapter<WorkspaceShortcut>({
  selectId: (data) => keyHandler(data, shortcutsKeys),
});

export const initialShortcutsState: ShortcutsState = shortcutsStateAdapter.getInitialState({});

export const initialShortcutsRelatedState = {
  shortcutsState: initialShortcutsState,
  loading: false,
  error: {},
};

export function shortcutsStateRelatedReducer(state = initialShortcutsRelatedState, action: ShortcutsActions): ShortcutsRelatedState {
  switch (action.type) {
    // Get Shortcuts
    case ShortcutsActionTypes.SHORTCUTS_GET:
      return { ...state, loading: true };
    case ShortcutsActionTypes.SHORTCUTS_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        shortcutsState: shortcutsStateAdapter.setAll(action.payload, state.shortcutsState),
      };
    }
    case ShortcutsActionTypes.SHORTCUTS_GET_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Clear
    case ShortcutsActionTypes.SHORTCUTS_CLEAR:
      return { ...initialShortcutsRelatedState };
    default:
      return state;
  }
}

export const getHomeRelatedState = createFeatureSelector<fromDashboardRelatedReducer.DashboardRelatedState>('dashboardRelatedReducer');

export const getShortcutsRelatedState = createSelector(getHomeRelatedState, (state: fromDashboardRelatedReducer.DashboardRelatedState) => state.shortcutsState);

// Current Shortcuts
export const getShortcutsState = createSelector(getShortcutsRelatedState, (state) => state.shortcutsState);
export const { selectAll: getShortcuts } = shortcutsStateAdapter.getSelectors(getShortcutsState);

// Loading
export const isLoading = createSelector(getShortcutsRelatedState, (state) => state.loading);

// Error
export const getError = createSelector(getShortcutsRelatedState, (state) => state.error);
