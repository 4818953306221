import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DvVerdeErrorLog, SharedApiFileService, SharedApiVerdeErrorLogService } from '@verde/api';
import { UserService } from '@verde/core';
import { ModalService } from '@verde/shared';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'verde-issue-modal',
  templateUrl: './issue-modal.component.html',
  styleUrls: ['./issue-modal.component.scss'],
})
export class IssueModalComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<boolean>();

  @ViewChild('reportIssueForm') reportIssueForm: FormGroup | undefined;

  @Input() issueLocation = '';

  @Output() hideModalEvent = new EventEmitter<boolean>();

  verdeErrorLogBody: DvVerdeErrorLog = {};

  disableAnimation = false;

  confirmationMessage = '';

  issueFormDefaultState = {
    issueDescription: null,
  };

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private userService: UserService,
    private sharedApiVerdeErrorLogService: SharedApiVerdeErrorLogService,
    private sharedApiFileService: SharedApiFileService,
    @Inject('environment') private environment: any,
  ) {}

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  hideModal() {
    this.hideModalEvent.emit(false);
  }

  openReportIssueModal() {
    this.issueFormDefaultState = {
      issueDescription: null,
    };
    if (this.reportIssueForm) {
      this.reportIssueForm.reset(this.issueFormDefaultState);
    }

    this.modalService.open('newIssueReportModal' + this.issueLocation);
  }

  // POST Issue Report
  postIssue(myForm: FormGroup) {
    this.spinner.show('confirmissue');
    this.modalService.open('confirmIssueModal');

    this.verdeErrorLogBody.bt_errordescription = myForm.value.issueDescription;
    this.verdeErrorLogBody.bt_errortype = 'ISSUE - ' + this.issueLocation;
    this.verdeErrorLogBody.bt_email = this.userService.user?.email;
    this.verdeErrorLogBody.odataPostEmployee = '/bthr_employees(' + this.userService.user?.employeeId + ')';
    this.verdeErrorLogBody.odataPostLegalEntity = '/bthr_legalentitieses(' + this.userService.user?.legalEntityId + ')';
    this.verdeErrorLogBody.bt_type = 692360001;
    this.verdeErrorLogBody.bt_uniquerecord = this.userService.user?.employeeId + moment().format('YYYY-MM-DD, h:mm:ss a');

    let recordID = '';

    this.sharedApiVerdeErrorLogService
      .postErrorLog({ body: this.verdeErrorLogBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
          recordID = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.verdeErrorLogBody = {};

          this.confirmationMessage = 'You have reported an issue successfully.';
          this.spinner.hide('confirmissue');

          // Upload Attachment
          if (myForm.value.issueAttachment) {
            this.sharedApiFileService
              .patchVerdeIssueFile({ recordID: recordID, body: { file: myForm.value.issueAttachment[0] } })
              .pipe(take(1))
              .subscribe(
                (ret) => {
                  console.log(ret);
                },
                (error) => {
                  console.error(error);
                },
                () => {
                  this.sharedApiVerdeErrorLogService
                    .issuesNoteFunction({
                      environmentURL: this.userService.verdeEnvironmentConfig?.dataverseUrl,
                      slot: this.environment.slot,
                      issueID: recordID,
                      hasAttachment: 'true',
                    })
                    .pipe(take(1))
                    .subscribe(
                      (ret) => {
                        console.log(ret);
                      },
                      (error) => {
                        console.error(error);
                      },
                    );
                },
              );
          } else {
            this.sharedApiVerdeErrorLogService
              .issuesNoteFunction({
                environmentURL: this.userService.verdeEnvironmentConfig?.dataverseUrl,
                slot: this.environment.slot,
                issueID: recordID,
                hasAttachment: 'false',
              })
              .pipe(take(1))
              .subscribe(
                (ret) => {
                  console.log(ret);
                },
                (error) => {
                  console.error(error);
                },
              );
          }
        },
      );
  }
}
