/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvAllMedicalSchemes } from '../models/dv-all-medical-schemes';

@Injectable({
  providedIn: 'root',
})
export class SharedApiAllMedicalSchemesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllMedicalSchemes
   */
  static readonly GetAllMedicalSchemesPath = '/api/AllMedicalSchemes/all/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMedicalSchemes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMedicalSchemes$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<DvAllMedicalSchemes>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiAllMedicalSchemesService.GetAllMedicalSchemesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvAllMedicalSchemes>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllMedicalSchemes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMedicalSchemes(params: { subscriptionKey?: string; APIURL?: string; legalEntityGuid: string }): Observable<Array<DvAllMedicalSchemes>> {
    return this.getAllMedicalSchemes$Response(params).pipe(map((r: StrictHttpResponse<Array<DvAllMedicalSchemes>>) => r.body as Array<DvAllMedicalSchemes>));
  }
}
