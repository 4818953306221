import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskPersonalActions, TaskPersonalActionTypes } from '../actions/task-personal.actions';
import * as fromTaskRelatedReducer from './task-related.reducer';

// Models
import { VerdeTaskCustom } from '@verde/shared';

// Helpers
import { keyHandler } from '@verde/core';

export interface TaskPersonalState extends EntityState<VerdeTaskCustom> {}

export interface TaskPersonalRelatedState {
  taskPersonalState: TaskPersonalState;
  loading: boolean;
  error: any;
}

export const taskPersonalKeys = ['bt_verdetasklistid'];
export const taskPersonalStateAdapter: EntityAdapter<VerdeTaskCustom> = createEntityAdapter<VerdeTaskCustom>({
  selectId: (data) => keyHandler(data, taskPersonalKeys),
});

export const initialTaskPersonalState: TaskPersonalState = taskPersonalStateAdapter.getInitialState({});

export const initialTaskPersonalRelatedState = {
  taskPersonalState: initialTaskPersonalState,
  loading: false,
  error: {},
};

export function taskPersonalRelatedReducer(state = initialTaskPersonalRelatedState, action: TaskPersonalActions): TaskPersonalRelatedState {
  switch (action.type) {
    // Get All Task Personal
    case TaskPersonalActionTypes.TASK_PERSONAL_GET_ALL:
      return { ...state, loading: true };
    case TaskPersonalActionTypes.TASK_PERSONAL_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        taskPersonalState: taskPersonalStateAdapter.setAll(action.payload, state.taskPersonalState),
      };
    case TaskPersonalActionTypes.TASK_PERSONAL_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    case TaskPersonalActionTypes.TASK_PERSONAL_CLEAR:
      return { ...initialTaskPersonalRelatedState };
    default:
      return state;
  }
}

export const getTaskRelatedState = createFeatureSelector<fromTaskRelatedReducer.TaskRelatedState>('taskRelatedReducer');

export const getTaskPersonalRelatedState = createSelector(
  getTaskRelatedState,
  (state: fromTaskRelatedReducer.TaskRelatedState) => state.taskPersonalRelatedState,
);

// Current Task

export const getTaskPersonalState = createSelector(getTaskPersonalRelatedState, (state) => state.taskPersonalState);
export const { selectAll: getAllPersonalTasks } = taskPersonalStateAdapter.getSelectors(getTaskPersonalState);

// Loading

export const isTaskPersonalLoading = createSelector(getTaskPersonalRelatedState, (state) => state.loading);

// Error

export const getTaskPersonalError = createSelector(getTaskPersonalRelatedState, (state) => state.error);
