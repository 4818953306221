import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import {
  SharedApiStructureService,
  SharedApiVerdeTimeEntryService,
  StructureActivityModel,
  StructureDepartments,
  StructureDivisions,
  StructureLegalEntities,
  TimeEntryActivityApiModel,
} from '@verde/api';
import { ModalService } from '@verde/shared';
import { Subject, take, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../services/verde-approval.service';

@Component({
  selector: 'verde-structure-activity',
  templateUrl: './structure-activity.component.html',
  styleUrls: ['./structure-activity.component.scss'],
})
export class StructureActivityComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  activityID: TimeEntryActivityApiModel;

  arr: Array<{ id: boolean; text: string }> = [
    { id: true, text: 'Yes' },
    { id: false, text: 'No' },
  ];

  structureActivityBody: StructureActivityModel;

  AllDivisionsData: Array<{ id: boolean; text: string }>;
  AllDepartmentsData: Array<{ id: boolean; text: string }>;

  StructureEntityData: StructureLegalEntities[];
  DivisionData: StructureDivisions[];
  DepartmentData: StructureDepartments[];

  FilteredDivisionData: StructureDivisions[];
  FilteredDepartmentData: StructureDepartments[];

  AllDivisionChoice: boolean = false;
  AllDepartmentChoice: boolean = false;

  AllDivisionDisabled: boolean = true;
  DivisionDisabled: boolean = true;
  AllDepartmentDisabled: boolean = true;
  DepartmentDisabled: boolean = true;

  objectToSubmit: any;

  constructor(
    private sidebarService: VerdeApprovalService,
    private sharedApiStructureService: SharedApiStructureService,
    private sharedApiVerdeTimeEntryService: SharedApiVerdeTimeEntryService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.sidebarService
      .getstructureActivity()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((id: TimeEntryActivityApiModel) => {
        if (id.bt_verdetimeentryactivityid!.length > 0) {
          this.activityID = id;
        }
      });

    this.settingData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  settingData() {
    this.AllDivisionsData = this.arr;
    this.AllDepartmentsData = this.arr;

    this.GetAllLegalEntites();
    this.GetAllDivisions();
    this.getAllDepartments();

    this.getActivityData();
  }

  getActivityData() {}

  GetAllLegalEntites() {
    this.sharedApiStructureService
      .structureGetAllLegalEntities()
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.StructureEntityData = ret;
        },
        (error) => {
          console.error(error);
        },
      );
  }

  getAllDepartments() {
    this.sharedApiStructureService
      .structureGetAllDepartments()
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.DepartmentData = ret;
        },
        (error) => {
          console.error(error);
        },
      );
  }

  GetAllDivisions() {
    this.sharedApiStructureService
      .structureGetAllDivisions()
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.DivisionData = ret;
        },
        (error) => {
          console.error(error);
        },
      );
  }

  SetStructureEntity(
    e: StructureLegalEntities,
    element1: FormControlDirective,
    element2: FormControlDirective,
    element3: FormControlDirective,
    element4: FormControlDirective,
  ) {
    element1.reset();
    element2.reset();
    element3.reset();
    element4.reset();

    this.AllDivisionChoice = false;
    this.AllDepartmentChoice = false;

    this.AllDivisionDisabled = true;
    this.DivisionDisabled = true;
    this.AllDepartmentDisabled = true;
    this.DepartmentDisabled = true;

    this.FilteredDivisionData = this.DivisionData.filter((t) => t._bthr_legalentity_value == e.bthr_legalentitiesid);
    this.AllDivisionDisabled = false;
  }

  SetAllDivisions(e: any, element1: FormControlDirective, element2: FormControlDirective, element3: FormControlDirective) {
    element1.reset();
    element2.reset();
    element3.reset();

    this.AllDivisionChoice = false;
    this.AllDepartmentChoice = false;

    this.DivisionDisabled = true;
    this.AllDepartmentDisabled = true;
    this.DepartmentDisabled = true;

    this.AllDivisionChoice = e.id;
    this.DivisionDisabled = false;
    if (e.id == true) {
      this.AllDepartmentDisabled = false;
      this.AllDepartmentChoice = e.id;
    }
  }
  SetDivision(e: StructureDivisions, element1: FormControlDirective, element2: FormControlDirective) {
    element1.reset();
    element2.reset();

    this.FilteredDepartmentData = this.DepartmentData.filter((t) => t._bthr_division_value == e.bthr_divisionid);
    this.AllDepartmentDisabled = false;
  }
  SetAllDepartments(e: any, element1: FormControlDirective) {
    element1.reset();

    this.AllDepartmentChoice = e.id;
    this.DepartmentDisabled = false;
  }
  SetDepartment(e: StructureDepartments) {}

  SubmitStructureAccess(e: any) {
    this.objectToSubmit = e;

    this.modalService.open('Confirmation');
  }

  Submit() {
    this.structureActivityBody = {
      odataPostbt_legalentity_value: '/bthr_legalentitieses(' + this.activityID._bt_legalentity_value + ')',
      odataPostbt_group_value: '/bt_verdetimeentrygroupings(' + this.activityID._bt_grouping_value + ')',
      odataPostbt_activity_value: '/bt_verdetimeentryactivities(' + this.activityID.bt_verdetimeentryactivityid + ')',
      odataPostbt_structureentity_value: '/bthr_legalentitieses(' + this.objectToSubmit.form.value.StructureEntityDropdown.bthr_legalentitiesid + ')',
      bt_alldivisions: this.objectToSubmit.form.value.AllDivisionsDropdown.id,
      odataPostbt_division_value:
        this.objectToSubmit.form.value.DivisionDropdown != undefined
          ? '/bthr_divisions(' + this.objectToSubmit.form.value.DivisionDropdown.bthr_divisionid + ')'
          : null,
      bt_alldepartments: this.objectToSubmit.form.value.AllDepartmentsDropdown != undefined ? this.objectToSubmit.form.value.AllDepartmentsDropdown.id : true,
      odataPostbt_department_value:
        this.objectToSubmit.form.value.DepartmentDropdown != undefined
          ? '/bthr_departments(' + this.objectToSubmit.form.value.DepartmentDropdown.bthr_departmentid + ')'
          : null,
    };

    this.sharedApiVerdeTimeEntryService
      .postVerdeTimeEntryStructureActivity({ body: this.structureActivityBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.objectToSubmit = {};
        },
        (error) => {
          console.error(error);
        },
      );

    this.sidebarService.setShowSidebar(false);
  }
}
