import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import {
  BookingDurationsFiltered,
  BookingTypesFiltered,
  LocationsFiltered,
  OfficesFiltered,
  SharedApiBookingDurationService,
  SharedApiBookingLocationsService,
  SharedApiBookingOfficesService,
  SharedApiBookingSubOfficesService,
  SharedApiBookingTypesService,
  SubOfficesFiltered,
  UserUserDto,
} from '@verde/api';

import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../../services/verde-approval.service';
import { DeviceTypeService } from 'libs/core/src/lib/services/device-type.service';
import { UserService } from '@verde/core';
import { SidePanelWidth } from '../../../enums/sidepanel-width.enum';

@Component({
  selector: 'verde-booking-availability-search',
  templateUrl: './booking-availability-search.component.html',
  styleUrls: ['./booking-availability-search.component.scss'],
})
export class BookingAvailabilitySearchComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  disableAnimation: boolean;

  // Api data fields
  bookingAvailabilities: GridDataResult;
  bookingTypes: BookingTypesFiltered[] = [];
  primaryLocations: LocationsFiltered[] = [];
  offices: OfficesFiltered[] = [];
  subOffices: SubOfficesFiltered[] = [];
  bookingDurations: BookingDurationsFiltered[] = [];

  // Search criteria fields

  fromDate: Date;
  toDate: Date;

  bookingDuration: any;
  bookingType: any;

  selectedBookingDuration: BookingDurationsFiltered;

  primaryLocation: LocationsFiltered;
  office: OfficesFiltered;
  subOffice: SubOfficesFiltered;
  SelectedSubOffice: SubOfficesFiltered;
  bookingItem: string;
  primaryLocObject: LocationsFiltered;
  primaryOfficeObject: OfficesFiltered;
  // Loading indicators
  bookingDurationsLoading: boolean;
  bookingTypesLoading: boolean;
  primaryLocationsLoading: boolean;
  officesLoading: boolean;
  subOfficesLoading: boolean;

  // Not found indicators
  subOfficesNotFound = false;
  officesNotFound = false;
  bookingAvailabilitiesNotFound = false;
  displayOfficeSelection = false;
  displaySubOfficeSelection = false;

  showAvailabilityResultsModal: boolean;
  MinDate: any;

  user: UserUserDto;

  PrefSet = false;

  PrimaryLocName: string;
  OfficeLocName: string;
  SubOfficeLocName: string;

  UserDepartment: string;
  DepartmentToPass: string;
  BookingTypeName: string;

  BookingReason: string;

  NeedDepartment: boolean;
  NeedOffice: boolean;
  NeedReason: boolean;
  NeedSubOffice: boolean;
  NeedLocation: boolean;

  format = 'yyyy/MM/dd HH:mm';

  steps: any = {
    minute: 15,
  };

  get BookingSubmitActive(): boolean {
    return this.bookingDuration != null && this.primaryLocation != null && ((this.office != null && this.subOffice != null) || this.BookingReason?.length > 10);
  }

  @Input() openModal: boolean;

  constructor(
    private sidebarService: VerdeApprovalService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private sharedApiBookingTypesService: SharedApiBookingTypesService,
    private sharedApiBookingDurationService: SharedApiBookingDurationService,
    private sharedApiBookingLocationsService: SharedApiBookingLocationsService,
    private sharedApiBookingOfficesService: SharedApiBookingOfficesService,
    private sharedApiBookingSubOfficesService: SharedApiBookingSubOfficesService,
    public deviceTypeService: DeviceTypeService,
  ) {}

  ngOnInit(): void {
    this.user = this.userService.proxyUser;
    this.UserDepartment = this.user?.divisionName + ' -- ' + this.user?.departmentName;
    this.DepartmentToPass = this.user?.departmentId;
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => (this.disableAnimation = data));

    this.open();
  }

  resetBookingFields() {
    this.bookingType = null;
    this.bookingDuration = null;
    this.fromDate = new Date(moment().format('YYYY-MM-DDT08:00'));
    this.toDate = new Date(moment().format('YYYY-MM-DDT08:00'));
    this.primaryLocation = null;
    this.office = null;
    this.subOffice = null;
    this.displayOfficeSelection = false;
    this.displaySubOfficeSelection = false;
    this.MinDate = new Date(moment().format('YYYY-MM-DDT08:00'));
  }

  SetBookingType(e: BookingTypesFiltered) {
    this.bookingType = e;

    this.BookingTypeName = e.bt_bookingtype;
    this.getAllBookingDurations(e.bt_bookingcategory);

    const SelectedType = this.bookingTypes.find((y) => y.bt_bookingtype === this.BookingTypeName);

    this.NeedDepartment = SelectedType.bt_department;
    this.NeedOffice = SelectedType.bt_office;
    this.NeedReason = SelectedType.bt_reasonrequired;
    this.NeedSubOffice = SelectedType.bt_suboffice;
    this.NeedLocation = SelectedType.bt_location;

    if (!this.NeedLocation) {
      this.primaryLocation = null;
    }
    if (!this.NeedOffice) {
      this.office = null;
    }
    if (!this.NeedSubOffice) {
      this.subOffice = null;
    }

    this.BookingReason = null;
  }

  open() {
    this.getAllBookingTypes();
    this.getAllPrimaryLocations();
    this.resetBookingFields();
  }

  private resetBookingTypes(): void {
    this.bookingTypes = null;
  }

  private resetBookingDurations(): void {
    this.bookingDurations = null;
  }

  private resetPrimaryLocations(): void {
    this.primaryLocations = [];
  }

  private resetOffices(): void {
    this.offices = [];
  }

  private resetSubOffices(): void {
    this.subOffices = [];
  }

  private resetForPrimaryLocations(): void {
    this.offices = [];
    this.office = null;
    this.subOffices = [];
    this.subOffice = null;
  }

  private resetForOffices(): void {
    this.subOffices = [];
    this.subOffice = null;
  }

  getAllBookingTypes() {
    this.resetBookingTypes();

    this.bookingTypesLoading = true;
    this.spinner.show('bookingtypes');

    this.sharedApiBookingTypesService
      .getBookingTypesByEntity({ legalEntityID: this.userService.user?.legalEntityId })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe({
        next: (allBookingTypes) => (this.bookingTypes = [...allBookingTypes]),
        error: (error) => console.error('Error fetching booking types:', error),
        complete: () => this.finalizeLoading('bookingtypes', 'bookingTypesLoading'),
      });
  }

  private finalizeLoading(spinnerName: string, loadingFlag: string): void {
    this.spinner.hide(spinnerName);
    this[loadingFlag] = false;
  }

  getAllBookingDurations(bookingType: string) {
    this.resetBookingDurations();

    this.BookingReason = null;

    this.spinner.show('bookingdurations');
    this.bookingDurationsLoading = true;

    this.sharedApiBookingDurationService
      .getBookingDurationsByEntity({ bookingType })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(
        (allBookingDurations) => {
          this.bookingDurations = [...allBookingDurations];
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('bookingdurations');
          this.bookingDurationsLoading = false;
        },
      );
  }

  getAllPrimaryLocations() {
    this.resetPrimaryLocations();

    this.spinner.show('primarylocations');
    this.primaryLocationsLoading = true;

    this.sharedApiBookingLocationsService
      .getLocationsByEntity({ legalEntityID: this.userService.user?.legalEntityId })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(
        (allPrimaryLocations) => {
          this.primaryLocations = [...allPrimaryLocations];
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('primarylocations');
          this.primaryLocationsLoading = false;
        },
      );
  }

  getAllOffices() {
    this.resetOffices();

    this.spinner.show('offices');
    this.officesLoading = true;
    this.sharedApiBookingOfficesService
      .getOfficesByEntity({ legalEntityID: this.userService.user?.legalEntityId, locationID: this.primaryLocObject.bthr_locationid })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(
        (allOffices) => {
          this.offices = [...allOffices];

          if (!this.offices || this.offices.length == 0) {
            this.officesNotFound = true;
            this.subOfficesNotFound = false;
          } else {
            this.officesNotFound = false;
            this.subOfficesNotFound = false;
          }
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('offices');
          this.officesLoading = false;
        },
      );
  }

  getAllSubOffices() {
    this.resetSubOffices();

    this.spinner.show('suboffices');
    this.subOfficesLoading = true;

    this.sharedApiBookingSubOfficesService
      .getSubOfficesByEntity({ legalEntityID: this.userService.user?.legalEntityId, officeID: this.primaryOfficeObject.bt_verdeofficeid })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(
        (allSubOffices) => {
          this.subOffices = [...allSubOffices];

          if (!this.subOffices || this.subOffices.length == 0) {
            this.subOfficesNotFound = true;
          } else {
            this.subOfficesNotFound = false;

            this.subOffices.forEach((t) => {
              if (t.bt_verdesubofficeid === this.user?.subOfficeId) {
                this.SelectedSubOffice = t;
              }
            });
          }
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('suboffices');
          this.subOfficesLoading = false;
        },
      );
  }

  searchForAvailability() {
    this.bookingTypes.forEach((type) => {
      if (type.bt_bookingtypeid === this.bookingType) {
        this.bookingType = type;
      }
    });
    if (this.deviceTypeService.isMobile() || this.deviceTypeService.isTablet()) {
      this.sidebarService.setSidebarSize(SidePanelWidth.Full);
    } else {
      this.sidebarService.setSidebarSize(SidePanelWidth.TwoThirds);
    }
    this.sidebarService.setSidebarType('bookingAvailabilityResults');
    this.sidebarService.setBookingAvailabilitySearchDetails({
      bookingFrom: this.fromDate,
      bookingTo: this.toDate,
      bookingDuration: this.bookingDuration,
      bookingType: this.bookingType?.bt_bookingtypeid,
      bookingTypeName: this.bookingType?.bt_bookingtype,
      primaryLocation: this.primaryLocation?.bthr_locationid,
      office: this.office?.bt_verdeofficeid,
      subOffice: this.subOffice?.bt_verdesubofficeid,
      bookingItem: this.bookingItem,
      PrimaryLocName: this.PrimaryLocName,
      OfficeLocName: this.OfficeLocName,
      SubOfficeLocName: this.SubOfficeLocName,
      SelectedSubOffice: this.SelectedSubOffice?.bt_attachmenttext,
      BookingReason: this.BookingReason,
      DepartmentToPass: this.DepartmentToPass,
      displayTitleMessage: '',
    });
    this.sidebarService.setShowSidebar(true);
  }

  toggleOfficeSelection() {
    this.displayOfficeSelection = !this.displayOfficeSelection;

    if (this.displayOfficeSelection) {
      this.getAllOffices();
    }
  }

  toggleSubOfficeSelection() {
    this.displaySubOfficeSelection = !this.displaySubOfficeSelection;

    if (this.displaySubOfficeSelection) {
      this.getAllSubOffices();
    }
  }

  updatePrimaryLocation(e: LocationsFiltered) {
    this.primaryLocObject = e;

    this.PrimaryLocName = e.bthr_locationname;
    this.resetForPrimaryLocations();

    if (this.primaryLocObject) {
      this.getAllOffices();
    }

    if (this.primaryLocation != null && !this.displayOfficeSelection && !this.PrefSet) {
      this.toggleOfficeSelection();
    }
  }

  updateOffice(e: OfficesFiltered) {
    this.primaryOfficeObject = e;
    this.OfficeLocName = e.bt_officename;
    this.resetForOffices();
    this.getAllSubOffices();
    if (this.office != null && !this.displaySubOfficeSelection && this.PrefSet) {
      this.toggleSubOfficeSelection();
    }
  }

  updateSubOffice(e: SubOfficesFiltered) {
    this.SubOfficeLocName = e.bt_name;
    this.subOffices.forEach((t) => {
      if (t.bt_verdesubofficeid == this.subOffice?.bt_verdesubofficeid) {
        this.SelectedSubOffice = t;
      }
    });
  }

  recalculateDateValues(from: boolean) {
    if (!this.bookingDuration) {
      return;
    }
    if (from) {
      this.toDate = new Date(moment(this.fromDate).add(this.bookingDuration, 'minutes').format('YYYY-MM-DDTHH:mm'));
    } else {
      this.fromDate = new Date(moment(this.toDate).subtract(this.bookingDuration, 'minutes').format('YYYY-MM-DDTHH:mm'));
    }
  }

  updateFromDate(e) {
    this.fromDate = new Date(moment(e).format('YYYY-MM-DDTHH:mm'));
    this.SwapIDAndMin(e, true);
  }

  updateToDate(e) {
    this.toDate = new Date(moment(e).format('YYYY-MM-DDTHH:mm'));
    this.SwapIDAndMin(e, false);
  }

  updateFromDatePicker(e) {
    this.SwapIDAndMin(e.bt_verdebookingdurationid, true);
  }

  SwapIDAndMin(ID, bool) {
    this.bookingDurations.forEach((t) => {
      if (t.bt_verdebookingdurationid === ID) {
        this.selectedBookingDuration = t;
      }
    });

    this.bookingDuration = this.selectedBookingDuration.bt_minutes.toString();
    if (this.bookingDuration === '540') {
      const date = new Date(this.fromDate);
      date.setHours(8, 0);
      this.fromDate = new Date(moment(date).format('YYYY-MM-DDTHH:mm'));
    }
    this.recalculateDateValues(bool);
    this.bookingDuration = this.selectedBookingDuration.bt_verdebookingdurationid.toString();
  }

  cancel() {
    this.sidebarService.setShowSidebar(false);
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
