<!-- #region Header -->
<div class="expansion_header_flex" style="padding-left: unset">
  <kendo-dropdownlist
    *ngIf="allVoteManagerNominationControl.length > 0"
    class="vote_group"
    [data]="allVoteManagerNominationControl"
    textField="odataVoteGroup"
    valueField="_bt_votinggoup_value"
    id="voteGroupDropdown"
    name="voteGroupDropdown"
    (valueChange)="setVoteGroupDropdownValue($event)"
    [ngModel]="selectedVoteManagerNominationControl"
  >
  </kendo-dropdownlist>

  <div *ngIf="selectedVoteManagerNominationControl == null" class="alert alert-warning" style="padding: 6px 12px !important; margin: 8px 5px 5px 10px">
    You have no nominations currently available for approval
  </div>

  <p *ngIf="selectedVoteManagerNominationControl != null" class="header_label">
    Total Nominations: {{ selectedVoteManagerNominationControl.bt_numberofnominations }}
  </p>

  <p *ngIf="selectedVoteManagerNominationControl != null" class="header_label">Allowed: {{ selectedVoteManagerNominationControl.bt_noofvotesavailable }}</p>

  <p *ngIf="selectedVoteManagerNominationControl != null" class="header_label">Approved: {{ selectedVoteManagerNominationControl.bt_nominationscomplted }}</p>

  <p *ngIf="selectedVoteManagerNominationControl != null" class="header_label">Not Approved: {{ selectedVoteManagerNominationControl.bt_nonotapproved }}</p>

  <p *ngIf="selectedVoteManagerNominationControl != null" class="header_label">Remaining Approvals: {{ selectedVoteManagerNominationControl.bt_remaining }}</p>
</div>
<!-- #endregion Header-->
<!-- #region Body -->

<div style="min-height: 250px">
  <div *ngIf="selectedVoteManagerNominationControl != null" style="position: relative">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="votegroupgrid"
      bdColor="rgb(237, 237, 237)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <form novalidate #editNominationsForm="ngForm">
      <kendo-grid
        #myGrid
        [rowClass]="rowCallback"
        [kendoGridBinding]="approvalsGridData"
        [resizable]="true"
        [scrollable]="true"
        [groupable]="true"
        [group]="approvalsGroups"
        (groupChange)="approvalsGroupChange($event)"
        (dataStateChange)="approvalsDataStateChange($event)"
        [selectable]="true"
        [kendoGridSelectBy]="approvalsSelectedCallback"
        [selectedKeys]="approvalsSelection"
        [sortable]="true"
        [sort]="approvalsSort"
        (sortChange)="approvalsSortChange($event)"
        (edit)="editHandler($event)"
        (cancel)="cancelHandler($event)"
        (save)="saveHandler($event)"
        (remove)="removeHandler($event)"
        (add)="addHandler($event, editNominationsForm)"
      >
        <!-- columns for larger devices -->
        <ng-template kendoGridToolbarTemplate>
          <input placeholder="Search all columns" kendoTextBox style="text-transform: none !important" (input)="onApprovalsFilter($event.target.value)" />
          <kendo-grid-spacer></kendo-grid-spacer>
          <button
            *ngIf="selectedVoteManagerNominationControl != null"
            [disabled]="confirmDisabledFlag"
            kendoButton
            themeColor="primary"
            (click)="confirmAllApprovals()"
          >
            Confirm
          </button>
          <button *ngIf="!(deviceTypeService.isMobile$ | async)" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
          <img
            title="Refresh"
            class="refresh_icon"
            src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg"
            alt=""
            (click)="refresh()"
          />
        </ng-template>
        <kendo-grid-column title="Image" width="60">
          <ng-template kendoGridCellTemplate let-ListTable>
            <img
              *ngIf="ListTable.bt_NominatedEmployee?.bthr_employeephoto != null"
              src="data:image/png;base64,{{ ListTable.bt_NominatedEmployee?.bthr_employeephoto }}"
              style="border-radius: 25%; z-index: 100; height: 50px; width: 50px"
            />
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="bt_NominatedEmployee.bt_fullname" title="Full Name" width="200"> </kendo-grid-column>
        <kendo-grid-column field="odataNomineeLegalEntity" title="Legal Entity" width="200"> </kendo-grid-column>
        <kendo-grid-column field="odataManagerApproved" title="Approved" width="75"> </kendo-grid-column>
        <kendo-grid-column field="odataApprovalStatus" title="Status" width="140"> </kendo-grid-column>
        <kendo-grid-column
          *ngIf="selectedVoteManagerNominationControl.bt_remaining > 0"
          field="bt_managerrejectionreason"
          title="Reason for not approving"
          width="250"
        >
        </kendo-grid-column>
        <kendo-grid-column
          *ngIf="selectedVoteManagerNominationControl.bt_remaining == 0"
          field="bt_managerrejectionreason"
          title="Reason for not approving"
          width="250"
        >
          <ng-template kendoGridCellTemplate let-ListTable>
            <div *ngIf="ListTable.odataApprovalStatus === 'Completed'">{{ ListTable.bt_managerrejectionreason }}</div>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <div *ngIf="dataItem.odataApprovalStatus === 'Outstanding'">
              <input
                [(ngModel)]="dataItem.bt_managerrejectionreason"
                kendoGridFocusable
                type="text"
                name="bt_managerrejectionreason"
                class="k-textbox k-input k-rounded-md"
                required
              />
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="odataRequiredBy" title="Required By" width="150"> </kendo-grid-column>
        <kendo-grid-command-column title="" width="40" [sticky]="true" [minResizableWidth]="40" [maxResizableWidth]="40">
          <ng-template kendoGridCellTemplate let-dataItem>
            <verde-dots-menu-button
              [items]="dotMenuItems"
              (menuClicked)="menuClicked(dataItem)"
              (menuItemClicked)="menuItemClicked($event, dataItem, approveNominationForm, editNominationsForm)"
            ></verde-dots-menu-button>

            <!--<mat-icon *ngIf="ListTable.odataApprovalStatus !== 'Completed' && selectedVoteManagerNominationControl.bt_remaining > 0"
                      title="Approve"
                      class="edit_button"
                      (click)="openApprovalModal(ListTable, approveNominationForm)">done_outline</mat-icon>-->
            <button hidden id="editReasonButton" kendoGridEditCommand></button>
            <button hidden id="editSaveButton" kendoGridSaveCommand [disabled]="editNominationsForm.invalid || editNominationsForm.pristine"></button>
            <button hidden id="cancelAddButton" kendoGridCancelCommand kendoButton></button>
          </ng-template>
        </kendo-grid-command-column>

        <!--<kendo-grid-column title=""
                           width="120">
          <ng-template kendoGridCellTemplate
                       let-ListTable
                       let-isNew="isNew">

            <mat-icon *ngIf="ListTable.odataApprovalStatus !== 'Completed' && selectedVoteManagerNominationControl.bt_remaining > 0"
                      title="Approve"
                      class="edit_button"
                      (click)="openApprovalModal(ListTable, approveNominationForm)">done_outline</mat-icon>
            <button *ngIf="ListTable.odataApprovalStatus !== 'Completed' && selectedVoteManagerNominationControl.bt_remaining == 0 && !ListTable.bt_managerapproved"
                    kendoGridEditCommand>
              Edit Reason
            </button>
            <button kendoGridSaveCommand
                    [disabled]="editNominationsForm.invalid || editNominationsForm.pristine">
              {{ isNew ? 'Add' : 'Update' }}
            </button>
            <button id="cancelAddButton"
                    kendoGridCancelCommand
                    kendoButton>
              {{ isNew ? 'Discard' : 'Cancel' }}
            </button>
          </ng-template>
        </kendo-grid-column>-->

        <kendo-grid-excel fileName="NominationApprovalsPlans.xlsx"></kendo-grid-excel>
      </kendo-grid>
    </form>

    <br />
    <br />
  </div>
</div>

<!-- #endregion Body -->
<!-- #region Modals -->
<!-- #region Approve Nomination Modal -->
<verde-modal
  modalTitle="Approve Nomination"
  submitButton="Submit"
  (actionButton)="patchNominationLists(approveNominationForm)"
  (modalStateOpen)="hideUserCard($event)"
  id="approveNominationModal"
  modalMaxWidth="1100"
  [submitActive]="approveNominationForm.valid && (agreedYes || agreedNo)"
  submitConfirmationMessage="Please confirm that you would like to submit this approval"
  [hasSubmitConfirmation]="true"
>
  <form #approveNominationForm="ngForm" novalidate autocomplete="off">
    <div class="nomination_approval_grid">
      <div>
        <p style="margin-bottom: 10px">The following team member:</p>

        <div style="min-height: 200px">
          <verde-user-card *ngIf="showUserCard" employeeID="{{ selectedNominee?._bt_nominatedemployee_value }}"></verde-user-card>
        </div>

        <p style="margin-top: 10px; margin-bottom: 10px">has been nominated in the {{ selectedNominee?.odataVoteGroup }} vote.</p>
      </div>

      <div style="width: 650px">
        <p style="margin-bottom: 10px">Nominees:</p>
        <kendo-grid
          style="max-height: 250px"
          [rowClass]="rowCallback"
          [data]="reasonsGridView"
          [resizable]="true"
          [scrollable]="true"
          [group]="reasonsGroups"
          (groupChange)="reasonsGroupChange($event)"
          (dataStateChange)="reasonsDataStateChange($event)"
          [sortable]="true"
          [sort]="reasonsSort"
          (sortChange)="reasonsSortChange($event)"
        >
          <kendo-grid-column title="Image" width="60">
            <ng-template kendoGridCellTemplate let-ListTable>
              <img
                *ngIf="ListTable.bt_NominatorEmployee?.bthr_employeephoto != null"
                src="data:image/png;base64,{{ ListTable.bt_NominatorEmployee?.bthr_employeephoto }}"
                style="border-radius: 25%; z-index: 100; height: 50px; width: 50px"
              />
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="bt_NominatorEmployee.bt_fullname" title="Nominator">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div
                [id]="dataItem.bt_votenominationid"
                [innerHTML]="dataItem.bt_NominatorEmployee.bt_fullname"
                [title]="getTitle(dataItem.bt_votenominationid)"
              ></div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="odataNominatorLegalEntity" title="Legal Entity"> </kendo-grid-column>
          <kendo-grid-column field="bt_nominationreason" title="Reason" width="200">
            <ng-template kendoGridCellTemplate let-ListTable>
              <div style="white-space: normal !important">
                {{ ListTable.bt_nominationreason }}
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>

      <div style="height: 90px">
        <div *ngIf="agreedNo" class="form-group" style="margin-bottom: unset">
          <label for="rejectReason">Reason for not approving:</label>
          <textarea
            required
            minlength="3"
            maxlength="500"
            placeholder="Please enter a detailed reason for not approving"
            class="form-control"
            id="rejectReason"
            name="rejectReason"
            #varRejectReason="ngModel"
            [ngModel]
          ></textarea>
          <!-- Validation -->
          <div class="alert alert-danger" *ngIf="varRejectReason.touched && !varRejectReason.valid">
            <div *ngIf="varRejectReason.errors.required">Field is required!</div>
            <div *ngIf="varRejectReason.errors.minlength">Enter a detailed reason</div>
          </div>
        </div>
      </div>

      <div class="form-group" style="text-align: right; margin-top: 50px">
        <div style="display: inline-flex; margin-bottom: unset">
          <label style="margin-bottom: unset">Do you approve this nomination?</label>

          <div style="display: inline-flex; margin-bottom: unset; margin-left: 5px; float: right">
            <label style="margin-bottom: unset" for="agreedYes">Yes:</label>
            <input
              style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align: middle"
              (change)="agreedYesChange($event.target.checked, varAgreedNo)"
              type="checkbox"
              kendoCheckBox
              class="form-control"
              id="agreedYes"
              name="agreedYes"
              #varAgreedYes="ngModel"
              [ngModel]
            />

            <label for="agreedNo" style="margin-left: 5px; margin-bottom: unset">No:</label>
            <input
              style="width: 25px; height: 20px; margin: 3px 0 0 5px; vertical-align: middle"
              (change)="agreedNoChange($event.target.checked, varAgreedYes)"
              type="checkbox"
              kendoCheckBox
              class="form-control"
              id="agreedNo"
              name="agreedNo"
              #varAgreedNo="ngModel"
              [ngModel]
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</verde-modal>

<!-- #endregion Approve Nomination Modal -->
<!-- #region Check Confirm Approvals Modal -->

<verde-modal modalTitle="Confirm Approvals" id="confirmAllApprovalsModal" modalMaxWidth="400" submitButton="Confirm" (actionButton)="patchFinaliseApprovals()">
  <p>Please confirm that you would like to finalise these approvals</p>
</verde-modal>
<!-- #endregion Check Confirm Approvals Modal -->
<!-- #region Confirmation Modal -->
<verde-modal modalTitle="Confirmation" id="confirmationModal" modalMaxWidth="400" [hasCancelButton]="false" submitButton="Continue">
  <div style="position: relative; height: 60px">
    <ngx-spinner
      [disableAnimation]="disableAnimation"
      name="confirmation"
      bdColor="rgb(237, 237, 237)"
      size="default"
      color="#6264a7"
      type="ball-clip-rotate"
      [fullScreen]="false"
    >
    </ngx-spinner>

    <p>{{ confirmationMessage }}</p>
  </div>
</verde-modal>
<!-- #endregion Confirmation Modal -->

<verde-modal
  id="confirmRejectionModal"
  modalTitle="Confirm"
  modalMaxWidth="350"
  submitButton="Confirm"
  [zIndex]="3000"
  (actionButton)="patchRejection()"
  (cancelButton)="cancelPatchRejection()"
>
  <p>Please confirm that you would like submit this reason for not approving</p>
</verde-modal>
<!-- Form not valid -->
<verde-modal id="editFormNotValidErrorModal" modalTitle="Invalid Entry" modalMaxWidth="350" submitButton="Continue" [hasCancelButton]="false" [zIndex]="3000">
  <p>Please ensure that all fields are completed before adding a new supplier</p>
</verde-modal>

<!-- #endregion Modals -->
