/* tslint:disable */
/* eslint-disable */
import { BtDocSalaryAdviceDetail } from './bt-doc-salary-advice-detail';
export interface BtDocSalaryAdviceHeader {
  _bt_doctype_value?: null | string;
  _bt_employee_value?: null | string;
  _bt_legalentity_value?: null | string;
  bt_DocSalaryAdviceDetail_DocSalaryAdviceH?: null | Array<BtDocSalaryAdviceDetail>;
  bt_ctccash?: null | string;
  bt_date?: string;
  bt_dateengaged?: null | string;
  bt_department?: null | string;
  bt_docsalaryadviceheader?: null | string;
  bt_docsalaryadviceheaderid?: null | string;
  bt_doctypetext?: null | string;
  bt_employeeidtext?: null | string;
  bt_idnumber?: null | string;
  bt_jobtitle?: null | string;
  bt_legalentitytext?: null | string;
  bt_netsalary?: null | string;
  bt_paymentmethod?: null | string;
  bt_paypoint?: null | string;
  bt_periodenddate?: null | string;
  bt_periodno?: null | any;
  bt_rateperhour?: null | string;
  bt_taxyear?: null | any;
  bt_totalcc?: null | string;
  bt_totalcompanycontributions?: null | string;
  bt_totaldeductions?: null | string;
  bt_totalearnings?: null | string;
  bt_totalperks?: null | string;
  bt_ytdearnings?: null | string;
  bt_ytdtax?: null | string;
  odatabt_doctype_value?: null | string;
  odatabt_employee_value?: null | string;
  odatabt_legalentity_value?: null | string;
}
