/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EquipmentBaseService } from '../equipment-base-service';
import { EquipmentApiConfiguration } from '../equipment-api-configuration';
import { EquipmentStrictHttpResponse } from '../equipment-strict-http-response';
import { EquipmentRequestBuilder } from '../equipment-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EquipmentEquipmentAccess } from '../models/equipment-equipment-access';
import { EquipmentEquipmentItem } from '../models/equipment-equipment-item';
import { EquipmentEquipmentItemSummary } from '../models/equipment-equipment-item-summary';
import { EquipmentEquipmentKit } from '../models/equipment-equipment-kit';
import { EquipmentEquipmentKitItem } from '../models/equipment-equipment-kit-item';
import { EquipmentEquipmentRequestDetail } from '../models/equipment-equipment-request-detail';
import { EquipmentEquipmentRequestHeader } from '../models/equipment-equipment-request-header';
import { EquipmentEquipmentTakeOn } from '../models/equipment-equipment-take-on';
import { EquipmentEquipmentTakeOnControl } from '../models/equipment-equipment-take-on-control';
import { EquipmentEquipmentTakeOnGroup } from '../models/equipment-equipment-take-on-group';
import { EquipmentEquipmentTakeOnMake } from '../models/equipment-equipment-take-on-make';
import { EquipmentEquipmentTakeOnType } from '../models/equipment-equipment-take-on-type';
import { EquipmentGetEquipmentAccessQuery } from '../models/equipment-get-equipment-access-query';
import { EquipmentGetEquipmentItemSummaryQuery } from '../models/equipment-get-equipment-item-summary-query';
import { EquipmentGetEquipmentItemsByEmployeeQuery } from '../models/equipment-get-equipment-items-by-employee-query';
import { EquipmentGetEquipmentItemsByLocationQuery } from '../models/equipment-get-equipment-items-by-location-query';
import { EquipmentGetEquipmentItemsStockQuery } from '../models/equipment-get-equipment-items-stock-query';
import { EquipmentGetEquipmentKitItemQuery } from '../models/equipment-get-equipment-kit-item-query';
import { EquipmentGetEquipmentKitQuery } from '../models/equipment-get-equipment-kit-query';
import { EquipmentGetEquipmentRequestDetailAllocationCountQuery } from '../models/equipment-get-equipment-request-detail-allocation-count-query';
import { EquipmentGetEquipmentRequestDetailAllocationQuery } from '../models/equipment-get-equipment-request-detail-allocation-query';
import { EquipmentGetEquipmentRequestDetailByHeaderIdQuery } from '../models/equipment-get-equipment-request-detail-by-header-id-query';
import { EquipmentGetEquipmentRequestDetailKitItemsQuery } from '../models/equipment-get-equipment-request-detail-kit-items-query';
import { EquipmentGetEquipmentRequestHeaderByIdQuery } from '../models/equipment-get-equipment-request-header-by-id-query';
import { EquipmentGetEquipmentRequestHeaderByOwnerQuery } from '../models/equipment-get-equipment-request-header-by-owner-query';
import { EquipmentGetEquipmentRequestHeaderByUniqueNameQuery } from '../models/equipment-get-equipment-request-header-by-unique-name-query';
import { EquipmentGetEquipmentTakeOnControlQuery } from '../models/equipment-get-equipment-take-on-control-query';
import { EquipmentGetEquipmentTakeOnGroupQuery } from '../models/equipment-get-equipment-take-on-group-query';
import { EquipmentGetEquipmentTakeOnMakeQuery } from '../models/equipment-get-equipment-take-on-make-query';
import { EquipmentGetEquipmentTakeOnQuery } from '../models/equipment-get-equipment-take-on-query';
import { EquipmentGetEquipmentTakeOnTypeQuery } from '../models/equipment-get-equipment-take-on-type-query';

@Injectable({
  providedIn: 'root',
})
export class EquipmentApiEquipmentService extends EquipmentBaseService {
  constructor(config: EquipmentApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getEquipmentItemsByLocation
   */
  static readonly GetEquipmentItemsByLocationPath = '/api/Equipment/itemsbylocation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentItemsByLocation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentItemsByLocation$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentItemsByLocationQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentItem>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentItemsByLocationPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentItem>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentItemsByLocation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentItemsByLocation(params?: { tenantId?: string; body?: EquipmentGetEquipmentItemsByLocationQuery }): Observable<Array<EquipmentEquipmentItem>> {
    return this.getEquipmentItemsByLocation$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentItem>>) => r.body as Array<EquipmentEquipmentItem>),
    );
  }

  /**
   * Path part for operation getEquipmentItemsByEmployee
   */
  static readonly GetEquipmentItemsByEmployeePath = '/api/Equipment/itemsbyemployee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentItemsByEmployee()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentItemsByEmployee$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentItemsByEmployeeQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentItem>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentItemsByEmployeePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentItem>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentItemsByEmployee$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentItemsByEmployee(params?: { tenantId?: string; body?: EquipmentGetEquipmentItemsByEmployeeQuery }): Observable<Array<EquipmentEquipmentItem>> {
    return this.getEquipmentItemsByEmployee$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentItem>>) => r.body as Array<EquipmentEquipmentItem>),
    );
  }

  /**
   * Path part for operation getEquipmentItemsStock
   */
  static readonly GetEquipmentItemsStockPath = '/api/Equipment/itemsstock';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentItemsStock()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentItemsStock$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentItemsStockQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentItem>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentItemsStockPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentItem>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentItemsStock$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentItemsStock(params?: { tenantId?: string; body?: EquipmentGetEquipmentItemsStockQuery }): Observable<Array<EquipmentEquipmentItem>> {
    return this.getEquipmentItemsStock$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentItem>>) => r.body as Array<EquipmentEquipmentItem>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestHeaderByUniqueName
   */
  static readonly GetEquipmentRequestHeaderByUniqueNamePath = '/api/Equipment/requestheaderbyuniquename';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestHeaderByUniqueName()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestHeaderByUniqueName$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestHeaderByUniqueNameQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestHeader>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentRequestHeaderByUniqueNamePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestHeader>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestHeaderByUniqueName$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestHeaderByUniqueName(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestHeaderByUniqueNameQuery;
  }): Observable<Array<EquipmentEquipmentRequestHeader>> {
    return this.getEquipmentRequestHeaderByUniqueName$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestHeader>>) => r.body as Array<EquipmentEquipmentRequestHeader>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestHeaderById
   */
  static readonly GetEquipmentRequestHeaderByIdPath = '/api/Equipment/requestheaderbyid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestHeaderById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestHeaderById$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestHeaderByIdQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestHeader>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentRequestHeaderByIdPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestHeader>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestHeaderById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestHeaderById(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestHeaderByIdQuery;
  }): Observable<Array<EquipmentEquipmentRequestHeader>> {
    return this.getEquipmentRequestHeaderById$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestHeader>>) => r.body as Array<EquipmentEquipmentRequestHeader>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestHeaderByOwner
   */
  static readonly GetEquipmentRequestHeaderByOwnerPath = '/api/Equipment/requestheaderbyowner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestHeaderByOwner()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestHeaderByOwner$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestHeaderByOwnerQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestHeader>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentRequestHeaderByOwnerPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestHeader>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestHeaderByOwner$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestHeaderByOwner(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestHeaderByOwnerQuery;
  }): Observable<Array<EquipmentEquipmentRequestHeader>> {
    return this.getEquipmentRequestHeaderByOwner$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestHeader>>) => r.body as Array<EquipmentEquipmentRequestHeader>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestDetailByHeaderId
   */
  static readonly GetEquipmentRequestDetailByHeaderIdPath = '/api/Equipment/requestdetailbyheaderid';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestDetailByHeaderId()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestDetailByHeaderId$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestDetailByHeaderIdQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestDetail>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentRequestDetailByHeaderIdPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestDetail>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestDetailByHeaderId$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestDetailByHeaderId(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestDetailByHeaderIdQuery;
  }): Observable<Array<EquipmentEquipmentRequestDetail>> {
    return this.getEquipmentRequestDetailByHeaderId$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestDetail>>) => r.body as Array<EquipmentEquipmentRequestDetail>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestDetailKitItems
   */
  static readonly GetEquipmentRequestDetailKitItemsPath = '/api/Equipment/requestdetailkititems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestDetailKitItems()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestDetailKitItems$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestDetailKitItemsQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestDetail>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentRequestDetailKitItemsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestDetail>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestDetailKitItems$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestDetailKitItems(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestDetailKitItemsQuery;
  }): Observable<Array<EquipmentEquipmentRequestDetail>> {
    return this.getEquipmentRequestDetailKitItems$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestDetail>>) => r.body as Array<EquipmentEquipmentRequestDetail>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestDetailAllocation
   */
  static readonly GetEquipmentRequestDetailAllocationPath = '/api/Equipment/requestdetailallocation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestDetailAllocation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestDetailAllocation$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestDetailAllocationQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestDetail>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentRequestDetailAllocationPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestDetail>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestDetailAllocation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestDetailAllocation(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestDetailAllocationQuery;
  }): Observable<Array<EquipmentEquipmentRequestDetail>> {
    return this.getEquipmentRequestDetailAllocation$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestDetail>>) => r.body as Array<EquipmentEquipmentRequestDetail>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestDetailAllocationCount
   */
  static readonly GetEquipmentRequestDetailAllocationCountPath = '/api/Equipment/requestdetailallocationcount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestDetailAllocationCount()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestDetailAllocationCount$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestDetailAllocationCountQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestDetail>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentRequestDetailAllocationCountPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestDetail>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestDetailAllocationCount$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentRequestDetailAllocationCount(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentRequestDetailAllocationCountQuery;
  }): Observable<Array<EquipmentEquipmentRequestDetail>> {
    return this.getEquipmentRequestDetailAllocationCount$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentRequestDetail>>) => r.body as Array<EquipmentEquipmentRequestDetail>),
    );
  }

  /**
   * Path part for operation getEquipmentAccess
   */
  static readonly GetEquipmentAccessPath = '/api/Equipment/access';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentAccess()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentAccess$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentAccessQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentAccess>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentAccessPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentAccess>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentAccess$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentAccess(params?: { tenantId?: string; body?: EquipmentGetEquipmentAccessQuery }): Observable<Array<EquipmentEquipmentAccess>> {
    return this.getEquipmentAccess$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentAccess>>) => r.body as Array<EquipmentEquipmentAccess>),
    );
  }

  /**
   * Path part for operation getEquipmentItemSummary
   */
  static readonly GetEquipmentItemSummaryPath = '/api/Equipment/itemsummary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentItemSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentItemSummary$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentItemSummaryQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentItemSummary>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentItemSummaryPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentItemSummary>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentItemSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentItemSummary(params?: { tenantId?: string; body?: EquipmentGetEquipmentItemSummaryQuery }): Observable<Array<EquipmentEquipmentItemSummary>> {
    return this.getEquipmentItemSummary$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentItemSummary>>) => r.body as Array<EquipmentEquipmentItemSummary>),
    );
  }

  /**
   * Path part for operation getEquipmentTakeOnMake
   */
  static readonly GetEquipmentTakeOnMakePath = '/api/Equipment/takeonmake';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentTakeOnMake()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentTakeOnMake$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentTakeOnMakeQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOnMake>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentTakeOnMakePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOnMake>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentTakeOnMake$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentTakeOnMake(params?: { tenantId?: string; body?: EquipmentGetEquipmentTakeOnMakeQuery }): Observable<Array<EquipmentEquipmentTakeOnMake>> {
    return this.getEquipmentTakeOnMake$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOnMake>>) => r.body as Array<EquipmentEquipmentTakeOnMake>),
    );
  }

  /**
   * Path part for operation getEquipmentTakeOnType
   */
  static readonly GetEquipmentTakeOnTypePath = '/api/Equipment/takeontype';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentTakeOnType()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentTakeOnType$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentTakeOnTypeQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOnType>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentTakeOnTypePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOnType>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentTakeOnType$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentTakeOnType(params?: { tenantId?: string; body?: EquipmentGetEquipmentTakeOnTypeQuery }): Observable<Array<EquipmentEquipmentTakeOnType>> {
    return this.getEquipmentTakeOnType$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOnType>>) => r.body as Array<EquipmentEquipmentTakeOnType>),
    );
  }

  /**
   * Path part for operation getEquipmentTakeOnGroup
   */
  static readonly GetEquipmentTakeOnGroupPath = '/api/Equipment/takeongroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentTakeOnGroup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentTakeOnGroup$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentTakeOnGroupQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOnGroup>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentTakeOnGroupPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOnGroup>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentTakeOnGroup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentTakeOnGroup(params?: { tenantId?: string; body?: EquipmentGetEquipmentTakeOnGroupQuery }): Observable<Array<EquipmentEquipmentTakeOnGroup>> {
    return this.getEquipmentTakeOnGroup$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOnGroup>>) => r.body as Array<EquipmentEquipmentTakeOnGroup>),
    );
  }

  /**
   * Path part for operation getEquipmentTakeOnControl
   */
  static readonly GetEquipmentTakeOnControlPath = '/api/Equipment/takeoncontrol';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentTakeOnControl()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentTakeOnControl$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentTakeOnControlQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOnControl>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentTakeOnControlPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOnControl>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentTakeOnControl$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentTakeOnControl(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentTakeOnControlQuery;
  }): Observable<Array<EquipmentEquipmentTakeOnControl>> {
    return this.getEquipmentTakeOnControl$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOnControl>>) => r.body as Array<EquipmentEquipmentTakeOnControl>),
    );
  }

  /**
   * Path part for operation getEquipmentTakeOn
   */
  static readonly GetEquipmentTakeOnPath = '/api/Equipment/takeon';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentTakeOn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentTakeOn$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentTakeOnQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOn>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentTakeOnPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOn>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentTakeOn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentTakeOn(params?: { tenantId?: string; body?: EquipmentGetEquipmentTakeOnQuery }): Observable<Array<EquipmentEquipmentTakeOn>> {
    return this.getEquipmentTakeOn$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentTakeOn>>) => r.body as Array<EquipmentEquipmentTakeOn>),
    );
  }

  /**
   * Path part for operation getEquipmentKit
   */
  static readonly GetEquipmentKitPath = '/api/Equipment/kit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentKit()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentKit$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentKitQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentKit>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentKitPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentKit>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentKit$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentKit(params?: { tenantId?: string; body?: EquipmentGetEquipmentKitQuery }): Observable<Array<EquipmentEquipmentKit>> {
    return this.getEquipmentKit$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentKit>>) => r.body as Array<EquipmentEquipmentKit>),
    );
  }

  /**
   * Path part for operation getEquipmentKitItem
   */
  static readonly GetEquipmentKitItemPath = '/api/Equipment/kititem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentKitItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentKitItem$Response(params?: {
    tenantId?: string;
    body?: EquipmentGetEquipmentKitItemQuery;
  }): Observable<EquipmentStrictHttpResponse<Array<EquipmentEquipmentKitItem>>> {
    const rb = new EquipmentRequestBuilder(this.rootUrl, EquipmentApiEquipmentService.GetEquipmentKitItemPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as EquipmentStrictHttpResponse<Array<EquipmentEquipmentKitItem>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentKitItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEquipmentKitItem(params?: { tenantId?: string; body?: EquipmentGetEquipmentKitItemQuery }): Observable<Array<EquipmentEquipmentKitItem>> {
    return this.getEquipmentKitItem$Response(params).pipe(
      map((r: EquipmentStrictHttpResponse<Array<EquipmentEquipmentKitItem>>) => r.body as Array<EquipmentEquipmentKitItem>),
    );
  }
}
