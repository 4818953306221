import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  QualificationsCreateEducationalInstitutionCommand,
  QualificationsGetEducationalInstitutionQuery,
  QualificationsGetReducedEducationalInstitutionQuery,
} from '@verde/api';
import { Observable } from 'rxjs';
import * as EducationalInstitutionActions from '../store/actions/educational-institution.actions';
import * as fromEducationalInstitutionReducer from '../store/reducers/educational-institution.reducer';
import * as fromProfileRelatedReducer from '../store/reducers/profile-related.reducer';

@Injectable({
  providedIn: 'root',
})
export class EducationalInstitutionService {
  // EducationalInstitution
  educationalInstitutionLoading$: Observable<boolean>;
  educationalInstitutionObjects$: Observable<any[]>;

  constructor(private store: Store<fromProfileRelatedReducer.ProfileRelatedState>) {
    // EducationalInstitution
    this.educationalInstitutionLoading$ = this.store.pipe(select(fromEducationalInstitutionReducer.isEducationalInstitutionLoading));
    this.educationalInstitutionObjects$ = this.store.pipe(select(fromEducationalInstitutionReducer.getEducationalInstitution));
  }

  // EducationalInstitution Detail
  // Get
  getEducationalInstitution(body: QualificationsGetEducationalInstitutionQuery) {
    this.store.dispatch(new EducationalInstitutionActions.EducationalInstitutionGetAll(body));
  }

  // Get Reduced
  getReducedEducationalInstitution(body: QualificationsGetReducedEducationalInstitutionQuery) {
    this.store.dispatch(new EducationalInstitutionActions.EducationalInstitutionGetReduced(body));
  }

  // Clear
  educationalInstitutionClear() {
    this.store.dispatch(new EducationalInstitutionActions.EducationalInstitutionClear(null));
  }

  // Create
  uploadEducationalInstitution(payload: QualificationsCreateEducationalInstitutionCommand) {
    this.store.dispatch(new EducationalInstitutionActions.EducationalInstitutionUpload(payload));
  }
}
