import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QualificationInstitutionLinkActions, QualificationInstitutionLinkActionTypes } from '../actions/qualification-institution-link.actions';
import * as fromProfileRelatedReducer from './profile-related.reducer';

// Models
import { QualificationsBthrQualification } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type QualificationInstitutionLinkState = EntityState<QualificationsBthrQualification>;

export interface QualificationInstitutionLinkRelatedState {
  qualificationInstitutionLinkState: QualificationInstitutionLinkState;
  selectedIds: string[];
  loading: boolean;
  error: any;
}

export const qualificationInstitutionLinkKeys = ['bt_qualficationinstitutionlinkid'];
export const qualificationInstitutionLinkStateAdapter: EntityAdapter<QualificationsBthrQualification> = createEntityAdapter<QualificationsBthrQualification>({
  selectId: (data) => keyHandler(data, qualificationInstitutionLinkKeys),
});

export const initialQualificationInstitutionLinkState: QualificationInstitutionLinkState = qualificationInstitutionLinkStateAdapter.getInitialState({});

export const initialQualificationInstitutionLinkRelatedState = {
  qualificationInstitutionLinkState: initialQualificationInstitutionLinkState,
  selectedIds: [],
  loading: false,
  error: {},
};

export function QualificationInstitutionLinkRelatedReducer(
  state = initialQualificationInstitutionLinkRelatedState,
  action: QualificationInstitutionLinkActions,
): QualificationInstitutionLinkRelatedState {
  switch (action.type) {
    // Get QualificationInstitutionLink
    case QualificationInstitutionLinkActionTypes.QUALIFICATIONINSTITUTIONLINK_GET_ALL:
      return { ...state, loading: true };
    case QualificationInstitutionLinkActionTypes.QUALIFICATIONINSTITUTIONLINK_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        qualificationInstitutionLinkState: qualificationInstitutionLinkStateAdapter.setAll(action.payload, state.qualificationInstitutionLinkState),
      };
    }
    case QualificationInstitutionLinkActionTypes.QUALIFICATIONINSTITUTIONLINK_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Upload
    case QualificationInstitutionLinkActionTypes.QUALIFICATIONINSTITUTIONLINK_UPLOAD_SUCCESS: {
      return {
        ...state,
      };
    }
    case QualificationInstitutionLinkActionTypes.QUALIFICATIONINSTITUTIONLINK_UPLOAD_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Clear
    case QualificationInstitutionLinkActionTypes.QUALIFICATIONINSTITUTIONLINK_CLEAR:
      return { ...initialQualificationInstitutionLinkRelatedState };
    default:
      return state;
  }
}

export const getProfileRelatedState = createFeatureSelector<fromProfileRelatedReducer.ProfileRelatedState>('profileRelatedReducer');

export const getQualificationInstitutionLinkRelatedState = createSelector(
  getProfileRelatedState,
  (state: fromProfileRelatedReducer.ProfileRelatedState) => state.qualificationInstitutionLinkState,
);

// Current QualificationInstitutionLink

export const getQualificationInstitutionLinkState = createSelector(
  getQualificationInstitutionLinkRelatedState,
  (state) => state.qualificationInstitutionLinkState,
);
export const { selectAll: getQualificationInstitutionLink } = qualificationInstitutionLinkStateAdapter.getSelectors(getQualificationInstitutionLinkState);

// Selected

export const getSelectedQualificationInstitutionLinkIds = createSelector(getQualificationInstitutionLinkRelatedState, (state) => state.selectedIds);

export const getSelectedProfiles = createSelector(
  getQualificationInstitutionLink,
  getSelectedQualificationInstitutionLinkIds,
  (QualificationInstitutionLink, selectedIds: string[]) =>
    QualificationInstitutionLink.filter((m) => selectedIds.indexOf(keyHandler(m, qualificationInstitutionLinkKeys)) !== -1),
);

// Loading

export const isQualificationInstitutionLinkLoading = createSelector(getQualificationInstitutionLinkRelatedState, (state) => state.loading);

// Error

export const getProfileError = createSelector(getQualificationInstitutionLinkRelatedState, (state) => state.error);
