import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControlDirective, FormGroup } from '@angular/forms';
import { DvTimeOffRequest, SharedApiFileService, SharedApiTaskService, SharedApiTimeOffService } from '@verde/api';
import { DeviceTypeService, UserService, ViewSDKClient } from '@verde/core';
import { VerdeApprovalService } from '@verde/shared';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ModalService } from '../modal.service';

@Component({
  selector: 'verde-timeoff-approval-modal',
  templateUrl: './timeoff-approval-modal.component.html',
  styleUrls: ['./timeoff-approval-modal.component.scss'],
})
export class TimeoffApprovalModalComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject<boolean>();

  @Input() requestID: string = '';
  @Input() approverType: string = '';
  @Input() employeeFullName: string = '';
  @Input() showModal: boolean = true;

  @Output() hideModalEvent = new EventEmitter<boolean>();
  @Output() onSuccess = new EventEmitter<boolean>();

  @ViewChild('timeoffApprovalForm') timeoffApprovalForm: FormGroup | undefined;

  //selected
  selectedTimeOffRequest: DvTimeOffRequest = {};

  //body
  patchTimeOffRequestBody: DvTimeOffRequest = {};

  supportingNoteBase64: string = '';

  confirmationMessage: string = '';
  approveButtonText: string = 'Approve';
  confirmationAction: string = 'approve';

  agreedYes: boolean = false;
  agreedNo: boolean = false;
  portionOfDayRequest: boolean = true;

  approveStartDate: string = '';
  approveEndDate: string = '';

  disableAnimation: boolean = false;

  errorMessage: string = '';
  showErrorRetryButton: boolean = false;
  showCalculationComment: boolean = false;
  allowApproval: boolean = true;
  enableSubmit: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    public userService: UserService,
    private sharedApiTimeOffService: SharedApiTimeOffService,
    private sharedApiTaskService: SharedApiTaskService,
    private sharedApiFileService: SharedApiFileService,
    private viewSDKClient: ViewSDKClient,
    public deviceTypeService: DeviceTypeService,
    private verdeApprovalService: VerdeApprovalService,
  ) {}

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
    if (this.showModal) {
      try {
        setTimeout(() => {
          const modal = document.getElementById('showApprovalModal');
          if (modal) {
            modal.click();
          }
        }, 5);
      } catch (error) {}
    } else {
      this.getVerdeTaskApprovalByID();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  hideModal(value: boolean) {
    if (!value) {
      this.hideModalEvent.emit(value);
    }
  }

  public getVerdeTaskApprovalByID(myForm: FormGroup | undefined = this.timeoffApprovalForm) {
    if (this.showModal) {
      this.spinner.show('timeoffapproval');
      this.modalService.open('newTimeoffApprovalModal');
    } else {
      this.spinner.show('timeoffapprovalNonModal');
    }

    this.sharedApiTaskService
      .getVerdeTaskApprovalById({ tableName: 'bthr_timeoffrequests', columnName: 'bthr_timeoffrequestid', recordID: this.requestID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.selectedTimeOffRequest = ret;
          console.log('ret', ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (
            moment(this.selectedTimeOffRequest.bthr_startdate).format('YYYY-MM-DD') === moment(this.selectedTimeOffRequest.bthr_enddate).format('YYYY-MM-DD')
          ) {
            this.portionOfDayRequest = true;
          } else {
            this.portionOfDayRequest = false;
          }

          this.allowApproval = true;

          this.agreedYes = false;
          this.agreedNo = false;

          this.approveStartDate = moment(this.selectedTimeOffRequest.bthr_startdate).format('YYYY/MM/DD');
          this.approveEndDate = moment(this.selectedTimeOffRequest.bthr_enddate).format('YYYY/MM/DD');

          //supporting note
          if (this.selectedTimeOffRequest.bthr_supportingnote_name !== null) {
            this.getSupportingNote();
          }

          this.spinner.hide('timeoffapproval');
          this.spinner.hide('timeoffapprovalNonModal');
        },
      );
  }

  onTabSelect(index: number) {
    if (index === 1) {
      setTimeout(() => {
        this.viewSDKClient.ready().then(() => {
          /* Invoke file preview */
          /* By default the embed mode will be Full Window */
          this.viewSDKClient.previewFile('pdf-div', {}, this.supportingNoteBase64, this.selectedTimeOffRequest.bthr_supportingnote_name!);
        });
      }, 1000);
    }
  }

  getSupportingNote() {
    this.sharedApiFileService.getSupportingNote({ recordid: this.requestID }).subscribe(
      (ret) => {
        this.supportingNoteBase64 = ret;
      },
      (error) => {
        console.error(error);
      },
    );
  }

  openConfirmModal() {
    this.modalService.open('confirmTimeOffApprovalModal');
  }

  patchTimeOffRequestApproval(myForm: FormGroup) {
    if (this.approverType === 'Manager') {
      // MANAGER APPROVAL
      let cancellationRequest: boolean = false;
      let cancellationRejected: boolean = false;

      if (this.selectedTimeOffRequest._bt_linkedtransaction_value === null) {
        // Normal Request
        if (this.agreedYes) {
          this.patchTimeOffRequestBody.bt_managerapprovalstatus = 365580003;
        } else {
          this.patchTimeOffRequestBody.bt_managerapprovalstatus = 365580002;
          this.patchTimeOffRequestBody.bthr_requestsstatus = 365580002;
          this.patchTimeOffRequestBody.bthr_requeststatuscomment = myForm.value.approveRequestComment;
        }
      } else {
        // Cancellation Request
        cancellationRequest = true;

        if (this.agreedYes) {
          this.patchTimeOffRequestBody.bt_managerapprovalstatus = 365580003;
        } else {
          this.patchTimeOffRequestBody.bt_managerapprovalstatus = 365580002;
          this.patchTimeOffRequestBody.bthr_requestsstatus = 365580002;
          this.patchTimeOffRequestBody.bthr_requeststatuscomment = myForm.value.approveRequestComment;
          cancellationRejected = true;
        }
      }

      this.patchTimeOffRequestBody.bt_managerapprovaldate = moment().format('YYYY-MM-DD');

      this.patchTimeOffRequestBody.bthr_timeoffrequestid = this.selectedTimeOffRequest.bthr_timeoffrequestid;

      this.sharedApiTimeOffService
        .patchTimeOffRequest({ body: this.patchTimeOffRequestBody })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            this.onSuccess.emit(true);
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.patchTimeOffRequestBody = {};

            if (cancellationRequest) {
              let quickUpdateBody: DvTimeOffRequest = {
                bthr_timeoffrequestid: this.selectedTimeOffRequest._bt_linkedtransaction_value,
                bt_cancelled: cancellationRejected ? false : null,
              };

              this.sharedApiTimeOffService
                .patchTimeOffRequest({ body: quickUpdateBody })
                .pipe(take(1))
                .subscribe(
                  (ret) => {},
                  (error) => {
                    console.error(error);
                  },
                );
            }

            this.confirmationMessage = 'You have successfully processed this transaction!';
            this.verdeApprovalService.setShowSidebar(false);
            this.spinner.hide('confirmtimeoffapproval');
          },
        );
    } else if (this.approverType === 'HR') {
      // HR APPROVAL
      let cancellationRequest: boolean = false;
      let cancellationRejected: boolean = false;

      if (this.selectedTimeOffRequest._bt_linkedtransaction_value === null) {
        // Normal Request
        if (this.agreedYes) {
          this.allowApproval = false;

          this.patchTimeOffRequestBody.bthr_requestsstatus = 365580003;
          this.patchTimeOffRequestBody.bt_calculationcomment = '';
          this.patchTimeOffRequestBody.bthr_calculationstatus = 365580000;
        } else {
          this.patchTimeOffRequestBody.bthr_requestsstatus = 365580002;
          this.patchTimeOffRequestBody.bthr_requeststatuscomment = myForm.value.approveRequestComment;
        }
      } else {
        // Cancellation Request
        cancellationRequest = true;

        this.spinner.show('confirmtimeoffapproval');
        this.modalService.open('confirmTimeOffApprovalModal');

        if (this.agreedYes) {
          this.patchTimeOffRequestBody.bthr_requestsstatus = 365580003;
        } else {
          this.patchTimeOffRequestBody.bthr_requestsstatus = 365580002;
          this.patchTimeOffRequestBody.bthr_requeststatuscomment = myForm.value.approveRequestComment;
          cancellationRejected = true;
        }
      }

      this.patchTimeOffRequestBody.bt_hrapprovaldate = moment().format('YYYY-MM-DD');
      this.patchTimeOffRequestBody.odataPostApprover = '/bthr_employees(' + this.userService.user?.employeeId + ')';
      this.patchTimeOffRequestBody.odataPostApproverLegalEntity = '/bthr_legalentitieses(' + this.userService.user?.legalEntityId + ')';

      this.patchTimeOffRequestBody.bthr_timeoffrequestid = this.selectedTimeOffRequest.bthr_timeoffrequestid;

      this.sharedApiTaskService
        .getVerdeTaskApprovalById({ tableName: 'bthr_timeoffrequests', columnName: 'bthr_timeoffrequestid', recordID: this.requestID })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            this.selectedTimeOffRequest = ret;
          },
          (error) => {
            console.error(error);
          },
          () => {
            if (this.selectedTimeOffRequest.odataRequestStatus === 'Submitted') {
              this.sharedApiTimeOffService
                .patchTimeOffRequest({ body: this.patchTimeOffRequestBody })
                .pipe(take(1))
                .subscribe(
                  (ret) => {
                    this.onSuccess.emit(true);
                  },
                  (error) => {
                    console.error(error);
                  },
                  () => {
                    this.patchTimeOffRequestBody = {};

                    if (cancellationRequest) {
                      let quickUpdateBody: DvTimeOffRequest = {
                        bthr_timeoffrequestid: this.selectedTimeOffRequest._bt_linkedtransaction_value,
                        bt_cancelled: cancellationRejected ? false : null,
                      };

                      this.sharedApiTimeOffService
                        .patchTimeOffRequest({ body: quickUpdateBody })
                        .pipe(take(1))
                        .subscribe(
                          (ret) => {},
                          (error) => {
                            console.error(error);
                          },
                          () => {},
                        );

                      if (this.agreedYes) {
                        let quickUpdateBody2: DvTimeOffRequest = {
                          bthr_timeoffrequestid: this.selectedTimeOffRequest.bthr_timeoffrequestid,
                          bthr_requestsstatus: 365580004,
                        };

                        this.sharedApiTimeOffService
                          .patchTimeOffRequest({ body: quickUpdateBody2 })
                          .pipe(take(1))
                          .subscribe(
                            (ret) => {},
                            (error) => {
                              console.error(error);
                            },
                            () => {},
                          );
                      }
                    }
                    this.confirmationMessage = 'You have successfully processed this transaction!';
                    this.verdeApprovalService.setShowSidebar(false);
                    this.spinner.hide('confirmtimeoffapproval');
                  },
                );
            } else {
              this.spinner.hide('timeoffapproval');
              this.modalService.close('confirmTimeOffApprovalModal');
              this.errorMessage = 'This time off request has already been processed.';
              this.modalService.open('timeOffApprovalErrorModal');
            }
          },
        );
    }

    this.hideModalEvent.emit(true);
  }

  // wait for data
  async waitForCompletedData() {
    let i: number = 0;

    this.spinner.show('timeoffapproval');

    this.showErrorRetryButton = false;

    do {
      await this.sharedApiTaskService
        .getVerdeTaskApprovalById({ tableName: 'bthr_timeoffrequests', columnName: 'bthr_timeoffrequestid', recordID: this.requestID })
        .toPromise()
        .then((ret) => {
          i++;

          this.selectedTimeOffRequest = ret!;
        });
    } while (this.selectedTimeOffRequest?.odataCalculationStatus === 'Calculating' && i < 40);

    if (this.selectedTimeOffRequest?.odataCalculationStatus === 'Calculation Error') {
      this.showCalculationComment = true;
      this.showErrorRetryButton = false;
    } else if (this.selectedTimeOffRequest?.odataCalculationStatus === 'Completed') {
      this.allowApproval = false;
      this.confirmationMessage = 'You have successfully approved this request!';
      this.modalService.open('confirmTimeOffApprovalModal');
    }

    if (i >= 40) {
      this.showErrorRetryButton = true;
      this.allowApproval = false;
      this.errorMessage = 'Calculation Error. Please try again.';
    }

    this.spinner.hide('timeoffapproval');
  }

  // agreed
  agreedYesChange(e: any, element: FormControlDirective) {
    this.agreedYes = e;
    this.agreedNo = !e;
    element.reset();

    this.enableSubmit = true;

    if (this.agreedYes) {
      this.confirmationAction = 'approve';
      this.approveButtonText = 'Approve';
    } else {
      this.confirmationAction = 'reject';
      this.approveButtonText = 'Reject';
    }
  }

  agreedNoChange(e: any, element: FormControlDirective) {
    this.agreedNo = e;
    this.agreedYes = !e;
    element.reset();

    this.enableSubmit = true;

    if (this.agreedNo) {
      this.confirmationAction = 'reject';
      this.approveButtonText = 'Reject';
    } else {
      this.confirmationAction = 'approve';
      this.approveButtonText = 'Approve';
    }
  }
}
