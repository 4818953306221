/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Injectable, OnDestroy } from '@angular/core';
import {
  Counter,
  FleetAccess,
  FleetMovement,
  LocationsNew,
  SharedApiCountersService,
  SharedApiCountriesService,
  SharedApiFleetMovementService,
  SharedApiVehiclesService,
  StringArrayParamaters,
  UserAppConfigDto,
  VehiclesFiltered,
  VerdeSupplier,
  VerdeSupplierReason,
} from '@verde/api';
import { UserService } from '@verde/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FleetAccessService implements OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  private _allFleetAccess: Array<FleetAccess> = new Array<FleetAccess>();
  private _newAllFleetAccess = new BehaviorSubject<Array<FleetAccess>>(new Array<FleetAccess>());
  private _allFleetItems: Array<VehiclesFiltered> = new Array<VehiclesFiltered>();
  private _newAllFleetItems = new BehaviorSubject<Array<VehiclesFiltered>>(new Array<VehiclesFiltered>());
  private _employeeFleetMovements: Array<FleetMovement> = new Array<FleetMovement>();
  private _employeeFleetMovements$ = new BehaviorSubject<Array<FleetMovement>>(new Array<FleetMovement>());
  private _locationFleetMovements: Array<FleetMovement> = new Array<FleetMovement>();
  private _locationFleetMovements$ = new BehaviorSubject<Array<FleetMovement>>(new Array<FleetMovement>());
  private _fleetItemsAtLocation: Array<VehiclesFiltered> = new Array<VehiclesFiltered>();
  private _newFleetItemsAtLocation = new BehaviorSubject<Array<VehiclesFiltered>>(new Array<VehiclesFiltered>());
  private _selectedFleetAccess: FleetAccess = null;
  private _allLocations = new BehaviorSubject<Array<LocationsNew>>(new Array<LocationsNew>());
  private _allFleetSuppliers: Array<VerdeSupplier> = new Array<VerdeSupplier>();
  private _fleetSupplierReasons: Array<VerdeSupplierReason> = new Array<VerdeSupplierReason>();
  userHasPermission$ = new BehaviorSubject<boolean>(false);
  private _yourFleetItemsCount = new BehaviorSubject<Counter | null>(null);
  private _selectedRecordID$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  currentFleetAccess: BehaviorSubject<FleetAccess> = new BehaviorSubject<FleetAccess>(null);
  currentAllLocations = this._allLocations.asObservable();
  currentAllFleetItems = this._newAllFleetItems.asObservable();
  currentEmployeeFleetMovements = this._employeeFleetMovements$.asObservable();
  currentLocationFleetMovements = this._locationFleetMovements$.asObservable();
  currentFleetItemsAtLocation = this._newFleetItemsAtLocation.asObservable();
  currentAllFleetAccess = this._newAllFleetAccess.asObservable();
  currentYourFleetCount = this._yourFleetItemsCount.asObservable();
  loaded = false;
  config: UserAppConfigDto = {};

  constructor(
    private spinner: NgxSpinnerService,
    private userService: UserService,

    private sharedApiVehiclesService: SharedApiVehiclesService,
    private sharedApiFleetMovementService: SharedApiFleetMovementService,
    private sharedApiCountriesService: SharedApiCountriesService,
    private sharedApiCountersService: SharedApiCountersService,
  ) {
    combineLatest([this.userService.proxyUser$, this.userService.config$, this.userService.permissionsLoaded$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        if (data[0] && data[1] && data[2] && !this.loaded) {
          this.loaded = true;
          this.config = data[1];
          this.checkLoadingComplete();
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  // Selected Record ID
  getSelectedRecordID() {
    return this._selectedRecordID$.asObservable();
  }

  setSelectedRecordID(id: string) {
    this._selectedRecordID$.next(id);
  }

  checkLoadingComplete() {
    if (this.config?.legalEntityConfig?.vehicles) {
      //this.getLocationsNew();
      this.checkUserPermissions();
    }
  }

  get userHasPermission(): boolean {
    return this.userHasPermission$.getValue();
  }

  checkUserPermissions() {
    this.userHasPermission$.next(false);

    const userHasPermission = this.userService.userHasPermission('FLEET-FLEET-MANAGER');
    this.userHasPermission$.next(userHasPermission);

    if (!userHasPermission) {
      this.getFleetSuppliers(this.userService.user?.legalEntityId!);
      this.getLocationsNew(this.userService.user?.legalEntityId);

      //count
      this.getYourFleetItemsEmployee();

      //movements
      this.getEmployeeFleetMovements(this.userService.user?.employeeId!);
    } else {
      this.getFleetAccess();
    }
  }

  //Fleet Access
  set selectedFleetAccess(val: FleetAccess) {
    this._selectedFleetAccess = val;

    this.spinner.hide('mainfleet');

    this.getAllFleetItems(val._bt_location_value!, val._bt_locationlegalentity_value!);
    this.getFleetItemsAtLocation(val._bt_location_value!, val._bt_locationlegalentity_value!);
    this.getFleetSuppliers(val._bt_locationlegalentity_value!);
    this.getLocationFleetMovements(val._bt_grouplocation_value!);
  }

  get selectedFleetAccess(): FleetAccess {
    return this._selectedFleetAccess;
  }

  set allFleetAccess(val: Array<FleetAccess>) {
    this._allFleetAccess = val;
    this.changeAllFleetAccess(val);
    this.currentFleetAccess.next(val[0]);
  }

  get allFleetAccess(): Array<FleetAccess> {
    return this._allFleetAccess;
  }

  applyNewLocation(e: FleetAccess) {
    this.spinner.show('mainfleet');

    this.allFleetAccess.forEach((t) => {
      if (t.bt_fleetaccessid === e.bt_fleetaccessid) {
        this.selectedFleetAccess = t;
        this.currentFleetAccess.next(t);
      }
    });

    this.getLocationsNew(this.selectedFleetAccess._bt_locationlegalentity_value);
  }

  //GET Fleet Access
  getFleetAccess() {
    this.spinner.show('mainfleet');
    return this.sharedApiFleetMovementService
      .getFleetAccess({ userID: this.userService.user?.employeeId! })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allFleetAccess = ret.sort((a, b) => {
            const legalEntityComparison = a.odataLocationLegalEntity.localeCompare(b.odataLocationLegalEntity);
            if (legalEntityComparison !== 0) {
              return legalEntityComparison;
            }

            return a.odataLocation.localeCompare(b.odataLocation);
          });

          if (this.allFleetAccess.length > 0 && this.selectedFleetAccess === null) {
            const defaultLegalEntityAccess = this.allFleetAccess.find((t) => t._bt_locationlegalentity_value == this.userService.user.legalEntityId);

            if (defaultLegalEntityAccess) {
              this.selectedFleetAccess = defaultLegalEntityAccess;
            } else {
              this.selectedFleetAccess = this.allFleetAccess[0];
            }
          }

          if (this.allFleetAccess.length > 0) {
            const allLocationIDs: string[] = [];
            const allLegalEntityIDs: string[] = [];

            this.allFleetAccess.forEach((t) => {
              allLocationIDs.push(t._bt_location_value);
              allLegalEntityIDs.push(t._bt_locationlegalentity_value);
            });

            const uniqueLocationIDs = Array.from(new Set(allLocationIDs));
            const uniqueLegalEntityIDs = Array.from(new Set(allLegalEntityIDs));

            const body: StringArrayParamaters = { entitieslegalEntityIDs: uniqueLegalEntityIDs, locationIDs: uniqueLocationIDs };

            this.getYourFleetItemsManager(body);
          }
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('mainfleet');

          this.getLocationsNew(this.selectedFleetAccess._bt_locationlegalentity_value);
        },
      );
  }

  changeAllFleetAccess(items: FleetAccess[]) {
    this._newAllFleetAccess.next(items);
  }

  //All Fleet
  set allFleetItems(val: Array<VehiclesFiltered>) {
    this._allFleetItems = val;

    this.changeAllFleetItems(val);
  }

  get allFleetItems(): Array<VehiclesFiltered> {
    return this._allFleetItems;
  }

  //For Fleet Managers
  getAllFleetItems(locationID: string, legalEntityID: string) {
    this.spinner.show('fleetmanagerlocationitems');
    this.sharedApiVehiclesService
      .getAllFleetItemsByLocation({ locationID, legalEntityID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allFleetItems = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('fleetmanagerlocationitems');
          this.spinner.hide('taballfleet');
          this.spinner.hide('mainfleet');
        },
      );
  }

  changeAllFleetItems(items: VehiclesFiltered[]) {
    this._newAllFleetItems.next(items);
  }

  //Fleet at Location
  set fleetItemsAtLocation(val: Array<VehiclesFiltered>) {
    this._fleetItemsAtLocation = val;

    this.changeFleetItemsAtLocation(val);
  }

  get fleetItemsAtLocation(): Array<VehiclesFiltered> {
    return this._fleetItemsAtLocation;
  }

  getFleetItemsAtLocation(locationID: string, legalEntityID: string) {
    this.sharedApiVehiclesService
      .getFleetItemsAtLocation({ locationID, legalEntityID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.fleetItemsAtLocation = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          //
        },
      );
  }

  changeFleetItemsAtLocation(items: VehiclesFiltered[]) {
    this._newFleetItemsAtLocation.next(items);
  }

  //Employee Fleet Movements
  set employeeFleetMovements(val: Array<FleetMovement>) {
    this._employeeFleetMovements = val;

    this.changeEmployeeFleetMovements(val);
  }

  get employeeFleetMovements(): Array<FleetMovement> {
    return this._employeeFleetMovements;
  }

  getEmployeeFleetMovements(userID: string) {
    this.sharedApiFleetMovementService
      .getFleetMovements({ userID }) //this one
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.employeeFleetMovements = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('outstandingactivities');
          this.spinner.hide('fleetrequests');
          this.spinner.hide('fleetmovementactions');
        },
      );
  }

  changeEmployeeFleetMovements(items: FleetMovement[]) {
    this._employeeFleetMovements$.next(items);
  }

  //Fleet Manager Fleet Movements by location
  set locationFleetMovements(val: Array<FleetMovement>) {
    this._locationFleetMovements = val;

    this.changeLocationFleetMovements(val);
  }

  get locationFleetMovements(): Array<FleetMovement> {
    return this._locationFleetMovements;
  }

  getLocationFleetMovements(locationID: string) {
    this.sharedApiFleetMovementService
      .getFleetMovementsByLocation({ locationID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.locationFleetMovements = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('outstandingactivities');
          this.spinner.hide('fleetrequests');
          this.spinner.hide('fleetnewitems');
          this.spinner.hide('fleetmovementactions');
        },
      );
  }

  changeLocationFleetMovements(items: FleetMovement[]) {
    this._locationFleetMovements$.next(items);
  }

  //Fleet Suppliers
  set allFleetSuppliers(val: Array<VerdeSupplier>) {
    this._allFleetSuppliers = val;
  }

  get allFleetSuppliers(): Array<VerdeSupplier> {
    return this._allFleetSuppliers;
  }

  //GET Fleet Suppliers
  getFleetSuppliers(legalEntityID: string) {
    this.sharedApiFleetMovementService
      .getAllVerdeSuppliers({ legalEntityID }) // this one
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allFleetSuppliers = ret.filter((t) => {
            if (t.odataSupplierType?.toLowerCase().includes('fleet')) {
              return t;
            }
          });
        },
        (error) => {
          console.error(error);
        },
      );
  }

  //Fleet Supplier Reasons
  set fleetSupplierReasons(val: Array<VerdeSupplierReason>) {
    this._fleetSupplierReasons = val;
  }

  get fleetSupplierReasons(): Array<VerdeSupplierReason> {
    return this._fleetSupplierReasons;
  }

  //GET Fleet Suppliers
  getFleetSupplierReasons(legalEntityID: string) {
    this.spinner.show('fleetsupplierreasons');
    this.sharedApiFleetMovementService
      .getVerdeSupplierReasons({ legalEntityID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.fleetSupplierReasons = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('fleetsupplierreasons');
        },
      );
  }

  //GET Locations New
  // Test
  getLocationsNew(legalEntityID: string) {
    this.sharedApiCountriesService
      .getLocationsNew({ legalEntityID: legalEntityID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          const locationsFiltered = ret.filter((t) => {
            if (t.bthr_locationtype === 692360002) {
              return t;
            }
          });

          this.changeAllLocations(locationsFiltered);
        },
        (error) => {
          console.error(error);
        },
      );
  }

  changeAllLocations(locations: LocationsNew[]) {
    this._allLocations.next(locations);
  }

  //Your Fleet Count
  getYourFleetItemsEmployee() {
    this.spinner.show('yourfleetcount');
    this.sharedApiCountersService
      .getYourFleetItemsEmployee({
        userID: this.userService.user?.employeeId!,
        legalEntityID: this.userService.user?.legalEntityId!,
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.changeYourFleetCount(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('yourfleetcount');
        },
      );
  }

  private allFleetItemsManagerCount: Counter = { countNumber: 0 };

  getYourFleetItemsManager(body: StringArrayParamaters) {
    this.spinner.show('yourfleetcount');

    this.sharedApiCountersService
      .getYourFleetItemsManager({ body: body })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allFleetItemsManagerCount.countNumber = this.allFleetItemsManagerCount.countNumber! + ret.countNumber!;

          this.changeYourFleetCount(this.allFleetItemsManagerCount);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('yourfleetcount');
        },
      );
  }

  changeYourFleetCount(count: Counter) {
    this._yourFleetItemsCount.next(count);
  }

  //Patch fleet item movement at location
  patchFleetItemMovementStatusAtLocation(recordID: string, locationName: string) {
    const patchFleetItemBody: VehiclesFiltered = {
      bt_statuscomment: locationName,
      bthr_vehiclesid: recordID,
      bt_fleetmovementstatus: 692360000,
    };

    this.sharedApiVehiclesService
      .patchVehicles({ body: patchFleetItemBody })
      .pipe(take(1))
      .subscribe(
        (payload) => {
          console.log(payload);
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.userHasPermission$.getValue()) {
            this.getAllFleetItems(this.selectedFleetAccess._bt_location_value!, this.selectedFleetAccess._bt_locationlegalentity_value!);
          }
        },
      );
  }

  //Patch fleet item movement allocated
  patchFleetItemMovementStatusAllocated(recordID: string, allocatedTo: string) {
    const patchFleetItemBody: VehiclesFiltered = {
      bt_statuscomment: allocatedTo,
      bthr_vehiclesid: recordID,
      bt_fleetmovementstatus: 692360001,
    };

    this.sharedApiVehiclesService
      .patchVehicles({ body: patchFleetItemBody })
      .pipe(take(1))
      .subscribe(
        (payload) => {
          console.log(payload);
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.userHasPermission$.getValue()) {
            this.getAllFleetItems(this.selectedFleetAccess._bt_location_value!, this.selectedFleetAccess._bt_locationlegalentity_value!);
          }
        },
      );
  }

  //Patch fleet item movement Assigned
  patchFleetItemMovementStatusAssigned(recordID: string, assignedTo: string) {
    const patchFleetItemBody: VehiclesFiltered = {
      bt_statuscomment: assignedTo,
      bthr_vehiclesid: recordID,
      bt_fleetmovementstatus: 692360002,
    };

    this.sharedApiVehiclesService
      .patchVehicles({ body: patchFleetItemBody })
      .pipe(take(1))
      .subscribe(
        (payload) => {
          console.log(payload);
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.userHasPermission$.getValue()) {
            this.getAllFleetItems(this.selectedFleetAccess._bt_location_value!, this.selectedFleetAccess._bt_locationlegalentity_value!);
          }
        },
      );
  }

  //Patch fleet item movement Assigned
  patchFleetItemMovementStatusAtSupplier(recordID: string, supplier: string) {
    const patchFleetItemBody: VehiclesFiltered = {
      bt_statuscomment: supplier,
      bthr_vehiclesid: recordID,
      bt_fleetmovementstatus: 692360003,
    };

    this.sharedApiVehiclesService
      .patchVehicles({ body: patchFleetItemBody })
      .pipe(take(1))
      .subscribe(
        (payload) => {
          console.log(payload);
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.userHasPermission$.getValue()) {
            this.getAllFleetItems(this.selectedFleetAccess._bt_location_value!, this.selectedFleetAccess._bt_locationlegalentity_value!);
          }
        },
      );
  }
}
