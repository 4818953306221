import { Action } from '@ngrx/store';
import { UserUserDto } from '@verde/api';
import { VerdeTaskCustom } from '@verde/shared';

export enum TaskPersonalActionTypes {
  // Get All Task Personal
  TASK_PERSONAL_GET_ALL = '[Task] Personal Get All Start',
  TASK_PERSONAL_GET_ALL_SUCCESS = '[Task] Personal Get All Start Success',
  TASK_PERSONAL_GET_ALL_ERROR = '[Task] Personal Get All Start Error',
  TASK_PERSONAL_GET_ALL_STOP = '[Task] Personal Get All Stop',
  TASK_PERSONAL_GET_ALL_STOP_SUCCESS = '[Task] Personal Get All Stop Success',
  // Clear All Task Personal
  TASK_PERSONAL_CLEAR = '[Task] Personal Clear',
}

// Get All Task Personal
export class TaskPersonalGetAll implements Action {
  readonly type = TaskPersonalActionTypes.TASK_PERSONAL_GET_ALL;
  constructor(public proxyUser: UserUserDto) {}
}

export class TaskPersonalGetAllSuccess implements Action {
  readonly type = TaskPersonalActionTypes.TASK_PERSONAL_GET_ALL_SUCCESS;
  constructor(public payload: VerdeTaskCustom[]) {}
}

export class TaskPersonalGetAllError implements Action {
  readonly type = TaskPersonalActionTypes.TASK_PERSONAL_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

export class TaskPersonalGetAllStop implements Action {
  readonly type = TaskPersonalActionTypes.TASK_PERSONAL_GET_ALL_STOP;
  constructor(public proxyUser: UserUserDto) {}
}

export class TaskPersonalGetAllStopSuccess implements Action {
  readonly type = TaskPersonalActionTypes.TASK_PERSONAL_GET_ALL_STOP_SUCCESS;
  constructor(public proxyUser: UserUserDto) {}
}

// Clear All Task Personal
export class TaskPersonalClear implements Action {
  readonly type = TaskPersonalActionTypes.TASK_PERSONAL_CLEAR;
  constructor(public payload: any) {}
}

export type TaskPersonalActions =
  // Get All Task Personal
  | TaskPersonalGetAll
  | TaskPersonalGetAllSuccess
  | TaskPersonalGetAllError
  | TaskPersonalGetAllStop
  | TaskPersonalGetAllStopSuccess
  // Clear All Task Personal
  | TaskPersonalClear;
