/* tslint:disable */
/* eslint-disable */
export enum DocumentBtSalaryAdviceType {
  Earnings = 'Earnings',
  Deductions = 'Deductions',
  CompanyContributions = 'CompanyContributions',
  LeaveBalance = 'LeaveBalance',
  EarningTotal = 'EarningTotal',
  DeductionsTotal = 'DeductionsTotal',
  TotalCc = 'TotalCC',
  NetPay = 'NetPay',
  Package = 'Package',
  YeartoDate = 'YeartoDate',
  CurrentPerks = 'CurrentPerks',
  TotalCompanyContributions = 'TotalCompanyContributions',
  CosttoCompany = 'CosttoCompany',
}
