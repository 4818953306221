/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommunicationApiConfiguration, CommunicationApiConfigurationParams } from './communication-api-configuration';

import { CommunicationApiCommunicationService } from './services/communication-api-communication.service';
import { CommunicationApiVersionService } from './services/communication-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [CommunicationApiCommunicationService, CommunicationApiVersionService, CommunicationApiConfiguration],
})
export class CommunicationApiModule {
  static forRoot(params: CommunicationApiConfigurationParams): ModuleWithProviders<CommunicationApiModule> {
    return {
      ngModule: CommunicationApiModule,
      providers: [
        {
          provide: CommunicationApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CommunicationApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('CommunicationApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
