/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmployeeFunds } from '../models/employee-funds';
import { FundOptionChanges } from '../models/fund-option-changes';
import { FundOptions } from '../models/fund-options';
import { PatchBodyFundOptionChanges } from '../models/patch-body-fund-option-changes';

@Injectable({
  providedIn: 'root',
})
export class SharedApiFundsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getEmployeeFunds
   */
  static readonly GetEmployeeFundsPath = '/api/Funds/getEmployeeFunds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeFunds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeFunds$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    userID?: string;
    legalEntityID?: string;
  }): Observable<StrictHttpResponse<Array<EmployeeFunds>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFundsService.GetEmployeeFundsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.query('userID', params.userID, {});
      rb.query('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EmployeeFunds>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeFunds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeFunds(params?: { subscriptionKey?: string; APIURL?: string; userID?: string; legalEntityID?: string }): Observable<Array<EmployeeFunds>> {
    return this.getEmployeeFunds$Response(params).pipe(map((r: StrictHttpResponse<Array<EmployeeFunds>>) => r.body as Array<EmployeeFunds>));
  }

  /**
   * Path part for operation getEmployeeFundsOptionChanges
   */
  static readonly GetEmployeeFundsOptionChangesPath = '/api/Funds/getEmployeeFundsOptionChanges';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeFundsOptionChanges()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeFundsOptionChanges$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    fundID?: string;
  }): Observable<StrictHttpResponse<Array<FundOptionChanges>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFundsService.GetEmployeeFundsOptionChangesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.query('fundID', params.fundID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FundOptionChanges>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeFundsOptionChanges$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeFundsOptionChanges(params?: { subscriptionKey?: string; APIURL?: string; fundID?: string }): Observable<Array<FundOptionChanges>> {
    return this.getEmployeeFundsOptionChanges$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FundOptionChanges>>) => r.body as Array<FundOptionChanges>),
    );
  }

  /**
   * Path part for operation getFundOptions
   */
  static readonly GetFundOptionsPath = '/api/Funds/getFundOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFundOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFundOptions$Response(params?: { subscriptionKey?: string; APIURL?: string; fundID?: string }): Observable<StrictHttpResponse<Array<FundOptions>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFundsService.GetFundOptionsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.query('fundID', params.fundID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<FundOptions>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFundOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFundOptions(params?: { subscriptionKey?: string; APIURL?: string; fundID?: string }): Observable<Array<FundOptions>> {
    return this.getFundOptions$Response(params).pipe(map((r: StrictHttpResponse<Array<FundOptions>>) => r.body as Array<FundOptions>));
  }

  /**
   * Path part for operation patchEmployeeFunds
   */
  static readonly PatchEmployeeFundsPath = '/api/Funds/patchEmployeeFunds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEmployeeFunds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeFunds$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID?: string;
    body?: EmployeeFunds;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFundsService.PatchEmployeeFundsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.query('recordID', params.recordID, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEmployeeFunds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeFunds(params?: { subscriptionKey?: string; APIURL?: string; recordID?: string; body?: EmployeeFunds }): Observable<boolean> {
    return this.patchEmployeeFunds$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchEmployeeFundOptionChanges
   */
  static readonly PatchEmployeeFundOptionChangesPath = '/api/Funds/patchEmployeeFundOptionChanges';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEmployeeFundOptionChanges()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeFundOptionChanges$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID?: string;
    body?: PatchBodyFundOptionChanges;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFundsService.PatchEmployeeFundOptionChangesPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.query('recordID', params.recordID, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEmployeeFundOptionChanges$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeFundOptionChanges(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID?: string;
    body?: PatchBodyFundOptionChanges;
  }): Observable<boolean> {
    return this.patchEmployeeFundOptionChanges$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postEmployeeFunds
   */
  static readonly PostEmployeeFundsPath = '/api/Funds/postEmployeeFunds';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postEmployeeFunds()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEmployeeFunds$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: EmployeeFunds }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFundsService.PostEmployeeFundsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postEmployeeFunds$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEmployeeFunds(params?: { subscriptionKey?: string; APIURL?: string; body?: EmployeeFunds }): Observable<boolean> {
    return this.postEmployeeFunds$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postFundOptionChanges
   */
  static readonly PostFundOptionChangesPath = '/api/Funds/postFundOptionChanges';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFundOptionChanges()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFundOptionChanges$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: FundOptionChanges }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiFundsService.PostFundOptionChangesPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postFundOptionChanges$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFundOptionChanges(params?: { subscriptionKey?: string; APIURL?: string; body?: FundOptionChanges }): Observable<string> {
    return this.postFundOptionChanges$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }
}
