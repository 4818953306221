/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserOffBoarding } from '../models/user-off-boarding';

@Injectable({
  providedIn: 'root',
})
export class SharedApiUserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation postOffBoarding
   */
  static readonly PostOffBoardingPath = '/api/User/post/offboarding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOffBoarding()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postOffBoarding$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: UserOffBoarding }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiUserService.PostOffBoardingPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOffBoarding$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postOffBoarding(params?: { subscriptionKey?: string; APIURL?: string; body?: UserOffBoarding }): Observable<string> {
    return this.postOffBoarding$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation patchOffBoarding
   */
  static readonly PatchOffBoardingPath = '/api/User/patch/offboarding/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchOffBoarding()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchOffBoarding$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
      file?: Blob;
    };
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiUserService.PatchOffBoardingPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchOffBoarding$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchOffBoarding(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
    body?: {
      file?: Blob;
    };
  }): Observable<boolean> {
    return this.patchOffBoarding$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
