import { BtVehicleInspectionChecklistDetailVehic } from '@verde/api';

export interface ChecklistGroup {
  groupSortingId: number;
  groupName: string;
  subgroups: ChecklistSubgroup[];
}

export interface ChecklistSubgroup {
  showSubgroup: boolean;
  subgroupSortingId: number;
  subgroupName: string;
  measures: BtVehicleInspectionChecklistDetailVehic[];
}
