/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TrainingLinks } from '../models/training-links';
import { VerdeOnlineTraining } from '../models/verde-online-training';
import { VerdeOnlineTrainingConfig } from '../models/verde-online-training-config';

@Injectable({
  providedIn: 'root',
})
export class SharedApiTrainingLinksService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getTrainingLinks
   */
  static readonly GetTrainingLinksPath = '/api/TrainingLinks/traininglinks/{legalEntityID}/{roleID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTrainingLinks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingLinks$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    roleID: string;
  }): Observable<StrictHttpResponse<Array<TrainingLinks>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTrainingLinksService.GetTrainingLinksPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('roleID', params.roleID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TrainingLinks>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTrainingLinks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTrainingLinks(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string; roleID: string }): Observable<Array<TrainingLinks>> {
    return this.getTrainingLinks$Response(params).pipe(map((r: StrictHttpResponse<Array<TrainingLinks>>) => r.body as Array<TrainingLinks>));
  }

  /**
   * Path part for operation getOnlineTrainingEmployee
   */
  static readonly GetOnlineTrainingEmployeePath = '/api/TrainingLinks/verdeonlinetrainingemployee/{configID}/{employeeID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOnlineTrainingEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnlineTrainingEmployee$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    configID: string;
    employeeID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VerdeOnlineTraining>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTrainingLinksService.GetOnlineTrainingEmployeePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('configID', params.configID, {});
      rb.path('employeeID', params.employeeID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdeOnlineTraining>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOnlineTrainingEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnlineTrainingEmployee(params: {
    subscriptionKey?: string;
    APIURL?: string;
    configID: string;
    employeeID: string;
    legalEntityID: string;
  }): Observable<Array<VerdeOnlineTraining>> {
    return this.getOnlineTrainingEmployee$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeOnlineTraining>>) => r.body as Array<VerdeOnlineTraining>),
    );
  }

  /**
   * Path part for operation getOnlineTrainingConfig
   */
  static readonly GetOnlineTrainingConfigPath = '/api/TrainingLinks/verdeonlinetrainingconfig/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOnlineTrainingConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnlineTrainingConfig$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VerdeOnlineTrainingConfig>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTrainingLinksService.GetOnlineTrainingConfigPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdeOnlineTrainingConfig>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getOnlineTrainingConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOnlineTrainingConfig(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<VerdeOnlineTrainingConfig>> {
    return this.getOnlineTrainingConfig$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeOnlineTrainingConfig>>) => r.body as Array<VerdeOnlineTrainingConfig>),
    );
  }

  /**
   * Path part for operation postOnlineTrainingEmployee
   */
  static readonly PostOnlineTrainingEmployeePath = '/api/TrainingLinks/post/onlinetrainingemployee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOnlineTrainingEmployee()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postOnlineTrainingEmployee$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VerdeOnlineTraining;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTrainingLinksService.PostOnlineTrainingEmployeePath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOnlineTrainingEmployee$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postOnlineTrainingEmployee(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeOnlineTraining }): Observable<boolean> {
    return this.postOnlineTrainingEmployee$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
