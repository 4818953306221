import { DvPerformanceReview, DvPerformanceReviewPatch, EnhancedReviewDetail } from '@verde/api';

export interface PerformanceReview extends DvPerformanceReview, DvPerformanceReviewPatch {}

export interface PerformanceReviewSummaryExtended {
  id: number;
  expanded: boolean;
  group: string;
  groupId: string;
  groupSorting: number;
  subgroup: {
    name: string;
    subGroupId: string;
    subgroupSorting: number;
    questions: {
      question: string;
      measureSorting: number;
      basepoint: any;
      manager: EnhancedReviewDetail;
      employee: EnhancedReviewDetail;
      average: EnhancedReviewDetail;
    }[];
  }[];
}
