/* tslint:disable */
/* eslint-disable */
import { BtIntegrationSource } from './bt-integration-source';
export interface BtPayrollInputHeader {
  _bt_integrationsource_value?: null | string;
  _bt_legalentity_value?: null | string;
  bt_IntegrationSource?: BtIntegrationSource;
  bt_detailfile?: boolean;
  bt_importstatus?: number;
  bt_message?: null | any;
  bt_overwrite?: boolean;
  bt_payrollimportheaderid?: null | string;
  bt_payrollimportheaderno?: null | string;
  bt_period?: number;
  bt_taxyear?: null | string;
}
