import { QualificationsGetAccreditationAuthorityQuery } from '@verde/api';

import { Action } from '@ngrx/store';

export enum AccreditationAuthorityActionTypes {
  // Get All VaccinationTypes
  ACCREDITATIONAUTHORITY_GET_ALL = '[AccreditationAuthority] Get All',
  ACCREDITATIONAUTHORITY_GET_ALL_SUCCESS = '[AccreditationAuthority] Get All Success',
  ACCREDITATIONAUTHORITY_GET_ALL_ERROR = '[AccreditationAuthority] Get All Error',
  // Clear All VaccinationTypes
  ACCREDITATIONAUTHORITY_CLEAR = '[AccreditationAuthority] Clear',
}

//Get All AccreditationAuthority
export class AccreditationAuthorityGetAll implements Action {
  readonly type = AccreditationAuthorityActionTypes.ACCREDITATIONAUTHORITY_GET_ALL;
  constructor(public body: QualificationsGetAccreditationAuthorityQuery) {}
}

export class AccreditationAuthorityGetAllSuccess implements Action {
  readonly type = AccreditationAuthorityActionTypes.ACCREDITATIONAUTHORITY_GET_ALL_SUCCESS;
  constructor(public payload: any) {}
}

export class AccreditationAuthorityGetAllError implements Action {
  readonly type = AccreditationAuthorityActionTypes.ACCREDITATIONAUTHORITY_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// Clear All
export class AccreditationAuthorityClear implements Action {
  readonly type = AccreditationAuthorityActionTypes.ACCREDITATIONAUTHORITY_CLEAR;
  constructor(public payload: any) {}
}

export type AccreditationAuthorityActions =
  // Get All AccreditationAuthority
  | AccreditationAuthorityGetAll
  | AccreditationAuthorityGetAllSuccess
  | AccreditationAuthorityGetAllError
  // Clear All AccreditationAuthority
  | AccreditationAuthorityClear;
