import { Action } from '@ngrx/store';
import { TimeEntryApiModel } from '@verde/api';

export enum TimeTrackingActionTypes {
  // Get All TimeTracking
  TIMETRACKING_GET_ALL = '[TIMETRACKING] Get All',
  TIMETRACKING_GET_ALL_SUCCESS = '[TIMETRACKING] Get All Success',
  TIMETRACKING_GET_ALL_ERROR = '[TIMETRACKING] Get All Error',

  // Clear TimeTracking
  TIMETRACKING_CLEAR = '[TIMETRACKING] Clear',
}

// Get All TimeTracking
export class TimeTrackingGetAll implements Action {
  readonly type = TimeTrackingActionTypes.TIMETRACKING_GET_ALL;
  constructor(public employeeID: string) {}
}

export class TimeTrackingGetAllSuccess implements Action {
  readonly type = TimeTrackingActionTypes.TIMETRACKING_GET_ALL_SUCCESS;
  constructor(public payload: TimeEntryApiModel[]) {}
}

export class TimeTrackingGetAllError implements Action {
  readonly type = TimeTrackingActionTypes.TIMETRACKING_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// Clear TimeTracking
export class TimeTrackingClear implements Action {
  readonly type = TimeTrackingActionTypes.TIMETRACKING_CLEAR;
  constructor(public payload: any) {}
}

export type TimeTrackingActions =
  // Get All TimeTracking
  | TimeTrackingGetAll
  | TimeTrackingGetAllSuccess
  | TimeTrackingGetAllError
  // Clear TimeTracking
  | TimeTrackingClear;
