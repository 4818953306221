/* tslint:disable */
/* eslint-disable */
export interface EnhancedReviewDetail {
  _bt_employee_value?: null | string;
  _bt_group_value?: null | string;
  _bt_performancereviewdetailsummary_value?: null | string;
  _bt_responder_value?: null | string;
  _bt_subgroup_value?: null | string;
  bt_actualamount?: null | number;
  bt_basepoint?: null | number;
  bt_basepointpercentage?: null | number;
  bt_benchmark?: null | string;
  bt_completed?: null | boolean;
  bt_detailtype?: null | number;
  bt_displaysubgroup?: null | boolean;
  bt_groupsorting?: null | number;
  bt_maxvalue?: null | number;
  bt_measuresorting?: null | number;
  bt_minvalue?: null | number;
  bt_performancereviewdetail?: null | string;
  bt_performancereviewdetailid?: null | string;
  bt_rating?: null | number;
  bt_ratingfloat?: null | number;
  bt_response?: null | string;
  bt_responsetype?: null | number;
  bt_subgroupsorting?: null | number;
  bt_targetamount?: null | number;
  odataCompleted?: null | string;
  odataCycle?: null | string;
  odataDetailType?: null | string;
  odataGroup?: null | string;
  odataMeasure?: null | string;
  odataPeriod?: null | string;
  odataResponseType?: null | string;
  odataSubGroup?: null | string;
  odataTemplate?: null | string;
}
