/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvCriminalRecords } from '../models/dv-criminal-records';

@Injectable({
  providedIn: 'root',
})
export class SharedApiCriminalRecordsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllCriminalRecords
   */
  static readonly GetAllCriminalRecordsPath = '/api/CriminalRecords/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCriminalRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCriminalRecords$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvCriminalRecords>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCriminalRecordsService.GetAllCriminalRecordsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvCriminalRecords>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllCriminalRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCriminalRecords(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<DvCriminalRecords>> {
    return this.getAllCriminalRecords$Response(params).pipe(map((r: StrictHttpResponse<Array<DvCriminalRecords>>) => r.body as Array<DvCriminalRecords>));
  }
}
