/* eslint-disable no-console */
import { Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { DelegationsApiDelegationsService } from '@verde/api';
import { saveAs } from 'file-saver';
import { Subject, take } from 'rxjs';

@Component({
  selector: 'verde-onboarding-structure-edit',
  templateUrl: './onboarding-structure-edit.component.html',
  styleUrls: ['./onboarding-structure-edit.component.scss'],
})
export class OnboardingStructureEditComponent implements OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  @Input() structure: FormGroup;
  @Input() positionSeat: any;
  @Input() onboardingEmployee: any;

  restrictions: FileRestrictions = {
    allowedExtensions: ['pdf'],
  };

  cvBase64: any;

  constructor(private delegationApi: DelegationsApiDelegationsService) {}

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  onFileSelect(event: any): void {
    const file = event.files[0];
    if (file && this.structure) {
      this.structure.get('structure.cvDoc')?.setValue(file);
    }
  }

  donwloadCV() {
    if (this.onboardingEmployee.employeeID) {
      this.delegationApi
        .downloadFile({
          body: { columnName: 'bt_cvdocument', entityName: 'bthr_employee', recordID: this.onboardingEmployee.employeeID },
        })
        .pipe(take(1))
        .subscribe(
          (val) => {
            this.cvBase64 = val;
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.downloadPdf(this.cvBase64);
          },
        );
    }
  }

  //convert to blob
  downloadPdf(base64String: any) {
    const binaryData = atob(base64String);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    saveAs(blob, this.onboardingEmployee.bt_cvdocument_Name);
  }
}
