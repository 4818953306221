/* tslint:disable */
/* eslint-disable */
export interface VerdeHeaderTemplate {
  _bt_legalentity_value?: null | string;
  bt_headercontent?: null | string;
  bt_headerimagebase64?: null | string;
  bt_headerurl?: null | string;
  bt_textorimage?: boolean;
  bt_verdedocumentheadertemplate?: null | string;
  bt_verdedocumentheadertemplateid?: null | string;
}
