import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StorageService } from '@verde/core';
import { Observable } from 'rxjs';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService, @Inject('environment') private environment: any) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes(this.environment.features.legacyApiUrl)) {
      const httpParams = request.params
        .set('APIURL', this.storageService.sessionGetKey('APIURL') || '')
        .set('subscriptionKey', this.storageService.sessionGetKey('subKey') || '');
      const dupReq = request.clone({
        params: httpParams,
        setHeaders: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        },
      });
      return next.handle(dupReq);
    } else {
      const dupReq = request.clone({
        setHeaders: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        },
      });
      return next.handle(dupReq);
    }
  }
}
