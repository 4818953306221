/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DelegationsBaseService } from '../delegations-base-service';
import { DelegationsApiConfiguration } from '../delegations-api-configuration';
import { DelegationsStrictHttpResponse } from '../delegations-strict-http-response';
import { DelegationsRequestBuilder } from '../delegations-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DelegationsCreateUpdateSuccessionsSuccessorCommand } from '../models/delegations-create-update-successions-successor-command';
import { DelegationsDownloadFileQuery } from '../models/delegations-download-file-query';
import { DelegationsGetHrSuccessionsManagersQuery } from '../models/delegations-get-hr-successions-managers-query';
import { DelegationsGetHrSuccessionsSuccessorsQuery } from '../models/delegations-get-hr-successions-successors-query';
import { DelegationsSuccessPlanManagerQueryResult } from '../models/delegations-success-plan-manager-query-result';
import { DelegationsSuccessionPlanSuccessorQueryResult } from '../models/delegations-succession-plan-successor-query-result';

@Injectable({
  providedIn: 'root',
})
export class DelegationsApiDelegationsService extends DelegationsBaseService {
  constructor(config: DelegationsApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getHrSuccessionsManagers
   */
  static readonly GetHrSuccessionsManagersPath = '/api/Delegations/hrsuccessionsmanagers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrSuccessionsManagers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getHrSuccessionsManagers$Response(params?: {
    tenantId?: string;
    body?: DelegationsGetHrSuccessionsManagersQuery;
  }): Observable<DelegationsStrictHttpResponse<DelegationsSuccessPlanManagerQueryResult>> {
    const rb = new DelegationsRequestBuilder(this.rootUrl, DelegationsApiDelegationsService.GetHrSuccessionsManagersPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DelegationsStrictHttpResponse<DelegationsSuccessPlanManagerQueryResult>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrSuccessionsManagers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getHrSuccessionsManagers(params?: {
    tenantId?: string;
    body?: DelegationsGetHrSuccessionsManagersQuery;
  }): Observable<DelegationsSuccessPlanManagerQueryResult> {
    return this.getHrSuccessionsManagers$Response(params).pipe(
      map((r: DelegationsStrictHttpResponse<DelegationsSuccessPlanManagerQueryResult>) => r.body as DelegationsSuccessPlanManagerQueryResult),
    );
  }

  /**
   * Path part for operation getHrSuccessionsSuccessors
   */
  static readonly GetHrSuccessionsSuccessorsPath = '/api/Delegations/hrsuccessionssuccessors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrSuccessionsSuccessors()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getHrSuccessionsSuccessors$Response(params?: {
    tenantId?: string;
    body?: DelegationsGetHrSuccessionsSuccessorsQuery;
  }): Observable<DelegationsStrictHttpResponse<DelegationsSuccessionPlanSuccessorQueryResult>> {
    const rb = new DelegationsRequestBuilder(this.rootUrl, DelegationsApiDelegationsService.GetHrSuccessionsSuccessorsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DelegationsStrictHttpResponse<DelegationsSuccessionPlanSuccessorQueryResult>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrSuccessionsSuccessors$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getHrSuccessionsSuccessors(params?: {
    tenantId?: string;
    body?: DelegationsGetHrSuccessionsSuccessorsQuery;
  }): Observable<DelegationsSuccessionPlanSuccessorQueryResult> {
    return this.getHrSuccessionsSuccessors$Response(params).pipe(
      map((r: DelegationsStrictHttpResponse<DelegationsSuccessionPlanSuccessorQueryResult>) => r.body as DelegationsSuccessionPlanSuccessorQueryResult),
    );
  }

  /**
   * Path part for operation createUpdateSuccessionsSuccessors
   */
  static readonly CreateUpdateSuccessionsSuccessorsPath = '/api/Delegations/createupdatesuccessionssuccessors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUpdateSuccessionsSuccessors()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUpdateSuccessionsSuccessors$Response(params?: {
    tenantId?: string;
    body?: DelegationsCreateUpdateSuccessionsSuccessorCommand;
  }): Observable<DelegationsStrictHttpResponse<string>> {
    const rb = new DelegationsRequestBuilder(this.rootUrl, DelegationsApiDelegationsService.CreateUpdateSuccessionsSuccessorsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DelegationsStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createUpdateSuccessionsSuccessors$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUpdateSuccessionsSuccessors(params?: { tenantId?: string; body?: DelegationsCreateUpdateSuccessionsSuccessorCommand }): Observable<string> {
    return this.createUpdateSuccessionsSuccessors$Response(params).pipe(map((r: DelegationsStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation downloadFile
   */
  static readonly DownloadFilePath = '/api/Delegations/downloadfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadFile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadFile$Response(params?: { tenantId?: string; body?: DelegationsDownloadFileQuery }): Observable<DelegationsStrictHttpResponse<string>> {
    const rb = new DelegationsRequestBuilder(this.rootUrl, DelegationsApiDelegationsService.DownloadFilePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DelegationsStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadFile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadFile(params?: { tenantId?: string; body?: DelegationsDownloadFileQuery }): Observable<string> {
    return this.downloadFile$Response(params).pipe(map((r: DelegationsStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation uploadFile
   */
  static readonly UploadFilePath = '/api/Delegations/uploadfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFile$Response(params?: {
    tenantId?: string;
    body?: {
      DataverseUrl?: string;
      file?: Blob;
      recordID?: string;
      entityName?: string;
      columnName?: string;
    };
  }): Observable<DelegationsStrictHttpResponse<string>> {
    const rb = new DelegationsRequestBuilder(this.rootUrl, DelegationsApiDelegationsService.UploadFilePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as DelegationsStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFile(params?: {
    tenantId?: string;
    body?: {
      DataverseUrl?: string;
      file?: Blob;
      recordID?: string;
      entityName?: string;
      columnName?: string;
    };
  }): Observable<string> {
    return this.uploadFile$Response(params).pipe(map((r: DelegationsStrictHttpResponse<string>) => r.body as string));
  }
}
