/* tslint:disable */
/* eslint-disable */
import { ProcessesLookupExtrapolate } from './processes-lookup-extrapolate';
import { ProcessesPositionSeatEmployee } from './processes-position-seat-employee';
import { ProcessesProcessesEmployee } from './processes-processes-employee';
import { ProcessesBtEmployeeLifecycleStage } from './processes-bt-employee-lifecycle-stage';
import { ProcessesBtRecruitmentStatus } from './processes-bt-recruitment-status';
import { ProcessesBtPositionseat } from './processes-bt-positionseat';
export interface ProcessesPositionSeats {
  assignedEmployees?: ProcessesPositionSeatEmployee;
  bt_RecruitmentStatus?: ProcessesBtRecruitmentStatus;
  bt_departmentmanager?: null | string;
  bt_divisionmanager?: null | string;
  bt_eeseat?: null | boolean;
  bt_employee?: string;
  bt_employeeassigned?: null | boolean;
  bt_employeelifecyclestage?: null | string;
  bt_employeetype?: ProcessesLookupExtrapolate;
  bt_expectedonboarding?: null | string;
  bt_lastworkingdate?: null | string;
  bt_legalentityCountryText?: null | string;
  bt_legalentitytext?: null | string;
  bt_lifecyclestage?: ProcessesBtEmployeeLifecycleStage;
  bt_location?: string;
  bt_manager?: ProcessesLookupExtrapolate;
  bt_probationtype?: ProcessesLookupExtrapolate;
  bt_reportingmanagerfullname?: null | string;
  bt_seatstatus?: ProcessesBtPositionseat;
  bt_seattitle?: null | string;
  bt_shifttype?: ProcessesLookupExtrapolate;
  bthr_Employee?: ProcessesProcessesEmployee;
  bthr_description?: null | string;
  bthr_positionseatid?: string;
  bthr_seatname?: null | string;
  calcAssignedStartDate?: null | string;
  calcEmpFirstName?: null | string;
  calcEmpFullname?: null | string;
  calcEmpLastName?: null | string;
  calcEmpStage?: null | string;
  calcEmpStatus?: null | string;
  cr1f2_department?: ProcessesLookupExtrapolate;
  cr1f2_division?: ProcessesLookupExtrapolate;
  cr1f2_legalentity?: ProcessesLookupExtrapolate;
  employee?: ProcessesLookupExtrapolate;
  legalEntityCountry?: ProcessesLookupExtrapolate;
  position?: ProcessesLookupExtrapolate;
}
