/* tslint:disable */
/* eslint-disable */
export interface DvApprovalConfig {
  _bt_escalate1legalentity_value?: null | string;
  _bt_escalate2legalentity_value?: null | string;
  _bt_escalategiftsto1st_value?: null | string;
  _bt_escalategiftsto2nd_value?: null | string;
  _bt_executiveapprover_value?: null | string;
  _bt_executivelegalentity_value?: null | string;
  _bt_legalentity_value?: null | string;
  bt_approvalconfigid?: null | string;
  bt_escalate?: null | boolean;
  bt_escalationvalue?: null | number;
  bt_executiveapproval?: null | boolean;
  bt_giftapproval?: null | boolean;
  bt_giftregister?: null | boolean;
  bt_managerapproval?: null | boolean;
  bt_name?: null | string;
  bt_ytdescalation?: null | number;
}
