/* tslint:disable */
/* eslint-disable */
import { EquipmentTakeOn } from './equipment-take-on';
import { User } from './user';
import { VerdeTask } from './verde-task';
export interface EquipmentTakeOnControl {
  _bt_employee_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_taskno_value?: null | string;
  bt_Employee?: User;
  bt_TaskNo?: VerdeTask;
  bt_bt_equipmenttakeon_TakeOnControl_bt_equip?: null | Array<EquipmentTakeOn>;
  bt_counter?: null | number;
  bt_counter_date?: null | string;
  bt_counter_state?: null | number;
  bt_counterinactive?: null | number;
  bt_counterinactive_date?: null | string;
  bt_counterinactive_state?: null | number;
  bt_equipmenttakeoncontrol?: null | string;
  bt_equipmenttakeoncontrolid?: null | string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataTaskNo?: null | string;
}
