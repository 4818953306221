/* tslint:disable */
/* eslint-disable */
export enum DelegationsBtEmployeeLifecycleStage {
  Applicant = 'Applicant',
  Offered = 'Offered',
  OfferRejected = 'Offer rejected',
  Onboarding = 'Onboarding',
  Employed = 'Employed',
  OffBoarding = 'Off Boarding',
  Exited = 'Exited',
}
