import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import { WebDynamicGetDynamicFormLookupQuery } from '@verde/api';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';

interface SelectProps extends FormlyFieldProps, FormlyFieldSelectProps {
  primitive?: boolean;
  loading?: boolean;
  readonly?: boolean;
  required?: boolean;
  tabindex?: number;
  disabled?: boolean;
  change?: (field: any, event: any) => void;
  lookUp?: WebDynamicGetDynamicFormLookupQuery;
}

@Component({
  selector: 'verde-kendo-formly-select',
  template: `
    <kendo-dropdownlist
      class="verde-custom-field"
      [id]="field.key"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [data]="filteredOptions$ | async"
      [textField]="textField"
      [valueField]="valueField"
      [value]="formControl.value"
      [readonly]="props.readonly"
      [required]="props.required"
      [tabIndex]="props.tabindex || 0"
      [disabled]="props.disabled"
      [valuePrimitive]="props.primitive ?? true"
      [attr.data-selected-value]="formControl.value"
      [filterable]="true" 
      (filterChange)="onFilterChange($event)" 
      (valueChange)="onValueChange($event)"
    >
      <ng-template kendoDropDownListValueTemplate let-dataItem>
        <ng-container *ngIf="props.loading; else content">
          <kendo-loader type="pulsing" themeColor="primary"></kendo-loader>
        </ng-container>
        <ng-template #content>
          {{ dataItem?.[textField] }}
        </ng-template>
      </ng-template>
    </kendo-dropdownlist>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoFormlySelect extends FieldType<FieldTypeConfig<SelectProps>> implements OnInit, OnDestroy, DoCheck {
  private onDestroy$ = new Subject<boolean>();
  private lookUpSubject = new BehaviorSubject<WebDynamicGetDynamicFormLookupQuery | undefined>(undefined);
  private filterQuery$ = new BehaviorSubject<string | null>(null); // Holds the current filter query
  options$: Observable<any[]>;
  filteredOptions$: Observable<any[]>;
  textField: string = 'label';
  valueField: string = 'value';

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.textField = this.props.lookUp?.label || 'label';
    this.valueField = this.props.lookUp?.value || 'value';

    if (this.props.options instanceof Observable) {
      this.props.loading = true;

      this.options$ = this.props.options.pipe(
        tap(() => {
          this.props.loading = false;
          this.cd.detectChanges();
        }),
        catchError((error) => {
          console.error('Error loading options:', error);
          this.props.loading = false;
          this.cd.detectChanges();
          return of([]);
        }),
        takeUntil(this.onDestroy$),
      );

      this.filteredOptions$ = combineLatest([this.options$, this.lookUpSubject.asObservable(), this.filterQuery$]).pipe(
        map(([options, lookUp, filterQuery]) => {
          let filtered = options;

          // Apply dependency-based filtering
          if (lookUp) {
            filtered = filtered.filter((option) => {
              if (lookUp.dependency && Object.keys(lookUp.dependency).length > 0) {
                return Object.keys(option).some((key) => {
                  const isObj = typeof option[key] === 'object';
                  const hasValue = lookUp.dependency.value !== undefined && lookUp.dependency.value !== null;
                  return isObj && hasValue && option[key].id === lookUp.dependency.value;
                });
              }
              return true;
            });
          }

          // Apply search filter
          if (filterQuery) {
            const query = filterQuery.toLowerCase();
            filtered = filtered.filter((option) => option[this.textField]?.toLowerCase().includes(query));
          }

          return filtered;
        }),
        takeUntil(this.onDestroy$),
      );
    } else {
      this.options$ = of(this.props.options);
      this.filteredOptions$ = this.options$;
      this.cd.detectChanges();
    }
  }

  onFilterChange(query: string) {
    this.filterQuery$.next(query); // Update the current filter query
    this.cd.detectChanges();
  }

  ngDoCheck() {
    this.filterOptions();
  }

  filterOptions() {
    this.lookUpSubject.next(this.props.lookUp);
  }

  onValueChange(event: any) {
    if (this.props.change) {
      this.props.change(this.field, event);
    }
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}

