/* tslint:disable */
/* eslint-disable */
export interface BtVerdeManualDocumentsControl {
  _bt_group_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_processowner_value?: null | string;
  _bt_processownerlegalentity_value?: null | string;
  btCompletedODataCommunityDisplayV1FormattedValue?: null | string;
  btGroupValueODataCommunityDisplayV1FormattedValue?: null | string;
  bt_acknowledge?: null | boolean;
  bt_allowedit?: null | boolean;
  bt_attachment_name?: null | string;
  bt_completed?: null | boolean;
  bt_createtask?: null | boolean;
  bt_description?: null | string;
  bt_duedate?: null | string;
  bt_message?: null | string;
  bt_objection?: null | boolean;
  bt_referenceno?: null | string;
  bt_required?: null | boolean;
  bt_verdemanualdocumentcontrol?: null | string;
  bt_verdemanualdocumentcontrolid?: null | string;
  odataLegalEntity?: null | string;
  odataProcessOwner?: null | string;
  odataProcessOwnerLegalEntity?: null | string;
}
