/* tslint:disable */
/* eslint-disable */
export interface LocationsNew {
  _bt_legalentity_value?: null | string;
  _bt_locationgroup_value?: null | string;
  _bthr_country_value?: null | string;
  bt_equipmentlocation?: null | boolean;
  bt_equipmentlocationtype?: null | number;
  bt_erpapi?: null | string;
  bt_erpmapping?: null | string;
  bt_locationunique?: null | string;
  bthr_locationid?: null | string;
  bthr_locationname?: null | string;
  bthr_locationtype?: null | number;
  bthr_recordstatus?: null | number;
  odataCountry?: null | string;
  odataEquipmentLocationType?: null | string;
  odataLocationGroup?: null | string;
  odataLocationType?: null | string;
  odataRegion?: null | string;
}
