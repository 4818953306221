/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmployeeTimeOffCycleSummary } from '../models/employee-time-off-cycle-summary';
import { EmployeeTimeOffSummary } from '../models/employee-time-off-summary';
import { TakeOnHrFocusImport } from '../models/take-on-hr-focus-import';
import { TimeOffActivation } from '../models/time-off-activation';
import { TimeOffCancelltionParameters } from '../models/time-off-cancelltion-parameters';
import { User } from '../models/user';
import { DvTimeOffRequest } from '../models/dv-time-off-request';
import { DvTimeOffRequestResubmit } from '../models/dv-time-off-request-resubmit';
import { DvTimeOffSummary } from '../models/dv-time-off-summary';
import { DvTimeOffTransactionType } from '../models/dv-time-off-transaction-type';
import { DvTimeOffTypes } from '../models/dv-time-off-types';
import { TimeOffCalendarRequests } from '../models/time-off-calendar-requests';

@Injectable({
  providedIn: 'root',
})
export class SharedApiTimeOffService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getTimeOffSummaries
   */
  static readonly GetTimeOffSummariesPath = '/api/TimeOff/summaries/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOffSummaries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffSummaries$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvTimeOffSummary>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetTimeOffSummariesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvTimeOffSummary>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOffSummaries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffSummaries(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<DvTimeOffSummary>> {
    return this.getTimeOffSummaries$Response(params).pipe(map((r: StrictHttpResponse<Array<DvTimeOffSummary>>) => r.body as Array<DvTimeOffSummary>));
  }

  /**
   * Path part for operation getTimeOffTransactionTypes
   */
  static readonly GetTimeOffTransactionTypesPath = '/api/TimeOff/transactiontypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOffTransactionTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffTransactionTypes$Response(params?: { subscriptionKey?: string; APIURL?: string }): Observable<StrictHttpResponse<Array<DvTimeOffTransactionType>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetTimeOffTransactionTypesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvTimeOffTransactionType>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOffTransactionTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffTransactionTypes(params?: { subscriptionKey?: string; APIURL?: string }): Observable<Array<DvTimeOffTransactionType>> {
    return this.getTimeOffTransactionTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvTimeOffTransactionType>>) => r.body as Array<DvTimeOffTransactionType>),
    );
  }

  /**
   * Path part for operation getTimeOffTypes
   */
  static readonly GetTimeOffTypesPath = '/api/TimeOff/timeofftypes/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOffTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffTypes$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<DvTimeOffTypes>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetTimeOffTypesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvTimeOffTypes>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOffTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffTypes(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<DvTimeOffTypes>> {
    return this.getTimeOffTypes$Response(params).pipe(map((r: StrictHttpResponse<Array<DvTimeOffTypes>>) => r.body as Array<DvTimeOffTypes>));
  }

  /**
   * Path part for operation getTimeOffRequests
   */
  static readonly GetTimeOffRequestsPath = '/api/TimeOff/requests/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOffRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffRequests$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvTimeOffRequest>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetTimeOffRequestsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvTimeOffRequest>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOffRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffRequests(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<DvTimeOffRequest>> {
    return this.getTimeOffRequests$Response(params).pipe(map((r: StrictHttpResponse<Array<DvTimeOffRequest>>) => r.body as Array<DvTimeOffRequest>));
  }

  /**
   * Path part for operation getTimeOffRequestById
   */
  static readonly GetTimeOffRequestByIdPath = '/api/TimeOff/requestbyid/{requestID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOffRequestById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffRequestById$Response(params: { subscriptionKey?: string; APIURL?: string; requestID: string }): Observable<StrictHttpResponse<DvTimeOffRequest>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetTimeOffRequestByIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('requestID', params.requestID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DvTimeOffRequest>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOffRequestById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffRequestById(params: { subscriptionKey?: string; APIURL?: string; requestID: string }): Observable<DvTimeOffRequest> {
    return this.getTimeOffRequestById$Response(params).pipe(map((r: StrictHttpResponse<DvTimeOffRequest>) => r.body as DvTimeOffRequest));
  }

  /**
   * Path part for operation getTimeOffManagerApprovals
   */
  static readonly GetTimeOffManagerApprovalsPath = '/api/TimeOff/timeoffmanagerapprovals/{managerID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOffManagerApprovals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffManagerApprovals$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<DvTimeOffRequest>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetTimeOffManagerApprovalsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerID', params.managerID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvTimeOffRequest>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOffManagerApprovals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffManagerApprovals(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
    legalEntityID: string;
  }): Observable<Array<DvTimeOffRequest>> {
    return this.getTimeOffManagerApprovals$Response(params).pipe(map((r: StrictHttpResponse<Array<DvTimeOffRequest>>) => r.body as Array<DvTimeOffRequest>));
  }

  /**
   * Path part for operation getTimeOffHrApprovals
   */
  static readonly GetTimeOffHrApprovalsPath = '/api/TimeOff/timeoffhrapprovals/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOffHrApprovals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffHrApprovals$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<DvTimeOffRequest>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetTimeOffHrApprovalsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvTimeOffRequest>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOffHrApprovals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffHrApprovals(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<DvTimeOffRequest>> {
    return this.getTimeOffHrApprovals$Response(params).pipe(map((r: StrictHttpResponse<Array<DvTimeOffRequest>>) => r.body as Array<DvTimeOffRequest>));
  }

  /**
   * Path part for operation getTimeOffDashboardSummary
   */
  static readonly GetTimeOffDashboardSummaryPath = '/api/TimeOff/dashboardtimeoffsummary/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOffDashboardSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffDashboardSummary$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<EmployeeTimeOffSummary>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetTimeOffDashboardSummaryPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EmployeeTimeOffSummary>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOffDashboardSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffDashboardSummary(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<Array<EmployeeTimeOffSummary>> {
    return this.getTimeOffDashboardSummary$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeTimeOffSummary>>) => r.body as Array<EmployeeTimeOffSummary>),
    );
  }

  /**
   * Path part for operation getEmployeeTimeOffSummaries
   */
  static readonly GetEmployeeTimeOffSummariesPath = '/api/TimeOff/employeetimeoffsummaries/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeTimeOffSummaries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeTimeOffSummaries$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<EmployeeTimeOffSummary>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetEmployeeTimeOffSummariesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EmployeeTimeOffSummary>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeTimeOffSummaries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeTimeOffSummaries(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<Array<EmployeeTimeOffSummary>> {
    return this.getEmployeeTimeOffSummaries$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeTimeOffSummary>>) => r.body as Array<EmployeeTimeOffSummary>),
    );
  }

  /**
   * Path part for operation postTimeOffRequest
   */
  static readonly PostTimeOffRequestPath = '/api/TimeOff/postrequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTimeOffRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeOffRequest$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: DvTimeOffRequest }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.PostTimeOffRequestPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postTimeOffRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeOffRequest(params?: { subscriptionKey?: string; APIURL?: string; body?: DvTimeOffRequest }): Observable<string> {
    return this.postTimeOffRequest$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation postTimeOffActivation
   */
  static readonly PostTimeOffActivationPath = '/api/TimeOff/postTimeOffActivation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTimeOffActivation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeOffActivation$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: TimeOffActivation }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.PostTimeOffActivationPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postTimeOffActivation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeOffActivation(params?: { subscriptionKey?: string; APIURL?: string; body?: TimeOffActivation }): Observable<boolean> {
    return this.postTimeOffActivation$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchTimeOffRequest
   */
  static readonly PatchTimeOffRequestPath = '/api/TimeOff/patchrequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTimeOffRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchTimeOffRequest$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: DvTimeOffRequest }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.PatchTimeOffRequestPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchTimeOffRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchTimeOffRequest(params?: { subscriptionKey?: string; APIURL?: string; body?: DvTimeOffRequest }): Observable<boolean> {
    return this.patchTimeOffRequest$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchTimeOffRequestResubmit
   */
  static readonly PatchTimeOffRequestResubmitPath = '/api/TimeOff/patchrequestresubmit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTimeOffRequestResubmit()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchTimeOffRequestResubmit$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: DvTimeOffRequestResubmit;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.PatchTimeOffRequestResubmitPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchTimeOffRequestResubmit$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchTimeOffRequestResubmit(params?: { subscriptionKey?: string; APIURL?: string; body?: DvTimeOffRequestResubmit }): Observable<boolean> {
    return this.patchTimeOffRequestResubmit$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getEmployeeTimeOffRequests
   */
  static readonly GetEmployeeTimeOffRequestsPath = '/api/TimeOff/employeetimeoffrequest/{employeeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeTimeOffRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeTimeOffRequests$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeID: string;
  }): Observable<StrictHttpResponse<Array<TimeOffCalendarRequests>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetEmployeeTimeOffRequestsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeID', params.employeeID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TimeOffCalendarRequests>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeTimeOffRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeTimeOffRequests(params: { subscriptionKey?: string; APIURL?: string; employeeID: string }): Observable<Array<TimeOffCalendarRequests>> {
    return this.getEmployeeTimeOffRequests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TimeOffCalendarRequests>>) => r.body as Array<TimeOffCalendarRequests>),
    );
  }

  /**
   * Path part for operation getHrTimeOffRequests
   */
  static readonly GetHrTimeOffRequestsPath = '/api/TimeOff/hrtimeoffrequests/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrTimeOffRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrTimeOffRequests$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<TimeOffCalendarRequests>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetHrTimeOffRequestsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TimeOffCalendarRequests>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrTimeOffRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrTimeOffRequests(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<TimeOffCalendarRequests>> {
    return this.getHrTimeOffRequests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TimeOffCalendarRequests>>) => r.body as Array<TimeOffCalendarRequests>),
    );
  }

  /**
   * Path part for operation getManagerTimeOffRequests
   */
  static readonly GetManagerTimeOffRequestsPath = '/api/TimeOff/managertimeoffrequests/{managerID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManagerTimeOffRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManagerTimeOffRequests$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<TimeOffCalendarRequests>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetManagerTimeOffRequestsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('managerID', params.managerID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TimeOffCalendarRequests>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManagerTimeOffRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getManagerTimeOffRequests(params: {
    subscriptionKey?: string;
    APIURL?: string;
    managerID: string;
    legalEntityID: string;
  }): Observable<Array<TimeOffCalendarRequests>> {
    return this.getManagerTimeOffRequests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TimeOffCalendarRequests>>) => r.body as Array<TimeOffCalendarRequests>),
    );
  }

  /**
   * Path part for operation getHrManagerTimeOffRequests
   */
  static readonly GetHrManagerTimeOffRequestsPath = '/api/TimeOff/hrmanagertimeoffrequests/{legalEntityID}/{managerApprovalStatus}/{requestStatus}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHrManagerTimeOffRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrManagerTimeOffRequests$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    managerApprovalStatus: string;
    requestStatus: string;
  }): Observable<StrictHttpResponse<Array<TimeOffCalendarRequests>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetHrManagerTimeOffRequestsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('managerApprovalStatus', params.managerApprovalStatus, {});
      rb.path('requestStatus', params.requestStatus, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TimeOffCalendarRequests>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHrManagerTimeOffRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHrManagerTimeOffRequests(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    managerApprovalStatus: string;
    requestStatus: string;
  }): Observable<Array<TimeOffCalendarRequests>> {
    return this.getHrManagerTimeOffRequests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TimeOffCalendarRequests>>) => r.body as Array<TimeOffCalendarRequests>),
    );
  }

  /**
   * Path part for operation getTimeOffTakeOnHrFocusImport
   */
  static readonly GetTimeOffTakeOnHrFocusImportPath = '/api/TimeOff/timeofftakeonhrfocusimport/{legalEntityID}/{userID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOffTakeOnHrFocusImport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffTakeOnHrFocusImport$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    userID: string;
  }): Observable<StrictHttpResponse<Array<TakeOnHrFocusImport>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetTimeOffTakeOnHrFocusImportPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('userID', params.userID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TakeOnHrFocusImport>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOffTakeOnHrFocusImport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffTakeOnHrFocusImport(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    userID: string;
  }): Observable<Array<TakeOnHrFocusImport>> {
    return this.getTimeOffTakeOnHrFocusImport$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TakeOnHrFocusImport>>) => r.body as Array<TakeOnHrFocusImport>),
    );
  }

  /**
   * Path part for operation getEmployeeTimeOffCycleSummary
   */
  static readonly GetEmployeeTimeOffCycleSummaryPath = '/api/TimeOff/employeetimeoffcyclesummary/{legalEntityID}/{userID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeTimeOffCycleSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeTimeOffCycleSummary$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    userID: string;
  }): Observable<StrictHttpResponse<Array<EmployeeTimeOffCycleSummary>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetEmployeeTimeOffCycleSummaryPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('userID', params.userID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EmployeeTimeOffCycleSummary>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeTimeOffCycleSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeTimeOffCycleSummary(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    userID: string;
  }): Observable<Array<EmployeeTimeOffCycleSummary>> {
    return this.getEmployeeTimeOffCycleSummary$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeTimeOffCycleSummary>>) => r.body as Array<EmployeeTimeOffCycleSummary>),
    );
  }

  /**
   * Path part for operation getTimeOffCycleDashboardSummary
   */
  static readonly GetTimeOffCycleDashboardSummaryPath = '/api/TimeOff/timeoffcyclesummaryfordashboard/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOffCycleDashboardSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffCycleDashboardSummary$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<EmployeeTimeOffCycleSummary>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetTimeOffCycleDashboardSummaryPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EmployeeTimeOffCycleSummary>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOffCycleDashboardSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffCycleDashboardSummary(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<Array<EmployeeTimeOffCycleSummary>> {
    return this.getTimeOffCycleDashboardSummary$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EmployeeTimeOffCycleSummary>>) => r.body as Array<EmployeeTimeOffCycleSummary>),
    );
  }

  /**
   * Path part for operation getTimeOffRequestFilteredForHr
   */
  static readonly GetTimeOffRequestFilteredForHrPath = '/api/TimeOff/timeoffcyclesummaryfordashboard/{legalEntityID}/{primaryLocationID}/{startDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOffRequestFilteredForHr()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffRequestFilteredForHr$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    primaryLocationID: string;
    startDate: string;
  }): Observable<StrictHttpResponse<Array<User>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetTimeOffRequestFilteredForHrPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('primaryLocationID', params.primaryLocationID, {});
      rb.path('startDate', params.startDate, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<User>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOffRequestFilteredForHr$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffRequestFilteredForHr(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    primaryLocationID: string;
    startDate: string;
  }): Observable<Array<User>> {
    return this.getTimeOffRequestFilteredForHr$Response(params).pipe(map((r: StrictHttpResponse<Array<User>>) => r.body as Array<User>));
  }

  /**
   * Path part for operation postTimeOffCancellation
   */
  static readonly PostTimeOffCancellationPath = '/api/TimeOff/post/timeoffcancellation/{environmentURL}/{slot}/{requestID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postTimeOffCancellation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeOffCancellation$Response(params: {
    environmentURL: string;
    slot: string;
    requestID: string;
    body?: TimeOffCancelltionParameters;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.PostTimeOffCancellationPath, 'post');
    if (params) {
      rb.path('environmentURL', params.environmentURL, {});
      rb.path('slot', params.slot, {});
      rb.path('requestID', params.requestID, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postTimeOffCancellation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postTimeOffCancellation(params: { environmentURL: string; slot: string; requestID: string; body?: TimeOffCancelltionParameters }): Observable<boolean> {
    return this.postTimeOffCancellation$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getTimeOffRequestCalculations
   */
  static readonly GetTimeOffRequestCalculationsPath = '/api/TimeOff/timeoffrequestcalculations/{environmentURL}/{slot}/{requestID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeOffRequestCalculations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffRequestCalculations$Response(params: {
    environmentURL: string;
    slot: string;
    requestID: string;
  }): Observable<StrictHttpResponse<DvTimeOffRequest>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.GetTimeOffRequestCalculationsPath, 'get');
    if (params) {
      rb.path('environmentURL', params.environmentURL, {});
      rb.path('slot', params.slot, {});
      rb.path('requestID', params.requestID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DvTimeOffRequest>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeOffRequestCalculations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeOffRequestCalculations(params: { environmentURL: string; slot: string; requestID: string }): Observable<DvTimeOffRequest> {
    return this.getTimeOffRequestCalculations$Response(params).pipe(map((r: StrictHttpResponse<DvTimeOffRequest>) => r.body as DvTimeOffRequest));
  }

  /**
   * Path part for operation deleteTimeOffRequest
   */
  static readonly DeleteTimeOffRequestPath = '/api/TimeOff/deleterequest/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTimeOffRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTimeOffRequest$Response(params: { subscriptionKey?: string; APIURL?: string; recordID: string }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTimeOffService.DeleteTimeOffRequestPath, 'delete');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTimeOffRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTimeOffRequest(params: { subscriptionKey?: string; APIURL?: string; recordID: string }): Observable<string> {
    return this.deleteTimeOffRequest$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }
}
