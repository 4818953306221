/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface TimeEntryGroupApiModel {
  _bt_groupowner_value?: null | string;
  _bt_groupownerentity_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_parentgroup_value?: null | string;
  bt_GroupOwner?: User;
  bt_description?: null | string;
  bt_enddate?: null | string;
  bt_groupstatus?: null | number;
  bt_name?: null | string;
  bt_nooftimeentries?: null | number;
  bt_startdate?: null | string;
  bt_verdetimeentrygroupingid?: null | string;
  odataPost_bt_groupowner_value?: null | string;
  odataPost_bt_groupownerentity_value?: null | string;
  odataPost_bt_legalentity_value?: null | string;
  odataPost_bt_parentgroup_value?: null | string;
  odata_bt_groupowner_value?: null | string;
  odata_bt_groupownerentity_value?: null | string;
  odata_bt_legalentity_value?: null | string;
  odata_bt_parentgroup_value?: null | string;
  odatagroupstatus?: null | string;
  statecode?: null | number;
}
