/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EquipmentAccess } from '../models/equipment-access';
import { EquipmentItem } from '../models/equipment-item';
import { EquipmentItemSummary } from '../models/equipment-item-summary';
import { EquipmentKitItems } from '../models/equipment-kit-items';
import { EquipmentKits } from '../models/equipment-kits';
import { EquipmentRequestDetail } from '../models/equipment-request-detail';
import { EquipmentRequestHeader } from '../models/equipment-request-header';
import { EquipmentSpec } from '../models/equipment-spec';
import { EquipmentSpecType } from '../models/equipment-spec-type';
import { EquipmentTakeOn } from '../models/equipment-take-on';
import { EquipmentTakeOnControl } from '../models/equipment-take-on-control';
import { EquipmentTakeOnGroup } from '../models/equipment-take-on-group';
import { EquipmentTakeOnMake } from '../models/equipment-take-on-make';
import { EquipmentTakeOnType } from '../models/equipment-take-on-type';
import { EquipmentType } from '../models/equipment-type';
import { EquipmentTypeClass } from '../models/equipment-type-class';

@Injectable({
  providedIn: 'root',
})
export class SharedApiEquipmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getEquipmentSpecs
   */
  static readonly GetEquipmentSpecsPath = '/api/Equipment/equipmentspecs/{legalEntityID}/{specTypeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentSpecs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentSpecs$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    specTypeID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentSpec>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentSpecsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('specTypeID', params.specTypeID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentSpec>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentSpecs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentSpecs(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string; specTypeID: string }): Observable<Array<EquipmentSpec>> {
    return this.getEquipmentSpecs$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentSpec>>) => r.body as Array<EquipmentSpec>));
  }

  /**
   * Path part for operation getEquipmentSpecTypes
   */
  static readonly GetEquipmentSpecTypesPath = '/api/Equipment/equipmentspectypes/{legalEntityID}/{typeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentSpecTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentSpecTypes$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    typeID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentSpecType>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentSpecTypesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('typeID', params.typeID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentSpecType>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentSpecTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentSpecTypes(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string; typeID: string }): Observable<Array<EquipmentSpecType>> {
    return this.getEquipmentSpecTypes$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentSpecType>>) => r.body as Array<EquipmentSpecType>));
  }

  /**
   * Path part for operation getEquipmentKits
   */
  static readonly GetEquipmentKitsPath = '/api/Equipment/equipmentkits/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentKits()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentKits$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentKits>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentKitsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentKits>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentKits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentKits(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<EquipmentKits>> {
    return this.getEquipmentKits$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentKits>>) => r.body as Array<EquipmentKits>));
  }

  /**
   * Path part for operation getEquipmentKitItems
   */
  static readonly GetEquipmentKitItemsPath = '/api/Equipment/equipmentkititems/{legalEntityID}/{kitID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentKitItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentKitItems$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    kitID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentKitItems>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentKitItemsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('kitID', params.kitID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentKitItems>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentKitItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentKitItems(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string; kitID: string }): Observable<Array<EquipmentKitItems>> {
    return this.getEquipmentKitItems$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentKitItems>>) => r.body as Array<EquipmentKitItems>));
  }

  /**
   * Path part for operation getEquipmentTypes
   */
  static readonly GetEquipmentTypesPath = '/api/Equipment/equipmenttypes/{legalEntityID}/{typeClassID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTypes$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    typeClassID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentType>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentTypesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('typeClassID', params.typeClassID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentType>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTypes(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string; typeClassID: string }): Observable<Array<EquipmentType>> {
    return this.getEquipmentTypes$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentType>>) => r.body as Array<EquipmentType>));
  }

  /**
   * Path part for operation getEquipmentTypeClasses
   */
  static readonly GetEquipmentTypeClassesPath = '/api/Equipment/equipmenttypeclasses/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentTypeClasses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTypeClasses$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentTypeClass>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentTypeClassesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentTypeClass>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentTypeClasses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTypeClasses(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<EquipmentTypeClass>> {
    return this.getEquipmentTypeClasses$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentTypeClass>>) => r.body as Array<EquipmentTypeClass>));
  }

  /**
   * Path part for operation getEquipmentTypeById
   */
  static readonly GetEquipmentTypeByIdPath = '/api/Equipment/equipmenttypebyid/{typeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentTypeById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTypeById$Response(params: { subscriptionKey?: string; APIURL?: string; typeID: string }): Observable<StrictHttpResponse<Array<EquipmentType>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentTypeByIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('typeID', params.typeID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentType>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentTypeById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTypeById(params: { subscriptionKey?: string; APIURL?: string; typeID: string }): Observable<Array<EquipmentType>> {
    return this.getEquipmentTypeById$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentType>>) => r.body as Array<EquipmentType>));
  }

  /**
   * Path part for operation getEquipmentSpecTypeById
   */
  static readonly GetEquipmentSpecTypeByIdPath = '/api/Equipment/equipmentspectypebyid/{specTypeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentSpecTypeById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentSpecTypeById$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    specTypeID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentSpecType>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentSpecTypeByIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('specTypeID', params.specTypeID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentSpecType>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentSpecTypeById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentSpecTypeById(params: { subscriptionKey?: string; APIURL?: string; specTypeID: string }): Observable<Array<EquipmentSpecType>> {
    return this.getEquipmentSpecTypeById$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentSpecType>>) => r.body as Array<EquipmentSpecType>));
  }

  /**
   * Path part for operation getEquipmentSpecById
   */
  static readonly GetEquipmentSpecByIdPath = '/api/Equipment/equipmentspecbyid/{specID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentSpecById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentSpecById$Response(params: { subscriptionKey?: string; APIURL?: string; specID: string }): Observable<StrictHttpResponse<Array<EquipmentSpec>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentSpecByIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('specID', params.specID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentSpec>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentSpecById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentSpecById(params: { subscriptionKey?: string; APIURL?: string; specID: string }): Observable<Array<EquipmentSpec>> {
    return this.getEquipmentSpecById$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentSpec>>) => r.body as Array<EquipmentSpec>));
  }

  /**
   * Path part for operation getEquipmentAccess
   */
  static readonly GetEquipmentAccessPath = '/api/Equipment/access/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentAccess$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentAccess>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentAccessPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentAccess>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentAccess(params: { subscriptionKey?: string; APIURL?: string; userID: string; legalEntityID: string }): Observable<Array<EquipmentAccess>> {
    return this.getEquipmentAccess$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentAccess>>) => r.body as Array<EquipmentAccess>));
  }

  /**
   * Path part for operation getEquipmentRequestHeaderByUniqueName
   */
  static readonly GetEquipmentRequestHeaderByUniqueNamePath = '/api/Equipment/requestheaderbyuniquename/{uniqueName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestHeaderByUniqueName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestHeaderByUniqueName$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    uniqueName: string;
  }): Observable<StrictHttpResponse<Array<EquipmentRequestHeader>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentRequestHeaderByUniqueNamePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('uniqueName', params.uniqueName, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentRequestHeader>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestHeaderByUniqueName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestHeaderByUniqueName(params: { subscriptionKey?: string; APIURL?: string; uniqueName: string }): Observable<Array<EquipmentRequestHeader>> {
    return this.getEquipmentRequestHeaderByUniqueName$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EquipmentRequestHeader>>) => r.body as Array<EquipmentRequestHeader>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestHeaderById
   */
  static readonly GetEquipmentRequestHeaderByIdPath = '/api/Equipment/requestheaderbyid/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestHeaderById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestHeaderById$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    recordID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentRequestHeader>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentRequestHeaderByIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('recordID', params.recordID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentRequestHeader>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestHeaderById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestHeaderById(params: { subscriptionKey?: string; APIURL?: string; recordID: string }): Observable<Array<EquipmentRequestHeader>> {
    return this.getEquipmentRequestHeaderById$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EquipmentRequestHeader>>) => r.body as Array<EquipmentRequestHeader>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestHeaderByOwner
   */
  static readonly GetEquipmentRequestHeaderByOwnerPath = '/api/Equipment/requestheadersbyowner/{userID}/{legalEntityID}/{approvalStatus}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestHeaderByOwner()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestHeaderByOwner$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
    approvalStatus: string;
  }): Observable<StrictHttpResponse<Array<EquipmentRequestHeader>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentRequestHeaderByOwnerPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('approvalStatus', params.approvalStatus, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentRequestHeader>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestHeaderByOwner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestHeaderByOwner(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
    approvalStatus: string;
  }): Observable<Array<EquipmentRequestHeader>> {
    return this.getEquipmentRequestHeaderByOwner$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EquipmentRequestHeader>>) => r.body as Array<EquipmentRequestHeader>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestDetailByHeaderId
   */
  static readonly GetEquipmentRequestDetailByHeaderIdPath = '/api/Equipment/requestdetailbyheaderid/{headerID}/{lineType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestDetailByHeaderId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestDetailByHeaderId$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    headerID: string;
    lineType: string;
  }): Observable<StrictHttpResponse<Array<EquipmentRequestDetail>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentRequestDetailByHeaderIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('headerID', params.headerID, {});
      rb.path('lineType', params.lineType, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentRequestDetail>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestDetailByHeaderId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestDetailByHeaderId(params: {
    subscriptionKey?: string;
    APIURL?: string;
    headerID: string;
    lineType: string;
  }): Observable<Array<EquipmentRequestDetail>> {
    return this.getEquipmentRequestDetailByHeaderId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EquipmentRequestDetail>>) => r.body as Array<EquipmentRequestDetail>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestDetailKitItems
   */
  static readonly GetEquipmentRequestDetailKitItemsPath = '/api/Equipment/requestdetailkititems/{linkedRecordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestDetailKitItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestDetailKitItems$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    linkedRecordID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentRequestDetail>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentRequestDetailKitItemsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('linkedRecordID', params.linkedRecordID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentRequestDetail>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestDetailKitItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestDetailKitItems(params: { subscriptionKey?: string; APIURL?: string; linkedRecordID: string }): Observable<Array<EquipmentRequestDetail>> {
    return this.getEquipmentRequestDetailKitItems$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EquipmentRequestDetail>>) => r.body as Array<EquipmentRequestDetail>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestDetailAllocation
   */
  static readonly GetEquipmentRequestDetailAllocationPath =
    '/api/Equipment/requestdetailallocation/{legalEntityID}/{locationID}/{approvalStatus}/{allocationStatus}/{lineType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestDetailAllocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestDetailAllocation$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    locationID: string;
    approvalStatus: string;
    allocationStatus: string;
    lineType: string;
  }): Observable<StrictHttpResponse<Array<EquipmentRequestDetail>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentRequestDetailAllocationPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('locationID', params.locationID, {});
      rb.path('approvalStatus', params.approvalStatus, {});
      rb.path('allocationStatus', params.allocationStatus, {});
      rb.path('lineType', params.lineType, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentRequestDetail>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestDetailAllocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestDetailAllocation(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    locationID: string;
    approvalStatus: string;
    allocationStatus: string;
    lineType: string;
  }): Observable<Array<EquipmentRequestDetail>> {
    return this.getEquipmentRequestDetailAllocation$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EquipmentRequestDetail>>) => r.body as Array<EquipmentRequestDetail>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestDetailAllocationCount
   */
  static readonly GetEquipmentRequestDetailAllocationCountPath =
    '/api/Equipment/requestdetailallocationcount/{legalEntityID}/{locationID}/{approvalStatus}/{allocationStatus}/{lineType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestDetailAllocationCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestDetailAllocationCount$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    locationID: string;
    approvalStatus: string;
    allocationStatus: string;
    lineType: string;
  }): Observable<StrictHttpResponse<Array<EquipmentRequestDetail>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentRequestDetailAllocationCountPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('locationID', params.locationID, {});
      rb.path('approvalStatus', params.approvalStatus, {});
      rb.path('allocationStatus', params.allocationStatus, {});
      rb.path('lineType', params.lineType, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentRequestDetail>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestDetailAllocationCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestDetailAllocationCount(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    locationID: string;
    approvalStatus: string;
    allocationStatus: string;
    lineType: string;
  }): Observable<Array<EquipmentRequestDetail>> {
    return this.getEquipmentRequestDetailAllocationCount$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EquipmentRequestDetail>>) => r.body as Array<EquipmentRequestDetail>),
    );
  }

  /**
   * Path part for operation getEquipmentTakeOnControl
   */
  static readonly GetEquipmentTakeOnControlPath = '/api/Equipment/takeoncontrol/{legalEntityID}/{employeeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentTakeOnControl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTakeOnControl$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    employeeID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentTakeOnControl>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentTakeOnControlPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('employeeID', params.employeeID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentTakeOnControl>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentTakeOnControl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTakeOnControl(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    employeeID: string;
  }): Observable<Array<EquipmentTakeOnControl>> {
    return this.getEquipmentTakeOnControl$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EquipmentTakeOnControl>>) => r.body as Array<EquipmentTakeOnControl>),
    );
  }

  /**
   * Path part for operation getEquipmentTakeOn
   */
  static readonly GetEquipmentTakeOnPath = '/api/Equipment/takeon/{legalEntityID}/{employeeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentTakeOn()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTakeOn$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    employeeID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentTakeOn>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentTakeOnPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('employeeID', params.employeeID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentTakeOn>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentTakeOn$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTakeOn(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string; employeeID: string }): Observable<Array<EquipmentTakeOn>> {
    return this.getEquipmentTakeOn$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentTakeOn>>) => r.body as Array<EquipmentTakeOn>));
  }

  /**
   * Path part for operation getEquipmentTakeOnGroups
   */
  static readonly GetEquipmentTakeOnGroupsPath = '/api/Equipment/takeongroups/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentTakeOnGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTakeOnGroups$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentTakeOnGroup>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentTakeOnGroupsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentTakeOnGroup>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentTakeOnGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTakeOnGroups(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<EquipmentTakeOnGroup>> {
    return this.getEquipmentTakeOnGroups$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EquipmentTakeOnGroup>>) => r.body as Array<EquipmentTakeOnGroup>),
    );
  }

  /**
   * Path part for operation getEquipmentTakeOnTypes
   */
  static readonly GetEquipmentTakeOnTypesPath = '/api/Equipment/takeontypes/{legalEntityID}/{groupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentTakeOnTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTakeOnTypes$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    groupID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentTakeOnType>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentTakeOnTypesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('groupID', params.groupID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentTakeOnType>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentTakeOnTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTakeOnTypes(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    groupID: string;
  }): Observable<Array<EquipmentTakeOnType>> {
    return this.getEquipmentTakeOnTypes$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentTakeOnType>>) => r.body as Array<EquipmentTakeOnType>));
  }

  /**
   * Path part for operation getEquipmentTakeOnMakes
   */
  static readonly GetEquipmentTakeOnMakesPath = '/api/Equipment/takeonmakes/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentTakeOnMakes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTakeOnMakes$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentTakeOnMake>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentTakeOnMakesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentTakeOnMake>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentTakeOnMakes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentTakeOnMakes(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<EquipmentTakeOnMake>> {
    return this.getEquipmentTakeOnMakes$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentTakeOnMake>>) => r.body as Array<EquipmentTakeOnMake>));
  }

  /**
   * Path part for operation getEquipmentItemsByLocation
   */
  static readonly GetEquipmentItemsByLocationPath = '/api/Equipment/itemsbylocation/{legalEntityID}/{locationID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentItemsByLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentItemsByLocation$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    locationID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentItem>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentItemsByLocationPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('locationID', params.locationID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentItem>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentItemsByLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentItemsByLocation(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    locationID: string;
  }): Observable<Array<EquipmentItem>> {
    return this.getEquipmentItemsByLocation$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentItem>>) => r.body as Array<EquipmentItem>));
  }

  /**
   * Path part for operation getEquipmentItemsByEmployee
   */
  static readonly GetEquipmentItemsByEmployeePath = '/api/Equipment/itemsbyemployee/{legalEntityID}/{employeeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentItemsByEmployee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentItemsByEmployee$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    employeeID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentItem>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentItemsByEmployeePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('employeeID', params.employeeID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentItem>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentItemsByEmployee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentItemsByEmployee(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    employeeID: string;
  }): Observable<Array<EquipmentItem>> {
    return this.getEquipmentItemsByEmployee$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentItem>>) => r.body as Array<EquipmentItem>));
  }

  /**
   * Path part for operation getEquipmentItemsSummary
   */
  static readonly GetEquipmentItemsSummaryPath = '/api/Equipment/equipmentitemsummaries/{legalEntityID}/{isLayered}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentItemsSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentItemsSummary$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    isLayered: boolean;
  }): Observable<StrictHttpResponse<Array<EquipmentItemSummary>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentItemsSummaryPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('isLayered', params.isLayered, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentItemSummary>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentItemsSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentItemsSummary(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
    isLayered: boolean;
  }): Observable<Array<EquipmentItemSummary>> {
    return this.getEquipmentItemsSummary$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EquipmentItemSummary>>) => r.body as Array<EquipmentItemSummary>),
    );
  }

  /**
   * Path part for operation getEquipmentRequestItemsStock
   */
  static readonly GetEquipmentRequestItemsStockPath = '/api/Equipment/requestitemsstock/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEquipmentRequestItemsStock()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestItemsStock$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<EquipmentItem>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.GetEquipmentRequestItemsStockPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<EquipmentItem>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEquipmentRequestItemsStock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEquipmentRequestItemsStock(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<EquipmentItem>> {
    return this.getEquipmentRequestItemsStock$Response(params).pipe(map((r: StrictHttpResponse<Array<EquipmentItem>>) => r.body as Array<EquipmentItem>));
  }

  /**
   * Path part for operation patchEquipmentRequestHeader
   */
  static readonly PatchEquipmentRequestHeaderPath = '/api/Equipment/patch/equipmentrequestheader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEquipmentRequestHeader()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEquipmentRequestHeader$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: EquipmentRequestHeader;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.PatchEquipmentRequestHeaderPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEquipmentRequestHeader$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEquipmentRequestHeader(params?: { subscriptionKey?: string; APIURL?: string; body?: EquipmentRequestHeader }): Observable<boolean> {
    return this.patchEquipmentRequestHeader$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchEquipmentRequestDetail
   */
  static readonly PatchEquipmentRequestDetailPath = '/api/Equipment/patch/equipmentrequestdetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEquipmentRequestDetail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEquipmentRequestDetail$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: EquipmentRequestDetail;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.PatchEquipmentRequestDetailPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEquipmentRequestDetail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEquipmentRequestDetail(params?: { subscriptionKey?: string; APIURL?: string; body?: EquipmentRequestDetail }): Observable<boolean> {
    return this.patchEquipmentRequestDetail$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchEquipmentItem
   */
  static readonly PatchEquipmentItemPath = '/api/Equipment/patch/equipmentitems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEquipmentItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEquipmentItem$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: EquipmentItem }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.PatchEquipmentItemPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEquipmentItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEquipmentItem(params?: { subscriptionKey?: string; APIURL?: string; body?: EquipmentItem }): Observable<boolean> {
    return this.patchEquipmentItem$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchEquipmentTakeOn
   */
  static readonly PatchEquipmentTakeOnPath = '/api/Equipment/patch/equipmenttakeons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEquipmentTakeOn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEquipmentTakeOn$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: EquipmentTakeOn }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.PatchEquipmentTakeOnPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEquipmentTakeOn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEquipmentTakeOn(params?: { subscriptionKey?: string; APIURL?: string; body?: EquipmentTakeOn }): Observable<boolean> {
    return this.patchEquipmentTakeOn$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postEquipmentRequestHeader
   */
  static readonly PostEquipmentRequestHeaderPath = '/api/Equipment/post/equipmentrequestheader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postEquipmentRequestHeader()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEquipmentRequestHeader$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: EquipmentRequestHeader;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.PostEquipmentRequestHeaderPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postEquipmentRequestHeader$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEquipmentRequestHeader(params?: { subscriptionKey?: string; APIURL?: string; body?: EquipmentRequestHeader }): Observable<string> {
    return this.postEquipmentRequestHeader$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation postEquipmentRequestDetail
   */
  static readonly PostEquipmentRequestDetailPath = '/api/Equipment/post/equipmentrequestdetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postEquipmentRequestDetail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEquipmentRequestDetail$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: EquipmentRequestDetail;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.PostEquipmentRequestDetailPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postEquipmentRequestDetail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEquipmentRequestDetail(params?: { subscriptionKey?: string; APIURL?: string; body?: EquipmentRequestDetail }): Observable<string> {
    return this.postEquipmentRequestDetail$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation postEquipmentTakeOn
   */
  static readonly PostEquipmentTakeOnPath = '/api/Equipment/post/equipmenttakeons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postEquipmentTakeOn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEquipmentTakeOn$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: EquipmentTakeOn }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEquipmentService.PostEquipmentTakeOnPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postEquipmentTakeOn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEquipmentTakeOn(params?: { subscriptionKey?: string; APIURL?: string; body?: EquipmentTakeOn }): Observable<string> {
    return this.postEquipmentTakeOn$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }
}
