import { Injectable } from '@angular/core';
import { DelegationsSuccessionPlanSuccessor, EmployeeFunds, FleetChecklist, ImportHeader, TimeEntryActivityApiModel } from '@verde/api';
import { BehaviorSubject } from 'rxjs';
import { SidePanelWidth } from '../enums/sidepanel-width.enum';

@Injectable({
  providedIn: 'root',
})
export class VerdeApprovalService {
  // Show Sidebar
  private showSidebar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private sidebarMetadata$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  // Sidebar Type
  private sidebarType$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  // Sidebar size
  private sidebarSize$: BehaviorSubject<number> = new BehaviorSubject<number>(SidePanelWidth.Quater);

  // View Document Logic
  private documentBase64$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private documentName$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private approvalID$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private activityID$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private structureActivityID$: BehaviorSubject<TimeEntryActivityApiModel> = new BehaviorSubject<TimeEntryActivityApiModel>({});

  private selectedFund$: BehaviorSubject<EmployeeFunds> = new BehaviorSubject<EmployeeFunds>({});

  private selectedMeasure$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedGroup$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedSubgroup$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedTemplate$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedTemplateName$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedTemplateType$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedBenchmark$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedLegalEntityID$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedLegalEntityName$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedJson$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private activeReview$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedImportHeader$: BehaviorSubject<ImportHeader> = new BehaviorSubject<ImportHeader>({});

  private selectedFleetItem$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedFleetOdoData$: BehaviorSubject<{ checklists: FleetChecklist[]; isFleetManager: boolean }> = new BehaviorSubject<{
    checklists: FleetChecklist[];
    isFleetManager: boolean;
  }>({ checklists: [], isFleetManager: false });

  private fleetBackUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private availableFleetAccess$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private selectedQualification$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private miniStructure$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private parentItem$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private movementType$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedQualificationNavigation$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedOpportunity$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private dynamicFormData$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private employeeExitData$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private positionSeatData$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private employeeResignationData$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private appConfigs$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private selectedTask$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private titleTag$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private titleHeading$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private bookingAvailabilitySearch$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  private successorDetailsData$: BehaviorSubject<{ successor: DelegationsSuccessionPlanSuccessor; type: string }> = new BehaviorSubject<{
    successor: DelegationsSuccessionPlanSuccessor;
    type: string;
  }>({ successor: {}, type: '' });

  private useToReload$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  reloadObaerve = this.useToReload$.asObservable();

  constructor() {}

  // Show Sidebar logic
  getShowSidebar() {
    return this.showSidebar$.asObservable();
  }

  setShowSidebar(showHide: boolean) {
    this.showSidebar$.next(showHide);

    if (!showHide) {
      this.sidebarType$.next('');
    }

    if (this.showSidebar$.value == false) {
      setTimeout(() => {
        this.useToReload$.next(!this.useToReload$.value);
      }, 1000);
    }
  }

  // Metadata
  getSidebarMetadata() {
    return this.sidebarMetadata$.asObservable();
  }

  setSidebarMetadata(data: any) {
    this.sidebarMetadata$.next(data);
  }

  // Sidebar state logic
  toggleSidebarState() {
    this.showSidebar$.next(!this.showSidebar$.value);
  }

  isSidebarOpen() {
    return this.showSidebar$.value;
  }

  // Sidebar Type logic
  getSidebarType() {
    return this.sidebarType$.asObservable();
  }

  setSidebarType(type: string) {
    this.sidebarType$.next(type);
  }

  // Sidebar Size logic
  getSidebarSize() {
    return this.sidebarSize$.asObservable();
  }

  setSidebarSize(size: number) {
    this.sidebarSize$.next(size);
  }

  //Payroll
  getImportHeader() {
    return this.selectedImportHeader$.asObservable();
  }

  setImportHeader(importHeader: ImportHeader) {
    this.selectedImportHeader$.next(importHeader);
  }

  // View Document Logic
  getDocumentBase64() {
    return this.documentBase64$.asObservable();
  }

  setDocumentBase64(base64: string) {
    this.documentBase64$.next(base64);
  }

  getDocumentName() {
    return this.documentName$.asObservable();
  }

  setDocumentName(name: string) {
    this.documentName$.next(name);
  }

  getApprovalID() {
    return this.approvalID$.asObservable();
  }

  setApprovalID(id: string) {
    this.approvalID$.next(id);
  }

  getactivityID() {
    return this.activityID$.asObservable();
  }

  setactivityID(id: string) {
    this.activityID$.next(id);
  }

  getstructureActivity() {
    return this.structureActivityID$.asObservable();
  }

  setastructureActivity(id: TimeEntryActivityApiModel) {
    this.structureActivityID$.next(id);
  }

  getSelectedFund() {
    return this.selectedFund$.asObservable();
  }

  setSelectedFund(fund: EmployeeFunds) {
    this.selectedFund$.next(fund);
  }

  getSelectedMeasure() {
    return this.selectedMeasure$.asObservable();
  }

  setSelectedMeasure(measure: any) {
    this.selectedMeasure$.next(measure);
  }

  getSelectedGroup() {
    return this.selectedGroup$.asObservable();
  }

  setSelectedGroup(group: any) {
    this.selectedGroup$.next(group);
  }

  getSelectedSubgroup() {
    return this.selectedSubgroup$.asObservable();
  }

  setSelectedSubgroup(subgroup: any) {
    this.selectedSubgroup$.next(subgroup);
  }

  getSelectedTemplate() {
    return this.selectedTemplate$.asObservable();
  }

  setSelectedTemplate(subgroup: any) {
    this.selectedTemplate$.next(subgroup);
  }

  getSelectedTemplateName() {
    return this.selectedTemplateName$.asObservable();
  }

  setSelectedTemplateName(subgroup: any) {
    this.selectedTemplateName$.next(subgroup);
  }

  getSelectedTemplateType() {
    return this.selectedTemplateType$.asObservable();
  }

  setSelectedTemplateType(subgroup: any) {
    this.selectedTemplateType$.next(subgroup);
  }

  getSelectedBenchmark() {
    return this.selectedBenchmark$.asObservable();
  }

  setSelectedBenchmark(Benchmark: any) {
    this.selectedBenchmark$.next(Benchmark);
  }

  getSelectedLegalEntityID() {
    return this.selectedLegalEntityID$.asObservable();
  }

  setSelectedLegalEntityID(LegalEntityID: any) {
    this.selectedLegalEntityID$.next(LegalEntityID);
  }

  getSelectedLegalEntityName() {
    return this.selectedLegalEntityName$.asObservable();
  }

  setSelectedLegalEntityName(LegalEntity: any) {
    this.selectedLegalEntityName$.next(LegalEntity);
  }

  getSelectedDynamicFormJson() {
    return this.selectedJson$.asObservable();
  }

  setSelectedDynamicFormJson(json: string) {
    this.selectedJson$.next(json);
  }

  //PERFORMANCE REVIEW
  getSelectedActiveReview() {
    return this.activeReview$.asObservable();
  }

  setSelectedActiveReview(review: any) {
    this.activeReview$.next(review);
  }

  // Return Fleet Item
  getSelectedFleetItem() {
    return this.selectedFleetItem$.asObservable();
  }

  setSelectedFleetItem(item: any) {
    this.selectedFleetItem$.next(item);
  }

  // Odo update
  getSelectedFleetOdoData() {
    return this.selectedFleetOdoData$.asObservable();
  }

  setSelectedFleetOdoData(item: { checklists: FleetChecklist[]; isFleetManager: boolean }) {
    this.selectedFleetOdoData$.next(item);
  }

  getFleetBackUrl() {
    return this.fleetBackUrl$.asObservable();
  }

  setFleetBackUrl(item: any) {
    this.fleetBackUrl$.next(item);
  }

  // Entity Transfer
  getAvailableFleetAccess() {
    return this.availableFleetAccess$.asObservable();
  }

  setAvailableFleetAccess(items: any[]) {
    this.availableFleetAccess$.next(items);
  }

  // Return Qualification
  getSelectedQualification() {
    return this.selectedQualification$.asObservable();
  }

  // Return Qualification Navigation
  getSelectedQualificationNavigation() {
    return this.selectedQualificationNavigation$.asObservable();
  }

  setSelectedQualification(item: any, item2?: string) {
    this.selectedQualification$.next(item);
    this.selectedQualificationNavigation$.next(item2);
  }

  // Return Opportunity
  getSelectedOpportunity() {
    return this.selectedOpportunity$.asObservable();
  }

  setSelectedOpportunity(item: any) {
    this.selectedOpportunity$.next(item);
  }

  // Dynamic Form Data
  getDynamicFormData() {
    return this.dynamicFormData$.asObservable();
  }

  setDynamicFormData(data: any) {
    this.dynamicFormData$.next(data);
  }

  // Employee Exit
  getEmployeeExitData() {
    return this.employeeExitData$.asObservable();
  }

  setEmployeeExitData(data: any) {
    this.employeeExitData$.next(data);
  }

  // Employee Resignation
  getEmployeeResignationData() {
    return this.employeeResignationData$.asObservable();
  }

  setEmployeeResignationData(data: any) {
    this.employeeResignationData$.next(data);
  }

  // Structure
  getMiniStructure() {
    return this.miniStructure$.asObservable();
  }

  setMiniStructure(item: any) {
    this.miniStructure$.next(item);
  }

  getParentItem() {
    return this.parentItem$.asObservable();
  }

  setParentItem(item: any) {
    this.parentItem$.next(item);
  }

  getMovementType() {
    return this.movementType$.asObservable();
  }

  setMovementType(item: any) {
    this.movementType$.next(item);
  }

  getAppConfigs() {
    return this.appConfigs$.asObservable();
  }

  setAppConfigs(item: any) {
    this.appConfigs$.next(item);
  }

  //Onboarding
  getPositionSeat() {
    return this.positionSeatData$.asObservable();
  }

  setPositionSeat(item: any) {
    this.positionSeatData$.next(item);
  }

  //Bookings Search
  getBookingAvailabilitySearchDetails() {
    return this.bookingAvailabilitySearch$.asObservable();
  }

  setBookingAvailabilitySearchDetails(item: any) {
    this.bookingAvailabilitySearch$.next(item);
  }

  // Successor Details
  getSuccessorDetails() {
    return this.successorDetailsData$.asObservable();
  }

  setSuccessorDetails(item: { successor: DelegationsSuccessionPlanSuccessor; type: string }) {
    this.successorDetailsData$.next(item);
  }

  // Selected Task Details
  getSelectedTask() {
    return this.selectedTask$.asObservable();
  }

  setSelectedTask(item: any) {
    this.selectedTask$.next(item);
  }

  // Title variable
  getTitleTag() {
    return this.titleTag$.asObservable();
  }

  setTitleTag(titleTag: any) {
    this.titleTag$.next(titleTag);
  }

  getTitleHeading() {
    return this.titleHeading$.asObservable();
  }

  setTitleHeading(titleHeading: any) {
    this.titleHeading$.next(titleHeading);
  }
}
