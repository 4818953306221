/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ProcessesBaseService } from '../processes-base-service';
import { ProcessesApiConfiguration } from '../processes-api-configuration';
import { ProcessesStrictHttpResponse } from '../processes-strict-http-response';
import { ProcessesRequestBuilder } from '../processes-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProcessesBooleanStringValueTuple } from '../models/processes-boolean-string-value-tuple';
import { ProcessesCitizenshipStatus } from '../models/processes-citizenship-status';
import { ProcessesCustomerDetail } from '../models/processes-customer-detail';
import { ProcessesEmployeeExit } from '../models/processes-employee-exit';
import { ProcessesEmployeeResignation } from '../models/processes-employee-resignation';
import { ProcessesEmployeeType } from '../models/processes-employee-type';
import { ProcessesExecuteProcessInstanceCommand } from '../models/processes-execute-process-instance-command';
import { ProcessesExitReason } from '../models/processes-exit-reason';
import { ProcessesGenericProcessHandlerCommand } from '../models/processes-generic-process-handler-command';
import { ProcessesGetCitizenshipStatusQuery } from '../models/processes-get-citizenship-status-query';
import { ProcessesGetCustomerDetailQuery } from '../models/processes-get-customer-detail-query';
import { ProcessesGetEmployeeExitQuery } from '../models/processes-get-employee-exit-query';
import { ProcessesGetEmployeeOnboardingQuery } from '../models/processes-get-employee-onboarding-query';
import { ProcessesGetEmployeeResignationByIdQuery } from '../models/processes-get-employee-resignation-by-id-query';
import { ProcessesGetEmployeeResignationQuery } from '../models/processes-get-employee-resignation-query';
import { ProcessesGetEmployeeTypeQuery } from '../models/processes-get-employee-type-query';
import { ProcessesGetExitReasonQuery } from '../models/processes-get-exit-reason-query';
import { ProcessesGetLocalResidencyStatusQuery } from '../models/processes-get-local-residency-status-query';
import { ProcessesGetLocationQuery } from '../models/processes-get-location-query';
import { ProcessesGetPositionSeatsQuery } from '../models/processes-get-position-seats-query';
import { ProcessesGetProbationQuery } from '../models/processes-get-probation-query';
import { ProcessesGetProcessCountryQuery } from '../models/processes-get-process-country-query';
import { ProcessesGetProcessNationalityQuery } from '../models/processes-get-process-nationality-query';
import { ProcessesGetShiftTypeQuery } from '../models/processes-get-shift-type-query';
import { ProcessesGetStructureAppConfigQuery } from '../models/processes-get-structure-app-config-query';
import { ProcessesGetVerdeProductQuery } from '../models/processes-get-verde-product-query';
import { ProcessesLocalResidencyStatus } from '../models/processes-local-residency-status';
import { ProcessesLocation } from '../models/processes-location';
import { ProcessesPatchEmployeeExitCommand } from '../models/processes-patch-employee-exit-command';
import { ProcessesPatchEmployeeOnboardingCommand } from '../models/processes-patch-employee-onboarding-command';
import { ProcessesPatchEmployeeResignationCancelationCommand } from '../models/processes-patch-employee-resignation-cancelation-command';
import { ProcessesPatchEmployeeResignationCommand } from '../models/processes-patch-employee-resignation-command';
import { ProcessesPatchPositionSeatCommand } from '../models/processes-patch-position-seat-command';
import { ProcessesPositionSeatsQueryResult } from '../models/processes-position-seats-query-result';
import { ProcessesPostCustomerDetailCommand } from '../models/processes-post-customer-detail-command';
import { ProcessesPostEmployeeOnboardingCommand } from '../models/processes-post-employee-onboarding-command';
import { ProcessesPostPositionSeatEmployeeCommand } from '../models/processes-post-position-seat-employee-command';
import { ProcessesPostProcessesQueCommand } from '../models/processes-post-processes-que-command';
import { ProcessesPostSuccessionPlanUpdatesCommand } from '../models/processes-post-succession-plan-updates-command';
import { ProcessesProbation } from '../models/processes-probation';
import { ProcessesProcessCountry } from '../models/processes-process-country';
import { ProcessesProcessNationality } from '../models/processes-process-nationality';
import { ProcessesProcessesEmployee } from '../models/processes-processes-employee';
import { ProcessesShiftType } from '../models/processes-shift-type';
import { ProcessesStructureAppConfig } from '../models/processes-structure-app-config';
import { ProcessesValidateIdNumberQuery } from '../models/processes-validate-id-number-query';
import { ProcessesValidateTaxNumberQuery } from '../models/processes-validate-tax-number-query';
import { ProcessesVerdeAnnotations } from '../models/processes-verde-annotations';
import { ProcessesVerdeProduct } from '../models/processes-verde-product';
import { ProcessesBtProcessStatus } from '../models/processes-bt-process-status';
import { ProcessesBthrEmployeeResignationsState } from '../models/processes-bthr-employee-resignations-state';
import { ProcessesBthrTerminationreason } from '../models/processes-bthr-terminationreason';

@Injectable({
  providedIn: 'root',
})
export class ProcessesApiProcessesService extends ProcessesBaseService {
  constructor(config: ProcessesApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getEmployeeExit
   */
  static readonly GetEmployeeExitPath = '/api/Processes/get/employeeExit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeExit()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeExit$Response(params?: { body?: ProcessesGetEmployeeExitQuery }): Observable<ProcessesStrictHttpResponse<Array<ProcessesEmployeeExit>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetEmployeeExitPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesEmployeeExit>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeExit$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeExit(params?: { body?: ProcessesGetEmployeeExitQuery }): Observable<Array<ProcessesEmployeeExit>> {
    return this.getEmployeeExit$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<Array<ProcessesEmployeeExit>>) => r.body as Array<ProcessesEmployeeExit>),
    );
  }

  /**
   * Path part for operation getEmployeeResignation
   */
  static readonly GetEmployeeResignationPath = '/api/Processes/get/employeeResignation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeResignation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeResignation$Response(params?: {
    body?: ProcessesGetEmployeeResignationQuery;
  }): Observable<ProcessesStrictHttpResponse<Array<ProcessesEmployeeResignation>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetEmployeeResignationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesEmployeeResignation>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeResignation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeResignation(params?: { body?: ProcessesGetEmployeeResignationQuery }): Observable<Array<ProcessesEmployeeResignation>> {
    return this.getEmployeeResignation$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<Array<ProcessesEmployeeResignation>>) => r.body as Array<ProcessesEmployeeResignation>),
    );
  }

  /**
   * Path part for operation getEmployeeResignationById
   */
  static readonly GetEmployeeResignationByIdPath = '/api/Processes/get/employeeResignationById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeResignationById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeResignationById$Response(params?: {
    body?: ProcessesGetEmployeeResignationByIdQuery;
  }): Observable<ProcessesStrictHttpResponse<Array<ProcessesEmployeeResignation>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetEmployeeResignationByIdPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesEmployeeResignation>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeResignationById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeResignationById(params?: { body?: ProcessesGetEmployeeResignationByIdQuery }): Observable<Array<ProcessesEmployeeResignation>> {
    return this.getEmployeeResignationById$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<Array<ProcessesEmployeeResignation>>) => r.body as Array<ProcessesEmployeeResignation>),
    );
  }

  /**
   * Path part for operation getExitReason
   */
  static readonly GetExitReasonPath = '/api/Processes/get/exitReason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExitReason()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getExitReason$Response(params?: { body?: ProcessesGetExitReasonQuery }): Observable<ProcessesStrictHttpResponse<Array<ProcessesExitReason>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetExitReasonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesExitReason>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExitReason$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getExitReason(params?: { body?: ProcessesGetExitReasonQuery }): Observable<Array<ProcessesExitReason>> {
    return this.getExitReason$Response(params).pipe(map((r: ProcessesStrictHttpResponse<Array<ProcessesExitReason>>) => r.body as Array<ProcessesExitReason>));
  }

  /**
   * Path part for operation getLocation
   */
  static readonly GetLocationPath = '/api/Processes/get/location';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLocation$Response(params?: { body?: ProcessesGetLocationQuery }): Observable<ProcessesStrictHttpResponse<Array<ProcessesLocation>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetLocationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesLocation>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLocation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLocation(params?: { body?: ProcessesGetLocationQuery }): Observable<Array<ProcessesLocation>> {
    return this.getLocation$Response(params).pipe(map((r: ProcessesStrictHttpResponse<Array<ProcessesLocation>>) => r.body as Array<ProcessesLocation>));
  }

  /**
   * Path part for operation getProcessCountry
   */
  static readonly GetProcessCountryPath = '/api/Processes/get/processCountry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcessCountry()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProcessCountry$Response(params?: { body?: ProcessesGetProcessCountryQuery }): Observable<ProcessesStrictHttpResponse<Array<ProcessesProcessCountry>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetProcessCountryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesProcessCountry>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProcessCountry$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProcessCountry(params?: { body?: ProcessesGetProcessCountryQuery }): Observable<Array<ProcessesProcessCountry>> {
    return this.getProcessCountry$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<Array<ProcessesProcessCountry>>) => r.body as Array<ProcessesProcessCountry>),
    );
  }

  /**
   * Path part for operation getProcessNationality
   */
  static readonly GetProcessNationalityPath = '/api/Processes/get/processNationality';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProcessNationality()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProcessNationality$Response(params?: {
    body?: ProcessesGetProcessNationalityQuery;
  }): Observable<ProcessesStrictHttpResponse<Array<ProcessesProcessNationality>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetProcessNationalityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesProcessNationality>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProcessNationality$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProcessNationality(params?: { body?: ProcessesGetProcessNationalityQuery }): Observable<Array<ProcessesProcessNationality>> {
    return this.getProcessNationality$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<Array<ProcessesProcessNationality>>) => r.body as Array<ProcessesProcessNationality>),
    );
  }

  /**
   * Path part for operation getCitizenshipStatus
   */
  static readonly GetCitizenshipStatusPath = '/api/Processes/get/citizenshipStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCitizenshipStatus()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCitizenshipStatus$Response(params?: {
    body?: ProcessesGetCitizenshipStatusQuery;
  }): Observable<ProcessesStrictHttpResponse<Array<ProcessesCitizenshipStatus>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetCitizenshipStatusPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesCitizenshipStatus>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCitizenshipStatus$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCitizenshipStatus(params?: { body?: ProcessesGetCitizenshipStatusQuery }): Observable<Array<ProcessesCitizenshipStatus>> {
    return this.getCitizenshipStatus$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<Array<ProcessesCitizenshipStatus>>) => r.body as Array<ProcessesCitizenshipStatus>),
    );
  }

  /**
   * Path part for operation getProbation
   */
  static readonly GetProbationPath = '/api/Processes/get/probation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProbation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProbation$Response(params?: { body?: ProcessesGetProbationQuery }): Observable<ProcessesStrictHttpResponse<Array<ProcessesProbation>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetProbationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesProbation>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProbation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getProbation(params?: { body?: ProcessesGetProbationQuery }): Observable<Array<ProcessesProbation>> {
    return this.getProbation$Response(params).pipe(map((r: ProcessesStrictHttpResponse<Array<ProcessesProbation>>) => r.body as Array<ProcessesProbation>));
  }

  /**
   * Path part for operation getShiftType
   */
  static readonly GetShiftTypePath = '/api/Processes/get/shifttype';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShiftType()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getShiftType$Response(params?: { body?: ProcessesGetShiftTypeQuery }): Observable<ProcessesStrictHttpResponse<Array<ProcessesShiftType>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetShiftTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesShiftType>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getShiftType$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getShiftType(params?: { body?: ProcessesGetShiftTypeQuery }): Observable<Array<ProcessesShiftType>> {
    return this.getShiftType$Response(params).pipe(map((r: ProcessesStrictHttpResponse<Array<ProcessesShiftType>>) => r.body as Array<ProcessesShiftType>));
  }

  /**
   * Path part for operation getEmployeeType
   */
  static readonly GetEmployeeTypePath = '/api/Processes/get/EmployeeType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeType()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeType$Response(params?: { body?: ProcessesGetEmployeeTypeQuery }): Observable<ProcessesStrictHttpResponse<Array<ProcessesEmployeeType>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetEmployeeTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesEmployeeType>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeType$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeType(params?: { body?: ProcessesGetEmployeeTypeQuery }): Observable<Array<ProcessesEmployeeType>> {
    return this.getEmployeeType$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<Array<ProcessesEmployeeType>>) => r.body as Array<ProcessesEmployeeType>),
    );
  }

  /**
   * Path part for operation getLocalResidencyStatus
   */
  static readonly GetLocalResidencyStatusPath = '/api/Processes/get/localResidencyStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocalResidencyStatus()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLocalResidencyStatus$Response(params?: {
    body?: ProcessesGetLocalResidencyStatusQuery;
  }): Observable<ProcessesStrictHttpResponse<Array<ProcessesLocalResidencyStatus>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetLocalResidencyStatusPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesLocalResidencyStatus>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLocalResidencyStatus$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLocalResidencyStatus(params?: { body?: ProcessesGetLocalResidencyStatusQuery }): Observable<Array<ProcessesLocalResidencyStatus>> {
    return this.getLocalResidencyStatus$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<Array<ProcessesLocalResidencyStatus>>) => r.body as Array<ProcessesLocalResidencyStatus>),
    );
  }

  /**
   * Path part for operation postProcessQue
   */
  static readonly PostProcessQuePath = '/api/Processes/post/processQue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postProcessQue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postProcessQue$Response(params?: { body?: ProcessesPostProcessesQueCommand }): Observable<ProcessesStrictHttpResponse<string>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.PostProcessQuePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postProcessQue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postProcessQue(params?: { body?: ProcessesPostProcessesQueCommand }): Observable<string> {
    return this.postProcessQue$Response(params).pipe(map((r: ProcessesStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation postEmployeeResignation
   */
  static readonly PostEmployeeResignationPath = '/api/Processes/post/employeeResignation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postEmployeeResignation()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postEmployeeResignation$Response(params?: {
    body?: {
      bt_employeeresignationsid?: string;
      bthr_terminationnoticedate?: string;
      bthr_resignationdate?: string;
      bt_fullname?: string;
      bthr_lastworkday?: string;
      bthr_terminationreason?: ProcessesBthrTerminationreason;
      bthr_terminationcomment?: string;
      bt_statecode?: ProcessesBthrEmployeeResignationsState;
      LegalEntityID?: string;
      EmployeeID?: string;
      bt_processedBy?: string;
      bt_processLegalEntity?: string;
      bt_ProcessStatus?: ProcessesBtProcessStatus;
      'bt_employee.Id'?: string;
      'bt_employee.primaryName'?: string;
      'bt_legalentity.bthr_legalentitiesid'?: string;
      'bt_legalentity.bthr_legalentityname'?: string;
      'bt_legalentity.bthr_country'?: string;
      filesToUpload?: Array<Blob>;
      annotations?: Array<ProcessesVerdeAnnotations>;
    };
  }): Observable<ProcessesStrictHttpResponse<boolean>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.PostEmployeeResignationPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as ProcessesStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postEmployeeResignation$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postEmployeeResignation(params?: {
    body?: {
      bt_employeeresignationsid?: string;
      bthr_terminationnoticedate?: string;
      bthr_resignationdate?: string;
      bt_fullname?: string;
      bthr_lastworkday?: string;
      bthr_terminationreason?: ProcessesBthrTerminationreason;
      bthr_terminationcomment?: string;
      bt_statecode?: ProcessesBthrEmployeeResignationsState;
      LegalEntityID?: string;
      EmployeeID?: string;
      bt_processedBy?: string;
      bt_processLegalEntity?: string;
      bt_ProcessStatus?: ProcessesBtProcessStatus;
      'bt_employee.Id'?: string;
      'bt_employee.primaryName'?: string;
      'bt_legalentity.bthr_legalentitiesid'?: string;
      'bt_legalentity.bthr_legalentityname'?: string;
      'bt_legalentity.bthr_country'?: string;
      filesToUpload?: Array<Blob>;
      annotations?: Array<ProcessesVerdeAnnotations>;
    };
  }): Observable<boolean> {
    return this.postEmployeeResignation$Response(params).pipe(map((r: ProcessesStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postEmployeeExit
   */
  static readonly PostEmployeeExitPath = '/api/Processes/post/employeeExit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postEmployeeExit()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postEmployeeExit$Response(params?: {
    body?: {
      bt_employeeexitdetailsid?: string;
      bt_noticedate?: string;
      bt_exitdate?: string;
      bt_comment?: string;
      bt_fullname?: string;
      LegalEntityID?: string;
      EmployeeID?: string;
      ExitReasonID?: string;
      bt_processedBy?: string;
      bt_processLegalEntity?: string;
      bt_ProcessStatus?: ProcessesBtProcessStatus;
      noticeType?: ProcessesBthrTerminationreason;
      'bt_exitreason.statuscode'?: boolean;
      'bt_exitreason.bt_verdeemployeeexitreasonsid'?: string;
      'bt_exitreason.bt_verdeemployeeexitreasons'?: string;
      'bt_exitreason.employeeNotice'?: boolean;
      'bt_exitreason.bt_employeenoticetype'?: ProcessesBthrTerminationreason;
      'bt_employee.Id'?: string;
      'bt_employee.primaryName'?: string;
      'bt_legalentity.bthr_legalentitiesid'?: string;
      'bt_legalentity.bthr_legalentityname'?: string;
      'bt_legalentity.bthr_country'?: string;
      filesToUpload?: Array<Blob>;
      annotations?: Array<ProcessesVerdeAnnotations>;
    };
  }): Observable<ProcessesStrictHttpResponse<boolean>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.PostEmployeeExitPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as ProcessesStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postEmployeeExit$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  postEmployeeExit(params?: {
    body?: {
      bt_employeeexitdetailsid?: string;
      bt_noticedate?: string;
      bt_exitdate?: string;
      bt_comment?: string;
      bt_fullname?: string;
      LegalEntityID?: string;
      EmployeeID?: string;
      ExitReasonID?: string;
      bt_processedBy?: string;
      bt_processLegalEntity?: string;
      bt_ProcessStatus?: ProcessesBtProcessStatus;
      noticeType?: ProcessesBthrTerminationreason;
      'bt_exitreason.statuscode'?: boolean;
      'bt_exitreason.bt_verdeemployeeexitreasonsid'?: string;
      'bt_exitreason.bt_verdeemployeeexitreasons'?: string;
      'bt_exitreason.employeeNotice'?: boolean;
      'bt_exitreason.bt_employeenoticetype'?: ProcessesBthrTerminationreason;
      'bt_employee.Id'?: string;
      'bt_employee.primaryName'?: string;
      'bt_legalentity.bthr_legalentitiesid'?: string;
      'bt_legalentity.bthr_legalentityname'?: string;
      'bt_legalentity.bthr_country'?: string;
      filesToUpload?: Array<Blob>;
      annotations?: Array<ProcessesVerdeAnnotations>;
    };
  }): Observable<boolean> {
    return this.postEmployeeExit$Response(params).pipe(map((r: ProcessesStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postEmployeeOnboarding
   */
  static readonly PostEmployeeOnboardingPath = '/api/Processes/post/employeeOnboarding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postEmployeeOnboarding()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEmployeeOnboarding$Response(params?: { body?: ProcessesPostEmployeeOnboardingCommand }): Observable<ProcessesStrictHttpResponse<string>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.PostEmployeeOnboardingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postEmployeeOnboarding$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEmployeeOnboarding(params?: { body?: ProcessesPostEmployeeOnboardingCommand }): Observable<string> {
    return this.postEmployeeOnboarding$Response(params).pipe(map((r: ProcessesStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation positionSeatEmployee
   */
  static readonly PositionSeatEmployeePath = '/api/Processes/post/positionSeatEmployee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `positionSeatEmployee()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  positionSeatEmployee$Response(params?: { body?: ProcessesPostPositionSeatEmployeeCommand }): Observable<ProcessesStrictHttpResponse<string>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.PositionSeatEmployeePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `positionSeatEmployee$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  positionSeatEmployee(params?: { body?: ProcessesPostPositionSeatEmployeeCommand }): Observable<string> {
    return this.positionSeatEmployee$Response(params).pipe(map((r: ProcessesStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation getCustomerDetail
   */
  static readonly GetCustomerDetailPath = '/api/Processes/get/customerdetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerDetail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCustomerDetail$Response(params?: { body?: ProcessesGetCustomerDetailQuery }): Observable<ProcessesStrictHttpResponse<Array<ProcessesCustomerDetail>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetCustomerDetailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesCustomerDetail>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerDetail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCustomerDetail(params?: { body?: ProcessesGetCustomerDetailQuery }): Observable<Array<ProcessesCustomerDetail>> {
    return this.getCustomerDetail$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<Array<ProcessesCustomerDetail>>) => r.body as Array<ProcessesCustomerDetail>),
    );
  }

  /**
   * Path part for operation postCustomerDetail
   */
  static readonly PostCustomerDetailPath = '/api/Processes/post/customerdetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postCustomerDetail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postCustomerDetail$Response(params?: { body?: ProcessesPostCustomerDetailCommand }): Observable<ProcessesStrictHttpResponse<string>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.PostCustomerDetailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postCustomerDetail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postCustomerDetail(params?: { body?: ProcessesPostCustomerDetailCommand }): Observable<string> {
    return this.postCustomerDetail$Response(params).pipe(map((r: ProcessesStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation getVerdeProduct
   */
  static readonly GetVerdeProductPath = '/api/Processes/get/verdeproduct';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeProduct()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVerdeProduct$Response(params?: { body?: ProcessesGetVerdeProductQuery }): Observable<ProcessesStrictHttpResponse<Array<ProcessesVerdeProduct>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetVerdeProductPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesVerdeProduct>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeProduct$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVerdeProduct(params?: { body?: ProcessesGetVerdeProductQuery }): Observable<Array<ProcessesVerdeProduct>> {
    return this.getVerdeProduct$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<Array<ProcessesVerdeProduct>>) => r.body as Array<ProcessesVerdeProduct>),
    );
  }

  /**
   * Path part for operation patchEmployeeExit
   */
  static readonly PatchEmployeeExitPath = '/api/Processes/patch/employeeExit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEmployeeExit()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeExit$Response(params?: { body?: ProcessesPatchEmployeeExitCommand }): Observable<ProcessesStrictHttpResponse<boolean>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.PatchEmployeeExitPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as ProcessesStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEmployeeExit$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeExit(params?: { body?: ProcessesPatchEmployeeExitCommand }): Observable<boolean> {
    return this.patchEmployeeExit$Response(params).pipe(map((r: ProcessesStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchEmployeeOnboarding
   */
  static readonly PatchEmployeeOnboardingPath = '/api/Processes/patch/employeeOnboarding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEmployeeOnboarding()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeOnboarding$Response(params?: { body?: ProcessesPatchEmployeeOnboardingCommand }): Observable<ProcessesStrictHttpResponse<boolean>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.PatchEmployeeOnboardingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as ProcessesStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEmployeeOnboarding$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeOnboarding(params?: { body?: ProcessesPatchEmployeeOnboardingCommand }): Observable<boolean> {
    return this.patchEmployeeOnboarding$Response(params).pipe(map((r: ProcessesStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchEmployeeResignation
   */
  static readonly PatchEmployeeResignationPath = '/api/Processes/patch/employeeResignation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEmployeeResignation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeResignation$Response(params?: { body?: ProcessesPatchEmployeeResignationCommand }): Observable<ProcessesStrictHttpResponse<boolean>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.PatchEmployeeResignationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as ProcessesStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEmployeeResignation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeResignation(params?: { body?: ProcessesPatchEmployeeResignationCommand }): Observable<boolean> {
    return this.patchEmployeeResignation$Response(params).pipe(map((r: ProcessesStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchEmployeeResignationCancelation
   */
  static readonly PatchEmployeeResignationCancelationPath = '/api/Processes/patch/employeeResignationCancelation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEmployeeResignationCancelation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeResignationCancelation$Response(params?: {
    body?: ProcessesPatchEmployeeResignationCancelationCommand;
  }): Observable<ProcessesStrictHttpResponse<boolean>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.PatchEmployeeResignationCancelationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as ProcessesStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEmployeeResignationCancelation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchEmployeeResignationCancelation(params?: { body?: ProcessesPatchEmployeeResignationCancelationCommand }): Observable<boolean> {
    return this.patchEmployeeResignationCancelation$Response(params).pipe(map((r: ProcessesStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchPositionSeat
   */
  static readonly PatchPositionSeatPath = '/api/Processes/patch/positionSeat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPositionSeat()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPositionSeat$Response(params?: { body?: ProcessesPatchPositionSeatCommand }): Observable<ProcessesStrictHttpResponse<boolean>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.PatchPositionSeatPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as ProcessesStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchPositionSeat$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPositionSeat(params?: { body?: ProcessesPatchPositionSeatCommand }): Observable<boolean> {
    return this.patchPositionSeat$Response(params).pipe(map((r: ProcessesStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postSuccessionPlanUpdates
   */
  static readonly PostSuccessionPlanUpdatesPath = '/api/Processes/post/PostSuccessionPlanUpdates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postSuccessionPlanUpdates()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postSuccessionPlanUpdates$Response(params?: { body?: ProcessesPostSuccessionPlanUpdatesCommand }): Observable<ProcessesStrictHttpResponse<boolean>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.PostSuccessionPlanUpdatesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as ProcessesStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postSuccessionPlanUpdates$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postSuccessionPlanUpdates(params?: { body?: ProcessesPostSuccessionPlanUpdatesCommand }): Observable<boolean> {
    return this.postSuccessionPlanUpdates$Response(params).pipe(map((r: ProcessesStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getPositionSeats
   */
  static readonly GetPositionSeatsPath = '/api/Processes/positionSeats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPositionSeats()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPositionSeats$Response(params?: { body?: ProcessesGetPositionSeatsQuery }): Observable<ProcessesStrictHttpResponse<ProcessesPositionSeatsQueryResult>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetPositionSeatsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<ProcessesPositionSeatsQueryResult>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPositionSeats$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getPositionSeats(params?: { body?: ProcessesGetPositionSeatsQuery }): Observable<ProcessesPositionSeatsQueryResult> {
    return this.getPositionSeats$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<ProcessesPositionSeatsQueryResult>) => r.body as ProcessesPositionSeatsQueryResult),
    );
  }

  /**
   * Path part for operation getEmployeeOnboarding
   */
  static readonly GetEmployeeOnboardingPath = '/api/Processes/employeeOnboarding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeOnboarding()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeOnboarding$Response(params?: {
    body?: ProcessesGetEmployeeOnboardingQuery;
  }): Observable<ProcessesStrictHttpResponse<Array<ProcessesProcessesEmployee>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetEmployeeOnboardingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesProcessesEmployee>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeOnboarding$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeOnboarding(params?: { body?: ProcessesGetEmployeeOnboardingQuery }): Observable<Array<ProcessesProcessesEmployee>> {
    return this.getEmployeeOnboarding$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<Array<ProcessesProcessesEmployee>>) => r.body as Array<ProcessesProcessesEmployee>),
    );
  }

  /**
   * Path part for operation validateIdNumber
   */
  static readonly ValidateIdNumberPath = '/api/Processes/validateidnumber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateIdNumber()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  validateIdNumber$Response(params?: { body?: ProcessesValidateIdNumberQuery }): Observable<ProcessesStrictHttpResponse<string>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.ValidateIdNumberPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validateIdNumber$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  validateIdNumber(params?: { body?: ProcessesValidateIdNumberQuery }): Observable<string> {
    return this.validateIdNumber$Response(params).pipe(map((r: ProcessesStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation validateTaxNumber
   */
  static readonly ValidateTaxNumberPath = '/api/Processes/validatetaxnumber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateTaxNumber()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  validateTaxNumber$Response(params?: { body?: ProcessesValidateTaxNumberQuery }): Observable<ProcessesStrictHttpResponse<string>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.ValidateTaxNumberPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `validateTaxNumber$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  validateTaxNumber(params?: { body?: ProcessesValidateTaxNumberQuery }): Observable<string> {
    return this.validateTaxNumber$Response(params).pipe(map((r: ProcessesStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation uploadOnboardingFile
   */
  static readonly UploadOnboardingFilePath = '/api/Processes/uploadOnboardingfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadOnboardingFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadOnboardingFile$Response(params?: {
    body?: {
      file?: Blob;
      recordID?: string;
      entityName?: string;
      columnName?: string;
    };
  }): Observable<ProcessesStrictHttpResponse<string>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.UploadOnboardingFilePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadOnboardingFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadOnboardingFile(params?: {
    body?: {
      file?: Blob;
      recordID?: string;
      entityName?: string;
      columnName?: string;
    };
  }): Observable<string> {
    return this.uploadOnboardingFile$Response(params).pipe(map((r: ProcessesStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation getStructureAppConfig
   */
  static readonly GetStructureAppConfigPath = '/api/Processes/get/structureAppConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStructureAppConfig()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getStructureAppConfig$Response(params?: {
    body?: ProcessesGetStructureAppConfigQuery;
  }): Observable<ProcessesStrictHttpResponse<Array<ProcessesStructureAppConfig>>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GetStructureAppConfigPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<Array<ProcessesStructureAppConfig>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStructureAppConfig$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getStructureAppConfig(params?: { body?: ProcessesGetStructureAppConfigQuery }): Observable<Array<ProcessesStructureAppConfig>> {
    return this.getStructureAppConfig$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<Array<ProcessesStructureAppConfig>>) => r.body as Array<ProcessesStructureAppConfig>),
    );
  }

  /**
   * Path part for operation genericProcessHandler
   */
  static readonly GenericProcessHandlerPath = '/api/Processes/genericProcessHandler';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `genericProcessHandler()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  genericProcessHandler$Response(params?: {
    body?: ProcessesGenericProcessHandlerCommand;
  }): Observable<ProcessesStrictHttpResponse<ProcessesBooleanStringValueTuple>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.GenericProcessHandlerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<ProcessesBooleanStringValueTuple>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `genericProcessHandler$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  genericProcessHandler(params?: { body?: ProcessesGenericProcessHandlerCommand }): Observable<ProcessesBooleanStringValueTuple> {
    return this.genericProcessHandler$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<ProcessesBooleanStringValueTuple>) => r.body as ProcessesBooleanStringValueTuple),
    );
  }

  /**
   * Path part for operation executeProcessInstanceHandler
   */
  static readonly ExecuteProcessInstanceHandlerPath = '/api/Processes/ExecuteProcessInstanceHandler';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeProcessInstanceHandler()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  executeProcessInstanceHandler$Response(params?: {
    body?: ProcessesExecuteProcessInstanceCommand;
  }): Observable<ProcessesStrictHttpResponse<ProcessesBooleanStringValueTuple>> {
    const rb = new ProcessesRequestBuilder(this.rootUrl, ProcessesApiProcessesService.ExecuteProcessInstanceHandlerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as ProcessesStrictHttpResponse<ProcessesBooleanStringValueTuple>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `executeProcessInstanceHandler$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  executeProcessInstanceHandler(params?: { body?: ProcessesExecuteProcessInstanceCommand }): Observable<ProcessesBooleanStringValueTuple> {
    return this.executeProcessInstanceHandler$Response(params).pipe(
      map((r: ProcessesStrictHttpResponse<ProcessesBooleanStringValueTuple>) => r.body as ProcessesBooleanStringValueTuple),
    );
  }
}
