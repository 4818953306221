/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvEmployeeSeats } from '../models/dv-employee-seats';

@Injectable({
  providedIn: 'root',
})
export class SharedApiEmployeeSeatsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getEmployeeSeats
   */
  static readonly GetEmployeeSeatsPath = '/api/EmployeeSeats/{seatid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeSeats()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeSeats$Response(params: { subscriptionKey?: string; APIURL?: string; seatid: string }): Observable<StrictHttpResponse<DvEmployeeSeats>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiEmployeeSeatsService.GetEmployeeSeatsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('seatid', params.seatid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DvEmployeeSeats>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeSeats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeeSeats(params: { subscriptionKey?: string; APIURL?: string; seatid: string }): Observable<DvEmployeeSeats> {
    return this.getEmployeeSeats$Response(params).pipe(map((r: StrictHttpResponse<DvEmployeeSeats>) => r.body as DvEmployeeSeats));
  }
}
