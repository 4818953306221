import { Action } from '@ngrx/store';
import { EmployeeTimeOffCycleSummary } from '@verde/api';

export enum TimeOffActionTypes {
  // Get All TimeOff
  TIMEOFF_GET_ALL = '[TIMEOFF] Get All',
  TIMEOFF_GET_ALL_SUCCESS = '[TIMEOFF] Get All Success',
  TIMEOFF_GET_ALL_ERROR = '[TIMEOFF] Get All Error',

  // Clear TimeOff
  TIMEOFF_CLEAR = '[TIMEOFF] Clear',
}

// Get All TimeOff
export class TimeOffGetAll implements Action {
  readonly type = TimeOffActionTypes.TIMEOFF_GET_ALL;
  constructor(public employeeID: string, public legalEntityID: string) {}
}

export class TimeOffGetAllSuccess implements Action {
  readonly type = TimeOffActionTypes.TIMEOFF_GET_ALL_SUCCESS;
  constructor(public payload: EmployeeTimeOffCycleSummary[]) {}
}

export class TimeOffGetAllError implements Action {
  readonly type = TimeOffActionTypes.TIMEOFF_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// Clear TimeOff
export class TimeOffClear implements Action {
  readonly type = TimeOffActionTypes.TIMEOFF_CLEAR;
  constructor(public payload: any) {}
}

export type TimeOffActions =
  // Get All TimeOff
  | TimeOffGetAll
  | TimeOffGetAllSuccess
  | TimeOffGetAllError
  // Clear TimeOff
  | TimeOffClear;
