/* tslint:disable */
/* eslint-disable */
export interface PeerReviewFeedback {
  _bt_employee_value?: null | string;
  bt_date?: null | string;
  bt_displaysubgroup?: null | boolean;
  bt_feedbackcompleted?: null | boolean;
  bt_groupsequence?: null | number;
  bt_maxvalue?: null | number;
  bt_measuresequence?: null | number;
  bt_minvalue?: null | number;
  bt_name?: null | string;
  bt_peerreviewfeedbackid?: null | string;
  bt_rating?: null | number;
  bt_response?: null | string;
  bt_responsetype?: null | number;
  bt_subgroupsequence?: null | number;
  odataFeedbackCompleted?: null | string;
  odataGroup?: null | string;
  odataMeasure?: null | string;
  odataResponseType?: null | string;
  odataSubGroup?: null | string;
  odataTemplate?: null | string;
}
