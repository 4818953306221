/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { FleetBaseService } from '../fleet-base-service';
import { FleetApiConfiguration } from '../fleet-api-configuration';
import { FleetStrictHttpResponse } from '../fleet-strict-http-response';
import { FleetRequestBuilder } from '../fleet-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FleetCreateUpdateFleetEntityTransferCommand } from '../models/fleet-create-update-fleet-entity-transfer-command';
import { FleetDriversAndBarcodeScannerCommand } from '../models/fleet-drivers-and-barcode-scanner-command';
import { FleetFleetMovementCancellationType } from '../models/fleet-fleet-movement-cancellation-type';
import { FleetGetFleetMovementCancellationTypeQuery } from '../models/fleet-get-fleet-movement-cancellation-type-query';
import { FleetPostFleetItemRollUpCountCommand } from '../models/fleet-post-fleet-item-roll-up-count-command';
import { FleetPostFleetMovementCancellationCommand } from '../models/fleet-post-fleet-movement-cancellation-command';
import { FleetPostFleetServiceManualUpdateCommand } from '../models/fleet-post-fleet-service-manual-update-command';

@Injectable({
  providedIn: 'root',
})
export class FleetApiFleetService extends FleetBaseService {
  constructor(config: FleetApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getFleetMovementCancellationTypes
   */
  static readonly GetFleetMovementCancellationTypesPath = '/api/Fleet/cancellationtypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFleetMovementCancellationTypes()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getFleetMovementCancellationTypes$Response(params?: {
    tenantId?: string;
    body?: FleetGetFleetMovementCancellationTypeQuery;
  }): Observable<FleetStrictHttpResponse<Array<FleetFleetMovementCancellationType>>> {
    const rb = new FleetRequestBuilder(this.rootUrl, FleetApiFleetService.GetFleetMovementCancellationTypesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as FleetStrictHttpResponse<Array<FleetFleetMovementCancellationType>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFleetMovementCancellationTypes$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getFleetMovementCancellationTypes(params?: {
    tenantId?: string;
    body?: FleetGetFleetMovementCancellationTypeQuery;
  }): Observable<Array<FleetFleetMovementCancellationType>> {
    return this.getFleetMovementCancellationTypes$Response(params).pipe(
      map((r: FleetStrictHttpResponse<Array<FleetFleetMovementCancellationType>>) => r.body as Array<FleetFleetMovementCancellationType>),
    );
  }

  /**
   * Path part for operation postFleetMovementCancellations
   */
  static readonly PostFleetMovementCancellationsPath = '/api/Fleet/post/cancellations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFleetMovementCancellations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetMovementCancellations$Response(params?: {
    tenantId?: string;
    body?: FleetPostFleetMovementCancellationCommand;
  }): Observable<FleetStrictHttpResponse<boolean>> {
    const rb = new FleetRequestBuilder(this.rootUrl, FleetApiFleetService.PostFleetMovementCancellationsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as FleetStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postFleetMovementCancellations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetMovementCancellations(params?: { tenantId?: string; body?: FleetPostFleetMovementCancellationCommand }): Observable<boolean> {
    return this.postFleetMovementCancellations$Response(params).pipe(map((r: FleetStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation createUpdateFleetEntityTransfer
   */
  static readonly CreateUpdateFleetEntityTransferPath = '/api/Fleet/post/entitytranfser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUpdateFleetEntityTransfer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUpdateFleetEntityTransfer$Response(params?: {
    tenantId?: string;
    body?: FleetCreateUpdateFleetEntityTransferCommand;
  }): Observable<FleetStrictHttpResponse<string>> {
    const rb = new FleetRequestBuilder(this.rootUrl, FleetApiFleetService.CreateUpdateFleetEntityTransferPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as FleetStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createUpdateFleetEntityTransfer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUpdateFleetEntityTransfer(params?: { tenantId?: string; body?: FleetCreateUpdateFleetEntityTransferCommand }): Observable<string> {
    return this.createUpdateFleetEntityTransfer$Response(params).pipe(map((r: FleetStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation scanBarcode
   */
  static readonly ScanBarcodePath = '/api/Fleet/ScanBarcode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanBarcode()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scanBarcode$Response(params?: { tenantId?: string; body?: FleetDriversAndBarcodeScannerCommand }): Observable<FleetStrictHttpResponse<string>> {
    const rb = new FleetRequestBuilder(this.rootUrl, FleetApiFleetService.ScanBarcodePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as FleetStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `scanBarcode$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scanBarcode(params?: { tenantId?: string; body?: FleetDriversAndBarcodeScannerCommand }): Observable<string> {
    return this.scanBarcode$Response(params).pipe(map((r: FleetStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation scanDrivers
   */
  static readonly ScanDriversPath = '/api/Fleet/ScanDrivers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `scanDrivers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scanDrivers$Response(params?: { tenantId?: string; body?: FleetDriversAndBarcodeScannerCommand }): Observable<FleetStrictHttpResponse<string>> {
    const rb = new FleetRequestBuilder(this.rootUrl, FleetApiFleetService.ScanDriversPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as FleetStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `scanDrivers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  scanDrivers(params?: { tenantId?: string; body?: FleetDriversAndBarcodeScannerCommand }): Observable<string> {
    return this.scanDrivers$Response(params).pipe(map((r: FleetStrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation postFleetItemRollUpCount
   */
  static readonly PostFleetItemRollUpCountPath = '/api/Fleet/post/rollupcount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFleetItemRollUpCount()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetItemRollUpCount$Response(params?: { tenantId?: string; body?: FleetPostFleetItemRollUpCountCommand }): Observable<FleetStrictHttpResponse<boolean>> {
    const rb = new FleetRequestBuilder(this.rootUrl, FleetApiFleetService.PostFleetItemRollUpCountPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as FleetStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postFleetItemRollUpCount$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetItemRollUpCount(params?: { tenantId?: string; body?: FleetPostFleetItemRollUpCountCommand }): Observable<boolean> {
    return this.postFleetItemRollUpCount$Response(params).pipe(map((r: FleetStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postFleetServiceManualUpdate
   */
  static readonly PostFleetServiceManualUpdatePath = '/api/Fleet/post/servicemanualupdate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postFleetServiceManualUpdate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetServiceManualUpdate$Response(params?: {
    tenantId?: string;
    body?: FleetPostFleetServiceManualUpdateCommand;
  }): Observable<FleetStrictHttpResponse<boolean>> {
    const rb = new FleetRequestBuilder(this.rootUrl, FleetApiFleetService.PostFleetServiceManualUpdatePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as FleetStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postFleetServiceManualUpdate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postFleetServiceManualUpdate(params?: { tenantId?: string; body?: FleetPostFleetServiceManualUpdateCommand }): Observable<boolean> {
    return this.postFleetServiceManualUpdate$Response(params).pipe(map((r: FleetStrictHttpResponse<boolean>) => r.body as boolean));
  }
}
