import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { State } from '@progress/kendo-data-query';
import {
  QualificationsApiQualificationsService,
  QualificationsBtEmployeeEducationalQualificationRenewal,
  QualificationsBthrAccreditationAuthority,
  QualificationsBthrCountry,
  QualificationsBthrEducationalInstitution,
  QualificationsBthrEmployeeEducationalQualification,
  QualificationsBthrInstitutiontype,
  QualificationsBthrQualification,
  QualificationsBthrQualificationType,
  QualificationsBthrRecordStatus,
  QualificationsBtQualficationInstitutionLink,
  QualificationsDataQuery,
} from '@verde/api';
import { DeviceTypeService, UserService, ViewSDKClient } from '@verde/core';
import { VerdeApprovalService } from '@verde/shared';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { Subject, take, takeUntil } from 'rxjs';
import { AccreditationAuthorityService } from '../../../../../../../../apps/verde/src/app/features/profile2/services/accreditation-authority.service';
import { CountryService } from '../../../../../../../../apps/verde/src/app/features/profile2/services/country.service';
import { EducationalInstitutionService } from '../../../../../../../../apps/verde/src/app/features/profile2/services/educational-institution.service';
import { EmployeeEducationalQualificationRenewService } from '../../../../../../../../apps/verde/src/app/features/profile2/services/employee-educational-qualification-renewal.service';
import { EmployeeEducationalQualificationService } from '../../../../../../../../apps/verde/src/app/features/profile2/services/employee-educational-qualification.service';
import { QualificationInstitutionLinkService } from '../../../../../../../../apps/verde/src/app/features/profile2/services/qualification-institution-link.service';
import { QualificationService } from '../../../../../../../../apps/verde/src/app/features/profile2/services/qualification.service';

type enumToArrayHolder = { key: string; value: string };
@Component({
  selector: 'verde-qualifications',
  templateUrl: './qualifications.component.html',
  styleUrls: ['./qualifications.component.scss'],
})
export class QualificationsComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  selectedEmployeeEducationalQualification: QualificationsBthrEmployeeEducationalQualification;
  selectedNavigationOption: string;
  qualificationsBthrQualificationTypeArray: enumToArrayHolder[];
  qualificationsBthrQualificationType = [];
  qualification: QualificationsBthrQualification[];
  qualificationDisplay: QualificationsBthrQualification[];
  institutiontype = [];
  link: QualificationsBtQualficationInstitutionLink[];
  linkDisplay: QualificationsBtQualficationInstitutionLink[];
  linkItem = [];
  institution: QualificationsBthrEducationalInstitution[];
  institutionDisplay: QualificationsBthrEducationalInstitution[];
  country: QualificationsBthrCountry[];
  accreditationAuthority: QualificationsBthrAccreditationAuthority[];
  qualificationRenewal: QualificationsBtEmployeeEducationalQualificationRenewal;
  institutionItem = [];
  //fileData = { stringData: "", fileName: "" }
  //editableEDIT= true;
  //editableADD= true;

  createItemSidepanel = '';
  //For data binding

  //EMPLOYEE QUALIFICATION
  eQInstution: QualificationsBthrEducationalInstitution;
  eQQualificationType: QualificationsBthrQualificationType;
  eQQualification: QualificationsBthrQualification;
  eQDateAttained: Date = new Date();
  eQExpiryDate: Date = new Date();
  eQLevel = 0;
  eQPoints = 0;
  eQfile: File[] = []; //File[] | null = null;
  eQValidationURL = '';
  expire = false;
  eQNewInstitutionName = '';
  eQNewInstitutionURL = '';
  eQNewQualificationName = '';
  //ADD QUALIFICATION
  addQQualificationName = '';
  addQType: QualificationsBthrQualificationType;
  addQDoesItExpire = false;
  addQWhen = 0;
  addQLevel = 0;
  addQPoints = 0;
  //ADD INSTITUTION
  addIName = '';
  addIType: QualificationsBthrInstitutiontype;
  addICountry: QualificationsBthrCountry;
  addIAccreditationAuthority: QualificationsBthrAccreditationAuthority;

  //Notification
  notificationMessage = '';
  notificationActive = false;
  notificationTitle = '';
  notificationButton = '';

  eQInstutionDropdownMessage = '';
  eQQualificationDropdownMessage = '';

  //Renewal:

  renewalDate: Date = new Date();
  renewalFile: File[] = [];
  renewalValidationURL = '';

  //Notepad
  //eQQualification == null || this.eQfile.length == 0 || eQInstution == null || eQQualificationType == null

  //Notepad End

  //forum -----------------------------------------------------------------------------------------------------
  editEQForm: FormGroup;
  fg: FormGroup;

  //mobile
  isMobile = false;

  registerForm: FormGroup = new FormGroup({
    eQInstution: new FormControl(),
    eQQualificationType: new FormControl(), //Validators.required),
    eQQualification: new FormControl(),
    eQDateAttained: new FormControl(),
    eQExpiryDate: new FormControl(),
    eQLevel: new FormControl(),
    eQPoints: new FormControl(),
    eQfile: new FormControl(),
    eQValidationURL: new FormControl(),
    eQNewInstitutionName: new FormControl(),
    eQNewQualificationName: new FormControl(),
    eQNewInstitutionURL: new FormControl(),
    expire: new FormControl(),
    renewalDate: new FormControl(),
    renewalFile: new FormControl(),
    renewalValidationURL: new FormControl(),
  });

  testTest = true;
  eQExpiryDateNgIf = false;
  eQLevelNgIf = false;
  eQPointsNgIf = false;
  viewRenewNgIf = false;
  viewRenewalFile = false;
  viewEQfile = false;
  vieweQInstutionNgIf = false;
  vieweQNewQualificationNgIf = false;
  viewFileDownloadNgIf = false; //---------------------------------------------
  submitButon = false;
  pdfName = '';

  disable: boolean;
  //@Output() qualificationChangedeEvent: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('registerForm') item;

  //Permissions
  editEQQualificationPermissions = false;
  addEQQualificationPermissions = false;

  //Extra
  linkCreated = true;
  test: QualificationsDataQuery = {};
  updateGaurd = false;
  qualificationButtonText = 'Submit';

  state: State = {
    skip: 0,
    take: 12,
    group: [],
    filter: { filters: [], logic: 'and' },
    sort: [],
  };

  disabledDates = (date: Date) => {
    return date.getDate() > new Date().getDate();
  };

  eQLevelSide = 1;
  eQPointsSide = 2;

  //PDF:
  myRestrictions: FileRestrictions = {
    allowedExtensions: ['.pdf'],
  };

  decoded: string;

  fileDataNgIf = false;
  currentRowCount = 0;

  constructor(
    private sidebarService: VerdeApprovalService,
    private employeeEducationalQualificationService: EmployeeEducationalQualificationService,
    private employeeEducationalQualificationRenewService: EmployeeEducationalQualificationRenewService,
    private qualificationService: QualificationService,
    private educationalInstitutionService: EducationalInstitutionService,
    private qualificationInstitutionLinkService: QualificationInstitutionLinkService,
    private countryService: CountryService,
    private accreditationAuthorityService: AccreditationAuthorityService,
    private userService: UserService,
    private qualificationsApiQualificationsService: QualificationsApiQualificationsService,
    private cdr: ChangeDetectorRef,
    private viewSDKClient: ViewSDKClient,
    private deviceTypeService: DeviceTypeService,
  ) {}

  ngOnInit(): void {
    this.getReducedEducationalInstitutionService();

    const now = new Date();
    this.registerForm?.get('eQInstution')?.setValue({});
    this.registerForm?.get('eQQualificationType')?.setValue(null);
    this.registerForm?.get('eQQualification')?.setValue(null);
    this.registerForm?.get('eQDateAttained')?.setValue(now);
    this.registerForm?.get('eQExpiryDate')?.setValue(null);
    this.registerForm?.get('eQLevel')?.setValue('');
    this.registerForm?.get('eQPoints')?.setValue('');
    this.registerForm?.get('eQValidationURL')?.setValue('');
    this.registerForm?.get('eQNewInstitutionName')?.setValue('');
    this.registerForm?.get('eQNewQualificationName')?.setValue('');
    this.registerForm?.get('eQNewInstitutionURL')?.setValue('');
    this.registerForm?.get('expire')?.setValue(null); //DOUBLE CHECK TO PULL FROM WHICH TABLE
    this.registerForm?.get('renewalDate')?.setValue(now);
    this.registerForm?.get('renewalFile')?.setValue(null);
    this.registerForm?.get('renewalValidationURL')?.setValue('');
    this.registerForm?.get('eQfile')?.setValue(null);

    // Dropdown qualification type
    this.qualificationsBthrQualificationType = [];

    for (const value in QualificationsBthrQualificationType) {
      this.qualificationsBthrQualificationType.push(value);
    }

    this.qualificationsBthrQualificationType = this.qualificationsBthrQualificationType.sort();

    // Get Dropdown institution type
    this.institutiontype = [];

    for (const value in QualificationsBthrInstitutiontype) {
      this.institutiontype.push(value);
    }

    // Get QualficationInstitutionLink
    //this.getQualficationInstitutionLinkService("", "");

    // Get selected item
    this.sidebarService
      .getSelectedQualification()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        if (Object.keys(item).length > 0) {
          this.selectedEmployeeEducationalQualification = item;
          //this.getCancellationTypes();
        }
      });

    this.sidebarService
      .getSelectedQualificationNavigation()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        if (Object.keys(item).length > 0) {
          this.selectedNavigationOption = item;
          //this.getCancellationTypes();
        }
      });

    if (this.selectedNavigationOption == 'viewUpdate' || this.selectedNavigationOption == 'viewRenew') {
      this.registerForm?.controls['eQInstution'].setValidators([Validators.requiredTrue]);
      this.registerForm?.controls['eQQualificationType'].setValidators([Validators.requiredTrue]);
      this.registerForm?.controls['eQQualification'].setValidators([Validators.requiredTrue]);
      this.registerForm?.controls['eQDateAttained'].setValidators([Validators.requiredTrue]);
      this.registerForm?.controls['eQfile'].setValidators([Validators.requiredTrue]);
      this.registerForm?.controls['eQValidationURL'].setValidators([Validators.requiredTrue]);
      this.registerForm?.controls['eQInstution'].setValidators([Validators.requiredTrue]);

      this.registerForm?.get('renewalDate')?.setValue(now);
      this.registerForm?.get('renewalFile')?.setValue(null);
      this.registerForm?.get('renewalValidationURL')?.setValue('');
      this.registerForm?.get('eQfile')?.setValue(null);
    } else if (this.selectedNavigationOption == 'viewRenew') {
      //
    } else if (this.selectedNavigationOption == 'viewAdd') {
      //
    }

    // Add items to Edit page
    if (this.selectedNavigationOption == 'viewUpdate' || this.selectedNavigationOption == 'viewRenew') {
      this.registerForm?.get('eQInstution')?.setValue({
        bthr_name: this.selectedEmployeeEducationalQualification?.educationalInstitution?.odata,
        bthr_id: this.selectedEmployeeEducationalQualification?.educationalInstitution?.id,
      }); //?? "Create New" ?? "createNew"
      this.registerForm?.get('eQQualificationType')?.setValue(this.selectedEmployeeEducationalQualification?.bthrQualification_bthr_qualificationtype ?? null);
      this.getInitialQualificationService(this.selectedEmployeeEducationalQualification?.qualification?.id); //---------------------
      this.registerForm?.get('eQDateAttained')?.setValue(new Date(this.selectedEmployeeEducationalQualification?.bthr_dateawarded));
      this.registerForm?.get('eQExpiryDate')?.setValue(new Date(this.selectedEmployeeEducationalQualification?.bt_expirydate));
      this.registerForm?.get('eQLevel')?.setValue(this.selectedEmployeeEducationalQualification?.bthrQualification_bt_level);
      this.registerForm?.get('eQPoints')?.setValue(this.selectedEmployeeEducationalQualification?.bthrQualification_bt_points);
      if (this.selectedEmployeeEducationalQualification?.qualification?.id != null) {
        this.getQualificationService(this.selectedEmployeeEducationalQualification?.qualification?.id); //-------------------------
      }
      this.registerForm?.get('expire')?.setValue(this.selectedEmployeeEducationalQualification?.bt_expire); //DOUBLE CHECK TO PULL FROM WHICH TABLE

      this.eQInstution = {
        bthr_name: this.selectedEmployeeEducationalQualification?.educationalInstitution?.odata,
        bthr_id: this.selectedEmployeeEducationalQualification?.educationalInstitution?.id,
      }; //?? "Create New" ?? "createNew"
      this.eQQualificationType = this.selectedEmployeeEducationalQualification?.bthrQualification_bthr_qualificationtype;
      this.eQDateAttained = new Date(this.selectedEmployeeEducationalQualification?.bthr_dateawarded);
      this.eQExpiryDate = new Date(this.selectedEmployeeEducationalQualification?.bt_expirydate);
      this.eQLevel = this.selectedEmployeeEducationalQualification?.bthrQualification_bt_level;
      this.eQPoints = this.selectedEmployeeEducationalQualification?.bthrQualification_bt_points;
      this.expire = this.selectedEmployeeEducationalQualification?.bt_expire; //DOUBLE CHECK TO PULL FROM WHICH TABLE

      if (this.selectedNavigationOption == 'viewUpdate') {
        this.eQValidationURL = this.selectedEmployeeEducationalQualification?.bt_validationurl;
        this.registerForm?.get('eQValidationURL')?.setValue(this.selectedEmployeeEducationalQualification?.bt_validationurl ?? '');
        this.registerForm?.get('eQNewInstitutionName')?.setValue(this.selectedEmployeeEducationalQualification?.bt_newinstitution ?? '');
        this.registerForm?.get('eQNewQualificationName')?.setValue(this.selectedEmployeeEducationalQualification?.bt_newqualificationname ?? '');
        this.registerForm?.get('eQNewInstitutionURL')?.setValue(this.selectedEmployeeEducationalQualification?.bt_newinstitutionurl ?? '');
        //this.fileData = { stringData: this.selectedEmployeeEducationalQualification?.qualificationsFileString, fileName: this.selectedEmployeeEducationalQualification?.qualificationsFileName }
      }
      this.registerForm
        ?.get('eQfile')
        ?.setValue([
          new File(
            [this.stringToBlob(this.selectedEmployeeEducationalQualification?.qualificationsFileString)],
            this.selectedEmployeeEducationalQualification?.qualificationsFileName,
          ),
        ]);
      setTimeout(() => {
        this.viewSDKClient.ready().then(() => {
          /* Invoke file preview */
          /* By default the embed mode will be Full Window */
          this.viewSDKClient.previewFile(
            'pdf-div',
            {},
            this.selectedEmployeeEducationalQualification?.qualificationsFileString ?? '',
            this.selectedEmployeeEducationalQualification?.qualificationsFileName,
          );
        });
      }, 1000);

      if (this.selectedNavigationOption == 'viewRenew') {
        this.registerForm?.get('eQValidationURL')?.setValue('');
        this.registerForm.controls.eQDateAttained.disable();
        this.registerForm.controls.eQExpiryDate.disable();
        this.registerForm.controls.eQQualificationType.disable();
        this.registerForm.controls.eQQualification.disable();
        this.registerForm.controls.eQInstution.disable();
        this.registerForm?.get('renewalDate')?.setValue(now);
        this.registerForm?.get('renewalValidationURL')?.setValue('');
      }
    }

    this.dropdownUpdate('a');
    this.qualification = this.qualificationDisplay = [];

    this.next();

    setTimeout(() => {
      this.runOnUpdate();
    });
  }

  ngAfterViewInit(): void {
    this.runOnUpdate();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  getQualficationInstitutionLinkService(qualificationInput?: string, filter?: string) {
    //this.qualificationInstitutionLinkService.qualificationClear()
    this.qualificationInstitutionLinkService.getQualificationInstitutionLink({ qualification: [qualificationInput] }); //, searchParam: filter ?? "", qualificationType: [typeInput]
    this.qualificationInstitutionLinkService.qualificationInstitutionLinkObjects$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((items: QualificationsBtQualficationInstitutionLink[]) => {
        this.link = this.linkDisplay = [] as QualificationsBthrEducationalInstitution[];

        if (items.length == 0) {
          this.registerForm?.get('eQInstutionDropdownMessage')?.setValue('No Data Found for ' + filter);
          this.link = this.linkDisplay = [] as QualificationsBthrEducationalInstitution[];
          this.registerForm?.get('eQInstution')?.setValue(null);

          this.runOnUpdate();
        } else {
          let holdTemp;
          holdTemp = null;
          holdTemp = items.map((x) => Object.assign({}, x));
          holdTemp.push({ institution: { id: 'createNew', odata: 'Create New' } });

          this.link = this.linkDisplay = holdTemp;

          this.eQInstutionDropdownMessage = '';

          this.runOnUpdate();
        }
      });

    this.runOnUpdate();
  }

  getInitialQualificationService(qualificationid?: string) {
    //------------------------------------------------------------------------------------------------------------------------------------------
    //this.qualificationService.qualificationClear()
    if (qualificationid != null || qualificationid !== undefined) {
      this.qualificationsApiQualificationsService
        .getQualification({
          body: {
            bthr_Qualificationid: qualificationid,
            query: {
              count: false,
              take: 1,
              skip: this.state.skip,
              sort: '',
              filter: '',
              search: '',
              searchColumns: [''],
            },
          },
        })
        .pipe(take(1))
        .subscribe((items) => {
          if (items.count != 0) {
            if (!this.updateGaurd) {
              this.registerForm?.get('eQQualification')?.setValue(items.results[0]);

              this.runOnUpdate();
            }
          }
        });
    }
  }

  instutionChanged(value) {
    this.runOnUpdate();
  }

  getQualificationService(qualificationid?: string, type?: QualificationsBthrQualificationType, filter?: string) {
    //------------------------------------------------------------------------------------------------------------------------------------------
    //this.qualificationService.qualificationClear()
    try {
      this.qualificationsApiQualificationsService
        .getQualification({
          body: {
            bthr_Qualificationid: qualificationid,
            searchParam: filter,
            qualificationType: type,
            query: {
              count: false,
              take: this.state.take,
              skip: this.state.skip,
              sort: '',
              filter: '',
              search: '',
              searchColumns: [''],
            },
          },
        })
        .pipe(take(1))
        .subscribe((items) => {
          if (!this.updateGaurd) {
            let holdTemp;
            holdTemp = null;
            holdTemp = items.results.map((x) => Object.assign({}, x));
            holdTemp.push({ id: 'createNew', bthr_name: 'Create New' });

            this.qualification = this.qualificationDisplay = holdTemp;

            this.runOnUpdate();
          }

          //set UI
          if (this.selectedNavigationOption == 'viewUpdate' || this.selectedNavigationOption == 'viewAdd' || this.selectedNavigationOption == 'viewRenew') {
            // Edit page
            if (this.updateGaurd) {
              this.setUIQualifications();

              this.runOnUpdate();
            }
            this.updateGaurd = false;
          }

          if (items.count == 0) {
            this.eQQualificationDropdownMessage = 'No Data Found for ' + filter;
          } else {
            this.eQQualificationDropdownMessage = '';
          }
        });
    } catch (e) {
      this.updateGaurd = false;

      this.runOnUpdate();
    }
  }

  getReducedQualificationService(type: QualificationsBthrQualificationType, filter?: string) {
    //this.qualificationService.qualificationClear()
    this.qualificationService.getReducedQualification({ searchParam: filter, qualificationType: [type] });
    this.qualificationService.qualificationObjects$.pipe(takeUntil(this.onDestroy$)).subscribe((items?: QualificationsBthrQualification[]) => {
      if (items.length != 0) {
        this.qualification = this.qualificationDisplay = items;
        //set UI
        if (this.selectedNavigationOption == 'viewUpdate' || this.selectedNavigationOption == 'viewAdd' || this.selectedNavigationOption == 'viewRenew') {
          // Edit page
          this.setUIQualifications();

          this.runOnUpdate();
        }
      } else {
        // loading or no data
      }
    });
  }

  setUIQualifications() {
    const Moment = moment(this.registerForm?.value.eQDateAttained, 'yyyy/MM/dd');
    const endDate = Moment;
    endDate.add(
      this.registerForm?.value?.eQQualification?.bt_expireafterdays == null || this.registerForm?.value?.eQQualification?.bt_expireafterdays === undefined
        ? 0
        : this.registerForm?.value?.eQQualification?.bt_expireafterdays,
      'days',
    );
    this.registerForm?.get('eQLevel')?.setValue(this.registerForm?.value.eQQualification?.bt_level.toString());
    this.registerForm?.get('eQPoints')?.setValue(this.registerForm?.value.eQQualification?.bt_points.toString());
    this.runOnUpdate();
  }

  dateAttainedChanged() {
    const Moment = moment(this.registerForm?.value.eQDateAttained, 'yyyy/MM/dd');
    const endDate = Moment;
    endDate.add(
      this.registerForm?.value.eQQualification.bt_expireafterdays == null || this.registerForm?.value.eQQualification.bt_expireafterdays === undefined
        ? 0
        : this.registerForm?.value.eQQualification.bt_expireafterdays,
      'days',
    );
    this.registerForm.get('eQExpiryDate')?.setValue(endDate?.toDate());
  }

  getCountriesService(filter: string) {
    if (filter.length > 3) {
      //this.countryService.countryClear()
      this.countryService.getCountry({ searchParam: filter });
      this.countryService.countryObjects$.pipe(takeUntil(this.onDestroy$)).subscribe((items: any[]) => {
        this.country = items;
        this.runOnUpdate();
      });
    }
  }

  getAccreditationAuthorityService() {
    //this.accreditationAuthorityService.accreditationAuthorityClear()
    this.accreditationAuthorityService.getAccreditationAuthority({ country: [this.addICountry.bthr_Countryid] });
    this.accreditationAuthorityService.accreditationAuthorityObjects$.pipe(takeUntil(this.onDestroy$)).subscribe((items: any[]) => {
      this.accreditationAuthority = items;
    });
  }

  enableEditInstitutionPanel() {
    this.createItemSidepanel = 'institution';
  }

  enableEditQualificationPanel() {
    this.createItemSidepanel = 'qualification';
  }

  closeAdd() {
    this.addIName = null;
    this.addIType = null; //-----------------
    this.addICountry = {};
    this.addIAccreditationAuthority = null;
    this.institution = [] as QualificationsBthrEducationalInstitution[];
    this.addQQualificationName = null;
    this.addQType = null;
    this.addQDoesItExpire = false;
    this.addQWhen = 0;
    this.addQLevel = 0;
    this.addQPoints = 0;

    this.createItemSidepanel = '';
  }

  submitConfirmed() {
    if (
      this.selectedNavigationOption == 'viewUpdate' &&
      (this.createItemSidepanel == null || this.createItemSidepanel == '' || this.createItemSidepanel === undefined)
    ) {
      if (this.registerForm?.value.eQQualification != null) {
        const Moment = moment(this.registerForm?.value.eQDateAttained, 'M/D/YYYY');
        const endDate = Moment;
        endDate.add(this.registerForm?.value.eQQualification.bt_expireafterdays, 'days');
        const files: File[] = [];
        if (!(this.registerForm?.value?.eQfile == null || this.registerForm?.value?.eQfile === undefined)) {
          files.push(this.registerForm?.value.eQfile[0]);
        }

        this.employeeEducationalQualificationService.updateUserEducationalQualification({
          bthr_employeeeducationalqualificationid: this.selectedEmployeeEducationalQualification?.bthr_employeeeducationalqualificationid?.toString(),
          bthr_dateawarded: moment(this.registerForm?.value?.eQDateAttained).format('M/D/YYYY').toString(), //format as 8/24/2023
          bt_expirydate: this.registerForm?.value?.eQQualification?.bt_expire != true ? null : moment(endDate).format('M/D/YYYY').toString(),
          bthr_qualification:
            this.registerForm?.value?.eQQualification?.id?.toString() == 'createNew' ? null : this.registerForm?.value?.eQQualification?.id?.toString(),
          bthr_educationalinstitution:
            this.registerForm?.value?.eQInstution?.bthr_id?.toString() == 'createNew' ? null : this.registerForm?.value.eQInstution?.bthr_id?.toString(),
          bthr_employee: this.selectedEmployeeEducationalQualification?.employee?.id?.toString(), //this.userService.user?.employeeId?.toString(),
          bt_legalentity: this.selectedEmployeeEducationalQualification?.legalEntity?.id?.toString(), //this.userService.userProfile._bthr_legalentity_value?.toString() ?? "",
          bt_validationurl: this.registerForm?.value?.eQValidationURL?.toString() ?? '',
          bt_newinstitution: this.registerForm?.value?.eQNewInstitutionName?.toString() ?? '',
          bt_newqualificationname: this.registerForm?.value?.eQNewQualificationName?.toString() ?? '',
          bt_newinstitutionurl: this.registerForm?.value?.eQNewInstitutionURL?.toString() ?? '',
          bt_newinstitutionyn: this.registerForm?.value?.eQInstution?.bthr_id?.toString() == 'createNew' ? true : false,
          bt_newqualificationyn: this.registerForm?.value?.eQQualification?.id?.toString() == 'createNew' ? true : false,
          bt_validated: false,
          bt_expire:
            this.registerForm?.value?.eQQualification?.bt_expire == null || this.registerForm?.value?.eQQualification?.bt_expire === undefined
              ? false
              : this.registerForm?.value?.eQQualification?.bt_expire,
          bthr_recordstatus: QualificationsBthrRecordStatus.Changed,
          qualificationsFile: this.registerForm?.value?.eQfile != null ? this.registerForm?.value.eQfile : null,
          bt_Proxy:
            this.userService?.user?.employeeId?.toString() != this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ||
            (this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ?? '') == ''
              ? true
              : false,
          bt_ProxyLegalEntity:
            this.userService?.user?.employeeId?.toString() != this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ||
            (this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ?? '') == ''
              ? this.userService.user.legalEntityId.toString()
              : null,
          bt_ProxyID:
            this.userService?.user?.employeeId?.toString() != this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ||
            (this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ?? '') == ''
              ? this.userService.user.employeeId?.toString()
              : null,
        });
        this.closeQualificationModal();
      } else {
        //alert("Please ensure that a qualification is selected");
        //this.notification("Cannot submit", "Please ensure that a qualification is selected")--------------------------------------------------------
      }
    } else if (
      this.selectedNavigationOption == 'viewAdd' &&
      (this.createItemSidepanel == null || this.createItemSidepanel == '' || this.createItemSidepanel === undefined)
    ) {
      if (this.registerForm?.value.eQQualification != null) {
        const Moment = moment(this.registerForm?.value.eQDateAttained, 'M/D/YYYY');
        const endDate = Moment;
        endDate.add(
          this.registerForm?.value.eQQualification.bt_expireafterdays == null || this.registerForm?.value.eQQualification.bt_expireafterdays === undefined
            ? 0
            : this.registerForm?.value.eQQualification.bt_expireafterdays,
          'days',
        );
        const files: File[] = [];
        if (this.registerForm?.value.eQfile != null) {
          files.push(this.registerForm?.value.eQfile[0]);
        }

        this.employeeEducationalQualificationService.uploadEmployeeEducationalQualification({
          bthr_dateawarded: moment(this.registerForm?.value?.eQDateAttained).format('M/D/YYYY').toString(), //format as 8/24/2023
          bt_expirydate: this.registerForm?.value?.eQQualification?.bt_expire != true ? null : moment(endDate).format('M/D/YYYY').toString(),
          bthr_qualification:
            this.registerForm?.value?.eQQualification?.id?.toString() == 'createNew' ? null : this.registerForm?.value?.eQQualification?.id?.toString(),
          bthr_educationalinstitution:
            this.registerForm?.value?.eQInstution?.bthr_id?.toString() == 'createNew' ? null : this.registerForm?.value.eQInstution?.bthr_id?.toString(),
          bthr_employee: this.selectedEmployeeEducationalQualification?.employee?.id?.toString(),
          bt_legalentity: this.selectedEmployeeEducationalQualification?.legalEntity?.id?.toString(),
          bt_validationurl: this.registerForm?.value?.eQValidationURL?.toString() ?? '',
          bt_newinstitution: this.registerForm?.value?.eQNewInstitutionName?.toString() ?? '',
          bt_newqualificationname: this.registerForm?.value?.eQNewQualificationName?.toString() ?? '',
          bt_newinstitutionurl: this.registerForm?.value?.eQNewInstitutionURL?.toString() ?? '',
          bt_newinstitutionyn: this.registerForm?.value?.eQInstution?.bthr_id?.toString() == 'createNew' ? true : false,
          bt_newqualificationyn: this.registerForm?.value?.eQQualification?.id?.toString() == 'createNew' ? true : false,
          bt_validated: false,
          bt_expire:
            this.registerForm?.value?.eQQualification?.bt_expire == null || this.registerForm?.value?.eQQualification?.bt_expire === undefined
              ? false
              : this.registerForm?.value?.eQQualification?.bt_expire,
          bthr_recordstatus: QualificationsBthrRecordStatus.New,
          qualificationsFile: this.registerForm?.value?.eQfile != null ? this.registerForm?.value?.eQfile : null,
          bt_Proxy:
            this.userService?.user?.employeeId?.toString() != this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ||
            (this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ?? '') == ''
              ? true
              : false,
          bt_ProxyLegalEntity:
            this.userService?.user?.employeeId?.toString() != this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ||
            (this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ?? '') == ''
              ? this.userService?.user?.legalEntityId?.toString()
              : null,
          bt_ProxyID:
            this.userService?.user?.employeeId?.toString() != this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ||
            (this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ?? '') == ''
              ? this.userService?.user?.employeeId?.toString()
              : null,
        });

        this.closeQualificationModal();

        //const secondFunction = async () => {
        //  try {
        //this.getCreateQualficationInstitutionLinkIfNotAvailableService(this.addEQQualification?.id?.toString(), this.qualification[0].bthr_qualificationtype, this.addEQInstution?.bthr_id?.toString());

        //  } catch (e) {
        //    this.linkCreated = false;
        //  }

        //  if (this.linkCreated) {
        //    this.employeeEducationalQualificationService.uploadEmployeeEducationalQualification({

        //      bthr_dateawarded: moment(this.addEQDateAttained).format("yyyy/MM/dd").toString(), //format as 8/24/2023
        //      bt_expirydate: this.qualification[0]?.bt_expire != true ? null : moment(endDate).format("yyyy/MM/dd").toString(),
        //      bthr_qualification: this.addEQQualification?.id?.toString(),
        //      bthr_educationalinstitution: this.addEQInstution?.bthr_id?.toString(),
        //      bthr_employee: this.userService.user?.employeeId?.toString(),
        //      bt_legalentity: this.userService.user?.legalEntityId?.toString(),
        //      bt_validationurl: this.addEQValidationURL?.toString(),
        //      bt_validated: false,
        //      bt_expire: this.qualification[0].bt_expire,
        //      bthr_recordstatus: QualificationsBthrRecordStatus.New,
        //      qualificationsFile: this.addEQfile.length > 0 ? files : null,

        //    }) ;

        //  } else {
        //    // INSERT ERROR HERE
        //  }

        //  this.linkCreated = true;

        //}
      } // renewals, institution, qualification
    } else if (
      this.selectedNavigationOption == 'viewRenew' &&
      (this.createItemSidepanel == null || this.createItemSidepanel == '' || this.createItemSidepanel === undefined)
    ) {
      const Moment = moment(this.registerForm?.value.eQDateAttained, 'M/D/YYYY');
      const endDate = Moment;
      endDate.add(
        this.registerForm?.value?.eQQualification?.bt_expireafterdays == null || this.registerForm?.value?.eQQualification.bt_expireafterdays === undefined
          ? 0
          : this.registerForm?.value?.eQQualification?.bt_expireafterdays,
        'days',
      );
      // const files = [];
      //if (this.registerForm?.value.renewalFile?.length != null) {
      //  files.push(this.registerForm?.value.renewalFile[0]);
      //}

      // Creates renewals
      this.employeeEducationalQualificationRenewService.uploadEmployeeEducationalQualificationRenew({
        bt_employeequalification: this.selectedEmployeeEducationalQualification.bthr_employeeeducationalqualificationid.toString(),
        proofOfRenewal: this.registerForm?.value?.renewalFile != null ? this.registerForm?.value?.renewalFile : null,
        bt_validationurl: this.registerForm?.value?.renewalValidationURL,
        bt_renewaldate: moment(this.registerForm?.value?.renewalDate).format('M/D/YYYY').toString(),
        bt_Proxy:
          this.userService?.user?.employeeId?.toString() != this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ||
          (this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ?? '') == ''
            ? true
            : false,
        bt_ProxyLegalEntity:
          this.userService?.user?.employeeId?.toString() != this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ||
          (this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ?? '') == ''
            ? this.userService?.user?.legalEntityId?.toString()
            : null,
        bt_ProxyID:
          this.userService?.user?.employeeId?.toString() != this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ||
          (this.selectedEmployeeEducationalQualification?.employee?.id?.toString() ?? '') == ''
            ? this.userService?.user?.employeeId?.toString()
            : null,
      });

      this.closeQualificationModal();
    }
  }

  createInstitution() {
    if (this.addIName != null && this.addIType != null && this.addICountry != null && this.addIAccreditationAuthority != null) {
      this.educationalInstitutionService.uploadEducationalInstitution({
        bthrEducationalInstitutions: {
          bthr_name: this.addIName,
          bthr_institutiontype: this.addIType,
          bthr_country: this.addICountry.bthr_Countryid.toString(),
          bthr_accreditationauthoritylookup: this.addIAccreditationAuthority.bthr_AccreditationAuthorityid.toString(),
        },
      });

      this.closeAdd();
    } else {
      //alert("Please ensure that all fields are filled");
    }
  }

  createQualification() {
    if (this.addQQualificationName != null && this.addQQualificationName != '' && this.addQType != null) {
      this.qualificationService.uploadQualification({
        bthrQualifications: {
          bthr_name: this.addQQualificationName,
          bthr_qualificationtype: this.addQType,
          bt_expire: this.addQDoesItExpire,
          bt_expireafterdays: this.addQWhen == null ? 0 : this.addQWhen,
          bt_level: this.addQLevel == null ? 0 : this.addQLevel,
          bt_points: this.addQPoints == null ? 0 : this.addQPoints,
        },
      });

      this.institution = this.institutionDisplay = [] as QualificationsBthrEducationalInstitution[];

      this.closeAdd();
    } else {
      //alert("Please ensure that the qualification has a name and type");
      this.notification('Cannot submit', 'Please ensure that the qualification has a name and type'); //-------------------------------- upgrade to new standard
    }
  }

  dropdownUpdate(path?: string) {
    // a is to see if data is from front end of picked by user
    if (path != 'a' && this.registerForm?.value?.eQQualification?.id?.toString() != 'createNew') {
      this.registerForm.get('eQQualification')?.setValue(null);
    }
  }

  handleCountryFilter(searchParam: string) {
    if (searchParam.length >= 2) {
      this.getCountriesService(searchParam);
    }
  }

  handleEQQualificationFilter(searchParam: string) {
    searchParam == null
      ? (this.qualificationDisplay = this.qualification)
      : (this.qualificationDisplay = this.qualification.filter((s) => s.bthr_name.toLowerCase().indexOf(searchParam.toLowerCase()) !== -1));

    //if (this.registerForm?.value.eQQualificationType != null && this.registerForm?.value.eQfile !== null) {
    //  if (searchParam.length >= 3) {
    //    this.getQualificationService("", QualificationsBthrQualificationType[this.registerForm?.value.eQQualificationType], searchParam)
    //  } else {
    //    this.getQualificationService("", QualificationsBthrQualificationType[this.registerForm?.value.eQQualificationType], "")
    //    //USE FOR WARNING POPUP
    //    //this.editableEDIT = true;
    //  }
    //} else {
    //  this.qualification = [];
    //  this.registerForm.get('eQQualification')?.setValue(null);
    //}
  }

  handleEQInstutionFilter(searchParam: string) {
    searchParam == null
      ? (this.institutionDisplay = this.institution)
      : (this.institutionDisplay = this.institution.filter((s) => s.bthr_name.toLowerCase().indexOf(searchParam.toLowerCase()) !== -1));
  }

  eQQualificationTypeChanged() {
    if (this.registerForm?.value?.eQQualification?.id?.toString() != 'createNew') {
      this.registerForm?.get('eQQualification')?.setValue(null);
    }

    if (this.registerForm?.value.eQQualificationType != null) {
      this.getQualificationService('', QualificationsBthrQualificationType[this.registerForm?.value.eQQualificationType], '');
    }

    //clear upstream

    //if (this.registerForm?.value?.eQInstution?.bthr_id?.toString() != "createNew") {
    //this.registerForm?.get('eQInstution')?.setValue(null);
    //}

    this.runOnUpdate();
  }

  qualificationChanged(inputValue) {
    //--
    //this.updateGaurd = true;
    //this.getQualificationService(this.registerForm?.value.eQQualification.id);

    //clear upstream
    //this.registerForm?.get('eQInstution')?.setValue(null);
    //this.institution = [] as QualificationsBthrEducationalInstitution[];

    if (!(this.registerForm?.value.eQQualification == null || this.registerForm?.value.eQQualification === undefined)) {
      const Moment = moment(this.registerForm?.value.eQDateAttained, 'M/D/YYYY');
      const endDate = Moment;
      endDate.add(this.registerForm?.value.eQQualification.bt_expireafterdays, 'days');
      this.eQQualificationType = this.registerForm?.value?.bthr_qualificationtype;
      if (this.registerForm?.value?.eQQualification?.bt_expire == true && endDate != null) {
        this.registerForm?.get('eQExpiryDate')?.setValue(endDate.toDate());
      }
      this.registerForm?.get('eQLevel')?.setValue(this.registerForm?.value?.eQQualification?.bt_level);
      this.registerForm?.get('eQPoints')?.setValue(this.registerForm?.value?.eQQualification?.bt_points);
      this.registerForm?.get('expire')?.setValue(this.registerForm?.value?.eQQualification?.bt_expire); //DOUBLE CHECK TO PULL FROM WHICH TABLE

      //  this.getQualficationInstitutionLinkService(this.registerForm?.value?.eQQualification?.bthr_Qualificationid?.toString(), inputValue);
    }

    this.runOnUpdate();
  }

  enableAddICountry() {
    this.addIAccreditationAuthority = null;
    this.getAccreditationAuthorityService();

    this.runOnUpdate();
  }

  closeQualificationModal() {
    this.sidebarService.setShowSidebar(false);
  }

  //public showNotification(message: string): void {
  //  this.notificationService.show({
  //    content: message,
  //    cssClass: "button-notification",
  //    animation: { type: "slide", duration: 400 },
  //    position: { horizontal: "center", vertical: "bottom" },
  //    type: { style: "success", icon: true },
  //    closable: true,
  //  });
  //}

  notification(title: string, message: string, button?: string) {
    this.notificationMessage = message;
    this.notificationTitle = title;
    this.notificationButton = button == null ? 'Continue' : button;
    this.notificationActive = true;
  }

  closeNotification() {
    this.notificationActive = false;
  }

  //Save file
  downloadPdf() {
    if (this.registerForm?.value?.eQfile[0] != null) {
      saveAs(this.registerForm?.value?.eQfile[0], this.registerForm?.value?.eQfile[0].name);
    }
  }

  //Save file
  downloadRenewalPdf() {
    if (this.registerForm?.value?.renewalFile[0] != null) {
      saveAs(this.registerForm?.value?.renewalFile[0], this.registerForm?.value?.renewalFile[0].name);
    }
  }

  //convert string to blob
  stringToBlob(base64String: string) {
    const binaryData = atob(base64String ?? '');
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: 'application/pdf' });

    return blob;
  }

  onFormUpdate() {
    this.eQInstution = { bthr_name: this.registerForm?.value?.eQInstution?.bthr_name, bthr_id: this.registerForm?.value?.eQInstution?.bthr_id };
    this.eQQualificationType = this.registerForm?.value?.eQQualificationType;
    this.eQDateAttained = new Date(this.registerForm?.value?.eQDateAttained);
    this.eQExpiryDate = new Date(this.registerForm?.value?.eQExpiryDate);
    this.eQLevel = this.registerForm?.value?.eQQualification?.eQLevel;
    this.eQPoints = this.registerForm?.value?.eQQualification?.eQPoints;
    this.eQValidationURL = this.registerForm?.value?.eQValidationURL; //
    this.eQNewInstitutionName = this.registerForm?.value?.eQNewInstitutionName; //
    this.eQNewQualificationName = this.registerForm?.value?.eQNewQualificationName; //
    this.eQNewInstitutionURL = this.registerForm?.value?.eQNewInstitutionURL; //
    this.expire = this.registerForm?.value?.eQQualification?.expire;
    this.eQQualification = this.registerForm?.value?.eQQualification;

    setTimeout(() => {
      this.runOnUpdate();
    });
  }

  next(): void {
    /*this.currentStep += 1;*/

    this.runOnUpdate();
  }

  runOnUpdate() {
    setTimeout(() => {
      if (this.registerForm?.value?.eQQualification == null) {
        this.disable = true;
        this.registerForm.controls.eQInstution.disable();
      } else {
        this.disable = false;
        this.registerForm.controls.eQInstution.enable();
      }

      if (this.registerForm?.value?.eQQualificationType == null) {
        this.disable = true;
        this.registerForm.controls.eQQualification.disable();
      } else {
        this.disable = false;
        this.registerForm.controls.eQQualification.enable();
      }

      //.log("aaa",this.registerForm?.value?.eQQualification,"b" ,  this.registerForm?.value?.eQQualification?.bt_expire,"c", this.registerForm?.value?.eQQualification?.bt_expireafterdays);
      if (
        this.registerForm?.value?.eQQualification == null || this.registerForm?.value?.eQQualification === undefined
          ? false
          : this.registerForm?.value?.eQQualification?.bt_expire == true && this.registerForm?.value?.eQQualification?.bt_expireafterdays != null
      ) {
        this.eQExpiryDateNgIf = true;
      } else {
        this.eQExpiryDateNgIf = false;
      }

      if (
        !(this.registerForm?.value?.eQQualification == null || this.registerForm?.value?.eQQualification === undefined) &&
        !(
          this.registerForm?.value?.eQQualification?.bt_points == 0 ||
          this.registerForm?.value?.eQQualification?.bt_points == null ||
          this.registerForm?.value?.eQQualification?.bt_points === undefined
        )
      ) {
        this.eQLevelNgIf = true;
      } else {
        this.eQLevelNgIf = false;
      }

      if (
        !(this.registerForm?.value?.eQQualification == null || this.registerForm?.value?.eQQualification === undefined) &&
        !(
          this.registerForm?.value?.eQQualification?.bt_points == 0 ||
          this.registerForm?.value?.eQQualification?.bt_points == null ||
          this.registerForm?.value?.eQQualification?.bt_points === undefined
        )
      ) {
        this.eQPointsNgIf = true;
      } else {
        this.eQPointsNgIf = false;
      }

      if (this.selectedNavigationOption == 'viewRenew') {
        this.viewRenewNgIf = true;
      } else {
        this.viewRenewNgIf = false;
      }
      if (this.registerForm?.value?.renewalFile == null) {
        this.viewRenewalFile = false;

        if (this.deviceTypeService.isMobile() == true) {
          this.sidebarService.setSidebarSize(100);
        } else {
          this.currentRowCount = 0;

          this.sidebarService.setSidebarSize(40);
        }
      } else {
        this.viewRenewalFile = true;

        if (this.deviceTypeService.isMobile() == true) {
          this.sidebarService.setSidebarSize(100);
        } else {
          this.sidebarService.setSidebarSize(85);
        }
        this.currentRowCount = 4;
      }

      if (this.registerForm?.value?.eQfile == null) {
        this.viewEQfile = false;

        if (this.deviceTypeService.isMobile() == true) {
          this.sidebarService.setSidebarSize(100);
        } else {
          this.currentRowCount = 0;

          this.sidebarService.setSidebarSize(40);
        }
      } else {
        this.viewEQfile = true;

        if (this.deviceTypeService.isMobile() == true) {
          this.sidebarService.setSidebarSize(100);
        } else {
          this.sidebarService.setSidebarSize(85);
        }
        this.currentRowCount = 4;
      }

      if (this.registerForm?.value?.eQInstution?.bthr_id?.toString() == 'createNew' && this.selectedNavigationOption != 'viewRenew') {
        this.vieweQInstutionNgIf = true;
      } else {
        this.vieweQInstutionNgIf = false;
      }

      if (this.registerForm?.value?.eQQualification?.id?.toString() == 'createNew' && this.selectedNavigationOption != 'viewRenew') {
        this.vieweQNewQualificationNgIf = true;
      } else {
        this.vieweQNewQualificationNgIf = false;
      }

      if (this.eQPointsNgIf && this.eQLevelNgIf) {
        this.eQLevelSide = 1;
        this.eQPointsSide = 2;
      } else if (!this.eQPointsNgIf && this.eQLevelNgIf) {
        this.eQLevelSide = 1;
        this.eQPointsSide = 2;
      } else if (this.eQPointsNgIf && !this.eQLevelNgIf) {
        this.eQLevelSide = 2;
        this.eQPointsSide = 1;
      }

      if (this.selectedNavigationOption == 'viewUpdate') {
        this.registerForm.controls.eQDateAttained.enable();
        if (this.registerForm?.value?.eQQualification?.eQDateAttained != null) {
          //this.registerForm.controls.eQExpiryDate.enable();
        }
        if (
          !(
            this.registerForm?.value?.eQQualification?.id?.toString() == null ||
            ((this.registerForm?.value?.eQNewQualificationName?.toString() ?? '') == '' &&
              this.registerForm?.value?.eQQualification?.id?.toString() == 'createNew') ||
            this.registerForm?.value?.eQfile == null ||
            this.registerForm?.value?.eQInstution?.bthr_id == null ||
            (((this.registerForm?.value?.eQNewInstitutionName?.toString() ?? '') == '' ||
              (this.registerForm?.value?.eQNewInstitutionURL?.toString() ?? '') == '') &&
              this.registerForm?.value?.eQInstution?.bthr_id?.toString() == 'createNew') ||
            this.registerForm?.value?.eQQualificationType == null
          )
        ) {
          this.submitButon = false;
        } else {
          this.submitButon = true;
        }
      } else if (this.selectedNavigationOption == 'viewAdd') {
        this.registerForm.controls.eQDateAttained.enable();
        if (this.registerForm?.value?.eQQualification?.eQDateAttained != null) {
          //this.registerForm.controls.eQExpiryDate.enable();
        }
        if (
          !(
            this.registerForm?.value?.eQQualification?.id?.toString() == null ||
            ((this.registerForm?.value?.eQNewQualificationName?.toString() ?? '') == '' &&
              this.registerForm?.value?.eQQualification?.id?.toString() == 'createNew') ||
            this.registerForm?.value?.eQfile == null ||
            this.registerForm?.value?.eQInstution?.bthr_id == null ||
            (((this.registerForm?.value?.eQNewInstitutionName?.toString() ?? '') == '' ||
              (this.registerForm?.value?.eQNewInstitutionURL?.toString() ?? '') == '') &&
              this.registerForm?.value?.eQInstution?.bthr_id?.toString() == 'createNew') ||
            this.registerForm?.value?.eQQualificationType == null
          )
        ) {
          this.submitButon = false;
        } else {
          this.submitButon = true;
        }
      } else if (this.selectedNavigationOption == 'viewRenew') {
        if (
          (this.registerForm?.value?.renewalFile?.toString() ?? '') == '' ||
          (this.registerForm?.value?.renewalValidationURL?.toString() ?? '') == '' ||
          (this.registerForm?.value?.renewalDate ?? '') == ''
        ) {
          this.submitButon = true;
        } else {
          this.submitButon = false;
        }
      }

      this.validateForum();

      this.cdr.detectChanges();
    });
  }

  eQLevelQPointsSide() {
    this.runOnUpdate();
  }

  eQfileChanged() {
    //this.fileData = { stringData: this.registerForm?.value?.eQfile[0], fileName: this.registerForm?.value?.eQfile[0].name }

    if ((this.registerForm?.value?.eQfile ?? null) != null || (this.registerForm?.value?.renewalFile ?? null) != null) {
      this.getBase64(this.registerForm?.value?.eQfile[0]! ?? this.registerForm?.value?.renewalFile[0]! ?? '').then((data) =>
        this.fileToBase64String(data.toString()),
      );
    }
    this.runOnUpdate();
  }

  fileToBase64String(testa: string) {
    if (this.deviceTypeService.isMobile() != true) {
      testa = testa.replace('data:', '').replace(/^.+,/, '').replace('on/pdf;base64,', '');

      setTimeout(() => {
        this.viewSDKClient.ready().then(() => {
          this.viewSDKClient.previewFile(
            'pdf-div',
            {},
            testa.toString() ?? '',
            this.registerForm?.value?.eQfile[0]?.name?.toString() ?? this.registerForm?.value?.renewalFile[0]?.name?.toString() ?? '',
          );
        });
        this.runOnUpdate();
      }, 1000);
      this.runOnUpdate();
    }
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject('');
    });
  }

  renewalFileChanged(data) {
    //this.fileData = { stringData: this.registerForm?.value?.renewalFile[0], fileName: this.registerForm?.value?.renewalFile[0].name }
    //this.renewalFile = [new File([this.stringToBlob(this.registerForm?.value?.renewalFile)], this.renewalFile[0].name)];
    this.runOnUpdate();
  }

  validateForum(): boolean {
    return this.registerForm.valid;
  }

  getReducedEducationalInstitutionService() {
    this.educationalInstitutionService.educationalInstitutionClear();
    this.educationalInstitutionService.getReducedEducationalInstitution({ searchParam: '' });
    this.educationalInstitutionService.educationalInstitutionObjects$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((items: QualificationsBthrEducationalInstitution[]) => {
        this.institution = items.sort();

        let holdTemp;
        holdTemp = null;
        holdTemp = items.map((x) => Object.assign({}, x));
        holdTemp.push({ bthr_id: 'createNew', bthr_name: 'Create New' });

        this.institution = this.institutionDisplay = holdTemp;

        this.runOnUpdate();
      });
  }
}
