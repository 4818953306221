import { Action } from '@ngrx/store';
import { DvRiskManagement } from '@verde/api';

export enum HealthRiskActionTypes {
  // Get All HealthRisk
  HEALTHRISK_GET_ALL = '[HEALTHRISK] Get All',
  HEALTHRISK_GET_ALL_SUCCESS = '[HEALTHRISK] Get All Success',
  HEALTHRISK_GET_ALL_ERROR = '[HEALTHRISK] Get All Error',

  // Clear HealthRisk
  HEALTHRISK_CLEAR = '[HEALTHRISK] Clear',
}

// Get All HealthRisk
export class HealthRiskGetAll implements Action {
  readonly type = HealthRiskActionTypes.HEALTHRISK_GET_ALL;
  constructor(public employeeID: string) {}
}

export class HealthRiskGetAllSuccess implements Action {
  readonly type = HealthRiskActionTypes.HEALTHRISK_GET_ALL_SUCCESS;
  constructor(public payload: DvRiskManagement[]) {}
}

export class HealthRiskGetAllError implements Action {
  readonly type = HealthRiskActionTypes.HEALTHRISK_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// Clear HealthRisk
export class HealthRiskClear implements Action {
  readonly type = HealthRiskActionTypes.HEALTHRISK_CLEAR;
  constructor(public payload: any) {}
}

export type HealthRiskActions =
  // Get All HealthRisk
  | HealthRiskGetAll
  | HealthRiskGetAllSuccess
  | HealthRiskGetAllError
  // Clear HealthRisk
  | HealthRiskClear;
