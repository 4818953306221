/* tslint:disable */
/* eslint-disable */
import { BtManager } from './bt-manager';
export interface VoteManagerNominationControl {
  _bt_legalentity_value?: null | string;
  _bt_manager_value?: null | string;
  _bt_votinggoup_value?: null | string;
  bt_Manager?: BtManager;
  bt_nominationapprovalstatus?: null | number;
  bt_nominationscomplted?: null | number;
  bt_nonotapproved?: null | number;
  bt_noofvotesavailable?: null | number;
  bt_numberofnominations?: null | number;
  bt_remaining?: null | number;
  bt_votemanagernominationcontrol?: null | string;
  bt_votemanagernominationcontrolid?: null | string;
  odataApprovalStatus?: null | string;
  odataLegalEntity?: null | string;
  odataVoteGroup?: null | string;
}
