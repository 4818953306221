/* tslint:disable */
/* eslint-disable */
export interface PeerReviewFeedbackSummary {
  _bt_cycle_value?: null | string;
  _bt_employee_value?: null | string;
  _bt_peerreviewtemplate_value?: null | string;
  _bt_period_value?: null | string;
  bt_duedate?: null | string;
  bt_peerreviewfeedbacksummary?: null | string;
  bt_peerreviewfeedbacksummaryid?: null | string;
  bt_peerreviewstatus?: null | number;
  bt_respondercategory?: null | number;
  bt_tcacceptance?: null | boolean;
  bt_tcacceptancedate?: null | string;
  odataCycle?: null | string;
  odataPeriod?: null | string;
  odataResponderCategory?: null | string;
  odataReviewStatus?: null | string;
  odataTemplate?: null | string;
}
