/* tslint:disable */
/* eslint-disable */
export interface Calendar {
  _bthr_legalentity_value?: null | string;
  bt_calendarcolumn?: null | string;
  bt_datelocal?: string;
  bt_day?: number;
  bt_dayofweek?: number;
  bt_month?: null | string;
  bt_year?: null | string;
  bthr_calendarid?: null | string;
  bthr_date?: string;
  bthr_dayofweek?: null | string;
  bthr_daytype?: number;
  bthr_dow?: null | string;
  bthr_name?: null | string;
  bthr_recordstatus?: number;
}
