import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BFFApiModule } from './_api_bff/bff-api.module';
import { BookingsApiModule } from './_api_bookings/bookings-api.module';
import { CommonApiModule } from './_api_common/common-api.module';
import { CommunicationApiModule } from './_api_communication/communication-api.module';
import { DelegationsApiModule } from './_api_delegations/delegations-api.module';
import { DocumentApiModule } from './_api_document/document-api.module';
import { EngageApiModule } from './_api_engage/engage-api.module';
import { EquipmentApiModule } from './_api_equipment/equipment-api.module';
import { FleetApiModule } from './_api_fleet/fleet-api.module';
import { GiftsApiModule } from './_api_gifts/gifts-api.module';
import { LearnApiModule } from './_api_learn/learn-api.module';
import { SharedApiModule } from './_api_legacy/shared-api.module';
import { PerformanceReviewApiModule } from './_api_performance-review/performance-review-api.module';
import { PolicyApiModule } from './_api_policy/policy-api.module';
import { ProcessesApiModule } from './_api_processes/processes-api.module';
import { ProfileApiModule } from './_api_profile/profile-api.module';
import { QualificationsApiModule } from './_api_qualifications/qualifications-api.module';
import { ReportingApiModule } from './_api_reporting/reporting-api.module';
import { StructureApiModule } from './_api_structure/structure-api.module';
import { SupportApiModule } from './_api_support/support-api.module';
import { TasksApiModule } from './_api_tasks/tasks-api.module';
import { TimeOffApiModule } from './_api_timeoff/time-off-api.module';
import { TimeTrackingApiModule } from './_api_timetracking/time-tracking-api.module';
import { UserApiModule } from './_api_user/user-api.module';
import { WebDynamicApiModule } from './_api_webdynamic/web-dynamic-api.module';
import { ModuleImportGuard } from './module-import-guard';
import { TemplateApiModule } from './_api_template/template-api.module';

@NgModule({
  imports: [CommonModule],
})
export class ApiModule {
  constructor(@Optional() @SkipSelf() parentModule: ApiModule) {
    ModuleImportGuard(parentModule, 'ApiModule');
  }

  static forRoot(environment: any) {
    let baseUrl = '';

    if (!environment.envConfig.includes('local')) {
      baseUrl = environment.redirectUri + '/';
    }

    return {
      ngModule: ApiModule,
      providers: [
        ...BFFApiModule.forRoot({ rootUrl: environment.redirectUri }).providers,
        ...BookingsApiModule.forRoot({ rootUrl: baseUrl + environment.features.bookingsApiUrl }).providers,
        ...CommonApiModule.forRoot({ rootUrl: baseUrl + environment.features.commonApiUrl }).providers,
        ...CommunicationApiModule.forRoot({ rootUrl: baseUrl + environment.features.communicationApiUrl }).providers,
        ...DelegationsApiModule.forRoot({ rootUrl: baseUrl + environment.features.delegationsApiUrl }).providers,
        ...DocumentApiModule.forRoot({ rootUrl: baseUrl + environment.features.documentApiUrl }).providers,
        ...EngageApiModule.forRoot({ rootUrl: baseUrl + environment.features.engageApiUrl }).providers,
        ...EquipmentApiModule.forRoot({ rootUrl: baseUrl + environment.features.equipmentApiUrl }).providers,
        ...FleetApiModule.forRoot({ rootUrl: baseUrl + environment.features.fleetApiUrl }).providers,
        ...GiftsApiModule.forRoot({ rootUrl: baseUrl + environment.features.giftsApiUrl }).providers,
        ...LearnApiModule.forRoot({ rootUrl: baseUrl + environment.features.learnApiUrl }).providers,
        ...SharedApiModule.forRoot({ rootUrl: baseUrl + environment.features.legacyApiUrl }).providers,
        ...PerformanceReviewApiModule.forRoot({ rootUrl: baseUrl + environment.features.performanceReviewApiUrl }).providers,
        ...PolicyApiModule.forRoot({ rootUrl: baseUrl + environment.features.policyApiUrl }).providers,
        ...ProcessesApiModule.forRoot({ rootUrl: baseUrl + environment.features.processesApiUrl }).providers,
        ...ProfileApiModule.forRoot({ rootUrl: baseUrl + environment.features.profileApiUrl }).providers,
        ...QualificationsApiModule.forRoot({ rootUrl: baseUrl + environment.features.qualificationsApiUrl }).providers,
        ...ReportingApiModule.forRoot({ rootUrl: baseUrl + environment.features.reportingApiUrl }).providers,
        ...StructureApiModule.forRoot({ rootUrl: baseUrl + environment.features.structureApiUrl }).providers,
        ...SupportApiModule.forRoot({ rootUrl: baseUrl + environment.features.supportApiUrl }).providers,
        ...TasksApiModule.forRoot({ rootUrl: baseUrl + environment.features.tasksApiUrl }).providers,
        ...TemplateApiModule.forRoot({ rootUrl: baseUrl + environment.features.templateApiUrl }).providers,
        ...TimeOffApiModule.forRoot({ rootUrl: baseUrl + environment.features.timeoffApiUrl }).providers,
        ...TimeTrackingApiModule.forRoot({ rootUrl: baseUrl + environment.features.timetrackingApiUrl }).providers,
        ...UserApiModule.forRoot({ rootUrl: baseUrl + environment.features.userApiUrl }).providers,
        ...WebDynamicApiModule.forRoot({ rootUrl: baseUrl + environment.features.webDynamicApiUrl }).providers,
      ],
      imports: [
        BFFApiModule.forRoot({ rootUrl: baseUrl + environment.redirectUri }),
        BookingsApiModule.forRoot({ rootUrl: baseUrl + environment.features.bookingsApiUrl }),
        CommonApiModule.forRoot({ rootUrl: baseUrl + environment.features.commonApiUrl }),
        CommunicationApiModule.forRoot({ rootUrl: baseUrl + environment.features.communicationApiUrl }),
        DelegationsApiModule.forRoot({ rootUrl: baseUrl + environment.features.delegationsApiUrl }),
        DocumentApiModule.forRoot({ rootUrl: baseUrl + environment.features.documentApiUrl }),
        EngageApiModule.forRoot({ rootUrl: baseUrl + environment.features.engageApiUrl }),
        EquipmentApiModule.forRoot({ rootUrl: baseUrl + environment.features.equipmentApiUrl }),
        FleetApiModule.forRoot({ rootUrl: baseUrl + environment.features.fleetApiUrl }),
        GiftsApiModule.forRoot({ rootUrl: baseUrl + environment.features.giftsApiUrl }),
        LearnApiModule.forRoot({ rootUrl: baseUrl + environment.features.learnApiUrl }),
        SharedApiModule.forRoot({ rootUrl: baseUrl + environment.features.legacyApiUrl }),
        PerformanceReviewApiModule.forRoot({ rootUrl: baseUrl + environment.features.performanceReviewApiUrl }),
        PolicyApiModule.forRoot({ rootUrl: baseUrl + environment.features.policyApiUrl }),
        ProcessesApiModule.forRoot({ rootUrl: baseUrl + environment.features.processesApiUrl }),
        ProfileApiModule.forRoot({ rootUrl: baseUrl + environment.features.profileApiUrl }),
        QualificationsApiModule.forRoot({ rootUrl: baseUrl + environment.features.qualificationsApiUrl }),
        ReportingApiModule.forRoot({ rootUrl: baseUrl + environment.features.reportingApiUrl }),
        StructureApiModule.forRoot({ rootUrl: baseUrl + environment.features.structureApiUrl }),
        SupportApiModule.forRoot({ rootUrl: baseUrl + environment.features.supportApiUrl }),
        TasksApiModule.forRoot({ rootUrl: baseUrl + environment.features.tasksApiUrl }),
        TemplateApiModule.forRoot({ rootUrl: baseUrl + environment.features.templateApiUrl }),
        TimeOffApiModule.forRoot({ rootUrl: baseUrl + environment.features.timeoffApiUrl }),
        TimeTrackingApiModule.forRoot({ rootUrl: baseUrl + environment.features.timetrackingApiUrl }),
        UserApiModule.forRoot({ rootUrl: baseUrl + environment.features.userApiUrl }),
        WebDynamicApiModule.forRoot({ rootUrl: baseUrl + environment.features.webDynamicApiUrl }),
      ],
      exports: [
        BFFApiModule.forRoot({ rootUrl: baseUrl + environment.redirectUri }),
        BookingsApiModule.forRoot({ rootUrl: baseUrl + environment.features.bookingsApiUrl }),
        CommonApiModule.forRoot({ rootUrl: baseUrl + environment.features.commonApiUrl }),
        CommunicationApiModule.forRoot({ rootUrl: baseUrl + environment.features.communicationApiUrl }),
        DelegationsApiModule.forRoot({ rootUrl: baseUrl + environment.features.delegationsApiUrl }),
        DocumentApiModule.forRoot({ rootUrl: baseUrl + environment.features.documentApiUrl }),
        EngageApiModule.forRoot({ rootUrl: baseUrl + environment.features.engageApiUrl }),
        EquipmentApiModule.forRoot({ rootUrl: baseUrl + environment.features.equipmentApiUrl }),
        FleetApiModule.forRoot({ rootUrl: baseUrl + environment.features.fleetApiUrl }),
        GiftsApiModule.forRoot({ rootUrl: baseUrl + environment.features.giftsApiUrl }),
        LearnApiModule.forRoot({ rootUrl: baseUrl + environment.features.learnApiUrl }),
        SharedApiModule.forRoot({ rootUrl: baseUrl + environment.features.legacyApiUrl }),
        PerformanceReviewApiModule.forRoot({ rootUrl: baseUrl + environment.features.performanceReviewApiUrl }),
        PolicyApiModule.forRoot({ rootUrl: baseUrl + environment.features.policyApiUrl }),
        ProcessesApiModule.forRoot({ rootUrl: baseUrl + environment.features.processesApiUrl }),
        ProfileApiModule.forRoot({ rootUrl: baseUrl + environment.features.profileApiUrl }),
        QualificationsApiModule.forRoot({ rootUrl: baseUrl + environment.features.qualificationsApiUrl }),
        ReportingApiModule.forRoot({ rootUrl: baseUrl + environment.features.reportingApiUrl }),
        StructureApiModule.forRoot({ rootUrl: baseUrl + environment.features.structureApiUrl }),
        SupportApiModule.forRoot({ rootUrl: baseUrl + environment.features.supportApiUrl }),
        TasksApiModule.forRoot({ rootUrl: baseUrl + environment.features.tasksApiUrl }),
        TemplateApiModule.forRoot({ rootUrl: baseUrl + environment.features.templateApiUrl }),
        TimeOffApiModule.forRoot({ rootUrl: baseUrl + environment.features.timeoffApiUrl }),
        TimeTrackingApiModule.forRoot({ rootUrl: baseUrl + environment.features.timetrackingApiUrl }),
        UserApiModule.forRoot({ rootUrl: baseUrl + environment.features.userApiUrl }),
        WebDynamicApiModule.forRoot({ rootUrl: baseUrl + environment.features.webDynamicApiUrl }),
      ],
    };
  }
}
