/* tslint:disable */
/* eslint-disable */
import { VerdeOnlineTrainingSteps } from './verde-online-training-steps';
export interface VerdeOnlineTrainingConfig {
  _bt_legalentity_value?: null | string;
  bt_autoload?: null | boolean;
  bt_bt_verdeonlinetrainingsteps_VerdeOnlineTr?: null | Array<VerdeOnlineTrainingSteps>;
  bt_configenum?: null | string;
  bt_description?: null | string;
  bt_enabledonotshowagain?: null | boolean;
  bt_enddate?: null | string;
  bt_missingsteps?: null | number;
  bt_module?: null | number;
  bt_moduleautoload?: null | boolean;
  bt_startdate?: null | string;
  bt_stepcount?: null | number;
  bt_stepcounter?: null | number;
  bt_verdeonlinetrainingconfig?: null | string;
  bt_verdeonlinetrainingconfigid?: null | string;
  odataLegalEntity?: null | string;
  odataModule?: null | string;
}
