/* tslint:disable */
/* eslint-disable */
export interface VerdeOnlineTraining {
  _bt_employee_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_onlinetrainingconfig_value?: null | string;
  bt_name?: null | string;
  bt_verdeonlinetrainingid?: null | string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataOnlineTrainingConfig?: null | string;
  odataPosEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostOnlineTrainingConfig?: null | string;
}
