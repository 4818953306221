/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QualificationsApiConfiguration, QualificationsApiConfigurationParams } from './qualifications-api-configuration';

import { QualificationsApiQualificationsService } from './services/qualifications-api-qualifications.service';
import { QualificationsApiVersionService } from './services/qualifications-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [QualificationsApiQualificationsService, QualificationsApiVersionService, QualificationsApiConfiguration],
})
export class QualificationsApiModule {
  static forRoot(params: QualificationsApiConfigurationParams): ModuleWithProviders<QualificationsApiModule> {
    return {
      ngModule: QualificationsApiModule,
      providers: [
        {
          provide: QualificationsApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: QualificationsApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('QualificationsApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
