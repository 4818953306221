/* tslint:disable */
/* eslint-disable */
export interface Isolation {
  _bthr_covid19test_value?: null | string;
  bt_latestresults?: null | number;
  bt_testresult?: null | number;
  bthr_covid19tested?: null | boolean;
  bthr_days?: null | number;
  bthr_enddate?: null | string;
  bthr_issolationid?: null | string;
  bthr_name?: null | string;
  bthr_possibleinfecteddate?: null | string;
  bthr_reason?: null | number;
  bthr_startdate?: null | string;
  odataCovidTest?: null | string;
  odataCovidTestForPost?: null | string;
  odataCovidTestResult?: null | string;
  odataCovidTestYesNo?: null | string;
  odataEmployee?: null | string;
  odataLatestTestResults?: null | string;
  odataLegalEntity?: null | string;
  odataReason?: null | string;
}
