/* tslint:disable */
/* eslint-disable */
export interface FleetMake {
  _bt_legalentity_value?: null | string;
  bt_makeunique?: null | string;
  bthr_make?: null | string;
  bthr_recordstatus?: null | number;
  bthr_vehiclemakeid?: null | string;
  odataLegalEntity?: null | string;
  odataPostLegalEntity?: null | string;
  odataRecordStatus?: null | string;
}
