import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

// Queries
import { PerformanceReviewPatchPerformanceReviewsCommand } from '@verde/api';

// Store
//Related
import * as fromPerformanceReviewRelatedReducer from '../store/reducers/performance-review-related.reducer';
// PerformanceReview
import * as PerformanceReviewActions from '../store/actions/performance-review.actions';
import * as fromPerformanceReviewReducer from '../store/reducers/performance-review.reducer';

@Injectable({
  providedIn: 'root',
})
export class StorePerformanceReviewService {
  // PerformanceReview
  performanceReviewLoading$: Observable<boolean>;
  performanceReviewObjects$: Observable<any[]>;

  constructor(private store: Store<fromPerformanceReviewRelatedReducer.PerformanceReviewRelatedState>) {
    // PerformanceReview
    this.performanceReviewLoading$ = this.store.pipe(select(fromPerformanceReviewReducer.isPerformanceReviewLoading));
    this.performanceReviewObjects$ = this.store.pipe(select(fromPerformanceReviewReducer.getAllPerformanceReview));
  }

  //public getPerformanceReview(body: TemplateGetPerformanceReviewsQuery) {
  //  this.store.dispatch(new PerformanceReviewActions.PerformanceReviewsGet(body));
  //}

  // Clear
  performanceReviewClear() {
    this.store.dispatch(new PerformanceReviewActions.PerformanceReviewClear(null));
  }

  //// Upload
  //uploadPerformanceReviews(payload: TemplateUploadPerformanceReviewCommand) {
  //  this.store.dispatch(new PerformanceReviewActions.PerformanceReviewsUpload(payload));
  //}

  // PerformanceReview update
  updatePerformanceReview(payload: PerformanceReviewPatchPerformanceReviewsCommand): Observable<boolean> {
    this.store.dispatch(new PerformanceReviewActions.PerformanceReviewUpdate(payload));

    return;
  }
}
