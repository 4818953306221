/* tslint:disable */
/* eslint-disable */
import { Codes } from './codes';
export interface DriversLicense {
  _bt_country_value?: null | string;
  _bt_type_value?: null | string;
  bt_backimage?: null | string;
  bt_backimagename?: null | string;
  bt_countryidissued?: null | string;
  bt_frontimage?: null | string;
  bt_frontimagename?: null | string;
  bt_idnumbervalid?: null | boolean;
  bt_licensenocaptured?: null | string;
  bt_licensevalidated?: null | boolean;
  bt_processfrombarcode?: null | boolean;
  bt_recordname?: null | string;
  bt_scanbirthdate?: null | string;
  bt_scangender?: null | string;
  bt_scanidnumber?: null | string;
  bt_scanidtype?: null | string;
  bt_scaninitials?: null | string;
  bt_scansurname?: null | string;
  bthr_codes?: null | string;
  bthr_driverslicenseid?: null | string;
  bthr_firstissue?: null | string;
  bthr_licensenonumber_t_?: null | string;
  bthr_recordstatus?: null | number;
  bthr_restrictions?: null | string;
  bthr_validfrom?: null | string;
  bthr_validto?: null | string;
  bthr_vehiclerestrictionsmultiselect?: null | string;
  licenseCodes?: null | Array<Codes>;
  odataCodes?: null | string;
  odataCountry?: null | string;
  odataLicenseType?: null | string;
  odataPostCountry?: null | string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostLicenseType?: null | string;
  odataRecordStatus?: null | string;
  odataRestrictions?: null | string;
  odataStatus?: null | string;
  odataVehicleRestrictions?: null | string;
  scanEmpty?: null | boolean;
  scanIDValid?: null | boolean;
}
