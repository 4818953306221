/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { VerdeTask } from '../models/verde-task';
import { DvTimeOffRequest } from '../models/dv-time-off-request';

@Injectable({
  providedIn: 'root',
})
export class SharedApiTaskService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getVerdeTasksListStatusById
   */
  static readonly GetVerdeTasksListStatusByIdPath = '/api/Task/taskliststatusbyid/{taskID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeTasksListStatusById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeTasksListStatusById$Response(params: { subscriptionKey?: string; APIURL?: string; taskID: string }): Observable<StrictHttpResponse<VerdeTask>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTaskService.GetVerdeTasksListStatusByIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('taskID', params.taskID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VerdeTask>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeTasksListStatusById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeTasksListStatusById(params: { subscriptionKey?: string; APIURL?: string; taskID: string }): Observable<VerdeTask> {
    return this.getVerdeTasksListStatusById$Response(params).pipe(map((r: StrictHttpResponse<VerdeTask>) => r.body as VerdeTask));
  }

  /**
   * Path part for operation getVerdeTasksByAssignedLink
   */
  static readonly GetVerdeTasksByAssignedLinkPath = '/api/Task/taskbyassignedlink/{employeeID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeTasksByAssignedLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeTasksByAssignedLink$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VerdeTask>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTaskService.GetVerdeTasksByAssignedLinkPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeID', params.employeeID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdeTask>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeTasksByAssignedLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeTasksByAssignedLink(params: { subscriptionKey?: string; APIURL?: string; employeeID: string; legalEntityID: string }): Observable<Array<VerdeTask>> {
    return this.getVerdeTasksByAssignedLink$Response(params).pipe(map((r: StrictHttpResponse<Array<VerdeTask>>) => r.body as Array<VerdeTask>));
  }

  /**
   * Path part for operation getVerdeTaskClosed
   */
  static readonly GetVerdeTaskClosedPath = '/api/Task/verdetaskclosed/{employeeID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeTaskClosed()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeTaskClosed$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VerdeTask>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTaskService.GetVerdeTaskClosedPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeID', params.employeeID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdeTask>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeTaskClosed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeTaskClosed(params: { subscriptionKey?: string; APIURL?: string; employeeID: string; legalEntityID: string }): Observable<Array<VerdeTask>> {
    return this.getVerdeTaskClosed$Response(params).pipe(map((r: StrictHttpResponse<Array<VerdeTask>>) => r.body as Array<VerdeTask>));
  }

  /**
   * Path part for operation getVerdeTasksForFollowUp
   */
  static readonly GetVerdeTasksForFollowUpPath = '/api/Task/tasklistfollowup/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeTasksForFollowUp()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeTasksForFollowUp$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VerdeTask>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTaskService.GetVerdeTasksForFollowUpPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdeTask>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeTasksForFollowUp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeTasksForFollowUp(params: { subscriptionKey?: string; APIURL?: string; userID: string; legalEntityID: string }): Observable<Array<VerdeTask>> {
    return this.getVerdeTasksForFollowUp$Response(params).pipe(map((r: StrictHttpResponse<Array<VerdeTask>>) => r.body as Array<VerdeTask>));
  }

  /**
   * Path part for operation getVerdeTasksBySubgroup
   */
  static readonly GetVerdeTasksBySubgroupPath = '/api/Task/tasklistsubgroup/{userID}/{legalEntityID}/{subGroupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeTasksBySubgroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeTasksBySubgroup$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
    subGroupID: string;
  }): Observable<StrictHttpResponse<Array<VerdeTask>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTaskService.GetVerdeTasksBySubgroupPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('subGroupID', params.subGroupID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdeTask>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeTasksBySubgroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeTasksBySubgroup(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
    subGroupID: string;
  }): Observable<Array<VerdeTask>> {
    return this.getVerdeTasksBySubgroup$Response(params).pipe(map((r: StrictHttpResponse<Array<VerdeTask>>) => r.body as Array<VerdeTask>));
  }

  /**
   * Path part for operation patchVerdeTaskList
   */
  static readonly PatchVerdeTaskListPath = '/api/Task/patch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVerdeTaskList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeTaskList$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeTask }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTaskService.PatchVerdeTaskListPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVerdeTaskList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeTaskList(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeTask }): Observable<boolean> {
    return this.patchVerdeTaskList$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postVerdeTaskList
   */
  static readonly PostVerdeTaskListPath = '/api/Task/post';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVerdeTaskList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeTaskList$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeTask }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTaskService.PostVerdeTaskListPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVerdeTaskList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeTaskList(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeTask }): Observable<boolean> {
    return this.postVerdeTaskList$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getVerdeTaskApprovalById
   */
  static readonly GetVerdeTaskApprovalByIdPath = '/api/Task/taskapprovalbyID/{tableName}/{columnName}/{recordID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeTaskApprovalById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeTaskApprovalById$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    tableName: string;
    columnName: string;
    recordID: string;
  }): Observable<StrictHttpResponse<DvTimeOffRequest>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTaskService.GetVerdeTaskApprovalByIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('tableName', params.tableName, {});
      rb.path('columnName', params.columnName, {});
      rb.path('recordID', params.recordID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DvTimeOffRequest>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeTaskApprovalById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeTaskApprovalById(params: {
    subscriptionKey?: string;
    APIURL?: string;
    tableName: string;
    columnName: string;
    recordID: string;
  }): Observable<DvTimeOffRequest> {
    return this.getVerdeTaskApprovalById$Response(params).pipe(map((r: StrictHttpResponse<DvTimeOffRequest>) => r.body as DvTimeOffRequest));
  }
}
