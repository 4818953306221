/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LocationsNew } from '../models/locations-new';
import { DvCountries } from '../models/dv-countries';

@Injectable({
  providedIn: 'root',
})
export class SharedApiCountriesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllCountries
   */
  static readonly GetAllCountriesPath = '/api/Countries/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCountries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCountries$Response(params?: { subscriptionKey?: string; APIURL?: string }): Observable<StrictHttpResponse<Array<DvCountries>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCountriesService.GetAllCountriesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvCountries>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllCountries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCountries(params?: { subscriptionKey?: string; APIURL?: string }): Observable<Array<DvCountries>> {
    return this.getAllCountries$Response(params).pipe(map((r: StrictHttpResponse<Array<DvCountries>>) => r.body as Array<DvCountries>));
  }

  /**
   * Path part for operation getLocationsNew
   */
  static readonly GetLocationsNewPath = '/api/Countries/locations/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationsNew()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationsNew$Response(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<StrictHttpResponse<Array<LocationsNew>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiCountriesService.GetLocationsNewPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<LocationsNew>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLocationsNew$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationsNew(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<LocationsNew>> {
    return this.getLocationsNew$Response(params).pipe(map((r: StrictHttpResponse<Array<LocationsNew>>) => r.body as Array<LocationsNew>));
  }
}
