/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvOrganisations } from '../models/dv-organisations';
import { DvOrganisationsPost } from '../models/dv-organisations-post';

@Injectable({
  providedIn: 'root',
})
export class SharedApiOrganisationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllOrganisations
   */
  static readonly GetAllOrganisationsPath = '/api/Organisations/get/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllOrganisations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllOrganisations$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<DvOrganisations>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiOrganisationsService.GetAllOrganisationsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvOrganisations>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllOrganisations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllOrganisations(params: { subscriptionKey?: string; APIURL?: string; legalEntityGuid: string }): Observable<Array<DvOrganisations>> {
    return this.getAllOrganisations$Response(params).pipe(map((r: StrictHttpResponse<Array<DvOrganisations>>) => r.body as Array<DvOrganisations>));
  }

  /**
   * Path part for operation postOrganisations
   */
  static readonly PostOrganisationsPath = '/api/Organisations/post';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrganisations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postOrganisations$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: DvOrganisationsPost }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiOrganisationsService.PostOrganisationsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postOrganisations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postOrganisations(params?: { subscriptionKey?: string; APIURL?: string; body?: DvOrganisationsPost }): Observable<boolean> {
    return this.postOrganisations$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
