import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ProfileApiProfileService } from '@verde/api';
import { UserService } from '@verde/core';
import { VerdeApprovalService } from '@verde/shared';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'verde-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, AfterViewInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject();

  dynamicData: any = {};

  resultMessage: string = '';
  showConfirmationModal: boolean = false;

  changesMade: boolean = false;

  constructor(
    private userService: UserService,
    private sidebarService: VerdeApprovalService,
    private cdr: ChangeDetectorRef,
    private profileApiProfileService: ProfileApiProfileService,
  ) {}

  ngOnInit(): void {
    this.sidebarService
      .getDynamicFormData()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        this.dynamicData = data;
      });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  submit(data: any) {
    let body: any = {};

    // Checking if each individual form has been dirty, is valid and is not pristine before assigning values to the body
    if (data.columnOneForm.dirty && data.columnOneForm.valid && !data.columnOneForm.pristine) {
      let properties: any[];

      // Getting a list of property names from each form in order to validate against the same value on the user profile
      properties = Object.keys(data.columnOneForm.value);
      properties.forEach((x) => {
        // Ensuring that there is a value from the form and that it is not the same value as what already exists on the user profile before appending it to the body
        if (
          data.columnOneForm.value[x] !== null &&
          data.columnOneForm.value[x] !== undefined &&
          //this.userNew[x] !== j.columnOneForm.value[x] &&
          data.columnOneFormfields[0].subType !== 'lookup'
        ) {
          body = {
            ...body,
            ...{
              [x]: data.columnOneForm.value[x],
            },
          };
        } else if (
          data.columnOneForm.value[x] !== null &&
          data.columnOneForm.value[x] !== undefined &&
          //this.userNew[x] !== j.columnOneForm.value[x] &&
          data.columnOneFormfields[0].subType === 'lookup'
        ) {
          body = {
            ...body,
            ...{
              [x]: { Id: data.columnOneForm.value[x] },
            },
          };
        }
      });
    }

    if (Object.keys(body).length > 0) {
      data.columnOneFormfields.forEach((field) => {
        if (field?.props?.pattern?.toLowerCase() == 'employee related lookup') {
          body = {
            ...body,
            ...{
              [field.key]: this.userService.user.employeeId,
            },
          };
        }
      });

      let payload = {
        id: this.dynamicData.relatedID,
        tableName: this.dynamicData.relatedTableName,
        requestType: 'insert',
        body: body,
      };

      this.changesMade = true;

      // Passing body through to update API
      this.callUpdate(payload);
    } else {
      this.changesMade = false;
      this.resultMessage = 'You have not made any changes to submit.';
      this.showConfirmationModal = true;
    }
  }

  callUpdate(body: any) {
    this.profileApiProfileService
      .patchDynamic({
        body: { dynamicUpdateBody: body },
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.resultMessage = this.dynamicData?.navigation == 'add' ? 'This record has been added successfully' : 'This record has been updated successfully';
          this.showConfirmationModal = true;
        },
      );
  }

  closeConfirmationModal() {
    this.showConfirmationModal = false;
    this.sidebarService.setSidebarMetadata({ showSidebar: false, changesMade: this.changesMade });
    this.sidebarService.setShowSidebar(false);
  }

  submitDisabled(): boolean {
    let result: boolean = false;
    let invalidForm: boolean = false;

    if (this.dynamicData.columnOneForm.valid && this.dynamicData.columnOneForm.dirty && !this.dynamicData.columnOneForm.pristine) {
      result = false;
    }

    if (!this.dynamicData.columnOneForm.valid) {
      invalidForm = true;
    }

    if (invalidForm) {
      result = true;
    }

    return result;
  }
}
