<!--<ngx-spinner [disableAnimation]="disableAnimation"
             name="vaccinationtab"
             bdColor="rgb(237, 237, 237)"
             size="default"
             color="#6264a7"
             type="ball-clip-rotate"
             [fullScreen]="false">

</ngx-spinner>-->

<!-- #region Body -->

<kendo-grid
  [rowClass]="rowCallback"
  [kendoGridBinding]="vaccinationInfoGridData"
  [resizable]="true"
  [scrollable]="true"
  [groupable]="true"
  [group]="vaccinationInfoGroups"
  (groupChange)="vaccinationInfoGroupChange($event)"
  (dataStateChange)="vaccinationInfoDataStateChange($event)"
  [selectable]="true"
  [kendoGridSelectBy]="vaccinationInfoSelectedCallback"
  [selectedKeys]="vaccinationInfoSelection"
  [sortable]="true"
  [sort]="vaccinationInfoSort"
  (sortChange)="vaccinationInfoSortChange($event)"
>
  <!-- columns for larger devices -->
  <ng-template kendoGridToolbarTemplate>
    <input placeholder="Search all columns" kendoTextBox style="text-transform: none !important" (input)="onVaccinationInfoFilter($event.target.value)" />
    <kendo-grid-spacer></kendo-grid-spacer>
    <button *ngIf="!(deviceTypeService.isMobile$ | async)" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
    <img title="Refresh" class="refresh_icon" src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg" alt="" (click)="refresh()" />
  </ng-template>
  <kendo-grid-column field="bt_EmployeeID.fullNameWithEmpId" title="Full Name" [width]="200"> </kendo-grid-column>
  <kendo-grid-column field="bt_responded" title="Responded" [width]="130"> </kendo-grid-column>
  <kendo-grid-column field="odataRequestAccepted" title="Policy Accepted" [width]="130"> </kendo-grid-column>
  <kendo-grid-column field="odataVaccinationStatus" title="Vaccination Status" [width]="200"> </kendo-grid-column>
  <kendo-grid-command-column title="" width="40" [sticky]="true" [minResizableWidth]="40" [maxResizableWidth]="40">
    <ng-template kendoGridCellTemplate let-dataItem>
      <verde-dots-menu-button
        [items]="dotMenuItems"
        (menuClicked)="menuClicked(dataItem)"
        (menuItemClicked)="menuItemClicked($event, dataItem)"
      ></verde-dots-menu-button>
    </ng-template>
  </kendo-grid-command-column>

  <kendo-grid-excel fileName="VaccinationInfo.xlsx"></kendo-grid-excel>
</kendo-grid>
<!-- #endregion Body -->
<!-- Modals -->
<!-- #region View Team Modal class="modal fade" -->
<div class="vaccination-modal-backdrop" *ngIf="showModal" [@fadeInBackdrop]>
  <div
    class="modal-dialog modal-xl modal-dialog-centered"
    role="document"
    [style.margin-top]="slideDownModal ? '0px' : '-300px'"
    [style.opacity]="slideDownModal ? '1' : '0'"
  >
    <div class="modal-content">
      <div style="position: relative">
        <ngx-spinner
          [disableAnimation]="disableAnimation"
          name="managerteam"
          bdColor="rgb(237, 237, 237)"
          size="default"
          color="#6264a7"
          type="ball-clip-rotate"
          [fullScreen]="false"
        >
        </ngx-spinner>

        <div class="modal-header" app-theme>
          <h5 class="modal-title">{{ selectedManager?.bt_EmployeeID?.fullNameWithEmpId }}'s Team</h5>
          <button kendoButton class="close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body" app-theme>
          <kendo-grid
            [rowClass]="rowCallback"
            [data]="managersTeamGridView"
            [resizable]="true"
            [groupable]="true"
            [group]="managersTeamGroups"
            (groupChange)="managersTeamGroupChange($event)"
            (dataStateChange)="managersTeamDataStateChange($event)"
            [selectable]="true"
            [kendoGridSelectBy]="managersTeamSelectedCallback"
            [selectedKeys]="managersTeamSelection"
            [sortable]="true"
            [sort]="managersTeamSort"
            (sortChange)="managersTeamSortChange($event)"
          >
            <!-- columns for larger devices -->
            <kendo-grid-column field="bt_EmployeeID.fullNameWithEmpId" title="Full Name"> </kendo-grid-column>
            <kendo-grid-column field="bt_responded" title="Responded"> </kendo-grid-column>
            <kendo-grid-column field="odataRequestAccepted" title="Request Accepted"> </kendo-grid-column>
            <kendo-grid-column field="odataVaccinationStatus" title="Vaccination Status"> </kendo-grid-column>
          </kendo-grid>
        </div>

        <div class="modal-footer" app-theme>
          <button kendoButton themeColor="secondary" (click)="closeModal()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- #endregion View Team Modal -->
