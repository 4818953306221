import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';

export interface KendoFormlyTextAreaProps extends FormlyFieldProps {}

@Component({
  selector: 'verde-kendo-formly-textarea',
  template: ` <textarea kendoTextArea [formControl]="formControl" [formlyAttributes]="field"></textarea> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoFormlyTextArea extends FieldType<FieldTypeConfig<KendoFormlyTextAreaProps>> {}
