/* tslint:disable */
/* eslint-disable */
export interface VotesCast {
  _bt_groupvote_value?: null | string;
  bt_votevotescast?: null | string;
  bt_votevotescastid?: null | string;
  odataPostVoteGroup?: null | string;
  odataPostVotedEmployee?: null | string;
  odataPostVotedLegalEntity?: null | string;
  odataPostVoter?: null | string;
  odataPostVoterLegalEntity?: null | string;
}
