import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FleetChecklist } from '@verde/api';
import { BackUrl } from '../../models/back-url-enum';

@Injectable({
  providedIn: 'root',
})
export class ChecklistFormService {
  private _freeTextRequired: boolean = true;
  private _yesNoRequired: boolean = true;
  private _numberRequired: boolean = true;
  private _dateRequired: boolean = true;
  private _dropdownRequired: boolean = true;

  private _fleetChecklist: FleetChecklist;
  private _allFleetChecklists: FleetChecklist[] = [];

  backUrl: string = '';

  get freeTextRequired(): boolean {
    return this._freeTextRequired;
  }

  set freeTextRequired(val: boolean) {
    this._freeTextRequired = val;
  }

  get yesNoRequired(): boolean {
    return this._yesNoRequired;
  }

  set yesNoRequired(val: boolean) {
    this._yesNoRequired = val;
  }

  get numberRequired(): boolean {
    return this._numberRequired;
  }

  set numberRequired(val: boolean) {
    this._numberRequired = val;
  }

  get dateRequired(): boolean {
    return this._dateRequired;
  }

  set dateRequired(val: boolean) {
    this._dateRequired = val;
  }

  get dropdownRequired(): boolean {
    return this._dropdownRequired;
  }

  set dropdownRequired(val: boolean) {
    this._dropdownRequired = val;
  }

  get fleetChecklist(): FleetChecklist {
    return this._fleetChecklist;
  }

  set fleetChecklist(val: FleetChecklist) {
    this._fleetChecklist = val;
  }

  get allFleetChecklists(): FleetChecklist[] {
    return this._allFleetChecklists;
  }

  set allFleetChecklists(val: FleetChecklist[]) {
    this._allFleetChecklists = val;
  }

  constructor(private router: Router) {}

  setAllChecklistsAndNav(
    checklists: FleetChecklist[],
    freeTextRequired: boolean = true,
    yesNoRequired: boolean = true,
    numberRequired: boolean = true,
    dateRequired: boolean = true,
    dropdownRequired: boolean = true,
    navbackRoute: string = BackUrl.FleetDashboard,
  ): void {
    this.allFleetChecklists = checklists;
    this.freeTextRequired = freeTextRequired;
    this.yesNoRequired = yesNoRequired;
    this.numberRequired = numberRequired;
    this.dateRequired = dateRequired;
    this.dropdownRequired = dropdownRequired;

    this.backUrl = navbackRoute;
    this.router.navigateByUrl('/fleet/fleet-checklist/inspection');
  }

  setAndNavToChecklist(
    checklist: FleetChecklist,
    freeTextRequired: boolean = true,
    yesNoRequired: boolean = true,
    numberRequired: boolean = true,
    dateRequired: boolean = true,
    dropdownRequired: boolean = true,
    navbackRoute: string = BackUrl.FleetDashboard,
  ): void {
    this.fleetChecklist = checklist;
    this.freeTextRequired = freeTextRequired;
    this.yesNoRequired = yesNoRequired;
    this.numberRequired = numberRequired;
    this.dateRequired = dateRequired;
    this.dropdownRequired = dropdownRequired;

    this.backUrl = navbackRoute;
    this.router.navigateByUrl('/fleet-checklist/inspection');
  }
}
