import { Action } from '@ngrx/store';
import {
  QualificationsCreateEducationalInstitutionCommand,
  QualificationsGetEducationalInstitutionQuery,
  QualificationsGetReducedEducationalInstitutionQuery,
} from '@verde/api';

export enum EducationalInstitutionActionTypes {
  // Get All EducationalInstitution
  EDUCATIONALINSTITUTION_GET_ALL = '[EducationalInstitution] Get All',
  EDUCATIONALINSTITUTION_GET_ALL_SUCCESS = '[EducationalInstitution] Get All Success',
  EDUCATIONALINSTITUTION_GET_ALL_ERROR = '[EducationalInstitution] Get All Error',

  // Get Reduced EducationalInstitution
  EDUCATIONALINSTITUTION_GET_REDUCED = '[EducationalInstitution] Get Reduced',
  EDUCATIONALINSTITUTION_GET_REDUCED_SUCCESS = '[EducationalInstitution] Get Reduced Success',
  EDUCATIONALINSTITUTION_GET_REDUCED_ERROR = '[EducationalInstitution] Get Reduced Error',

  //  Add EducationalInstitution
  EDUCATIONALINSTITUTION_UPLOAD = '[EducationalInstitution] Upload',
  EDUCATIONALINSTITUTION_UPLOAD_SUCCESS = '[EducationalInstitution] Upload Success',
  EDUCATIONALINSTITUTION_UPLOAD_ERROR = '[EducationalInstitution] Upload Error',

  // Clear All EducationalInstitution
  EDUCATIONALINSTITUTION_CLEAR = '[EducationalInstitution] Clear',
}

//Get All EducationalInstitution
export class EducationalInstitutionGetAll implements Action {
  readonly type = EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_GET_ALL;
  constructor(public body: QualificationsGetEducationalInstitutionQuery) {}
}

export class EducationalInstitutionGetAllSuccess implements Action {
  readonly type = EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_GET_ALL_SUCCESS;
  constructor(public payload: any) {}
}

export class EducationalInstitutionGetAllError implements Action {
  readonly type = EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

//Get Reduced EducationalInstitution
export class EducationalInstitutionGetReduced implements Action {
  readonly type = EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_GET_REDUCED;
  constructor(public body: QualificationsGetReducedEducationalInstitutionQuery) {}
}

export class EducationalInstitutionGetReducedSuccess implements Action {
  readonly type = EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_GET_REDUCED_SUCCESS;
  constructor(public payload: any) {}
}

export class EducationalInstitutionGetReducedError implements Action {
  readonly type = EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_GET_REDUCED_ERROR;
  constructor(public payload: any) {}
}

// UPLOAD EducationalInstitution
export class EducationalInstitutionUpload implements Action {
  readonly type = EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_UPLOAD;
  constructor(public payload: QualificationsCreateEducationalInstitutionCommand) {}
}

export class EducationalInstitutionUploadSuccess implements Action {
  readonly type = EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_UPLOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class EducationalInstitutionUploadError implements Action {
  readonly type = EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_UPLOAD_ERROR;
  constructor(public payload: any) {}
}

// Clear All
export class EducationalInstitutionClear implements Action {
  readonly type = EducationalInstitutionActionTypes.EDUCATIONALINSTITUTION_CLEAR;
  constructor(public payload: any) {}
}

export type EducationalInstitutionActions =
  // Get All EducationalInstitution
  | EducationalInstitutionGetAll
  | EducationalInstitutionGetAllSuccess
  | EducationalInstitutionGetAllError
  // Get Reduced EducationalInstitution
  | EducationalInstitutionGetReduced
  | EducationalInstitutionGetReducedSuccess
  | EducationalInstitutionGetReducedError
  // Structure Change Request Upload
  | EducationalInstitutionUpload
  | EducationalInstitutionUploadSuccess
  | EducationalInstitutionUploadError
  // Clear All EducationalInstitution
  | EducationalInstitutionClear;
