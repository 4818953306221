import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { WebDynamicApiWebService, WebDynamicGetDynamicFormLookupQuery } from '@verde/api';
import { DeviceTypeService, UserService } from '@verde/core';
import { SidePanelWidth, VerdeApprovalService } from '@verde/shared';
import { BehaviorSubject, Subject, take, takeUntil, tap } from 'rxjs';
import { WebDynamicFormType } from '../enums/web-dynamic-form-type.enum';
import { WebDynamicModel, WebDynamicFormModel, WebDynamicSidePanelArgs } from '../models/web-dynamic-form.model';
import { WebDynamicFormAction } from '../enums/web-dynamic-form-action.enum';
import { IntlService } from '@progress/kendo-angular-intl';

@Injectable({
  providedIn: 'root',
})
export class WebDynamicService implements OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  webDynamicFormModel$: BehaviorSubject<WebDynamicFormModel | undefined> = new BehaviorSubject<WebDynamicFormModel | undefined>(undefined);

  constructor(
    private http: HttpClient,
    private sidebarService: VerdeApprovalService,
    public userService: UserService,
    private webDynamicApiWebService: WebDynamicApiWebService,
    private formlyJsonschema: FormlyJsonschema,
    public deviceTypeService: DeviceTypeService,
    private router: Router,
    private intl: IntlService,
    @Inject('environment') private environment: any,
  ) {}

  setDynamicForm(webDynamicModel: WebDynamicModel) {
    const sasToken = `si=web-dynamic&spr=https&sv=2022-11-02&sr=c&sig=sj6Gc4lRtj77AGcsoqYS8br7oxmBMzPJsVEpIpOR1v4%3D`;
    this.http
      .get<any>(`https://verdedynamicformsjson.blob.core.windows.net/$web/${this.environment.env}/${webDynamicModel.formFile}.json?${sasToken}`)
      .pipe(
        tap(({ schema, additional }) => {
          const form = new FormGroup({});
          schema = this.replacePlaceholdersRecursively(schema, this.userService.config$.getValue());
          const fields = [
            this.formlyJsonschema.toFieldConfig(schema, {
              map: (mappedField: FormlyFieldConfig, mapSource: any) => {
                // Prepare default options
                let extraProps = {};
                if (mappedField.props.options === undefined) {
                  if (mapSource.type === 'boolean') {
                    extraProps = {
                      options: [
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                      ],
                    };
                  }

                  if (mapSource.type === 'select') {
                    extraProps = {
                      options: this.webDynamicApiWebService.dynamicFormLookupData({
                        body: mapSource.props.lookUp as WebDynamicGetDynamicFormLookupQuery,
                      }),
                    };

                    mappedField.hooks = {
                      ...mappedField.hooks,
                      onChanges: (field: FormlyFieldConfig) => this.onFieldChanges(form, fields, field),
                    };
                  }

                  if (mapSource.type === 'grid') {
                    extraProps = {
                      options: this.webDynamicApiWebService.dynamicFormLookupData({
                        body: mapSource.props.lookUp as WebDynamicGetDynamicFormLookupQuery,
                      }),
                    };
                  }
                }

                if (mapSource.type === 'textarea') {
                  mappedField.className = 'width-100'; // Individual fields
                }

                if (mapSource.type === 'date') {
                  if (mapSource.default === 'today') {
                    mapSource.default = new Date();
                  } else if (mapSource.default) {
                    const customDate = this.intl.formatDate(mapSource.default, 'yyyy/MM/dd');
                    if (customDate) {
                      mapSource.default = new Date(customDate);
                    }
                  }
                }

                // Ensure we can use custom properties in our schema.
                mappedField = {
                  ...mappedField,
                  props: {
                    ...mappedField.props,
                    ...mapSource.props,
                    ...extraProps,
                    readonly: (mapSource.readOnly ?? false) || webDynamicModel.action === WebDynamicFormAction.Read,
                  },
                  expressions: {
                    ...(mapSource.props?.expressions ?? {}),
                  },
                  defaultValue: mapSource.default,
                };

                mappedField.templateOptions = {
                  ...mappedField.props,
                };

                return mappedField;
              },
            }),
          ];

          const title = fields[0].props.label;

          fields[0].props.label = null; // Clear the title that is shown at the wrong place
          fields[0].templateOptions.label = null;

          this.webDynamicFormModel$.next({
            ...webDynamicModel,
            form,
            schema,
            fields,
            additional: additional,
          });

          switch (webDynamicModel.formType) {
            case WebDynamicFormType.SidePanel: {
              const args = webDynamicModel.args as WebDynamicSidePanelArgs;
              this.sidebarService.setShowSidebar(args.visible);
              this.sidebarService.setTitleTag(schema.title);
              const size = this.deviceTypeService.isMobile() === false ? additional?.formWidth || args.size || SidePanelWidth.Half : SidePanelWidth.Full;
              this.sidebarService.setSidebarSize(Number(size));
              this.sidebarService.setSidebarType('verdeDynamicForm');
              break;
            }
            case WebDynamicFormType.Grid: {
              this.router.navigate([this.router.url, 'web_dynamic'], {
                skipLocationChange: true,
                state: { breadcrumb: { title, path: 'web_dynamic' } },
              });
              break;
            }
          }
        }),
        take(1),
      )
      .subscribe();
  }

  private replacePlaceholdersRecursively(obj: any, config: any): any {
    if (obj && typeof obj === 'object') {
      for (const key of Object.keys(obj)) {
        if (typeof obj[key] === 'string') {
          obj[key] = this.replacePlaceholders(obj[key], config);
        } else if (Array.isArray(obj[key])) {
          obj[key] = obj[key].map((item) => this.replacePlaceholdersRecursively(item, config));
        } else if (typeof obj[key] === 'object') {
          obj[key] = this.replacePlaceholdersRecursively(obj[key], config);
        }
      }
    }
    return obj;
  }

  private getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  private replacePlaceholders(template: string, config: any): string | boolean {
    // Replace {none} with an empty string
    template = template.replace(/\{none\}/g, '');

    // Replace placeholders
    template = template.replace(/\$\{(.*?)\}/g, (_, path) => {
      const value = this.getNestedValue(config, path);
      return value !== undefined ? value : '';
    });

    // Trim whitespace
    template = template.trim();

    // Cast "true" or "false" to boolean
    if (template === 'true') return true;
    if (template === 'false') return false;

    return template; // Return the final string if not boolean
  }

  private findControlByKey(control: AbstractControl, key: string): AbstractControl | null {
    if (control instanceof FormGroup) {
      if (key in control.controls) {
        return control.controls[key];
      }
      // Recursively search within nested FormGroups
      for (const child of Object.values(control.controls)) {
        const result = this.findControlByKey(child, key);
        if (result) {
          return result;
        }
      }
    } else if (control instanceof FormArray) {
      // Recursively search within FormArray elements
      for (const child of control.controls) {
        const result = this.findControlByKey(child, key);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  private onFieldChanges(form: FormGroup, fields: FormlyFieldConfig[], field: FormlyFieldConfig) {
    if (field.key) {
      const key = field.key as string;
      const control = this.findControlByKey(form, key);
      if (control) {
        control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
          if (value) {
            this.updateDependentFields(fields, key, value);
          }
        });
      }
    }
  }

  private updateDependentFields(fields: FormlyFieldConfig[], property: string, value: any) {
    fields.forEach((field) => {
      if (
        field.props.lookUp &&
        field.props.lookUp.dependency &&
        Object.keys(field.props.lookUp.dependency).length > 0 &&
        field.props.lookUp.dependency.property === property
      ) {
        const updatedLookUp = {
          ...field.props.lookUp,
          dependency: {
            ...field.props.lookUp.dependency,
            value,
          },
        };
        field.props.lookUp = updatedLookUp;
        field.templateOptions.lookUp = updatedLookUp;
      }

      if (field.fieldGroup) {
        this.updateDependentFields(field.fieldGroup, property, value);
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
