import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  FleetApiFleetService,
  FleetChecklist,
  FleetFleetServiceManualUpdate,
  SharedApiFleetMovementService,
  SharedApiVehiclesService,
  VehiclesFiltered,
} from '@verde/api';
import { UserService } from '@verde/core';
import { VerdeApprovalService } from '@verde/shared';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'verde-fleet-odo-update',
  templateUrl: './fleet-odo-update.component.html',
  styleUrls: ['./fleet-odo-update.component.scss'],
})
export class FleetOdoUpdateComponent implements OnInit, AfterViewInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject();

  allFleetChecklists: FleetChecklist[] = [];
  selectedFleetItem: VehiclesFiltered = {};
  isFleetManager: boolean = false;

  showConfirmationModal: boolean = false;
  showCheckModal: boolean = false;

  nextServiceDate: string = '';
  todaysDate: string = moment().format('YYYY/MM/DD');

  latestOdoReading: number = 0;

  constructor(
    private configServie: UserService,
    private spinner: NgxSpinnerService,
    private sidebarService: VerdeApprovalService,
    private fleetApiFleetService: FleetApiFleetService,
    private sharedApiVehiclesService: SharedApiVehiclesService,
    private sharedApiFleetMovementService: SharedApiFleetMovementService,
  ) {}

  ngOnInit(): void {
    this.spinner.show('fleetodoupdate');
    this.sidebarService
      .getSelectedFleetOdoData()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        if (Object.keys(item).length > 0) {
          this.allFleetChecklists = item.checklists;
          this.isFleetManager = item.isFleetManager;
          console.log('allFleetChecklists', this.allFleetChecklists);

          this.getFleetItem();
        }
      });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  getFleetItem() {
    this.sharedApiVehiclesService
      .getFleetItemById({ itemID: this.allFleetChecklists[0]?._bt_regno_value! })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.selectedFleetItem = ret;
          console.log('selectedFleetItem', this.selectedFleetItem);
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.selectedFleetItem.bthr_lastservicedate) {
            this.nextServiceDate = moment(this.selectedFleetItem.bthr_lastservicedate)
              .add(this.selectedFleetItem.bt_calculateddaystillnextservice)
              .format('YYYY/MM/DD');
          }

          this.spinner.hide('fleetodoupdate');
        },
      );
  }

  postEntityTransfer(myForm: any) {
    // this.showCheckModal = false;

    this.allFleetChecklists.map((checklist) => {
      const checklistModification: FleetChecklist = this.mapToSummarySubmit(checklist);
      if (this.isFleetManager) {
        checklistModification.odataPostResponder = `/bthr_employees(${this.configServie.user?.employeeId})`;
        checklistModification.odataPostResponderLegalEntity = `/bthr_legalentitieses(${this.configServie.user?.legalEntityId})`;
      }
      checklistModification.bt_peerreviewstatus = 692360001;
      this.sharedApiFleetMovementService
        .patchFleetChecklistSummary({ body: checklistModification })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            console.log(ret);

            if (this.selectedFleetItem.odataServiceStatus != 'New') {
              this.showConfirmationModal = true;
            }
          },
          (error) => {
            console.error(error);
          },
        );
    });

    // New service update
    if (this.selectedFleetItem.odataServiceStatus == 'New') {
      let body: FleetFleetServiceManualUpdate = {
        bt_legalentity: { id: this.selectedFleetItem._bt_legalentity_value },
        bt_fleetitem: { id: this.selectedFleetItem.bthr_vehiclesid },
        bt_lastservicedate: myForm.value.lastServiceDate,
        bt_odoreading: this.latestOdoReading,
        bt_updatedlegalentity: { id: this.configServie.user.legalEntityId },
        bt_updateby: { id: this.configServie.user.employeeId },
      };

      this.fleetApiFleetService
        .postFleetServiceManualUpdate({ body: { body: body } })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            console.log(ret);
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.showConfirmationModal = true;
          },
        );
    }
  }

  private mapToSummarySubmit(checklist: FleetChecklist): FleetChecklist {
    return {
      bt_Responder: checklist.bt_Responder,
      bt_duehours: checklist.bt_duehours,
      bt_feedbackcompleted: checklist.bt_feedbackcompleted,
      bt_frequency: checklist.bt_frequency,
      bt_licensenumber: checklist.bt_licensenumber,
      bt_movementtype: checklist.bt_movementtype,
      bt_odo: this.latestOdoReading,
      bt_peerreviewstatus: checklist.bt_peerreviewstatus,
      bt_transferdate: checklist.bt_transferdate,
      bt_transferdirection: checklist.bt_transferdirection,
      bt_vehicleinspectionchecklistsummary: checklist.bt_vehicleinspectionchecklistsummary,
      bt_vehicleinspectionchecklistsummaryid: checklist.bt_vehicleinspectionchecklistsummaryid,
    };
  }

  closeConfirmationModal() {
    this.showConfirmationModal = false;
    this.sidebarService.setShowSidebar(false);
  }
}
