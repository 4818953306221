/* tslint:disable */
/* eslint-disable */
export interface SubgroupSummary {
  _bt_cycle_value?: null | string;
  _bt_employee_value?: null | string;
  _bt_group_value?: null | string;
  _bt_period_value?: null | string;
  _bt_responder_value?: null | string;
  _bt_subgroup_value?: null | string;
  _bt_template_value?: null | string;
  bt_basepoint?: null | number;
  bt_basepointpercentage?: null | number;
  bt_benchmark?: null | string;
  bt_date?: null | string;
  bt_feedbackcompleted?: null | boolean;
  bt_performancereviewsubgroupsummary?: null | string;
  bt_performancereviewsubgroupsummaryid?: null | string;
  bt_ratingaverage?: null | number;
  bt_ratingfloat?: null | number;
  bt_recordstatus?: null | number;
  bt_response?: null | string;
  bt_templatetype?: null | number;
  odataCycle?: null | string;
  odataFeedbackCompleted?: null | string;
  odataGroup?: null | string;
  odataPeriod?: null | string;
  odataSubgroup?: null | string;
  odataTemplate?: null | string;
  odataTemplateType?: null | string;
}
