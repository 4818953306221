/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { WebDynamicBaseService } from '../web-dynamic-base-service';
import { WebDynamicApiConfiguration } from '../web-dynamic-api-configuration';
import { WebDynamicStrictHttpResponse } from '../web-dynamic-strict-http-response';
import { WebDynamicRequestBuilder } from '../web-dynamic-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { WebDynamicCreateRegisterColumnQuery } from '../models/web-dynamic-create-register-column-query';
import { WebDynamicCreateRegisterJsonQuery } from '../models/web-dynamic-create-register-json-query';
import { WebDynamicCreateRegisterSecurityFunctionQuery } from '../models/web-dynamic-create-register-security-function-query';
import { WebDynamicCreateRegisterSystemFormsImportCommand } from '../models/web-dynamic-create-register-system-forms-import-command';

@Injectable({
  providedIn: 'root',
})
export class WebDynamicApiManagementService extends WebDynamicBaseService {
  constructor(config: WebDynamicApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation createRegisterSystemFormImports
   */
  static readonly CreateRegisterSystemFormImportsPath = '/api/Management/Create_Register_SystemForm_Imports';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRegisterSystemFormImports()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterSystemFormImports$Response(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterSystemFormsImportCommand;
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {
    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CreateRegisterSystemFormImportsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRegisterSystemFormImports$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterSystemFormImports(params?: { tenantId?: string; body?: WebDynamicCreateRegisterSystemFormsImportCommand }): Observable<boolean> {
    return this.createRegisterSystemFormImports$Response(params).pipe(map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation createRegisterColumns
   */
  static readonly CreateRegisterColumnsPath = '/api/Management/Create_Register_Columns';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRegisterColumns()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterColumns$Response(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterColumnQuery;
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {
    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CreateRegisterColumnsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRegisterColumns$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterColumns(params?: { tenantId?: string; body?: WebDynamicCreateRegisterColumnQuery }): Observable<boolean> {
    return this.createRegisterColumns$Response(params).pipe(map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation createRegisterJson
   */
  static readonly CreateRegisterJsonPath = '/api/Management/Create_Register_Json';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRegisterJson()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterJson$Response(params?: { tenantId?: string; body?: WebDynamicCreateRegisterJsonQuery }): Observable<WebDynamicStrictHttpResponse<boolean>> {
    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CreateRegisterJsonPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRegisterJson$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterJson(params?: { tenantId?: string; body?: WebDynamicCreateRegisterJsonQuery }): Observable<boolean> {
    return this.createRegisterJson$Response(params).pipe(map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation createRegisterSecurityFunction
   */
  static readonly CreateRegisterSecurityFunctionPath = '/api/Management/Create_Register_SecurityFunction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRegisterSecurityFunction()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterSecurityFunction$Response(params?: {
    tenantId?: string;
    body?: WebDynamicCreateRegisterSecurityFunctionQuery;
  }): Observable<WebDynamicStrictHttpResponse<boolean>> {
    const rb = new WebDynamicRequestBuilder(this.rootUrl, WebDynamicApiManagementService.CreateRegisterSecurityFunctionPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as WebDynamicStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRegisterSecurityFunction$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createRegisterSecurityFunction(params?: { tenantId?: string; body?: WebDynamicCreateRegisterSecurityFunctionQuery }): Observable<boolean> {
    return this.createRegisterSecurityFunction$Response(params).pipe(map((r: WebDynamicStrictHttpResponse<boolean>) => r.body as boolean));
  }
}
