import { Action } from '@ngrx/store';
import { UserUserDto } from '@verde/api';

export enum ManagerActionTypes {
  // Get Manager
  MANAGER_GET = '[MANAGER] Get',
  MANAGER_GET_SUCCESS = '[MANAGER] Get Success',
  MANAGER_GET_ERROR = '[MANAGER] Get Error',

  // Clear Manager
  MANAGER_CLEAR = '[MANAGER] Clear',
}

// Get Manager
export class ManagerGet implements Action {
  readonly type = ManagerActionTypes.MANAGER_GET;
  constructor(public managerID: string) {}
}

export class ManagerGetSuccess implements Action {
  readonly type = ManagerActionTypes.MANAGER_GET_SUCCESS;
  constructor(public payload: UserUserDto[]) {}
}

export class ManagerGetError implements Action {
  readonly type = ManagerActionTypes.MANAGER_GET_ERROR;
  constructor(public payload: any) {}
}

// Clear Manager
export class ManagerClear implements Action {
  readonly type = ManagerActionTypes.MANAGER_CLEAR;
  constructor(public payload: any) {}
}

export type ManagerActions =
  // Get Manager
  | ManagerGet
  | ManagerGetSuccess
  | ManagerGetError
  // Clear Manager
  | ManagerClear;
