import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TemplateGetMeasuresQuery, TemplatePatchTemplateMeasureCommand, TemplateUploadMeasureCommand } from '@verde/api';
import { Observable } from 'rxjs';
import * as MeasureActions from '../store/actions/measure.actions';
import * as fromMeasureReducer from '../store/reducers/measure.reducer';
import * as fromProfileRelatedReducer from '../store/reducers/profile-related.reducer';

@Injectable({
  providedIn: 'root',
})
export class MeasureService {
  // Measure
  measureLoading$: Observable<boolean>;
  measureObjects$: Observable<any[]>;

  constructor(private store: Store<fromProfileRelatedReducer.ProfileRelatedState>) {
    // Measure
    this.measureLoading$ = this.store.pipe(select(fromMeasureReducer.isMeasureLoading));
    this.measureObjects$ = this.store.pipe(select(fromMeasureReducer.getAllMeasure));
  }

  getMeasure(body: TemplateGetMeasuresQuery) {
    this.store.dispatch(new MeasureActions.MeasuresGet(body));
  }

  // Clear
  measureClear() {
    this.store.dispatch(new MeasureActions.MeasuresClear(null));
  }

  // Upload
  uploadMeasures(payload: TemplateUploadMeasureCommand) {
    this.store.dispatch(new MeasureActions.MeasuresUpload(payload));
  }

  // Measure update
  updateMeasure(payload: TemplatePatchTemplateMeasureCommand) {
    this.store.dispatch(new MeasureActions.MeasureUpdate(payload));
  }
}
