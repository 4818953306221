/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface Successions {
  _bt_successoremployeeid_value?: null | string;
  bt_EmployeeID?: User;
  bt_SuccessorEmployeeID?: User;
  bt_comment?: null | string;
  bt_externaltrainingcomments?: null | string;
  bt_externaltrainingrequired?: null | boolean;
  bt_gapanalysisstatus?: null | number;
  bt_internaltrainingcomments?: null | string;
  bt_internaltrainingrequired?: null | boolean;
  bt_lastreviewdate?: null | string;
  bt_mobilitytransferpotential?: null | number;
  bt_name?: null | string;
  bt_priorityno?: null | number;
  bt_readyin?: null | number;
  bt_readynow?: null | boolean;
  bt_retentionpotential?: null | number;
  bt_retirementstatus?: null | number;
  bt_successortype?: null | number;
  bt_successplanid?: null | string;
  bt_trainingplanattachmentname?: null | string;
  bt_trainingplandeveloped?: null | number;
  odataExternalRequired?: null | string;
  odataGapAnalysisStatus?: null | string;
  odataInternalRequired?: null | string;
  odataMobilityTransferPotential?: null | string;
  odataPostEmployee?: null | string;
  odataPostEmployeeLegalEntity?: null | string;
  odataPostSuccessor?: null | string;
  odataPostSuccessorLegalEntity?: null | string;
  odataReadyIn?: null | string;
  odataReadyNow?: null | string;
  odataRententionPotential?: null | string;
  odataRetirementStatus?: null | string;
  odataSuccessor?: null | string;
  odataSuccessorLegalEntity?: null | string;
  odataSuccessorType?: null | string;
  odataTrainingPlanDeveloped?: null | string;
}
