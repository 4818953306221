/* eslint-disable no-console */
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedApiFileService, SharedApiVerdeRequestsService, VerdeRequest, VerdeRequestType } from '@verde/api';
import { StorageService, UserService } from '@verde/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { RequistionStatus } from '../../../../../../../../apps/verde/src/app/features/requests/models/requests.interface';
import { RequestModalService } from '../../../../../../../../apps/verde/src/app/features/requests/services/request-modal.service';
import { RequestsService } from '../../../../../../../../apps/verde/src/app/features/requests/services/requests.service';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'verde-new-request-modal',
  templateUrl: './new-request-modal.component.html',
  styleUrls: ['./new-request-modal.component.scss'],
})
export class NewRequestModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() newRequestCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() defaultRequestType = '';

  private onDestroy$ = new Subject<boolean>();
  private maxFileSize = 5242880;
  private specificationDocument: File = null;
  private quote1: File = null;
  private quote2: File = null;
  private quote3: File = null;

  requestTypes: VerdeRequestType[] | null;
  requestTypeValues: string[] = null;
  currentRequestType: VerdeRequestType = null;
  requestTypeSelected = false;
  templateDocumentBase64 = null;
  minDate: Date = new Date();
  disableAnimation: boolean;
  confirmationMessage: string;
  templateFileDownloadInProgress = false;

  newRequestTypeForm: FormGroup;
  newRequestForm: FormGroup;

  constructor(
    private requestsService: RequestsService,
    private userService: UserService,
    private modalService: ModalService,
    private spinner: NgxSpinnerService,
    public requestModalService: RequestModalService,
    private sharedApiFileService: SharedApiFileService,
    private sharedApiRequestService: SharedApiVerdeRequestsService,
    private router: Router,
    private storageService: StorageService,
  ) {
    this.requestModalService.initialiseRequestTypeForm(this.userService?.user);
    this.requestModalService.initialiseRequestForm();
    this.newRequestForm = this.requestModalService.getRequestForm();
  }

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
  }

  ngAfterViewInit(): void {
    if (this.defaultRequestType === '') {
      this.initRequestTypes();
    } else if (this.defaultRequestType === 'Equipment') {
      this.initEquipmentRequestTypes();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  private initRequestTypes(): void {
    this.spinner.show('requesttypespinner');
    this.requestsService
      .fetchAllRequestTypes()
      .pipe(
        take(1),
        map((requestType) => requestType.map((request) => request)),
      )
      .subscribe(
        (requestTypeList) => {
          this.requestTypes = requestTypeList;
          this.requestTypeValues = requestTypeList.map((item) => item.bt_verderequesttype);
          this.spinner.hide('requesttypespinner');
        },
        (error) => console.error('Error fetching request types', error),
      );
  }

  private initEquipmentRequestTypes(): void {
    this.spinner.show('requesttypespinner');

    const func = this.userService?.getPermission('PEOPLE-EMPLOYEE');

    this.requestsService
      .fetchAllNonGenericRequestTypes('692360003', func.functionId, this.userService.user.legalEntityId!)
      .pipe(
        take(1),
        map((requestType) => requestType?.map((request) => request)),
      )
      .subscribe(
        (requestTypeList) => {
          this.requestTypes = requestTypeList!;
          this.requestTypeValues = requestTypeList?.map((item) => item.bt_verderequesttype);
          this.spinner.hide('requesttypespinner');
        },
        (error) => console.error('Error fetching request types', error),
      );
  }

  private postSpecificationDocument(request: VerdeRequest): void {
    if (this.specificationDocument) {
      this.sharedApiFileService
        .patchRequestSpecificationDocument({ recordID: request.bt_verderequestid, body: { file: this.specificationDocument } })
        .pipe(take(1))
        .subscribe(
          () => {
            //
          },
          (error) => console.error('An error occurred while uploading the specification document: ', error),
        );
    }
  }

  private postQuoteDocument1(request: VerdeRequest): void {
    if (this.quote1) {
      this.sharedApiFileService
        .patchRequestQuote1({ recordID: request.bt_verderequestid, body: { file: this.quote1 } })
        .pipe(take(1))
        .subscribe(
          () => {
            //
          },
          (error) => console.error('An error occurred while uploading the quote document 1: ', error),
        );
    }
  }
  // TODO look at fleet stuff for validation (fleet-approval-modal)
  private postQuoteDocument2(request: VerdeRequest): void {
    if (this.quote2) {
      this.sharedApiFileService
        .patchRequestQuote2({ recordID: request.bt_verderequestid, body: { file: this.quote2 } })
        .pipe(take(1))
        .subscribe(
          () => {
            //
          },
          (error) => console.error('An error occurred while uploading the quote document 2: ', error),
        );
    }
  }

  private postQuoteDocument3(request: VerdeRequest): void {
    if (this.quote3) {
      this.sharedApiFileService
        .patchRequestQuote3({ recordID: request.bt_verderequestid, body: { file: this.quote3 } })
        .pipe(take(1))
        .subscribe(
          () => {
            //
          },
          (error) => console.error('An error occurred while uploading the quote document 3: ', error),
        );
    }
  }

  viewModal(name: string): void {
    if (this.defaultRequestType === 'Equipment') {
      this.submitLightweightRequest();
    } else {
      if (name === 'newRequestModal1') {
        this.modalService.close('newRequestModal2');
      } else {
        this.modalService.close('newRequestModal1');
      }

      this.modalService.open(name);
    }
  }

  changeRequestType(requestTypeId: string): void {
    this.currentRequestType = this.requestTypes.find((request) => `/bt_verderequesttypes(${request.bt_verderequesttypeid})` === requestTypeId);
    this.requestTypeSelected = !!this.currentRequestType;

    if (this.currentRequestType.bt_verderequesttypeid && this.currentRequestType.bt_displaytemplate) {
      this.getTemplateFileDocument(this.currentRequestType.bt_verderequesttypeid);
    }

    this.getMinDate();
    this.requestModalService.setRequestTypeForm(this.currentRequestType, this.userService?.user);
  }

  uploadSpecificationDocument(event: any): void {
    const { files } = event.target;
    if (files && files.length > 0) {
      if (files[0].size < this.maxFileSize) {
        const splitName = files[0].name.split('.');
        const extension = splitName[splitName.length - 1];
        const { employeeId } = this.userService?.user;
        const fileName = `${employeeId}_SpecificationDocument_.${extension}`;

        this.specificationDocument = new File([files[0]], fileName);
        this.newRequestForm.controls.bt_specificationdocumentname.setValue(fileName);
      } else {
        alert(`File ${files[0].name} is too large to upload.`);
      }
    } else {
      alert('Please upload a specification document.');
    }
  }

  uploadQuote(event: any, quoteNumber: number): void {
    // TODO first check if an upload is required, else return
    const { files } = event.target;

    if (files && files.length > 0) {
      if (files[0].size < this.maxFileSize) {
        const splitName = files[0].name.split('.');
        const extension = splitName[splitName.length - 1];
        const { employeeId } = this.userService?.user;
        const fileName = `${employeeId}_Quote${quoteNumber}_.${extension}`;

        if (quoteNumber === 1) {
          this.quote1 = new File([files[0]], fileName);
          this.newRequestForm.controls.bt_quote1name.setValue(fileName);
        } else if (quoteNumber === 2) {
          this.quote2 = new File([files[0]], fileName);
          this.newRequestForm.controls.bt_quote2name.setValue(fileName);
        } else {
          this.quote3 = new File([files[0]], fileName);
          this.newRequestForm.controls.bt_quote3name.setValue(fileName);
        }
      } else {
        alert(`File ${files[0].name} is too large to upload.`);
      }
    } else {
      alert('Please upload a specification document.');
    }
  }

  // TODO update statecode to 1 (to make it inactive) when status is NEW -> this will soft delete the request

  submitLightweightRequest(): void {
    this.spinner.show('equipmentmain');
    this.storageService.sessionDeleteKey('EquipmentRequestHeaderID');

    this.newRequestForm.controls.bt_uniquename.setValue(this.userService?.user.employeeId + '_Request_' + moment().format('YYYY-MM-DD, h:mm:ss a'));

    // change requisition status to submitted
    this.newRequestForm.controls.bt_requisitionstatus.setValue(RequistionStatus.STATUS_SUBMITTED);

    const newVerdeRequest: VerdeRequest = {
      odataPostRequestType: this.newRequestTypeForm.controls.odataPostRequestType.value,
      odataPostRequestedByLegalEntity: this.newRequestTypeForm.controls.odataPostRequestedByLegalEntity.value,
      odataPostRequestedBy: this.newRequestTypeForm.controls.odataPostRequestedBy.value,
      odataPostManagerEntity: this.newRequestTypeForm.controls.odataPostManagerEntity.value,
      odataPostManager: this.newRequestTypeForm.controls.odataPostManager.value,
      odataProcessLegalEntity: this.newRequestTypeForm.controls.odataProcessLegalEntity.value,
      ...this.requestModalService.getRequestForm().value,
    };

    newVerdeRequest.bt_description = 'Equipment Request';
    newVerdeRequest.bt_motivation = 'Equipment Request';
    newVerdeRequest.bt_requestdate = moment().add(1, 'day').format('YYYY-MM-DD');
    newVerdeRequest.bt_requisitiondate = moment().add(1, 'day').format('YYYY-MM-DD');

    this.sharedApiRequestService
      .postVerdeRequest({ body: newVerdeRequest })
      .pipe(take(1))
      .subscribe(
        () => {
          this.requestsService
            .fetchAllEmployeeRequests()
            .pipe(
              take(1),
              map((requests) => requests.find((request) => request.bt_uniquename === this.newRequestForm.value.bt_uniquename)),
            )
            .subscribe(
              (request: VerdeRequest) => {
                //clear form
                this.newRequestForm.reset();
                this.newRequestCreated.emit(true);

                this.router.navigate(['/equipment/request', request.bt_verderequestid]);
              },
              (error) => console.error('Error posting documents: ', error),
            );
        },
        (error) => console.error('Error creating a new request: ', error),
      );
  }

  submitRequest(): void {
    this.spinner.show('newRequestSpinner');
    this.modalService.open('newRequestConfirmationModal');

    this.newRequestForm.controls.bt_uniquename.setValue(this.userService?.user.employeeId + '_Request_' + moment().format('YYYY-MM-DD, h:mm:ss a'));

    // change requisition status to submitted
    this.newRequestForm.controls.bt_requisitionstatus.setValue(RequistionStatus.STATUS_SUBMITTED);

    const newVerdeRequest: VerdeRequest = {
      odataPostRequestType: this.newRequestTypeForm.controls.odataPostRequestType.value,
      odataPostRequestedByLegalEntity: this.newRequestTypeForm.controls.odataPostRequestedByLegalEntity.value,
      odataPostRequestedBy: this.newRequestTypeForm.controls.odataPostRequestedBy.value,
      odataPostManagerEntity: this.newRequestTypeForm.controls.odataPostManagerEntity.value,
      odataPostManager: this.newRequestTypeForm.controls.odataPostManager.value,
      odataProcessLegalEntity: this.newRequestTypeForm.controls.odataProcessLegalEntity.value,
      ...this.requestModalService.getRequestForm().value,
    };

    this.sharedApiRequestService
      .postVerdeRequest({ body: newVerdeRequest })
      .pipe(take(1))
      .subscribe(
        () => {
          this.requestsService
            .fetchAllEmployeeRequests()
            .pipe(
              take(1),
              map((requests) => requests.find((request) => request.bt_uniquename === this.newRequestForm.value.bt_uniquename)),
            )
            .subscribe(
              (request: VerdeRequest) => {
                this.postSpecificationDocument(request);
                this.postQuoteDocument1(request);
                this.postQuoteDocument2(request);
                this.postQuoteDocument3(request);
                //clear form
                this.newRequestForm.reset();
                this.newRequestCreated.emit(true);

                this.confirmationMessage = 'You have successfully created a request';
                this.spinner.hide('newRequestSpinner');
              },
              (error) => console.error('Error posting documents: ', error),
            );
          // fetch all requests and find the newly created one
          // TODO call file upload function here
        },
        (error) => console.error('Error creating a new request: ', error),
      );
  }

  presentSaveOrDeleteModal(): void {
    this.modalService.close('newRequestModal2');
    this.modalService.open('saveOrDeleteModal');
  }

  saveCurrentRequest(): void {
    this.newRequestForm?.controls.bt_requisitionstatus.setValue(RequistionStatus.STATUS_NEW);

    const savedRequest: VerdeRequest = {
      odataPostRequestType: this.newRequestTypeForm.controls.odataPostRequestType.value,
      odataPostRequestedByLegalEntity: this.newRequestTypeForm.controls.odataPostRequestedByLegalEntity.value,
      odataPostRequestedBy: this.newRequestTypeForm.controls.odataPostRequestedBy.value,
      odataPostManagerEntity: this.newRequestTypeForm.controls.odataPostManagerEntity.value,
      odataPostManager: this.newRequestTypeForm.controls.odataPostManager.value,
      odataProcessLegalEntity: this.newRequestTypeForm.controls.odataProcessLegalEntity.value,
      ...this.requestModalService.getRequestForm().value,
    };

    this.modalService.open('newRequestConfirmationModal');
    this.spinner.show('confirmationSpinner');

    // set requisition status to "New"
    this.sharedApiRequestService
      .postVerdeRequest({ body: savedRequest })
      .pipe(take(1))
      .subscribe(
        () => (this.confirmationMessage = 'You have successfully saved a request'),
        (error) => console.error('Error saving a new request: ', error),
        () => {
          this.spinner.hide('confirmationSpinner');
          this.modalService.close('newRequestConfirmationModal');
          this.newRequestCreated.emit(true);
        },
      );
  }

  deleteRequest(): void {
    this.modalService.close('saveOrDeleteModal');
    this.modalService.close('newRequestModal2');
  }

  getMinDate(): void {
    const leadTime = this.currentRequestType?.bt_leadtime || 0;
    const today = new Date();

    this.minDate = moment(today).add(leadTime, 'days').toDate();
    this.newRequestForm.controls.bt_requestdate.setValue(this.minDate);
  }

  downloadFile(base64, fileName) {
    const link = document.createElement('a');

    link.href = 'data:application/pdf;base64,' + base64;
    link.download = fileName;
    link.click();
  }

  getTemplateFileDocument(requestid: string) {
    this.templateFileDownloadInProgress = true;
    this.spinner.show('templateDocumentSpinner');

    this.sharedApiFileService
      .getRequestTypeTemplate({ requestid })
      .pipe(take(1))
      .subscribe(
        (data) => (this.templateDocumentBase64 = data),
        (error) => console.error(error),
        () => {
          this.spinner.hide('templateDocumentSpinner');
          this.templateFileDownloadInProgress = false;
        },
      );
  }

  isSpecDocFieldValid(): boolean {
    if (this.currentRequestType.bt_specificationrequired) {
      return !!this.newRequestForm.controls.bt_specificationdocumentname.value;
    }

    return true;
  }

  isModalOpen(event: boolean): void {
    if (event) {
      this.newRequestTypeForm = this.requestModalService.getRequestTypeform();
    }
  }
}
