/* tslint:disable */
/* eslint-disable */
import { WebDynamicColumnData } from './web-dynamic-column-data';
import { WebDynamicSortData } from './web-dynamic-sort-data';
import { WebDynamicWebDynamicFilterDto } from './web-dynamic-web-dynamic-filter-dto';
export interface WebDynamicGetDynamicFormLookupQuery {
  columns?: null | Array<WebDynamicColumnData>;
  dependency?: WebDynamicWebDynamicFilterDto;
  dynamicgroup?: null | string;
  id?: null | string;
  label?: null | string;
  sort?: null | Array<WebDynamicSortData>;
  value?: null | string;
}
