/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PerformanceReviewBaseService } from '../performance-review-base-service';
import { PerformanceReviewApiConfiguration } from '../performance-review-api-configuration';
import { PerformanceReviewStrictHttpResponse } from '../performance-review-strict-http-response';
import { PerformanceReviewRequestBuilder } from '../performance-review-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PerformanceReviewGetAllPeerReviewFeedbackQuery } from '../models/performance-review-get-all-peer-review-feedback-query';
import { PerformanceReviewGetAllPeerReviewFeedbackSummariesQuery } from '../models/performance-review-get-all-peer-review-feedback-summaries-query';
import { PerformanceReviewPatchPeerReviewFeedbackCommand } from '../models/performance-review-patch-peer-review-feedback-command';
import { PerformanceReviewPatchPeerReviewFeedbackSummaryCommand } from '../models/performance-review-patch-peer-review-feedback-summary-command';
import { PerformanceReviewPeerReviewFeedback } from '../models/performance-review-peer-review-feedback';
import { PerformanceReviewPeerReviewFeedbackSummary } from '../models/performance-review-peer-review-feedback-summary';

@Injectable({
  providedIn: 'root',
})
export class PerformanceReviewApiPeerReviewService extends PerformanceReviewBaseService {
  constructor(config: PerformanceReviewApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllPeerReviewFeedback
   */
  static readonly GetAllPeerReviewFeedbackPath = '/api/PeerReview/getAllPeerReviewFeedback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPeerReviewFeedback()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllPeerReviewFeedback$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetAllPeerReviewFeedbackQuery;
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewPeerReviewFeedback>>> {
    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPeerReviewService.GetAllPeerReviewFeedbackPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewPeerReviewFeedback>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPeerReviewFeedback$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllPeerReviewFeedback(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetAllPeerReviewFeedbackQuery;
  }): Observable<Array<PerformanceReviewPeerReviewFeedback>> {
    return this.getAllPeerReviewFeedback$Response(params).pipe(
      map((r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewPeerReviewFeedback>>) => r.body as Array<PerformanceReviewPeerReviewFeedback>),
    );
  }

  /**
   * Path part for operation getAllPeerReviewFeedbackSummaries
   */
  static readonly GetAllPeerReviewFeedbackSummariesPath = '/api/PeerReview/getAllPeerReviewFeedbackSummaries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPeerReviewFeedbackSummaries()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllPeerReviewFeedbackSummaries$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetAllPeerReviewFeedbackSummariesQuery;
  }): Observable<PerformanceReviewStrictHttpResponse<Array<PerformanceReviewPeerReviewFeedbackSummary>>> {
    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPeerReviewService.GetAllPeerReviewFeedbackSummariesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as PerformanceReviewStrictHttpResponse<Array<PerformanceReviewPeerReviewFeedbackSummary>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPeerReviewFeedbackSummaries$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAllPeerReviewFeedbackSummaries(params?: {
    tenantId?: string;
    body?: PerformanceReviewGetAllPeerReviewFeedbackSummariesQuery;
  }): Observable<Array<PerformanceReviewPeerReviewFeedbackSummary>> {
    return this.getAllPeerReviewFeedbackSummaries$Response(params).pipe(
      map(
        (r: PerformanceReviewStrictHttpResponse<Array<PerformanceReviewPeerReviewFeedbackSummary>>) =>
          r.body as Array<PerformanceReviewPeerReviewFeedbackSummary>,
      ),
    );
  }

  /**
   * Path part for operation patchPeerReviewFeedback
   */
  static readonly PatchPeerReviewFeedbackPath = '/api/PeerReview/patch/patchPeerReviewFeedback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPeerReviewFeedback()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPeerReviewFeedback$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewPatchPeerReviewFeedbackCommand;
  }): Observable<PerformanceReviewStrictHttpResponse<boolean>> {
    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPeerReviewService.PatchPeerReviewFeedbackPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PerformanceReviewStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchPeerReviewFeedback$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPeerReviewFeedback(params?: { tenantId?: string; body?: PerformanceReviewPatchPeerReviewFeedbackCommand }): Observable<boolean> {
    return this.patchPeerReviewFeedback$Response(params).pipe(map((r: PerformanceReviewStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchPeerReviewFeedbackSummary
   */
  static readonly PatchPeerReviewFeedbackSummaryPath = '/api/PeerReview/patch/patchPeerReviewFeedbackSummary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPeerReviewFeedbackSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPeerReviewFeedbackSummary$Response(params?: {
    tenantId?: string;
    body?: PerformanceReviewPatchPeerReviewFeedbackSummaryCommand;
  }): Observable<PerformanceReviewStrictHttpResponse<boolean>> {
    const rb = new PerformanceReviewRequestBuilder(this.rootUrl, PerformanceReviewApiPeerReviewService.PatchPeerReviewFeedbackSummaryPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as PerformanceReviewStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchPeerReviewFeedbackSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPeerReviewFeedbackSummary(params?: { tenantId?: string; body?: PerformanceReviewPatchPeerReviewFeedbackSummaryCommand }): Observable<boolean> {
    return this.patchPeerReviewFeedbackSummary$Response(params).pipe(map((r: PerformanceReviewStrictHttpResponse<boolean>) => r.body as boolean));
  }
}
