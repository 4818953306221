import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CountryActions, CountryActionTypes } from '../actions/country.actions';
import * as fromProfileRelatedReducer from './profile-related.reducer';

// Models
import { QualificationsBthrCountry } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type CountryState = EntityState<QualificationsBthrCountry>;

export interface CountryRelatedState {
  countryState: CountryState;
  selectedIds: string[];
  loading: boolean;
  error: any;
}

export const countryKeys = ['bthr_Countryid'];
export const countryStateAdapter: EntityAdapter<QualificationsBthrCountry> = createEntityAdapter<QualificationsBthrCountry>({
  selectId: (data) => keyHandler(data, countryKeys),
});

export const initialCountryState: CountryState = countryStateAdapter.getInitialState({});

export const initialCountryRelatedState = {
  countryState: initialCountryState,
  selectedIds: [],
  loading: false,
  error: {},
};

export function CountryRelatedReducer(state = initialCountryRelatedState, action: CountryActions): CountryRelatedState {
  switch (action.type) {
    // Get Country
    case CountryActionTypes.COUNTRY_GET_ALL:
      return { ...state, loading: true };
    case CountryActionTypes.COUNTRY_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        countryState: countryStateAdapter.setAll(action.payload, state.countryState),
      };
    }
    case CountryActionTypes.COUNTRY_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Clear
    case CountryActionTypes.COUNTRY_CLEAR:
      return { ...initialCountryRelatedState };
    default:
      return state;
  }
}

export const getProfileRelatedState = createFeatureSelector<fromProfileRelatedReducer.ProfileRelatedState>('profileRelatedReducer');

export const getCountryRelatedState = createSelector(
  getProfileRelatedState,
  (state: fromProfileRelatedReducer.ProfileRelatedState) => state.countryReducerState,
);

// Current Country

export const getCountryState = createSelector(getCountryRelatedState, (state) => state.countryState);
export const { selectAll: getCountry } = countryStateAdapter.getSelectors(getCountryState);

// Selected

export const getSelectedCountryIds = createSelector(getCountryRelatedState, (state) => state.selectedIds);

export const getSelectedProfiles = createSelector(getCountry, getSelectedCountryIds, (Country, selectedIds: string[]) =>
  Country.filter((m) => selectedIds.indexOf(keyHandler(m, countryKeys)) !== -1),
);

// Loading

export const isCountryLoading = createSelector(getCountryRelatedState, (state) => state.loading);

// Error

export const getProfileError = createSelector(getCountryRelatedState, (state) => state.error);
