/* tslint:disable */
/* eslint-disable */
export enum ProfileBtEmployeeLifecycleStage {
  Applicant = 'Applicant',
  Offered = 'Offered',
  Offerrejected = 'Offerrejected',
  Onboarding = 'Onboarding',
  Employed = 'Employed',
  OffBoarding = 'OffBoarding',
  Exited = 'Exited',
}
