<kendo-grid
  id="viewPayslipGrid"
  [data]="allPayslipsData"
  [resizable]="true"
  [scrollable]="true"
  [pageable]="false"
  [skip]="state.skip"
  [sortable]="true"
  [sort]="sort"
  [groupable]="true"
  [loading]="payslipLoading"
  (dataStateChange)="dataStateChange($event)"
>
  <!--Columns-->
  <ng-template kendoGridToolbarTemplate style="height: 57px">
    <!--<div *ngIf="allowedLegalEntities.length > 0" style="margin-left: 8px">
    <label style="width: 70px; margin: auto 0" for="calendarEntityDropdown">Legal Entity:</label>
    <kendo-dropdownlist
      [data]="allowedLegalEntities"
      textField="legalEntityName"
      valueField="legalEntityId"
      id="calendarEntityDropdown"
      name="calendarEntityDropdown"
      #varcalendarEntityDropdown="ngModel"
      required
      [ngModel]
      (valueChange)="setEntityDropdownValue($event, varcalendarEmployee)"
      style="width: 250px"
    >
    </kendo-dropdownlist>

    <label style="width: 60px; margin: auto 0 auto 8px" for="calendarEmployee">Employee:</label>
    <kendo-dropdownlist
      [data]="allEmployeesFilteredData"
      textField="fullNameWithEmpId"
      valueField="employeeId"
      [filterable]="true"
      (filterChange)="handleEmployeeFilter($event)"
      id="calendarEmployee"
      name="calendarEmployee"
      #varcalendarEmployee="ngModel"
      required
      [ngModel]
      (valueChange)="getHrDocs($event)"
      style="width: 250px"
      [disabled]="entityDropdownValue == null"
    >
      <ng-template kendoDropDownListNoDataTemplate>
        <p>{{ employeeDropdownMessage }}</p>
      </ng-template>
    </kendo-dropdownlist>
  </div>-->
    <p>Please contact HR if your payslip is not loaded</p>
    <kendo-grid-spacer></kendo-grid-spacer>
    <button *ngIf="!(deviceTypeService.isMobile$ | async)" style="height: 36px; margin-left: auto" kendoGridExcelCommand icon="file-excel">
      Export to Excel
    </button>
    <img title="Refresh" class="refresh_icon" src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg" alt="" (click)="refresh()" />
  </ng-template>
  <kendo-grid-column field="bt_doctypetext" title="Type" [width]="250"> </kendo-grid-column>
  <kendo-grid-column field="bt_periodenddate" title="Period End Date" [width]="270">
    <ng-template kendoGridCellTemplate let-ListTable>
      {{ convertStringToDate(ListTable.bt_periodenddate) | date : 'yyyy/MM/dd' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="createdon" title="Date Uploaded" [width]="270">
    <ng-template kendoGridCellTemplate let-ListTable>
      {{ ListTable.createdon | date : 'yyyy/MM/dd' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="bt_taxyear" title="Tax Year" [width]="250"> </kendo-grid-column>
  <kendo-grid-column field="bt_periodno" title="Period No" [width]="250"> </kendo-grid-column>
  <kendo-grid-command-column title="" [width]="40" [sticky]="true" [minResizableWidth]="40" [maxResizableWidth]="40">
    <ng-template kendoGridCellTemplate let-dataItem>
      <verde-dots-menu-button [items]="dotMenuItems" (menuClicked)="menuClicked(dataItem)" (menuItemClicked)="menuItemClicked($event)">
      </verde-dots-menu-button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-excel fileName="FinancialDocs.xlsx"></kendo-grid-excel>
</kendo-grid>

<verde-payslip-dialog *ngIf="showDialog" [selectedItem]="selectedItemNew" (hideModalEvent)="hideDialogModal($event)"> </verde-payslip-dialog>

<!-- Modals -->
<verde-id-validation *ngIf="showIDValidationModal" (modalClosed)="showIDValidationModal = $event" (modalSuccess)="validationSuccess($event)">
</verde-id-validation>
<!-- Something -->
<verde-modal id="navToWebApp" modalTitle="Please Note" submitButton="Continue" (actionButton)="goToWeb()">
  <p>This functionality is temporarily only available on the web version of Verde.</p>
  <p>Please select Continue to be navigated to the web version else, Cancel.</p>
</verde-modal>
