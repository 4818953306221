/* tslint:disable */
/* eslint-disable */
export interface VerdeFooterTemplate {
  _bt_legalentity_value?: null | string;
  bt_footercontent?: null | string;
  bt_footerimagebase64?: null | string;
  bt_footerurl?: null | string;
  bt_textorimage?: boolean;
  bt_verdedocumentfootertemplate?: null | string;
  bt_verdedocumentfootertemplateid?: null | string;
}
