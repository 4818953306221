/* tslint:disable */
/* eslint-disable */
export interface BookingsGetBookingAvailabilitiesQuery {
  bookingTypeId?: string;
  fromDate?: null | string;
  legalEntityId?: string;
  officeId?: string;
  primaryLocationId?: string;
  subOfficeId?: string;
  toDate?: null | string;
  allBookings?: boolean;
}
