/* tslint:disable */
/* eslint-disable */
export interface TriggerNotifications {
  _bt_triggertemplate_value?: null | string;
  bt_messagetosend?: null | string;
  bt_notificationtriggerlogid?: null | string;
  bt_parameters?: null | string;
  bt_triggerno?: null | string;
  bt_triggerrequired?: null | boolean;
  createdon?: null | string;
  displayMessage?: null | string;
  odataTriggerTemplate?: null | string;
}
