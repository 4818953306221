/* tslint:disable */
/* eslint-disable */
export interface FundOptions {
  _bt_fund_value?: null | string;
  _bt_legalentity_value?: null | string;
  bt_description?: null | string;
  bt_fundoption?: null | string;
  bt_fundoptionsid?: null | string;
  bt_percentage?: number;
  odataLegalEntity?: null | string;
  odatafund?: null | string;
}
