import { StructureBtStructureMovementType, StructureBtStructureType } from '@verde/api';
import { StructureFunctions } from '../enums/structure-permissions.enum';

export interface MenuActionStructureType {
  ParentStructureType: StructureBtStructureType;
  MovementType: StructureBtStructureMovementType;
  StructureType: StructureBtStructureType;
  StructureFunction: StructureFunctions | null;
}

export const menuActionStructureTypes: MenuActionStructureType[] = [
  // Division - Add
  {
    ParentStructureType: StructureBtStructureType.Division,
    MovementType: StructureBtStructureMovementType.New,
    StructureType: StructureBtStructureType.Division,
    StructureFunction: StructureFunctions.STRUCTURE_NEW_DIVISION,
  },
  {
    ParentStructureType: StructureBtStructureType.Division,
    MovementType: StructureBtStructureMovementType.New,
    StructureType: StructureBtStructureType.Department,
    StructureFunction: StructureFunctions.STRUCTURE_NEW_DEPARTMENT,
  },
  {
    ParentStructureType: StructureBtStructureType.Division,
    MovementType: StructureBtStructureMovementType.New,
    StructureType: StructureBtStructureType.Position,
    StructureFunction: StructureFunctions.STRUCTURE_NEW_POSITION,
  },
  //{
  //  ParentStructureType: StructureBtStructureType.Division,
  //  MovementType: StructureBtStructureMovementType.New,
  //  StructureType: StructureBtStructureType.PositionSeat,
  //  StructureFunction: StructureFunctions.STRUCTURE_NEW_SEAT,
  //},
  // Division - Transfer
  {
    ParentStructureType: StructureBtStructureType.Division,
    MovementType: StructureBtStructureMovementType.Transfer,
    StructureType: StructureBtStructureType.PositionSeat,
    StructureFunction: StructureFunctions.STRUCTURE_TRANSFER_SEAT,
  },
  // Division - Dispose
  {
    ParentStructureType: StructureBtStructureType.Division,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.Division,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_DIVISION,
  },
  {
    ParentStructureType: StructureBtStructureType.Division,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.Department,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_DEPARTMENT,
  },
  {
    ParentStructureType: StructureBtStructureType.Division,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.Position,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_POSITION,
  },
  {
    ParentStructureType: StructureBtStructureType.Division,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.PositionSeat,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_SEAT,
  },
  // LegalEntity - Add
  {
    ParentStructureType: StructureBtStructureType.LegalEntity,
    MovementType: StructureBtStructureMovementType.New,
    StructureType: StructureBtStructureType.LegalEntity,
    StructureFunction: StructureFunctions.STRUCTURE_NEW_ENTITY,
  },
  {
    ParentStructureType: StructureBtStructureType.LegalEntity,
    MovementType: StructureBtStructureMovementType.New,
    StructureType: StructureBtStructureType.Division,
    StructureFunction: StructureFunctions.STRUCTURE_NEW_DIVISION,
  },
  {
    ParentStructureType: StructureBtStructureType.LegalEntity,
    MovementType: StructureBtStructureMovementType.New,
    StructureType: StructureBtStructureType.Position,
    StructureFunction: StructureFunctions.STRUCTURE_NEW_POSITION,
  },
  //{
  //  ParentStructureType: StructureBtStructureType.LegalEntity,
  //  MovementType: StructureBtStructureMovementType.New,
  //  StructureType: StructureBtStructureType.PositionSeat,
  //  StructureFunction: StructureFunctions.STRUCTURE_NEW_SEAT,
  //},
  // LegalEntity - Transfer
  {
    ParentStructureType: StructureBtStructureType.LegalEntity,
    MovementType: StructureBtStructureMovementType.Transfer,
    StructureType: StructureBtStructureType.PositionSeat,
    StructureFunction: StructureFunctions.STRUCTURE_TRANSFER_SEAT,
  },
  // LegalEntity - Dispose
  {
    ParentStructureType: StructureBtStructureType.LegalEntity,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.LegalEntity,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_ENTITY,
  },
  {
    ParentStructureType: StructureBtStructureType.LegalEntity,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.Division,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_DIVISION,
  },
  {
    ParentStructureType: StructureBtStructureType.LegalEntity,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.Position,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_POSITION,
  },
  {
    ParentStructureType: StructureBtStructureType.LegalEntity,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.PositionSeat,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_SEAT,
  },
  // Department - Add
  {
    ParentStructureType: StructureBtStructureType.Department,
    MovementType: StructureBtStructureMovementType.New,
    StructureType: StructureBtStructureType.PositionSeat,
    StructureFunction: StructureFunctions.STRUCTURE_NEW_SEAT,
  },
  {
    ParentStructureType: StructureBtStructureType.Department,
    MovementType: StructureBtStructureMovementType.New,
    StructureType: StructureBtStructureType.Department,
    StructureFunction: StructureFunctions.STRUCTURE_NEW_DEPARTMENT,
  },
  {
    ParentStructureType: StructureBtStructureType.Department,
    MovementType: StructureBtStructureMovementType.New,
    StructureType: StructureBtStructureType.Position,
    StructureFunction: StructureFunctions.STRUCTURE_NEW_POSITION,
  },
  // Department - Transfer
  {
    ParentStructureType: StructureBtStructureType.Department,
    MovementType: StructureBtStructureMovementType.Transfer,
    StructureType: StructureBtStructureType.PositionSeat,
    StructureFunction: StructureFunctions.STRUCTURE_TRANSFER_SEAT,
  },
  // Department - Dispose
  {
    ParentStructureType: StructureBtStructureType.Department,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.PositionSeat,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_SEAT,
  },
  {
    ParentStructureType: StructureBtStructureType.Department,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.Department,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_DEPARTMENT,
  },
  {
    ParentStructureType: StructureBtStructureType.Department,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.Position,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_POSITION,
  },
  // Seat - Transfer
  {
    ParentStructureType: StructureBtStructureType.PositionSeat,
    MovementType: StructureBtStructureMovementType.Transfer,
    StructureType: StructureBtStructureType.PositionSeat,
    StructureFunction: StructureFunctions.STRUCTURE_TRANSFER_SEAT,
  },
  {
    ParentStructureType: StructureBtStructureType.PositionSeat,
    MovementType: StructureBtStructureMovementType.Transfer,
    StructureType: StructureBtStructureType.Department,
    StructureFunction: StructureFunctions.STRUCTURE_TRANSFER_TO_DEPARTMENT,
  },
  {
    ParentStructureType: StructureBtStructureType.PositionSeat,
    MovementType: StructureBtStructureMovementType.Transfer,
    StructureType: StructureBtStructureType.Division,
    StructureFunction: StructureFunctions.STRUCTURE_TRANSFER_TO_DIVISION,
  },
  {
    ParentStructureType: StructureBtStructureType.PositionSeat,
    MovementType: StructureBtStructureMovementType.Transfer,
    StructureType: StructureBtStructureType.LegalEntity,
    StructureFunction: StructureFunctions.STRUCTURE_TRANSFER_TO_LEGAL_ENTITY,
  },
  // Seat - Dispose
  {
    ParentStructureType: StructureBtStructureType.PositionSeat,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.PositionSeat,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_SEAT,
  },
  {
    ParentStructureType: StructureBtStructureType.PositionSeat,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.Department,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_DEPARTMENT,
  },
  {
    ParentStructureType: StructureBtStructureType.PositionSeat,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.Division,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_DIVISION,
  },
  {
    ParentStructureType: StructureBtStructureType.PositionSeat,
    MovementType: StructureBtStructureMovementType.Dispose,
    StructureType: StructureBtStructureType.LegalEntity,
    StructureFunction: StructureFunctions.STRUCTURE_DISPOSE_ENTITY,
  },
];
