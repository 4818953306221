import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TimeTrackingActions, TimeTrackingActionTypes } from '../actions/timetracking.actions';
import * as fromDashboardRelatedReducer from './dashboard-related.reducer';

// Models
import { TimeEntryApiModel } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type TimeTrackingState = EntityState<TimeEntryApiModel>;

export interface TimeTrackingRelatedState {
  timeTrackingState: TimeTrackingState;
  loading: boolean;
  error: any;
}

export const timeTrackingKeys = ['bt_verdetimeentryid'];
export const timeTrackingStateAdapter: EntityAdapter<TimeEntryApiModel> = createEntityAdapter<TimeEntryApiModel>({
  selectId: (data) => keyHandler(data, timeTrackingKeys),
});

export const initialTimeTrackingState: TimeTrackingState = timeTrackingStateAdapter.getInitialState({});

export const initialTimeTrackingRelatedState = {
  timeTrackingState: initialTimeTrackingState,
  loading: false,
  error: {},
};

export function timeTrackingStateRelatedReducer(state = initialTimeTrackingRelatedState, action: TimeTrackingActions): TimeTrackingRelatedState {
  switch (action.type) {
    // Get All TimeTracking
    case TimeTrackingActionTypes.TIMETRACKING_GET_ALL:
      return { ...state, loading: true };
    case TimeTrackingActionTypes.TIMETRACKING_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        timeTrackingState: timeTrackingStateAdapter.setAll(action.payload, state.timeTrackingState),
      };
    }
    case TimeTrackingActionTypes.TIMETRACKING_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Clear
    case TimeTrackingActionTypes.TIMETRACKING_CLEAR:
      return { ...initialTimeTrackingRelatedState };
    default:
      return state;
  }
}

export const getHomeRelatedState = createFeatureSelector<fromDashboardRelatedReducer.DashboardRelatedState>('dashboardRelatedReducer');

export const getTimeTrackingRelatedState = createSelector(
  getHomeRelatedState,
  (state: fromDashboardRelatedReducer.DashboardRelatedState) => state.timeTrackingState,
);

// Current TimeTracking
export const getTimeTrackingState = createSelector(getTimeTrackingRelatedState, (state) => state.timeTrackingState);
export const { selectAll: getTimeTracking } = timeTrackingStateAdapter.getSelectors(getTimeTrackingState);

// Loading
export const isLoading = createSelector(getTimeTrackingRelatedState, (state) => state.loading);

// Error
export const getError = createSelector(getTimeTrackingRelatedState, (state) => state.error);
