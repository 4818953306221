/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { VerdeLearnCallouts } from '../models/verde-learn-callouts';

@Injectable({
  providedIn: 'root',
})
export class SharedApiVerdeLearnCalloutsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation postVerdeLearnCallouts
   */
  static readonly PostVerdeLearnCalloutsPath = '/api/VerdeLearnCallouts/post/employeecallouts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVerdeLearnCallouts()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeLearnCallouts$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeLearnCallouts }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeLearnCalloutsService.PostVerdeLearnCalloutsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVerdeLearnCallouts$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeLearnCallouts(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeLearnCallouts }): Observable<boolean> {
    return this.postVerdeLearnCallouts$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
