import { Action } from '@ngrx/store';
import { VerdeTask } from '@verde/api';

export enum TasksActionTypes {
  // Get All Tasks
  TASKS_GET_ALL = '[TASKS] Get All',
  TASKS_GET_ALL_SUCCESS = '[TASKS] Get All Success',
  TASKS_GET_ALL_ERROR = '[TASKS] Get All Error',

  // Clear Tasks
  TASKS_CLEAR = '[TASKS] Clear',
}

// Get All Tasks
export class TasksGetAll implements Action {
  readonly type = TasksActionTypes.TASKS_GET_ALL;
  constructor(public employeeID: string, public legalEntityID: string) {}
}

export class TasksGetAllSuccess implements Action {
  readonly type = TasksActionTypes.TASKS_GET_ALL_SUCCESS;
  constructor(public payload: VerdeTask[]) {}
}

export class TasksGetAllError implements Action {
  readonly type = TasksActionTypes.TASKS_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// Clear Tasks
export class TasksClear implements Action {
  readonly type = TasksActionTypes.TASKS_CLEAR;
  constructor(public payload: any) {}
}

export type TasksActions =
  // Get All Tasks
  | TasksGetAll
  | TasksGetAllSuccess
  | TasksGetAllError
  // Clear Tasks
  | TasksClear;
