/* tslint:disable */
/* eslint-disable */
export interface SubOfficesFiltered {
  _bt_office_value?: null | string;
  bt_attachment_url?: null | string;
  bt_attachmenttext?: null | string;
  bt_name?: null | string;
  bt_verdesubofficeid?: null | string;
  odataOffice?: null | string;
  odataPostOffice?: null | string;
}
