import { Action } from '@ngrx/store';
import { StringArrayParamaters, WorkspaceShortcut } from '@verde/api';

export enum ShortcutsActionTypes {
  // Get Shortcuts
  SHORTCUTS_GET = '[SHORTCUTS] Get',
  SHORTCUTS_GET_SUCCESS = '[SHORTCUTS] Get Success',
  SHORTCUTS_GET_ERROR = '[SHORTCUTS] Get Error',

  // Clear Shortcuts
  SHORTCUTS_CLEAR = '[SHORTCUTS] Clear',
}

// Get Shortcuts
export class ShortcutsGet implements Action {
  readonly type = ShortcutsActionTypes.SHORTCUTS_GET;
  constructor(public body: StringArrayParamaters) {}
}

export class ShortcutsGetSuccess implements Action {
  readonly type = ShortcutsActionTypes.SHORTCUTS_GET_SUCCESS;
  constructor(public payload: WorkspaceShortcut[]) {}
}

export class ShortcutsGetError implements Action {
  readonly type = ShortcutsActionTypes.SHORTCUTS_GET_ERROR;
  constructor(public payload: any) {}
}

// Clear Shortcuts
export class ShortcutsClear implements Action {
  readonly type = ShortcutsActionTypes.SHORTCUTS_CLEAR;
  constructor(public payload: any) {}
}

export type ShortcutsActions =
  // Get Shortcuts
  | ShortcutsGet
  | ShortcutsGetSuccess
  | ShortcutsGetError
  // Clear Shortcuts
  | ShortcutsClear;
