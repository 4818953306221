import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StepperComponent } from '@progress/kendo-angular-layout';
import { NotificationService } from '@progress/kendo-angular-notification';
import {
  ProcessesApiProcessesService,
  ProcessesBtEmployeeStatusEnum,
  ProcessesBtSeatMovementType,
  ProcessesCitizenshipStatus,
  ProcessesEmployeeOnboarding,
  ProcessesEmployeeType,
  ProcessesGetCitizenshipStatusQuery,
  ProcessesGetLocalResidencyStatusQuery,
  ProcessesLocalResidencyStatus,
  ProcessesPositionSeatEmployee,
  ProcessesPositionSeats,
  ProcessesProbation,
  ProcessesProcessCountry,
  ProcessesProcessNationality,
  ProcessesShiftType,
  ProcessesValidateIdNumberQuery,
  ProcessesValidateTaxNumberQuery,
  UserAppConfigDto,
} from '@verde/api';
import { UserService } from '@verde/core';
import { ModalService, RefreshService } from '@verde/shared';
import { HrPortalService } from 'apps/verde/src/app/features/hr-portal/services/hr-portal.service';
import moment from 'moment';
import { forkJoin, Subject, take, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../services/verde-approval.service';

@Component({
  selector: 'verde-employee-onboarding',
  templateUrl: './employee-onboarding.component.html',
  styleUrls: ['./employee-onboarding.component.scss'],
})
export class EmployeeOnboardingComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  @ViewChild('appendTo', { read: ViewContainerRef, static: false })
  public appendTo: ViewContainerRef;

  public loadingPanelVisible = true;
  @ViewChild('stepper', { static: true })
  public stepper: StepperComponent;
  public currentStep = 0;
  private sumbitted = false;
  private isStepValid = (index: number): boolean => {
    return this.getGroupAt(index).valid;
  };
  private shouldValidate = (): boolean => {
    return this.sumbitted === true;
  };

  public steps = [
    {
      label: 'Structure',
      isValid: this.isStepValid,
      validate: this.shouldValidate,
    },
    {
      label: 'Required Info',
      isValid: this.isStepValid,
      validate: this.shouldValidate,
    },
    {
      label: 'Nationality',
      isValid: this.isStepValid,
      validate: this.shouldValidate,
    },
    {
      label: 'Tax Info',
      isValid: this.isStepValid,
      validate: this.shouldValidate,
    },
  ];

  nationalities: any[] = [];
  countries: any[] = [];
  localResidencyStatus: any[] = [];
  citizenStatus: any[] = [];
  employeeType: any[] = [];
  probation: any[] = [];
  shiftType: any[] = [];

  public form = new FormGroup({
    structure: new FormGroup({
      description: new FormControl(),
      position: new FormControl(),
      division: new FormControl(),
      department: new FormControl(),
      legalEntity: new FormControl(),
      cvDoc: new FormControl(null, Validators.required),
    }),
    accountDetails: new FormGroup({
      startDate: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      middleNames: new FormControl(''),
      preferredName: new FormControl(''),
      empNumber: new FormControl(''),
      employeeTypes: new FormControl(),
      probationType: new FormControl(),
      shiftTypes: new FormControl(),
    }),
    personalDetails: new FormGroup({
      countryResidence: new FormControl(null, Validators.required),
      localResidency: new FormControl(null, Validators.required),
      primaryNationality: new FormControl(null, Validators.required),
      citizenshipStatus: new FormControl(null, Validators.required),
      foreignNational: new FormControl(),
      idNumber: new FormControl('', [Validators.required]),
      idUpload: new FormControl(null, Validators.required),
    }),

    passportInfo: new FormGroup({
      hasPassport: new FormControl('no'),
      passportCountry: new FormControl(null),
      passportNumber: new FormControl(''),
      passportExpDate: new FormControl(''),
      passportUpload: new FormControl(null),
    }),

    paymentDetails: new FormGroup({
      taxNoOutstanding: new FormControl('yes', Validators.required),
      taxNo: new FormControl(''),
    }),
  });

  positionSeat: ProcessesPositionSeats;
  taxValidation: string = '';
  newEmpGuid: string;
  testCountry: string = '';
  CountryObj: ProcessesProcessCountry;
  nationalitiesObj: ProcessesProcessNationality;
  localResidencyStatusObj: ProcessesLocalResidencyStatus;
  citizenStatusObj: ProcessesCitizenshipStatus;
  employeeTypeObj: ProcessesEmployeeType;
  probationObj: ProcessesProbation;
  shiftTypeObj: ProcessesShiftType;
  validationMessage: string;
  taxValidationMessage: string;
  validateYesNo: boolean;
  confirmMessage: string;
  public config: UserAppConfigDto;
  totalSteps: number = 4;
  count: number = 3;
  passportStep: number = 10;
  taxStep: number = 3;
  foreignNational: boolean;

  paymentDetails: any;
  personalDetails: any;

  constructor(
    private processesApiProcessesService: ProcessesApiProcessesService,
    private sidebarService: VerdeApprovalService,
    private refreshService: HrPortalService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    private userService: UserService,
    private refresh: RefreshService,
  ) {}

  ngOnInit(): void {
    this.form.get('personalDetails.foreignNational')?.valueChanges.subscribe((value) => {
      const passportCountry = this.form.get('passportInfo.passportCountry');
      const passportNumber = this.form.get('passportInfo.passportNumber');
      const passportExpDate = this.form.get('passportInfo.passportExpDate');
      const passportUpload = this.form.get('passportInfo.passportUpload');

      if (value === 'yes') {
        // Add required validators if hasPassport is 'yes'
        passportCountry?.setValidators(Validators.required);
        passportNumber?.setValidators(Validators.required);
        passportExpDate?.setValidators(Validators.required);
        passportUpload?.setValidators(Validators.required);
      } else {
        // Clear validators if hasPassport is not 'yes'
        passportCountry?.clearValidators();
        passportNumber?.clearValidators();
        passportExpDate?.clearValidators();
        passportUpload?.clearValidators();
      }

      // Update the form control validity status
      passportCountry?.updateValueAndValidity();
      passportNumber?.updateValueAndValidity();
      passportExpDate?.updateValueAndValidity();
      passportUpload?.updateValueAndValidity();
    });

    this.paymentDetails = this.form.controls.paymentDetails;
    this.personalDetails = this.form.controls.personalDetails;

    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.config = data;
    });

    this.sidebarService
      .getPositionSeat()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: any) => {
        this.positionSeat = data;
        this.testCountry = this.positionSeat?.bt_legalentityCountryText || '';
      });

    let body: any = {
      stateCode: true,
    };
    let body2: any = {
      legalEntity: this.positionSeat?.cr1f2_legalentity?.id || 0,
    };

    const observables = [
      this.processesApiProcessesService.getProcessCountry({ body: body }),
      this.processesApiProcessesService.getProcessNationality({ body: body }),
      this.processesApiProcessesService.getEmployeeType({ body: body2 }),
      this.processesApiProcessesService.getProbation({ body: body2 }),
      this.processesApiProcessesService.getShiftType({ body: body2 }),
    ];

    forkJoin(observables)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (results) => {
          this.countries = results[0];
          this.nationalities = results[1];
          this.employeeType = results[2];
          this.probation = results[3];
          this.shiftType = results[4];

          this.countries.sort((a, b) => {
            if (a.bthr_country < b.bthr_country) {
              return -1;
            } else if (a.bthr_country > b.bthr_country) {
              return 1;
            } else {
              return 0;
            }
          });

          this.citizenStatus.sort((a, b) => {
            if (a.bt_statusname < b.bt_statusname) {
              return -1;
            } else if (a.bt_statusname > b.bt_statusname) {
              return 1;
            } else {
              return 0;
            }
          });

          this.nationalities.sort((a, b) => {
            if (a.bthr_nationality < b.bthr_nationality) {
              return -1;
            } else if (a.bthr_nationality > b.bthr_nationality) {
              return 1;
            } else {
              return 0;
            }
          });

          this.employeeType.sort((a, b) => {
            if (a.bthr_employeetype < b.bthr_employeetype) {
              return -1;
            } else if (a.bthr_employeetype > b.bthr_employeetype) {
              return 1;
            } else {
              return 0;
            }
          });

          this.probation.sort((a, b) => {
            if (a.bt_probationname < b.bt_probationname) {
              return -1;
            } else if (a.bt_probationname > b.bt_probationname) {
              return 1;
            } else {
              return 0;
            }
          });

          this.shiftType.sort((a, b) => {
            if (a.bt_shifttype < b.bt_shifttype) {
              return -1;
            } else if (a.bt_shifttype > b.bt_shifttype) {
              return 1;
            } else {
              return 0;
            }
          });

          this.employeeTypeObj = this.employeeType.find((c) => c.bthr_employeetypeid === String(this.positionSeat.bt_employeetype?.id));
          if (this.employeeTypeObj) {
            this.form.get('accountDetails.employeeTypes').setValue(this.employeeTypeObj);
          }

          this.probationObj = this.probation.find((c) => c.bt_probationid === String(this.positionSeat.bt_probationtype?.id));
          if (this.probationObj) {
            this.form.get('accountDetails.probationType').setValue(this.probationObj);
          }

          this.shiftTypeObj = this.shiftType.find((c) => c.bt_shifttypeid === String(this.positionSeat.bt_shifttype?.id));
          if (this.shiftTypeObj) {
            this.form.get('accountDetails.shiftTypes').setValue(this.shiftTypeObj);
          }

          this.CountryObj = this.countries.find((c) => c.bthr_country === String(this.testCountry));
          if (this.CountryObj) {
            this.form.get('personalDetails.countryResidence').setValue(this.CountryObj);
            if (this.CountryObj.bthr_country == this.positionSeat.bt_legalentityCountryText) {
              this.foreignNational = false;
            } else if (this.CountryObj.bthr_country != this.positionSeat.bt_legalentityCountryText) {
              this.foreignNational = true;
            }
            this.setResidency(this.CountryObj);
          }

          this.nationalitiesObj = this.nationalities.find((c) => c.bt_country?.primaryName === String(this.testCountry));
          if (this.nationalitiesObj) {
            this.form.get('personalDetails.primaryNationality').setValue(this.nationalitiesObj);
            this.setDefaultCitizenStatus(this.nationalitiesObj);
            this.refresh.updateSharedValue(this.nationalitiesObj.bthr_nationality);
          }
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.loadingPanelVisible = false;
        },
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  setDefaultCitizenStatus(nationality: ProcessesProcessNationality) {
    let body: ProcessesGetCitizenshipStatusQuery;
    if (nationality.bthr_nationalityid) {
      body = {
        nationalityID: nationality.bthr_nationalityid,
      };
    } else {
      body = {
        nationalityID: '00000000-0000-0000-0000-000000000000',
      };
    }
    this.processesApiProcessesService
      .getCitizenshipStatus({ body: body })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.citizenStatus = val;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.citizenStatusObj = this.citizenStatus.find((c) => c.bt_statusname === String(this.nationalitiesObj.bt_defaultcitizenstatus?.primaryName));
          if (this.citizenStatusObj) {
            this.form.get('personalDetails.citizenshipStatus').setValue(this.citizenStatusObj);
          }
        },
      );
  }

  setResidency(country) {
    let body: ProcessesGetLocalResidencyStatusQuery = {
      countryID: country.bthr_countryid,
    };

    this.processesApiProcessesService
      .getLocalResidencyStatus({ body: body })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.localResidencyStatus = val;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.localResidencyStatusObj = this.CountryObj?.bt_defaultresidency
            ? this.localResidencyStatus?.find((c) => c.bt_localresidencystatus === String(this.CountryObj.bt_defaultresidency.primaryName))
            : null;
          if (this.localResidencyStatusObj) {
            this.form.get('personalDetails.localResidency').setValue(this.localResidencyStatusObj);
            this.validateYesNo = this.localResidencyStatusObj.bt_validateid;
          }
        },
      );
  }

  public get currentGroup(): FormGroup {
    return this.getGroupAt(this.currentStep);
  }

  private getGroupAt(index: number): FormGroup {
    const groups = Object.keys(this.form.controls).map((groupName) => this.form.get(groupName)) as FormGroup[];
    return groups[index];
  }

  public next(): void {
    this.sumbitted = true;
    this.stepper.validateSteps();
    //if (this.foreignNational == true && this.currentStep == 2) {
    //  this.currentStep += 1;
    //} else if (this.foreignNational == false && this.currentStep == 2) {
    //  this.currentStep = this.currentStep + 2;
    //}
    //else {
    //  this.currentStep += 1;
    //}

    this.currentStep += 1;
  }

  public prev(): void {
    this.sumbitted = true;
    this.stepper.validateSteps();
    this.currentStep -= 1;
  }

  public submit() {
    this.sumbitted = true;

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.stepper.validateSteps();
    } else {
      this.modalService.open('confirmModal');
      this.confirmMessage = 'Please confirm employee onboarding';
    }
  }

  confirmOnboarding() {
    let validation: ProcessesValidateIdNumberQuery = {
      countryName: this.form.value.personalDetails.primaryNationality?.bthr_nationality || '',
      idNumber: this.form.value.personalDetails.idNumber || '',
      validateId: this.form.value.personalDetails.localResidency?.bt_validateid || false,
    };
    this.processesApiProcessesService
      .validateIdNumber({ body: validation })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.validationMessage = val;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.taxNumberValidation();
        },
      );
  }

  taxNumberValidation() {
    if (this.form.value.paymentDetails.taxNoOutstanding === 'no') {
      let validation: ProcessesValidateTaxNumberQuery = {
        countryName: this.form.value.personalDetails.primaryNationality.bthr_nationality,
        taxNumber: this.form.value.paymentDetails.taxNo,
      };
      this.processesApiProcessesService
        .validateTaxNumber({ body: validation })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (val) => {
            this.taxValidationMessage = val;
          },
          (error) => {
            console.error(error);
          },
          () => {
            this.postEmployeeOnboarding();
          },
        );
    } else {
      this.postEmployeeOnboardingNoTaxNo();
    }
  }

  postEmployeeOnboarding() {
    if (
      (this.validationMessage === 'Cannot be validated' || this.validationMessage === 'Valid - South African ID number') &&
      (this.taxValidationMessage === 'Valid - ' + this.form.value.personalDetails.primaryNationality.bthr_nationality + ' Tax number' ||
        this.taxValidationMessage ===
          "Invalid - Can't be validated as " + this.form.value.personalDetails.primaryNationality.bthr_nationality + ' is not catered for.')
    ) {
      this.loadingPanelVisible = true;

      let forNational: boolean;
      if (this.form.value.personalDetails.foreignNational === 'yes') {
        forNational = true;
      } else {
        forNational = false;
      }

      let outTax: boolean;
      if (this.form.value.paymentDetails.taxNoOutstanding === 'yes') {
        outTax = true;
      } else {
        outTax = false;
      }

      let passportYN: boolean;
      if (this.form.value.passportInfo.hasPassport === 'yes') {
        passportYN = true;
      } else {
        passportYN = false;
      }

      let empStatus: ProcessesBtEmployeeStatusEnum;
      if (new Date(this.form.value.accountDetails.startDate) > new Date()) {
        empStatus = ProcessesBtEmployeeStatusEnum.PreEmployment;
      } else {
        empStatus = ProcessesBtEmployeeStatusEnum.Employed;
      }

      const startDate = new Date(this.form.value.accountDetails.startDate);
      const updatedDate = new Date(startDate.getTime() + 12 * 60 * 60 * 1000);
      this.form.value.accountDetails.startDate = updatedDate.toISOString();

      const passportExpDate = this.form.value.passportInfo.passportExpDate;
      if (passportExpDate) {
        const passExpDate = new Date(passportExpDate);
        if (!isNaN(passExpDate.getTime())) {
          const updatedpassExpDate = new Date(passExpDate.getTime() + 12 * 60 * 60 * 1000);
          this.form.patchValue({
            passportInfo: {
              passportExpDate: updatedpassExpDate.toISOString(),
            },
          });
        } else {
        }
      } else {
      }

      let body: ProcessesEmployeeOnboarding = {
        bthr_firstname: this.form.value.accountDetails.firstName,
        bthr_lastname: this.form.value.accountDetails.lastName,
        bthr_legalentity: this.positionSeat.cr1f2_legalentity.id,
        bthr_foreignnational: forNational,
        bthr_nationalidnumber: this.form.value.personalDetails.idNumber,
        bthr_employeetype: this.form.value.accountDetails.employeeTypes.bthr_employeetypeid,
        bthr_taxnumber: this.form.value.paymentDetails.taxNo,
        bt_countryofresidence: this.form.value.personalDetails.countryResidence.bthr_countryid,
        bt_nationality: this.form.value.personalDetails.primaryNationality.bthr_nationalityid,
        bt_taxnoouts: outTax,
        bt_citizenshipstatus: this.form.value.personalDetails.citizenshipStatus.bt_citizenshipstatusid,
        bt_localresidencystatus: this.form.value.personalDetails.localResidency.bt_localresidencystatusid,
        bt_neworganizationjoindate: this.form.value.accountDetails.startDate,
        bt_shifttype: this.form.value.accountDetails.shiftTypes.bt_shifttypeid,
        bt_probationtype: this.form.value.accountDetails.probationType.bt_probationid,
        seat: this.positionSeat.bthr_positionseatid,
        bt_EmployeeStatus: empStatus,
        bthr_middlenames: this.form.value.accountDetails.middleNames,
        bthr_preferredname: this.form.value.accountDetails.preferredName,
        bthr_employee_id: this.form.value.accountDetails.empNumber,
      };

      if (forNational == true) {
        (body.hasPassport = passportYN),
          (body.passportCountry = this.form.value.personalDetails.countryResidence.bthr_countryid),
          (body.passportExpDate = this.form.value.passportInfo.passportExpDate),
          (body.passportNumber = this.form.value.passportInfo.passportNumber);
      }

      this.processesApiProcessesService
        .postEmployeeOnboarding({
          body: {
            body: body,
          },
        })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            this.newEmpGuid = ret;
          },
          (error) => {
            console.error(error);
          },
          () => {
            /*this.postEmployeeAssignment();*/
            this.sidebarService.setShowSidebar(false);
            this.refreshService.emitParameter(this.positionSeat.cr1f2_legalentity.id);
            if (this.form.value.personalDetails.idUpload != null) {
              this.uploadIddoc();
            } else {
              this.uploadCVDoc();
            }
          },
        );
    } else {
      if (this.validationMessage === 'Cannot be validated' || this.validationMessage === 'Valid - South African ID number') {
        this.showError(this.taxValidationMessage);
      } else if (
        this.taxValidationMessage === 'Valid - ' + this.form.value.personalDetails.primaryNationality.bthr_nationality + ' Tax number' ||
        this.taxValidationMessage ===
          "Invalid - Can't be validated as " + this.form.value.personalDetails.primaryNationality.bthr_nationality + ' is not catered for.'
      ) {
        this.showError(this.validationMessage);
      } else {
        this.showError(this.validationMessage);
        this.showError(this.taxValidationMessage);
      }
    }
  }

  postEmployeeOnboardingNoTaxNo() {
    if (this.validationMessage === 'Cannot be validated' || this.validationMessage === 'Valid - South African ID number') {
      let forNational: boolean;
      if (this.form.value.personalDetails.foreignNational === 'yes') {
        forNational = true;
      } else {
        forNational = false;
      }

      let outTax: boolean;
      if (this.form.value.paymentDetails.taxNoOutstanding === 'yes') {
        outTax = true;
      } else {
        outTax = false;
      }
      let passportYN: boolean;
      if (this.form.value.passportInfo.hasPassport === 'yes') {
        passportYN = true;
      } else {
        passportYN = false;
      }

      let empStatus: ProcessesBtEmployeeStatusEnum;
      if (new Date(this.form.value.accountDetails.startDate) > new Date()) {
        empStatus = ProcessesBtEmployeeStatusEnum.PreEmployment;
      } else {
        empStatus = ProcessesBtEmployeeStatusEnum.Employed;
      }
      const startDate = new Date(this.form.value.accountDetails.startDate);
      const updatedDate = new Date(startDate.getTime() + 12 * 60 * 60 * 1000);
      this.form.value.accountDetails.startDate = updatedDate.toISOString();

      const passportExpDate = this.form.value.passportInfo.passportExpDate;
      if (passportExpDate) {
        const passExpDate = new Date(passportExpDate);
        if (!isNaN(passExpDate.getTime())) {
          const updatedpassExpDate = new Date(passExpDate.getTime() + 12 * 60 * 60 * 1000);
          this.form.patchValue({
            passportInfo: {
              passportExpDate: updatedpassExpDate.toISOString(),
            },
          });
        } else {
        }
      } else {
      }

      let body: ProcessesEmployeeOnboarding = {
        bthr_firstname: this.form.value.accountDetails.firstName,
        bthr_lastname: this.form.value.accountDetails.lastName,
        bthr_legalentity: this.positionSeat.cr1f2_legalentity.id,
        bthr_foreignnational: forNational,
        bthr_nationalidnumber: this.form.value.personalDetails.idNumber,
        bthr_employeetype: this.form.value.accountDetails.employeeTypes.bthr_employeetypeid,
        bthr_taxnumber: this.form.value.paymentDetails.taxNo,
        bt_countryofresidence: this.form.value.personalDetails.countryResidence.bthr_countryid,
        bt_nationality: this.form.value.personalDetails.primaryNationality.bthr_nationalityid,
        bt_taxnoouts: outTax,
        bt_citizenshipstatus: this.form.value.personalDetails.citizenshipStatus.bt_citizenshipstatusid,
        bt_localresidencystatus: this.form.value.personalDetails.localResidency.bt_localresidencystatusid,
        bt_neworganizationjoindate: this.form.value.accountDetails.startDate,
        bt_shifttype: this.form.value.accountDetails.shiftTypes.bt_shifttypeid,
        bt_probationtype: this.form.value.accountDetails.probationType.bt_probationid,
        seat: this.positionSeat.bthr_positionseatid,
        bt_EmployeeStatus: empStatus,
        bthr_middlenames: this.form.value.accountDetails.middleNames,
        bthr_preferredname: this.form.value.accountDetails.preferredName,
        bthr_employee_id: this.form.value.accountDetails.empNumber,
      };
      if (forNational == true) {
        (body.hasPassport = passportYN),
          (body.passportCountry = this.form.value.personalDetails.countryResidence.bthr_countryid),
          (body.passportExpDate = this.form.value.passportInfo.passportExpDate),
          (body.passportNumber = this.form.value.passportInfo.passportNumber);
      }
      this.loadingPanelVisible = true;
      this.processesApiProcessesService
        .postEmployeeOnboarding({
          body: {
            body: body,
          },
        })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            this.newEmpGuid = ret;
          },
          (error) => {
            console.error(error);
          },
          () => {
            /*this.postEmployeeAssignment();*/
            this.sidebarService.setShowSidebar(false);
            this.refreshService.emitParameter(this.positionSeat.cr1f2_legalentity.id);
            if (this.form.value.personalDetails.idUpload != null) {
              this.uploadIddoc();
            } else {
              this.uploadCVDoc();
            }
          },
        );
    } else {
      this.showError(this.validationMessage);
    }
  }

  postEmployeeAssignment() {
    let body: ProcessesPositionSeatEmployee = {
      bt_employee: this.newEmpGuid,
      bt_movementtype: ProcessesBtSeatMovementType.Occupy,
      bt_positionseat: this.positionSeat.bthr_positionseatid,
      bt_legalentity: this.positionSeat.cr1f2_legalentity.id,
      bt_employeelegalentity: this.positionSeat.cr1f2_legalentity.id,
    };

    this.processesApiProcessesService
      .positionSeatEmployee({ body: { body: body } })
      .pipe(take(1))
      .subscribe(
        (ret) => {},
        (error) => {
          console.error(error);
        },
        () => {
          this.loadingPanelVisible = false;
        },
      );
  }

  public showError(errorMsg: string): void {
    this.notificationService.show({
      appendTo: this.appendTo,
      content: errorMsg,
      position: { horizontal: 'right', vertical: 'top' },
      animation: { type: 'fade', duration: 600 },
      type: { style: 'error', icon: true },
      hideAfter: 2000,
    });
  }

  uploadIddoc() {
    let empFullName = this.form.value.accountDetails.firstName + this.form.value.accountDetails.lastName;
    const idFile = this.renameFile(this.form.value.personalDetails.idUpload[0], 'ID_' + empFullName + '_' + moment().format('YYYY-MM-DD, h:mm:ss a'));
    let uploadBody: any = {
      file: idFile,
      recordID: this.newEmpGuid,
      entityName: 'bthr_employee',
      columnName: 'bthr_nationalidpassport',
    };

    this.processesApiProcessesService
      .uploadOnboardingFile({
        body: uploadBody,
      })
      .pipe(take(1))
      .subscribe(
        () => {
          //
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.form.value.structure.cvDoc != null) {
            this.uploadCVDoc();
          }
        },
      );
  }

  uploadCVDoc() {
    let empFullName = this.form.value.accountDetails.firstName + this.form.value.accountDetails.lastName;
    const cvFile = this.renameFile(this.form.value.structure.cvDoc[0], 'CV_' + empFullName + '_' + moment().format('YYYY-MM-DD, h:mm:ss a'));
    let uploadBody: any = {
      file: cvFile,
      recordID: this.newEmpGuid,
      entityName: 'bthr_employee',
      columnName: 'bt_cvdocument',
    };

    this.processesApiProcessesService
      .uploadOnboardingFile({
        body: uploadBody,
      })
      .pipe(take(1))
      .subscribe(
        () => {
          //
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.form.value.passportInfo.passportUpload != null) {
            this.uploadPassport();
          }
        },
      );
  }

  uploadPassport() {
    let empFullName = this.form.value.accountDetails.firstName + this.form.value.accountDetails.lastName;
    const passportFile = this.renameFile(
      this.form.value.passportInfo.passportUpload[0],
      'PASSPORT_' + empFullName + '_' + moment().format('YYYY-MM-DD, h:mm:ss a'),
    );
    let uploadBody: any = {
      file: passportFile,
      recordID: this.newEmpGuid,
      entityName: 'bthr_employee',
      columnName: 'bt_passport',
    };

    this.processesApiProcessesService
      .uploadOnboardingFile({
        body: uploadBody,
      })
      .pipe(take(1))
      .subscribe(
        () => {
          //
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }

  onValidateTaxNumber() {
    if (this.form.value.personalDetails.countryResidence != null) {
      this.validateTaxNumber();
    } else {
      alert('Country cant be empty');
    }
  }

  validateTaxNumber() {
    let validateTax: ProcessesValidateTaxNumberQuery = {
      countryName: this.form.value.personalDetails.primaryNationality.bthr_nationality,
      taxNumber: this.form.value.paymentDetails.taxNo,
    };
    this.processesApiProcessesService
      .validateTaxNumber({ body: validateTax })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (val) => {
          this.taxValidation = val;
        },
        () => {},
      );
  }

  handleChildEvent(data: boolean) {
    if (data == true) {
      this.steps = [
        {
          label: 'Structure',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Required Info',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Nationality',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Passport',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Tax Info',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
      ];
      this.totalSteps = 5;
      this.count = 4;
      this.passportStep = 3;
      this.taxStep = 4;
      this.foreignNational = true;
    } else if (data == false) {
      this.steps = [
        {
          label: 'Structure',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Required Info',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Nationality',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
        {
          label: 'Tax Info',
          isValid: this.isStepValid,
          validate: this.shouldValidate,
        },
      ];
      this.totalSteps = 4;
      this.count = 3;
      this.taxStep = 3;
      this.passportStep = 10;
      this.foreignNational = false;
    }
  }

  renameFile(file: File, newBaseName: string): File {
    const originalFileName = file.name;
    const fileExtension = originalFileName.substring(originalFileName.lastIndexOf('.'));
    const newFileName = `${newBaseName}${fileExtension}`;
    return new File([file], newFileName, { type: file.type });
  }
}
