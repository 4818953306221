/* tslint:disable */
/* eslint-disable */
export interface BthrFund {
  _bt_documenttemplate_value?: null | string;
  _bt_fundtypelookup_value?: null | string;
  bt_updatedallowed?: boolean;
  bt_updatejson?: null | string;
  bthr_fundid?: null | string;
  odataDocumentTemplate?: null | string;
  odataFundTypeLookup?: null | string;
}
