/* tslint:disable */
/* eslint-disable */
export interface VerdePositions {
  _bt_department_value?: null | string;
  _bt_positiontype_value?: null | string;
  _bt_securityprofile_value?: null | string;
  _bthr_department_value?: null | string;
  _bthr_division_value?: null | string;
  _bthr_grade_value?: null | string;
  _bthr_legalentity_value?: null | string;
  _bthr_parentposition_value?: null | string;
  bt_JobDescriptionAttachment_Name?: null | string;
  bt_carriestarget?: null | boolean;
  bt_commission?: null | boolean;
  bt_costcentre?: null | string;
  bt_kpi?: null | boolean;
  bt_positionunique?: null | string;
  bthr_description?: null | string;
  bthr_positionid?: null | string;
  bthr_positionno?: null | string;
  bthr_positiontitle?: null | string;
  bthr_recordstatus?: null | number;
  odataDepartment?: null | string;
  odataDepartmentNew?: null | string;
  odataDivision?: null | string;
  odataGrade?: null | string;
  odataLegalEntity?: null | string;
  odataParentPosition?: null | string;
  odataPositionType?: null | string;
  odataSecurityProfile?: null | string;
}
