/* tslint:disable */
/* eslint-disable */
export interface VaccineBooster {
  _bt_boostervaccine_value?: null | string;
  _bt_country_value?: null | string;
  _bt_vaccine_value?: null | string;
  bt_availablefrom?: null | string;
  bt_sequence?: null | number;
  bt_vaccinationstatus?: null | number;
  bt_vaccinebooster?: null | string;
  bt_vaccineboosterid?: null | string;
  odataBoosterVaccine?: null | string;
  odataVaccine?: null | string;
}
