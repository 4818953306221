/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TimeOffApiConfiguration, TimeOffApiConfigurationParams } from './time-off-api-configuration';

import { TimeOffApiEmployeeMovementsService } from './services/time-off-api-employee-movements.service';
import { TimeOffApiEmployeeProvisionService } from './services/time-off-api-employee-provision.service';
import { TimeOffApiManualCycleCreationService } from './services/time-off-api-manual-cycle-creation.service';
import { TimeOffApiTimeOffService } from './services/time-off-api-time-off.service';
import { TimeOffApiVersionService } from './services/time-off-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    TimeOffApiEmployeeMovementsService,
    TimeOffApiEmployeeProvisionService,
    TimeOffApiManualCycleCreationService,
    TimeOffApiTimeOffService,
    TimeOffApiVersionService,
    TimeOffApiConfiguration,
  ],
})
export class TimeOffApiModule {
  static forRoot(params: TimeOffApiConfigurationParams): ModuleWithProviders<TimeOffApiModule> {
    return {
      ngModule: TimeOffApiModule,
      providers: [
        {
          provide: TimeOffApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: TimeOffApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('TimeOffApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
