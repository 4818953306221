/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PolicyApiConfiguration, PolicyApiConfigurationParams } from './policy-api-configuration';

import { PolicyApiPolicyService } from './services/policy-api-policy.service';
import { PolicyApiVersionService } from './services/policy-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [PolicyApiPolicyService, PolicyApiVersionService, PolicyApiConfiguration],
})
export class PolicyApiModule {
  static forRoot(params: PolicyApiConfigurationParams): ModuleWithProviders<PolicyApiModule> {
    return {
      ngModule: PolicyApiModule,
      providers: [
        {
          provide: PolicyApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: PolicyApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('PolicyApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
