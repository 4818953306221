/* tslint:disable */
/* eslint-disable */
export interface EquipmentKitItems {
  _bt_equipmentkit_value?: null | string;
  _bt_equipmentspec_value?: null | string;
  _bt_equipmentspectype_value?: null | string;
  _bt_equipmenttype_value?: null | string;
  _bt_equipmenttypeclass_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_requestequipmentitem_value?: null | string;
  bt_equipmentkititemid?: null | string;
  bt_equipmentkititemno?: null | string;
  bt_qty?: null | number;
  odataEquipmentKit?: null | string;
  odataEquipmentSpec?: null | string;
  odataEquipmentSpecType?: null | string;
  odataEquipmentType?: null | string;
  odataEquipmentTypeClass?: null | string;
  odataLegalEntity?: null | string;
  odataRequestEquipmentItem?: null | string;
}
