import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControlDirective, FormGroup } from '@angular/forms';
import { FleetClassificationUpdate, SharedApiVehiclesService, VehiclesFiltered } from '@verde/api';
import { UserService } from '@verde/core';
import { ModalService } from '@verde/shared';
import { FleetAccessService } from 'apps/verde/src/app/features/fleet/services/fleet-access/fleet-access.service';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'verde-fleet-classification-modal',
  templateUrl: './fleet-classification-modal.component.html',
  styleUrls: ['./fleet-classification-modal.component.scss'],
})
export class FleetClassificationModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  @Input() fleetItemID: string = '';

  @Output() hideModalEvent = new EventEmitter<boolean>();

  // Arrays
  allClassifications = [];

  selectedFleetItem: VehiclesFiltered = {};

  postClassificationUpdateBody: FleetClassificationUpdate = {};

  checkDefaultPattern: RegExp = /^(?!default$).*/;

  confirmationMessage: string = '';
  disableAnimation: boolean = false;

  effectiveDate: any;
  todaysDate: string = moment().format('YYYY-MM-DD');
  minReversalDateNew = new Date(moment().add(1, 'day').format('YYYY-MM-DD'));

  todaysDateNew = new Date();

  agreedYes: boolean = false;
  agreedNo: boolean = false;

  classificationFormDefaultState = {};

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private userService: UserService,
    private fleetAccess: FleetAccessService,
    private sharedApiVehiclesService: SharedApiVehiclesService,
  ) {}

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
  }

  ngAfterViewInit(): void {
    document.getElementById('showClassificationModal')?.click();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  hideModal() {
    this.hideModalEvent.emit(false);
  }

  getFleetItem(myForm: FormGroup) {
    this.spinner.show('fleetclassificationupdate');
    this.modalService.open('fleetClassificationUpdateModal');

    this.sharedApiVehiclesService
      .getFleetItemById({ itemID: this.fleetItemID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.selectedFleetItem = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.agreedYes = false;
          this.agreedNo = false;

          if (this.selectedFleetItem?.bthr_classification == 365580000) {
            this.allClassifications = [{ text: 'Pool Vehicle', value: 365580001 }];
          } else if (this.selectedFleetItem?.bthr_classification == 365580001) {
            this.allClassifications = [{ text: 'Company Vehicle', value: 365580000 }];
          }

          this.spinner.hide('fleetclassificationupdate');
        },
      );
  }

  postFleetClassificationUpdate(myForm: FormGroup) {
    this.spinner.show('confirmfleetclassificationupdate');
    this.modalService.close('fleetClassificationUpdateModal');
    this.modalService.open('confirmFleetClassificationUpdateModal');

    this.postClassificationUpdateBody.odataPostLegalEntity =
      '/bthr_legalentitieses(' + this.fleetAccess.selectedFleetAccess._bt_locationlegalentity_value + ')';
    this.postClassificationUpdateBody.odataPostRequestedByLegalEntity = '/bthr_legalentitieses(' + this.userService.user?.legalEntityId + ')';
    this.postClassificationUpdateBody.odataPostRequestedBy = '/bthr_employees(' + this.userService.user?.employeeId + ')';
    this.postClassificationUpdateBody.odataPostFleetItem = '/bthr_vehicleses(' + this.selectedFleetItem.bthr_vehiclesid + ')';

    this.postClassificationUpdateBody.bt_fromclassification = this.selectedFleetItem.bthr_classification;
    this.postClassificationUpdateBody.bt_toclassification = Number(myForm.value.updateToClassification.value);

    this.postClassificationUpdateBody.bt_effectivedate = myForm.value.updateEffectiveDate;

    if (this.todaysDate === moment(myForm.value.updateEffectiveDate).format('YYYY-MM-DD')) {
      this.postClassificationUpdateBody.bt_processnow = true;
    }

    if (this.agreedYes) {
      this.postClassificationUpdateBody.bt_autoreverse = true;

      this.postClassificationUpdateBody.bt_reversedate = myForm.value.updateReversalDate;
    } else {
      this.postClassificationUpdateBody.bt_autoreverse = false;
    }

    this.sharedApiVehiclesService
      .postFleetClassificationUpdate({ body: this.postClassificationUpdateBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.postClassificationUpdateBody = {};
          this.confirmationMessage = 'You have successfully submitted this classification update!';

          this.spinner.hide('confirmfleetclassificationupdate');
        },
      );
  }

  updateEffectiveDateValue(e: any) {
    this.minReversalDateNew = new Date(moment(e).add(1, 'day').format('YYYY-MM-DD'));
  }

  // agreed
  agreedYesChange(e: any, element: FormControlDirective) {
    this.agreedYes = e;
    this.agreedNo = !e;
    element.reset();
  }

  agreedNoChange(e: any, element: FormControlDirective) {
    this.agreedNo = e;
    this.agreedYes = !e;
    element.reset();
  }
}
