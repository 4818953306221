/* tslint:disable */
/* eslint-disable */
import { BtCycleType } from './bt-cycle-type';
import { BtPeriod } from './bt-period';
import { EmployeeReviewFiltered } from './employee-review-filtered';
import { User } from './user';
export interface ReviewSummariesFiltered {
  _bt_cycle_value?: null | string;
  _bt_employeeid_value?: null | string;
  _bt_period_value?: null | string;
  bt_Cycle?: BtCycleType;
  bt_EmployeeID?: User;
  bt_Period?: BtPeriod;
  bt_average?: null | number;
  bt_bthr_EmployeePerformanceReview_Summary_bt?: null | Array<EmployeeReviewFiltered>;
  bt_name?: null | string;
  bt_noofcycles?: null | number;
  bt_noofcyclescomms?: null | number;
  bt_noofcycleskpi?: null | number;
  bt_performancereviewsummaryid?: null | string;
  odataCycle?: null | string;
  odataEmployee?: null | string;
  odataPeriod?: null | string;
}
