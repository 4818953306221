import { Action } from '@ngrx/store';
import { TemplateGetMeasuresQuery, TemplatePatchTemplateMeasureCommand, TemplateUploadMeasureCommand } from '@verde/api';

export enum MeasuresActionTypes {
  // Get MEASURES
  MEASURES_GET_ALL = '[MEASURES] Get All Start',
  MEASURES_GET_ALL_SUCCESS = '[MEASURES] Get All Start Success',
  MEASURES_GET_ALL_ERROR = '[MEASURES] Get All Start Error',

  // UPDATE MEASURES
  MEASURES_UPDATE = '[MEASURES] UPDATE',
  MEASURES_UPDATE_SUCCESS = '[MEASURES] UPDATE Success',
  MEASURES_UPDATE_ERROR = '[MEASURES] UPDATE Error',

  // MEASURES Add
  MEASURES_UPLOAD = '[MEASURES] Upload',
  MEASURES_UPLOAD_SUCCESS = '[MEASURES] Upload Success',
  MEASURES_UPLOAD_ERROR = '[MEASURES] Upload Error',

  // Clear All MEASURES
  MEASURES_ITEM_CLEAR = '[MEASURES] Clear',
}

// Get Measure
export class MeasuresGet implements Action {
  readonly type = MeasuresActionTypes.MEASURES_GET_ALL;
  constructor(public body: TemplateGetMeasuresQuery) {}
}

export class MeasuresGetSuccess implements Action {
  readonly type = MeasuresActionTypes.MEASURES_GET_ALL_SUCCESS;
  constructor(public payload: any[]) {}
}

export class MeasuresGetError implements Action {
  readonly type = MeasuresActionTypes.MEASURES_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// UPDATE MEASURES
export class MeasureUpdate implements Action {
  readonly type = MeasuresActionTypes.MEASURES_UPDATE;
  constructor(public payload: TemplatePatchTemplateMeasureCommand) {}
}

export class MeasureUpdateSuccess implements Action {
  readonly type = MeasuresActionTypes.MEASURES_UPDATE_SUCCESS;
  constructor(public payload: any) {}
}

export class MeasureUpdateError implements Action {
  readonly type = MeasuresActionTypes.MEASURES_UPDATE_ERROR;
  constructor(public payload: any) {}
}

// UPLOAD MEASURE
export class MeasuresUpload implements Action {
  readonly type = MeasuresActionTypes.MEASURES_UPLOAD;
  constructor(public payload: TemplateUploadMeasureCommand) {}
}

export class MeasuresUploadSuccess implements Action {
  readonly type = MeasuresActionTypes.MEASURES_UPLOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class MeasuresUploadError implements Action {
  readonly type = MeasuresActionTypes.MEASURES_UPLOAD_ERROR;
  constructor(public payload: any) {}
}

// Clear All Measure
export class MeasuresClear implements Action {
  readonly type = MeasuresActionTypes.MEASURES_ITEM_CLEAR;
  constructor(public payload: any) {}
}

export type MeasuresActions =
  // Get Measure
  | MeasuresGet
  | MeasuresGetSuccess
  | MeasuresGetError
  // Set All Structure Change Requests
  | MeasureUpdate
  | MeasureUpdateSuccess
  | MeasureUpdateError
  // Structure Change Request Upload
  | MeasuresUpload
  | MeasuresUploadSuccess
  | MeasuresUploadError
  // Clear All Measure
  | MeasuresClear;
