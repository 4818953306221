/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvVulnerabilityAssessments } from '../models/dv-vulnerability-assessments';

@Injectable({
  providedIn: 'root',
})
export class SharedApiVulnerabilityAssessmentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllVulnerabilityAssessments
   */
  static readonly GetAllVulnerabilityAssessmentsPath = '/api/VulnerabilityAssessments/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllVulnerabilityAssessments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVulnerabilityAssessments$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvVulnerabilityAssessments>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVulnerabilityAssessmentsService.GetAllVulnerabilityAssessmentsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvVulnerabilityAssessments>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllVulnerabilityAssessments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVulnerabilityAssessments(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<DvVulnerabilityAssessments>> {
    return this.getAllVulnerabilityAssessments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvVulnerabilityAssessments>>) => r.body as Array<DvVulnerabilityAssessments>),
    );
  }

  /**
   * Path part for operation patchVulnerabilityAssessments
   */
  static readonly PatchVulnerabilityAssessmentsPath = '/api/VulnerabilityAssessments/patch/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVulnerabilityAssessments()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVulnerabilityAssessments$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvVulnerabilityAssessments;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVulnerabilityAssessmentsService.PatchVulnerabilityAssessmentsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVulnerabilityAssessments$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVulnerabilityAssessments(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvVulnerabilityAssessments;
  }): Observable<boolean> {
    return this.patchVulnerabilityAssessments$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
