import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccreditationAuthorityActions, AccreditationAuthorityActionTypes } from '../actions/accreditation-authority.actions';
import * as fromProfileRelatedReducer from './profile-related.reducer';

// Models
import { QualificationsBthrAccreditationAuthority } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type AccreditationAuthorityState = EntityState<QualificationsBthrAccreditationAuthority>;

export interface AccreditationAuthorityRelatedState {
  accreditationAuthorityState: AccreditationAuthorityState;
  selectedIds: string[];
  loading: boolean;
  error: any;
}

export const accreditationAuthorityKeys = ['bthr_AccreditationAuthorityid'];
export const accreditationAuthorityStateAdapter: EntityAdapter<QualificationsBthrAccreditationAuthority> =
  createEntityAdapter<QualificationsBthrAccreditationAuthority>({
    selectId: (data) => keyHandler(data, accreditationAuthorityKeys),
  });

export const initialAccreditationAuthorityState: AccreditationAuthorityState = accreditationAuthorityStateAdapter.getInitialState({});

export const initialAccreditationAuthorityRelatedState = {
  accreditationAuthorityState: initialAccreditationAuthorityState,
  selectedIds: [],
  loading: false,
  error: {},
};

export function AccreditationAuthorityRelatedReducer(
  state = initialAccreditationAuthorityRelatedState,
  action: AccreditationAuthorityActions,
): AccreditationAuthorityRelatedState {
  switch (action.type) {
    // Get AccreditationAuthority
    case AccreditationAuthorityActionTypes.ACCREDITATIONAUTHORITY_GET_ALL:
      return { ...state, loading: true };
    case AccreditationAuthorityActionTypes.ACCREDITATIONAUTHORITY_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        accreditationAuthorityState: accreditationAuthorityStateAdapter.setAll(action.payload, state.accreditationAuthorityState),
      };
    }
    case AccreditationAuthorityActionTypes.ACCREDITATIONAUTHORITY_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Clear
    case AccreditationAuthorityActionTypes.ACCREDITATIONAUTHORITY_CLEAR:
      return { ...initialAccreditationAuthorityRelatedState };
    default:
      return state;
  }
}

export const getProfileRelatedState = createFeatureSelector<fromProfileRelatedReducer.ProfileRelatedState>('profileRelatedReducer');

export const getAccreditationAuthorityRelatedState = createSelector(
  getProfileRelatedState,
  (state: fromProfileRelatedReducer.ProfileRelatedState) => state.accreditationAuthorityReducerState,
);

// Current AccreditationAuthority

export const getAccreditationAuthorityState = createSelector(getAccreditationAuthorityRelatedState, (state) => state.accreditationAuthorityState);
export const { selectAll: getAccreditationAuthority } = accreditationAuthorityStateAdapter.getSelectors(getAccreditationAuthorityState);

// Selected

export const getSelectedAccreditationAuthorityIds = createSelector(getAccreditationAuthorityRelatedState, (state) => state.selectedIds);

export const getSelectedProfiles = createSelector(
  getAccreditationAuthority,
  getSelectedAccreditationAuthorityIds,
  (AccreditationAuthority, selectedIds: string[]) =>
    AccreditationAuthority.filter((m) => selectedIds.indexOf(keyHandler(m, accreditationAuthorityKeys)) !== -1),
);

// Loading

export const isAccreditationAuthorityLoading = createSelector(getAccreditationAuthorityRelatedState, (state) => state.loading);

// Error

export const getProfileError = createSelector(getAccreditationAuthorityRelatedState, (state) => state.error);
