/* tslint:disable */
/* eslint-disable */
import { VerdeFooterTemplate } from './verde-footer-template';
import { VerdeHeaderTemplate } from './verde-header-template';
export interface VerdeDocumentsAll {
  _bt_legalentity_value?: null | string;
  bt_HeaderTemplate?: VerdeHeaderTemplate;
  bt_TemplateFooter?: VerdeFooterTemplate;
  bt_templatename?: null | string;
  bt_templateno?: null | string;
  bt_verdedocumentstemplateid?: null | string;
}
