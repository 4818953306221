import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StepperActivateEvent } from '@progress/kendo-angular-layout';
import { checkIcon, chevronDoubleRightIcon, xIcon } from '@progress/kendo-svg-icons';
import { ApprovalLogsAndApprovers, ApprovalLogsAssignedTo, SharedApiVerdeApprovalsService, UserUserDto, VehiclesFiltered, VerdeApprovalLogs } from '@verde/api';
import { UserService, ViewSDKClient } from '@verde/core';
import { RefreshService } from '@verde/shared';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { SidePanelWidth } from './enums/sidepanel-width.enum';
import { CapturePeerReviewServiceService } from './services/capture-peer-review-service.service';
import { CaptureReviewService } from './services/capture-review.service';
import { VerdeApprovalService } from './services/verde-approval.service';

@Component({
  selector: 'verde-approval-panel',
  templateUrl: './verde-approval-panel.component.html',
  styleUrls: ['./verde-approval-panel.component.scss'],
})
export class VerdeApprovalPanelComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  public icons = {
    checkIcon: checkIcon,
    chevronDoubleRightIcon: chevronDoubleRightIcon,
    xIcon: xIcon,
  };

  showSidebar: Observable<boolean>;
  sidebarType: string;

  // View Documents
  documentBase64: string;
  documentName: string;
  isDocument: boolean;
  documentBase64Done: boolean;
  documentNameDone: boolean;

  @Input() duration: number = SidePanelWidth.Quater;
  @Input() navWidth: number = window.innerWidth;
  @Input() direction: string = 'right';

  approvalLogsAssignedTo: ApprovalLogsAssignedTo[];
  approversGridData: ApprovalLogsAndApprovers[];

  //Stepper
  approvalLogs: VerdeApprovalLogs[];
  approvalLogGroupID: string;
  public current = 0;
  public steps = [];
  public logID: string;
  public logGroup: string;
  public logGroupID: string;
  public selectedTemplateName: string;

  selectedFleetItem: VehiclesFiltered = {};
  successorDetails: any = {};
  fleetBackUrl: string = '';

  showVerdeApprovalModal: boolean = false;
  approvalDefinitionName: string;

  showStepper = false;

  selectedStep: any;

  resignationTitle: UserUserDto;
  resignationEdit: any;

  showCancelModal: boolean = false;

  //Qualification Title
  qualificationTitle: string = 'Qualifications';
  selectedQualificationNavigationOption: string = '';

  //Booking title variables
  bookingAvailabilityResultsTitle: string = '';

  //Universal title variable
  universalTitleVar: string = '';
  universalTitleHeadingVar: string = '';

  constructor(
    private sidebarService: VerdeApprovalService,
    private sharedApiVerdeApprovalsService: SharedApiVerdeApprovalsService,
    private viewSDKClient: ViewSDKClient,
    public captureReviewService: CaptureReviewService,
    public capturePeerReviewService: CapturePeerReviewServiceService,
    private userService: UserService,
    private refreshService: RefreshService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.sidebarService
      .getTitleTag()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        if (Object.keys(item).length > 0) {
          this.universalTitleVar = item.toString();
        }
      });

    this.sidebarService
      .getTitleHeading()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        if (Object.keys(item).length > 0) {
          this.universalTitleHeadingVar = item.toString();
          console.log('this.universalTitleHeadingVar', this.universalTitleHeadingVar);
        }
      });

    this.sidebarService
      .getSidebarSize()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((size) => {
        this.navWidth = size;
      });

    this.sidebarService
      .getSelectedQualificationNavigation()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        if (Object.keys(item).length > 0) {
          this.selectedQualificationNavigationOption = item;
          //this.getCancellationTypes();
        }
      });

    if (this.selectedQualificationNavigationOption == 'viewUpdate') {
      this.qualificationTitle = 'Edit';
    } else if (this.selectedQualificationNavigationOption == 'viewAdd') {
      this.qualificationTitle = 'Add';
    }

    this.resignationTitle = this.userService.user;

    this.refreshService.onApiCallCompleted().subscribe(() => {
      this.sidebarService
        .getEmployeeResignationData()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((data) => {
          this.resignationEdit = data;
        });
    });

    this.showSidebar = this.sidebarService.getShowSidebar();
    this.sidebarService
      .getSidebarType()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((type) => {
        this.sidebarType = type;
        // debugger
        if (this.sidebarType === 'view-document') {
          this.isDocument = true;
          this.checkDocumentLoading();
        }
      });

    this.sidebarService
      .getDocumentBase64()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((base64) => {
        if (base64.length > 0) {
          this.documentBase64 = base64;

          this.documentBase64Done = true;
          this.checkDocumentLoading();
        }
      });

    this.sidebarService
      .getDocumentName()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((name) => {
        if (name.length > 0) {
          this.documentName = name;
          this.documentNameDone = true;
          this.checkDocumentLoading();
        }
      });

    this.sidebarService
      .getApprovalID()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((id) => {
        if (id?.length > 0) {
          this.getApprovalLogs(id);
        }
      });

    this.sidebarService
      .getSelectedTemplateName()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((templateName: any) => {
        this.selectedTemplateName = templateName;
      });

    this.sidebarService
      .getSuccessorDetails()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((payload) => {
        this.successorDetails = payload;
      });

    this.sidebarService
      .getSelectedFleetItem()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        this.selectedFleetItem = item;
      });

    this.sidebarService
      .getFleetBackUrl()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        this.fleetBackUrl = item;
      });

    const infoIcons = document.getElementsByClassName('info-icon');
    for (let i = 0; i < infoIcons.length; i++) {
      infoIcons[i].addEventListener('mouseover', function () {
        const tooltip = document.createElement('span');
        tooltip.innerHTML = this.getAttribute('title');
        tooltip.classList.add('info-tooltip');
        this.appendChild(tooltip);
      });
      infoIcons[i].addEventListener('mouseout', function () {
        this.removeChild(this.lastChild);
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  ngAfterViewInit(): void {}

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  checkDocumentLoading() {
    if (this.isDocument && this.documentBase64Done && this.documentNameDone) {
      setTimeout(() => {
        this.viewSDKClient.ready().then(() => {
          /* Invoke file preview */
          /* By default the embed mode will be Full Window */
          this.viewSDKClient.previewFile('pdf-div', {}, this.documentBase64, this.documentName);
        });
      }, 1000);
    }
  }

  onSidebarClose() {
    if (this.sidebarType !== 'fleet-odometer-update') {
      this.steps = [];
      this.sidebarService.setShowSidebar(false);
    } else {
      this.showCancelModal = true;
    }
  }

  getSidebarStyle(showNav: boolean) {
    let sidebarStyle: any = {};

    sidebarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
    sidebarStyle.width = this.navWidth + 'vw';
    sidebarStyle[this.direction] = (showNav ? 0 : this.navWidth * -1) + 'vw';

    return sidebarStyle;
  }

  getApprovalLogs(id: string) {
    this.showStepper = false;
    this.steps = [];
    this.sharedApiVerdeApprovalsService
      .getVerdeApprovalLogs({ logGroupID: id })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.approvalLogs = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.approvalLogGroupID = id;
          this.logGroup = this.approvalLogs[0].odataLogGroup;

          this.approvalLogs.forEach((element) => {
            this.steps.push({
              label: element.bt_ApprovalDefinition?.bt_name,
              status: element.odataApprovalStatus,
              sequence: element.bt_sequence,
              definition: element.bt_ApprovalDefinition?.bt_approvaldefinitionid,
              info: element.bt_approvaltype,
              disabled: false,
              logID: element.bt_verdeapprovallogsid,
              logGroupID: element._bt_loggroup_value,
              icon: 'user',
              approvedBy: element.odataApprover,
              approvalDate: element.bt_approvaldate,
            });
          });

          //if (this.steps.length === 1) {
          //  this.steps.push({
          //    label: "Completed",
          //    status: "",
          //    sequence: 2,
          //    definition: "",
          //    info: "Process Completed",
          //  })
          //}

          this.steps.sort((a, b) => a.sequence - b.sequence).map((obj) => obj.label);

          let index = this.steps.findIndex((status) => status.status === 'Submitted');

          this.current = index;
          this.selectedStep = this.steps[this.current];
          this.showStepper = true;

          this.getApprovalLogsandAssignedTo(this.steps[this.current].logID);
        },
      );
  }

  public activate(e: StepperActivateEvent): void {
    this.getApprovalLogsAssignedTo(e.step.logID);
    this.approvalDefinitionName = e.step.label;
    this.logID = e.step.logID;
    this.logGroupID = e.step.logGroupID;
    this.getApprovalLogsandAssignedTo(this.logID);
  }

  currentStepChange(event: any) {
    this.selectedStep = this.steps[event];
  }

  hideVerdeApprovalModal(): void {
    this.showVerdeApprovalModal = false;
    this.getApprovalLogs(this.approvalLogGroupID);
  }

  //get
  getApprovalLogsAssignedTo(id: string) {
    this.sharedApiVerdeApprovalsService
      .getApprovalLogsAssignedTo({ logID: id })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.approvalLogsAssignedTo = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }

  getApprovalLogsandAssignedTo(logID: string) {
    this.sharedApiVerdeApprovalsService
      .getApprovalLogsAndApprovers({ logID: logID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.approversGridData = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }

  // Cancel logic
  confirmCancel() {
    this.showCancelModal = false;
    this.router.navigateByUrl(this.fleetBackUrl);
    this.sidebarService.setShowSidebar(false);
  }

  setBookingResultsTitle(displayMessage: string) {
    this.bookingAvailabilityResultsTitle = displayMessage;
  }
}
