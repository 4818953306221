/* eslint-disable @typescript-eslint/no-empty-interface */
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GroupsActions, GroupsActionTypes } from '../actions/group.actions';
import * as fromProfileRelatedReducer from './profile-related.reducer';

// Models
import { TemplateTemplateGroup } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export interface GroupState extends EntityState<TemplateTemplateGroup> {}

export interface GroupRelatedState {
  groupState: GroupState;
  loading: boolean;
  error: any;
}

export const groupKeys = ['bt_peerreviewtemplategroupid'];
export const groupStateAdapter: EntityAdapter<TemplateTemplateGroup> = createEntityAdapter<TemplateTemplateGroup>({
  selectId: (data) => keyHandler(data, groupKeys),
});

export const initialGroupState: GroupState = groupStateAdapter.getInitialState({});

export const initialGroupRelatedState = {
  groupState: initialGroupState,
  loading: false,
  error: {},
};

export function groupRelatedReducer(state = initialGroupRelatedState, action: GroupsActions): GroupRelatedState {
  switch (action.type) {
    // Get Group
    case GroupsActionTypes.GROUPS_GET_ALL:
      return { ...state, loading: true };
    case GroupsActionTypes.GROUPS_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        groupState: groupStateAdapter.upsertMany(action.payload, state.groupState),
      };
    case GroupsActionTypes.GROUPS_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Upload Groups
    case GroupsActionTypes.GROUPS_UPLOAD_SUCCESS: {
      return {
        ...state,
      };
    }

    // Group Update
    case GroupsActionTypes.GROUPS_UPDATE_SUCCESS: {
      return {
        ...state,
      };
    }

    // Clear
    case GroupsActionTypes.GROUPS_ITEM_CLEAR:
      return { ...initialGroupRelatedState };
    default:
      return state;
  }
}

export const getProfileRelatedState = createFeatureSelector<fromProfileRelatedReducer.ProfileRelatedState>('profileRelatedReducer');

export const getGroupRelatedState = createSelector(getProfileRelatedState, (state: fromProfileRelatedReducer.ProfileRelatedState) => state.groupsState);

// Current Group

export const getGroupState = createSelector(getGroupRelatedState, (state) => state.groupState);
export const { selectAll: getAllGroup } = groupStateAdapter.getSelectors(getGroupState);

// Loading

export const isGroupLoading = createSelector(getGroupRelatedState, (state) => state.loading);

// Error

export const getGroupError = createSelector(getGroupRelatedState, (state) => state.error);
