/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { VaccineBooster } from '../models/vaccine-booster';
import { DvVaccines } from '../models/dv-vaccines';

@Injectable({
  providedIn: 'root',
})
export class SharedApiVaccinesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllVaccines
   */
  static readonly GetAllVaccinesPath = '/api/Vaccines/all/{legalEntityGuid}/{countryGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllVaccines()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVaccines$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
    countryGuid: string;
  }): Observable<StrictHttpResponse<Array<DvVaccines>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVaccinesService.GetAllVaccinesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
      rb.path('countryGuid', params.countryGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvVaccines>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllVaccines$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVaccines(params: { subscriptionKey?: string; APIURL?: string; legalEntityGuid: string; countryGuid: string }): Observable<Array<DvVaccines>> {
    return this.getAllVaccines$Response(params).pipe(map((r: StrictHttpResponse<Array<DvVaccines>>) => r.body as Array<DvVaccines>));
  }

  /**
   * Path part for operation getAllVaccineBoosters
   */
  static readonly GetAllVaccineBoostersPath = '/api/Vaccines/boosters/{legalEntityGuid}/{countryGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllVaccineBoosters()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVaccineBoosters$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
    countryGuid: string;
  }): Observable<StrictHttpResponse<Array<VaccineBooster>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVaccinesService.GetAllVaccineBoostersPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
      rb.path('countryGuid', params.countryGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VaccineBooster>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllVaccineBoosters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVaccineBoosters(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
    countryGuid: string;
  }): Observable<Array<VaccineBooster>> {
    return this.getAllVaccineBoosters$Response(params).pipe(map((r: StrictHttpResponse<Array<VaccineBooster>>) => r.body as Array<VaccineBooster>));
  }
}
