/* tslint:disable */
/* eslint-disable */
import { User } from './user';
import { DvTimeOffTypes } from './dv-time-off-types';
export interface TimeOffCalendarRequests {
  _bt_approver_value?: null | string;
  _bt_approverlegalstatus_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_linkedtransaction_value?: null | string;
  _bt_manager_value?: null | string;
  _bt_managerlegalentity_value?: null | string;
  _bt_timeofftype_lookup_value?: null | string;
  _bthr_employee_value?: null | string;
  _bthr_proxyid_value?: null | string;
  _bthr_timeoffcheck_value?: null | string;
  _bthr_timeofftype_value?: null | string;
  _bthr_txntype_value?: null | string;
  bt_calculationcomment?: null | string;
  bt_cancelled?: null | boolean;
  bt_hrapprovaldate?: null | string;
  bt_managerapprovaldate?: null | string;
  bt_managerapprovalstatus?: null | number;
  bthr_Employee?: User;
  bthr_TimeOffCheck?: DvTimeOffTypes;
  bthr_approvalcomment?: null | string;
  bthr_approvalid?: null | string;
  bthr_approvalname?: null | string;
  bthr_approvaltriggered?: null | boolean;
  bthr_attachmentname?: null | string;
  bthr_calculationstatus?: null | number;
  bthr_comments?: null | string;
  bthr_enddate?: null | string;
  bthr_fractionofday?: null | number;
  bthr_leavedays?: null | number;
  bthr_nonworkdays?: null | number;
  bthr_partdayrequestchoice?: null | number;
  bthr_proxy?: null | boolean;
  bthr_recallorrejected?: null | boolean;
  bthr_remainingbeforeaftertimeoff?: null | number;
  bthr_remainingbeforetimeoff?: null | number;
  bthr_requestdate?: null | string;
  bthr_requestno?: null | string;
  bthr_requestsstatus?: null | number;
  bthr_requeststatuscomment?: null | string;
  bthr_startdate?: null | string;
  bthr_submitforapproval?: null | boolean;
  bthr_supportingnote?: null | string;
  bthr_supportingnote_name?: null | string;
  bthr_supportingnoterequired?: null | boolean;
  bthr_timeoffrequestid?: null | string;
  odataApprover?: null | string;
  odataApproverLegalEntity?: null | string;
  odataCalculationStatus?: null | string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataManager?: null | string;
  odataManagerApprovalStatus?: null | string;
  odataManagerLegalEntity?: null | string;
  odataPartDayRequest?: null | string;
  odataRequestStatus?: null | string;
  odataTimeOffCheck?: null | string;
  odataTimeOffType?: null | string;
  odataTxnType?: null | string;
  statecode?: null | number;
}
