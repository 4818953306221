/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BFFBaseService } from '../bff-base-service';
import { BFFApiConfiguration } from '../bff-api-configuration';
import { BFFStrictHttpResponse } from '../bff-strict-http-response';
import { BFFRequestBuilder } from '../bff-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BFFVersionDto } from '../models/bff-version-dto';

@Injectable({
  providedIn: 'root',
})
export class BFFApiVersionService extends BFFBaseService {
  constructor(config: BFFApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation versionControllerGetDeployedVersion
   */
  static readonly VersionControllerGetDeployedVersionPath = '/api/Version';

  /**
   * Get Deployed Version.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versionControllerGetDeployedVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerGetDeployedVersion$Response(params?: {}): Observable<BFFStrictHttpResponse<BFFVersionDto>> {
    const rb = new BFFRequestBuilder(this.rootUrl, BFFApiVersionService.VersionControllerGetDeployedVersionPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as BFFStrictHttpResponse<BFFVersionDto>;
        }),
      );
  }

  /**
   * Get Deployed Version.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `versionControllerGetDeployedVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerGetDeployedVersion(params?: {}): Observable<BFFVersionDto> {
    return this.versionControllerGetDeployedVersion$Response(params).pipe(map((r: BFFStrictHttpResponse<BFFVersionDto>) => r.body as BFFVersionDto));
  }

  /**
   * Path part for operation versionControllerSse
   */
  static readonly VersionControllerSsePath = '/api/Version/Event';

  /**
   * Subscribe to Version Events.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versionControllerSse()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerSse$Response(params?: {}): Observable<BFFStrictHttpResponse<void>> {
    const rb = new BFFRequestBuilder(this.rootUrl, BFFApiVersionService.VersionControllerSsePath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as BFFStrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Subscribe to Version Events.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `versionControllerSse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerSse(params?: {}): Observable<void> {
    return this.versionControllerSse$Response(params).pipe(map((r: BFFStrictHttpResponse<void>) => r.body as void));
  }
}
