/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApprovalDataInputs } from '../models/approval-data-inputs';
import { ApprovalLogsAndApprovers } from '../models/approval-logs-and-approvers';
import { ApprovalLogsAssignedTo } from '../models/approval-logs-assigned-to';
import { VerdeApprovalLogGroups } from '../models/verde-approval-log-groups';
import { VerdeApprovalLogs } from '../models/verde-approval-logs';
import { VerdeApprovalsDelegations } from '../models/verde-approvals-delegations';

@Injectable({
  providedIn: 'root',
})
export class SharedApiVerdeApprovalsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getVerdeApprovalDelegations
   */
  static readonly GetVerdeApprovalDelegationsPath = '/api/VerdeApprovals/verdeapprovaldelegations/{employeeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeApprovalDelegations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeApprovalDelegations$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeID: string;
  }): Observable<StrictHttpResponse<Array<VerdeApprovalsDelegations>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeApprovalsService.GetVerdeApprovalDelegationsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeID', params.employeeID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdeApprovalsDelegations>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeApprovalDelegations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeApprovalDelegations(params: { subscriptionKey?: string; APIURL?: string; employeeID: string }): Observable<Array<VerdeApprovalsDelegations>> {
    return this.getVerdeApprovalDelegations$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeApprovalsDelegations>>) => r.body as Array<VerdeApprovalsDelegations>),
    );
  }

  /**
   * Path part for operation getVerdeApprovalLogs
   */
  static readonly GetVerdeApprovalLogsPath = '/api/VerdeApprovals/bt_verdeapprovallog/{logGroupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeApprovalLogs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeApprovalLogs$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    logGroupID: string;
  }): Observable<StrictHttpResponse<Array<VerdeApprovalLogs>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeApprovalsService.GetVerdeApprovalLogsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('logGroupID', params.logGroupID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdeApprovalLogs>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeApprovalLogs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeApprovalLogs(params: { subscriptionKey?: string; APIURL?: string; logGroupID: string }): Observable<Array<VerdeApprovalLogs>> {
    return this.getVerdeApprovalLogs$Response(params).pipe(map((r: StrictHttpResponse<Array<VerdeApprovalLogs>>) => r.body as Array<VerdeApprovalLogs>));
  }

  /**
   * Path part for operation getVerdeApprovalLogsById
   */
  static readonly GetVerdeApprovalLogsByIdPath = '/api/VerdeApprovals/bt_verdeapprovallogsbyid/{logID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeApprovalLogsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeApprovalLogsById$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    logID: string;
  }): Observable<StrictHttpResponse<Array<VerdeApprovalLogs>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeApprovalsService.GetVerdeApprovalLogsByIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('logID', params.logID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdeApprovalLogs>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeApprovalLogsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeApprovalLogsById(params: { subscriptionKey?: string; APIURL?: string; logID: string }): Observable<Array<VerdeApprovalLogs>> {
    return this.getVerdeApprovalLogsById$Response(params).pipe(map((r: StrictHttpResponse<Array<VerdeApprovalLogs>>) => r.body as Array<VerdeApprovalLogs>));
  }

  /**
   * Path part for operation getApprovalLogsAssignedTo
   */
  static readonly GetApprovalLogsAssignedToPath = '/api/VerdeApprovals/approvallogsassignedto/{logID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApprovalLogsAssignedTo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalLogsAssignedTo$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    logID: string;
  }): Observable<StrictHttpResponse<Array<ApprovalLogsAssignedTo>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeApprovalsService.GetApprovalLogsAssignedToPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('logID', params.logID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ApprovalLogsAssignedTo>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApprovalLogsAssignedTo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalLogsAssignedTo(params: { subscriptionKey?: string; APIURL?: string; logID: string }): Observable<Array<ApprovalLogsAssignedTo>> {
    return this.getApprovalLogsAssignedTo$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ApprovalLogsAssignedTo>>) => r.body as Array<ApprovalLogsAssignedTo>),
    );
  }

  /**
   * Path part for operation getApprovalLogsAndApprovers
   */
  static readonly GetApprovalLogsAndApproversPath = '/api/VerdeApprovals/getApprovalLogsAndApprovers/{logID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApprovalLogsAndApprovers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalLogsAndApprovers$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    logID: string;
  }): Observable<StrictHttpResponse<Array<ApprovalLogsAndApprovers>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeApprovalsService.GetApprovalLogsAndApproversPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('logID', params.logID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ApprovalLogsAndApprovers>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getApprovalLogsAndApprovers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApprovalLogsAndApprovers(params: { subscriptionKey?: string; APIURL?: string; logID: string }): Observable<Array<ApprovalLogsAndApprovers>> {
    return this.getApprovalLogsAndApprovers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ApprovalLogsAndApprovers>>) => r.body as Array<ApprovalLogsAndApprovers>),
    );
  }

  /**
   * Path part for operation getVerdeApprovalLogGroupsById
   */
  static readonly GetVerdeApprovalLogGroupsByIdPath = '/api/VerdeApprovals/approvalloggroupsbyid/{logGroupID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeApprovalLogGroupsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeApprovalLogGroupsById$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    logGroupID: string;
  }): Observable<StrictHttpResponse<Array<VerdeApprovalLogGroups>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeApprovalsService.GetVerdeApprovalLogGroupsByIdPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('logGroupID', params.logGroupID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdeApprovalLogGroups>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeApprovalLogGroupsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeApprovalLogGroupsById(params: { subscriptionKey?: string; APIURL?: string; logGroupID: string }): Observable<Array<VerdeApprovalLogGroups>> {
    return this.getVerdeApprovalLogGroupsById$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeApprovalLogGroups>>) => r.body as Array<VerdeApprovalLogGroups>),
    );
  }

  /**
   * Path part for operation getVerdeApprovalLogGroupsByIdAndTask
   */
  static readonly GetVerdeApprovalLogGroupsByIdAndTaskPath = '/api/VerdeApprovals/approvalloggroupsbyidandtask/{logGroupID}/{taskID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdeApprovalLogGroupsByIdAndTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeApprovalLogGroupsByIdAndTask$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    logGroupID: string;
    taskID: string;
  }): Observable<StrictHttpResponse<Array<VerdeApprovalLogGroups>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeApprovalsService.GetVerdeApprovalLogGroupsByIdAndTaskPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('logGroupID', params.logGroupID, {});
      rb.path('taskID', params.taskID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdeApprovalLogGroups>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdeApprovalLogGroupsByIdAndTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdeApprovalLogGroupsByIdAndTask(params: {
    subscriptionKey?: string;
    APIURL?: string;
    logGroupID: string;
    taskID: string;
  }): Observable<Array<VerdeApprovalLogGroups>> {
    return this.getVerdeApprovalLogGroupsByIdAndTask$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdeApprovalLogGroups>>) => r.body as Array<VerdeApprovalLogGroups>),
    );
  }

  /**
   * Path part for operation patchVerdeApprovalDelegations
   */
  static readonly PatchVerdeApprovalDelegationsPath = '/api/VerdeApprovals/patch/verdeapprovaldelegations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVerdeApprovalDelegations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeApprovalDelegations$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: Array<VerdeApprovalsDelegations>;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeApprovalsService.PatchVerdeApprovalDelegationsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVerdeApprovalDelegations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeApprovalDelegations(params?: { subscriptionKey?: string; APIURL?: string; body?: Array<VerdeApprovalsDelegations> }): Observable<boolean> {
    return this.patchVerdeApprovalDelegations$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchVerdeApprovalsLogs
   */
  static readonly PatchVerdeApprovalsLogsPath = '/api/VerdeApprovals/patch/verdeapprovallogs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVerdeApprovalsLogs()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeApprovalsLogs$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeApprovalLogs }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeApprovalsService.PatchVerdeApprovalsLogsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVerdeApprovalsLogs$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeApprovalsLogs(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeApprovalLogs }): Observable<boolean> {
    return this.patchVerdeApprovalsLogs$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postVerdeApprovalDelegations
   */
  static readonly PostVerdeApprovalDelegationsPath = '/api/VerdeApprovals/post/verdeapprovaldelegations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVerdeApprovalDelegations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeApprovalDelegations$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: Array<VerdeApprovalsDelegations>;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeApprovalsService.PostVerdeApprovalDelegationsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVerdeApprovalDelegations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeApprovalDelegations(params?: { subscriptionKey?: string; APIURL?: string; body?: Array<VerdeApprovalsDelegations> }): Observable<boolean> {
    return this.postVerdeApprovalDelegations$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postToApprovalFunc
   */
  static readonly PostToApprovalFuncPath = '/api/VerdeApprovals/post/PostToApprovalFunc/{environmentURL}/{slot}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postToApprovalFunc()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postToApprovalFunc$Response(params: { environmentURL: string; slot: string; body?: ApprovalDataInputs }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdeApprovalsService.PostToApprovalFuncPath, 'post');
    if (params) {
      rb.path('environmentURL', params.environmentURL, {});
      rb.path('slot', params.slot, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postToApprovalFunc$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postToApprovalFunc(params: { environmentURL: string; slot: string; body?: ApprovalDataInputs }): Observable<boolean> {
    return this.postToApprovalFunc$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
