/* tslint:disable */
/* eslint-disable */
import { BtStructureEmployee } from './bt-structure-employee';
import { BtPositionseat } from './bt-positionseat';
export interface StructurePositionSeats {
  _bthr_position_value?: null | string;
  _cr1f2_department_value?: null | string;
  _cr1f2_division_value?: null | string;
  _cr1f2_legalentity_value?: null | string;
  btHaschildrenODataCommunityDisplayV1FormattedValue?: null | string;
  bt_haschildren?: boolean;
  bt_seatstatus?: BtPositionseat;
  bthr_Employee?: BtStructureEmployee;
  bthr_description?: null | string;
  bthr_positionseatid?: null | string;
  bthr_seatname?: null | string;
  cr1f2DepartmentValueODataCommunityDisplayV1FormattedValue?: null | string;
  cr1f2DivisionValueODataCommunityDisplayV1FormattedValue?: null | string;
  cr1f2LegalentityValueODataCommunityDisplayV1FormattedValue?: null | string;
  odataPosition?: null | string;
  odataSeatStatus?: null | string;
}
