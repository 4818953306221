import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataBindingDirective, RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { GroupDescriptor } from '@progress/kendo-data-query';
import { DocumentApiDocumentService, DocumentVerdeBulkImport, UserUserDto } from '@verde/api';
import { AuthenticationService, DeviceTypeService, UserService } from '@verde/core';
import { DotsMenuItem, ManualDocumentsDataService, MfAuthService, ModalService } from '@verde/shared';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'verde-bulk-docs',
  templateUrl: './bulk-docs.component.html',
  styleUrls: ['./bulk-docs.component.scss'],
})
export class BulkDocsComponent implements OnInit, OnDestroy {
  @Input() GroupingType: string;

  private onDestroy$ = new Subject<boolean>();
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  dotMenuItems: DotsMenuItem[] = [];
  skeletonSelectedCallback = (args: { dataItem: any }) => args.dataItem;
  skeletonSelection: any[] = [];
  DocumentGridData: any[] = [];
  disableAnimation: boolean = false;
  bulkDocsLoading: boolean = true;
  selectedItemNew: any;
  DocumentsGroups: GroupDescriptor[] = [{ field: '' }];
  financialDocumentsGroups: GroupDescriptor[] = [{ field: 'bt_documenttype.bt_doctypefinancial' }];

  public selectableSettings: SelectableSettings = {
    enabled: true,
    checkboxOnly: true,
  };
  constructor(
    public userService: UserService,
    public deviceTypeService: DeviceTypeService,
    private documentApiDocumentService: DocumentApiDocumentService,
    private modalService: ModalService,
    private mfAuthService: MfAuthService,
    private authenticationService: AuthenticationService,
    private manualDocumentsDataService: ManualDocumentsDataService,
    @Inject('environment') private environment: any,
  ) {
    this.manualDocumentsDataService.getUserUpdates().subscribe((user: UserUserDto) => {
      this.GetDataNew();
    });
  }

  ngOnInit(): void {
    this.GetDataNew();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  DocumentsGroupChange(group: GroupDescriptor[]): void {
    this.DocumentsGroups = group;
  }

  financialDocumentsGroupChange(group: GroupDescriptor[]): void {
    this.financialDocumentsGroups = group;
  }

  refresh() {
    this.GetDataNew();
  }

  GetDataNew() {
    this.documentApiDocumentService
      .getBulkUploadDocs({
        body: { empId: this.userService.proxyUser.employeeId, grouping: this.GroupingType },
      })
      .pipe(take(1))
      .subscribe(
        (ret1: DocumentVerdeBulkImport[]) => {
          ret1.forEach((element) => {
            let filename: string = element.bt_filename;
            element.bt_filename = filename.substring(36);
          });
          this.DocumentGridData = ret1;
          this.bulkDocsLoading = false;
        },
        (error) => {
          this.bulkDocsLoading = false;
          console.error(error);
        },
        () => {
          this.bulkDocsLoading = false;
        },
      );
  }

  menuClicked(parentItem: any) {
    this.selectedItemNew = parentItem;
    this.dotMenuItems = [];

    let tempItems: DotsMenuItem[] = [];

    tempItems.push({ id: 'download', text: 'Download' });

    this.dotMenuItems = [...tempItems];
  }

  menuItemClicked(menuItem: DotsMenuItem) {
    if (menuItem.id === 'download') {
      if (!this.authenticationService.authState$.getValue().isTeamsAuthed) {
        this.mfAuthService.microsoftSignIn();
        this.mfAuthService
          .getSignInSuccess()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((returnObject) => {
            if (returnObject.success) {
              this.documentApiDocumentService
                .downloadFile({
                  body: { recordID: this.selectedItemNew.recordID, columnName: 'bt_documentattachment', entityName: 'bt_verdebulkimport' },
                })
                .pipe(take(1))
                .subscribe(
                  (ret1) => {
                    const parts = this.selectedItemNew.bt_filename.split('.');
                    const extension = parts[1];

                    const byteCharacters = atob(ret1);
                    const byteArrays = [];

                    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                      const slice = byteCharacters.slice(offset, offset + 512);

                      const byteNumbers = new Array(slice.length);
                      for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                      }

                      const byteArray = new Uint8Array(byteNumbers);
                      byteArrays.push(byteArray);
                    }

                    const blobType = extension === 'pdf' ? 'application/pdf' : `image/${extension}`;
                    const blob = new Blob(byteArrays, { type: blobType }); // Adjust type accordingly

                    // Create download link
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = this.selectedItemNew.bt_filename; // Adjust the filename
                    link.click();
                  },
                  (error) => {
                    console.error(error);
                  },
                  () => {},
                );
            }
          });
        this.mfAuthService
          .getSignInError()
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((returnObject) => {
            if (returnObject.fail) {
            }
          });
      } else {
        this.modalService.open('navToWebApp');
      }
    }
  }

  rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  goToWeb() {
    if (this.environment.slot === 'dev') {
      window.open('https://devapp.verde.work/document', '_blank');
    } else if (this.environment.slot === 'uat') {
      window.open('https://uatapp.verde.work/document', '_blank');
    } else if (this.environment.slot === 'vox-uat') {
      window.open('https://devapp.verde.work/document', '_blank');
    } else if (this.environment.slot === 'prod') {
      window.open('https://app.verde.work/document', '_blank');
    }
    this.modalService.close('navToWebApp');
  }
}
