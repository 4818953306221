import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControlDirective } from '@angular/forms';
import { FleetLicenseRenewal, SharedApiFileService, SharedApiVehiclesService } from '@verde/api';
import { UserService } from '@verde/core';
import { FleetAccessService } from 'apps/verde/src/app/features/fleet/services/fleet-access/fleet-access.service';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ModalService } from '../../modal.service';

@Component({
  selector: 'verde-fleet-license-modal',
  templateUrl: './fleet-license-modal.component.html',
  styleUrls: ['./fleet-license-modal.component.scss'],
})
export class FleetLicenseModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  @Output() hideModalEvent = new EventEmitter<boolean>();
  @Output() transactionSuccessful = new EventEmitter<boolean>();

  disableAnimation: boolean = false;

  //Bodies
  postLicenseBody: FleetLicenseRenewal = {};

  confirmationMessage: string = '';

  maxFileSize: number = 5242880;
  licenseFile: File = {} as File;

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    public userService: UserService,
    private fleetAccess: FleetAccessService,
    private sharedApiVehiclesService: SharedApiVehiclesService,
    private sharedApiFileService: SharedApiFileService,
  ) {}

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
  }

  ngAfterViewInit(): void {
    this.modalService.open('fleetLicenseModal');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  hideModal() {
    this.hideModalEvent.emit(false);
  }

  onConfirmClick(): void {
    this.transactionSuccessful.emit(true);
    this.hideModalEvent.emit(false);
  }

  postFleetLicense() {
    this.spinner.show('confirmfleetlicense');
    this.modalService.open('confirmFleetLicenseModal');

    this.postLicenseBody.odataUploadEmployee = '/bthr_employees(' + this.userService.user.employeeId + ')';
    this.postLicenseBody.odataUploadLegalEntity = '/bthr_legalentitieses(' + this.userService.user.legalEntityId + ')';
    this.postLicenseBody.bt_processstatus = 'Submitted';

    this.postLicenseBody.bt_name = this.userService.user.employeeId + '_License Renewal_' + moment().format('YYYY-MM-DD, h:mm:ss a');
    const recordName = this.postLicenseBody.bt_name;

    this.sharedApiVehiclesService
      .postFleetLicenseRenewal({ body: this.postLicenseBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          let allFleetLicenses: Array<FleetLicenseRenewal> = new Array<FleetLicenseRenewal>();

          this.sharedApiVehiclesService
            .getFleetLicensesByEntity({ legalEntityGuid: this.fleetAccess.selectedFleetAccess._bt_locationlegalentity_value! })
            .pipe(take(1))
            .subscribe(
              (ret) => {
                allFleetLicenses = ret;
              },
              (error) => {
                console.error(error);
              },
              () => {
                this.postLicenseBody = {};

                let theLicense: FleetLicenseRenewal = {};

                allFleetLicenses.forEach((t: FleetLicenseRenewal) => {
                  if (t.bt_name === recordName) {
                    theLicense = t;
                  }
                });

                if (Object.keys(theLicense).length > 0) {
                  if (this.licenseFile != null) {
                    this.sharedApiFileService
                      .patchLicenseRenewalFile({ recordID: theLicense.bt_fleetlicenserenewalid!, body: { file: this.licenseFile } })
                      .pipe(take(1))
                      .subscribe(
                        (ret) => {
                          console.log(ret);
                        },
                        (error) => {
                          console.error(error);
                        },
                        () => {
                          this.confirmationMessage = 'You have successfully added a fleet item license!';
                          this.spinner.hide('confirmfleetlicense');

                          const updateBody: FleetLicenseRenewal = {
                            bt_processdocument: true,
                            bt_fleetlicenserenewalid: theLicense.bt_fleetlicenserenewalid,
                          };

                          this.sharedApiVehiclesService
                            .patchFleetLicenseRenewal({ body: updateBody })
                            .pipe(take(1))
                            .subscribe(
                              (ret) => {
                                console.log(ret);
                              },
                              (error) => {
                                console.error(error);
                              },
                            );
                        },
                      );
                  }
                }
              },
            );
        },
      );
  }

  licenseFileSelected(event: any, element: FormControlDirective) {
    if (event) {
      if (event[0].size < this.maxFileSize) {
        const splitName = event[0].name.split('.');
        const extension = splitName[splitName.length - 1];
        this.licenseFile = new File([event[0]], this.userService.user.employeeId + '_License' + '.' + extension);
        this.postLicenseBody.bt_proofofrenewalname = this.licenseFile.name;
      } else {
        alert('File: ' + event[0].name + 'is too large to upload.');
        element.reset();
      }
    }
  }
}
