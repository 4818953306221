/* tslint:disable */
/* eslint-disable */
export interface EquipmentTakeOnType {
  _bt_legalentity_value?: null | string;
  _bt_takeongroup_value?: null | string;
  bt_allowqty?: null | boolean;
  bt_equipmenttakeontype?: null | string;
  bt_equipmenttakeontypeid?: null | string;
  odataLegalEntity?: null | string;
  odataTakeOnGroup?: null | string;
}
