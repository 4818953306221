import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { DocumentApiDocumentService, DocumentBtImportProcessStatus, DocumentBtPayrollmportDetail, DocumentImportPayrollHeader, ImportHeader } from '@verde/api';
import { DeviceTypeService, UserService } from '@verde/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../services/verde-approval.service';

@Component({
  selector: 'verde-payroll-upload',
  templateUrl: './payroll-upload.component.html',
  styleUrls: ['./payroll-upload.component.scss'],
})
export class PayrollUploadComponent implements OnInit {
  onDestroy$: Subject<any> = new Subject();

  // Models
  selectedItem: DocumentImportPayrollHeader;
  importDetailToPost: DocumentBtPayrollmportDetail;
  importDetailToPatch: DocumentBtPayrollmportDetail;

  // File-related
  file1: File[] | null = null;
  file2: File[] | null = null;
  myRestrictions: FileRestrictions = {
    allowedExtensions: ['.csv'],
    //Add the txt in for testing.
    //allowedExtensions: ['.csv', '.txt'],
  };

  // Flags and Messages
  incorrectFlag: boolean = false;
  resultMessage: string = '';
  showConfirmationModal: boolean = false;

  // View and Animation
  @ViewChild('UploadPayslipData', { static: false }) form: NgForm;
  disableAnimation: boolean = true;

  // Other properties
  headerContentURL: string;
  payrollImportGuid: string;

  constructor(
    private sidebarService: VerdeApprovalService,
    public deviceTypeService: DeviceTypeService,
    private userService: UserService,
    private documentApiDocumentService: DocumentApiDocumentService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
    this.sidebarService
      .getImportHeader()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((importHeader: ImportHeader) => {
        this.selectedItem = importHeader;
      });
  }

  transform(value: string): string {
    if (!value) {
      return ''; // Handle empty input
    }

    const words = value.toLowerCase().split(' ');

    words.forEach((word, index) => {
      words[index] = word.charAt(0).toUpperCase() + word.slice(1);
    });

    return words.join(' ');
  }

  onFileChange(event: any, fileNumber: string) {
    this.incorrectFlag = false;
    if (event.length > 0) {
      if ((fileNumber === 'file1' && event[0].name.toLowerCase().includes('header')) || event[0].name.toLowerCase().includes('mark')) {
      } else if (fileNumber === 'file1' && !event[0].name.toLowerCase().includes('header')) {
        this.file1 = [];
        this.incorrectFlag = true;
      }
      if ((fileNumber === 'file2' && event[0].name.toLowerCase().includes('detail')) || event[0].name.toLowerCase().includes('mark')) {
      } else if (fileNumber === 'file2' && !event[0].name.toLowerCase().includes('detail')) {
        this.file2 = [];
        this.incorrectFlag = true;
      }
    }
  }

  uploadFiles(UploadPayslipData: NgForm) {
    this.resultMessage = '';

    if (this.file1.length === 0 || this.file2.length === 0) {
      return; // Exit early if files are missing
    }

    this.spinner.show('uploadpayroll');

    // Set import details
    this.importDetailToPost = {
      bt_comment: 'Started processing...',
      bt_importstatus: DocumentBtImportProcessStatus.Import,
      bt_overwrite: true,
      bt_PayrollImportHeaderNo: this.selectedItem.recordID!,
      bt_ImportByEntity: this.userService.user?.legalEntityId!,
      bt_ImportedBy: this.userService.user?.employeeId!,
    };

    // Post Payroll Import Detail
    this.documentApiDocumentService
      .postBtPayrollmportDetail({ body: { btPayrollmportDetail: this.importDetailToPost } })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.payrollImportGuid = ret;
          this.uploadFilesInternal(UploadPayslipData, this.payrollImportGuid);
        },
        (error) => console.error(error),
      );
  }

  private uploadFilesInternal(UploadPayslipData: NgForm, payrollImportGuid: string) {
    const files: File[] = [this.file1[0], this.file2[0]];

    // Upload files
    this.documentApiDocumentService
      .uploadFiles({
        body: {
          Files: files,
          Overwrite: this.selectedItem.overwrite,
          Period: this.selectedItem.period?.toString(),
          TaxYear: this.selectedItem.taxYear?.toString(),
          LegalEntity: this.selectedItem.bt_entitymapping?.toString(),
          PeriodYear: this.selectedItem.periodYear?.toString(),
          PayrollImportDetailID: payrollImportGuid,
        },
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          if (ret.toLowerCase().includes('accepted')) {
            this.handleUploadResponse(ret, UploadPayslipData);
          } else {
            this.handleErrorUploadResponse(ret, UploadPayslipData);
          }
        },
        (error) => {
          this.handleErrorUploadResponse(error, UploadPayslipData);
        },
      );
  }

  private handleUploadResponse(ret: string, UploadPayslipData: NgForm) {
    this.resultMessage = ret.toLowerCase().includes('accepted') ? 'Import successful!' : 'Import unsuccessful. Please contact support.';

    // Set import details for patching
    this.importDetailToPatch = {
      bt_comment: this.resultMessage,
      bt_importstatus: DocumentBtImportProcessStatus.Ready,
      bt_PayrollmportDetailID: this.payrollImportGuid,
      bt_numberofdetailrecords: Number(this.resultMessage.split('/')[1]),
    };

    // Patch Payroll Import Detail
    this.documentApiDocumentService
      .postBtPayrollmportDetail({ body: { btPayrollmportDetail: this.importDetailToPatch } })
      .pipe(take(1))
      .subscribe(
        () => this.finalizeUpload(UploadPayslipData),
        (error) => console.error(error),
      );
  }

  private handleErrorUploadResponse(ret: any, UploadPayslipData: NgForm) {
    let resultMessageToPost = ret;

    if (typeof ret === 'object' && ret !== null) {
      resultMessageToPost = JSON.stringify(ret);
      resultMessageToPost = resultMessageToPost.replace(/[{}\.]/g, '');
    } else {
      resultMessageToPost = ret.replace(/[{}\.]/g, '');
    }

    this.resultMessage = 'Import unsuccessful. Please check message on the record and contact support.';

    // Set import details for patching
    this.importDetailToPatch = {
      bt_comment: resultMessageToPost,
      bt_importstatus: DocumentBtImportProcessStatus.Error,
      bt_PayrollmportDetailID: this.payrollImportGuid,
    };

    console.error(resultMessageToPost);

    // Patch Payroll Import Detail
    this.documentApiDocumentService
      .postBtPayrollmportDetail({ body: { btPayrollmportDetail: this.importDetailToPatch } })
      .pipe(take(1))
      .subscribe(
        () => this.finalizeUpload(UploadPayslipData),
        (error) => {
          this.finalizeUpload(UploadPayslipData);
        },
      );
  }

  private finalizeUpload(UploadPayslipData: NgForm) {
    // Reset form and clear files
    UploadPayslipData.resetForm();
    this.file1 = [];
    this.file2 = [];

    this.spinner.hide('uploadpayroll');
    this.showConfirmationModal = true;
  }

  closeConfirmationModal() {
    this.showConfirmationModal = false;
    this.sidebarService.setShowSidebar(false);
  }
}
