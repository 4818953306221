/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PolicyListAcceptance } from '../models/policy-list-acceptance';
import { VerdePolicy } from '../models/verde-policy';
import { VerdePolicyAcceptance } from '../models/verde-policy-acceptance';

@Injectable({
  providedIn: 'root',
})
export class SharedApiVerdePoliciesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getVerdePolicies
   */
  static readonly GetVerdePoliciesPath = '/api/VerdePolicies/verdepolicies/{LegalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdePolicies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdePolicies$Response(params: { subscriptionKey?: string; APIURL?: string; LegalEntityID: string }): Observable<StrictHttpResponse<Array<VerdePolicy>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdePoliciesService.GetVerdePoliciesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('LegalEntityID', params.LegalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdePolicy>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdePolicies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdePolicies(params: { subscriptionKey?: string; APIURL?: string; LegalEntityID: string }): Observable<Array<VerdePolicy>> {
    return this.getVerdePolicies$Response(params).pipe(map((r: StrictHttpResponse<Array<VerdePolicy>>) => r.body as Array<VerdePolicy>));
  }

  /**
   * Path part for operation patchPolicyAcceptance
   */
  static readonly PatchPolicyAcceptancePath = '/api/VerdePolicies/patch/policyAcceptance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPolicyAcceptance()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPolicyAcceptance$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: VerdePolicyAcceptance;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdePoliciesService.PatchPolicyAcceptancePath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchPolicyAcceptance$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPolicyAcceptance(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdePolicyAcceptance }): Observable<boolean> {
    return this.patchPolicyAcceptance$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getEmployeePoliciesAcceptance
   */
  static readonly GetEmployeePoliciesAcceptancePath = '/api/VerdePolicies/employeepolicyacceptance/{LegalEntityID}/{EmployeeID}/{PolicyID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeePoliciesAcceptance()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeePoliciesAcceptance$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntityID: string;
    EmployeeID: string;
    PolicyID: string;
  }): Observable<StrictHttpResponse<Array<VerdePolicyAcceptance>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdePoliciesService.GetEmployeePoliciesAcceptancePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('LegalEntityID', params.LegalEntityID, {});
      rb.path('EmployeeID', params.EmployeeID, {});
      rb.path('PolicyID', params.PolicyID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdePolicyAcceptance>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeePoliciesAcceptance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmployeePoliciesAcceptance(params: {
    subscriptionKey?: string;
    APIURL?: string;
    LegalEntityID: string;
    EmployeeID: string;
    PolicyID: string;
  }): Observable<Array<VerdePolicyAcceptance>> {
    return this.getEmployeePoliciesAcceptance$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VerdePolicyAcceptance>>) => r.body as Array<VerdePolicyAcceptance>),
    );
  }

  /**
   * Path part for operation getPolicyListAcceptance
   */
  static readonly GetPolicyListAcceptancePath = '/api/VerdePolicies/verdepolicyacceptance/{EmployeeID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPolicyListAcceptance()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPolicyListAcceptance$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    EmployeeID: string;
  }): Observable<StrictHttpResponse<Array<PolicyListAcceptance>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVerdePoliciesService.GetPolicyListAcceptancePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('EmployeeID', params.EmployeeID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PolicyListAcceptance>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPolicyListAcceptance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPolicyListAcceptance(params: { subscriptionKey?: string; APIURL?: string; EmployeeID: string }): Observable<Array<PolicyListAcceptance>> {
    return this.getPolicyListAcceptance$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PolicyListAcceptance>>) => r.body as Array<PolicyListAcceptance>),
    );
  }
}
