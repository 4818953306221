/* tslint:disable */
/* eslint-disable */
export interface BookingsPostBookingsCommand {
  bt_Departmental?: null | boolean;
  bt_bookingno?: null | string;
  bt_bookingreason?: null | string;
  bt_fromdate?: null | string;
  bt_todate?: null | string;
  bt_verdebookingsid?: null | string;
  odataBookingItem?: null | string;
  odataBookingType?: null | string;
  odataDuration?: null | string;
  odataEmployee?: null | string;
  odataOffice?: null | string;
  odataPostBookingItem?: null | string;
  odataPostBookingType?: null | string;
  odataPostDepartment?: null | string;
  odataPostDuration?: null | string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostOffice?: null | string;
  odataPostPrimaryLocation?: null | string;
  odataPostSubOffice?: null | string;
  odataPrimaryLocation?: null | string;
  odataSubOffice?: null | string;
  stateCode?: null | number;
}
