import { Injectable } from '@angular/core';
import { PeerReviewFeedback, PerformanceReviewApiPerformanceReviewService, SharedApiPeerReviewService, SharedApiPerformanceReviewService } from '@verde/api';
import { UserPeerReviewProcess, UserReviewProcess } from '@verde/shared';
import cloneDeep from 'lodash.clonedeep';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { StoreEnhancedReviewDetailService } from './enhanced-review-detail';
import { StorePerformanceReviewService } from './store-performance-review';

interface FeedbackPatch {
  bt_feedbackcompleted: boolean;
  bt_peerreviewfeedbackid: string;
  bt_rating: number;
  bt_response: string;
}

@Injectable({
  providedIn: 'root',
})
export class PerformanceReviewService {
  private _currentPeerReviewQuestions: any[] = [];
  private _currentPerformanceReviewQuestions: any[] = [];

  constructor(
    private sharedApiPeerReviewService: SharedApiPeerReviewService,
    private sharedApiPerformanceReviewService: SharedApiPerformanceReviewService,
    private performanceReviewApiPerformanceReviewService: PerformanceReviewApiPerformanceReviewService,
    private storePerformanceReviewService: StorePerformanceReviewService,
    private storeEnhancedReviewDetailService: StoreEnhancedReviewDetailService,
  ) {}

  set currentPeerReviewQuestions(val: PeerReviewFeedback[]) {
    this._currentPeerReviewQuestions = cloneDeep(val);
  }

  set currentPerformanceReviewQuestions(val: any[]) {
    this._currentPerformanceReviewQuestions = cloneDeep(val);
  }

  updatePeerReview(reviewProcess: UserPeerReviewProcess, updateSummaries = true, updateQuestions = true): Observable<any> {
    return combineLatest([
      updateSummaries ? this.updatePeerReviewSummaries(reviewProcess) : of(EMPTY),
      updateQuestions ? this.updatePeerReviewFeedback(reviewProcess.questions) : of(EMPTY),
    ]);
  }

  private updatePeerReviewSummaries(reviewProcess: UserPeerReviewProcess): Observable<boolean> {
    return this.sharedApiPeerReviewService.patchPeerReviewFeedbackSummary({
      body: {
        bt_peerreviewfeedbacksummaryid: reviewProcess.peerReviewSummaries.bt_peerreviewfeedbacksummaryid,
        bt_peerreviewstatus: reviewProcess.peerReviewSummaries.bt_peerreviewstatus,
        bt_tcacceptance: reviewProcess.peerReviewSummaries.bt_tcacceptance,
      },
    });
  }

  private updatePeerReviewFeedback(questions: PeerReviewFeedback[]): any {
    const allQuestionsBody: FeedbackPatch[] = questions
      .map((question) => {
        const body: FeedbackPatch = {
          bt_feedbackcompleted: question.bt_feedbackcompleted,
          bt_peerreviewfeedbackid: question.bt_peerreviewfeedbackid,
          bt_rating: question.bt_rating,
          bt_response: question.bt_response,
        };
        return body;
      })
      .filter((item) => item.bt_rating || item.bt_response)
      .filter((item) => {
        for (const question of this._currentPeerReviewQuestions) {
          if (question.bt_peerreviewfeedbackid === item.bt_peerreviewfeedbackid) {
            return !(question.bt_rating === item.bt_rating && question.bt_response === item.bt_response);
          }
        }
      });

    const allRequests = allQuestionsBody.map((body) => {
      return this.sharedApiPeerReviewService.patchPeerReviewFeedback({ body });
    });

    return combineLatest([...allRequests]);
  }

  updatePerformanceReview(reviewProcess: UserReviewProcess, updateSummaries = true, updateQuestions = true): Observable<any> {
    return combineLatest([
      updateSummaries ? this.updatePerformanceReviewSummaries(reviewProcess) : of(EMPTY),
      updateQuestions ? this.updatePerformanceReviewFeedback(reviewProcess.questions) : of(EMPTY),
    ]);
  }

  private updatePerformanceReviewSummaries(reviewProcess: any): Observable<any> {
    const body = reviewProcess.performanceReviewSummaries;
    delete body['bthr_Employee'];
    Object.keys(body).forEach((key) => {
      if (key.toLowerCase().includes('odata') || key.startsWith('_')) {
        delete body[key];
      }
    });

    /*return this.sharedApiPerformanceReviewService.patchPerformanceReviews({ managerguid: this.userService.user._bthr_manager_value, body });*/

    return this.storePerformanceReviewService.updatePerformanceReview({ patchPerformanceReviewsCommands: body });
  }

  private updatePerformanceReviewFeedback(questions: any[]): any {
    const allQuestionsBody: any[] = questions
      .filter((item) => item.bt_ratingfloat || item.bt_response)
      .filter((item) => {
        for (const question of this._currentPerformanceReviewQuestions) {
          if (question.bt_performancereviewdetailid === item.bt_performancereviewdetailid) {
            return !(question.bt_ratingfloat === item.bt_ratingfloat && question.bt_response === item.bt_response);
          }
        }
      });

    const allRequests = allQuestionsBody.map((body) => {
      Object.keys(body).forEach(() => {
        body.bt_completed = true;
        //if (key.toLowerCase().includes('odata') || key.startsWith('_')) {
        //  delete body[key];
        //}
      });

      /*return this.sharedApiPerformanceReviewService.patchEnhancedReviewDetail({ body });*/
      return this.storeEnhancedReviewDetailService.updateEnhancedReviewDetail({ patchEnhancedReviewDetailCommands: body });
    });

    return combineLatest([...allRequests]);
  }
}
