/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EngageApiConfiguration, EngageApiConfigurationParams } from './engage-api-configuration';

import { EngageApiEngageService } from './services/engage-api-engage.service';
import { EngageApiVersionService } from './services/engage-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [EngageApiEngageService, EngageApiVersionService, EngageApiConfiguration],
})
export class EngageApiModule {
  static forRoot(params: EngageApiConfigurationParams): ModuleWithProviders<EngageApiModule> {
    return {
      ngModule: EngageApiModule,
      providers: [
        {
          provide: EngageApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: EngageApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('EngageApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
