import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'verde-kendo-formly-array',
  template: `
    <h6 *ngIf="props.label">{{ props.label }}</h6>
    <kendo-label *ngIf="props.description">{{ props.description }}</kendo-label>
    <div class="d-flex flex-row-reverse">
      <button class="btn btn-primary" type="button" (click)="add()">+</button>
    </div>

    <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>

    <div *ngFor="let field of field.fieldGroup; let i = index" class="row align-items-start">
      <formly-field class="col" [field]="field" [id]="field.key"></formly-field>
      <div *ngIf="field.props.removable !== false" class="col-2 text-right">
        <button class="btn btn-danger" type="button" (click)="remove(i)">-</button>
      </div>
    </div>
  `,
})
export class KendoFormlyArray extends FieldArrayType {}
