import { UserGraph } from './user-graph';

export class AuthState {
  // Auth
  isTeamsAuthed?: boolean = false;
  isMicrosoftAuthed?: boolean = false;
  tenantId?: string = '';
  azureObjectId?: string = '';
  userEmail?: string = '';
}
