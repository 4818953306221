import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ManagerActions, ManagerActionTypes } from '../actions/manager.actions';
import * as fromDashboardRelatedReducer from './dashboard-related.reducer';

// Models
import { User } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type ManagerState = EntityState<User>;

export interface ManagerRelatedState {
  managerState: ManagerState;
  loading: boolean;
  error: any;
}

export const managerKeys = ['bthr_employeeid'];
export const managerStateAdapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (data) => keyHandler(data, managerKeys),
});

export const initialManagerState: ManagerState = managerStateAdapter.getInitialState({});

export const initialManagerRelatedState = {
  managerState: initialManagerState,
  loading: false,
  error: {},
};

export function managerStateRelatedReducer(state = initialManagerRelatedState, action: ManagerActions): ManagerRelatedState {
  switch (action.type) {
    // Get Manager
    case ManagerActionTypes.MANAGER_GET:
      return { ...state, loading: true };
    case ManagerActionTypes.MANAGER_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        managerState: managerStateAdapter.setAll(action.payload, state.managerState),
      };
    }
    case ManagerActionTypes.MANAGER_GET_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Clear
    case ManagerActionTypes.MANAGER_CLEAR:
      return { ...initialManagerRelatedState };
    default:
      return state;
  }
}

export const getHomeRelatedState = createFeatureSelector<fromDashboardRelatedReducer.DashboardRelatedState>('dashboardRelatedReducer');

export const getManagerRelatedState = createSelector(getHomeRelatedState, (state: fromDashboardRelatedReducer.DashboardRelatedState) => state.managerState);

// Current Manager
export const getManagerState = createSelector(getManagerRelatedState, (state) => state.managerState);
export const { selectAll: getManager } = managerStateAdapter.getSelectors(getManagerState);

// Loading
export const isLoading = createSelector(getManagerRelatedState, (state) => state.loading);

// Error
export const getError = createSelector(getManagerRelatedState, (state) => state.error);
