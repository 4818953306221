/* tslint:disable */
/* eslint-disable */
export enum ProfileBtMyProfilePages {
  Personal = 'Personal',
  Organisation = 'Organisation',
  Location = 'Location',
  FinancialDetails = 'FinancialDetails',
  Address = 'Address',
  WorkersPermit = 'WorkersPermit',
  Funds = 'Funds',
  TimeOff = 'TimeOff',
  Contract = 'Contract',
  Contacts = 'Contacts',
}
