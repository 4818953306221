/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PerformanceReviewApiConfiguration, PerformanceReviewApiConfigurationParams } from './performance-review-api-configuration';

import { PerformanceReviewApiPeerReviewService } from './services/performance-review-api-peer-review.service';
import { PerformanceReviewApiPerformanceReviewService } from './services/performance-review-api-performance-review.service';
import { PerformanceReviewApiVersionService } from './services/performance-review-api-version.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    PerformanceReviewApiPeerReviewService,
    PerformanceReviewApiPerformanceReviewService,
    PerformanceReviewApiVersionService,
    PerformanceReviewApiConfiguration,
  ],
})
export class PerformanceReviewApiModule {
  static forRoot(params: PerformanceReviewApiConfigurationParams): ModuleWithProviders<PerformanceReviewApiModule> {
    return {
      ngModule: PerformanceReviewApiModule,
      providers: [
        {
          provide: PerformanceReviewApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: PerformanceReviewApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('PerformanceReviewApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
