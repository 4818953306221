/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CommunicationBaseService } from '../communication-base-service';
import { CommunicationApiConfiguration } from '../communication-api-configuration';
import { CommunicationStrictHttpResponse } from '../communication-strict-http-response';
import { CommunicationRequestBuilder } from '../communication-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommunicationVerdeVersion } from '../models/communication-verde-version';

@Injectable({
  providedIn: 'root',
})
export class CommunicationApiVersionService extends CommunicationBaseService {
  constructor(config: CommunicationApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getVersionNumber
   */
  static readonly GetVersionNumberPath = '/api/Version/GetVersionNumber';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVersionNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersionNumber$Response(params?: {}): Observable<CommunicationStrictHttpResponse<CommunicationVerdeVersion>> {
    const rb = new CommunicationRequestBuilder(this.rootUrl, CommunicationApiVersionService.GetVersionNumberPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as CommunicationStrictHttpResponse<CommunicationVerdeVersion>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVersionNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVersionNumber(params?: {}): Observable<CommunicationVerdeVersion> {
    return this.getVersionNumber$Response(params).pipe(
      map((r: CommunicationStrictHttpResponse<CommunicationVerdeVersion>) => r.body as CommunicationVerdeVersion),
    );
  }
}
