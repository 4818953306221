import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';

export interface KendoFormlyImageProps extends FormlyFieldProps {
  width?: string; // Optional width
  height?: string; // Optional height
  margin?: string; // Optional margin
}

@Component({
  selector: 'verde-kendo-formly-image',
  template: `<img
    class="image"
    [src]="imageUrl"
    *ngIf="imageUrl"
    alt="Image"
    [style.width]="props.width || '172px'"
    [style.height]="props.height || '172px'"
    [style.margin]="props.margin || '1rem'"
  />`,
  styles: [
    `
      .image {
        border-radius: 50%;
      }
    `,
  ],
})
export class KendoFormlyImage extends FieldType<FieldTypeConfig<KendoFormlyImageProps>> implements OnInit {
  imageUrl: string;

  ngOnInit() {
    if (this.formControl) {
      this.imageUrl = 'data:image/jpeg;base64,' + this.field.defaultValue;
    }
  }
}
