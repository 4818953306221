/* eslint-disable no-console */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControlDirective, FormGroup } from '@angular/forms';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { GroupDescriptor, orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { SharedApiVotingService, UserAppConfigDto, UserUserDto, VoteManagerNominationControl, VoteNomination, VoteNominationList } from '@verde/api';
import { DeviceTypeService, UserService } from '@verde/core';
import { DotsMenuItem, ModalService } from '@verde/shared';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'verde-nomination-approval-tab',
  templateUrl: './nomination-approval-tab.component.html',
  styleUrls: ['./nomination-approval-tab.component.scss'],
})
export class NominationApprovalTabComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject();

  //#region Variable Declarations

  @ViewChild('myGrid') private grid;

  // Users profile
  user: UserUserDto;

  config: UserAppConfigDto = {};

  dotMenuItems: DotsMenuItem[] = [];

  disableAnimation: boolean;
  showUserCard = false;
  agreedYes = false;
  agreedNo = false;

  confirmationMessage = '';
  voteGroupDropdownValue = '';

  //Arrays for APIs
  allVoteManagerNominationControl: Array<VoteManagerNominationControl> = new Array<VoteManagerNominationControl>();
  allNominationsToApprove: Array<VoteNominationList> = new Array<VoteNominationList>();
  allNominationsPerNominee: Array<VoteNomination> = new Array<VoteNomination>();

  selectedNominee: VoteNominationList;

  selectedVoteManagerNominationControl: VoteManagerNominationControl = null;

  confirmDisabledFlag = true;

  //bodies
  patchNominationListBody: VoteNominationList = {};
  patchNominatedListReasonBody: VoteNominationList = {};
  patchVoteManagerNominationControl: VoteManagerNominationControl = {};

  defaultValue = 'default';
  checkDefaultPattern = /^(?!default$).*/;

  approvalFormDefaultState = {
    agreedYes: null,
    agreedNo: null,
    rejectReason: null,
  };

  //#region Kendo variables
  // Nomination Approvals
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  approvalsGridData: GridDataResult[] | VoteNominationList[];

  approvalsGridView: GridDataResult;

  approvalsState: State = {
    skip: 0,
    take: 5,
  };

  approvalsSort: SortDescriptor[] = [
    {
      field: 'fullName',
      dir: 'asc',
    },
  ];

  approvalsGroups: GroupDescriptor[];

  approvalsSelection: any[] = [];

  approvalsSelectedCallback = (args) => args.dataItem;

  //Editing
  view: Observable<GridDataResult>;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  inEditMode = false;
  private editedRowIndex: number;
  sender: any;
  rowIndex: any;

  // Nomination Reasons
  reasonsGridView: GridDataResult;

  reasonsState: State = {
    skip: 0,
    take: 5,
  };

  reasonsSort: SortDescriptor[] = [
    {
      field: 'fullName',
      dir: 'asc',
    },
  ];

  reasonsGroups: GroupDescriptor[];

  reasonsSelection: any[] = [];

  reasonsSelectedCallback = (args) => args.dataItem;

  //#endregion Kendo variables

  //#endregion Variable Declarations

  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private sharedApiVotingService: SharedApiVotingService,
    public deviceTypeService: DeviceTypeService,
  ) {}

  ngOnInit(): void {
    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.config = data;
    });

    this.user = this.userService.user;

    if (Object.keys(this.user).length > 0) {
      this.getAllVoteManagerNominationControl();
    }

    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  refresh() {
    this.getAllVoteManagerNominationControl();
  }

  // #region Three Dot Menu Logic
  menuClicked(parentItem: VoteNominationList) {
    this.dotMenuItems = [];
    const tempItems: DotsMenuItem[] = [];

    if (!this.inEditMode && parentItem.odataApprovalStatus !== 'Completed' && this.selectedVoteManagerNominationControl.bt_remaining > 0) {
      tempItems.push({ id: 'approve', text: 'Approve' });
    } else if (
      !this.inEditMode &&
      parentItem.odataApprovalStatus !== 'Completed' &&
      this.selectedVoteManagerNominationControl.bt_remaining == 0 &&
      !parentItem.bt_managerapproved
    ) {
      tempItems.push({ id: 'editReason', text: 'Edit Reason' });
    } else if (this.inEditMode) {
      tempItems.push({ id: 'save', text: 'Save' });
      tempItems.push({ id: 'cancel', text: 'Cancel' });
    }

    if (tempItems.length === 0) {
      tempItems.push({ text: 'No Options' });
    }

    this.dotMenuItems = [...tempItems];
  }

  menuItemClicked(menuItem: DotsMenuItem, parentItem: VoteNominationList, myForm: FormGroup, myForm2: FormGroup) {
    if (menuItem.id === 'approve') {
      this.openApprovalModal(parentItem, myForm);
    } else if (menuItem.id === 'editReason') {
      document.getElementById('editReasonButton').click();
    } else if (menuItem.id === 'save') {
      if (myForm2.invalid || myForm2.pristine) {
        this.modalService.open('editFormNotValidErrorModal');
      } else {
        document.getElementById('editSaveButton').click();
      }
    } else if (menuItem.id === 'cancel') {
      document.getElementById('cancelAddButton').click();
    }
  }

  // #endregion Three Dot Menu Logic

  //#region API Calls
  getAllVoteManagerNominationControl() {
    this.spinner.show('nominationsapprovaltab');
    this.sharedApiVotingService
      .getAllVoteManagerNominationControl({
        managerID: this.user?.employeeId,
        legalEntityID: this.user?.legalEntityId,
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allVoteManagerNominationControl = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.allVoteManagerNominationControl.length > 0) {
            this.selectedVoteManagerNominationControl = this.allVoteManagerNominationControl[0];

            this.getNominationLists(this.selectedVoteManagerNominationControl._bt_votinggoup_value);
          } else {
            this.selectedVoteManagerNominationControl = null;
            this.spinner.hide('nominationsapprovaltab');
          }
        },
      );
  }

  getNominationLists(voteID: string) {
    this.sharedApiVotingService
      .getVoteNominatedLists({ legalEntityID: this.user?.legalEntityId, managerID: this.user?.employeeId, voteID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allNominationsToApprove = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.allNominationsToApprove.length > 0) {
            this.confirmDisabledFlag = false;

            this.allNominationsToApprove.forEach((t) => {
              if (t.odataApprovalStatus === 'Outstanding') {
                this.confirmDisabledFlag = true;
              }
            });

            this.approvalsGridData = this.allNominationsToApprove;
          }

          this.loadApprovals();
          this.spinner.hide('nominationsapprovaltab');
        },
      );
  }

  getVoteNominationsPerNominee(userID: string, legalEntityID: string, voteID: string) {
    this.spinner.show('nomineenominationreasons');
    this.sharedApiVotingService
      .getVoteNominationsPerNominee({ userID, legalEntityID, voteID })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.allNominationsPerNominee = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.loadReasons();
          this.spinner.hide('nomineenominationreasons');
        },
      );
  }

  patchNominationLists(myForm: FormGroup) {
    this.spinner.show('confirmation');
    this.modalService.open('confirmationModal');

    //------------------------------------------------------------------------------------------------------------------------
    //Updating the actual nomination list

    this.patchNominationListBody.bt_approvalstatus = 692360001;
    this.patchNominationListBody.bt_managerapprovaldate = moment().format('YYYY-MM-DD');

    if (this.agreedYes) {
      this.patchNominationListBody.bt_managerapproved = true;
    } else {
      this.patchNominationListBody.bt_managerapproved = false;
      this.patchNominationListBody.bt_managerrejectionreason = myForm.value.rejectReason;
    }

    this.patchNominationListBody.bt_votenominatedlistid = this.selectedNominee.bt_votenominatedlistid;

    this.sharedApiVotingService
      .patchVoteNominatedLists({ body: this.patchNominationListBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.sharedApiVotingService
            .getVoteNominatedLists({
              legalEntityID: this.user?.legalEntityId,
              managerID: this.user?.employeeId,
              voteID: this.selectedVoteManagerNominationControl._bt_votinggoup_value,
            })
            .pipe(take(1))
            .subscribe(
              (ret) => {
                this.allNominationsToApprove = ret;
              },
              (error) => {
                console.error(error);
              },
              () => {
                if (this.allNominationsToApprove.length > 0) {
                  this.confirmDisabledFlag = false;

                  this.allNominationsToApprove.forEach((t) => {
                    if (t.odataApprovalStatus === 'Outstanding') {
                      this.confirmDisabledFlag = true;
                    }
                  });

                  this.approvalsGridData = this.allNominationsToApprove;
                }

                this.loadApprovals();

                this.confirmationMessage = 'You have successfully approved this nomination!';
                this.spinner.hide('confirmation');
              },
            );
        },
      );

    //------------------------------------------------------------------------------------------------------------------------
    //Updating the vote manager nomination control

    if (this.agreedYes) {
      this.patchVoteManagerNominationControl.bt_nominationscomplted = this.selectedVoteManagerNominationControl.bt_nominationscomplted + 1;
    } else {
      this.patchVoteManagerNominationControl.bt_nonotapproved = this.selectedVoteManagerNominationControl.bt_nonotapproved + 1;
    }

    this.patchVoteManagerNominationControl.bt_nominationapprovalstatus = 692360001;
    this.patchVoteManagerNominationControl.bt_votemanagernominationcontrolid = this.selectedVoteManagerNominationControl.bt_votemanagernominationcontrolid;

    this.sharedApiVotingService
      .patchVoteManagerNominationControl({ body: this.patchVoteManagerNominationControl })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.patchVoteManagerNominationControl = {};

          this.sharedApiVotingService
            .getVoteManagerNominationControlByVote({
              managerID: this.user?.employeeId,
              legalEntityID: this.user?.legalEntityId,
              voteID: this.selectedVoteManagerNominationControl._bt_votinggoup_value,
            })
            .pipe(take(1))
            .subscribe(
              (ret) => {
                if (ret.length > 0) {
                  this.selectedVoteManagerNominationControl = ret[0];
                } else {
                  this.selectedVoteManagerNominationControl = null;
                }
              },
              (error) => {
                console.error(error);
              },
              () => {
                if (this.selectedVoteManagerNominationControl != null) {
                  this.getNominationLists(this.selectedVoteManagerNominationControl._bt_votinggoup_value);
                } else {
                  this.allNominationsToApprove = new Array<VoteNominationList>();
                  this.approvalsGridData = this.allNominationsToApprove;
                  this.loadApprovals();
                }
              },
            );
        },
      );
  }

  patchRejection() {
    this.sender.closeRow(this.rowIndex);

    this.sharedApiVotingService
      .patchVoteNominatedLists({ body: this.patchNominatedListReasonBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.sharedApiVotingService
            .getVoteNominatedLists({
              legalEntityID: this.user?.legalEntityId,
              managerID: this.user?.employeeId,
              voteID: this.selectedVoteManagerNominationControl._bt_votinggoup_value,
            })
            .pipe(take(1))
            .subscribe(
              (ret) => {
                this.allNominationsToApprove = ret;
              },
              (error) => {
                console.error(error);
              },
              () => {
                if (this.allNominationsToApprove.length > 0) {
                  this.confirmDisabledFlag = false;

                  this.allNominationsToApprove.forEach((t) => {
                    if (t.odataApprovalStatus === 'Outstanding') {
                      this.confirmDisabledFlag = true;
                    }
                  });

                  this.approvalsGridData = this.allNominationsToApprove;
                }

                this.patchNominatedListReasonBody = null;

                this.loadApprovals();

                this.inEditMode = false;
              },
            );
        },
      );

    let tempManagerNominationControlBody: VoteManagerNominationControl;

    tempManagerNominationControlBody.bt_nonotapproved = this.selectedVoteManagerNominationControl.bt_nonotapproved + 1;

    tempManagerNominationControlBody.bt_votemanagernominationcontrolid = this.selectedVoteManagerNominationControl.bt_votemanagernominationcontrolid;

    this.sharedApiVotingService
      .patchVoteManagerNominationControl({ body: tempManagerNominationControlBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          tempManagerNominationControlBody = null;

          this.sharedApiVotingService
            .getVoteManagerNominationControlByVote({
              managerID: this.user?.employeeId,
              legalEntityID: this.user?.legalEntityId,
              voteID: this.selectedVoteManagerNominationControl._bt_votinggoup_value,
            })
            .pipe(take(1))
            .subscribe(
              (ret) => {
                if (ret.length > 0) {
                  this.selectedVoteManagerNominationControl = ret[0];
                } else {
                  this.selectedVoteManagerNominationControl = null;
                }
              },
              (error) => {
                console.error(error);
              },
              () => {
                if (this.selectedVoteManagerNominationControl != null) {
                  this.getNominationLists(this.selectedVoteManagerNominationControl._bt_votinggoup_value);
                } else {
                  this.allNominationsToApprove = new Array<VoteNominationList>();
                  this.approvalsGridData = this.allNominationsToApprove;
                  this.loadApprovals();
                }
              },
            );
        },
      );
  }

  patchFinaliseApprovals() {
    this.spinner.show('confirmation');
    this.modalService.open('confirmationModal');

    let tempManagerNominationControlBody: VoteManagerNominationControl = {
      bt_nominationapprovalstatus: 692360002,
      bt_votemanagernominationcontrolid: this.selectedVoteManagerNominationControl.bt_votemanagernominationcontrolid,
    };

    this.sharedApiVotingService
      .patchVoteManagerNominationControl({ body: tempManagerNominationControlBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          tempManagerNominationControlBody = null;

          this.sharedApiVotingService
            .getAllVoteManagerNominationControl({
              managerID: this.user?.employeeId,
              legalEntityID: this.user?.legalEntityId,
            })
            .pipe(take(1))
            .subscribe(
              (ret) => {
                this.allVoteManagerNominationControl = ret;
              },
              (error) => {
                console.error(error);
              },
              () => {
                if (this.allVoteManagerNominationControl.length > 0) {
                  this.selectedVoteManagerNominationControl = this.allVoteManagerNominationControl[0];

                  this.getNominationLists(this.selectedVoteManagerNominationControl._bt_votinggoup_value);
                } else {
                  this.selectedVoteManagerNominationControl = null;
                  this.allNominationsToApprove = new Array<VoteNominationList>();
                  this.approvalsGridData = this.allNominationsToApprove;

                  this.loadApprovals();

                  this.confirmationMessage = 'You have successfully finalised these nomination approvals!';
                  this.spinner.hide('confirmation');
                }
              },
            );
        },
      );
  }

  //#endregion API Calls

  openApprovalModal(selectedRecord: VoteNominationList, myForm: FormGroup) {
    this.selectedNominee = selectedRecord;

    this.showUserCard = true;
    this.agreedYes = false;
    this.agreedNo = false;

    this.approvalFormDefaultState = {
      agreedYes: this.agreedYes,
      agreedNo: this.agreedNo,
      rejectReason: null,
    };

    myForm.reset(this.approvalFormDefaultState);

    this.getVoteNominationsPerNominee(
      this.selectedNominee._bt_nominatedemployee_value,
      this.selectedNominee._bt_nomineelegalentity_value,
      this.selectedNominee._bt_votegroup_value,
    );

    this.modalService.open('approveNominationModal');
  }

  hideUserCard(e: boolean) {
    if (!e) {
      this.showUserCard = e;
    }
  }

  confirmAllApprovals() {
    this.modalService.open('confirmAllApprovalsModal');
  }

  agreedYesChange(e, element: FormControlDirective) {
    this.agreedYes = e;
    this.agreedNo = !e;
    element.reset();
  }

  agreedNoChange(e, element: FormControlDirective) {
    this.agreedNo = e;
    this.agreedYes = !e;
    element.reset();
  }

  setVoteGroupDropdownValue(e: VoteManagerNominationControl) {
    this.voteGroupDropdownValue = e._bt_votinggoup_value;

    this.selectedVoteManagerNominationControl = e;

    this.getNominationLists(this.voteGroupDropdownValue);
  }

  //#region Kendo Methods
  // Row style callback
  rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  // Nomination Approvals
  loadApprovals(): void {
    this.approvalsGridView = {
      data: orderBy(this.allNominationsToApprove, this.approvalsSort),
      total: this.allNominationsToApprove.length,
    };

    const group: GroupDescriptor[] = [
      {
        field: 'odataApprovalStatus',
        dir: 'desc',
      },
    ];

    this.approvalsGroupChange(group);
  }

  approvalsGroupChange(groups: GroupDescriptor[]): void {
    this.approvalsGroups = groups;
    this.approvalsGridView = process(this.allNominationsToApprove, { group: this.approvalsGroups });
  }

  approvalsDataStateChange(state: DataStateChangeEvent): void {
    this.approvalsState = state;
    this.approvalsGridView = process(this.allNominationsToApprove, this.approvalsState);
  }

  approvalsSortChange(sort: SortDescriptor[]): void {
    this.approvalsSort = sort;
    this.loadApprovals();
  }

  onApprovalsFilter(inputValue: string): void {
    this.approvalsGridData = process(this.allNominationsToApprove, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'fullName',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'odataNomineeLegalEntity',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'odataManagerApproved',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'odataApprovalStatus',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'bt_managerrejectionreason',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'odataRequiredBy',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  //Editing
  cancelPatchRejection() {
    this.patchNominatedListReasonBody = null;
    this.inEditMode = false;
  }

  editHandler({ sender, rowIndex }) {
    this.closeEditor(sender);

    this.editedRowIndex = rowIndex;

    this.inEditMode = true;

    sender.editRow(rowIndex);
  }

  cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);

    this.inEditMode = false;
  }

  saveHandler({ sender, rowIndex, dataItem }) {
    this.modalService.open('confirmRejectionModal');

    this.patchNominatedListReasonBody.bt_managerrejectionreason = dataItem.bt_managerrejectionreason;
    this.patchNominatedListReasonBody.bt_approvalstatus = 692360001;
    this.patchNominatedListReasonBody.bt_votenominatedlistid = dataItem.bt_votenominatedlistid;

    this.sender = sender;
    this.rowIndex = rowIndex;

    this.inEditMode = false;

    this.editedRowIndex = undefined;
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);

    this.editedRowIndex = undefined;
    this.inEditMode = false;
  }

  // Nomination Reasons
  loadReasons(): void {
    this.reasonsGridView = {
      data: orderBy(this.allNominationsPerNominee, this.reasonsSort),
      total: this.allNominationsPerNominee.length,
    };
  }

  reasonsGroupChange(groups: GroupDescriptor[]): void {
    this.reasonsGroups = groups;
    this.reasonsGridView = process(this.allNominationsPerNominee, { group: this.reasonsGroups });
  }

  reasonsDataStateChange(state: DataStateChangeEvent): void {
    this.reasonsState = state;
    this.reasonsGridView = process(this.allNominationsPerNominee, this.reasonsState);
  }

  reasonsSortChange(sort: SortDescriptor[]): void {
    this.reasonsSort = sort;
    this.loadReasons();
  }

  getTitle(id): string {
    return document.getElementById(id).innerText;
  }

  //#endregion Kendo Methods
}
