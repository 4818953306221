import { PerformanceReviewDvPerformanceReview, PerformanceReviewEnhancedReviewDetail, User } from '@verde/api';
import { PeerReviewUser } from '@verde/shared';

export interface UserReviewProcess {
  employee?: User;
  performanceReviewSummaries?: PerformanceReviewDvPerformanceReview;
  questions?: PerformanceReviewEnhancedReviewDetail[];
  performanceReviewUser?: PeerReviewUser;
  idRef?: number;
}
