/* tslint:disable */
/* eslint-disable */
export interface VerdeDocumentsOne {
  _bt_headertemplate_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_templatefooter_value?: null | string;
  allKeys?: null | Array<string>;
  bt_fitonpage?: boolean;
  bt_headerfirstpageonly?: boolean;
  bt_layout?: null | string;
  bt_templatecontent?: null | string;
  bt_templatename?: null | string;
  bt_templateno?: null | string;
  bt_verdedocumentstemplateid?: null | string;
}
