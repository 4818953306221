/* tslint:disable */
/* eslint-disable */
export interface EnhancedReviewSummary {
  _bt_cycle_value?: null | string;
  _bt_employee_value?: null | string;
  _bt_period_value?: null | string;
  _bt_responder_value?: null | string;
  _bt_template_value?: null | string;
  bt_basepoint?: null | number;
  bt_basepointpercentage?: null | number;
  bt_benchmark?: null | string;
  bt_date?: null | string;
  bt_displaysubgroup?: null | boolean;
  bt_feedbackcompleted?: null | boolean;
  bt_groupsorting?: null | number;
  bt_measuresorting?: null | number;
  bt_performancereviewdetailsummary?: null | string;
  bt_performancereviewdetailsummaryid?: null | string;
  bt_ratingaverage?: null | number;
  bt_ratingfloat?: null | number;
  bt_recordstatus?: null | number;
  bt_response?: null | string;
  bt_responsetype?: null | number;
  bt_subgroupsorting?: null | number;
  bt_templatetype?: null | number;
  odataCycle?: null | string;
  odataFeedbackCompleted?: null | string;
  odataGroup?: null | string;
  odataMeasure?: null | string;
  odataPeriod?: null | string;
  odataResponseType?: null | string;
  odataSubgroup?: null | string;
  odataTemplate?: null | string;
  odataTemplateType?: null | string;
}
