/* tslint:disable */
/* eslint-disable */
export interface BookingsBookingsFilteredDto {
  bt_Departmental?: null | boolean;
  bt_ProxyEmployee?: null | string;
  bt_bookingno?: null | string;
  bt_bookingreason?: null | string;
  bt_fromdate?: null | string;
  bt_todate?: null | string;
  bt_verdebookingsid?: null | string;
  cancelRecurring?: null | boolean;
  includeWeekends?: null | boolean;
  isProxy?: null | boolean;
  isRecurring?: null | boolean;
  linkedId?: null | string;
  numberOfDays?: null | number;
  odataBookingItem?: null | string;
  odataBookingType?: null | string;
  odataDuration?: null | string;
  odataEmployee?: null | string;
  odataOffice?: null | string;
  odataPostBookingItem?: null | string;
  odataPostBookingType?: null | string;
  odataPostDepartment?: null | string;
  odataPostDuration?: null | string;
  odataPostEmployee?: null | string;
  odataPostLegalEntity?: null | string;
  odataPostOffice?: null | string;
  odataPostPrimaryLocation?: null | string;
  odataPostSubOffice?: null | string;
  odataPrimaryLocation?: null | string;
  odataSubOffice?: null | string;
  stateCode?: null | number;
}
