/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { LearnBaseService } from '../learn-base-service';
import { LearnApiConfiguration } from '../learn-api-configuration';
import { LearnStrictHttpResponse } from '../learn-strict-http-response';
import { LearnRequestBuilder } from '../learn-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LearnGetLearnQuery } from '../models/learn-get-learn-query';
import { LearnLearnExample } from '../models/learn-learn-example';
import { LearnPostLearnCommand } from '../models/learn-post-learn-command';

@Injectable({
  providedIn: 'root',
})
export class LearnApiLearnService extends LearnBaseService {
  constructor(config: LearnApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getLearn
   */
  static readonly GetLearnPath = '/api/Learn/get/learn';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLearn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLearn$Response(params?: { tenantId?: string; body?: LearnGetLearnQuery }): Observable<LearnStrictHttpResponse<Array<LearnLearnExample>>> {
    const rb = new LearnRequestBuilder(this.rootUrl, LearnApiLearnService.GetLearnPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as LearnStrictHttpResponse<Array<LearnLearnExample>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLearn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getLearn(params?: { tenantId?: string; body?: LearnGetLearnQuery }): Observable<Array<LearnLearnExample>> {
    return this.getLearn$Response(params).pipe(map((r: LearnStrictHttpResponse<Array<LearnLearnExample>>) => r.body as Array<LearnLearnExample>));
  }

  /**
   * Path part for operation postLearn
   */
  static readonly PostLearnPath = '/api/Learn/post/learn';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postLearn()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postLearn$Response(params?: { tenantId?: string; body?: LearnPostLearnCommand }): Observable<LearnStrictHttpResponse<boolean>> {
    const rb = new LearnRequestBuilder(this.rootUrl, LearnApiLearnService.PostLearnPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as LearnStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postLearn$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postLearn(params?: { tenantId?: string; body?: LearnPostLearnCommand }): Observable<boolean> {
    return this.postLearn$Response(params).pipe(map((r: LearnStrictHttpResponse<boolean>) => r.body as boolean));
  }
}
