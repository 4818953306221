import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { QualificationsGetAccreditationAuthorityQuery } from '@verde/api';
import { Observable } from 'rxjs';
import * as AccreditationAuthorityActions from '../store/actions/accreditation-authority.actions';
import * as fromAccreditationAuthorityReducer from '../store/reducers/accreditation-authority.reducer';
import * as fromProfileRelatedReducer from '../store/reducers/profile-related.reducer';

@Injectable({
  providedIn: 'root',
})
export class AccreditationAuthorityService {
  // AccreditationAuthority
  accreditationAuthorityLoading$: Observable<boolean>;
  accreditationAuthorityObjects$: Observable<any[]>;

  constructor(private store: Store<fromProfileRelatedReducer.ProfileRelatedState>) {
    // AccreditationAuthority
    this.accreditationAuthorityLoading$ = this.store.pipe(select(fromAccreditationAuthorityReducer.isAccreditationAuthorityLoading));
    this.accreditationAuthorityObjects$ = this.store.pipe(select(fromAccreditationAuthorityReducer.getAccreditationAuthority));
  }

  // AccreditationAuthority Detail
  // Get
  getAccreditationAuthority(body: QualificationsGetAccreditationAuthorityQuery) {
    this.store.dispatch(new AccreditationAuthorityActions.AccreditationAuthorityGetAll(body));
  }

  // Clear
  accreditationAuthorityClear() {
    this.store.dispatch(new AccreditationAuthorityActions.AccreditationAuthorityClear(null));
  }
}
