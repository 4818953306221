import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BirthdaysActions, BirthdaysActionTypes } from '../actions/birthdays.actions';
import * as fromDashboardRelatedReducer from './dashboard-related.reducer';

// Models
import { User } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type BirthdaysState = EntityState<User>;

export interface BirthdaysRelatedState {
  birthdaysState: BirthdaysState;
  loading: boolean;
  error: any;
}

export const birthdaysKeys = ['bthr_employeeid'];
export const birthdaysStateAdapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (data) => keyHandler(data, birthdaysKeys),
});

export const initialBirthdaysState: BirthdaysState = birthdaysStateAdapter.getInitialState({});

export const initialBirthdaysRelatedState = {
  birthdaysState: initialBirthdaysState,
  loading: false,
  error: {},
};

export function birthdaysStateRelatedReducer(state = initialBirthdaysRelatedState, action: BirthdaysActions): BirthdaysRelatedState {
  switch (action.type) {
    // Get All Birthdays
    case BirthdaysActionTypes.BIRTHDAYS_GET_ALL:
      return { ...state, loading: true };
    case BirthdaysActionTypes.BIRTHDAYS_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        birthdaysState: birthdaysStateAdapter.setAll(action.payload, state.birthdaysState),
      };
    }
    case BirthdaysActionTypes.BIRTHDAYS_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Clear
    case BirthdaysActionTypes.BIRTHDAYS_CLEAR:
      return { ...initialBirthdaysRelatedState };
    default:
      return state;
  }
}

export const getHomeRelatedState = createFeatureSelector<fromDashboardRelatedReducer.DashboardRelatedState>('dashboardRelatedReducer');

export const getBirthdaysRelatedState = createSelector(getHomeRelatedState, (state: fromDashboardRelatedReducer.DashboardRelatedState) => state.birthdaysState);

// Current Birthdays
export const getBirthdaysState = createSelector(getBirthdaysRelatedState, (state) => state.birthdaysState);
export const { selectAll: getBirthdays } = birthdaysStateAdapter.getSelectors(getBirthdaysState);

// Loading
export const isLoading = createSelector(getBirthdaysRelatedState, (state) => state.loading);

// Error
export const getError = createSelector(getBirthdaysRelatedState, (state) => state.error);
