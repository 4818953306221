import { Pipe, PipeTransform } from '@angular/core';
import { CommonHelpers } from '@verde/core';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  transform(value: string, format: string): string | null {
    return CommonHelpers.formatDate(value, format);
  }
}
