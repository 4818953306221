/* tslint:disable */
/* eslint-disable */
export interface FleetType {
  _bt_legalentity_value?: null | string;
  bt_checklistrequired?: null | boolean;
  bt_fleettype?: null | string;
  bt_fleettypeid?: null | string;
  bt_fleettypeunique?: null | string;
  odataLegalEntity?: null | string;
}
