import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import {
  DocumentApiDocumentService,
  DocumentBtMfaStatus,
  DocumentBtSalaryAdviceType,
  DocumentDocSalaryAdviceDetail,
  DocumentDocSalaryAdviceHeader,
  DocumentPostVerdePayslipAccessControl,
} from '@verde/api';
import { AuthenticationService, UserService } from '@verde/core';
import { MfAuthService, ModalService } from '@verde/shared';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'verde-payslip-dialog',
  templateUrl: './payslip-dialog.component.html',
  styleUrls: ['./payslip-dialog.component.scss'],
})
export class PayslipDialogComponent implements OnInit, OnDestroy {
  // ---------------------
  // Grid Configuration
  // ---------------------
  skeletonSelectedCallback = (args: { dataItem: any }) => args.dataItem;
  skeletonSelection: any[] = [];

  // ---------------------
  // Input and Output Properties
  // ---------------------
  @Input() selectedItem: DocumentDocSalaryAdviceHeader = {};
  @Output() hideModalEvent = new EventEmitter<boolean>();

  // ---------------------
  // Lifecycle Hooks and Observables
  // ---------------------
  onDestroy$ = new Subject<boolean>();
  disableAnimation = false;

  // ---------------------
  // Data Arrays
  // ---------------------
  salaryDocAdvice: DocumentDocSalaryAdviceHeader[] = [];
  EarningsGrid: DocumentDocSalaryAdviceDetail[] = [];
  DeductionsGrid: DocumentDocSalaryAdviceDetail[] = [];
  CompanyContributionsGrid: DocumentDocSalaryAdviceDetail[] = [];
  CompanyContributionsDifferent: DocumentDocSalaryAdviceDetail[] = [];

  // ---------------------
  // Individual Objects
  // ---------------------
  currentPerks: DocumentDocSalaryAdviceDetail = {};
  VerdePayslipAccessControl: DocumentPostVerdePayslipAccessControl = {};
  formatted_periodenddate: Date = new Date();

  // ---------------------
  // URL and UI State
  // ---------------------
  HeaderContentURL: string;
  opened = false;

  constructor(
    private mfAuthService: MfAuthService,
    private documentApiDocumentService: DocumentApiDocumentService,
    private modalService: ModalService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    @Inject('environment') private environment: any,
  ) {}

  ngOnInit(): void {
    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
    this.fetchPayslipData();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  capitalizeFirstLetter(value: string): string {
    if (!value) return value;

    if (!value.includes('.')) {
      const words = value.split(' ');

      const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

      // Join the words back into a string
      const result = capitalizedWords.join(' ');

      return result;
    }
    return value;
  }

  updateAnotherDivHeight() {
    const payrollMain = document.querySelector('.payroll-main') as HTMLElement;
    const payslipInnerContainer = document.querySelector('.payslip-inner-container') as HTMLElement;

    if (payrollMain && payslipInnerContainer) {
      // Get the height of payroll-main as a number (removing 'px')
      const mainDivHeight = parseFloat(getComputedStyle(payrollMain).height);

      // Calculate the new height for payslip-inner-container based on your pattern
      const initialHeight = 770; // Initial height for payslip-inner-container
      const initialMainHeight = 82.43; // Initial height for payroll-main
      const increasePer40px = 40.11; // Increase in payslip-inner-container height for every 40.11px in payroll-main height

      const newHeight = initialHeight + ((mainDivHeight - initialMainHeight) / increasePer40px) * increasePer40px;

      // Calculate the margin-top based on the height
      const baseHeight = 770; // Initial height for margin-top: 0px
      const incrementHeight = 40.11; // Height increment for margin-top increase
      const incrementMarginTop = 40.11; // Margin-top increment for every 40.11px increase in height

      let marginTop = 0;

      if (newHeight >= baseHeight) {
        const heightDifference = newHeight - baseHeight;
        const numberOfIncrements = Math.floor(heightDifference / incrementHeight);
        marginTop = numberOfIncrements * incrementMarginTop;
      }

      // Set the new height and margin-top for payslip-inner-container
      payslipInnerContainer.style.height = `${newHeight}px`;
      payslipInnerContainer.style.marginTop = `${marginTop}px`;
    }
  }

  applyStylesByScreenHeight() {
    const screenHeight = window.innerHeight;

    // Define screen width thresholds and corresponding style changes
    const thresholds = [
      { maxHeight: 1010, payrollMainStyle: { height: '910px', top: '28px' }, payslipInnerContainerStyle: { marginTop: '0px' } },
      { maxHeight: 940, payrollMainStyle: { height: '850px', top: '26px' }, payslipInnerContainerStyle: { marginTop: '0px' } },
      { maxHeight: 880, payrollMainStyle: { height: '780px', top: '26px' }, payslipInnerContainerStyle: { marginTop: '0px' } },
      { maxHeight: 810, payrollMainStyle: { height: '700px', top: '22px' }, payslipInnerContainerStyle: { marginTop: '70px' } },
      { maxHeight: 720, payrollMainStyle: { height: '630px', top: '20px' }, payslipInnerContainerStyle: { marginTop: '160px' } },
      { maxHeight: 650, payrollMainStyle: { height: '580px', top: '20px' }, payslipInnerContainerStyle: { marginTop: '250px' } },
      { maxHeight: 600, payrollMainStyle: { height: '520px', top: '18px' }, payslipInnerContainerStyle: { marginTop: '269px' } },
    ];

    // Apply styles based on screen height
    thresholds.forEach((threshold) => {
      if (screenHeight <= threshold.maxHeight) {
        const payslipTop = document.querySelector('.payslip-inner-container') as HTMLElement;

        if (payslipTop) {
          let payslipTopMarginTop = 0;
          payslipTopMarginTop = parseFloat(getComputedStyle(payslipTop).marginTop);

          payslipTop.style.marginTop = threshold.payslipInnerContainerStyle.marginTop;
        }

        return;
      }
    });
  }

  public close(status: string): void {
    this.opened = false;
    this.hideModalEvent.emit(false);
  }

  public open(): void {
    this.opened = true;

    setTimeout(() => {
      let el = document.getElementById('payslipTop');
      el.scrollIntoView({ behavior: 'auto' });

      // Call the function to update payslip-inner-container's height
      this.updateAnotherDivHeight();
      this.applyStylesByScreenHeight();
    }, 10);
  }

  fetchPayslipData() {
    if (!this.authenticationService.authState$.getValue().isTeamsAuthed) {
      this.mfAuthService.microsoftSignIn();
      this.mfAuthService
        .getSignInSuccess()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((returnObject) => {
          if (returnObject.success) {
            this.documentApiDocumentService
              .decrypt({ body: { header: { bt_docsalaryadviceheaderid: this.selectedItem.bt_docsalaryadviceheaderid } } })
              .pipe(take(1))
              .subscribe(
                (ret) => {
                  this.selectedItem = { ...ret };
                  let [day, month, year] = this.selectedItem.bt_periodenddate.split('/');
                  this.formatted_periodenddate = new Date(`${year}-${month}-${day}`);
                  let childOfParent = this.selectedItem.bt_DocSalaryAdviceDetail_DocSalaryAdviceH;
                  this.EarningsGrid = childOfParent!.filter((t) => t.bt_salaryadvicetype == DocumentBtSalaryAdviceType.Earnings);
                  this.DeductionsGrid = childOfParent!.filter((t) => t.bt_salaryadvicetype == DocumentBtSalaryAdviceType.Deductions);
                  this.CompanyContributionsGrid = childOfParent!.filter(
                    (item) =>
                      item.bt_salaryadvicetype == DocumentBtSalaryAdviceType.CompanyContributions &&
                      item.bt_docsalaryadvicedetail != 'Current Perks' &&
                      item.bt_docsalaryadvicedetail !== 'SKILLS' &&
                      item.bt_docsalaryadvicedetail !== 'U.I.F.',
                  );
                  this.CompanyContributionsDifferent = childOfParent!.filter(
                    (item) =>
                      item.bt_docsalaryadvicedetail === 'SKILLS' ||
                      (item.bt_docsalaryadvicedetail === 'U.I.F.' && item.bt_salaryadvicetype == DocumentBtSalaryAdviceType.CompanyContributions),
                  );
                  this.CompanyContributionsGrid.sort((a, b) => a.bt_docsalaryadvicedetail!.localeCompare(b.bt_docsalaryadvicedetail!));
                  this.currentPerks = childOfParent!.find(
                    (t) => t.bt_salaryadvicetype == DocumentBtSalaryAdviceType.CompanyContributions && t.bt_docsalaryadvicedetail == 'Current Perks',
                  );
                  this.HeaderContentURL = this.selectedItem.headerURL;
                },
                (error) => {
                  this.opened = false;
                  this.hideModalEvent.emit(false);
                },
                () => {
                  this.open();

                  const selectedEmployee = this.selectedItem.bt_employee;
                  const selectedLegalEntity = this.selectedItem.bt_legalentity;
                  const user = this.userService.user;

                  const originalObject = JSON.parse(JSON.stringify(returnObject.data));

                  const LoggedInUser = user.email;

                  const modifiedObject = {
                    userPrincipalName: originalObject.userPrincipalName,
                    emailAddress: LoggedInUser,
                  };

                  const VerdePayslipAccessControl: DocumentPostVerdePayslipAccessControl = {
                    bt_mfaresponse: JSON.stringify(modifiedObject),
                    bt_mfastatus: DocumentBtMfaStatus.Success,
                    bt_payslipforemployee: selectedEmployee.bthr_employeeid,
                    bt_payslipforentity: selectedLegalEntity.bthr_legalentitiesid,
                    bt_requestedby: selectedEmployee.bthr_employeeid === user.employeeId ? selectedEmployee.bthr_employeeid : user.employeeId,
                    bt_requestedbyentity: selectedEmployee.bthr_employeeid === user.employeeId ? selectedLegalEntity.bthr_legalentitiesid : user.legalEntityId,
                  };

                  this.documentApiDocumentService
                    .postVerdePayslipAccessControl({ body: VerdePayslipAccessControl })
                    .pipe(take(1))
                    .subscribe(
                      () => {
                        //
                      },
                      (error) => {
                        console.error(error);
                      },
                      () => {},
                    );
                },
              );
          } else if (!returnObject.success) {
            this.opened = false;
            this.hideModalEvent.emit(false);
          }
        });
      this.mfAuthService
        .getSignInError()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((returnObject) => {
          if (returnObject.fail) {
            this.opened = false;

            const selectedEmployee = this.selectedItem.bt_employee;
            const selectedLegalEntity = this.selectedItem.bt_legalentity;
            const user = this.userService.user;

            const originalObject = JSON.parse(JSON.stringify(returnObject.data));

            const LoggedInUser = user.email;

            const modifiedObject = {
              userPrincipalName: originalObject.userPrincipalName,
              emailAddress: LoggedInUser,
            };

            const VerdePayslipAccessControl: DocumentPostVerdePayslipAccessControl = {
              bt_mfaresponse: JSON.stringify(modifiedObject),
              bt_mfastatus: DocumentBtMfaStatus.Failed,
              bt_payslipforemployee: selectedEmployee.bthr_employeeid,
              bt_payslipforentity: selectedLegalEntity.bthr_legalentitiesid,
              bt_requestedby: selectedEmployee.bthr_employeeid === user.employeeId ? selectedEmployee.bthr_employeeid : user.employeeId,
              bt_requestedbyentity: selectedEmployee.bthr_employeeid === user.employeeId ? selectedLegalEntity.bthr_legalentitiesid : user.legalEntityId,
            };

            this.documentApiDocumentService
              .postVerdePayslipAccessControl({ body: VerdePayslipAccessControl })
              .pipe(take(1))
              .subscribe(
                () => {
                  //
                },
                (error) => {
                  console.error(error);
                },
                () => {},
              );
            this.hideModalEvent.emit(false);
          }
        });
    } else {
      this.modalService.open('navToWebApp');
    }
  }

  SaveThePdf(pdf: PDFExportComponent) {
    pdf.saveAs(this.selectedItem.bt_employeefullname + '.pdf');
  }

  goToWeb() {
    if (this.environment.slot === 'dev') {
      window.open('https://devapp.verde.work/document', '_blank');
    } else if (this.environment.slot === 'uat') {
      window.open('https://uatapp.verde.work/document', '_blank');
    } else if (this.environment.slot === 'vox-uat') {
      window.open('https://devapp.verde.work/document', '_blank');
    } else if (this.environment.slot === 'prod') {
      window.open('https://app.verde.work/', '_blank');
    }

    this.modalService.close('navToWebApp');
  }
}
