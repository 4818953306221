import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TimeOffActions, TimeOffActionTypes } from '../actions/timeoff.actions';
import * as fromDashboardRelatedReducer from './dashboard-related.reducer';

// Models
import { EmployeeTimeOffCycleSummary } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type TimeOffState = EntityState<EmployeeTimeOffCycleSummary>;

export interface TimeOffRelatedState {
  timeOffState: TimeOffState;
  loading: boolean;
  error: any;
}

export const timeOffKeys = ['bt_employeetimeoffcyclesummaryid'];
export const timeOffStateAdapter: EntityAdapter<EmployeeTimeOffCycleSummary> = createEntityAdapter<EmployeeTimeOffCycleSummary>({
  selectId: (data) => keyHandler(data, timeOffKeys),
});

export const initialTimeOffState: TimeOffState = timeOffStateAdapter.getInitialState({});

export const initialTimeOffRelatedState = {
  timeOffState: initialTimeOffState,
  loading: false,
  error: {},
};

export function timeOffStateRelatedReducer(state = initialTimeOffRelatedState, action: TimeOffActions): TimeOffRelatedState {
  switch (action.type) {
    // Get All TimeOff
    case TimeOffActionTypes.TIMEOFF_GET_ALL:
      return { ...state, loading: true };
    case TimeOffActionTypes.TIMEOFF_GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        timeOffState: timeOffStateAdapter.setAll(action.payload, state.timeOffState),
      };
    }
    case TimeOffActionTypes.TIMEOFF_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Clear
    case TimeOffActionTypes.TIMEOFF_CLEAR:
      return { ...initialTimeOffRelatedState };
    default:
      return state;
  }
}

export const getHomeRelatedState = createFeatureSelector<fromDashboardRelatedReducer.DashboardRelatedState>('dashboardRelatedReducer');

export const getTimeOffRelatedState = createSelector(getHomeRelatedState, (state: fromDashboardRelatedReducer.DashboardRelatedState) => state.timeOffState);

// Current TimeOff
export const getTimeOffState = createSelector(getTimeOffRelatedState, (state) => state.timeOffState);
export const { selectAll: getTimeOff } = timeOffStateAdapter.getSelectors(getTimeOffState);

// Loading
export const isLoading = createSelector(getTimeOffRelatedState, (state) => state.loading);

// Error
export const getError = createSelector(getTimeOffRelatedState, (state) => state.error);
