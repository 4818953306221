/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GiftSummary } from '../models/gift-summary';
import { DvGifts } from '../models/dv-gifts';

@Injectable({
  providedIn: 'root',
})
export class SharedApiGiftsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllGifts
   */
  static readonly GetAllGiftsPath = '/api/Gifts/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllGifts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllGifts$Response(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<StrictHttpResponse<Array<DvGifts>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiGiftsService.GetAllGiftsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvGifts>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllGifts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllGifts(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<DvGifts>> {
    return this.getAllGifts$Response(params).pipe(map((r: StrictHttpResponse<Array<DvGifts>>) => r.body as Array<DvGifts>));
  }

  /**
   * Path part for operation patchGifts
   */
  static readonly PatchGiftsPath = '/api/Gifts/patch/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchGifts()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchGifts$Response(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: DvGifts }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiGiftsService.PatchGiftsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchGifts$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchGifts(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: DvGifts }): Observable<boolean> {
    return this.patchGifts$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postGift
   */
  static readonly PostGiftPath = '/api/Gifts/post';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postGift()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postGift$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: DvGifts }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiGiftsService.PostGiftPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postGift$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postGift(params?: { subscriptionKey?: string; APIURL?: string; body?: DvGifts }): Observable<boolean> {
    return this.postGift$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getAllGiftSummaries
   */
  static readonly GetAllGiftSummariesPath = '/api/Gifts/summaries/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllGiftSummaries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllGiftSummaries$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<GiftSummary>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiGiftsService.GetAllGiftSummariesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<GiftSummary>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllGiftSummaries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllGiftSummaries(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<GiftSummary>> {
    return this.getAllGiftSummaries$Response(params).pipe(map((r: StrictHttpResponse<Array<GiftSummary>>) => r.body as Array<GiftSummary>));
  }
}
