import { Action } from '@ngrx/store';
import { StructureStructureFull } from '@verde/api';
import { StructureFullExtended } from '../models/structure-full-extended.model';

export enum StructureActionTypes {
  // Get All Structure
  STRUCTURE_GET_ALL = '[Structure] Get All',
  STRUCTURE_GET_ALL_SUCCESS = '[Structure] Get All Success',
  STRUCTURE_GET_ALL_ERROR = '[Structure] Get All Error',
  // Structure Add
  STRUCTURE_ADD = '[Structure] Add',
  STRUCTURE_ADD_SUCCESS = '[Structure] Add Success',
  // Structure Update
  STRUCTURE_UPDATE = '[Structure] Update',
  STRUCTURE_UPDATE_SUCCESS = '[Structure] Update Success',
  // Structure Dispose
  STRUCTURE_DISPOSE = '[Structure] Dispose',
  STRUCTURE_DISPOSE_SUCCESS = '[Structure] Dispose Success',
  // Structure Delete
  STRUCTURE_DELETE = '[Structure] Delete',
  STRUCTURE_DELETE_SUCCESS = '[Structure] Delete Success',
  // Structure Selected
  STRUCTURE_PARENT_SELECT = '[Structure] Parent Select',
  STRUCTURE_PARENT_DESELECT = '[Structure] Parent Deselect',
  // Structure Undo One
  STRUCTURE_UNDO_ONE = '[Structure] Undo One',
  STRUCTURE_UNDO_ONE_SUCCESS = '[Structure] Undo One Success',
  // Structure Undo
  STRUCTURE_UNDO = '[Structure] Undo',
  STRUCTURE_UNDO_SUCCESS = '[Structure] Undo Success',
  // Clear All Structure
  STRUCTURE_CLEAR = '[Structure] Clear',
}

// Get All Structure
export class StructureGetAll implements Action {
  readonly type = StructureActionTypes.STRUCTURE_GET_ALL;
  constructor(public legalEntityID: string, public structureAdmin: boolean, public managerID: string) {}
}

export class StructureGetAllSuccess implements Action {
  readonly type = StructureActionTypes.STRUCTURE_GET_ALL_SUCCESS;
  constructor(public payload: StructureStructureFull[]) {}
}

export class StructureGetAllError implements Action {
  readonly type = StructureActionTypes.STRUCTURE_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// Structure Add
export class StructureAdd implements Action {
  readonly type = StructureActionTypes.STRUCTURE_ADD;
  constructor(public payload: StructureFullExtended[]) {}
}

export class StructureAddSuccess implements Action {
  readonly type = StructureActionTypes.STRUCTURE_ADD_SUCCESS;
  constructor(public payload: StructureFullExtended[]) {}
}

// Structure Update
export class StructureUpdate implements Action {
  readonly type = StructureActionTypes.STRUCTURE_UPDATE;
  constructor(public payload: StructureFullExtended[]) {}
}

export class StructureUpdateSuccess implements Action {
  readonly type = StructureActionTypes.STRUCTURE_UPDATE_SUCCESS;
  constructor(public payload: StructureFullExtended[]) {}
}

// Structure Dispose
export class StructureDispose implements Action {
  readonly type = StructureActionTypes.STRUCTURE_DISPOSE;
  constructor(public payload: StructureFullExtended[]) {}
}

export class StructureDisposeSuccess implements Action {
  readonly type = StructureActionTypes.STRUCTURE_DISPOSE_SUCCESS;
  constructor(public payload: StructureFullExtended[]) {}
}

// Structure Delete
export class StructureDelete implements Action {
  readonly type = StructureActionTypes.STRUCTURE_DELETE;
  constructor(public payload: StructureFullExtended[]) {}
}

export class StructureDeleteSuccess implements Action {
  readonly type = StructureActionTypes.STRUCTURE_DELETE_SUCCESS;
  constructor(public payload: StructureFullExtended[]) {}
}

// Structure Parent Selected
export class StructureParentSelect implements Action {
  readonly type = StructureActionTypes.STRUCTURE_PARENT_SELECT;
  constructor(public payload: string) {}
}

export class StructureParentDeselect implements Action {
  readonly type = StructureActionTypes.STRUCTURE_PARENT_DESELECT;
  constructor(public payload: any) {}
}

// Structure Undo
export class StructureUndoOne implements Action {
  readonly type = StructureActionTypes.STRUCTURE_UNDO_ONE;
  constructor(public payload: StructureFullExtended) {}
}

export class StructureUndoOneSuccess implements Action {
  readonly type = StructureActionTypes.STRUCTURE_UNDO_ONE_SUCCESS;
  constructor(public payload: StructureFullExtended) {}
}

// Structure Undo
export class StructureUndo implements Action {
  readonly type = StructureActionTypes.STRUCTURE_UNDO;
  constructor(public payload: any) {}
}

export class StructureUndoSuccess implements Action {
  readonly type = StructureActionTypes.STRUCTURE_UNDO_SUCCESS;
  constructor(public payload: any) {}
}

// Clear All Structure
export class StructureClear implements Action {
  readonly type = StructureActionTypes.STRUCTURE_CLEAR;
  constructor(public payload: any) {}
}

export type StructureActions =
  // Get All Structure
  | StructureGetAll
  | StructureGetAllSuccess
  | StructureGetAllError
  // Structure Add
  | StructureAdd
  | StructureAddSuccess
  // Structure Update
  | StructureUpdate
  | StructureUpdateSuccess
  // Structure Dispose
  | StructureDispose
  | StructureDisposeSuccess
  // Structure Delete
  | StructureDelete
  | StructureDeleteSuccess
  // Structure Parent Selected
  | StructureParentSelect
  | StructureParentDeselect
  // Structure Undo One
  | StructureUndoOne
  | StructureUndoOneSuccess
  // Structure Undo
  | StructureUndo
  | StructureUndoSuccess
  // Clear All Structure
  | StructureClear;
