/* tslint:disable */
/* eslint-disable */
import { BtFleetItemMovementHistory } from './bt-fleet-item-movement-history';
import { FleetMovement } from './fleet-movement';
import { User } from './user';
export interface FleetItemMovementHistory {
  _bt_employee_value?: null | string;
  _bt_legalentity_value?: null | string;
  bt_Employee?: User;
  bt_FleetItem?: BtFleetItemMovementHistory;
  bt_FleetMovement?: FleetMovement;
  bt_classification?: number;
  bt_fleetcardapplicationid?: null | string;
  bt_fleetcardapplicationno?: null | string;
  bt_transactiondate?: string;
  bt_transactiontype?: number;
  odataClassification?: null | string;
  odataEmployee?: null | string;
  odataLegalEntity?: null | string;
  odataTransactionType?: null | string;
}
