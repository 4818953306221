/* tslint:disable */
/* eslint-disable */
export interface VoteGroupVoting {
  _bt_cycle_value?: null | string;
  _bt_groupcycle_value?: null | string;
  _bt_groupperiod_value?: null | string;
  _bt_period_value?: null | string;
  bt_closingdate?: null | string;
  bt_groupvotingstage?: null | number;
  bt_groupvotingstatus?: null | number;
  bt_votegroupvoting?: null | string;
  bt_votegroupvotingid?: null | string;
  odataVotingStage?: null | string;
  odataVotingStatus?: null | string;
}
