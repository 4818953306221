/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PeerReviewFeedback } from '../models/peer-review-feedback';
import { PeerReviewFeedbackSummary } from '../models/peer-review-feedback-summary';

@Injectable({
  providedIn: 'root',
})
export class SharedApiPeerReviewService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllPeerReviewFeedback
   */
  static readonly GetAllPeerReviewFeedbackPath = '/api/PeerReview/all/{responderID}/{employeeID}/{legalEntityID}/{templateID}/{cycleID}/{periodID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPeerReviewFeedback()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPeerReviewFeedback$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    responderID: string;
    employeeID: string;
    legalEntityID: string;
    templateID: string;
    cycleID: string;
    periodID: string;
  }): Observable<StrictHttpResponse<Array<PeerReviewFeedback>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiPeerReviewService.GetAllPeerReviewFeedbackPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('responderID', params.responderID, {});
      rb.path('employeeID', params.employeeID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
      rb.path('templateID', params.templateID, {});
      rb.path('cycleID', params.cycleID, {});
      rb.path('periodID', params.periodID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PeerReviewFeedback>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPeerReviewFeedback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPeerReviewFeedback(params: {
    subscriptionKey?: string;
    APIURL?: string;
    responderID: string;
    employeeID: string;
    legalEntityID: string;
    templateID: string;
    cycleID: string;
    periodID: string;
  }): Observable<Array<PeerReviewFeedback>> {
    return this.getAllPeerReviewFeedback$Response(params).pipe(map((r: StrictHttpResponse<Array<PeerReviewFeedback>>) => r.body as Array<PeerReviewFeedback>));
  }

  /**
   * Path part for operation getAllPeerReviewFeedbackSummaries
   */
  static readonly GetAllPeerReviewFeedbackSummariesPath = '/api/PeerReview/summaries/{responderID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPeerReviewFeedbackSummaries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPeerReviewFeedbackSummaries$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    responderID: string;
  }): Observable<StrictHttpResponse<Array<PeerReviewFeedbackSummary>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiPeerReviewService.GetAllPeerReviewFeedbackSummariesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('responderID', params.responderID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<PeerReviewFeedbackSummary>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPeerReviewFeedbackSummaries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPeerReviewFeedbackSummaries(params: { subscriptionKey?: string; APIURL?: string; responderID: string }): Observable<Array<PeerReviewFeedbackSummary>> {
    return this.getAllPeerReviewFeedbackSummaries$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PeerReviewFeedbackSummary>>) => r.body as Array<PeerReviewFeedbackSummary>),
    );
  }

  /**
   * Path part for operation patchPeerReviewFeedback
   */
  static readonly PatchPeerReviewFeedbackPath = '/api/PeerReview/patch/feedback';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPeerReviewFeedback()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPeerReviewFeedback$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: PeerReviewFeedback }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiPeerReviewService.PatchPeerReviewFeedbackPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchPeerReviewFeedback$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPeerReviewFeedback(params?: { subscriptionKey?: string; APIURL?: string; body?: PeerReviewFeedback }): Observable<boolean> {
    return this.patchPeerReviewFeedback$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation patchPeerReviewFeedbackSummary
   */
  static readonly PatchPeerReviewFeedbackSummaryPath = '/api/PeerReview/patch/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPeerReviewFeedbackSummary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPeerReviewFeedbackSummary$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: PeerReviewFeedbackSummary;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiPeerReviewService.PatchPeerReviewFeedbackSummaryPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchPeerReviewFeedbackSummary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchPeerReviewFeedbackSummary(params?: { subscriptionKey?: string; APIURL?: string; body?: PeerReviewFeedbackSummary }): Observable<boolean> {
    return this.patchPeerReviewFeedbackSummary$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
