/* tslint:disable */
/* eslint-disable */
export interface BookingsGetBookingAvailabilitiesFilteredDto {
  bookingItem?: null | string;
  bookingItemsId?: null | string;
  employeeId?: null | string;
  fromDate?: null | string;
  fullName?: null | string;
  isBooked?: boolean;
  toDate?: null | string;
}
