import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  BFFApiDocumentQueueService,
  DocumentApiDocumentService,
  DocumentBtIntegrationType,
  DocumentBtProcessStatus,
  DocumentDocTypeFinancial,
  DocumentLegalEntityDto,
  DocumentVerdeIntegrationSource,
} from '@verde/api';
import { DeviceTypeService, UserService } from '@verde/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take } from 'rxjs';
import { VerdeApprovalService } from '../../services/verde-approval.service';

@Component({
  selector: 'verde-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss'],
})
export class BulkUploadComponent implements OnInit {
  onDestroy$: Subject<any> = new Subject();
  file1: File[] | null = null;
  legalEntities: Array<DocumentLegalEntityDto> = new Array<DocumentLegalEntityDto>();
  integrationSource: Array<DocumentVerdeIntegrationSource> = new Array<DocumentVerdeIntegrationSource>();
  docType: Array<DocumentDocTypeFinancial> = new Array<DocumentDocTypeFinancial>();
  showConfirmationModal: boolean = false;
  uploadStatusMessage: string = 'Uploading Files...';
  titleUploadStatusMessage: string = 'Upload in Progress.';
  cannotContinue: boolean = true;
  disableAnimation: any;
  currentStep: number = 1;
  fb: FormBuilder;
  fg: FormGroup;

  constructor(
    private sidebarService: VerdeApprovalService,
    public deviceTypeService: DeviceTypeService,
    private documentApiDocumentService: DocumentApiDocumentService,
    private bffApiDocumentQueueService: BFFApiDocumentQueueService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.fb = new FormBuilder();
    this.resetForm();
    this.spinner.show('LoadingData');
    this.cannotContinue = true;
    this.GetDocType();
    this.GetIntegrationSource();
  }

  resetForm() {
    this.fg = this.fb.group({
      legalEntityDropdown: [, Validators.required],
      docTypeDropdown: [, Validators.required],
      fileUpload: [, Validators.required],
    });
  }

  isFormValid(): boolean {
    return this.fg.valid;
  }

  GetIntegrationSource() {
    this.documentApiDocumentService
      .getIntegrationSource({ body: { dataverseUrl: '' } })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.integrationSource = ret.filter(
            (t) =>
              t.bt_integrationtype == DocumentBtIntegrationType.FileImport &&
              t.bt_legalentity.bthr_legalentitiesid == this.userService.user.legalEntityId &&
              t.bt_verdeintegrationsource == 'BULK',
          );
        },
        (error) => {
          console.error(error);
        },
        () => {},
      );
  }

  GetLegalEntites() {
    this.documentApiDocumentService
      .getLegalEntity({ body: { dataverseUrl: '' } })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.legalEntities = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.spinner.hide('LoadingData');
        },
      );
  }

  GetDocType() {
    this.documentApiDocumentService
      .getDocTypeFinancial({ body: { dataverseUrl: '' } })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          this.docType = ret.filter((t) => t.bt_allowbulkupload);
          if (this.docType.length == 0) {
            this.titleUploadStatusMessage = 'Setup Required';
            this.uploadStatusMessage = 'Before doing Bulk Uploads, Please set it up on the Admin page.';
            this.showConfirmationModal = true;
            this.cannotContinue = false;
          }
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.GetLegalEntites();
        },
      );
  }

  uploadFiles() {
    this.showConfirmationModal = true;
    this.spinner.show('uploadSpinner');
    let recordId: string;
    this.documentApiDocumentService
      .postVerdeBulkControl({
        body: {
          bt_documenttype: this.fg.value.docTypeDropdown.bt_doctypefinancialid,
          bt_importbyentity: this.userService.user.legalEntityId,
          bt_importedby: this.userService.user.employeeId,
          bt_legalentity: this.fg.value.legalEntityDropdown.legalEntityId,
          bt_processcomment: '',
          bt_status: DocumentBtProcessStatus.Processing,
        },
      })
      .subscribe(
        (ret) => {
          const files = this.fg.value.fileUpload;
          const batchSize = 50;
          const totalBatches = Math.ceil(files.length / batchSize);
          let batchesCompleted = 0;
          for (let i = 0; i < files.length; i += batchSize) {
            const batch = files.slice(i, i + batchSize);
            recordId = ret;
            this.bffApiDocumentQueueService
              .documentQueueControllerUploadFiles({
                body: {
                  files: batch,
                  docType: this.fg.value.docTypeDropdown.bt_doctypefinancialid,
                  integrationSource: this.integrationSource[0].guid,
                  importControl: ret,
                  grouping: this.fg.value.docTypeDropdown.documentGrouping,
                  tenantId: '',
                },
              })
              .subscribe(
                (ret) => {
                  batchesCompleted++;

                  this.titleUploadStatusMessage = 'Upload in Progress. Batch ' + batchesCompleted + ' of ' + totalBatches + ' .';
                  if (batchesCompleted === totalBatches) {
                    this.spinner.hide('uploadSpinner');
                    this.titleUploadStatusMessage = 'File Upload Complete';
                    this.uploadStatusMessage =
                      "Great news! Your files have been successfully uploaded. You're all set to continue using the app while we take care of the rest behind the scenes.";
                    this.cannotContinue = false;
                  }
                },
                (error) => {
                  i = files.length + 1;
                  this.spinner.hide('uploadSpinner');
                  this.titleUploadStatusMessage = 'File Upload Error';
                  this.uploadStatusMessage = 'There was an error. Please check the control table for more details.';
                  this.cannotContinue = false;

                  this.documentApiDocumentService
                    .postVerdeBulkControl({
                      body: {
                        bt_documenttype: this.fg.value.docTypeDropdown.bt_doctypefinancialid,
                        bt_importbyentity: this.userService.user.legalEntityId,
                        bt_importedby: this.userService.user.employeeId,
                        bt_legalentity: this.fg.value.legalEntityDropdown.legalEntityId,
                        bt_processcomment: 'Document Upload error: ' + error,
                        bt_status: DocumentBtProcessStatus.Cancelled,
                        recordId: recordId,
                      },
                    })
                    .subscribe(() => {
                      //
                    });
                },
                () => {},
              );
          }
        },
        (error) => {
          console.error(error);
          this.spinner.hide('uploadSpinner');
          this.titleUploadStatusMessage = 'Control table error.';
          this.uploadStatusMessage = 'There was an error. Please check the control table for more details.';
          this.cannotContinue = false;
          console.error(error);

          this.documentApiDocumentService
            .postVerdeBulkControl({
              body: {
                bt_documenttype: this.fg.value.docTypeDropdown.bt_doctypefinancialid,
                bt_importbyentity: this.userService.user.legalEntityId,
                bt_importedby: this.userService.user.employeeId,
                bt_legalentity: this.fg.value.legalEntityDropdown.legalEntityId,
                bt_processcomment: 'Control table error :' + error,
                bt_status: DocumentBtProcessStatus.Cancelled,
              },
            })
            .subscribe(() => {
              //
            });
        },
        () => {},
      );
  }

  closeConfirmationModal() {
    this.showConfirmationModal = false;
    this.sidebarService.setShowSidebar(false);
  }
}
