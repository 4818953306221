/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { StructureChangeRequest } from '../models/structure-change-request';
import { StructureDepartments } from '../models/structure-departments';
import { StructureDivisions } from '../models/structure-divisions';
import { StructureFull } from '../models/structure-full';
import { StructureLegalEntities } from '../models/structure-legal-entities';
import { VerdePositions } from '../models/verde-positions';

@Injectable({
  providedIn: 'root',
})
export class SharedApiStructureService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation structureGetAllDepartments
   */
  static readonly StructureGetAllDepartmentsPath = '/api/Structure/structure_departments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structureGetAllDepartments()` instead.
   *
   * This method doesn't expect any request body.
   */
  structureGetAllDepartments$Response(params?: { subscriptionKey?: string; APIURL?: string }): Observable<StrictHttpResponse<Array<StructureDepartments>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiStructureService.StructureGetAllDepartmentsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<StructureDepartments>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structureGetAllDepartments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structureGetAllDepartments(params?: { subscriptionKey?: string; APIURL?: string }): Observable<Array<StructureDepartments>> {
    return this.structureGetAllDepartments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StructureDepartments>>) => r.body as Array<StructureDepartments>),
    );
  }

  /**
   * Path part for operation structureGetAllDivisions
   */
  static readonly StructureGetAllDivisionsPath = '/api/Structure/structure_divisions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structureGetAllDivisions()` instead.
   *
   * This method doesn't expect any request body.
   */
  structureGetAllDivisions$Response(params?: { subscriptionKey?: string; APIURL?: string }): Observable<StrictHttpResponse<Array<StructureDivisions>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiStructureService.StructureGetAllDivisionsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<StructureDivisions>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structureGetAllDivisions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structureGetAllDivisions(params?: { subscriptionKey?: string; APIURL?: string }): Observable<Array<StructureDivisions>> {
    return this.structureGetAllDivisions$Response(params).pipe(map((r: StrictHttpResponse<Array<StructureDivisions>>) => r.body as Array<StructureDivisions>));
  }

  /**
   * Path part for operation structureGetAllLegalEntities
   */
  static readonly StructureGetAllLegalEntitiesPath = '/api/Structure/structure_legal_entities';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `structureGetAllLegalEntities()` instead.
   *
   * This method doesn't expect any request body.
   */
  structureGetAllLegalEntities$Response(params?: { subscriptionKey?: string; APIURL?: string }): Observable<StrictHttpResponse<Array<StructureLegalEntities>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiStructureService.StructureGetAllLegalEntitiesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<StructureLegalEntities>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `structureGetAllLegalEntities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  structureGetAllLegalEntities(params?: { subscriptionKey?: string; APIURL?: string }): Observable<Array<StructureLegalEntities>> {
    return this.structureGetAllLegalEntities$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StructureLegalEntities>>) => r.body as Array<StructureLegalEntities>),
    );
  }

  /**
   * Path part for operation getFullStructure
   */
  static readonly GetFullStructurePath = '/api/Structure/get_full_structure';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFullStructure()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullStructure$Response(params?: { subscriptionKey?: string; APIURL?: string }): Observable<StrictHttpResponse<Array<StructureFull>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiStructureService.GetFullStructurePath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<StructureFull>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFullStructure$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullStructure(params?: { subscriptionKey?: string; APIURL?: string }): Observable<Array<StructureFull>> {
    return this.getFullStructure$Response(params).pipe(map((r: StrictHttpResponse<Array<StructureFull>>) => r.body as Array<StructureFull>));
  }

  /**
   * Path part for operation createStructureChangeRequests
   */
  static readonly CreateStructureChangeRequestsPath = '/api/Structure/create_changerequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createStructureChangeRequests()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createStructureChangeRequests$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: StructureChangeRequest;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiStructureService.CreateStructureChangeRequestsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createStructureChangeRequests$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createStructureChangeRequests(params?: { subscriptionKey?: string; APIURL?: string; body?: StructureChangeRequest }): Observable<string> {
    return this.createStructureChangeRequests$Response(params).pipe(map((r: StrictHttpResponse<string>) => r.body as string));
  }

  /**
   * Path part for operation getStructureChangeRequests
   */
  static readonly GetStructureChangeRequestsPath = '/api/Structure/structurechangerequests/{userID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStructureChangeRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStructureChangeRequests$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<StructureChangeRequest>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiStructureService.GetStructureChangeRequestsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userID', params.userID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<StructureChangeRequest>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStructureChangeRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStructureChangeRequests(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userID: string;
    legalEntityID: string;
  }): Observable<Array<StructureChangeRequest>> {
    return this.getStructureChangeRequests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StructureChangeRequest>>) => r.body as Array<StructureChangeRequest>),
    );
  }

  /**
   * Path part for operation getVerdePositions
   */
  static readonly GetVerdePositionsPath = '/api/Structure/verdepositions/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerdePositions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdePositions$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<VerdePositions>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiStructureService.GetVerdePositionsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<VerdePositions>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVerdePositions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerdePositions(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<VerdePositions>> {
    return this.getVerdePositions$Response(params).pipe(map((r: StrictHttpResponse<Array<VerdePositions>>) => r.body as Array<VerdePositions>));
  }
}
