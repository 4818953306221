/* tslint:disable */
/* eslint-disable */
export interface BookingsGetBookingAvailabilitiesQuery {
  allBookings?: null | boolean;
  bookingTypeId?: string;
  fromDate?: null | string;
  includeWeekends?: null | boolean;
  isRecurring?: null | boolean;
  legalEntityId?: string;
  numberOfDays?: null | number;
  officeId?: string;
  primaryLocationId?: string;
  subOfficeId?: string;
  toDate?: null | string;
}
