/* tslint:disable */
/* eslint-disable */
import { User } from './user';
export interface ApprovalLogsAndApprovers {
  _bt_approver_value?: null | string;
  _bt_approverlegalentity_value?: null | string;
  _bt_employee_value?: null | string;
  bt_Employee?: User;
  bt_approvaldate?: null | string;
  bt_approvalstatus?: number;
  bt_comment?: null | string;
}
