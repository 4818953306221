/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvMemberships } from '../models/dv-memberships';
import { DvMembershipsPost } from '../models/dv-memberships-post';

@Injectable({
  providedIn: 'root',
})
export class SharedApiMembershipsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllMemberships
   */
  static readonly GetAllMembershipsPath = '/api/Memberships/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllMemberships()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMemberships$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvMemberships>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiMembershipsService.GetAllMembershipsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvMemberships>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllMemberships$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllMemberships(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<DvMemberships>> {
    return this.getAllMemberships$Response(params).pipe(map((r: StrictHttpResponse<Array<DvMemberships>>) => r.body as Array<DvMemberships>));
  }

  /**
   * Path part for operation patchMemberships
   */
  static readonly PatchMembershipsPath = '/api/Memberships/patch/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchMemberships()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchMemberships$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvMembershipsPost;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiMembershipsService.PatchMembershipsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchMemberships$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchMemberships(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: DvMembershipsPost }): Observable<boolean> {
    return this.patchMemberships$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postMemberships
   */
  static readonly PostMembershipsPath = '/api/Memberships/post/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postMemberships()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postMemberships$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvMembershipsPost;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiMembershipsService.PostMembershipsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postMemberships$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postMemberships(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: DvMembershipsPost }): Observable<boolean> {
    return this.postMemberships$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
