/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { QualificationsBaseService } from '../qualifications-base-service';
import { QualificationsApiConfiguration } from '../qualifications-api-configuration';
import { QualificationsStrictHttpResponse } from '../qualifications-strict-http-response';
import { QualificationsRequestBuilder } from '../qualifications-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { QualificationsBtEmployeeEducationalQualificationRenewal } from '../models/qualifications-bt-employee-educational-qualification-renewal';
import { QualificationsBtQualficationInstitutionLink } from '../models/qualifications-bt-qualfication-institution-link';
import { QualificationsBthrAccreditationAuthority } from '../models/qualifications-bthr-accreditation-authority';
import { QualificationsBthrCountry } from '../models/qualifications-bthr-country';
import { QualificationsBthrEducationalInstitution } from '../models/qualifications-bthr-educational-institution';
import { QualificationsBthrEmployeeEducationalQualification } from '../models/qualifications-bthr-employee-educational-qualification';
import { QualificationsBthrQualification } from '../models/qualifications-bthr-qualification';
import { QualificationsBthrQualificationQueryResult } from '../models/qualifications-bthr-qualification-query-result';
import { QualificationsCreateEducationalInstitutionCommand } from '../models/qualifications-create-educational-institution-command';
import { QualificationsCreateQualficationInstitutionLinkCommand } from '../models/qualifications-create-qualfication-institution-link-command';
import { QualificationsCreateQualificationCommand } from '../models/qualifications-create-qualification-command';
import { QualificationsGetAccreditationAuthorityQuery } from '../models/qualifications-get-accreditation-authority-query';
import { QualificationsGetCountriesQuery } from '../models/qualifications-get-countries-query';
import { QualificationsGetEducationalInstitutionQuery } from '../models/qualifications-get-educational-institution-query';
import { QualificationsGetEmployeeEducationalQualificationQuery } from '../models/qualifications-get-employee-educational-qualification-query';
import { QualificationsGetEmployeeEducationalQualificationRenewalQuery } from '../models/qualifications-get-employee-educational-qualification-renewal-query';
import { QualificationsGetQualficationInstitutionLinkQuery } from '../models/qualifications-get-qualfication-institution-link-query';
import { QualificationsGetQualificationQuery } from '../models/qualifications-get-qualification-query';
import { QualificationsGetReducedEducationalInstitutionQuery } from '../models/qualifications-get-reduced-educational-institution-query';
import { QualificationsGetReducedQualificationQuery } from '../models/qualifications-get-reduced-qualification-query';
import { QualificationsBtQualificationStatus } from '../models/qualifications-bt-qualification-status';
import { QualificationsBtValidationType } from '../models/qualifications-bt-validation-type';
import { QualificationsBthrQualificationType } from '../models/qualifications-bthr-qualification-type';
import { QualificationsBthrRecordStatus } from '../models/qualifications-bthr-record-status';

@Injectable({
  providedIn: 'root',
})
export class QualificationsApiQualificationsService extends QualificationsBaseService {
  constructor(config: QualificationsApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getCountries
   */
  static readonly GetCountriesPath = '/api/Qualifications/countries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountries()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCountries$Response(params?: {
    tenantId?: string;
    body?: QualificationsGetCountriesQuery;
  }): Observable<QualificationsStrictHttpResponse<Array<QualificationsBthrCountry>>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.GetCountriesPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as QualificationsStrictHttpResponse<Array<QualificationsBthrCountry>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCountries$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getCountries(params?: { tenantId?: string; body?: QualificationsGetCountriesQuery }): Observable<Array<QualificationsBthrCountry>> {
    return this.getCountries$Response(params).pipe(
      map((r: QualificationsStrictHttpResponse<Array<QualificationsBthrCountry>>) => r.body as Array<QualificationsBthrCountry>),
    );
  }

  /**
   * Path part for operation getEducationalInstitution
   */
  static readonly GetEducationalInstitutionPath = '/api/Qualifications/educationalInstitution';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEducationalInstitution()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEducationalInstitution$Response(params?: {
    tenantId?: string;
    body?: QualificationsGetEducationalInstitutionQuery;
  }): Observable<QualificationsStrictHttpResponse<Array<QualificationsBthrEducationalInstitution>>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.GetEducationalInstitutionPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as QualificationsStrictHttpResponse<Array<QualificationsBthrEducationalInstitution>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEducationalInstitution$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEducationalInstitution(params?: {
    tenantId?: string;
    body?: QualificationsGetEducationalInstitutionQuery;
  }): Observable<Array<QualificationsBthrEducationalInstitution>> {
    return this.getEducationalInstitution$Response(params).pipe(
      map((r: QualificationsStrictHttpResponse<Array<QualificationsBthrEducationalInstitution>>) => r.body as Array<QualificationsBthrEducationalInstitution>),
    );
  }

  /**
   * Path part for operation getEmployeeEducationalQualification
   */
  static readonly GetEmployeeEducationalQualificationPath = '/api/Qualifications/employeeEducationalQualification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeEducationalQualification()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeEducationalQualification$Response(params?: {
    tenantId?: string;
    body?: QualificationsGetEmployeeEducationalQualificationQuery;
  }): Observable<QualificationsStrictHttpResponse<Array<QualificationsBthrEmployeeEducationalQualification>>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.GetEmployeeEducationalQualificationPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as QualificationsStrictHttpResponse<Array<QualificationsBthrEmployeeEducationalQualification>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeEducationalQualification$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeEducationalQualification(params?: {
    tenantId?: string;
    body?: QualificationsGetEmployeeEducationalQualificationQuery;
  }): Observable<Array<QualificationsBthrEmployeeEducationalQualification>> {
    return this.getEmployeeEducationalQualification$Response(params).pipe(
      map(
        (r: QualificationsStrictHttpResponse<Array<QualificationsBthrEmployeeEducationalQualification>>) =>
          r.body as Array<QualificationsBthrEmployeeEducationalQualification>,
      ),
    );
  }

  /**
   * Path part for operation getEmployeeEducationalQualificationRenewals
   */
  static readonly GetEmployeeEducationalQualificationRenewalsPath = '/api/Qualifications/employeeEducationalQualificationRenewals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmployeeEducationalQualificationRenewals()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeEducationalQualificationRenewals$Response(params?: {
    tenantId?: string;
    body?: QualificationsGetEmployeeEducationalQualificationRenewalQuery;
  }): Observable<QualificationsStrictHttpResponse<Array<QualificationsBtEmployeeEducationalQualificationRenewal>>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.GetEmployeeEducationalQualificationRenewalsPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as QualificationsStrictHttpResponse<Array<QualificationsBtEmployeeEducationalQualificationRenewal>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmployeeEducationalQualificationRenewals$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getEmployeeEducationalQualificationRenewals(params?: {
    tenantId?: string;
    body?: QualificationsGetEmployeeEducationalQualificationRenewalQuery;
  }): Observable<Array<QualificationsBtEmployeeEducationalQualificationRenewal>> {
    return this.getEmployeeEducationalQualificationRenewals$Response(params).pipe(
      map(
        (r: QualificationsStrictHttpResponse<Array<QualificationsBtEmployeeEducationalQualificationRenewal>>) =>
          r.body as Array<QualificationsBtEmployeeEducationalQualificationRenewal>,
      ),
    );
  }

  /**
   * Path part for operation getQualification
   */
  static readonly GetQualificationPath = '/api/Qualifications/qualification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQualification()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getQualification$Response(params?: {
    tenantId?: string;
    body?: QualificationsGetQualificationQuery;
  }): Observable<QualificationsStrictHttpResponse<QualificationsBthrQualificationQueryResult>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.GetQualificationPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as QualificationsStrictHttpResponse<QualificationsBthrQualificationQueryResult>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQualification$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getQualification(params?: { tenantId?: string; body?: QualificationsGetQualificationQuery }): Observable<QualificationsBthrQualificationQueryResult> {
    return this.getQualification$Response(params).pipe(
      map((r: QualificationsStrictHttpResponse<QualificationsBthrQualificationQueryResult>) => r.body as QualificationsBthrQualificationQueryResult),
    );
  }

  /**
   * Path part for operation getReducedEducationalInstitution
   */
  static readonly GetReducedEducationalInstitutionPath = '/api/Qualifications/reducedEducationalInstitution';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReducedEducationalInstitution()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getReducedEducationalInstitution$Response(params?: {
    tenantId?: string;
    body?: QualificationsGetReducedEducationalInstitutionQuery;
  }): Observable<QualificationsStrictHttpResponse<Array<QualificationsBthrEducationalInstitution>>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.GetReducedEducationalInstitutionPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as QualificationsStrictHttpResponse<Array<QualificationsBthrEducationalInstitution>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReducedEducationalInstitution$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getReducedEducationalInstitution(params?: {
    tenantId?: string;
    body?: QualificationsGetReducedEducationalInstitutionQuery;
  }): Observable<Array<QualificationsBthrEducationalInstitution>> {
    return this.getReducedEducationalInstitution$Response(params).pipe(
      map((r: QualificationsStrictHttpResponse<Array<QualificationsBthrEducationalInstitution>>) => r.body as Array<QualificationsBthrEducationalInstitution>),
    );
  }

  /**
   * Path part for operation getReducedQualification
   */
  static readonly GetReducedQualificationPath = '/api/Qualifications/reducedQualification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReducedQualification()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getReducedQualification$Response(params?: {
    tenantId?: string;
    body?: QualificationsGetReducedQualificationQuery;
  }): Observable<QualificationsStrictHttpResponse<Array<QualificationsBthrQualification>>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.GetReducedQualificationPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as QualificationsStrictHttpResponse<Array<QualificationsBthrQualification>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getReducedQualification$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getReducedQualification(params?: {
    tenantId?: string;
    body?: QualificationsGetReducedQualificationQuery;
  }): Observable<Array<QualificationsBthrQualification>> {
    return this.getReducedQualification$Response(params).pipe(
      map((r: QualificationsStrictHttpResponse<Array<QualificationsBthrQualification>>) => r.body as Array<QualificationsBthrQualification>),
    );
  }

  /**
   * Path part for operation getQualficationInstitutionLink
   */
  static readonly GetQualficationInstitutionLinkPath = '/api/Qualifications/qualficationInstitutionLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQualficationInstitutionLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getQualficationInstitutionLink$Response(params?: {
    tenantId?: string;
    body?: QualificationsGetQualficationInstitutionLinkQuery;
  }): Observable<QualificationsStrictHttpResponse<Array<QualificationsBtQualficationInstitutionLink>>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.GetQualficationInstitutionLinkPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as QualificationsStrictHttpResponse<Array<QualificationsBtQualficationInstitutionLink>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQualficationInstitutionLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getQualficationInstitutionLink(params?: {
    tenantId?: string;
    body?: QualificationsGetQualficationInstitutionLinkQuery;
  }): Observable<Array<QualificationsBtQualficationInstitutionLink>> {
    return this.getQualficationInstitutionLink$Response(params).pipe(
      map(
        (r: QualificationsStrictHttpResponse<Array<QualificationsBtQualficationInstitutionLink>>) =>
          r.body as Array<QualificationsBtQualficationInstitutionLink>,
      ),
    );
  }

  /**
   * Path part for operation getAccreditationAuthority
   */
  static readonly GetAccreditationAuthorityPath = '/api/Qualifications/accreditationAuthority';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccreditationAuthority()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccreditationAuthority$Response(params?: {
    tenantId?: string;
    body?: QualificationsGetAccreditationAuthorityQuery;
  }): Observable<QualificationsStrictHttpResponse<Array<QualificationsBthrAccreditationAuthority>>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.GetAccreditationAuthorityPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as QualificationsStrictHttpResponse<Array<QualificationsBthrAccreditationAuthority>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccreditationAuthority$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAccreditationAuthority(params?: {
    tenantId?: string;
    body?: QualificationsGetAccreditationAuthorityQuery;
  }): Observable<Array<QualificationsBthrAccreditationAuthority>> {
    return this.getAccreditationAuthority$Response(params).pipe(
      map((r: QualificationsStrictHttpResponse<Array<QualificationsBthrAccreditationAuthority>>) => r.body as Array<QualificationsBthrAccreditationAuthority>),
    );
  }

  /**
   * Path part for operation patchEmployeeEducationalQualification
   */
  static readonly PatchEmployeeEducationalQualificationPath = '/api/Qualifications/patch/employeeEducationalQualification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEmployeeEducationalQualification()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchEmployeeEducationalQualification$Response(params?: {
    tenantId?: string;
    body?: {
      bthr_employeeeducationalqualificationid?: string;
      bt_legalentity?: string;
      bthr_employee?: string;
      bthr_qualification?: string;
      bthr_educationalinstitution?: string;
      bthr_dateawarded?: string;
      bt_expire?: boolean;
      bt_expirydate?: string;
      bt_qualificationstatus?: QualificationsBtQualificationStatus;
      bt_validationurl?: string;
      bthr_recordstatus?: QualificationsBthrRecordStatus;
      bt_validated?: boolean;
      bt_validationtype?: QualificationsBtValidationType;
      bt_validatedbyentity?: string;
      bt_validatedby?: string;
      bt_newinstitutionyn?: boolean;
      bt_newinstitution?: string;
      bt_newinstitutionurl?: string;
      bt_newqualificationyn?: boolean;
      bt_newqualificationname?: string;
      'LegalEntity.id'?: string;
      'LegalEntity.odata'?: string;
      'Employee.id'?: string;
      'Employee.odata'?: string;
      'Qualification.id'?: string;
      'Qualification.odata'?: string;
      'EducationalInstitution.id'?: string;
      'EducationalInstitution.odata'?: string;
      'ValidatedByEntity.id'?: string;
      'ValidatedByEntity.odata'?: string;
      'ValidatedBy.id'?: string;
      'ValidatedBy.odata'?: string;
      BthrQualification_bthr_qualificationtype?: QualificationsBthrQualificationType;
      BthrQualification_bt_level?: number;
      BthrQualification_bt_points?: number;
      QualificationsFileString?: string;
      QualificationsFile?: Array<Blob>;
      QualificationsFileName?: string;
      bt_proxy?: boolean;
      bt_proxylegalentity?: string;
      bt_proxyid?: string;
      'ProxyLegalEntity.id'?: string;
      'ProxyLegalEntity.odata'?: string;
      'ProxyId.id'?: string;
      'ProxyId.odata'?: string;
    };
  }): Observable<QualificationsStrictHttpResponse<boolean>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.PatchEmployeeEducationalQualificationPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as QualificationsStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchEmployeeEducationalQualification$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  patchEmployeeEducationalQualification(params?: {
    tenantId?: string;
    body?: {
      bthr_employeeeducationalqualificationid?: string;
      bt_legalentity?: string;
      bthr_employee?: string;
      bthr_qualification?: string;
      bthr_educationalinstitution?: string;
      bthr_dateawarded?: string;
      bt_expire?: boolean;
      bt_expirydate?: string;
      bt_qualificationstatus?: QualificationsBtQualificationStatus;
      bt_validationurl?: string;
      bthr_recordstatus?: QualificationsBthrRecordStatus;
      bt_validated?: boolean;
      bt_validationtype?: QualificationsBtValidationType;
      bt_validatedbyentity?: string;
      bt_validatedby?: string;
      bt_newinstitutionyn?: boolean;
      bt_newinstitution?: string;
      bt_newinstitutionurl?: string;
      bt_newqualificationyn?: boolean;
      bt_newqualificationname?: string;
      'LegalEntity.id'?: string;
      'LegalEntity.odata'?: string;
      'Employee.id'?: string;
      'Employee.odata'?: string;
      'Qualification.id'?: string;
      'Qualification.odata'?: string;
      'EducationalInstitution.id'?: string;
      'EducationalInstitution.odata'?: string;
      'ValidatedByEntity.id'?: string;
      'ValidatedByEntity.odata'?: string;
      'ValidatedBy.id'?: string;
      'ValidatedBy.odata'?: string;
      BthrQualification_bthr_qualificationtype?: QualificationsBthrQualificationType;
      BthrQualification_bt_level?: number;
      BthrQualification_bt_points?: number;
      QualificationsFileString?: string;
      QualificationsFile?: Array<Blob>;
      QualificationsFileName?: string;
      bt_proxy?: boolean;
      bt_proxylegalentity?: string;
      bt_proxyid?: string;
      'ProxyLegalEntity.id'?: string;
      'ProxyLegalEntity.odata'?: string;
      'ProxyId.id'?: string;
      'ProxyId.odata'?: string;
    };
  }): Observable<boolean> {
    return this.patchEmployeeEducationalQualification$Response(params).pipe(map((r: QualificationsStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation createEmployeeEducationalQualification
   */
  static readonly CreateEmployeeEducationalQualificationPath = '/api/Qualifications/createEmployeeEducationalQualification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEmployeeEducationalQualification()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createEmployeeEducationalQualification$Response(params?: {
    tenantId?: string;
    body?: {
      bthr_employeeeducationalqualificationid?: string;
      bt_legalentity?: string;
      bthr_employee?: string;
      bthr_qualification?: string;
      bthr_educationalinstitution?: string;
      bthr_dateawarded?: string;
      bt_expire?: boolean;
      bt_expirydate?: string;
      bt_qualificationstatus?: QualificationsBtQualificationStatus;
      bt_validationurl?: string;
      bthr_recordstatus?: QualificationsBthrRecordStatus;
      bt_validated?: boolean;
      bt_validationtype?: QualificationsBtValidationType;
      bt_validatedbyentity?: string;
      bt_validatedby?: string;
      bt_newinstitutionyn?: boolean;
      bt_newinstitution?: string;
      bt_newinstitutionurl?: string;
      bt_newqualificationyn?: boolean;
      bt_newqualificationname?: string;
      'LegalEntity.id'?: string;
      'LegalEntity.odata'?: string;
      'Employee.id'?: string;
      'Employee.odata'?: string;
      'Qualification.id'?: string;
      'Qualification.odata'?: string;
      'EducationalInstitution.id'?: string;
      'EducationalInstitution.odata'?: string;
      'ValidatedByEntity.id'?: string;
      'ValidatedByEntity.odata'?: string;
      'ValidatedBy.id'?: string;
      'ValidatedBy.odata'?: string;
      BthrQualification_bthr_qualificationtype?: QualificationsBthrQualificationType;
      BthrQualification_bt_level?: number;
      BthrQualification_bt_points?: number;
      QualificationsFileString?: string;
      QualificationsFile?: Array<Blob>;
      QualificationsFileName?: string;
      bt_proxy?: boolean;
      bt_proxylegalentity?: string;
      bt_proxyid?: string;
      'ProxyLegalEntity.id'?: string;
      'ProxyLegalEntity.odata'?: string;
      'ProxyId.id'?: string;
      'ProxyId.odata'?: string;
    };
  }): Observable<QualificationsStrictHttpResponse<boolean>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.CreateEmployeeEducationalQualificationPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as QualificationsStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createEmployeeEducationalQualification$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createEmployeeEducationalQualification(params?: {
    tenantId?: string;
    body?: {
      bthr_employeeeducationalqualificationid?: string;
      bt_legalentity?: string;
      bthr_employee?: string;
      bthr_qualification?: string;
      bthr_educationalinstitution?: string;
      bthr_dateawarded?: string;
      bt_expire?: boolean;
      bt_expirydate?: string;
      bt_qualificationstatus?: QualificationsBtQualificationStatus;
      bt_validationurl?: string;
      bthr_recordstatus?: QualificationsBthrRecordStatus;
      bt_validated?: boolean;
      bt_validationtype?: QualificationsBtValidationType;
      bt_validatedbyentity?: string;
      bt_validatedby?: string;
      bt_newinstitutionyn?: boolean;
      bt_newinstitution?: string;
      bt_newinstitutionurl?: string;
      bt_newqualificationyn?: boolean;
      bt_newqualificationname?: string;
      'LegalEntity.id'?: string;
      'LegalEntity.odata'?: string;
      'Employee.id'?: string;
      'Employee.odata'?: string;
      'Qualification.id'?: string;
      'Qualification.odata'?: string;
      'EducationalInstitution.id'?: string;
      'EducationalInstitution.odata'?: string;
      'ValidatedByEntity.id'?: string;
      'ValidatedByEntity.odata'?: string;
      'ValidatedBy.id'?: string;
      'ValidatedBy.odata'?: string;
      BthrQualification_bthr_qualificationtype?: QualificationsBthrQualificationType;
      BthrQualification_bt_level?: number;
      BthrQualification_bt_points?: number;
      QualificationsFileString?: string;
      QualificationsFile?: Array<Blob>;
      QualificationsFileName?: string;
      bt_proxy?: boolean;
      bt_proxylegalentity?: string;
      bt_proxyid?: string;
      'ProxyLegalEntity.id'?: string;
      'ProxyLegalEntity.odata'?: string;
      'ProxyId.id'?: string;
      'ProxyId.odata'?: string;
    };
  }): Observable<boolean> {
    return this.createEmployeeEducationalQualification$Response(params).pipe(map((r: QualificationsStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation createEmployeeEducationalQualificationRenewals
   */
  static readonly CreateEmployeeEducationalQualificationRenewalsPath = '/api/Qualifications/createEmployeeEducationalQualificationRenewals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEmployeeEducationalQualificationRenewals()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createEmployeeEducationalQualificationRenewals$Response(params?: {
    tenantId?: string;
    body?: {
      bt_EmployeeQualificationRenewalid?: string;
      bt_employeequalification?: string;
      bt_renewaldate?: string;
      bt_validationurl?: string;
      bt_validated?: boolean;
      bt_validationtype?: QualificationsBtValidationType;
      bthr_isonumeric?: string;
      bt_validatebyentity?: string;
      bt_validatedby?: string;
      'EmployeeQualification.id'?: string;
      'EmployeeQualification.odata'?: string;
      'Validatebyentity.id'?: string;
      'Validatebyentity.odata'?: string;
      'Validatedby.id'?: string;
      'Validatedby.odata'?: string;
      ProofOfRenewalFileString?: string;
      proofOfRenewal?: Array<Blob>;
      ProofOfRenewalFileName?: string;
      bt_proxy?: boolean;
      bt_proxylegalentity?: string;
      bt_proxyid?: string;
      'ProxyLegalEntity.id'?: string;
      'ProxyLegalEntity.odata'?: string;
      'ProxyId.id'?: string;
      'ProxyId.odata'?: string;
    };
  }): Observable<QualificationsStrictHttpResponse<boolean>> {
    const rb = new QualificationsRequestBuilder(
      this.rootUrl,
      QualificationsApiQualificationsService.CreateEmployeeEducationalQualificationRenewalsPath,
      'post',
    );
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as QualificationsStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createEmployeeEducationalQualificationRenewals$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createEmployeeEducationalQualificationRenewals(params?: {
    tenantId?: string;
    body?: {
      bt_EmployeeQualificationRenewalid?: string;
      bt_employeequalification?: string;
      bt_renewaldate?: string;
      bt_validationurl?: string;
      bt_validated?: boolean;
      bt_validationtype?: QualificationsBtValidationType;
      bthr_isonumeric?: string;
      bt_validatebyentity?: string;
      bt_validatedby?: string;
      'EmployeeQualification.id'?: string;
      'EmployeeQualification.odata'?: string;
      'Validatebyentity.id'?: string;
      'Validatebyentity.odata'?: string;
      'Validatedby.id'?: string;
      'Validatedby.odata'?: string;
      ProofOfRenewalFileString?: string;
      proofOfRenewal?: Array<Blob>;
      ProofOfRenewalFileName?: string;
      bt_proxy?: boolean;
      bt_proxylegalentity?: string;
      bt_proxyid?: string;
      'ProxyLegalEntity.id'?: string;
      'ProxyLegalEntity.odata'?: string;
      'ProxyId.id'?: string;
      'ProxyId.odata'?: string;
    };
  }): Observable<boolean> {
    return this.createEmployeeEducationalQualificationRenewals$Response(params).pipe(map((r: QualificationsStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation createEducationalInstitution
   */
  static readonly CreateEducationalInstitutionPath = '/api/Qualifications/createEducationalInstitution';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createEducationalInstitution()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createEducationalInstitution$Response(params?: {
    tenantId?: string;
    body?: QualificationsCreateEducationalInstitutionCommand;
  }): Observable<QualificationsStrictHttpResponse<boolean>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.CreateEducationalInstitutionPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as QualificationsStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createEducationalInstitution$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createEducationalInstitution(params?: { tenantId?: string; body?: QualificationsCreateEducationalInstitutionCommand }): Observable<boolean> {
    return this.createEducationalInstitution$Response(params).pipe(map((r: QualificationsStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation createQualification
   */
  static readonly CreateQualificationPath = '/api/Qualifications/createQualification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createQualification()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createQualification$Response(params?: {
    tenantId?: string;
    body?: QualificationsCreateQualificationCommand;
  }): Observable<QualificationsStrictHttpResponse<boolean>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.CreateQualificationPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as QualificationsStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createQualification$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createQualification(params?: { tenantId?: string; body?: QualificationsCreateQualificationCommand }): Observable<boolean> {
    return this.createQualification$Response(params).pipe(map((r: QualificationsStrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation createQualficationInstitutionLink
   */
  static readonly CreateQualficationInstitutionLinkPath = '/api/Qualifications/createQualficationInstitutionLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createQualficationInstitutionLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createQualficationInstitutionLink$Response(params?: {
    tenantId?: string;
    body?: QualificationsCreateQualficationInstitutionLinkCommand;
  }): Observable<QualificationsStrictHttpResponse<boolean>> {
    const rb = new QualificationsRequestBuilder(this.rootUrl, QualificationsApiQualificationsService.CreateQualficationInstitutionLinkPath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as QualificationsStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createQualficationInstitutionLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createQualficationInstitutionLink(params?: { tenantId?: string; body?: QualificationsCreateQualficationInstitutionLinkCommand }): Observable<boolean> {
    return this.createQualficationInstitutionLink$Response(params).pipe(map((r: QualificationsStrictHttpResponse<boolean>) => r.body as boolean));
  }
}
