import { User } from '@sentry/angular-ivy';
import { PeerReviewFeedback, PeerReviewFeedbackSummary } from '@verde/api';
import { PeerReviewUser } from './peer-review-user';
export interface UserPeerReviewProcess {
  employee?: User;
  peerReviewSummaries?: PeerReviewFeedbackSummary;
  questions?: PeerReviewFeedback[];
  peerReviewUser?: PeerReviewUser;
  idRef?: number;
}
