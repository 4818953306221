/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvVaccinationSummary } from '../models/dv-vaccination-summary';
import { DvVaccinationTypes } from '../models/dv-vaccination-types';
import { DvVaccinations } from '../models/dv-vaccinations';
import { DvVaccinationsPost } from '../models/dv-vaccinations-post';

@Injectable({
  providedIn: 'root',
})
export class SharedApiVaccinationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllVaccinations
   */
  static readonly GetAllVaccinationsPath = '/api/Vaccinations/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllVaccinations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVaccinations$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvVaccinations>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVaccinationsService.GetAllVaccinationsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvVaccinations>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllVaccinations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVaccinations(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<DvVaccinations>> {
    return this.getAllVaccinations$Response(params).pipe(map((r: StrictHttpResponse<Array<DvVaccinations>>) => r.body as Array<DvVaccinations>));
  }

  /**
   * Path part for operation patchVaccinations
   */
  static readonly PatchVaccinationsPath = '/api/Vaccinations/patch/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVaccinations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVaccinations$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvVaccinationsPost;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVaccinationsService.PatchVaccinationsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVaccinations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVaccinations(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: DvVaccinationsPost }): Observable<boolean> {
    return this.patchVaccinations$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation postVaccinations
   */
  static readonly PostVaccinationsPath = '/api/Vaccinations/post/{userguid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVaccinations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVaccinations$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    userguid: string;
    body?: DvVaccinationsPost;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVaccinationsService.PostVaccinationsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('userguid', params.userguid, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVaccinations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVaccinations(params: { subscriptionKey?: string; APIURL?: string; userguid: string; body?: DvVaccinationsPost }): Observable<boolean> {
    return this.postVaccinations$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }

  /**
   * Path part for operation getVaccinationSummaries
   */
  static readonly GetVaccinationSummariesPath = '/api/Vaccinations/summary/{employeeGUID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVaccinationSummaries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccinationSummaries$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
  }): Observable<StrictHttpResponse<Array<DvVaccinationSummary>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVaccinationsService.GetVaccinationSummariesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvVaccinationSummary>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVaccinationSummaries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccinationSummaries(params: { subscriptionKey?: string; APIURL?: string; employeeGUID: string }): Observable<Array<DvVaccinationSummary>> {
    return this.getVaccinationSummaries$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvVaccinationSummary>>) => r.body as Array<DvVaccinationSummary>),
    );
  }

  /**
   * Path part for operation getVaccinationTypes
   */
  static readonly GetVaccinationTypesPath = '/api/Vaccinations/types/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVaccinationTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccinationTypes$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<DvVaccinationTypes>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiVaccinationsService.GetVaccinationTypesPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvVaccinationTypes>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVaccinationTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVaccinationTypes(params: { subscriptionKey?: string; APIURL?: string; legalEntityGuid: string }): Observable<Array<DvVaccinationTypes>> {
    return this.getVaccinationTypes$Response(params).pipe(map((r: StrictHttpResponse<Array<DvVaccinationTypes>>) => r.body as Array<DvVaccinationTypes>));
  }
}
