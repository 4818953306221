/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DvAllEducationalInstitutions } from '../models/dv-all-educational-institutions';
import { DvAllEducationalInstitutionsPost } from '../models/dv-all-educational-institutions-post';

@Injectable({
  providedIn: 'root',
})
export class SharedApiAllEducationalInstitutionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getAllEducationalInstitutions
   */
  static readonly GetAllEducationalInstitutionsPath = '/api/AllEducationalInstitutions/get/{legalEntityGuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllEducationalInstitutions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEducationalInstitutions$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<StrictHttpResponse<Array<DvAllEducationalInstitutions>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiAllEducationalInstitutionsService.GetAllEducationalInstitutionsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityGuid', params.legalEntityGuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DvAllEducationalInstitutions>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllEducationalInstitutions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllEducationalInstitutions(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityGuid: string;
  }): Observable<Array<DvAllEducationalInstitutions>> {
    return this.getAllEducationalInstitutions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DvAllEducationalInstitutions>>) => r.body as Array<DvAllEducationalInstitutions>),
    );
  }

  /**
   * Path part for operation postEducationalInstitutions
   */
  static readonly PostEducationalInstitutionsPath = '/api/AllEducationalInstitutions/post';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postEducationalInstitutions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEducationalInstitutions$Response(params?: {
    subscriptionKey?: string;
    APIURL?: string;
    body?: DvAllEducationalInstitutionsPost;
  }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiAllEducationalInstitutionsService.PostEducationalInstitutionsPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postEducationalInstitutions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postEducationalInstitutions(params?: { subscriptionKey?: string; APIURL?: string; body?: DvAllEducationalInstitutionsPost }): Observable<boolean> {
    return this.postEducationalInstitutions$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
