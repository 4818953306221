import { Action } from '@ngrx/store';
import { UserUserDto } from '@verde/api';
import { VerdeTaskCustom } from '@verde/shared';

export enum TaskClosedActionTypes {
  // Get All Task Closed
  TASK_CLOSED_GET_ALL = '[Task] Closed Get All Start',
  TASK_CLOSED_GET_ALL_SUCCESS = '[Task] Closed Get All Start Success',
  TASK_CLOSED_GET_ALL_ERROR = '[Task] Closed Get All Start Error',
  TASK_CLOSED_GET_ALL_STOP = '[Task] Closed Get All Stop',
  TASK_CLOSED_GET_ALL_STOP_SUCCESS = '[Task] Closed Get All Stop Success',
  // Clear All Task Closed
  TASK_CLOSED_CLEAR = '[Task] Closed Clear',
}

// Get All Task Closed
export class TaskClosedGetAll implements Action {
  readonly type = TaskClosedActionTypes.TASK_CLOSED_GET_ALL;
  constructor(public proxyUser: UserUserDto) {}
}

export class TaskClosedGetAllSuccess implements Action {
  readonly type = TaskClosedActionTypes.TASK_CLOSED_GET_ALL_SUCCESS;
  constructor(public payload: VerdeTaskCustom[]) {}
}

export class TaskClosedGetAllError implements Action {
  readonly type = TaskClosedActionTypes.TASK_CLOSED_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

export class TaskClosedGetAllStop implements Action {
  readonly type = TaskClosedActionTypes.TASK_CLOSED_GET_ALL_STOP;
  constructor(public proxyUser: UserUserDto) {}
}

export class TaskClosedGetAllStopSuccess implements Action {
  readonly type = TaskClosedActionTypes.TASK_CLOSED_GET_ALL_STOP_SUCCESS;
  constructor(public proxyUser: UserUserDto) {}
}

// Clear All Task Closed
export class TaskClosedClear implements Action {
  readonly type = TaskClosedActionTypes.TASK_CLOSED_CLEAR;
  constructor(public payload: any) {}
}

export type TaskClosedActions =
  // Get All Task Closed
  | TaskClosedGetAll
  | TaskClosedGetAllSuccess
  | TaskClosedGetAllError
  | TaskClosedGetAllStop
  | TaskClosedGetAllStopSuccess
  // Clear All Task Closed
  | TaskClosedClear;
