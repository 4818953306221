import { Action } from '@ngrx/store';
import { TemplateGetBenchmarksByGroupQuery, TemplateGetBenchmarksQuery } from '@verde/api';

export enum BenchmarksActionTypes {
  // Get BENCHMARKS
  BENCHMARKS_GET_ALL = '[BENCHMARKS] Get All Start',
  BENCHMARKS_GET_ALL_SUCCESS = '[BENCHMARKS] Get All Start Success',
  BENCHMARKS_GET_ALL_ERROR = '[BENCHMARKS] Get All Start Error',

  // Get BENCHMARKS BY GROUP
  BENCHMARKS_BY_GROUP_GET_ALL = '[BENCHMARKS BY GROUP] Get All Start',
  BENCHMARKS_BY_GROUP_GET_ALL_SUCCESS = '[BENCHMARKS BY GROUP] Get All Start Success',
  BENCHMARKS_BY_GROUP_GET_ALL_ERROR = '[BENCHMARKS BY GROUP] Get All Start Error',

  // Clear All BENCHMARKS
  BENCHMARKS_ITEM_CLEAR = '[BENCHMARKS] Clear',
}

// Get Benchmark
export class BenchmarksGet implements Action {
  readonly type = BenchmarksActionTypes.BENCHMARKS_GET_ALL;
  constructor(public body: TemplateGetBenchmarksQuery) {}
}

export class BenchmarksGetSuccess implements Action {
  readonly type = BenchmarksActionTypes.BENCHMARKS_GET_ALL_SUCCESS;
  constructor(public payload: any[]) {}
}

export class BenchmarksGetError implements Action {
  readonly type = BenchmarksActionTypes.BENCHMARKS_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// Get Benchmark By Group
export class BenchmarksByGroupGet implements Action {
  readonly type = BenchmarksActionTypes.BENCHMARKS_BY_GROUP_GET_ALL;
  constructor(public body: TemplateGetBenchmarksByGroupQuery) {}
}

export class BenchmarksByGroupGetSuccess implements Action {
  readonly type = BenchmarksActionTypes.BENCHMARKS_BY_GROUP_GET_ALL_SUCCESS;
  constructor(public payload: any[]) {}
}

export class BenchmarksByGroupGetError implements Action {
  readonly type = BenchmarksActionTypes.BENCHMARKS_BY_GROUP_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// Clear All Benchmark
export class BenchmarksClear implements Action {
  readonly type = BenchmarksActionTypes.BENCHMARKS_ITEM_CLEAR;
  constructor(public payload: any) {}
}

export type BenchmarksActions =
  // Get Benchmark
  | BenchmarksGet
  | BenchmarksGetSuccess
  | BenchmarksGetError
  // Get Benchmark By Group
  | BenchmarksByGroupGet
  | BenchmarksByGroupGetSuccess
  | BenchmarksByGroupGetError
  // Clear All Benchmark
  | BenchmarksClear;
