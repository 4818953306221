/* tslint:disable */
/* eslint-disable */
import { UserBtCallOutType } from './user-bt-call-out-type';
import { UserBtDynamicSource } from './user-bt-dynamic-source';
import { UserBtEmbedType } from './user-bt-embed-type';
import { UserBtLinkType } from './user-bt-link-type';
import { UserBtNavBar } from './user-bt-nav-bar';
import { UserBtModule } from './user-bt-module';
export interface UserSecurityFunctionDto {
  approvalStep?: null | boolean;
  autoExpand?: null | boolean;
  bt_AppGuid?: null | string;
  bt_AppName?: null | string;
  bt_CommandBar?: null | boolean;
  bt_Description?: null | string;
  bt_DropDownYN?: null | boolean;
  bt_DynamicFormSecurityInput?: null | string;
  bt_DynamicSource?: UserBtDynamicSource;
  bt_EmbedType?: UserBtEmbedType;
  bt_EmbeddedApp?: null | boolean;
  bt_Entity?: null | string;
  bt_FormViewId?: null | string;
  bt_GridLine?: null | boolean;
  bt_GridMenu?: null | boolean;
  bt_LinkType?: UserBtLinkType;
  bt_LoadOnStart?: null | boolean;
  bt_NavBar?: UserBtNavBar;
  bt_NewRecord?: null | boolean;
  bt_PageMenu?: null | boolean;
  bt_RequireAuthenticate?: null | boolean;
  bt_VerdeWorkSpaceID?: null | string;
  bt_VerdeWorkSpaceName?: null | string;
  bt_Workspace?: null | boolean;
  callOutType?: UserBtCallOutType;
  configFieldName?: null | string;
  controlList?: null | boolean;
  controlListTemplateId?: null | string;
  controlListTemplateName?: null | string;
  dropdownDisplay?: null | string;
  dropdownName?: null | string;
  dynamicFormRegisterId?: null | string;
  dynamicFormRegisterName?: null | string;
  dynamicGroupId?: null | string;
  dynamicGroupName?: null | string;
  function?: null | string;
  functionId?: null | string;
  groupName?: null | string;
  groupingSequence?: null | number;
  handlerId?: null | string;
  handlerName?: null | string;
  jsonFileName?: null | string;
  learnCallOut?: null | boolean;
  legalEntityId?: string;
  legalEntityName?: null | string;
  menuContext?: null | string;
  menuGroup?: null | string;
  menuGrouping?: null | boolean;
  menuName?: null | string;
  menuSequence?: null | number;
  module?: UserBtModule;
  powerBI?: null | boolean;
  reportId?: null | string;
  reportType?: null | string;
  technicalName?: null | string;
  tileId?: null | string;
  tileName?: null | string;
  useInMenu?: null | boolean;
  workspaceId?: null | string;
}
