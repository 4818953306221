/* tslint:disable */
/* eslint-disable */
import { BtAssignedTo } from './bt-assigned-to';
import { BtEmployeeRelatedTo } from './bt-employee-related-to';
import { BtFollowedUpby } from './bt-followed-upby';
import { BtVerdeTaskListAssignToTaskListBtVerd } from './bt-verde-task-list-assign-to-task-list-bt-verd';
export interface VerdeTask {
  _bt_assignedto_value?: null | string;
  _bt_employeerelatedto_value?: null | string;
  _bt_followedupby_value?: null | string;
  _bt_proxyemployee_value?: null | string;
  _bt_proxylegalentity_value?: null | string;
  _bt_reviewsubgroup_value?: null | string;
  _bt_taskgroup_value?: null | string;
  _bt_tasklisttemplate_value?: null | string;
  bt_AssignedTo?: BtAssignedTo;
  bt_EmployeeRelatedTo?: BtEmployeeRelatedTo;
  bt_FollowedUpby?: BtFollowedUpby;
  bt_VerdeTaskListAssignTo_TaskList_bt_Verd?: null | Array<BtVerdeTaskListAssignToTaskListBtVerd>;
  bt_approval?: null | boolean;
  bt_completeddate?: null | string;
  bt_completeddatetime?: null | string;
  bt_duedate?: null | string;
  bt_duedatedatetime?: null | string;
  bt_duedatetime?: null | string;
  bt_expecteddate?: null | string;
  bt_followedup?: null | boolean;
  bt_followupcomments?: null | string;
  bt_followupdatetime?: null | string;
  bt_followupstatus?: null | number;
  bt_priority?: null | number;
  bt_proxy?: null | boolean;
  bt_referencecolumn?: null | string;
  bt_referenceguid?: null | string;
  bt_referencetable?: null | string;
  bt_startdate?: null | string;
  bt_startdatetime?: null | string;
  bt_taskdatestatus?: null | string;
  bt_taskdescription?: null | string;
  bt_taskheader?: null | string;
  bt_tasklistno?: null | string;
  bt_tasksource?: null | number;
  bt_taskstatus?: null | number;
  bt_verdefunctiontechnicalname?: null | string;
  bt_verdetasklistid?: null | string;
  odatPostAssignedTo?: null | string;
  odatPostAssignedToLegalEntity?: null | string;
  odatPostEmployeeRealtedTo?: null | string;
  odatPostFollowUpLegalEntity?: null | string;
  odatPostFollowedUpBy?: null | string;
  odatPostProxyEmployee?: null | string;
  odatPostProxyLegalEntity?: null | string;
  odatPostRelatedToLegalEntity?: null | string;
  odatPostReviewSubgroup?: null | string;
  odatPostTaskGroup?: null | string;
  odataFollowUpStatus?: null | string;
  odataFollowedUp?: null | string;
  odataProxyEmployee?: null | string;
  odataProxyLegalEntity?: null | string;
  odataReviewSubgroup?: null | string;
  odataTaskGroup?: null | string;
  odataTaskSource?: null | string;
  odataTaskStatus?: null | string;
  odataTaskTemplate?: null | string;
}
