import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { VerdeApprovalService } from '@verde/shared';
import { Subject, take, takeUntil } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

// Steps for creation:
// Copy and paste existing component as a base to work off of
// Change file names accordingly
// In .ts file, change selector, templateUrl, styleUrls and the component name on the export line
// go to the shared.module.ts and add reference in for your component
// in verde-approval-panel.component.html, add you reference to your new component dependent on the sidebar type
// in verde-approval-service, make sure you add in relevant variables to pass data through to your new component
// on the component that you are initiating navigation from, inject the verde-approval-service and set the relevant fields to open the sidebar, including populating the type to the relevant value
// sidebar should open to your new component

@Component({
  selector: 'verde-a-dummy-sidepanel',
  templateUrl: './a-dummy-sidepanel.component.html',
  styleUrls: ['./a-dummy-sidepanel.component.scss'],
})
export class ADummySidepanelComponent implements OnInit, AfterViewInit, OnDestroy {
  onDestroy$: Subject<any> = new Subject();
  fb: FormBuilder;
  fg: FormGroup;

  currentStep: number = 1;
  //  Set total steps needed for your form
  totalSteps: number = 3;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['jpg', 'jpeg', 'png', 'pdf'],
  };

  constructor(private sidebarService: VerdeApprovalService, private http: HttpClient, private msalService: MsalService) {}

  ngOnInit(): void {
    this.fb = new FormBuilder();
    this.resetForm();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  resetForm() {
    this.fg = this.fb.group({
      exampleTextBox: [],
      exampleTextBox2: [],
      exampleTextBox3: [],
      exampleTextBox4: [],
      exampleTextBox5: [],
      exampleDateInput: [new Date()],
      exampleDropdown: [],
      exampleDropdown2: [],
      exampleDropdown3: [],
      exampleTextArea: [],
      fileUpload: [],
      radioButton: [],
      radioButton2: [],
      exampleGraphApi1: [],
      exampleGraphApi2: [],
      exampleGraphApi3: [],
      exampleGraphApi4: [],
      exampleGraphApi5: [],
      exampleGraphApi6: [],
      exampleGraphApi7: [],
      exampleGraphApi1a: [],
      exampleGraphApi1b: [],
    });
  }

  public next(): void {
    this.currentStep += 1;
  }

  public prev(): void {
    this.currentStep -= 1;
  }

  submit() {
    console.log(this.fg?.value?.exampleGraphApi5);
    console.log(this.fg?.value?.exampleGraphApi2);

    const account = this.msalService.instance.getActiveAccount();

    if ((this.fg?.value?.exampleGraphApi2 ?? '') != '') {
      if (account) {
        this.msalService
          .acquireTokenSilent({
            scopes: [this.fg?.value?.exampleGraphApi2],
            account: account,
          })
          .subscribe((tokenResponse: AuthenticationResult) => {
            const headers = new HttpHeaders({
              Authorization: `Bearer ${tokenResponse}`,
              'Content-Type': 'application/json',
            });

            this.http
              .get(this.fg?.value?.exampleGraphApi2, { headers })
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (data: any) => {
                  console.log('GraphGetAPIData', data.value);
                  //   this.spinner.hide('my-calendar');
                },
                (error?: any) => {
                  console.log('GraphGetAPIError', error.error);
                },
              );
          });
      }
    }

    if ((this.fg?.value?.exampleGraphApi5 ?? '') != '') {
      if (account) {
        this.msalService
          .acquireTokenSilent({
            scopes: [this.fg?.value?.exampleGraphApi4],
            account: account,
          })
          .subscribe((tokenResponse: AuthenticationResult) => {
            const headers = new HttpHeaders({
              Authorization: `Bearer ${tokenResponse}`,
              'Content-Type': 'application/json',
            });

            this.http
              .post(this.fg?.value?.exampleGraphApi5, this.fg?.value?.exampleGraphApi6, { headers })
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                (data?: any) => {
                  console.log('GraphGetAPIData', data.value);
                  //       this.spinner.hide('my-calendar');
                },
                (error?: any) => {
                  console.log('GraphGetAPIError', error.error);
                },
              );
          });
      }
    }
  }
}
