/* eslint-disable no-console */
import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { GroupDescriptor, orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { ManagerVaccinationInfo, SharedApiRiskManagementService, UserUserDto } from '@verde/api';
import { DeviceTypeService, UserService } from '@verde/core';
import { DotsMenuItem } from '@verde/shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'verde-vaccination-tab',
  templateUrl: './vaccination-tab.component.html',
  styleUrls: ['./vaccination-tab.component.scss'],
  animations: [
    trigger('fadeInBackdrop', [
      transition(':enter', [style({ opacity: 0 }), animate('0.3s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('0.3s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class VaccinationTabComponent implements OnInit, OnDestroy {
  //#region Variable Declarations
  onDestroy$: Subject<any> = new Subject();

  // Users profile
  user: UserUserDto;
  showModal = false;
  slideDownModal = false;

  disableAnimation: boolean;

  dotMenuItems: DotsMenuItem[] = [];

  // API Array
  allManagerVaccinationInfo: Array<ManagerVaccinationInfo> = new Array<ManagerVaccinationInfo>();
  allMembersUnderManager: Array<ManagerVaccinationInfo> = new Array<ManagerVaccinationInfo>();
  selectedManager: ManagerVaccinationInfo;

  //#region Kendo variables
  // Engage Team
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  vaccinationInfoGridData: GridDataResult[] | ManagerVaccinationInfo[];

  vaccinationInfoGridView: GridDataResult;

  vaccinationInfoState: State = {
    skip: 0,
    take: 5,
  };

  vaccinationInfoSort: SortDescriptor[] = [
    {
      field: 'bt_fullnamewithempid',
      dir: 'asc',
    },
  ];

  vaccinationInfoGroups: GroupDescriptor[];

  vaccinationInfoSelection: any[] = [];

  vaccinationInfoSelectedCallback = (args) => args.dataItem;

  // Managers Team
  managersTeamGridView: GridDataResult;

  managersTeamState: State = {
    skip: 0,
    take: 5,
  };

  managersTeamSort: SortDescriptor[] = [
    {
      field: 'bt_fullnamewithempid',
      dir: 'asc',
    },
  ];

  managersTeamGroups: GroupDescriptor[];

  managersTeamSelection: any[] = [];

  managersTeamSelectedCallback = (args) => args.dataItem;

  //#endregion Kendo variables

  //#endregion Variable Declarations

  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private sharedApiRiskManagementService: SharedApiRiskManagementService,
    public deviceTypeService: DeviceTypeService,
  ) {}

  ngOnInit() {
    this.spinner.show('vaccinationtab');

    this.userService.config$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe((config) => {
      this.user = config.user;
    });

    this.getManagerVaccinationInfo();

    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe((data) => {
      this.disableAnimation = data;
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  refresh() {
    this.spinner.show('vaccinationtab');
    this.getManagerVaccinationInfo();
  }

  // #region Three Dot Menu Logic
  menuClicked(parentItem: ManagerVaccinationInfo) {
    this.dotMenuItems = [];
    const tempItems: DotsMenuItem[] = [];

    if (parentItem.bt_EmployeeID.bt_hassubordinates) {
      tempItems.push({ id: 'viewTeam', text: 'View Team' });
    }

    if (tempItems.length === 0) {
      tempItems.push({ text: 'No Options' });
    }

    this.dotMenuItems = [...tempItems];
  }

  menuItemClicked(menuItem: DotsMenuItem, parentItem: ManagerVaccinationInfo) {
    if (menuItem.id === 'viewTeam') {
      this.openMembersUnderManager(parentItem);
    }
  }

  // #endregion Three Dot Menu Logic

  getManagerVaccinationInfo() {
    this.sharedApiRiskManagementService
      .getManagerVaccinationInfo({ managerID: this.user?.employeeId })
      .pipe(take(1), distinctUntilChanged())
      .subscribe(
        (ret) => {
          this.allManagerVaccinationInfo = ret;
          this.vaccinationInfoGridData = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.loadVaccinationInfo();
          this.spinner.hide('vaccinationtab');
        },
      );
  }

  // GET All members under manager
  getMembersUnderManager(managerID: string) {
    this.spinner.show('managerteam');
    this.sharedApiRiskManagementService
      .getManagerVaccinationInfo({ managerID })
      .pipe(take(1), distinctUntilChanged())
      .subscribe(
        (ret) => {
          this.allMembersUnderManager = ret;
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.loadManagersTeam();
          this.spinner.hide('managerteam');
        },
      );
  }

  // Manager members
  openMembersUnderManager(selectedRecord: ManagerVaccinationInfo) {
    this.showModal = true;
    setTimeout(() => {
      this.slideDownModal = true;
    }, 10);
    this.selectedManager = selectedRecord;
    this.getMembersUnderManager(this.selectedManager.bt_EmployeeID.bthr_employeeid);
  }

  closeModal(): void {
    this.slideDownModal = false;
    setTimeout(() => {
      this.showModal = false;
    }, 10);
  }

  //#region Kendo Methods
  // Row style callback
  rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  // Engage Team
  loadVaccinationInfo(): void {
    this.vaccinationInfoGridView = {
      data: orderBy(this.allManagerVaccinationInfo, this.vaccinationInfoSort),
      total: this.allManagerVaccinationInfo.length,
    };
  }

  vaccinationInfoGroupChange(groups: GroupDescriptor[]): void {
    this.vaccinationInfoGroups = groups;
    this.vaccinationInfoGridView = process(this.allManagerVaccinationInfo, { group: this.vaccinationInfoGroups });
  }

  vaccinationInfoDataStateChange(state: DataStateChangeEvent): void {
    this.vaccinationInfoState = state;
    this.vaccinationInfoGridView = process(this.allManagerVaccinationInfo, this.vaccinationInfoState);
  }

  vaccinationInfoSortChange(sort: SortDescriptor[]): void {
    this.vaccinationInfoSort = sort;
    this.loadVaccinationInfo();
  }

  // Members under manager
  private loadManagersTeam(): void {
    this.managersTeamGridView = {
      data: orderBy(this.allMembersUnderManager, this.managersTeamSort),
      total: this.allMembersUnderManager.length,
    };
  }

  managersTeamGroupChange(groups: GroupDescriptor[]): void {
    this.managersTeamGroups = groups;
    this.managersTeamGridView = process(this.allMembersUnderManager, { group: this.managersTeamGroups });
  }

  managersTeamDataStateChange(state: DataStateChangeEvent): void {
    this.managersTeamState = state;
    this.managersTeamGridView = process(this.allMembersUnderManager, this.managersTeamState);
  }

  managersTeamSortChange(sort: SortDescriptor[]): void {
    this.managersTeamSort = sort;
    this.loadManagersTeam();
  }

  onVaccinationInfoFilter(inputValue: string): void {
    this.vaccinationInfoGridData = process(this.allManagerVaccinationInfo, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'bt_EmployeeID.bt_fullnamewithempid',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'bt_responded',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'odataRequestAccepted',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'odataVaccinationStatus',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  //#endregion Kendo Methods
}
