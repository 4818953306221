import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContextMenuSelectEvent } from '@progress/kendo-angular-menu';

// Models
import { DotsMenuItem } from './dots-action-item';

// Icons
import { SVGIcon, moreHorizontalIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'verde-dots-menu-button',
  templateUrl: './dots-menu-button.component.html',
  styleUrls: ['./dots-menu-button.component.scss'],
})
export class DotsMenuButtonComponent {
  @Input() items: any[] = [];
  @Input() visible: boolean = true;

  @Output() menuClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() menuItemClicked: EventEmitter<DotsMenuItem> = new EventEmitter<DotsMenuItem>();

  public svgMoreHorizontal: SVGIcon = moreHorizontalIcon;

  openMenu() {
    this.menuClicked.emit();
  }

  itemClicked(menuItem: ContextMenuSelectEvent) {
    this.menuItemClicked.emit({
      id: menuItem.index,
      ...menuItem.item,
    });
  }
}
