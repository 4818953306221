/* tslint:disable */
/* eslint-disable */
export enum ProcessesBtEmployeeLifecycleStage {
  Applicant = 'Applicant',
  ShortList = 'ShortList',
  Offer = 'Offer',
  Onboarding = 'Onboarding',
  Employed = 'Employed',
  OffBoarding = 'OffBoarding',
  Exited = 'Exited',
  Evaluate = 'Evaluate',
}
