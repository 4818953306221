<div *ngIf="!deviceTypeService.isMobile()">
  <kendo-gridlayout gap="2% 8%" [rows]="[]" [cols]="[{ width: '1fr' }, { width: '1fr' }]">
    <kendo-gridlayout-item [row]="1" [col]="1" [rowSpan]="8" style="margin-top: 2px; max-height: 75vh">
      <kendo-label text="Selected Structure Breakdown" class="label"></kendo-label>

      <!--Structure Layout-->
      <kendo-treelist
        #structureChangeTreelist
        class="sidepanel-treelist"
        [kendoTreeListFlatBinding]="miniStructure"
        itemKey="recordID"
        [isExpanded]="isExpanded"
        kendoTreeListExpandable
        idField="recordID"
        parentIdField="parentRecordID"
        kendoTreeListSelectable
        [selectable]="settings"
        [(selectedItems)]="selected"
        (selectionChange)="onRowSelect($event)"
      >
        <kendo-treelist-column [expandable]="true" field="name" title="Structure"></kendo-treelist-column>
        <kendo-treelist-column title="Organizational Level">
          <ng-template kendoTreeListCellTemplate let-dataItem>
            {{ getReadableName(dataItem.structureType) }}
          </ng-template>
        </kendo-treelist-column>
      </kendo-treelist>
    </kendo-gridlayout-item>
    <!--End Structure Layout-->

    <kendo-gridlayout-item [row]="1" [col]="2" style="margin-top: 2px">
      <div class="main">
        <div class="scrollable-content">
          <div *ngIf="currentStep === 1">
            <form [formGroup]="fg" autocomplete="off" class="k-form">
              <kendo-gridlayout gap="1% 8%" [rows]="[]" [cols]="[{ width: '1fr' }, { width: '1fr' }]">
                <!-------------------------------------------------------------------------------->
                <!--Selected-->
                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="2">
                  <kendo-formfield
                    class=""
                    *ngIf="
                      isSelectedStructureType([
                        BtStructureType.LegalEntity,
                        BtStructureType.Division,
                        BtStructureType.Department,
                        BtStructureType.PositionSeat
                      ]) && isSeatSelected == false
                    "
                  >
                    <kendo-label text="Selected"></kendo-label>
                    <input formControlName="selectedName" kendoTextBox [readonly]="true" />
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Selected-->
                <!-------------------------------------------------------------------------------->
                <!--Add Structure Type-->
                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="2">
                  <kendo-formfield
                    *ngIf="
                      isSelectedStructureType([
                        BtStructureType.LegalEntity,
                        BtStructureType.Division,
                        BtStructureType.Department,
                        BtStructureType.PositionSeat
                      ]) && isSeatSelected == false
                    "
                    class=""
                  >
                    <kendo-label [for]="structureType" text="Add Structure Type"></kendo-label>
                    <kendo-dropdownlist
                      #structureType
                      formControlName="structureType"
                      [data]="structureTypes"
                      textField="StructureType"
                      valueField="StructureType"
                      [valuePrimitive]="true"
                      required
                    >
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                        {{ getReadableName(dataItem?.StructureType) }}
                      </ng-template>
                      <ng-template kendoDropDownListItemTemplate let-dataItem>
                        {{ getReadableName(dataItem.StructureType) }}
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Add Structure Type-->
                <!-------------------------------------------------------------------------------->
                <!--Name-->
                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="2">
                  <kendo-formfield
                    *ngIf="
                      isSelectedStructureType([
                        BtStructureType.LegalEntity,
                        BtStructureType.Division,
                        BtStructureType.Department,
                        BtStructureType.PositionSeat
                      ]) && isSeatSelected == false
                    "
                  >
                    <kendo-label [for]="nameNew" text="Name"></kendo-label>
                    <input formControlName="name" kendoTextBox #nameNew required />
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Name-->
                <!-------------------------------------------------------------------------------->
                <!--Effective Date-->
                <kendo-gridlayout-item [row]="4" [col]="1">
                  <kendo-formfield
                    *ngIf="
                      isSelectedStructureType([BtStructureType.LegalEntity, BtStructureType.Division, BtStructureType.Department]) && isSeatSelected == false
                    "
                  >
                    <kendo-label [for]="effectiveDate" text="Effective Date"></kendo-label>
                    <kendo-datepicker formControlName="effectiveDate" #effectiveDate format="yyyy/MM/dd" required></kendo-datepicker>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Effective Date-->
                <!-------------------------------------------------------------------------------->
                <!--Motivation-->
                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="2">
                  <kendo-formfield
                    *ngIf="
                      isSelectedStructureType([BtStructureType.LegalEntity, BtStructureType.Division, BtStructureType.Department]) && isSeatSelected == false
                    "
                  >
                    <kendo-label [for]="motivation" text="Motivation"></kendo-label>
                    <kendo-textarea formControlName="motivation" #motivation [rows]="3" resizable="vertical" required></kendo-textarea>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Motivation-->
                <!-------------------------------------------------------------------------------->
                <!--Position-->
                <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="2">
                  <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false">
                    <kendo-label [for]="position" text="Position"></kendo-label>
                    <kendo-dropdownlist
                      #position
                      formControlName="position"
                      [data]="positionsData"
                      textField="bthr_description"
                      valueField="bthr_positionid"
                      (valueChange)="onPositionSelect($event)"
                      [valuePrimitive]="true"
                      [filterable]="true"
                      (filterChange)="handlePositionFilter($event)"
                      required
                    >
                    </kendo-dropdownlist>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Position-->
                <!-------------------------------------------------------------------------------->
                <!--Employee Type-->
                <kendo-gridlayout-item [row]="5" [col]="1">
                  <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false">
                    <kendo-label [for]="employeeTypes" text="Employee Type"></kendo-label>
                    <kendo-dropdownlist
                      #employeeTypes
                      formControlName="employeeTypes"
                      [data]="employeeTypeObj"
                      textField="bthr_employeetype"
                      valueField="bthr_employeetypeid"
                      id="employeeTypesDropdown"
                      name="employeeTypesDropdown"
                      [ngModel]
                      [disabled]=""
                      required
                    >
                      <ng-template kendoDropDownListNoDataTemplate>
                        <p></p>
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Employee Type-->
                <!-------------------------------------------------------------------------------->
                <!--Location-->
                <kendo-gridlayout-item [row]="5" [col]="2">
                  <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false" class="">
                    <kendo-label [for]="location" text="Location"></kendo-label>
                    <kendo-dropdownlist
                      #location
                      formControlName="location"
                      [data]="locationObj"
                      textField="bthr_locationname"
                      valueField="bthr_locationid"
                      id="locationDropdown"
                      name="locationDropdown"
                      [ngModel]
                      [disabled]=""
                      required
                    >
                      <ng-template kendoDropDownListNoDataTemplate>
                        <p></p>
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Location-->
                <!-------------------------------------------------------------------------------->
                <!--Effective Date-->
                <kendo-gridlayout-item [row]="6" [col]="1">
                  <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false">
                    <kendo-label [for]="effectiveDate" text="Effective Date"></kendo-label>
                    <kendo-datepicker formControlName="effectiveDate" #effectiveDate format="yyyy/MM/dd" required></kendo-datepicker>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Effective Date-->
                <!-------------------------------------------------------------------------------->
                <!--Motivation-->
                <kendo-gridlayout-item [row]="7" [col]="1" [colSpan]="2">
                  <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false">
                    <kendo-label [for]="motivation" text="Motivation"></kendo-label>
                    <kendo-textarea formControlName="motivation" #motivation [rows]="3" resizable="vertical" required></kendo-textarea>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Motivation-->
                <!-------------------------------------------------------------------------------->
                <!--Is Manager-->
                <div
                  *ngIf="
                    isSelectedStructureType([BtStructureType.PositionSeat]) && parentItem.seatStatus === BtPositionseat.NotApplicable && isSeatSelected == false
                  "
                  class=""
                >
                  <kendo-label [for]="isManager" text="Is Manager"></kendo-label>

                  <input formControlName="isManager" type="checkbox" kendoCheckBox #isManager required />
                </div>
                <!--End Is Manager-->
                <!-------------------------------------------------------------------------------->
                <!--Budget-->
                <kendo-gridlayout-item [row]="8" [col]="1" [colSpan]="2">
                  <kendo-formfield
                    *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false && displayBudgets == true"
                    class="radio-buttons"
                  >
                    <kendo-label class="radio-label" text="Budget"></kendo-label>
                    <div class="radio-buttons-container">
                      <ul class="k-radio-list k-list-horizontal">
                        <li class="k-radio-item">
                          <input type="radio" #yes value="yes" kendoRadioButton formControlName="budget" [checked]="true" />
                          <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                        </li>
                        <li class="k-radio-item">
                          <input type="radio" #no value="no" kendoRadioButton formControlName="budget" />
                          <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                        </li>
                      </ul>
                    </div>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Budget-->
                <!-------------------------------------------------------------------------------->
                <!--EE SEAT-->
                <kendo-gridlayout-item [row]="9" [col]="1" [colSpan]="2">
                  <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false" class="radio-buttons">
                    <kendo-label class="radio-label" text="EE Seat"></kendo-label>
                    <div class="radio-buttons-container">
                      <ul class="k-radio-list k-list-horizontal">
                        <li class="k-radio-item">
                          <input type="radio" #yes value="yes" kendoRadioButton formControlName="eeSeat" [checked]="true" />
                          <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                        </li>
                        <li class="k-radio-item">
                          <input type="radio" #no value="no" kendoRadioButton formControlName="eeSeat" />
                          <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                        </li>
                      </ul>
                    </div>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End EE SEAT-->
                <!-------------------------------------------------------------------------------->
                <!--Default Security-->
                <kendo-gridlayout-item [row]="10" [col]="1" [colSpan]="2">
                  <kendo-formfield
                    orientation="horizontal"
                    *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false && displaySP"
                    class="radio-buttons"
                  >
                    <kendo-label class="radio-label" text="Default Security"></kendo-label>
                    <div class="radio-buttons-container">
                      <ul class="k-radio-list k-list-horizontal">
                        <li class="k-radio-item">
                          <input
                            type="radio"
                            #yes
                            value="yes"
                            kendoRadioButton
                            formControlName="inheritSecurity"
                            [checked]="true"
                            (change)="onRadioSPChange(yes.value)"
                          />
                          <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                        </li>
                        <li class="k-radio-item">
                          <input type="radio" #no value="no" kendoRadioButton formControlName="inheritSecurity" (change)="onRadioSPChange(no.value)" />
                          <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                        </li>
                      </ul>
                    </div>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Default Security-->
                <!-------------------------------------------------------------------------------->
                <!--Default Job Description-->
                <kendo-gridlayout-item [row]="11" [col]="1" [colSpan]="2">
                  <kendo-formfield
                    orientation="horizontal"
                    *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false"
                    class="radio-buttons"
                  >
                    <kendo-label class="radio-label" text="Default Job Description"></kendo-label>
                    <div class="radio-buttons-container">
                      <ul class="k-radio-list k-list-horizontal">
                        <li class="k-radio-item">
                          <input
                            type="radio"
                            #yes
                            value="yes"
                            kendoRadioButton
                            formControlName="inheritJobDescription"
                            [checked]="true"
                            (change)="onRadioJDChange(yes.value)"
                          />
                          <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                        </li>
                        <li class="k-radio-item">
                          <input type="radio" #no value="no" kendoRadioButton formControlName="inheritJobDescription" (change)="onRadioJDChange(no.value)" />
                          <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                        </li>
                      </ul>
                    </div>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Default Job Description-->
                <!-------------------------------------------------------------------------------->
                <!--Default Allowances-->
                <kendo-gridlayout-item [row]="12" [col]="1" [colSpan]="2">
                  <kendo-formfield
                    orientation="horizontal"
                    *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false"
                    class="radio-buttons"
                  >
                    <kendo-label class="radio-label" text="Default Allowances"></kendo-label>
                    <div class="radio-buttons-container">
                      <ul class="k-radio-list k-list-horizontal">
                        <li class="k-radio-item">
                          <input
                            type="radio"
                            #yes
                            value="yes"
                            kendoRadioButton
                            formControlName="defaultAllowances"
                            [checked]="true"
                            (change)="onRadioDAChange(yes.value)"
                          />
                          <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                        </li>
                        <li class="k-radio-item">
                          <input type="radio" #no value="no" kendoRadioButton formControlName="defaultAllowances" (change)="onRadioDAChange(no.value)" />
                          <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                        </li>
                      </ul>
                    </div>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Default Allowances-->
                <!-------------------------------------------------------------------------------->
              </kendo-gridlayout>
            </form>
          </div>

          <div *ngIf="isSeatSelected == true">
            <p style="margin-top: 60%">Actions cannot be performed on this organizational level</p>
          </div>

          <!-------------------------------------------------------------------------------->
          <!--STEP 2-->

          <div style="margin-top: 20px" *ngIf="currentStep === 2">
            <form [formGroup]="fg2" autocomplete="off" class="k-form">
              <!--Default Security-->
              <kendo-formfield
                orientation="horizontal"
                *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false && displaySP"
                class="form-radio-field"
              >
                <kendo-label text="Default Security"></kendo-label>
                <div class="radio-buttons-container" style="margin-left: 6vh">
                  <ul class="k-radio-list k-list-horizontal position-ul">
                    <li class="">
                      <input
                        type="radio"
                        #yes
                        value="yes"
                        kendoRadioButton
                        formControlName="inheritSecurity2"
                        [checked]="true"
                        (change)="onRadioSPChange2('yes')"
                      />
                      <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                    </li>
                    <li class="">
                      <input type="radio" #no value="no" kendoRadioButton formControlName="inheritSecurity2" (change)="onRadioSPChange2('no')" />
                      <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                    </li>
                  </ul>
                </div>
              </kendo-formfield>
              <!--End Default Security-->
              <!-------------------------------------------------------------------------------->
              <!--From Security Profile-->

              <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="2">
                <kendo-formfield class="" *ngIf="currentStep === 2">
                  <kendo-label text="Default From"></kendo-label>
                  <input formControlName="defaultSPfrom" kendoTextBox [readonly]="true" />
                </kendo-formfield>
              </kendo-gridlayout-item>

              <!--End From Security Profile-->
              <!-------------------------------------------------------------------------------->
              <!--Security Profile-->
              <!--<kendo-gridlayout-item [row]="3"
                                     [col]="1"
                                     [colSpan]="2">
                <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false  && displaySP && (fg.controls.inheritSecurity.value === 'no' || fg.controls.inheritSecurity.value === 'yes')" class="form-field">
                  <kendo-label [for]="securityProfile"
                               text="Security Profile"></kendo-label>
                  <kendo-dropdownlist #securityProfile
                                      formControlName="securityProfile"
                                      [data]="securityProfiles"
                                      textField="bt_securityprofile.primaryName"
                                      valueField="bt_securityprofile.id"
                                      [disabled]="fg.controls.inheritSecurity.value === 'yes'">
                  </kendo-dropdownlist>
                </kendo-formfield>
              </kendo-gridlayout-item>-->

              <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="2">
                <kendo-formfield
                  class=""
                  *ngIf="
                    isSelectedStructureType([BtStructureType.PositionSeat]) &&
                    isSeatSelected == false &&
                    displaySP &&
                    (fg.controls.inheritSecurity.value === 'no' || fg.controls.inheritSecurity.value === 'yes')
                  "
                  class="form-field"
                >
                  <kendo-label [for]="securityProfile" text="Security Profile"></kendo-label>
                  <input formControlName="securityProfile" kendoTextBox [readonly]="true" />
                </kendo-formfield>
              </kendo-gridlayout-item>

              <!--End Security Profile-->
              <!-------------------------------------------------------------------------------->
              <!--Security Profile Comment-->
              <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="2">
                <kendo-formfield
                  *ngIf="
                    isSelectedStructureType([BtStructureType.PositionSeat]) &&
                    isSeatSelected == false &&
                    displaySP &&
                    fg.controls.inheritSecurity.value === 'no'
                  "
                  class="form-field"
                >
                  <kendo-label [for]="commentSP" text="Comment"></kendo-label>
                  <kendo-textarea formControlName="commentSP" #commentSP [rows]="3" resizable="vertical" required></kendo-textarea>
                </kendo-formfield>
              </kendo-gridlayout-item>
              <!--End Security Profile Comment-->
            </form>
          </div>

          <!--END STEP 2-->
          <!-------------------------------------------------------------------------------->
          <!--STEP 3-->
          <div style="margin-top: 20px" *ngIf="currentStep === 3">
            <form [formGroup]="fg3" autocomplete="off" class="k-form">
              <!--Default Job Description-->
              <kendo-formfield
                orientation="horizontal"
                *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false"
                class="form-radio-field"
              >
                <kendo-label text="Default Job Description"></kendo-label>
                <div class="radio-buttons-container" style="margin-left: 1.8vh">
                  <ul class="k-radio-list k-list-horizontal position-ul">
                    <li class="">
                      <input
                        type="radio"
                        #yes
                        value="yes"
                        kendoRadioButton
                        formControlName="inheritJobDescription2"
                        [checked]="true"
                        (change)="onRadioJDChange2(yes.value)"
                      />
                      <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                    </li>
                    <li class="">
                      <input type="radio" #no value="no" kendoRadioButton formControlName="inheritJobDescription2" (change)="onRadioJDChange2(no.value)" />
                      <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                    </li>
                  </ul>
                </div>
              </kendo-formfield>
              <!--End Default Job Description-->
              <!-------------------------------------------------------------------------------->
              <!--Upload Job Description-->
              <kendo-formfield
                *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false && fg3.controls.inheritJobDescription2.value === 'no'"
              >
                <kendo-label [for]="fileUpload" text="Upload Job Description"></kendo-label>
                <kendo-fileselect #fileUpload formControlName="fileUpload" [restrictions]="" (select)="onFileSelect($event)" required> </kendo-fileselect>
                <kendo-formhint>Allowed extensions, pdf</kendo-formhint>
              </kendo-formfield>
              <!--End Upload Job Description-->
              <!-------------------------------------------------------------------------------->
              <!--Download Attachment-->
              <!--<div *ngIf="currentStep === 3 && fg3.controls.inheritJobDescription2.value === 'yes' && selectedPosition.bt_JobDescriptionAttachment_Name != null">
                <label class=""
                       text=""></label>
                <ul>
                  <ng-container>
                    <ng-container>
                      <li>
                        <a href="javascript:void(0);"
                           (click)="downloadPdf()"
                           style="color: blue">
                          {{ selectedPosition.bt_JobDescriptionAttachment_Name }}
                        </a>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </div>-->
              <div
                *ngIf="currentStep === 3 && fg3.controls.inheritJobDescription2.value === 'yes' && selectedPosition.bt_JobDescriptionAttachment_Name == null"
              >
                <label class="" text=""></label>
                <ul>
                  <ng-container>
                    <ng-container>
                      <li>
                        <p>No Available Default Job Description</p>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </div>

              <div
                *ngIf="currentStep === 3 && fg3.controls.inheritJobDescription2.value === 'yes' && selectedPosition.bt_JobDescriptionAttachment_Name != null"
                id="pdf-div"
                style="height: 65vh"
              ></div>

              <!--End Download Attachment-->
            </form>
          </div>
          <!--END STEP 3-->
          <!-------------------------------------------------------------------------------->
          <!--STEP 4-->
          <div style="margin-top: 20px" *ngIf="currentStep === 4">
            <form [formGroup]="fg4" autocomplete="off" class="k-form">
              <!--Default Allowances-->
              <kendo-formfield
                orientation="horizontal"
                *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false"
                class="form-radio-field"
              >
                <kendo-label text="Default Allowances"></kendo-label>
                <div class="radio-buttons-container" style="margin-left: 3.8vh">
                  <ul class="k-radio-list k-list-horizontal position-ul">
                    <li class="">
                      <input
                        type="radio"
                        #yes
                        value="yes"
                        kendoRadioButton
                        formControlName="defaultAllowances2"
                        [checked]="true"
                        (change)="onRadioDAChange2(yes.value)"
                      />
                      <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                    </li>
                    <li class="">
                      <input type="radio" #no value="no" kendoRadioButton formControlName="defaultAllowances2" (change)="onRadioDAChange2(no.value)" />
                      <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                    </li>
                  </ul>
                </div>
              </kendo-formfield>
              <!--End Default Allowances-->
              <!-------------------------------------------------------------------------------->
              <!--Default Allowances Grid-->
              <kendo-grid
                [kendoGridBinding]="defaultAllowances"
                kendoGridSelectBy=""
                [sortable]="true"
                [groupable]="false"
                [reorderable]="true"
                [resizable]="true"
                [height]="100"
              >
                <kendo-grid-column field="bt_allowance.primaryName" title="Allowance Name" [width]="220"> </kendo-grid-column>
                <kendo-grid-column field="bt_value.value" title="Value" [width]="220">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    {{ formatNumber(dataItem.bt_value.value) }}
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
              <!--End Default Allowances Grid-->
              <!-------------------------------------------------------------------------------->
              <!--Allowances Comment-->
              <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="2">
                <kendo-formfield
                  *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false && fg4.controls.defaultAllowances2.value === 'no'"
                  class="form-field"
                >
                  <kendo-label [for]="commentAllowance" text="Comment"></kendo-label>
                  <kendo-textarea formControlName="commentAllowance" #commentAllowance [rows]="3" resizable="vertical" required></kendo-textarea>
                </kendo-formfield>
              </kendo-gridlayout-item>
              <!--End Allowances Comment-->
              <!-------------------------------------------------------------------------------->
            </form>
          </div>
          <!--END STEP 4-->
        </div>
      </div>
    </kendo-gridlayout-item>

    <!-- Navigation Buttons -->
    <div #appendTo class="fixed-buttons">
      <div class="form-buttons">
        <div class="k-form-buttons k-buttons-end">
          <button kendoButton themeColor="primary" *ngIf="currentStep > 1" (click)="previousStep()">Previous</button>
          <button
            kendoButton
            themeColor="primary"
            *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && currentStep < totalSteps"
            (click)="nextStep()"
            [disabled]=""
          >
            Next
          </button>
          <button
            kendoButton
            themeColor="primary"
            *ngIf="!isSelectedStructureType([BtStructureType.PositionSeat]) || (isSeatSelected && currentStep === totalSteps)"
            (click)="add()"
          >
            {{ isSelectedStructureType([BtStructureType.PositionSeat]) ? 'Submit' : 'Add' }}
          </button>

          <button kendoButton themeColor="primary" *ngIf="currentStep > 3" (click)="add()">
            {{ isSelectedStructureType([BtStructureType.PositionSeat]) ? 'Submit' : 'Add' }}
          </button>
        </div>
      </div>
    </div>
  </kendo-gridlayout>
</div>

<div *ngIf="deviceTypeService.isMobile()">
  <kendo-gridlayout gap="2% 8%" [rows]="[]" [cols]="[{ width: '1fr' }, { width: '1fr' }]">
    <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="2" style="margin-top: 2px">
      <div class="main">
        <div class="scrollable-content">
          <div *ngIf="currentStep === 1">
            <form [formGroup]="fg" autocomplete="off" class="k-form">
              <kendo-gridlayout gap="1% 8%" [rows]="[]" [cols]="[{ width: '1fr' }, { width: '1fr' }]">
                <!-------------------------------------------------------------------------------->
                <!--Selected-->
                <kendo-gridlayout-item [row]="1" [col]="1" [colSpan]="2">
                  <kendo-formfield
                    class=""
                    *ngIf="
                      isSelectedStructureType([
                        BtStructureType.LegalEntity,
                        BtStructureType.Division,
                        BtStructureType.Department,
                        BtStructureType.PositionSeat
                      ]) && isSeatSelected == false
                    "
                  >
                    <kendo-label text="Selected"></kendo-label>
                    <input formControlName="selectedName" kendoTextBox [readonly]="true" />
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Selected-->
                <!-------------------------------------------------------------------------------->
                <!--Add Structure Type-->
                <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="2">
                  <kendo-formfield
                    *ngIf="
                      isSelectedStructureType([
                        BtStructureType.LegalEntity,
                        BtStructureType.Division,
                        BtStructureType.Department,
                        BtStructureType.PositionSeat
                      ]) && isSeatSelected == false
                    "
                    class=""
                  >
                    <kendo-label [for]="structureType" text="Add Structure Type"></kendo-label>
                    <kendo-dropdownlist
                      #structureType
                      formControlName="structureType"
                      [data]="structureTypes"
                      textField="StructureType"
                      valueField="StructureType"
                      [valuePrimitive]="true"
                      required
                    >
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                        {{ getReadableName(dataItem?.StructureType) }}
                      </ng-template>
                      <ng-template kendoDropDownListItemTemplate let-dataItem>
                        {{ getReadableName(dataItem.StructureType) }}
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Add Structure Type-->
                <!-------------------------------------------------------------------------------->
                <!--Name-->
                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="2">
                  <kendo-formfield
                    *ngIf="
                      isSelectedStructureType([BtStructureType.LegalEntity, BtStructureType.Division, BtStructureType.Department]) && isSeatSelected == false
                    "
                  >
                    <kendo-label [for]="nameNew" text="Name"></kendo-label>
                    <input formControlName="name" kendoTextBox #nameNew required />
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Name-->
                <!-------------------------------------------------------------------------------->
                <!--Effective Date-->
                <kendo-gridlayout-item [row]="4" [col]="1">
                  <kendo-formfield
                    *ngIf="
                      isSelectedStructureType([BtStructureType.LegalEntity, BtStructureType.Division, BtStructureType.Department]) && isSeatSelected == false
                    "
                  >
                    <kendo-label [for]="effectiveDate" text="Effective Date"></kendo-label>
                    <kendo-datepicker formControlName="effectiveDate" #effectiveDate format="yyyy/MM/dd" required></kendo-datepicker>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Effective Date-->
                <!-------------------------------------------------------------------------------->
                <!--Motivation-->
                <kendo-gridlayout-item [row]="5" [col]="1" [colSpan]="2">
                  <kendo-formfield
                    *ngIf="
                      isSelectedStructureType([BtStructureType.LegalEntity, BtStructureType.Division, BtStructureType.Department]) && isSeatSelected == false
                    "
                  >
                    <kendo-label [for]="motivation" text="Motivation"></kendo-label>
                    <kendo-textarea formControlName="motivation" #motivation [rows]="3" resizable="vertical" required></kendo-textarea>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Motivation-->
                <!-------------------------------------------------------------------------------->
                <!--Position-->
                <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="2">
                  <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false">
                    <kendo-label [for]="position" text="Position"></kendo-label>
                    <kendo-dropdownlist
                      #position
                      formControlName="position"
                      [data]="positionsData"
                      textField="bthr_description"
                      valueField="bthr_positionid"
                      (valueChange)="onPositionSelect($event)"
                      [valuePrimitive]="true"
                      [filterable]="true"
                      (filterChange)="handlePositionFilter($event)"
                      required
                    >
                    </kendo-dropdownlist>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Position-->
                <!-------------------------------------------------------------------------------->
                <!--Employee Type-->
                <kendo-gridlayout-item [row]="4" [col]="1">
                  <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false">
                    <kendo-label [for]="employeeTypes" text="Employee Type"></kendo-label>
                    <kendo-dropdownlist
                      #employeeTypes
                      formControlName="employeeTypes"
                      [data]="employeeTypeObj"
                      textField="bthr_employeetype"
                      valueField="bthr_employeetypeid"
                      id="employeeTypesDropdown"
                      name="employeeTypesDropdown"
                      [ngModel]
                      [disabled]=""
                      required
                    >
                      <ng-template kendoDropDownListNoDataTemplate>
                        <p></p>
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Employee Type-->
                <!-------------------------------------------------------------------------------->
                <!--Location-->
                <kendo-gridlayout-item [row]="4" [col]="2">
                  <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false" class="">
                    <kendo-label [for]="location" text="Location"></kendo-label>
                    <kendo-dropdownlist
                      #location
                      formControlName="location"
                      [data]="locationObj"
                      textField="bthr_locationname"
                      valueField="bthr_locationid"
                      id="locationDropdown"
                      name="locationDropdown"
                      [ngModel]
                      [disabled]=""
                      required
                    >
                      <ng-template kendoDropDownListNoDataTemplate>
                        <p></p>
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Location-->
                <!-------------------------------------------------------------------------------->
                <!--Effective Date-->
                <kendo-gridlayout-item [row]="5" [col]="1">
                  <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false">
                    <kendo-label [for]="effectiveDate" text="Effective Date"></kendo-label>
                    <kendo-datepicker formControlName="effectiveDate" #effectiveDate format="yyyy/MM/dd" required></kendo-datepicker>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Effective Date-->
                <!-------------------------------------------------------------------------------->
                <!--Motivation-->
                <kendo-gridlayout-item [row]="6" [col]="1" [colSpan]="2">
                  <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false">
                    <kendo-label [for]="motivation" text="Motivation"></kendo-label>
                    <kendo-textarea formControlName="motivation" #motivation [rows]="3" resizable="vertical" required></kendo-textarea>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Motivation-->
                <!-------------------------------------------------------------------------------->
                <!--Is Manager-->
                <div
                  *ngIf="
                    isSelectedStructureType([BtStructureType.PositionSeat]) && parentItem.seatStatus === BtPositionseat.NotApplicable && isSeatSelected == false
                  "
                  class=""
                >
                  <kendo-label [for]="isManager" text="Is Manager"></kendo-label>

                  <input formControlName="isManager" type="checkbox" kendoCheckBox #isManager required />
                </div>
                <!--End Is Manager-->
                <!-------------------------------------------------------------------------------->
                <!--Budget-->
                <kendo-gridlayout-item [row]="7" [col]="1">
                  <kendo-formfield
                    *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false && displayBudgets == true"
                    class="radio-buttons2"
                  >
                    <kendo-label class="radio-label" text="Budget"></kendo-label>
                    <div class="radio-buttons-container">
                      <ul class="k-radio-list k-list-horizontal">
                        <li class="k-radio-item">
                          <input type="radio" #yes value="yes" kendoRadioButton formControlName="budget" [checked]="true" />
                          <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                        </li>
                        <li class="k-radio-item">
                          <input type="radio" #no value="no" kendoRadioButton formControlName="budget" />
                          <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                        </li>
                      </ul>
                    </div>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Budget-->
                <!-------------------------------------------------------------------------------->
                <!--EE SEAT-->
                <kendo-gridlayout-item [row]="8" [col]="1">
                  <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false" class="radio-buttons2">
                    <kendo-label class="radio-label" text="EE Seat"></kendo-label>
                    <div class="radio-buttons-container">
                      <ul class="k-radio-list k-list-horizontal">
                        <li class="k-radio-item">
                          <input type="radio" #yes value="yes" kendoRadioButton formControlName="eeSeat" [checked]="true" />
                          <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                        </li>
                        <li class="k-radio-item">
                          <input type="radio" #no value="no" kendoRadioButton formControlName="eeSeat" />
                          <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                        </li>
                      </ul>
                    </div>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End EE SEAT-->
                <!-------------------------------------------------------------------------------->
                <!--Default Security-->
                <kendo-gridlayout-item [row]="9" [col]="1">
                  <kendo-formfield
                    orientation="horizontal"
                    *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false && displaySP"
                    class="radio-buttons2"
                  >
                    <kendo-label class="radio-label" text="Default Security"></kendo-label>
                    <div class="radio-buttons-container">
                      <ul class="k-radio-list k-list-horizontal">
                        <li class="k-radio-item">
                          <input
                            type="radio"
                            #yes
                            value="yes"
                            kendoRadioButton
                            formControlName="inheritSecurity"
                            [checked]="true"
                            (change)="onRadioSPChange(yes.value)"
                          />
                          <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                        </li>
                        <li class="k-radio-item">
                          <input type="radio" #no value="no" kendoRadioButton formControlName="inheritSecurity" (change)="onRadioSPChange(no.value)" />
                          <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                        </li>
                      </ul>
                    </div>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Default Security-->
                <!-------------------------------------------------------------------------------->
                <!--Default Job Description-->
                <kendo-gridlayout-item [row]="10" [col]="1">
                  <kendo-formfield
                    orientation="horizontal"
                    *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false"
                    class="radio-buttons2"
                  >
                    <kendo-label class="radio-label" text="Default Job Description"></kendo-label>
                    <div class="radio-buttons-container">
                      <ul class="k-radio-list k-list-horizontal">
                        <li class="k-radio-item">
                          <input
                            type="radio"
                            #yes
                            value="yes"
                            kendoRadioButton
                            formControlName="inheritJobDescription"
                            [checked]="true"
                            (change)="onRadioJDChange(yes.value)"
                          />
                          <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                        </li>
                        <li class="k-radio-item">
                          <input type="radio" #no value="no" kendoRadioButton formControlName="inheritJobDescription" (change)="onRadioJDChange(no.value)" />
                          <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                        </li>
                      </ul>
                    </div>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Default Job Description-->
                <!-------------------------------------------------------------------------------->
                <!--Default Allowances-->
                <kendo-gridlayout-item [row]="11" [col]="1">
                  <kendo-formfield
                    orientation="horizontal"
                    *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false"
                    class="radio-buttons2"
                  >
                    <kendo-label class="radio-label" text="Default Allowances"></kendo-label>
                    <div class="radio-buttons-container">
                      <ul class="k-radio-list k-list-horizontal">
                        <li class="k-radio-item">
                          <input
                            type="radio"
                            #yes
                            value="yes"
                            kendoRadioButton
                            formControlName="defaultAllowances"
                            [checked]="true"
                            (change)="onRadioDAChange(yes.value)"
                          />
                          <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                        </li>
                        <li class="k-radio-item">
                          <input type="radio" #no value="no" kendoRadioButton formControlName="defaultAllowances" (change)="onRadioDAChange(no.value)" />
                          <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                        </li>
                      </ul>
                    </div>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!--End Default Allowances-->
                <!-------------------------------------------------------------------------------->
              </kendo-gridlayout>
            </form>
          </div>

          <div *ngIf="isSeatSelected == true">
            <p style="margin-top: 60%">Actions cannot be performed on this organizational level</p>
          </div>

          <!-------------------------------------------------------------------------------->
          <!--STEP 2-->

          <div style="margin-top: 20px" *ngIf="currentStep === 2">
            <form [formGroup]="fg2" autocomplete="off" class="k-form">
              <!--Default Security-->
              <kendo-formfield
                orientation="horizontal"
                *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false && displaySP"
                class="form-radio-field"
              >
                <kendo-label text="Default Security"></kendo-label>
                <div class="radio-buttons-container" style="margin-left: 6vh">
                  <ul class="k-radio-list k-list-horizontal position-ul">
                    <li class="">
                      <input
                        type="radio"
                        #yes
                        value="yes"
                        kendoRadioButton
                        formControlName="inheritSecurity2"
                        [checked]="true"
                        (change)="onRadioSPChange2('yes')"
                      />
                      <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                    </li>
                    <li class="">
                      <input type="radio" #no value="no" kendoRadioButton formControlName="inheritSecurity2" (change)="onRadioSPChange2('no')" />
                      <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                    </li>
                  </ul>
                </div>
              </kendo-formfield>
              <!--End Default Security-->
              <!-------------------------------------------------------------------------------->
              <!--From Security Profile-->

              <kendo-gridlayout-item [row]="2" [col]="1" [colSpan]="2">
                <kendo-formfield class="" *ngIf="currentStep === 2">
                  <kendo-label text="Default From"></kendo-label>
                  <input formControlName="defaultSPfrom" kendoTextBox [readonly]="true" />
                </kendo-formfield>
              </kendo-gridlayout-item>

              <!--End From Security Profile-->
              <!-------------------------------------------------------------------------------->
              <!--Security Profile-->
              <!--<kendo-gridlayout-item [row]="3"
                                     [col]="1"
                                     [colSpan]="2">
                <kendo-formfield *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false  && displaySP && (fg.controls.inheritSecurity.value === 'no' || fg.controls.inheritSecurity.value === 'yes')" class="form-field">
                  <kendo-label [for]="securityProfile"
                               text="Security Profile"></kendo-label>
                  <kendo-dropdownlist #securityProfile
                                      formControlName="securityProfile"
                                      [data]="securityProfiles"
                                      textField="bt_securityprofile.primaryName"
                                      valueField="bt_securityprofile.id"
                                      [disabled]="fg.controls.inheritSecurity.value === 'yes'">
                  </kendo-dropdownlist>
                </kendo-formfield>
              </kendo-gridlayout-item>-->

              <kendo-gridlayout-item [row]="3" [col]="1" [colSpan]="2">
                <kendo-formfield
                  class=""
                  *ngIf="
                    isSelectedStructureType([BtStructureType.PositionSeat]) &&
                    isSeatSelected == false &&
                    displaySP &&
                    (fg.controls.inheritSecurity.value === 'no' || fg.controls.inheritSecurity.value === 'yes')
                  "
                  class="form-field"
                >
                  <kendo-label [for]="securityProfile" text="Security Profile"></kendo-label>
                  <input formControlName="securityProfile" kendoTextBox [readonly]="true" />
                </kendo-formfield>
              </kendo-gridlayout-item>

              <!--End Security Profile-->
              <!-------------------------------------------------------------------------------->
              <!--Security Profile Comment-->
              <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="2">
                <kendo-formfield
                  *ngIf="
                    isSelectedStructureType([BtStructureType.PositionSeat]) &&
                    isSeatSelected == false &&
                    displaySP &&
                    fg.controls.inheritSecurity.value === 'no'
                  "
                  class="form-field"
                >
                  <kendo-label [for]="commentSP" text="Comment"></kendo-label>
                  <kendo-textarea formControlName="commentSP" #commentSP [rows]="3" resizable="vertical" required></kendo-textarea>
                </kendo-formfield>
              </kendo-gridlayout-item>
              <!--End Security Profile Comment-->
            </form>
          </div>

          <!--END STEP 2-->
          <!-------------------------------------------------------------------------------->
          <!--STEP 3-->
          <div style="margin-top: 20px" *ngIf="currentStep === 3">
            <form [formGroup]="fg3" autocomplete="off" class="k-form">
              <!--Default Job Description-->
              <kendo-formfield
                orientation="horizontal"
                *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false"
                class="form-radio-field"
              >
                <kendo-label text="Default Job Description"></kendo-label>
                <div class="radio-buttons-container" style="margin-left: 1.8vh">
                  <ul class="k-radio-list k-list-horizontal position-ul">
                    <li class="">
                      <input
                        type="radio"
                        #yes
                        value="yes"
                        kendoRadioButton
                        formControlName="inheritJobDescription2"
                        [checked]="true"
                        (change)="onRadioJDChange2(yes.value)"
                      />
                      <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                    </li>
                    <li class="">
                      <input type="radio" #no value="no" kendoRadioButton formControlName="inheritJobDescription2" (change)="onRadioJDChange2(no.value)" />
                      <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                    </li>
                  </ul>
                </div>
              </kendo-formfield>
              <!--End Default Job Description-->
              <!-------------------------------------------------------------------------------->
              <!--Upload Job Description-->
              <kendo-formfield
                *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false && fg3.controls.inheritJobDescription2.value === 'no'"
              >
                <kendo-label [for]="fileUpload" text="Upload Job Description"></kendo-label>
                <kendo-fileselect #fileUpload formControlName="fileUpload" [restrictions]="" (select)="onFileSelect($event)" required> </kendo-fileselect>
                <kendo-formhint>Allowed extensions, pdf</kendo-formhint>
              </kendo-formfield>
              <!--End Upload Job Description-->
              <!-------------------------------------------------------------------------------->
              <!--Job Description Comment-->
              <!--End Job Description Commentt-->
              <!-------------------------------------------------------------------------------->
              <!--Download Attachment-->
              <!--<div *ngIf="currentStep === 3 && fg3.controls.inheritJobDescription2.value === 'yes' && selectedPosition.bt_JobDescriptionAttachment_Name != null">
    <label class=""
           text=""></label>
    <ul>
      <ng-container>
        <ng-container>
          <li>
            <a href="javascript:void(0);"
               (click)="downloadPdf()"
               style="color: blue">
              {{ selectedPosition.bt_JobDescriptionAttachment_Name }}
            </a>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>-->
              <div
                *ngIf="currentStep === 3 && fg3.controls.inheritJobDescription2.value === 'yes' && selectedPosition.bt_JobDescriptionAttachment_Name == null"
              >
                <label class="" text=""></label>
                <ul>
                  <ng-container>
                    <ng-container>
                      <li>
                        <p>No Available Default Job Description</p>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </div>

              <div
                *ngIf="currentStep === 3 && fg3.controls.inheritJobDescription2.value === 'yes' && selectedPosition.bt_JobDescriptionAttachment_Name != null"
                id="pdf-div"
                style="height: 65vh"
              ></div>
              <!--End Download Attachment-->
            </form>
          </div>
          <!--END STEP 3-->
          <!-------------------------------------------------------------------------------->
          <!--STEP 4-->
          <div style="margin-top: 20px" *ngIf="currentStep === 4">
            <form [formGroup]="fg4" autocomplete="off" class="k-form">
              <!--Default Allowances-->
              <kendo-formfield
                orientation="horizontal"
                *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false"
                class="form-radio-field"
              >
                <kendo-label text="Default Allowances"></kendo-label>
                <div class="radio-buttons-container" style="margin-left: 3.8vh">
                  <ul class="k-radio-list k-list-horizontal position-ul">
                    <li class="">
                      <input
                        type="radio"
                        #yes
                        value="yes"
                        kendoRadioButton
                        formControlName="defaultAllowances2"
                        [checked]="true"
                        (change)="onRadioDAChange2(yes.value)"
                      />
                      <kendo-label class="k-radio-label" [for]="yes" text="Yes"></kendo-label>
                    </li>
                    <li class="">
                      <input type="radio" #no value="no" kendoRadioButton formControlName="defaultAllowances2" (change)="onRadioDAChange2(no.value)" />
                      <kendo-label class="k-radio-label" [for]="no" text="No"></kendo-label>
                    </li>
                  </ul>
                </div>
              </kendo-formfield>
              <!--End Default Allowances-->
              <!-------------------------------------------------------------------------------->
              <!--Default Allowances Grid-->
              <kendo-grid
                [kendoGridBinding]="defaultAllowances"
                kendoGridSelectBy=""
                [sortable]="true"
                [groupable]="false"
                [reorderable]="true"
                [resizable]="true"
                [height]="100"
              >
                <kendo-grid-column field="bt_allowance.primaryName" title="Allowance Name" [width]="220"> </kendo-grid-column>
                <kendo-grid-column field="bt_value.value" title="Value" [width]="220"> </kendo-grid-column>
              </kendo-grid>
              <!--End Default Allowances Grid-->
              <!-------------------------------------------------------------------------------->
              <!--Allowances Comment-->
              <kendo-gridlayout-item [row]="4" [col]="1" [colSpan]="2">
                <kendo-formfield
                  *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && isSeatSelected == false && fg4.controls.defaultAllowances2.value === 'no'"
                  class="form-field"
                >
                  <kendo-label [for]="commentAllowance" text="Comment"></kendo-label>
                  <kendo-textarea formControlName="commentAllowance" #commentAllowance [rows]="3" resizable="vertical" required></kendo-textarea>
                </kendo-formfield>
              </kendo-gridlayout-item>
              <!--End Allowances Comment-->
              <!-------------------------------------------------------------------------------->
            </form>
          </div>
          <!--END STEP 4-->
        </div>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>

  <!-- Navigation Buttons -->
  <div #appendTo class="fixed-buttons">
    <div class="form-buttons">
      <div class="k-form-buttons k-buttons-end">
        <button kendoButton themeColor="primary" *ngIf="currentStep > 1" (click)="previousStep()">Previous</button>
        <button
          kendoButton
          themeColor="primary"
          *ngIf="isSelectedStructureType([BtStructureType.PositionSeat]) && currentStep < totalSteps"
          (click)="nextStep()"
          [disabled]=""
        >
          Next
        </button>
        <button
          kendoButton
          themeColor="primary"
          *ngIf="!isSelectedStructureType([BtStructureType.PositionSeat]) || (isSeatSelected && currentStep === totalSteps)"
          (click)="add()"
        >
          {{ isSelectedStructureType([BtStructureType.PositionSeat]) ? 'Submit' : 'Add' }}
        </button>

        <button kendoButton themeColor="primary" *ngIf="currentStep > 3" (click)="add()">
          {{ isSelectedStructureType([BtStructureType.PositionSeat]) ? 'Submit' : 'Add' }}
        </button>
      </div>
    </div>
  </div>
</div>
