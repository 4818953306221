/* tslint:disable */
/* eslint-disable */
export interface EmployeeReviewFiltered {
  _bt_summary_value?: null | string;
  bt_attachmentfilename?: null | string;
  bt_commission?: null | boolean;
  bt_employeecompleted?: boolean;
  bt_employeerecallaccepted?: null | boolean;
  bt_employeerecallaccepteddate?: null | string;
  bt_kpi?: null | boolean;
  bt_managercompleted?: boolean;
  bt_managerrecallrequest?: null | boolean;
  bt_managerrecallrequestdate?: null | string;
  bt_reviewenhancement?: null | boolean;
  bt_totalscore?: null | number;
  bthr_employeeperformancereviewid?: null | string;
  bthr_name?: null | string;
  bthr_reviewdate?: null | string;
  bthr_reviewperiodenddate?: null | string;
  bthr_reviewperiodstartdate?: null | string;
  bthr_summaryofresults?: null | string;
  odataCommission?: null | string;
  odataEmployeeCompleted?: null | string;
  odataKPI?: null | string;
  odataManagerCompleted?: null | string;
  odataSummary?: null | string;
}
