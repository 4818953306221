import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControlDirective, FormGroup } from '@angular/forms';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { GroupDescriptor, orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import {
  DelegationsApiDelegationsService,
  DelegationsBtSuccessorType,
  DelegationsSuccessionPlanSuccessor,
  DvLegalEntities,
  SharedApiFileService,
  SharedApiLegalEntitiesService,
  SharedApiSuccessionsService,
  Successions,
  SuccessionsManager,
  User,
  UserApiUserService,
  UserAppConfigDto,
  UserUserDto,
} from '@verde/api';
import { DeviceTypeService, GridPagingService, UserService } from '@verde/core';
import { DotsMenuItem, ModalService, SidePanelWidth, VerdeApprovalService } from '@verde/shared';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'verde-successions-tab',
  templateUrl: './successions-tab.component.html',
  styleUrls: ['./successions-tab.component.scss'],
})
export class SuccessionsTabComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();

  //#region Variable Declarations
  // Users profile
  user: UserUserDto;

  config: UserAppConfigDto;

  maxFileSize: number = 5242880;

  trainingPlanFile: File;

  disableAnimation: boolean;

  showModal: boolean = false;
  slideDownModal: boolean = false;

  trainingPlanBase64: string;
  trainingPlanFileName: string;

  dotMenuItems: DotsMenuItem[] = [];

  // API Array
  allSuccessionPlans: Array<Successions> = new Array<Successions>();
  successionPlanManager: SuccessionsManager;
  allLegalEntities: Array<DvLegalEntities> = new Array<DvLegalEntities>();

  postSuccessorBody: Successions = {};

  selectedSuccessor: DelegationsSuccessionPlanSuccessor = {};
  //selectedSuccessor: Successions = {};

  successorEmployeeDetails: UserUserDto = {};

  selectedEmployeeId: string = '';

  todaysDate = moment().format('YYYY-MM-DD');

  confirmationMessage: string = '';
  userLegalEntity: string = '';

  optionValue = 'Choose an option';

  //newSuccessorDropdownValue: string = 'default';
  newLegalEntityDropdownValue: string = 'default';
  newSuccessorTypeDropdownValue: string = 'default';
  newRetentionPotentialDropdownValue: string = 'default';
  newRetirementStatusDropdownValue: string = 'default';
  newTransferPotentialDropdownValue: string = 'default';
  newReadyNowDropdownValue: string = 'default';
  newReadyInDropdownValue: string = 'default';
  newGapStatusDropdownValue: string = 'default';
  newInternalRequiredDropdownValue: string = 'default';
  newExternalRequiredDropdownValue: string = 'default';
  newTrainingPlanDropdownValue: string = 'default';

  defaultValue: string = 'default';
  checkDefaultPattern: RegExp = /^(?!default$).*/;
  checkOptionPattern: RegExp = /^(?!Choose an option).*/;

  screen1SubmitConfirmation: boolean = false;
  screen1CancelConfirmation: boolean = false;

  screen1SubmitText: string = 'Submit';

  allEmployeesFilteredData: Array<User> = new Array<User>();

  employeeDropdownMessage: string = 'Type at least 3 characters';

  newSuccessor1DefaultValue = {
    newSuccessorType: null,
    newLegalEntityDropdown: null,
    newLegalEntityHard: null,
    newSuccessor: null,
    newPriority: null,
    newReviewDate: null,
    newSuccessorComment: null,
  };

  newSuccessor2DefaultValue = {
    newRetentionPotential: null,
    newRetirementStatus: null,
    newTransferPotential: null,
    newReadyNow: null,
    newReadyIn: null,
    newGapStatus: null,
    newInternalRequired: null,
    newExternalRequired: null,
    newTrainingPlan: null,
    newInternalComment: null,
    newExternalComment: null,
  };

  editInternalSuccessorDefaultValue = {
    editReviewDate: null,
    editPriority: null,
    editSuccessorComment: null,
    editRetentionPotential: null,
    editRetirementStatus: null,
    editTransferPotential: null,
    editReadyNow: null,
    editReadyIn: null,
    editGapStatus: null,
    editInternalRequired: null,
    editExternalRequired: null,
    editTrainingPlan: null,
    editInternalComment: null,
    editExternalComment: null,
  };

  //#region Kendo variables
  // Successions
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  public successionPlansGridData: GridDataResult[] | Successions[];

  successionPlansGridView: GridDataResult;

  successionPlansState: State = {
    skip: 0,
    take: 5,
  };

  successionPlansSort: SortDescriptor[] = [
    {
      field: 'bt_priorityno',
      dir: 'asc',
    },
  ];

  successionPlansGroups: GroupDescriptor[];

  successionPlansSelection: any[] = [];

  successionPlansSelectedCallback = (args) => args.dataItem;

  // new api logic

  allSuccessSuccessors: GridDataResult;

  successorState: State = {
    skip: 0,
    take: this.gridPagingService.pageSize - 2,
    group: [],
    filter: { filters: [], logic: 'and' },
    sort: [],
  };

  successSuccessorsLoading: boolean = false;

  //#endregion Kendo variables

  //#endregion Variable Declarations

  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private gridPagingService: GridPagingService,
    private sharedApiLegalEntitiesService: SharedApiLegalEntitiesService,
    private sharedApiSuccessionsService: SharedApiSuccessionsService,
    private userApiUserService: UserApiUserService,
    private sharedApiFileService: SharedApiFileService,
    public deviceTypeService: DeviceTypeService,
    private sidebarService: VerdeApprovalService,
    private delegationsApiDelegationsService: DelegationsApiDelegationsService,
  ) {}

  ngOnInit(): void {
    this.spinner.show('successionstab');

    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => {
      this.config = config;
      this.user = config.user;
    });

    this.userLegalEntity = this.user?.legalEntityName;

    //this.getSuccessionPlans();
    this.getSuccessionPlanManager();
    this.getAllLegalEntities();

    this.userService.disableAnimation$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.disableAnimation = data;
    });
  }

  ngAfterViewInit(): void {
    this.gridPagingService.changeGridID('oldsuccessorsgird');

    setTimeout(() => {
      console.log('pageSize', this.gridPagingService.pageSize);
      this.successorState.take = this.gridPagingService.pageSize - 2;

      this.getSuccessPlanSuccessors();
    }, 100);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  refresh() {
    this.spinner.show('successionstab');
    this.getSuccessPlanSuccessors();
    //this.getSuccessionPlans();
    this.getSuccessionPlanManager();
  }

  // #region Three Dot Menu Logic
  menuClicked(parentItem: DelegationsSuccessionPlanSuccessor) {
    console.log('parentItem', parentItem);
    this.selectedSuccessor = parentItem;
    this.selectedEmployeeId = parentItem.bt_successoremployeeid?.id;
    this.dotMenuItems = [];
    let tempItems: DotsMenuItem[] = [];

    if (parentItem.bt_successortype == DelegationsBtSuccessorType.Internal) {
      tempItems.push({ id: 'edit', text: 'Edit' });
    }

    tempItems.push({ id: 'delete', text: 'Delete' });

    if (tempItems.length === 0) {
      tempItems.push({ text: 'No Options' });
    }

    this.dotMenuItems = [...tempItems];
  }

  menuItemClicked(menuItem: DotsMenuItem, myForm: FormGroup) {
    if (menuItem.id === 'edit') {
      //this.openSuccessorEdit(myForm);

      this.sidebarService.setSidebarType('successor-details');
      this.sidebarService.setSidebarSize(SidePanelWidth.Third);
      this.sidebarService.setSuccessorDetails({ successor: this.selectedSuccessor, type: 'edit' });
      this.sidebarService.setShowSidebar(true);

      this.sidebarService
        .getShowSidebar()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((data) => {
          if (!data) {
            this.refresh();
          }
        });
    } else if (menuItem.id === 'delete') {
      this.modalService.open('confirmSuccessorDeleteModal');
    }
  }

  // #endregion Three Dot Menu Logic

  //#region API CALLS
  //#region GET CALLS
  // getSuccessionPlans() {
  //   this.sharedApiSuccessionsService
  //     .getSuccessionPlans({ userID: this.user?.employeeId, legalEntityID: this.user?.legalEntityId })
  //     .pipe(take(1))
  //     .subscribe(
  //       (ret) => {
  //         this.allSuccessionPlans = ret;
  //       },
  //       (error) => {
  //         console.error(error);
  //       },
  //       () => {
  //         this.successionPlansGridData = this.allSuccessionPlans;

  //         this.loadSuccessions();
  //         this.spinner.hide('successionstab');
  //       },
  //     );
  // }

  successorDataStateChange(state: DataStateChangeEvent): void {
    this.successorState = state;

    this.getSuccessPlanSuccessors();
  }

  getSuccessPlanSuccessors() {
    this.successSuccessorsLoading = true;
    let sortVal = '';

    if (this.successorState.sort.length > 0) {
      if (this.successorState.sort[0].dir === 'desc') {
        sortVal = '-' + this.successorState.sort[0].field;
      } else {
        sortVal = this.successorState.sort[0].field;
      }
    }

    this.delegationsApiDelegationsService
      .getHrSuccessionsSuccessors({
        body: {
          successManagerID: this.user?.employeeId,
          query: {
            count: false,
            take: this.successorState.take,
            skip: this.successorState.skip,
            sort: sortVal,
            filter: '',
            search: '',
            searchColumns: [''],
            pageNumber: 1,
          },
        },
      })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log('getHrSuccessionsSuccessors', ret);
          this.allSuccessSuccessors = { data: ret.results, total: ret.count };
          this.successSuccessorsLoading = false;

          console.log('', ret.results[2].bt_readyin);
        },
        (error) => {
          console.error(error);
        },
      );
  }

  getSuccessionPlanManager() {
    this.sharedApiSuccessionsService
      .getSuccessionPlanManagers({ userID: this.user?.employeeId, legalEntityID: this.user?.legalEntityId })
      .pipe(take(1))
      .subscribe((ret) => {
        this.successionPlanManager = ret[0];
      });
  }

  // GET All Legal Entities
  getAllLegalEntities() {
    this.sharedApiLegalEntitiesService
      .getAllLegalEntities()
      .pipe(take(1))
      .subscribe((ret) => {
        this.allLegalEntities = ret;
      });
  }

  // GET Training Plan File
  public getTrainingPlanFile() {
    if (this.allSuccessionPlans.length > 0 && this.selectedSuccessor.bt_trainingplanattachmentname != null) {
      //this.spinner.show('reviewfile');
      this.sharedApiFileService
        .getTrainingPlan({ planid: this.selectedSuccessor.bt_successplanid })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            this.trainingPlanBase64 = ret;

            const imageName = this.allSuccessionPlans.filter((t) => {
              if (t.bt_successplanid === this.selectedSuccessor.bt_successplanid) {
                return t;
              }
            });

            this.trainingPlanFileName = imageName[0].bt_trainingplanattachmentname;
          },
          (error) => {
            console.error(error);
          },
          () => {
            //this.spinner.hide('reviewfile');
          },
        );
    } else {
      //this.spinner.hide('reviewfile');
    }
  }

  //#endregion GET CALLS
  //#region POST CALLS
  //POST Internal Successor
  postInternalSuccessor(myForm1: FormGroup, myForm2: FormGroup) {
    this.spinner.show('confirmation');
    this.modalService.close('newSuccessorModalScreen2');
    this.modalService.open('newSuccessorConfirmationModal');

    this.postSuccessorBody.odataPostEmployee = '/bthr_employees(' + this.user?.employeeId + ')';
    this.postSuccessorBody.odataPostEmployeeLegalEntity = '/bthr_legalentitieses(' + this.user?.legalEntityId + ')';

    this.postSuccessorBody.bt_successortype = 692360000;

    this.postSuccessorBody.odataPostSuccessorLegalEntity = '/bthr_legalentitieses(' + this.successorEmployeeDetails.legalEntityId + ')';
    this.postSuccessorBody.odataPostSuccessor = '/bthr_employees(' + this.successorEmployeeDetails.employeeId + ')';

    this.postSuccessorBody.bt_priorityno = myForm1.value.newPriority;
    this.postSuccessorBody.bt_lastreviewdate = myForm1.value.newReviewDate;
    this.postSuccessorBody.bt_comment = myForm1.value.newSuccessorComment;

    if (this.newRetentionPotentialDropdownValue === 'Low') {
      this.postSuccessorBody.bt_retentionpotential = 692360000;
    } else if (this.newRetentionPotentialDropdownValue === 'Medium') {
      this.postSuccessorBody.bt_retentionpotential = 692360001;
    } else if (this.newRetentionPotentialDropdownValue === 'High') {
      this.postSuccessorBody.bt_retentionpotential = 692360002;
    }

    if (this.newRetirementStatusDropdownValue === 'Likely') {
      this.postSuccessorBody.bt_retirementstatus = 692360000;
    } else if (this.newRetirementStatusDropdownValue === 'Unlikely') {
      this.postSuccessorBody.bt_retirementstatus = 692360001;
    }

    if (this.newTransferPotentialDropdownValue === 'Low') {
      this.postSuccessorBody.bt_mobilitytransferpotential = 692360000;
    } else if (this.newTransferPotentialDropdownValue === 'Medium') {
      this.postSuccessorBody.bt_mobilitytransferpotential = 692360001;
    } else if (this.newTransferPotentialDropdownValue === 'High') {
      this.postSuccessorBody.bt_mobilitytransferpotential = 692360002;
    }

    if (this.newReadyNowDropdownValue === 'Yes') {
      this.postSuccessorBody.bt_readynow = true;

      // additional logic for the rest of the fields if ready now \\
      this.postSuccessorBody.bt_readyin = 692360000;
      this.postSuccessorBody.bt_gapanalysisstatus = 692360002;
      this.postSuccessorBody.bt_internaltrainingrequired = false;
      this.postSuccessorBody.bt_externaltrainingrequired = false;
      this.postSuccessorBody.bt_trainingplandeveloped = 692360002;
    } else {
      this.postSuccessorBody.bt_readynow = false;

      // additional logic for the rest of the fields if not ready now \\
      if (this.newReadyInDropdownValue === '1 - 2 years') {
        this.postSuccessorBody.bt_readyin = 692360001;
      } else if (this.newReadyInDropdownValue === '3 - 5 years') {
        this.postSuccessorBody.bt_readyin = 692360002;
      } else if (this.newReadyInDropdownValue === '5 years plus') {
        this.postSuccessorBody.bt_readyin = 692360003;
      }

      if (this.newGapStatusDropdownValue === 'Not started') {
        this.postSuccessorBody.bt_gapanalysisstatus = 692360000;
      } else if (this.newGapStatusDropdownValue === 'In progress') {
        this.postSuccessorBody.bt_gapanalysisstatus = 692360001;
      } else if (this.newGapStatusDropdownValue === 'Completed') {
        this.postSuccessorBody.bt_gapanalysisstatus = 692360002;
      }

      if (this.newInternalRequiredDropdownValue === 'Yes') {
        this.postSuccessorBody.bt_internaltrainingrequired = true;
      } else {
        this.postSuccessorBody.bt_internaltrainingrequired = false;
      }

      if (this.newExternalRequiredDropdownValue === 'Yes') {
        this.postSuccessorBody.bt_externaltrainingrequired = true;
      } else {
        this.postSuccessorBody.bt_externaltrainingrequired = false;
      }

      if (this.newTrainingPlanDropdownValue === 'Not started') {
        this.postSuccessorBody.bt_trainingplandeveloped = 692360000;
      } else if (this.newTrainingPlanDropdownValue === 'In progress') {
        this.postSuccessorBody.bt_trainingplandeveloped = 692360001;
      } else if (this.newTrainingPlanDropdownValue === 'Completed') {
        this.postSuccessorBody.bt_trainingplandeveloped = 692360002;
      }

      this.postSuccessorBody.bt_internaltrainingcomments = myForm2.value.newInternalComment;
      this.postSuccessorBody.bt_externaltrainingcomments = myForm2.value.newExternalComment;
    }

    this.postSuccessorBody.bt_name = this.user?.employeeId + '_' + moment().format('YYYY-MM-DD, h:mm:ss a');
    const successorID = this.postSuccessorBody.bt_name;

    this.sharedApiSuccessionsService
      .postSuccessor({ body: this.postSuccessorBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.trainingPlanFile != null) {
            this.sharedApiSuccessionsService
              .getSuccessionPlans({ userID: this.user?.employeeId, legalEntityID: this.user?.legalEntityId })
              .pipe(take(1))
              .subscribe(
                (ret) => {
                  this.allSuccessionPlans = ret;
                },
                (error) => {
                  console.error(error);
                },
                () => {
                  let successionsArray: Array<Successions> = new Array<Successions>();

                  successionsArray = this.allSuccessionPlans.filter((t) => {
                    if (t.bt_name === successorID) {
                      return t;
                    }
                  });

                  this.sharedApiFileService
                    .patchTrainingPlan({ planid: successionsArray[0].bt_successplanid, body: { file: this.trainingPlanFile } })
                    .pipe(take(1))
                    .subscribe(
                      (ret) => {
                        console.log(ret);
                      },
                      (error) => {
                        console.error(error);
                      },
                      () => {
                        this.sharedApiSuccessionsService
                          .getSuccessionPlans({ userID: this.user?.employeeId, legalEntityID: this.user?.legalEntityId })
                          .pipe(take(1))
                          .subscribe(
                            (ret) => {
                              this.allSuccessionPlans = ret;
                            },
                            (error) => {
                              console.error(error);
                            },
                            () => {
                              this.successionPlansGridData = this.allSuccessionPlans;

                              this.loadSuccessions();

                              this.postSuccessorBody = {};
                              this.confirmationMessage = 'You have added a new sucessor successfully!';
                              this.spinner.hide('confirmation');

                              this.trainingPlanFile = null;
                            },
                          );
                      },
                    );
                },
              );
          } else {
            this.sharedApiSuccessionsService
              .getSuccessionPlans({ userID: this.user?.employeeId, legalEntityID: this.user?.legalEntityId })
              .pipe(take(1))
              .subscribe(
                (ret) => {
                  this.allSuccessionPlans = ret;
                },
                (error) => {
                  console.error(error);
                },
                () => {
                  this.successionPlansGridData = this.allSuccessionPlans;

                  this.loadSuccessions();

                  this.postSuccessorBody = {};
                  this.confirmationMessage = 'You have added a new sucessor successfully!';
                  this.spinner.hide('confirmation');
                },
              );
          }
        },
      );
  }

  //POST External Successor
  postExternalSuccessor(myForm: FormGroup) {
    this.postSuccessorBody.odataPostEmployee = '/bthr_employees(' + this.user?.employeeId + ')';
    this.postSuccessorBody.odataPostEmployeeLegalEntity = '/bthr_legalentitieses(' + this.user?.legalEntityId + ')';

    this.postSuccessorBody.bt_successortype = 692360001;
    this.postSuccessorBody.bt_priorityno = myForm.value.newPriority;
    this.postSuccessorBody.bt_lastreviewdate = myForm.value.newReviewDate;
    this.postSuccessorBody.bt_comment = myForm.value.newSuccessorComment;

    this.postSuccessorBody.bt_name = this.user?.employeeNumber + '_' + moment().format('YYYY-MM-DD, h:mm:ss a');

    this.sharedApiSuccessionsService
      .postSuccessor({ body: this.postSuccessorBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          this.sharedApiSuccessionsService
            .getSuccessionPlans({ userID: this.user?.employeeId, legalEntityID: this.user?.legalEntityId })
            .pipe(take(1))
            .subscribe(
              (ret) => {
                this.allSuccessionPlans = ret;
              },
              (error) => {
                console.error(error);
              },
              () => {
                this.successionPlansGridData = this.allSuccessionPlans;

                this.loadSuccessions();

                this.postSuccessorBody = {};
                this.confirmationMessage = 'You have added a new sucessor successfully!';
                this.spinner.hide('confirmation');
              },
            );
        },
      );
  }

  //PATCH Internal Successor
  patchInternalSuccessor(myForm: FormGroup) {
    this.spinner.show('confirmation');
    this.modalService.close('editInternalSuccessorModal');
    this.modalService.open('newSuccessorConfirmationModal');

    this.postSuccessorBody.bt_priorityno = myForm.value.editPriority;
    this.postSuccessorBody.bt_lastreviewdate = moment().format('YYYY-MM-DD');
    this.postSuccessorBody.bt_comment = myForm.value.editSuccessorComment;

    if (this.newRetentionPotentialDropdownValue === 'Low') {
      this.postSuccessorBody.bt_retentionpotential = 692360000;
    } else if (this.newRetentionPotentialDropdownValue === 'Medium') {
      this.postSuccessorBody.bt_retentionpotential = 692360001;
    } else if (this.newRetentionPotentialDropdownValue === 'High') {
      this.postSuccessorBody.bt_retentionpotential = 692360002;
    }

    if (this.newRetirementStatusDropdownValue === 'Likely') {
      this.postSuccessorBody.bt_retirementstatus = 692360000;
    } else if (this.newRetirementStatusDropdownValue === 'Unlikely') {
      this.postSuccessorBody.bt_retirementstatus = 692360001;
    }

    if (this.newTransferPotentialDropdownValue === 'Low') {
      this.postSuccessorBody.bt_mobilitytransferpotential = 692360000;
    } else if (this.newTransferPotentialDropdownValue === 'Medium') {
      this.postSuccessorBody.bt_mobilitytransferpotential = 692360001;
    } else if (this.newTransferPotentialDropdownValue === 'High') {
      this.postSuccessorBody.bt_mobilitytransferpotential = 692360002;
    }

    if (this.newReadyNowDropdownValue === 'Yes') {
      this.postSuccessorBody.bt_readynow = true;

      // additional logic for the rest of the fields if ready now \\
      this.postSuccessorBody.bt_readyin = 692360000;
      this.postSuccessorBody.bt_gapanalysisstatus = 692360002;
      this.postSuccessorBody.bt_internaltrainingrequired = false;
      this.postSuccessorBody.bt_externaltrainingrequired = false;
      this.postSuccessorBody.bt_trainingplandeveloped = 692360002;
    } else {
      this.postSuccessorBody.bt_readynow = false;

      // additional logic for the rest of the fields if not ready now \\
      if (this.newReadyInDropdownValue === '1 - 2 years') {
        this.postSuccessorBody.bt_readyin = 692360001;
      } else if (this.newReadyInDropdownValue === '3 - 5 years') {
        this.postSuccessorBody.bt_readyin = 692360002;
      } else if (this.newReadyInDropdownValue === '5 years plus') {
        this.postSuccessorBody.bt_readyin = 692360003;
      }

      if (this.newGapStatusDropdownValue === 'Not started') {
        this.postSuccessorBody.bt_gapanalysisstatus = 692360000;
      } else if (this.newGapStatusDropdownValue === 'In progress') {
        this.postSuccessorBody.bt_gapanalysisstatus = 692360001;
      } else if (this.newGapStatusDropdownValue === 'Completed') {
        this.postSuccessorBody.bt_gapanalysisstatus = 692360002;
      }

      if (this.newInternalRequiredDropdownValue === 'Yes') {
        this.postSuccessorBody.bt_internaltrainingrequired = true;
        this.postSuccessorBody.bt_internaltrainingcomments = myForm.value.editInternalComment;
      } else {
        this.postSuccessorBody.bt_internaltrainingrequired = false;
      }

      if (this.newExternalRequiredDropdownValue === 'Yes') {
        this.postSuccessorBody.bt_externaltrainingrequired = true;
        this.postSuccessorBody.bt_externaltrainingcomments = myForm.value.editExternalComment;
      } else {
        this.postSuccessorBody.bt_externaltrainingrequired = false;
      }

      if (this.newTrainingPlanDropdownValue === 'Not started') {
        this.postSuccessorBody.bt_trainingplandeveloped = 692360000;
      } else if (this.newTrainingPlanDropdownValue === 'In progress') {
        this.postSuccessorBody.bt_trainingplandeveloped = 692360001;
      } else if (this.newTrainingPlanDropdownValue === 'Completed') {
        this.postSuccessorBody.bt_trainingplandeveloped = 692360002;
      }
    }

    this.postSuccessorBody.bt_successplanid = this.selectedSuccessor.bt_successplanid;

    console.log('postSuccessorBody', this.postSuccessorBody);

    this.sharedApiSuccessionsService
      .patchSuccessor({ body: this.postSuccessorBody })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          if (this.trainingPlanFile != null) {
            this.sharedApiFileService
              .patchTrainingPlan({ planid: this.selectedSuccessor.bt_successplanid, body: { file: this.trainingPlanFile } })
              .pipe(take(1))
              .subscribe(
                (ret) => {
                  console.log(ret);
                },
                (error) => {
                  console.error(error);
                },
                () => {
                  this.sharedApiSuccessionsService
                    .getSuccessionPlans({ userID: this.user?.employeeId, legalEntityID: this.user?.legalEntityId })
                    .pipe(take(1))
                    .subscribe(
                      (ret) => {
                        this.allSuccessionPlans = ret;
                      },
                      (error) => {
                        console.error(error);
                      },
                      () => {
                        this.successionPlansGridData = this.allSuccessionPlans;

                        this.loadSuccessions();

                        this.postSuccessorBody = {};
                        this.confirmationMessage = 'You have added a new sucessor successfully!';
                        this.spinner.hide('confirmation');

                        this.trainingPlanFile = null;
                      },
                    );
                },
              );
          } else {
            this.sharedApiSuccessionsService
              .getSuccessionPlans({ userID: this.user?.employeeId, legalEntityID: this.user?.legalEntityId })
              .pipe(take(1))
              .subscribe(
                (ret) => {
                  this.allSuccessionPlans = ret;
                },
                (error) => {
                  console.error(error);
                },
                () => {
                  this.successionPlansGridData = this.allSuccessionPlans;

                  this.loadSuccessions();

                  this.postSuccessorBody = {};
                  this.confirmationMessage = 'You have added a new sucessor successfully!';
                  this.spinner.hide('confirmation');
                },
              );
          }
        },
      );
  }

  deleteSuccessor() {
    this.sharedApiSuccessionsService
      .deleteSuccessor({ recordID: this.selectedSuccessor.bt_successplanid })
      .pipe(take(1))
      .subscribe(
        (ret) => {
          console.log(ret);
        },
        (error) => {
          console.error(error);
        },
        () => {
          //this.getSuccessionPlans();
          this.getSuccessPlanSuccessors();
        },
      );
  }
  //#endregion POST CALLS
  //#endregion API CALLS

  openSuccessorAdd(myForm: FormGroup) {
    // this.successorEmployeeDetails = null;
    // this.selectedEmployeeId = null;
    // this.newSuccessorTypeDropdownValue = 'default';
    // this.newLegalEntityDropdownValue = 'default';
    // //this.newSuccessorDropdownValue = 'default';

    // this.newSuccessor1DefaultValue = {
    //   newSuccessorType: 'default',
    //   newLegalEntityDropdown: 'default',
    //   newLegalEntityHard: this.userLegalEntity,
    //   newSuccessor: 'Choose an option',
    //   newPriority: 'default',
    //   newReviewDate: this.todaysDate,
    //   newSuccessorComment: null,
    // };

    // myForm.reset(this.newSuccessor1DefaultValue);

    // this.modalService.open('newSuccessorModalScreen1');

    this.sidebarService.setSidebarType('successor-details');
    this.sidebarService.setSidebarSize(SidePanelWidth.Third);
    this.sidebarService.setSuccessorDetails({ successor: {}, type: 'create' });
    this.sidebarService.setShowSidebar(true);

    this.sidebarService
      .getShowSidebar()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data) => {
        if (!data) {
          this.refresh();
        }
      });
  }

  // openSuccessorEdit(myForm: FormGroup) {
  //   this.successorEmployeeDetails = null;

  //   this.newRetentionPotentialDropdownValue = this.selectedSuccessor.odataRententionPotential;
  //   this.newRetirementStatusDropdownValue = this.selectedSuccessor.odataRetirementStatus;
  //   this.newTransferPotentialDropdownValue = this.selectedSuccessor.odataMobilityTransferPotential;
  //   this.newReadyInDropdownValue = this.selectedSuccessor.odataReadyIn;
  //   this.newGapStatusDropdownValue = this.selectedSuccessor.odataGapAnalysisStatus;
  //   this.newInternalRequiredDropdownValue = this.selectedSuccessor.odataInternalRequired;
  //   this.newExternalRequiredDropdownValue = this.selectedSuccessor.odataExternalRequired;
  //   this.newTrainingPlanDropdownValue = this.selectedSuccessor.odataTrainingPlanDeveloped;
  //   this.newReadyNowDropdownValue = this.selectedSuccessor.odataReadyNow;

  //   const formatDate = moment(this.selectedSuccessor.bt_lastreviewdate).format('YYYY-MM-DD');

  //   this.editInternalSuccessorDefaultValue = {
  //     editReviewDate: formatDate,
  //     editPriority: this.selectedSuccessor.bt_priorityno,
  //     editSuccessorComment: this.selectedSuccessor.bt_comment,
  //     editRetentionPotential: this.selectedSuccessor.odataRententionPotential,
  //     editRetirementStatus: this.selectedSuccessor.odataRetirementStatus,
  //     editTransferPotential: this.selectedSuccessor.odataMobilityTransferPotential,
  //     editReadyNow: this.selectedSuccessor.odataReadyNow,
  //     editReadyIn: this.selectedSuccessor.odataReadyIn,
  //     editGapStatus: this.selectedSuccessor.odataGapAnalysisStatus,
  //     editInternalRequired: this.selectedSuccessor.odataInternalRequired,
  //     editExternalRequired: this.selectedSuccessor.odataExternalRequired,
  //     editTrainingPlan: this.selectedSuccessor.odataTrainingPlanDeveloped,
  //     editInternalComment: this.selectedSuccessor.bt_internaltrainingcomments,
  //     editExternalComment: this.selectedSuccessor.bt_externaltrainingcomments,
  //   };

  //   myForm.reset(this.editInternalSuccessorDefaultValue);

  //   this.modalService.open('editInternalSuccessorModal');
  // }

  //Check
  checkCloseNewSuccessor() {}

  checkSubmitNewSuccessor() {}

  checkNewSuccessor(myForm1: FormGroup, myForm2: FormGroup) {
    this.modalService.close('newSuccessorModalScreen1');

    if (myForm1.value.newSuccessorType === 'Internal') {
      this.newRetentionPotentialDropdownValue = 'default';
      this.newRetirementStatusDropdownValue = 'default';
      this.newTransferPotentialDropdownValue = 'default';
      this.newReadyNowDropdownValue = 'default';
      this.newReadyInDropdownValue = 'default';
      this.newGapStatusDropdownValue = 'default';
      this.newInternalRequiredDropdownValue = 'default';
      this.newExternalRequiredDropdownValue = 'default';
      this.newTrainingPlanDropdownValue = 'default';

      this.newSuccessor2DefaultValue = {
        newRetentionPotential: 'default',
        newRetirementStatus: 'default',
        newTransferPotential: 'default',
        newReadyNow: 'default',
        newReadyIn: 'default',
        newGapStatus: 'default',
        newInternalRequired: 'default',
        newExternalRequired: 'default',
        newTrainingPlan: 'default',
        newInternalComment: null,
        newExternalComment: null,
      };

      myForm2.reset(this.newSuccessor2DefaultValue);

      this.modalService.open('newSuccessorModalScreen2');
    } else {
      this.spinner.show('confirmation');
      this.modalService.open('newSuccessorConfirmationModal');
      this.postExternalSuccessor(myForm1);
    }
  }

  //Reset input
  resetInput(el: FormControlDirective) {
    el.reset();
  }

  resetValue(element: FormControlDirective) {
    element.reset(this.optionValue);
  }

  validatePriorityalue(element: FormControlDirective) {
    if (element.value > 10 || element.value < 1) {
      element.reset();
    } else if (element.value % 1 != 0) {
      const num = Math.floor(element.value);
      element.reset(num);
    }
  }

  // File
  // Training Plan
  trainingPlanFileSelected(event, element: FormControlDirective) {
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files[0].size < this.maxFileSize) {
        const splitName = event.target.files[0].name.split('.');
        const extension = splitName[splitName.length - 1];
        this.trainingPlanFile = new File([event.target.files[0]], this.selectedEmployeeId + '_Training Plan' + '.' + extension);
        this.postSuccessorBody.bt_trainingplanattachmentname = this.trainingPlanFile.name;
      } else {
        // TODO: add proper error message modal for large files error handling
        alert('File: ' + event.target.files[0].name + 'is too large to upload.');
        element.reset();
      }
    }
  }

  //Dropdown
  setNewSuccessorTypeDropdownValue(e) {
    this.newSuccessorTypeDropdownValue = e;

    if (e === 'Internal' && !this.config?.legalEntityConfig.successionAllowGroupEntities) {
      this.newLegalEntityDropdownValue = this.user?.legalEntityName;
    } else {
      this.newLegalEntityDropdownValue = 'default';
    }

    //Confirmation popup logic
    this.screen1CancelConfirmation = true;

    if (e === 'Internal') {
      this.screen1SubmitText = 'Next';
      this.screen1SubmitConfirmation = false;
    } else {
      this.screen1SubmitText = 'Submit';
      this.screen1SubmitConfirmation = true;
    }
  }

  setNewLegalEntityDropdownValue(e) {
    if (e !== 'default') {
      this.newLegalEntityDropdownValue = e;
    } else {
      this.newLegalEntityDropdownValue = 'default';
    }
  }

  setNewSuccessorDropdownValue(e: UserUserDto) {
    //this.newSuccessorDropdownValue = e;

    this.successorEmployeeDetails = e;
    this.selectedEmployeeId = e.employeeId;

    //this.userService.allEmployees.forEach((t) => {
    //  if (t.fullNameWithEmpId === e) {
    //    this.successorEmployeeDetails = t;
    //  }
    //});
  }

  setNewRetentionPotentialDropdownValue(e) {
    this.newRetentionPotentialDropdownValue = e;
  }

  setNewRetirementStatusDropdownValue(e) {
    this.newRetirementStatusDropdownValue = e;
  }

  setNewTransferPotentialDropdownValue(e) {
    this.newTransferPotentialDropdownValue = e;
  }

  setNewReadyNowDropdownValue(e) {
    this.newReadyNowDropdownValue = e;
  }

  setNewReadyInDropdownValue(e) {
    this.newReadyInDropdownValue = e;
  }

  setNewGapStatusDropdownValue(e) {
    this.newGapStatusDropdownValue = e;
  }

  setNewInternalRequiredDropdownValue(e) {
    this.newInternalRequiredDropdownValue = e;
  }

  setNewExternalRequiredDropdownValue(e) {
    this.newExternalRequiredDropdownValue = e;
  }

  setNewTrainingPlanDropdownValue(e) {
    this.newTrainingPlanDropdownValue = e;
  }

  //#region Kendo Methods
  handleSuccessorFilter(search: string) {
    if (search.length >= 3) {
      let leid = this.newLegalEntityDropdownValue;
      if (this.newLegalEntityDropdownValue === this.user?.legalEntityName) {
        leid = this.user.legalEntityId;
      }

      this.userApiUserService
        .getUsersByLegalEntity({
          body: {
            search,
            legalEntityId: leid,
          },
        })
        .pipe(take(1))
        .subscribe(
          (ret) => {
            this.allEmployeesFilteredData = ret;
          },
          (error) => {
            console.error(error);
          },
          () => {
            if (this.allEmployeesFilteredData?.length === 0) {
              this.employeeDropdownMessage = 'No Data Found for ' + search;
            }
          },
        );
    } else if (search.length === 0) {
      this.allEmployeesFilteredData = [];
      this.employeeDropdownMessage = 'Type at least 3 characters';
    }
  }

  // Row style callback
  rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  // Succession Plans
  loadSuccessions(): void {
    this.successionPlansGridView = {
      data: orderBy(this.allSuccessionPlans, this.successionPlansSort),
      total: this.allSuccessionPlans.length,
    };
  }

  successionPlansGroupChange(groups: GroupDescriptor[]): void {
    this.successionPlansGroups = groups;
    this.successionPlansGridView = process(this.allSuccessionPlans, { group: this.successionPlansGroups });
  }

  successionPlansDataStateChange(state: DataStateChangeEvent): void {
    this.successionPlansState = state;
    this.successionPlansGridView = process(this.allSuccessionPlans, this.successionPlansState);
  }

  successionPlansSortChange(sort: SortDescriptor[]): void {
    this.successionPlansSort = sort;
    this.loadSuccessions();
  }

  public onSuccessPlansFilter(inputValue: string): void {
    this.successionPlansGridData = process(this.allSuccessionPlans, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'fullName',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'odataSuccessorLegalEntity',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'odataSuccessorType',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'bt_priorityno',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'odataReadyIn',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  //#endregion Kendo Methods
}
