import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VerdeSharedModule } from '../shared/shared.module';
import { WebDynamicFormComponent } from './components/web-dynamic-form/web-dynamic-form.component';

const routes: Routes = [
  {
    path: '',
    component: WebDynamicFormComponent,
  },
];

@NgModule({
  imports: [VerdeSharedModule, RouterModule.forChild(routes)],
  exports: [WebDynamicFormComponent],
  declarations: [WebDynamicFormComponent],
})
export class WebDynamicModule {}
