import { Component, OnInit } from '@angular/core';
import { ProcessesPositionSeats, UserAppConfigDto, UserSecurityFunctionDto } from '@verde/api';
import { UserService } from '@verde/core';
import { SidePanelWidth, WebDynamicFormType, WebDynamicFormAction, WebDynamicService, WebDynamicSidePanelArgs } from '@verde/shared';
import { Subject, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../services/verde-approval.service';

@Component({
  selector: 'verde-employee-onboarding-additional',
  templateUrl: './employee-onboarding-additional.component.html',
  styleUrls: ['./employee-onboarding-additional.component.scss'],
})
export class EmployeeOnboardingAdditionalComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();

  peopleManagementFunctionsOnboarding: Array<UserSecurityFunctionDto> = new Array<UserSecurityFunctionDto>();
  config: UserAppConfigDto = {};
  enhancedDone = false;
  configDone = false;

  expandedStates: boolean[] = this.peopleManagementFunctionsOnboarding.map(() => false);
  positionSeat: ProcessesPositionSeats;

  constructor(private sidebarService: VerdeApprovalService, private userService: UserService, private webDynamicService: WebDynamicService) {}

  ngOnInit(): void {
    this.sidebarService
      .getPositionSeat()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((data: any) => {
        this.positionSeat = data;
      });

    this.userService.config$.pipe(takeUntil(this.onDestroy$)).subscribe((config) => {
      if (config !== null && config !== undefined && Object.keys(config).length > 0) {
        this.config = config;

        // Functions
        this.configDone = true;
        this.getMenuFunctionReporting();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  getMenuFunctionReporting() {
    if (this.configDone) {
      this.peopleManagementFunctionsOnboarding = [];
      const initialFunctions = this.userService.filterUserPermissions('People Management Onboarding', false).sort((a, b) => {
        if (a.menuName && b.menuName) {
          const nameA = a.menuName.toLowerCase();
          const nameB = b.menuName.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        }

        return 0;
      });

      //console.log("initialFunctions", initialFunctions)

      initialFunctions.forEach((t) => {
        if (this.config?.legalEntityConfig[t.configFieldName] || t.configFieldName === 'null') {
          this.peopleManagementFunctionsOnboarding.push(t);
        }
      });
    }
  }

  navigateSomewhere(item) {
    if ((item?.jsonFileName ?? '') == '') {
      this.sidebarService.setSidebarSize(40);
      this.sidebarService.setShowSidebar(true);
      this.sidebarService.setSidebarType('employeeOnboardingEdit');
      this.sidebarService.setPositionSeat(this.positionSeat);
    } else {
      this.webDynamicService.setDynamicForm({
        formFile: item.jsonFileName,
        formType: WebDynamicFormType.SidePanel,
        action: WebDynamicFormAction.Update,
        args: {
          visible: true,
          size: SidePanelWidth.Half,
          model: {
            entityId: this.positionSeat.employee.id,
          },
        } as WebDynamicSidePanelArgs,
      });
    }
  }

  //Code for testing purposes later
  public onAction(index: number): void {}

  onTitleClick(event: Event, index: number) {
    event.preventDefault();
    event.stopPropagation();
  }

  onExpandedChange(expanded: boolean, index: number) {
    if (expanded) {
      setTimeout(() => {
        this.expandedStates[index] = false;
      });
    }
  }
}
