/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { UserBaseService } from '../user-base-service';
import { UserApiConfiguration } from '../user-api-configuration';
import { UserStrictHttpResponse } from '../user-strict-http-response';
import { UserRequestBuilder } from '../user-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserCounter } from '../models/user-counter';
import { UserGetExitedUserByLegalEntityQuery } from '../models/user-get-exited-user-by-legal-entity-query';
import { UserGetUserByDepartmentQuery } from '../models/user-get-user-by-department-query';
import { UserGetUserByEmailQuery } from '../models/user-get-user-by-email-query';
import { UserGetUserByIdQuery } from '../models/user-get-user-by-id-query';
import { UserGetUserByLegalEntityQuery } from '../models/user-get-user-by-legal-entity-query';
import { UserGetUserTeamQuery } from '../models/user-get-user-team-query';
import { UserGetUsersBirthdayByLegalEntityQuery } from '../models/user-get-users-birthday-by-legal-entity-query';
import { UserGetVotingOpenCountQuery } from '../models/user-get-voting-open-count-query';
import { UserGetWorkplacesByLegalEntityQuery } from '../models/user-get-workplaces-by-legal-entity-query';
import { UserPostUserControlQueryCommand } from '../models/user-post-user-control-query-command';
import { UserUpdateUserCommand } from '../models/user-update-user-command';
import { UserUserDto } from '../models/user-user-dto';
import { UserWorkplaceDto } from '../models/user-workplace-dto';

@Injectable({
  providedIn: 'root',
})
export class UserApiUserService extends UserBaseService {
  constructor(
    config: UserApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserByEmail
   */
  static readonly GetUserByEmailPath = '/api/User/GetUserByEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserByEmail()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserByEmail$Response(params?: {
    body?: UserGetUserByEmailQuery
  }): Observable<UserStrictHttpResponse<UserUserDto>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiUserService.GetUserByEmailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as UserStrictHttpResponse<UserUserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserByEmail$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserByEmail(params?: {
    body?: UserGetUserByEmailQuery
  }): Observable<UserUserDto> {

    return this.getUserByEmail$Response(params).pipe(
      map((r: UserStrictHttpResponse<UserUserDto>) => r.body as UserUserDto)
    );
  }

  /**
   * Path part for operation getUserById
   */
  static readonly GetUserByIdPath = '/api/User/GetUserById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserById$Response(params?: {
    body?: UserGetUserByIdQuery
  }): Observable<UserStrictHttpResponse<UserUserDto>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiUserService.GetUserByIdPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as UserStrictHttpResponse<UserUserDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserById(params?: {
    body?: UserGetUserByIdQuery
  }): Observable<UserUserDto> {

    return this.getUserById$Response(params).pipe(
      map((r: UserStrictHttpResponse<UserUserDto>) => r.body as UserUserDto)
    );
  }

  /**
   * Path part for operation getUsersByLegalEntity
   */
  static readonly GetUsersByLegalEntityPath = '/api/User/GetUsersByLegalEntity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersByLegalEntity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUsersByLegalEntity$Response(params?: {
    body?: UserGetUserByLegalEntityQuery
  }): Observable<UserStrictHttpResponse<Array<UserUserDto>>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiUserService.GetUsersByLegalEntityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as UserStrictHttpResponse<Array<UserUserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsersByLegalEntity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUsersByLegalEntity(params?: {
    body?: UserGetUserByLegalEntityQuery
  }): Observable<Array<UserUserDto>> {

    return this.getUsersByLegalEntity$Response(params).pipe(
      map((r: UserStrictHttpResponse<Array<UserUserDto>>) => r.body as Array<UserUserDto>)
    );
  }

  /**
   * Path part for operation getExitedUsersByLegalEntity
   */
  static readonly GetExitedUsersByLegalEntityPath = '/api/User/GetExitedUsersByLegalEntity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExitedUsersByLegalEntity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getExitedUsersByLegalEntity$Response(params?: {
    body?: UserGetExitedUserByLegalEntityQuery
  }): Observable<UserStrictHttpResponse<Array<UserUserDto>>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiUserService.GetExitedUsersByLegalEntityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as UserStrictHttpResponse<Array<UserUserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExitedUsersByLegalEntity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getExitedUsersByLegalEntity(params?: {
    body?: UserGetExitedUserByLegalEntityQuery
  }): Observable<Array<UserUserDto>> {

    return this.getExitedUsersByLegalEntity$Response(params).pipe(
      map((r: UserStrictHttpResponse<Array<UserUserDto>>) => r.body as Array<UserUserDto>)
    );
  }

  /**
   * Path part for operation getVotingOpenCount
   */
  static readonly GetVotingOpenCountPath = '/api/User/GetVotingOpenCount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVotingOpenCount()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVotingOpenCount$Response(params?: {
    body?: UserGetVotingOpenCountQuery
  }): Observable<UserStrictHttpResponse<Array<UserCounter>>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiUserService.GetVotingOpenCountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as UserStrictHttpResponse<Array<UserCounter>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVotingOpenCount$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVotingOpenCount(params?: {
    body?: UserGetVotingOpenCountQuery
  }): Observable<Array<UserCounter>> {

    return this.getVotingOpenCount$Response(params).pipe(
      map((r: UserStrictHttpResponse<Array<UserCounter>>) => r.body as Array<UserCounter>)
    );
  }

  /**
   * Path part for operation getUsersBirthdayByLegalEntity
   */
  static readonly GetUsersBirthdayByLegalEntityPath = '/api/User/GetUsersBirthdayByLegalEntity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersBirthdayByLegalEntity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUsersBirthdayByLegalEntity$Response(params?: {
    body?: UserGetUsersBirthdayByLegalEntityQuery
  }): Observable<UserStrictHttpResponse<Array<UserUserDto>>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiUserService.GetUsersBirthdayByLegalEntityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as UserStrictHttpResponse<Array<UserUserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsersBirthdayByLegalEntity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUsersBirthdayByLegalEntity(params?: {
    body?: UserGetUsersBirthdayByLegalEntityQuery
  }): Observable<Array<UserUserDto>> {

    return this.getUsersBirthdayByLegalEntity$Response(params).pipe(
      map((r: UserStrictHttpResponse<Array<UserUserDto>>) => r.body as Array<UserUserDto>)
    );
  }

  /**
   * Path part for operation getWorkplacesByLegalEntity
   */
  static readonly GetWorkplacesByLegalEntityPath = '/api/User/GetWorkplacesByLegalEntity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkplacesByLegalEntity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWorkplacesByLegalEntity$Response(params?: {
    body?: UserGetWorkplacesByLegalEntityQuery
  }): Observable<UserStrictHttpResponse<Array<UserWorkplaceDto>>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiUserService.GetWorkplacesByLegalEntityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as UserStrictHttpResponse<Array<UserWorkplaceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWorkplacesByLegalEntity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getWorkplacesByLegalEntity(params?: {
    body?: UserGetWorkplacesByLegalEntityQuery
  }): Observable<Array<UserWorkplaceDto>> {

    return this.getWorkplacesByLegalEntity$Response(params).pipe(
      map((r: UserStrictHttpResponse<Array<UserWorkplaceDto>>) => r.body as Array<UserWorkplaceDto>)
    );
  }

  /**
   * Path part for operation getUserTeam
   */
  static readonly GetUserTeamPath = '/api/User/GetUserTeam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserTeam()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserTeam$Response(params?: {
    body?: UserGetUserTeamQuery
  }): Observable<UserStrictHttpResponse<Array<UserUserDto>>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiUserService.GetUserTeamPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as UserStrictHttpResponse<Array<UserUserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserTeam$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUserTeam(params?: {
    body?: UserGetUserTeamQuery
  }): Observable<Array<UserUserDto>> {

    return this.getUserTeam$Response(params).pipe(
      map((r: UserStrictHttpResponse<Array<UserUserDto>>) => r.body as Array<UserUserDto>)
    );
  }

  /**
   * Path part for operation getUsersByDepartment
   */
  static readonly GetUsersByDepartmentPath = '/api/User/GetUsersByDepartment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsersByDepartment()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUsersByDepartment$Response(params?: {
    body?: UserGetUserByDepartmentQuery
  }): Observable<UserStrictHttpResponse<Array<UserUserDto>>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiUserService.GetUsersByDepartmentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as UserStrictHttpResponse<Array<UserUserDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsersByDepartment$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getUsersByDepartment(params?: {
    body?: UserGetUserByDepartmentQuery
  }): Observable<Array<UserUserDto>> {

    return this.getUsersByDepartment$Response(params).pipe(
      map((r: UserStrictHttpResponse<Array<UserUserDto>>) => r.body as Array<UserUserDto>)
    );
  }

  /**
   * Path part for operation updateUser
   */
  static readonly UpdateUserPath = '/api/User/UpdateUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUser$Response(params?: {
    body?: UserUpdateUserCommand
  }): Observable<UserStrictHttpResponse<boolean>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiUserService.UpdateUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as UserStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateUser(params?: {
    body?: UserUpdateUserCommand
  }): Observable<boolean> {

    return this.updateUser$Response(params).pipe(
      map((r: UserStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation updateUserImage
   */
  static readonly UpdateUserImagePath = '/api/User/UpdateUserImage/{EmployeeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateUserImage$Response(params: {
    EmployeeId: string;
    body?: {
'file'?: Blob;
}
  }): Observable<UserStrictHttpResponse<boolean>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiUserService.UpdateUserImagePath, 'post');
    if (params) {
      rb.path('EmployeeId', params.EmployeeId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as UserStrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateUserImage(params: {
    EmployeeId: string;
    body?: {
'file'?: Blob;
}
  }): Observable<boolean> {

    return this.updateUserImage$Response(params).pipe(
      map((r: UserStrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation createUserControl
   */
  static readonly CreateUserControlPath = '/api/User/CreateUserControl';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserControl()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUserControl$Response(params?: {
    organizationId?: string;
    body?: UserPostUserControlQueryCommand
  }): Observable<UserStrictHttpResponse<void>> {

    const rb = new UserRequestBuilder(this.rootUrl, UserApiUserService.CreateUserControlPath, 'post');
    if (params) {
      rb.query('organizationId', params.organizationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as UserStrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createUserControl$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUserControl(params?: {
    organizationId?: string;
    body?: UserPostUserControlQueryCommand
  }): Observable<void> {

    return this.createUserControl$Response(params).pipe(
      map((r: UserStrictHttpResponse<void>) => r.body as void)
    );
  }

}
