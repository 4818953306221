/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BookingItems } from '../models/booking-items';
import { BookingsFiltered } from '../models/bookings-filtered';

@Injectable({
  providedIn: 'root',
})
export class SharedApiBookingItemsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getBookingItemsByEntity
   */
  static readonly GetBookingItemsByEntityPath = '/api/BookingItems/bookingitemsbylegalentity/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookingItemsByEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingItemsByEntity$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<BookingItems>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiBookingItemsService.GetBookingItemsByEntityPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<BookingItems>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookingItemsByEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBookingItemsByEntity(params: { subscriptionKey?: string; APIURL?: string; legalEntityID: string }): Observable<Array<BookingItems>> {
    return this.getBookingItemsByEntity$Response(params).pipe(map((r: StrictHttpResponse<Array<BookingItems>>) => r.body as Array<BookingItems>));
  }

  /**
   * Path part for operation patchVerdeBookings
   */
  static readonly PatchVerdeBookingsPath = '/api/BookingItems/patch/patchVerdeBookings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchVerdeBookings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeBookings$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: BookingsFiltered }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiBookingItemsService.PatchVerdeBookingsPath, 'patch');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `patchVerdeBookings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  patchVerdeBookings(params?: { subscriptionKey?: string; APIURL?: string; body?: BookingsFiltered }): Observable<boolean> {
    return this.patchVerdeBookings$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
