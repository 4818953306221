/* tslint:disable */
/* eslint-disable */
import { ProfileBtVaccinationStatus } from './profile-bt-vaccination-status';
export interface ProfileVaccineBoosters {
  _bt_boostervaccine_value?: null | string;
  _bt_country_value?: null | string;
  _bt_vaccine_value?: null | string;
  bt_availablefrom?: null | string;
  bt_sequence?: null | number;
  bt_vaccinationstatus?: ProfileBtVaccinationStatus;
  bt_vaccinebooster?: null | string;
  bt_vaccineboosterid?: null | string;
}
