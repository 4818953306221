/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TriggerNotifications } from '../models/trigger-notifications';
import { VerdeWorkflowLog } from '../models/verde-workflow-log';

@Injectable({
  providedIn: 'root',
})
export class SharedApiTriggerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getTriggerNotifications
   */
  static readonly GetTriggerNotificationsPath = '/api/Trigger/notifications/{employeeGUID}/{legalEntityID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTriggerNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTriggerNotifications$Response(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
    legalEntityID: string;
  }): Observable<StrictHttpResponse<Array<TriggerNotifications>>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTriggerService.GetTriggerNotificationsPath, 'get');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.path('employeeGUID', params.employeeGUID, {});
      rb.path('legalEntityID', params.legalEntityID, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<TriggerNotifications>>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTriggerNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTriggerNotifications(params: {
    subscriptionKey?: string;
    APIURL?: string;
    employeeGUID: string;
    legalEntityID: string;
  }): Observable<Array<TriggerNotifications>> {
    return this.getTriggerNotifications$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TriggerNotifications>>) => r.body as Array<TriggerNotifications>),
    );
  }

  /**
   * Path part for operation postVerdeWorkflowLog
   */
  static readonly PostVerdeWorkflowLogPath = '/api/Trigger/post/workflowlog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postVerdeWorkflowLog()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeWorkflowLog$Response(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeWorkflowLog }): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SharedApiTriggerService.PostVerdeWorkflowLogPath, 'post');
    if (params) {
      rb.query('subscriptionKey', params.subscriptionKey, {});
      rb.query('APIURL', params.APIURL, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postVerdeWorkflowLog$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  postVerdeWorkflowLog(params?: { subscriptionKey?: string; APIURL?: string; body?: VerdeWorkflowLog }): Observable<boolean> {
    return this.postVerdeWorkflowLog$Response(params).pipe(map((r: StrictHttpResponse<boolean>) => r.body as boolean));
  }
}
