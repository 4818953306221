<!-- #region Header -->
<div class="expansion_header_flex" style="padding-left: unset">
  <p class="header_label">Last Review Date: {{ successionPlanManager?.bt_lastreviewdate | date : 'yyyy/MM/dd' }}</p>

  <p class="header_label">Internal Available: {{ successionPlanManager?.odataInternalAvailable }}</p>

  <p class="header_label">Successor Identified: {{ successionPlanManager?.odataSuccessorAvailable }}</p>

  <p class="header_label">Ready In: {{ successionPlanManager?.odataReadyIn }}</p>
</div>
<!-- #endregion Header-->
<!-- #region Body -->
<kendo-grid id="oldsuccessorsgird"
            [rowClass]="rowCallback"
            [data]="allSuccessSuccessors"
            [resizable]="true"
            [scrollable]="true"
            [pageable]="false"
            [skip]="successorState.skip"
            [sortable]="true"
            [sort]="successorState.sort"
            [loading]="successSuccessorsLoading"
            (dataStateChange)="successorDataStateChange($event)">
  <!-- columns for larger devices -->
  <ng-template kendoGridToolbarTemplate>
    <input placeholder="Search all columns" kendoTextBox style="text-transform: none !important" (input)="onSuccessPlansFilter($event.target.value)" />
    <kendo-grid-spacer></kendo-grid-spacer>
    <button kendoButton themeColor="primary" (click)="openSuccessorAdd(newSuccessorForm1)">New Successor</button>
    <button *ngIf="!(deviceTypeService.isMobile$ | async)" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
    <img title="Refresh" class="refresh_icon" src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg" alt="" (click)="refresh()" />
  </ng-template>
  <kendo-grid-column [width]="100" field="bt_successorfullname" title="Successor"> </kendo-grid-column>
  <kendo-grid-column [width]="100" field="bt_successoremployeeid.primaryName" title="Successor ID"> </kendo-grid-column>
  <kendo-grid-column field="bt_successortype" title="Type" width="250"> </kendo-grid-column>
  <kendo-grid-column field="bt_comment" title="Comment" width="250"> </kendo-grid-column>

  <kendo-grid-column field="bt_priorityno" title="Priority" width="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div *ngIf="dataItem.bt_priorityno == 1">High</div>
      <div *ngIf="dataItem.bt_priorityno == 2">Medium</div>
      <div *ngIf="dataItem.bt_priorityno > 2">Low</div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="bt_readyin" title="Ready In" width="200">
    <!-- Temp Fixes -->
    <ng-template kendoGridCellTemplate let-dataItem>
      <div *ngIf="dataItem.bt_readyin?.toString() == 'Now'">Now</div>
      <div *ngIf="dataItem.bt_readyin?.toString() == '_12years'">1 - 2 years</div>
      <div *ngIf="dataItem.bt_readyin?.toString() == '_35years'">3 - 5 years</div>
      <div *ngIf="dataItem.bt_readyin?.toString() == '_5yearsplus'">5 years plus</div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column title="" width="40" [sticky]="true" [minResizableWidth]="40" [maxResizableWidth]="40">
    <ng-template kendoGridCellTemplate let-dataItem>
      <verde-dots-menu-button [items]="dotMenuItems" (menuClicked)="menuClicked(dataItem)" (menuItemClicked)="menuItemClicked($event)"></verde-dots-menu-button>
    </ng-template>
  </kendo-grid-command-column>

  <kendo-grid-excel fileName="SuccessionPlans.xlsx"></kendo-grid-excel>
</kendo-grid>

<!-- #region Delete Modal -->

<verde-modal modalTitle="Confirmation" id="confirmSuccessorDeleteModal" modalMaxWidth="400" submitButton="Continue" (actionButton)="deleteSuccessor()">
  <div style="height: 60px">
    <p>Are you sure you want to remove this successor?</p>
  </div>
</verde-modal>
<!-- #endregion Delete Modal -->

<!-- #endregion Body -->
<!-- #region Modals -->
<!-- #region New Successor Modal Screen 1 -->
<!-- <verde-modal modalTitle="New Successor"
          [submitButton]="screen1SubmitText"
          (actionButton)="checkNewSuccessor(newSuccessorForm1, newSuccessorForm2)"
          id="newSuccessorModalScreen1"
          modalMaxWidth="498"
          [submitActive]="newSuccessorForm1.valid"
          submitConfirmationMessage="Please confirm that you would like to submit this new successor"
          cancelConfirmationMessage="Please confirm that you would like to cancel"
          [hasSubmitConfirmation]="screen1SubmitConfirmation"
          [hasCancelConfirmation]="screen1CancelConfirmation">
  <form #newSuccessorForm1="ngForm"
        novalidate
        autocomplete="off">
    <div class="modal_grid">
      <div class="form-group">
        <label for="newSuccessorType">Successor Type:</label>
        <select class="custom-select"
                [pattern]="checkDefaultPattern"
                id="newSuccessorType"
                name="newSuccessorType"
                #varNewSuccessorType="ngModel"
                (change)="setNewSuccessorTypeDropdownValue($event.target.value)"
                [ngModel]="defaultValue">
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Internal">Internal</option>
          <option value="External">External</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varNewSuccessorType.touched && !varNewSuccessorType.valid">
          <div *ngIf="varNewSuccessorType?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div></div>

      <div class="form-group">
        <label for="newReviewDate">Last Review Date</label>
        <input readonly
               type="text"
               id="newReviewDate"
               name="newReviewDate"
               #varNewReviewDate="ngModel"
               class="form-control"
               [ngModel]="todaysDate" />
      </div>

      <div class="form-group">
        <label for="newPriority">Priority:</label>
        <select class="custom-select"
                [pattern]="checkDefaultPattern"
                id="newPriority"
                name="newPriority"
                #varNewPriority="ngModel"
                [ngModel]="defaultValue">
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="3">Low</option>
          <option value="2">Medium</option>
          <option value="1">High</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varNewPriority.touched && !varNewPriority.valid">
          <div *ngIf="varNewPriority.errors.required">Field is required!</div>
          <div *ngIf="varNewPriority.errors.pattern">Field is required!</div>
        </div>
      </div>

      <div *ngIf="newSuccessorTypeDropdownValue === 'Internal' && config?.legalEntityConfig?.successionAllowGroupEntities"
           class="form-group">
        <label for="newLegalEntityDropdown">Legal Entity:</label>
        <select class="custom-select"
                [pattern]="checkDefaultPattern"
                id="newLegalEntityDropdown"
                name="newLegalEntityDropdown"
                #varNewLegalEntityDropdown="ngModel"
                (change)="setNewLegalEntityDropdownValue($event.target.value)"
                [ngModel]="defaultValue">
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option *ngFor="let entity of allLegalEntities"
                  value="{{ entity.bthr_legalentitiesid }}">
            {{ entity.bthr_legalentityname }}
          </option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varNewLegalEntityDropdown.touched && !varNewLegalEntityDropdown.valid">
          <div *ngIf="varNewLegalEntityDropdown?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div *ngIf="newSuccessorTypeDropdownValue === 'Internal' && !config?.legalEntityConfig?.successionAllowGroupEntities"
           class="form-group">
        <label for="newLegalEntityHard">Legal Entity:</label>
        <input readonly
               type="text"
               id="newLegalEntityHard"
               name="newLegalEntityHard"
               #varNewLegalEntityHard="ngModel"
               class="form-control"
               [ngModel]="userLegalEntity" />
      </div>

      <div *ngIf="newSuccessorTypeDropdownValue === 'Internal'"
           class="form-group">
        <label for="transferToEmployee">Successor:</label>
        <kendo-dropdownlist [data]="allEmployeesFilteredData"
                            textField="fullNameWithEmpId"
                            valueField="employeeId"
                            [filterable]="true"
                            (filterChange)="handleSuccessorFilter($event)"
                            id="newSuccessor"
                            name="newSuccessor"
                            #varNewSuccessor="ngModel"
                            required
                            [ngModel]
                            (valueChange)="setNewSuccessorDropdownValue($event)">
          <ng-template kendoDropDownListNoDataTemplate>
            <p>{{ employeeDropdownMessage }}</p>
          </ng-template>
        </kendo-dropdownlist>

        <div class="alert alert-danger"
             *ngIf="varNewSuccessor.touched && !varNewSuccessor.valid">
          <div *ngIf="varNewSuccessor.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="newSuccessorComment">Comment:</label>
        <textarea maxlength="500"
                  style="height: 60px"
                  class="form-control"
                  id="newSuccessorComment"
                  name="newSuccessorComment"
                  #varNewSuccessorComment="ngModel"
                  [ngModel]></textarea>

        <div class="alert alert-danger"
             *ngIf="varNewSuccessorComment.touched && !varNewSuccessorComment.valid">
          <div *ngIf="varNewSuccessorComment.errors.required">Field is required!</div>
        </div>
      </div>
    </div>
  </form>
</verde-modal> -->

<!-- #endregion New Successor Modal Screen 1 -->
<!-- #region New Successor Modal Screen 2 -->
<!-- submitButton="Submit" -->
<!-- <verde-modal modalTitle="New Successor - {{ successorEmployeeDetails?.fullNameWithEmpId }}"
          (actionButton)="postInternalSuccessor(newSuccessorForm1, newSuccessorForm2)"
          id="newSuccessorModalScreen2"
          submitButton="Submit"
          [submitActive]="newSuccessorForm2.valid"
          submitConfirmationMessage="Please confirm that you would like to submit this new successor"
          cancelConfirmationMessage="Please confirm that you would like to cancel"
          [hasSubmitConfirmation]="true"
          [hasCancelConfirmation]="true">
  <form #newSuccessorForm2="ngForm"
        novalidate
        autocomplete="off">
    <div class="next_modal_grid">
      <div class="form-group wide">
        <label for="newRetentionPotential">Retention Potential:</label>
        <select class="custom-select"
                [pattern]="checkDefaultPattern"
                id="newRetentionPotential"
                name="newRetentionPotential"
                #varNewRetentionPotential="ngModel"
                (change)="setNewRetentionPotentialDropdownValue($event.target.value)"
                [ngModel]="defaultValue">
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varNewRetentionPotential.touched && !varNewRetentionPotential.valid">
          <div *ngIf="varNewRetentionPotential?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="newRetirementStatus">Retirement Status:</label>
        <select class="custom-select"
                [pattern]="checkDefaultPattern"
                id="newRetirementStatus"
                name="newRetirementStatus"
                #varNewRetirementStatus="ngModel"
                (change)="setNewRetirementStatusDropdownValue($event.target.value)"
                [ngModel]="defaultValue">
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Likely">Likely</option>
          <option value="Unlikely">Unlikely</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varNewRetirementStatus.touched && !varNewRetirementStatus.valid">
          <div *ngIf="varNewRetirementStatus?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="newTransferPotential">Mobility/Transfer Potential:</label>
        <select class="custom-select"
                [pattern]="checkDefaultPattern"
                id="newTransferPotential"
                name="newTransferPotential"
                #varNewTransferPotential="ngModel"
                (change)="setNewTransferPotentialDropdownValue($event.target.value)"
                [ngModel]="defaultValue">
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varNewTransferPotential.touched && !varNewTransferPotential.valid">
          <div *ngIf="varNewTransferPotential?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="newReadyNow">Ready Now:</label>
        <select class="custom-select"
                [pattern]="checkDefaultPattern"
                id="newReadyNow"
                name="newReadyNow"
                #varNewReadyNow="ngModel"
                (change)="setNewReadyNowDropdownValue($event.target.value)"
                [ngModel]="defaultValue">
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varNewReadyNow.touched && !varNewReadyNow.valid">
          <div *ngIf="varNewReadyNow?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="newReadyIn">Ready In:</label>
        <select [disabled]="newReadyNowDropdownValue !== 'No'"
                class="custom-select"
                [pattern]="checkDefaultPattern"
                id="newReadyIn"
                name="newReadyIn"
                #varNewReadyIn="ngModel"
                (change)="setNewReadyInDropdownValue($event.target.value)"
                [ngModel]="defaultValue">
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Now">Now</option>
          <option value="1 - 2 years">1 - 2 years</option>
          <option value="3 - 5 years">3 - 5 years</option>
          <option value="5 years plus">5 years plus</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varNewReadyIn.touched && !varNewReadyIn.valid">
          <div *ngIf="varNewReadyIn?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="newGapStatus">Gap Analysis Status</label>
        <select [disabled]="newReadyNowDropdownValue !== 'No'"
                class="custom-select"
                [pattern]="checkDefaultPattern"
                id="newGapStatus"
                name="newGapStatus"
                #varNewGapStatus="ngModel"
                (change)="setNewGapStatusDropdownValue($event.target.value)"
                [ngModel]="defaultValue">
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Not started">Not started</option>
          <option value="In progress">In progress</option>
          <option value="Completed">Completed</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varNewGapStatus.touched && !varNewGapStatus.valid">
          <div *ngIf="varNewGapStatus?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="newInternalRequired">Internal Training Required:</label>
        <select [disabled]="newReadyNowDropdownValue !== 'No'"
                class="custom-select"
                [pattern]="checkDefaultPattern"
                id="newInternalRequired"
                name="newInternalRequired"
                #varNewInternalRequired="ngModel"
                (change)="setNewInternalRequiredDropdownValue($event.target.value)"
                [ngModel]="defaultValue">
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varNewInternalRequired.touched && !varNewInternalRequired.valid">
          <div *ngIf="varNewInternalRequired?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="newExternalRequired">External Training Required:</label>
        <select [disabled]="newReadyNowDropdownValue !== 'No'"
                class="custom-select"
                [pattern]="checkDefaultPattern"
                id="newExternalRequired"
                name="newExternalRequired"
                #varNewExternalRequired="ngModel"
                (change)="setNewExternalRequiredDropdownValue($event.target.value)"
                [ngModel]="defaultValue">
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varNewExternalRequired.touched && !varNewExternalRequired.valid">
          <div *ngIf="varNewExternalRequired?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="newTrainingPlan">Training Plan Developed</label>
        <select [disabled]="newReadyNowDropdownValue !== 'No'"
                class="custom-select"
                [pattern]="checkDefaultPattern"
                id="newTrainingPlan"
                name="newTrainingPlan"
                #varNewTrainingPlan="ngModel"
                (change)="setNewTrainingPlanDropdownValue($event.target.value)"
                [ngModel]="defaultValue">
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Not Started">Not Started</option>
          <option value="In progress">In progress</option>
          <option value="Completed">Completed</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varNewTrainingPlan.touched && !varNewTrainingPlan.valid">
          <div *ngIf="varNewTrainingPlan?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div *ngIf="newInternalRequiredDropdownValue === 'Yes'"
           class="form-group extrawide">
        <label for="newInternalComment">Internal Training Comment:</label>
        <textarea [disabled]="newReadyNowDropdownValue !== 'No'"
                  required
                  minlength="3"
                  maxlength="500"
                  style="height: 60px"
                  class="form-control"
                  id="newInternalComment"
                  name="newInternalComment"
                  #varNewInternalComment="ngModel"
                  [ngModel]></textarea>

        <div class="alert alert-danger"
             *ngIf="varNewInternalComment.touched && !varNewInternalComment.valid">
          <div *ngIf="varNewInternalComment.errors.required">Field is required!</div>
          <div *ngIf="varNewInternalComment.errors.minlength">Enter a detailed comment</div>
        </div>
      </div>

      <div *ngIf="newExternalRequiredDropdownValue === 'Yes'"
           class="form-group extrawide">
        <label for="newExternalComment">External Training Comment:</label>
        <textarea [disabled]="newReadyNowDropdownValue !== 'No'"
                  required
                  minlength="3"
                  maxlength="500"
                  style="height: 60px"
                  class="form-control"
                  id="newExternalComment"
                  name="newExternalComment"
                  #varNewExternalComment="ngModel"
                  [ngModel]></textarea>

        <div class="alert alert-danger"
             *ngIf="varNewExternalComment.touched && !varNewExternalComment.valid">
          <div *ngIf="varNewExternalComment.errors.required">Field is required!</div>
          <div *ngIf="varNewExternalComment.errors.minlength">Enter a detailed comment</div>
        </div>
      </div>

      <div *ngIf="newTrainingPlanDropdownValue === 'Completed'"
           class="form-group wide">
        <label for="trainingPlanFile">Upload Training Plan:</label>
        <br />
        <input type="file"
               title="Upload Training Plan"
               id="trainingPlanFile"
               name="trainingPlanFile"
               #varTrainingPlanFile="ngModel"
               [ngModel]
               (change)="trainingPlanFileSelected($event, varTrainingPlanFile)" />

        <div class="alert alert-danger"
             *ngIf="varTrainingPlanFile.touched && !varTrainingPlanFile.valid">
          <div *ngIf="varTrainingPlanFile.errors?.required">Field is required!</div>
        </div>
      </div>
    </div>
  </form>
</verde-modal> -->

<!-- #endregion New Successor Modal Screen 2 -->
<!-- #region Edit Internal Successor Modal -->
<!-- <verde-modal modalTitle="View Successor - {{ selectedSuccessor?.bt_SuccessorEmployeeID?.bt_fullname }}"
          submitButton="Submit"
          (actionButton)="patchInternalSuccessor(editInternalSuccessorForm)"
          id="editInternalSuccessorModal"
          [submitActive]="editInternalSuccessorForm.valid"
          submitConfirmationMessage="Please confirm that you would like to submit this new successor"
          cancelConfirmationMessage="Please confirm that you would like to cancel"
          [hasSubmitConfirmation]="true"
          [hasCancelConfirmation]="true">
  <form #editInternalSuccessorForm="ngForm"
        novalidate
        autocomplete="off">
    <div class="next_modal_grid">
      <div class="form-group wide">
        <label for="editReviewDate">Last Review Date</label>
        <input readonly
               type="text"
               id="editReviewDate"
               name="editReviewDate"
               #varEditReviewDate="ngModel"
               class="form-control"
               [ngModel] />
      </div>

      <div class="form-group wide">
        <label for="editPriority">Priority:</label>
        <select class="custom-select"
                [pattern]="checkDefaultPattern"
                id="editPriority"
                name="editPriority"
                #varEditPriority="ngModel"
                [ngModel]="defaultValue">
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="3">Low</option>
          <option value="2">Medium</option>
          <option value="1">High</option>
        </select>

        <div class="alert alert-danger"
             *ngIf="varEditPriority.touched && !varEditPriority.valid">
          <div *ngIf="varEditPriority.errors.required">Field is required!</div>
          <div *ngIf="varEditPriority.errors.pattern">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="editSuccessorComment">Comment:</label>
        <textarea maxlength="500"
                  style="height: 60px"
                  class="form-control"
                  id="editSuccessorComment"
                  name="editSuccessorComment"
                  #varEditSuccessorComment="ngModel"
                  [ngModel]></textarea>

        <div class="alert alert-danger"
             *ngIf="varEditSuccessorComment.touched && !varEditSuccessorComment.valid">
          <div *ngIf="varEditSuccessorComment.errors.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="editRetentionPotential">Retention Potential:</label>
        <select class="custom-select"
                id="editRetentionPotential"
                name="editRetentionPotential"
                #varEditRetentionPotential="ngModel"
                (change)="setNewRetentionPotentialDropdownValue($event.target.value)"
                [ngModel]>
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varEditRetentionPotential.touched && !varEditRetentionPotential.valid">
          <div *ngIf="varEditRetentionPotential?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="editRetirementStatus">Retirement Status:</label>
        <select class="custom-select"
                id="editRetirementStatus"
                name="editRetirementStatus"
                #varEditRetirementStatus="ngModel"
                (change)="setNewRetirementStatusDropdownValue($event.target.value)"
                [ngModel]>
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Likely">Likely</option>
          <option value="Unlikely">Unlikely</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varEditRetirementStatus.touched && !varEditRetirementStatus.valid">
          <div *ngIf="varEditRetirementStatus?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="editTransferPotential">Mobility/Transfer Potential:</label>
        <select class="custom-select"
                id="editTransferPotential"
                name="editTransferPotential"
                #varEditTransferPotential="ngModel"
                (change)="setNewTransferPotentialDropdownValue($event.target.value)"
                [ngModel]>
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varEditTransferPotential.touched && !varEditTransferPotential.valid">
          <div *ngIf="varEditTransferPotential?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="editReadyNow">Ready Now:</label>
        <select class="custom-select"
                id="editReadyNow"
                name="editReadyNow"
                #varEditReadyNow="ngModel"
                (change)="setNewReadyNowDropdownValue($event.target.value)"
                [ngModel]>
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varEditReadyNow.touched && !varEditReadyNow.valid">
          <div *ngIf="varEditReadyNow?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="editReadyIn">Ready In:</label>
        <select [disabled]="newReadyNowDropdownValue !== 'No'"
                class="custom-select"
                id="editReadyIn"
                name="editReadyIn"
                #varEditReadyIn="ngModel"
                (change)="setNewReadyInDropdownValue($event.target.value)"
                [ngModel]>
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option *ngIf="newReadyNowDropdownValue !== 'No'"
                  value="Now">
            Now
          </option>
          <option value="1 - 2 years">1 - 2 years</option>
          <option value="3 - 5 years">3 - 5 years</option>
          <option value="5 years plus">5 years plus</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varEditReadyIn.touched && !varEditReadyIn.valid">
          <div *ngIf="varEditReadyIn?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="editGapStatus">Gap Analysis Status</label>
        <select [disabled]="newReadyNowDropdownValue !== 'No'"
                class="custom-select"
                id="editGapStatus"
                name="editGapStatus"
                #varEditGapStatus="ngModel"
                (change)="setNewGapStatusDropdownValue($event.target.value)"
                [ngModel]>
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Not started">Not started</option>
          <option value="In progress">In progress</option>
          <option value="Completed">Completed</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varEditGapStatus.touched && !varEditGapStatus.valid">
          <div *ngIf="varEditGapStatus?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="editInternalRequired">Internal Training Required:</label>
        <select [disabled]="newReadyNowDropdownValue !== 'No'"
                class="custom-select"
                id="editInternalRequired"
                name="editInternalRequired"
                #varEditInternalRequired="ngModel"
                (change)="setNewInternalRequiredDropdownValue($event.target.value)"
                [ngModel]>
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varEditInternalRequired.touched && !varEditInternalRequired.valid">
          <div *ngIf="varEditInternalRequired?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="editExternalRequired">External Training Required:</label>
        <select [disabled]="newReadyNowDropdownValue !== 'No'"
                class="custom-select"
                id="editExternalRequired"
                name="editExternalRequired"
                #varEditExternalRequired="ngModel"
                (change)="setNewExternalRequiredDropdownValue($event.target.value)"
                [ngModel]>
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varEditExternalRequired.touched && !varEditExternalRequired.valid">
          <div *ngIf="varEditExternalRequired?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div class="form-group wide">
        <label for="editTrainingPlan">Training Plan Developed</label>
        <select [disabled]="newReadyNowDropdownValue !== 'No'"
                class="custom-select"
                id="editTrainingPlan"
                name="editTrainingPlan"
                #varEditTrainingPlan="ngModel"
                (change)="setNewTrainingPlanDropdownValue($event.target.value)"
                [ngModel]>
          <option value="default"
                  hidden>
            Choose an option
          </option>
          <option value="Not Started">Not Started</option>
          <option value="In progress">In progress</option>
          <option value="Completed">Completed</option>
        </select>


        <div class="alert alert-danger"
             *ngIf="varEditTrainingPlan.touched && !varEditTrainingPlan.valid">
          <div *ngIf="varEditTrainingPlan?.errors?.required">Field is required!</div>
        </div>
      </div>

      <div *ngIf="newInternalRequiredDropdownValue === 'Yes'"
           class="form-group extrawide">
        <label for="editInternalComment">Internal Training Comment:</label>
        <textarea [disabled]="newReadyNowDropdownValue !== 'No'"
                  required
                  minlength="3"
                  maxlength="500"
                  style="height: 60px"
                  class="form-control"
                  id="editInternalComment"
                  name="editInternalComment"
                  #varEditInternalComment="ngModel"
                  [ngModel]></textarea>

        <div class="alert alert-danger"
             *ngIf="varEditInternalComment.touched && !varEditInternalComment.valid">
          <div *ngIf="varEditInternalComment.errors.required">Field is required!</div>
          <div *ngIf="varEditInternalComment.errors.minlength">Enter a detailed comment</div>
        </div>
      </div>

      <div *ngIf="newExternalRequiredDropdownValue === 'Yes'"
           class="form-group extrawide">
        <label for="editExternalComment">External Training Comment:</label>
        <textarea [disabled]="newReadyNowDropdownValue !== 'No'"
                  required
                  minlength="3"
                  maxlength="500"
                  style="height: 60px"
                  class="form-control"
                  id="editExternalComment"
                  name="editExternalComment"
                  #varEditExternalComment="ngModel"
                  [ngModel]></textarea>

        <div class="alert alert-danger"
             *ngIf="varEditExternalComment.touched && !varEditExternalComment.valid">
          <div *ngIf="varEditExternalComment.errors.required">Field is required!</div>
          <div *ngIf="varEditExternalComment.errors.minlength">Enter a detailed comment</div>
        </div>
      </div>

      <div *ngIf="newTrainingPlanDropdownValue === 'Completed'"
           class="form-group wide">
        <label for="editTrainingPlanFile">Upload Training Plan:</label>
        <br />
        <input type="file"
               title="Upload Training Plan"
               id="editTrainingPlanFile"
               name="editTrainingPlanFile"
               #varEditTrainingPlanFile="ngModel"
               [ngModel]
               (change)="trainingPlanFileSelected($event, varTrainingPlanFile)" />

        <div class="alert alert-danger"
             *ngIf="varEditTrainingPlanFile.touched && !varEditTrainingPlanFile.valid">
          <div *ngIf="varEditTrainingPlanFile.errors?.required">Field is required!</div>
        </div>
      </div>
    </div>
  </form>
</verde-modal> -->

<!-- #endregion Edit Internal Successor Modal -->

<!-- #region Confirmation Modal -->

<!-- <verde-modal modalTitle="Confirmation"
          id="newSuccessorConfirmationModal"
          modalMaxWidth="400">
  <div style="position: relative; height: 60px">
    <ngx-spinner [disableAnimation]="disableAnimation"
                 name="confirmation"
                 bdColor="rgb(237, 237, 237)"
                 size="default"
                 color="#6264a7"
                 type="ball-clip-rotate"
                 [fullScreen]="false">

    </ngx-spinner>

    <p>{{ confirmationMessage }}</p>
  </div>
</verde-modal> -->
<!-- #endregion Confirmation Modal -->
<!-- #region Modals -->
