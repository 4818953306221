import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'verde-kendo-formly-multi-schema',
  template: `
    <div class="card">
      <div class="card-body">
        <h6 *ngIf="props.label">{{ props.label }}</h6>
        <kendo-label *ngIf="props.description">{{ props.description }}</kendo-label>
        <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
          <formly-validation-message [field]="field"></formly-validation-message>
        </div>
        <formly-field [id]="field.key" *ngFor="let f of field.fieldGroup" [field]="f"></formly-field>
      </div>
    </div>
  `,
})
export class KendoFormlyMultiSchema extends FieldType {}
