/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CommonBaseService } from '../common-base-service';
import { CommonApiConfiguration } from '../common-api-configuration';
import { CommonStrictHttpResponse } from '../common-strict-http-response';
import { CommonRequestBuilder } from '../common-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonRetrieveMultipleQuery } from '../models/common-retrieve-multiple-query';

@Injectable({
  providedIn: 'root',
})
export class CommonApiCommonService extends CommonBaseService {
  constructor(config: CommonApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation retrieveMultiple
   */
  static readonly RetrieveMultiplePath = '/api/Common/get/retrieveMultiple';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retrieveMultiple()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  retrieveMultiple$Response(params?: { tenantId?: string; body?: CommonRetrieveMultipleQuery }): Observable<CommonStrictHttpResponse<string>> {
    const rb = new CommonRequestBuilder(this.rootUrl, CommonApiCommonService.RetrieveMultiplePath, 'post');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as CommonStrictHttpResponse<string>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `retrieveMultiple$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  retrieveMultiple(params?: { tenantId?: string; body?: CommonRetrieveMultipleQuery }): Observable<string> {
    return this.retrieveMultiple$Response(params).pipe(map((r: CommonStrictHttpResponse<string>) => r.body as string));
  }
}
