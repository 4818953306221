import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult, RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { AggregateDescriptor, GroupDescriptor, GroupResult, orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { SharedApiVerdeTimeEntryService } from '@verde/api';
import { DotsMenuItem } from '@verde/shared';
import { Subject, take, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../services/verde-approval.service';

@Component({
  selector: 'verde-user-access-grid',
  templateUrl: './user-access-grid.component.html',
  styleUrls: ['./user-access-grid.component.scss'],
})
export class UserAccessGridComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  activityID: string;

  dotMenuItems: DotsMenuItem[] = [];

  allUserAccessData: Array<any> = new Array<any>();
  selectedItem: any;

  // Kendo Variables
  @ViewChild('myGrid') private grid: any;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  public UserAccessGridData: GroupResult[] | any[];

  UserAccessGridView: GridDataResult;

  UserAccessState: State = {
    skip: 0,
    take: 5,
  };

  UserAccessSort: SortDescriptor[] = [
    {
      field: 'bt_Employee.odataLegalEntity',
      dir: 'asc',
    },
  ];

  UserAccessAggregates: AggregateDescriptor[] = [{ field: 'bt_Employee.odataLegalEntity', aggregate: 'count' }];

  UserAccessGroups: GroupDescriptor[] = [{ field: 'bt_Employee.odataLegalEntity', aggregates: this.UserAccessAggregates }];

  UserAccessSelectedCallback = (args: any) => args.dataItem;

  UserAccessSelection: any[] = [];

  public selectableSettings: SelectableSettings = {
    enabled: true,
    checkboxOnly: true,
  };

  constructor(private sidebarService: VerdeApprovalService, private sharedApiVerdeTimeEntryService: SharedApiVerdeTimeEntryService) {}

  ngOnInit(): void {
    this.sidebarService
      .getactivityID()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((id) => {
        if (id?.length > 0) {
          this.activityID = id;
        }
      });
  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  ngAfterViewInit(): void {
    this.sharedApiVerdeTimeEntryService
      .getUserAccessForActivity({
        ActivityID: this.activityID,
      })
      .pipe(take(1))
      .subscribe(
        (ret1) => {
          this.UserAccessGridData = ret1;
        },
        (error) => {
          console.error(error);
        },
      );
  }

  // Three dot menu logic
  menuClicked(parentItem: any) {
    this.selectedItem = parentItem;

    this.dotMenuItems = [];
    let tempItems: DotsMenuItem[] = [];

    tempItems.push({ id: 'testid', text: 'Test' });

    this.dotMenuItems = [...tempItems];
  }

  menuItemClicked(menuItem: DotsMenuItem) {
    if (menuItem.id === 'testid') {
    }
  }

  // Kendo

  rowCallback(context: RowClassArgs) {
    const isEven = context.index % 2 === 0;
    return {
      even: isEven,
      odd: !isEven,
    };
  }

  private loadUserAccessData(): void {
    this.UserAccessGridView = {
      data: orderBy(this.allUserAccessData, this.UserAccessSort),
      total: this.allUserAccessData.length,
    };

    this.UserAccessGridView.data?.forEach((_, index) => {
      this.grid?.collapseGroup(String(index));
    });
  }

  UserAccessGroupChange(group: GroupDescriptor[]): void {
    group.map((group) => (group.aggregates = this.UserAccessAggregates));

    this.UserAccessGroups = group;
  }

  UserAccessDataStateChange(state: DataStateChangeEvent): void {
    this.UserAccessState = state;
    this.UserAccessGridView = process(this.allUserAccessData, this.UserAccessState);
  }

  UserAccessSortChange(sort: SortDescriptor[]): void {
    this.UserAccessSort = sort;
    this.loadUserAccessData();
  }

  public onUserAccessFilter(inputValue: string): void {
    this.UserAccessGridData = process(this.allUserAccessData, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'bt_Employee.odataLegalEntity',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'bt_Employee.odataDepartment',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'bt_Employee.odataDivision',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'bt_Employee.bt_fullname',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }
}
