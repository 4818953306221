/* tslint:disable */
/* eslint-disable */
export interface EquipmentSpec {
  _bt_equipmenttype_value?: null | string;
  _bt_legalentity_value?: null | string;
  _bt_specificationtype_value?: null | string;
  bt_estimatedvalue?: null | number;
  bt_verdeequipmentspec?: null | string;
  bt_verdeequipmentspecid?: null | string;
  odataEquipmentType?: null | string;
  odataLegalEntity?: null | string;
  odataSpecificationType?: null | string;
}
