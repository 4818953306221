import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CountersActions, CountersActionTypes } from '../actions/counters.actions';
import * as fromDashboardRelatedReducer from './dashboard-related.reducer';

// Models
import { Counter } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type CountersState = EntityState<Counter>;

export interface CountersRelatedState {
  votingCounterState: CountersState;
  loading: boolean;
  error: any;
}

export const countersKeys = ['countNumber'];
export const countersStateAdapter: EntityAdapter<Counter> = createEntityAdapter<Counter>({
  selectId: (data) => keyHandler(data, countersKeys),
});

export const initialCountersState: CountersState = countersStateAdapter.getInitialState({});

export const initialCountersRelatedState = {
  votingCounterState: initialCountersState,
  loading: false,
  error: {},
};

export function countersStateRelatedReducer(state = initialCountersRelatedState, action: CountersActions): CountersRelatedState {
  switch (action.type) {
    // Get Voting Counters
    case CountersActionTypes.VOTING_COUNTER_GET:
      return { ...state, loading: true };
    case CountersActionTypes.VOTING_COUNTER_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        votingCounterState: countersStateAdapter.setAll(action.payload, state.votingCounterState),
      };
    }
    case CountersActionTypes.VOTING_COUNTER_GET_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Clear
    case CountersActionTypes.COUNTERS_CLEAR:
      return { ...initialCountersRelatedState };
    default:
      return state;
  }
}

export const getHomeRelatedState = createFeatureSelector<fromDashboardRelatedReducer.DashboardRelatedState>('dashboardRelatedReducer');

export const getCountersRelatedState = createSelector(getHomeRelatedState, (state: fromDashboardRelatedReducer.DashboardRelatedState) => state.countersState);

// Current Voting Counter
export const getVotingCounterState = createSelector(getCountersRelatedState, (state) => state.votingCounterState);
export const { selectAll: getVotingCounter } = countersStateAdapter.getSelectors(getVotingCounterState);

// Loading
export const isVotingCounterLoading = createSelector(getCountersRelatedState, (state) => state.loading);

// Error
export const getError = createSelector(getCountersRelatedState, (state) => state.error);
