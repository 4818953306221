import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TemplateGetSubgroupsQuery, TemplatePatchTemplateSubgroupCommand, TemplateUploadSubgroupCommand } from '@verde/api';
import { Observable } from 'rxjs';
import * as SubgroupActions from '../store/actions/subgroup.actions';
import * as fromProfileRelatedReducer from '../store/reducers/profile-related.reducer';
import * as fromSubgroupReducer from '../store/reducers/subgroup.reducer';

@Injectable({
  providedIn: 'root',
})
export class SubgroupService {
  // Subgroup
  subgroupLoading$: Observable<boolean>;
  subgroupObjects$: Observable<any[]>;

  constructor(private store: Store<fromProfileRelatedReducer.ProfileRelatedState>) {
    // Subgroup
    this.subgroupLoading$ = this.store.pipe(select(fromSubgroupReducer.isSubgroupLoading));
    this.subgroupObjects$ = this.store.pipe(select(fromSubgroupReducer.getAllSubgroup));
  }

  // Equipment Item
  // Get Equipment Items By Location
  getSubgroup(body: TemplateGetSubgroupsQuery) {
    this.store.dispatch(new SubgroupActions.SubgroupsGet(body));
  }

  // Clear
  subgroupClear() {
    this.store.dispatch(new SubgroupActions.SubgroupsClear(null));
  }

  // Post
  uploadSubgroup(payload: TemplateUploadSubgroupCommand) {
    this.store.dispatch(new SubgroupActions.SubgroupsUpload(payload));
  }

  // Subgroup update
  updateSubgroup(payload: TemplatePatchTemplateSubgroupCommand) {
    this.store.dispatch(new SubgroupActions.SubgroupUpdate(payload));
  }
}
