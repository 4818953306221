import { Action } from '@ngrx/store';
import { UserUserDto } from '@verde/api';

export enum BirthdaysActionTypes {
  // Get All Birthdays
  BIRTHDAYS_GET_ALL = '[BIRTHDAYS] Get All',
  BIRTHDAYS_GET_ALL_SUCCESS = '[BIRTHDAYS] Get All Success',
  BIRTHDAYS_GET_ALL_ERROR = '[BIRTHDAYS] Get All Error',

  // Clear Birthdays
  BIRTHDAYS_CLEAR = '[BIRTHDAYS] Clear',
}

// Get All Birthdays
export class BirthdaysGetAll implements Action {
  readonly type = BirthdaysActionTypes.BIRTHDAYS_GET_ALL;
  constructor(public legalEntityID: string) {}
}

export class BirthdaysGetAllSuccess implements Action {
  readonly type = BirthdaysActionTypes.BIRTHDAYS_GET_ALL_SUCCESS;
  constructor(public payload: UserUserDto[]) {}
}

export class BirthdaysGetAllError implements Action {
  readonly type = BirthdaysActionTypes.BIRTHDAYS_GET_ALL_ERROR;
  constructor(public payload: any) {}
}

// Clear Birthdays
export class BirthdaysClear implements Action {
  readonly type = BirthdaysActionTypes.BIRTHDAYS_CLEAR;
  constructor(public payload: any) {}
}

export type BirthdaysActions =
  // Get All Birthdays
  | BirthdaysGetAll
  | BirthdaysGetAllSuccess
  | BirthdaysGetAllError
  // Clear Birthdays
  | BirthdaysClear;
