import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EngageActions, EngageActionTypes } from '../actions/engage.actions';
import * as fromDashboardRelatedReducer from './dashboard-related.reducer';

// Models
import { EmployeeStatus } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export type EngageState = EntityState<EmployeeStatus>;

export interface EngageRelatedState {
  engageState: EngageState;
  loading: boolean;
  error: any;
}

export const engageKeys = ['bt_employeestatusid'];
export const engageStateAdapter: EntityAdapter<EmployeeStatus> = createEntityAdapter<EmployeeStatus>({
  selectId: (data) => keyHandler(data, engageKeys),
});

export const initialEngageState: EngageState = engageStateAdapter.getInitialState({});

export const initialEngageRelatedState = {
  engageState: initialEngageState,
  loading: false,
  error: {},
};

export function engageStateRelatedReducer(state = initialEngageRelatedState, action: EngageActions): EngageRelatedState {
  switch (action.type) {
    // Get  Engage
    case EngageActionTypes.ENGAGE_GET:
      return { ...state, loading: true };
    case EngageActionTypes.ENGAGE_GET_SUCCESS: {
      return {
        ...state,
        loading: false,
        engageState: engageStateAdapter.setAll(action.payload, state.engageState),
      };
    }
    case EngageActionTypes.ENGAGE_GET_ERROR:
      return { ...state, loading: false, error: action.payload };
    // Clear
    case EngageActionTypes.ENGAGE_CLEAR:
      return { ...initialEngageRelatedState };
    default:
      return state;
  }
}

export const getHomeRelatedState = createFeatureSelector<fromDashboardRelatedReducer.DashboardRelatedState>('dashboardRelatedReducer');

export const getEngageRelatedState = createSelector(getHomeRelatedState, (state: fromDashboardRelatedReducer.DashboardRelatedState) => state.engageState);

// Current Engage
export const getEngageState = createSelector(getEngageRelatedState, (state) => state.engageState);
export const { selectAll: getEngage } = engageStateAdapter.getSelectors(getEngageState);

// Loading
export const isLoading = createSelector(getEngageRelatedState, (state) => state.loading);

// Error
export const getError = createSelector(getEngageRelatedState, (state) => state.error);
