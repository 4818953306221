import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from '@environments-verde/environment';
import { ApiModule } from '@verde/api';
import { CoreModule } from '@verde/core';
import { VerdeSharedModule } from '@verde/shared';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule.forRoot(environment),
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    VerdeSharedModule,
    environment.imports,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: !isDevMode(),
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
