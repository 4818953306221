/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { TimeOffBaseService } from '../time-off-base-service';
import { TimeOffApiConfiguration } from '../time-off-api-configuration';
import { TimeOffStrictHttpResponse } from '../time-off-strict-http-response';
import { TimeOffRequestBuilder } from '../time-off-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TimeOffEmployeeProvisionCommand } from '../models/time-off-employee-provision-command';

@Injectable({
  providedIn: 'root',
})
export class TimeOffApiEmployeeProvisionService extends TimeOffBaseService {
  constructor(config: TimeOffApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation employeeProvision
   */
  static readonly EmployeeProvisionPath = '/api/EmployeeProvision/post/employeeprovision';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `employeeProvision()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  employeeProvision$Response(params?: { body?: TimeOffEmployeeProvisionCommand }): Observable<TimeOffStrictHttpResponse<boolean>> {
    const rb = new TimeOffRequestBuilder(this.rootUrl, TimeOffApiEmployeeProvisionService.EmployeeProvisionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as TimeOffStrictHttpResponse<boolean>;
        }),
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `employeeProvision$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  employeeProvision(params?: { body?: TimeOffEmployeeProvisionCommand }): Observable<boolean> {
    return this.employeeProvision$Response(params).pipe(map((r: TimeOffStrictHttpResponse<boolean>) => r.body as boolean));
  }
}
