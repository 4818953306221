/* tslint:disable */
/* eslint-disable */
export enum PerformanceReviewBtResponseType {
  Rating = 'Rating',
  FreeText = 'FreeText',
  Target = 'Target',
  YesNo = 'YesNo',
  Number = 'Number',
  Date = 'Date',
  CheckBox = 'CheckBox',
  Dropdown = 'Dropdown',
}
