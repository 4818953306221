/* tslint:disable */
/* eslint-disable */
import { BtStructureManager } from './bt-structure-manager';
import { BtStructurePositionSeat } from './bt-structure-position-seat';
import { BtPositionseat } from './bt-positionseat';
export interface StructureDepartments {
  _bthr_division_value?: null | string;
  _bthr_legalentity_value?: null | string;
  _bthr_parentdepartment_value?: null | string;
  btHaschildrenODataCommunityDisplayV1FormattedValue?: null | string;
  bt_Manager?: BtStructureManager;
  bt_PositionSeat?: BtStructurePositionSeat;
  bt_haschildren?: boolean;
  bt_seatstatus?: BtPositionseat;
  bthrDivisionValueODataCommunityDisplayV1FormattedValue?: null | string;
  bthrLegalentityValueODataCommunityDisplayV1FormattedValue?: null | string;
  bthrParentdepartmentValueODataCommunityDisplayV1FormattedValue?: null | string;
  bthr_department?: null | string;
  bthr_departmentid?: null | string;
  odataSeatStatus?: null | string;
}
