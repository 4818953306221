import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { QualificationsGetEmployeeEducationalQualificationQuery } from '@verde/api';
import { Observable } from 'rxjs';
import * as EmployeeEducationalQualificationActions from '../store/actions/employee-educational-qualification.actions';
import * as fromEmployeeEducationalQualificationReducer from '../store/reducers/employee-educational-qualification.reducer';
import * as fromProfileRelatedReducer from '../store/reducers/profile-related.reducer';

@Injectable({
  providedIn: 'root',
})
export class EmployeeEducationalQualificationService {
  // EmployeeEducationalQualification
  employeeEducationalQualificationLoading$: Observable<boolean>;
  employeeEducationalQualificationObjects$: Observable<any[]>;

  constructor(private store: Store<fromProfileRelatedReducer.ProfileRelatedState>) {
    // EmployeeEducationalQualification
    this.employeeEducationalQualificationLoading$ = this.store.pipe(
      select(fromEmployeeEducationalQualificationReducer.isEmployeeEducationalQualificationLoading),
    );
    this.employeeEducationalQualificationObjects$ = this.store.pipe(select(fromEmployeeEducationalQualificationReducer.getEmployeeEducationalQualification));
  }

  // EmployeeEducationalQualification Detail
  // Get
  getEmployeeEducationalQualification(body: QualificationsGetEmployeeEducationalQualificationQuery) {
    this.store.dispatch(new EmployeeEducationalQualificationActions.EmployeeEducationalQualificationGetAll(body));
  }

  // Clear
  employeeEducationalQualificationClear() {
    this.store.dispatch(new EmployeeEducationalQualificationActions.EmployeeEducationalQualificationClear(null));
  }

  // Upload
  uploadEmployeeEducationalQualification(payload: any) {
    this.store.dispatch(new EmployeeEducationalQualificationActions.EmployeeEducationalQualificationsUpload(payload));
  }

  // Update
  updateUserEducationalQualification(payload: any) {
    this.store.dispatch(new EmployeeEducationalQualificationActions.EmployeeEducationalQualificationUpdate(payload));
  }
}
