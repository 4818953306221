/* tslint:disable */
/* eslint-disable */
import { BtDocSalaryAdviceHeader } from './bt-doc-salary-advice-header';
export interface BtDocSalaryAdviceDetail {
  _bt_docsalaryadviceheader_value?: null | string;
  bt_DocSalaryAdviceHeader?: BtDocSalaryAdviceHeader;
  bt_amount?: null | string;
  bt_docsalaryadvicedetail?: null | string;
  bt_docsalaryadvicedetailid?: null | string;
  bt_salaryadvicetype?: number;
  bt_unit?: null | string;
  odataPostbt_docsalaryadviceHeader?: null | string;
  odatabt_docsalaryadviceheader_value?: null | string;
}
