import { Action } from '@ngrx/store';
import { EmployeeStatus } from '@verde/api';

export enum EngageActionTypes {
  // Get Engage
  ENGAGE_GET = '[ENGAGE] Get ',
  ENGAGE_GET_SUCCESS = '[ENGAGE] Get  Success',
  ENGAGE_GET_ERROR = '[ENGAGE] Get  Error',

  // Clear Engage
  ENGAGE_CLEAR = '[ENGAGE] Clear',
}

// Get Engage
export class EngageGet implements Action {
  readonly type = EngageActionTypes.ENGAGE_GET;
  constructor(public employeeEmail: string) {}
}

export class EngageGetSuccess implements Action {
  readonly type = EngageActionTypes.ENGAGE_GET_SUCCESS;
  constructor(public payload: EmployeeStatus[]) {}
}

export class EngageGetError implements Action {
  readonly type = EngageActionTypes.ENGAGE_GET_ERROR;
  constructor(public payload: any) {}
}

// Clear Engage
export class EngageClear implements Action {
  readonly type = EngageActionTypes.ENGAGE_CLEAR;
  constructor(public payload: any) {}
}

export type EngageActions =
  // Get Engage
  | EngageGet
  | EngageGetSuccess
  | EngageGetError
  // Clear Engage
  | EngageClear;
