/* eslint-disable @typescript-eslint/no-empty-interface */
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BenchmarksActions, BenchmarksActionTypes } from '../actions/benchmark.actions';
import * as fromProfileRelatedReducer from './profile-related.reducer';

// Models
import { TemplateBenchmark } from '@verde/api';

// Helpers
import { keyHandler } from '@verde/core';

export interface BenchmarkState extends EntityState<TemplateBenchmark> {}

export interface BenchmarkRelatedState {
  benchmarkState: BenchmarkState;
  loading: boolean;
  error: any;
}

export const benchmarkKeys = ['bt_peerreviewbenchmarkid'];
export const benchmarkStateAdapter: EntityAdapter<TemplateBenchmark> = createEntityAdapter<TemplateBenchmark>({
  selectId: (data) => keyHandler(data, benchmarkKeys),
});

export const initialBenchmarkState: BenchmarkState = benchmarkStateAdapter.getInitialState({});

export const initialBenchmarkRelatedState = {
  benchmarkState: initialBenchmarkState,
  loading: false,
  error: {},
};

export function benchmarkRelatedReducer(state = initialBenchmarkRelatedState, action: BenchmarksActions): BenchmarkRelatedState {
  switch (action.type) {
    // Get Benchmark
    case BenchmarksActionTypes.BENCHMARKS_GET_ALL:
      return { ...state, loading: true };
    case BenchmarksActionTypes.BENCHMARKS_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        benchmarkState: benchmarkStateAdapter.upsertMany(action.payload, state.benchmarkState),
      };
    case BenchmarksActionTypes.BENCHMARKS_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Get Benchmark By Group
    case BenchmarksActionTypes.BENCHMARKS_BY_GROUP_GET_ALL:
      return { ...state, loading: true };
    case BenchmarksActionTypes.BENCHMARKS_BY_GROUP_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        benchmarkState: benchmarkStateAdapter.upsertMany(action.payload, state.benchmarkState),
      };
    case BenchmarksActionTypes.BENCHMARKS_BY_GROUP_GET_ALL_ERROR:
      return { ...state, loading: false, error: action.payload };

    // Clear
    case BenchmarksActionTypes.BENCHMARKS_ITEM_CLEAR:
      return { ...initialBenchmarkRelatedState };
    default:
      return state;
  }
}

export const getProfileRelatedState = createFeatureSelector<fromProfileRelatedReducer.ProfileRelatedState>('profileRelatedReducer');

export const getBenchmarkRelatedState = createSelector(getProfileRelatedState, (state: fromProfileRelatedReducer.ProfileRelatedState) => state.benchmarksState);

// Current Benchmark

export const getBenchmarkState = createSelector(getBenchmarkRelatedState, (state) => state.benchmarkState);
export const { selectAll: getAllBenchmark } = benchmarkStateAdapter.getSelectors(getBenchmarkState);

// Loading

export const isBenchmarkLoading = createSelector(getBenchmarkRelatedState, (state) => state.loading);

// Error

export const getBenchmarkError = createSelector(getBenchmarkRelatedState, (state) => state.error);
